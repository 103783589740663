import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useHistory, useLocation } from 'react-router-dom';

import { keyMap } from '../utils/platform';
import { setSearchProductType } from '../reducers/app';
import { openModal } from '../reducers/modals';
import { openSideModal } from '../reducers/sideModals';
import { hasPermissionTo } from '../selectors/auth';
import {
  country as countrySelector,
  shiftsEnabled,
  isOnlyInvoicingPlan,
} from '../selectors/company';
import { itemsCount } from '../selectors/activeInvoice';
import Home from './home/Home';
import Invoices from './invoices/Invoices';
import Shifts from './shifts/Shifts';
import Items from './items/Items';
import Payments from './payments/Payments';
import Refunds from './refunds/Refunds';
import Statistics from './statistics/Statistics';
import Inventory from './inventory/Inventory';
import Settings from './settings/Settings';
import Membership from './membership/Membership';

const Main = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  let history = useHistory();
  let location = useLocation();
  const isShiftsEnabled = useSelector(shiftsEnabled);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const activeInvoiceItems = useSelector(itemsCount);
  const country = useSelector(countrySelector);

  configure({ ignoreTags: [] });

  const handlers = {
    GOTO_HOME: (event) => {
      event.preventDefault();
      if (location.pathname !== '/') history.push('/');
    },
    GOTO_PAYMENTS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/payments') history.push('/payments');
    },
    GOTO_REFUNDS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/refunds') history.push('/refunds');
    },
    GOTO_SHIFTS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/shifts' && isShiftsEnabled)
        history.push('/shifts');
    },
    OPEN_SHORTCUT_HELP: (event) => {
      event.preventDefault();
      dispatch(openModal({ modal: 'shortcuts' }));
    },
    OPEN_NEW_ITEM: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(openModal({ modal: 'item' }));
    },
    OPEN_NEW_CLIENT: (event) => {
      event.preventDefault();
      if (location.pathname === '/')
        dispatch(openSideModal({ sideModal: 'contact' }));
    },
    OPEN_PAYMENT_MODAL: (event) => {
      event.preventDefault();
      if (location.pathname === '/' && !!activeInvoiceItems)
        dispatch(openModal({ modal: 'invoice' }));
    },
    FOCUS_NORMAL_SEARCH: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(setSearchProductType('text'));
    },
    FOCUS_BARCODE_SEARCH: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(setSearchProductType('barcode'));
    },
  };

  return (
    <div className='main main-new-color'>
      {!onlyInvoicingPlan && (
        <GlobalHotKeys
          keyMap={keyMap(country)}
          handlers={handlers}
          allowChanges
        />
      )}

      <Switch>
        <Route path='/' exact component={Home} />

        {!onlyInvoicingPlan && <Route path='/invoices' component={Invoices} />}

        {!!isShiftsEnabled &&
          !onlyInvoicingPlan &&
          can('index', 'pos-cashier') && (
            <Route path='/shifts' component={Shifts} />
          )}

        {!onlyInvoicingPlan && <Route path='/items' component={Items} />}

        {!onlyInvoicingPlan && <Route path='/payments' component={Payments} />}

        {!onlyInvoicingPlan && <Route path='/refunds' component={Refunds} />}

        {!onlyInvoicingPlan && (
          <Route path='/statistics' component={Statistics} />
        )}

        <Route path='/plan' component={Membership} />

        <Route path='/inventory' component={Inventory} />

        <Route path='/settings' component={Settings} />

        <Redirect to='/' />
      </Switch>
    </div>
  );
};

export default Main;
