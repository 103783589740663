import React from "react";

import Shortcuts from './shortcuts/Shortcuts';
import OpenShift from './openShift/OpenShift';
import CloseShift from './closeShift/CloseShift';
import NewCloseShift from "./closeShift/NewCloseShift";
import Payments from './payments/Payments';
import Refunds from './refunds/Refunds';
import Invoice from './invoice/Invoice';
import InvoiceSaved from './invoiceSaved/InvoiceSaved';
import SendEmail from './sendEmail/SendEmail';
import EditInvoice from './editInvoice/EditInvoice';
import LogoutConfirm from './logoutConfirm/LogoutConfirm';
import RenamePendingInvoice from './renamePendingInvoice/RenamePendingInvoice';
import StationSettings from './stationSettings/StationSettings'
import Welcome from './welcome/Welcome';
import OnboardingEnd from './onboardingEnd/OnboardingEnd';
import PlanLimitReached from './planLimitReached/PlanLimitReached';
import CompanyEmail from './companyEmail/CompanyEmail';
import ReadonlyPlan from './readonlyPlan/ReadonlyPlan';
import ReadonlyPlanWithData from './readonlyPlanWithData/ReadonlyPlanWithData';
import SuspendedPlan from './suspendedPlan/SuspendedPlan';
import NewItem from './newItem/NewItem';
import Variant from './variant/Variant';
import NewTermsAndConditionsColombia from './newTermsAndConditionsColombia/NewTCCol'
//import ActivateFEHelpers from './activateFE/ActivateFE';
import CustomField from './customField/CustomField';
import Warehouse from "./warehouse/Warehouse";
import Station from "./station/Station";
import ItemCategory from "./itemCategory/ItemCategory";
import PriceList from "./priceList/PriceList";
import DailyReport from './dailyReport/DailyReport';
import CancelItemCreation from "./newItem/CancelItemCreation";
import Activate5UVT from "./activate5UVT/Activate5UVT";
import Invoice5UVT from "./invoice5UVT/Invoice5UVT";
import Invoice5UVTNewClient from "./invoice5UVTNewClient/Invoice5UVTNewClient";
import Deactivate5UVT from "./deactivate5UVT/Deactivate5UVT";
import LimitedUser5UVT from "./limitedUser5UVT/LimitedUser5UVT";
import ConfigureWarehouse from "./warehouse/ConfigureWarehouse";
import Reminder5UVT from './reminder5UVT/Reminder5UVT';
import ActivateWompiIntegration from "./activateWompiIntegration/ActivateWompiIntegration";
import ReconfigureNumeration5UVT from "./reconfigureNumeration5UVT/ReconfigureNumeration5UVT";
import EditFENumerationTerminal from "./editFENumerationTerminal/EditFENumerationTerminal";
import ElectronicPayrollTutorial from "./electronicPayrollTutorial/ElectronicPayrollTutorial";
import MembershipPaymentDeclined from "./membershipPaymentDeclined/MembershipPaymentDeclined";
import HappyWeekExperimentModal from "./happyWeekExperiment/HappyWeekExperiment";
import SolutionsModal from "./solutions";
import ElectronicPOSDocument from "./electronicPosDocument";
import CompanyDataUpdate from "./companyDataUpdate/CompanyDataUpdate";
import ChangeToOrdinaryNumeration from "./changeToOrdinaryNumeration/ChangeToOrdinaryNumeration";


const Modals = () => (
  <>
    <Shortcuts />
    <OpenShift />
    <CloseShift />
    <NewCloseShift />
    <Payments />
    <Variant />
    <Refunds />
    <Invoice />
    <InvoiceSaved />
    <Invoice5UVT />
    <Invoice5UVTNewClient />
    <SendEmail />
    <EditInvoice />
    <LogoutConfirm />
    <RenamePendingInvoice />
    <StationSettings />
    <Welcome />
    <OnboardingEnd />
    <PlanLimitReached />
    <CompanyEmail />
    <ReadonlyPlan />
    <ReadonlyPlanWithData />
    <SuspendedPlan />
    <NewItem />
    <NewTermsAndConditionsColombia />
    {/** <ActivateFEHelpers /> */}
    <CustomField />
    <Warehouse />
    <Station />
    <ItemCategory />
    <PriceList />
    <DailyReport />
    <CancelItemCreation />
    <Activate5UVT />
    <Deactivate5UVT />
    <LimitedUser5UVT />
    <ConfigureWarehouse />
    <Reminder5UVT />
    <ActivateWompiIntegration />
    <ReconfigureNumeration5UVT />
    <EditFENumerationTerminal />
    <ElectronicPayrollTutorial />
    <MembershipPaymentDeclined />
    <HappyWeekExperimentModal />
    <SolutionsModal />
    <ElectronicPOSDocument />
    <CompanyDataUpdate />
    <ChangeToOrdinaryNumeration/>
  </>
);

export default Modals;
