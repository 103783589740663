import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { get, lowerFirst } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';

import * as mutations from '../../../graphql/mutations';
import { APIGraphqlSelector, station as stationSelector } from '../../../selectors/app';
import { country as countrySelector, decimalPrecision, idCompanySelector } from '../../../selectors/company';
import { customFields as customFieldsSelector } from '../../../selectors/customFields';
import { refresh } from '../../../reducers/items';
import { checkFeatureLimit } from '../../../reducers/membership';
import { closeSideModal } from '../../../reducers/sideModals';
import * as itemsDB from '../../../database/itemsDB'
import { oldItemTransformer } from '../../../utils/transformers';
import { toast, warehouseInfoLink } from '../../../utils';
import { formError } from '../../../utils/errors';
import { oldValidate, transform } from '../../forms/item/utils'
import PriceList from '../modals/priceList/PriceList';
import ItemCategory from '../modals/itemCategory/ItemCategory';
import Warehouse from '../modals/warehouse/Warehouse';
import SideModal from '../../common/SideModal';
import Header from '../common/Header';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Body from './FormBody';
import { replaceAndParse } from '../../../utils/index';

const initialModalState = {
  itemCategory: false,
  priceList: false,
  warehouse: false,
}

const Item = () => {
  const dispatch = useDispatch();
  const [modals, setModals] = useState(initialModalState)
  const [priceListIndex, setPriceListIndex] = useState(0)
  const [warehouseIndex, setWarehouseIndex] = useState(0)
  const isOpen = useSelector(state => get(state, 'sideModals.item.isOpen', false));
  const station = useSelector(stationSelector)
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const companyId = useSelector(idCompanySelector);
  const customFields = useSelector(customFieldsSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);


  let initialValues = {
    customFields: {}
  };

  customFields.map(field => {
    if (get(field, 'type') === 'optionsList') {
      initialValues.customFields[`cf${get(field, 'id')}`] = {
        value: get(field, 'defaultValue', null), label: get(field, 'defaultValue', null)
      }
    } else if (get(field, 'type') === 'boolean')
      initialValues.customFields[`cf${get(field, 'id')}`] = get(field, 'defaultValue', null) === "1"
    else
      initialValues.customFields[`cf${get(field, 'id')}`] = get(field, 'defaultValue', null)

    return null;
  })

  useEffect(() => {
    setModals(initialModalState)
  }, [isOpen])

  const close = () => {
    dispatch(closeSideModal({ sideModal: 'item' }))
  }

  const submit = async values => {
    const transformedValues = transform(values, {
      country, decimal, allCustomFields: customFields,
    })

    try {
      let addItem = false;

      const response = await APIGraphql(graphqlOperation(mutations.createItem, {
        item: oldItemTransformer(transformedValues)
      }))

      window.dataLayer.push({
        event: 'eventActivity',
        eventActivityAction: 'item / add / *',
        eventActivityLabel: companyId,
      });

      const { idWarehouse } = station;
      const item = get(response, 'data.createItem', null);
      const warehouses = get(item, 'inventory.warehouses', null);

      if (!!item) {
        if (!!warehouses) {
          if (!!warehouses.find(warehouse => +get(warehouse, 'id') === +idWarehouse))
            addItem = true
        } else
          addItem = true
      }

      if (addItem) {
        await itemsDB.put({ ...item, id: parseInt(item.id) })
        dispatch(refresh())
      }

      close();

      if (warehouses && !(!!warehouses?.find(warehouse => +get(warehouse, 'id') === +idWarehouse)))
        toast.success({
          title: `${I18n.get('itemCreatedSuccessfullyAlter', 'Ya está creado tu producto. 🏷')}`,
          subtitle: `${I18n.get('itemCreatedSuccessfullyMessageAlter', 'Ten en cuenta que no lo asociaste a la bodega de tu terminal actual y para venderlo debes ')}`,
          link: {
            url: `${warehouseInfoLink(country)}`,
            message: `${I18n.get('itemCreatedSuccessfullyMessageLink', 'cambiar de terminal o editar esta.')}`
          }
        })
      else
        toast.success({
          title: `${I18n.get('itemCreatedSuccessfully', 'producto creado con éxito')} 🎯`,
          subtitle: replaceAndParse(I18n.get('itemCreatedSuccessfullyMessage', 'El {} {} ya está disponible en tu cuenta.'), [
            get(item, 'type') !== 'simple' ? lowerFirst(I18n.get(get(item, 'type'))) : lowerFirst(I18n.get('product', 'producto')),
            `<span class="font-weight-bold">${get(item, 'name', '')}</span>`
          ])
        })

    } catch (error) {
      return formError(error, I18n.get('createItemError', 'hubo un error en la creación del producto'))
    }
  }

  return (
    <SideModal
      open={isOpen}
      onRequestClose={() => close()}
      destroyOnClose
    >
      <>
        <Form
          onSubmit={submit}
          mutators={{
            ...arrayMutators
          }}
          validate={values => oldValidate(values, { country, customFields })}
          initialValues={initialValues}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, values, form, submitError, submitting }) => (
            <>
              <form
                noValidate
                className="w-100 h-100 bg-white d-flex flex-column"
                onSubmit={handleSubmit}
              >
                <Header
                  title={I18n.get('newItem', 'nuevo producto o servicio')}
                  onClose={() => close()}
                  description={I18n.get('newItemSubtitle', 'Crea los productos o servicios que ofreces en tu negocio.')}
                  seeMoreUrl={country === 'peru'
                    ? "https://ayuda.alegra.com/es/crea-tus-productos-y-servicios-con-el-c%C3%B3digo-sunat-para-facturar-desde-el-punto-de-venta"
                    : "https://ayuda.alegra.com/es/gesti%C3%B3n-de-productos-en-el-punto-de-venta-pos"}
                />

                <div className="side-modal__body">
                  <Body
                    values={values}
                    form={form}
                    newItemCategoryAction={() => setModals({ ...modals, itemCategory: true })}
                    newPriceListAction={index => {
                      setModals({ ...modals, priceList: true })
                      setPriceListIndex(index)
                    }}
                    newWarehouseAction={index => dispatch(checkFeatureLimit('warehouses', () => {
                      setModals({ ...modals, warehouse: true })
                      setWarehouseIndex(index)
                    }))}
                    fromOldForm
                  />
                </div>

                <Notification
                  isOpen={!!submitError}
                  type="error"
                  text={submitError}
                />

                <Bottom
                  onClose={() => close()}
                  disabled={submitting}
                  submitting={submitting}
                />
              </form>

              <PriceList
                isOpen={!!modals.priceList}
                onRequestClose={() => setModals({ ...modals, priceList: false })}
                onCreated={priceList => {
                  try {
                    form.change(`priceLists.${priceListIndex}.priceList`, priceList)
                  } catch {
                    //
                  }
                }}
                fromOldForm
              />

              <Warehouse
                isOpen={!!modals.warehouse}
                onRequestClose={() => setModals({ ...modals, warehouse: false })}
                onCreated={warehouse => {
                  try {
                    form.change(`warehouses.${warehouseIndex}.warehouse`, warehouse)
                  } catch {
                    //
                  }
                }}
                fromOldForm
              />

              <ItemCategory
                isOpen={!!modals.itemCategory}
                onRequestClose={() => setModals({ ...modals, itemCategory: false })}
                onCreated={category => form.change('category', category)}
              />
            </>
          )}
        </Form>
      </>
    </SideModal>
  )
}

export default Item;