import React from 'react';
import { Field } from 'react-final-form';
import { get, capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';

import countries from '../../../countriesData/general/countries';
import colombiaAddresses from '../../../countriesData/colombia/citiesDepartments.json';
import costaRicaAddresses from '../../../countriesData/costaRica/districtsCantonsProvinces.json';
import neighborhoods from '../../../countriesData/costaRica/dataNeighborhood';
import argentinaProvinces from '../../../countriesData/argentina/provinces';
import argentinaCities from '../../../countriesData/argentina/cities.json';
import peruAddresses from '../../../countriesData/peru/address.json';
import dominicanaAddresses from '../../../countriesData/republicaDominicana/municipalityProvince.json';
import spainAddresses from '../../../countriesData/spain/municipalityProvince.json';
import panamaCities from '../../../countriesData/panama/cities.json';
import { renderField, renderSelect } from '../../fields/V0/Fields';

const colombiaAddress = props => {
  const identification = get(props, 'values.identification.type.key', '');
  const colombianContact = get(props, 'values.address.country.key', '') === 'COL';
  const foreignId = identification === 'DIE' || identification === 'PP' || identification === 'TE' || identification === 'FOREIGN_NIT';
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || colombianContact;
  const address = get(props, 'values.address.address', '');
  const combinedAddress = get(props, 'values.address.combined', '');

  const isRequired = () => {
    if (!foreignId) {
      if (address || combinedAddress) {
        if (!combinedAddress) {
          const municipality = get(props, 'values.defaultAddress.combined', '');
          address && props.form.change('address.combined', municipality);
        }
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {showCountry && (
        <Field
          name="address.country"
          component={renderSelect}
          className="col-md-6"
          options={identification === 'FOREIGN_NIT' ? countries.filter(country => country.key !== 'COL') : countries}
          label={capitalize(I18n.get('country', 'país'))}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
        />
      )}

      {showCombinedAddress && (
        <Field
          name="address.combined"
          component={renderSelect}
          className={'col-6 col-md-6'}
          options={colombiaAddresses}
          label={[
            capitalize(I18n.get('city', 'municipio')),
            capitalize(I18n.get('department', 'departamento')),
          ].join(' / ')}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
          required={isRequired()}
        />
      )}


      <Field
        name="address.address"
        className={'col-6 col-md-6'}
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
        helpTooltip={I18n.get('addressTooltip', 'Debes incluir la dirección cuando realizas la venta porfuera de tu local o si eliges el municipio de tu cliente')}
        required={isRequired()}
      />
    </>
  )
}

const spainAddress = props => {
  return (
    <>
      <Field
        name='address.combined'
        className='col-12'
        component={renderSelect}
        options={spainAddresses}
        label={[
          capitalize(I18n.get('city', 'municipio')),
          capitalize(I18n.get('province', 'provincia')),
        ].join(' / ')}
        getOptionLabel={(option) => option.value}
        getOptionValue={(option) => option.key}
        isSearchable={true}
      />
    </>
  );
}

const costaRicaAddress = props => {
  const identification = get(props, 'values.identification.type.key', '');
  const foreignId = identification === 'PE';
  const combinedAddress = get(props, 'values.address.combined.key', '');
  const districtId = combinedAddress.split(', ')[0]

  return (
    <>
      {!foreignId && (
        <Field
          name="address.combined"
          className="col-md-6"
          component={renderSelect}
          options={costaRicaAddresses}
          label={[
            capitalize(I18n.get('district', 'distrito')),
            capitalize(I18n.get('canton', 'cantón')),
            capitalize(I18n.get('province', 'provincia')),
          ].join(' / ')}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
          required
        />
      )}

      {!foreignId && (
        <Field
          name="address.neighborhood"
          component={renderSelect}
          className="col-md-6"
          options={neighborhoods.filter(i => '' + i.idDistrict === districtId)}
          label={capitalize(I18n.get('neighborhood', 'Barrio'))}
          isSearchable={true}
          required
        />
      )}

      <Field
        name="address.address"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
        required
      />
    </>
  )
}

const argentinaAddress = props => {
  const provinceId = get(props, 'values.address.province.id', '');

  return (
    <>
      <Field
        name="address.province"
        className="col-md-6"
        component={renderSelect}
        options={argentinaProvinces}
        label={capitalize(I18n.get('province', 'Provincia'))}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.value}
        required={props.isElectronic}
        isSearchable={true}
      />

      <Field
        name="address.city"
        className="col-md-6"
        component={renderSelect}
        options={argentinaCities.filter(i => i.idProvince === provinceId)}
        label={capitalize(I18n.get('city', 'Ciudad'))}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.value}
        required={props.isElectronic}
        isSearchable={true}
      />

      <Field
        name="address.postalCode"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('zipCode', 'Código postal'))}
      />

      <Field
        name="address.address"
        className="col-md-6"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
        required={props.isElectronic}
      />
    </>
  )
}

const peruAddress = props => {
  const identification = get(props, 'values.identification.type.key', '');
  const localContact = get(props, 'values.address.country.key', '') === 'PER';
  const foreignId = !(identification === 'RUC' || identification === 'DNI');
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || localContact;

  return (
    <>
      {showCountry && (
        <Field
          name="address.country"
          className="col-12"
          component={renderSelect}
          options={countries}
          label={capitalize(I18n.get('country', 'país'))}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
        />
      )}

      {showCombinedAddress && (
        <Field
          name="address.combined"
          className="col-12"
          component={renderSelect}
          options={peruAddresses}
          label={[
            capitalize(I18n.get('district', 'distrito')),
            capitalize(I18n.get('province', 'provincia')),
            capitalize(I18n.get('department', 'departamento')),
            capitalize(I18n.get('ubigeoCode', 'código ubigeo')),
          ].join(' / ')}
          getOptionLabel={option => option.caption}
          getOptionValue={option => option.caption}
          isSearchable={true}
        />
      )}
      {showCombinedAddress && (
        <Field
          name="address.urbanization"
          className="col-md-6"
          component={renderField}
          type="text"
          label={capitalize(I18n.get('urbanization', 'Urbanización'))}
        />
      )}

      <Field
        className={showCombinedAddress ? 'col-md-6' : 'col-12'}
        name="address.address"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
      />
    </>
  )
}

const dominicanaAddress = props => {
  const identification = get(props, 'values.identification.type.key', '');
  const localContact = get(props, 'values.address.country.key', '') === 'DOM';
  const foreignId = !(identification === 'RNC' || identification === 'CED');
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || localContact;

  return (
    <>
      {showCountry && (
        <Field
          name="address.country"
          className="col-12"
          component={renderSelect}
          options={countries}
          label={capitalize(I18n.get('country', 'país'))}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
        />
      )}

      {showCombinedAddress && (
        <Field
          name="address.combined"
          className="col-12"
          component={renderSelect}
          options={dominicanaAddresses}
          label={[
            capitalize(I18n.get('city', 'municipio')),
            capitalize(I18n.get('province', 'provincia')),
          ].join(' / ')}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          isSearchable={true}
        />
      )}

      <Field
        className={showCombinedAddress ? 'col-md-6' : 'col-12'}
        name="address.address"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
      />
    </>
  )
}

const panamaAddress = (props) => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const kindOfPerson = get(props, 'values.kindOfPerson.key', '');
  const isElectronic = get(props, 'values.isElectronic', '');
  const isRequiredField = () => {
    let isRequired = true;
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (identificationType === 'FINAL_CONSUMER' || identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') isRequired = false;
    return isRequired;
  }
  return (
    <>
      {
        (identificationType !== 'FOREIGN' && identificationType !== 'FOREIGN_COMPANY') && (
          <Field
            name="address.combined"
            component={renderSelect}
            className='col-12'
            options={panamaCities}
            label={[
              capitalize(I18n.get('township', 'Corregimiento')),
              capitalize(I18n.get('district', 'Distrito')),
              capitalize(I18n.get('province', 'Provincia')),
            ].join(' / ')}
            getOptionLabel={option => `${option.value}, ${option.parentValue}, ${option.provinceValue}`}
            getOptionValue={option => option.code}
            isSearchable={true}
            required={isRequiredField()}
          />
        )
      }

      {
        (identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') && (
          <Field
            name="address.country"
            component={renderSelect}
            className="col-md-6"
            options={countries}
            label={capitalize(I18n.get('country', 'país'))}
            getOptionLabel={option => option.value}
            getOptionValue={option => option.key}
            isSearchable={true}
            required={identificationType === 'FOREIGN'}
          />
        )
      }

      <Field
        className={(identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') ? 'col-md-6' : 'col-12'}
        name="address.address"
        component={renderField}
        type="text"
        label={capitalize(I18n.get('address', 'Dirección'))}
        required={isRequiredField()}
      />
    </>
  )
}

const defaultAddress = props => (
  <>
    <Field
      name="address.city"
      className="col-md-6"
      component={renderField}
      type="text"
      label={capitalize(I18n.get('city', 'ciudad'))}
    />
    <Field
      name="address.address"
      className="col-md-6"
      component={renderField}
      type="text"
      label={capitalize(I18n.get('address', 'Dirección'))}
    />
  </>
)

const renderAddress = props => {
  if (props.country === 'costaRica')
    return costaRicaAddress(props);

  if (props.country === 'peru')
    return peruAddress(props);

  if (props.country === 'argentina')
    return argentinaAddress(props);

  if (props.country === 'colombia')
    return colombiaAddress(props);

  if (props.country === 'republicaDominicana')
    return dominicanaAddress(props);

  if (props.country === 'panama')
    return panamaAddress(props);

  if(props.country === 'spain')
    return spainAddress(props);

  return defaultAddress(props);
}

export default renderAddress;