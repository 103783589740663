import React from "react";
import { useSelector } from "react-redux";
import { I18n } from "@aws-amplify/core";
import { capitalize } from "lodash";

import { items as itemsSelector } from "../../../selectors/activeInvoice";
import Item from "./Item";
import SelectItemSVG from "../../svg/SelectItem";
import Typography from "../../common/Typography";

const InvoiceItems = () => {
  const items = useSelector((state) => itemsSelector(state));

  return (
    <div className="h-100 new-active-invoice__items">
      {!!items && items.length > 0 ? (
        items.map((item, index) => (
          <Item key={index} item={item} index={index} />
        ))
      ) : (
        <div className="no-item p-2 w-100 h-100 text-muted text-capitalize-first d-flex flex-column justify-content-center align-items-center text-center">
          <SelectItemSVG className="mb-4" />
          <div className="w-50">
              <Typography
                htmlType="span"
                type="body-2-regular"
                variant="tertiary"
                text={capitalize(
                  I18n.get(
                    "selectProductsToCreateYourInvoice",
                    "selecciona productos para crear tu factura"
                  )
                )}
              />
            </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceItems;
