import React from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import { closeModal, closeAllModals } from '../../../reducers/modals'
import { closeAllSideModals } from '../../../reducers/sideModals'
import { getPlanName } from '../../../selectors/membership'
import { replaceAndParse } from '../../../utils'
import Success from '../../svg/Success'
import Modal from '../../common/Modal';

let PlanLimitReached = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => get(state, 'modals.planLimitReached.isOpen', false));
  const planName = useSelector(getPlanName);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'planLimitReached' }))}
      title=''
      className="modal__plan-limit-reached"
    >
      <div>
        <div className="px-md-5 px-2">
          <div className="px-md-5 mb-3 text-muted h4 text-center">
            {
              replaceAndParse(I18n.get('planLimitReached', 'Superaste los límites de tu {}'), [
                `<span class='text-primary text-uppercase'>${planName}</a>`
              ])
            }
          </div>
          <div className="px-md-5 mb-4  h1 text-primary text-center font-weight-light">{I18n.get('planLimitReachedTitle', '¡Tu negocio sigue creciendo! 🙌')}</div>
          <div className="px-md-5 mb-3 text-muted h3 text-center">
            {I18n.get('planLimitReachedSubtitle', 'Cámbiate a un plan hecho a tu medida para seguir vendiendo fácil y rápido en tu punto de venta con mayores beneficios.')}
          </div>
        </div>

        <div className="modal_content px-md-5 px-2 py-5 d-flex flex-row justify-content-center">
          <div className="left-content">
            <div className="left-content-list h3">
              <div>
                <p className="mb-3 text-muted">
                  <Success className="success-icon" />
                  {capitalize(I18n.get('planLimitReachedDescription1', 'Más ingresos mensuales'))}
                </p>
                <p className="mb-3 text-muted">
                  <Success className="success-icon" />
                  {capitalize(I18n.get('planLimitReachedDescription2', 'Mayor cantidad de usuarios'))}
                </p>
                <p className="mb-3 text-muted">
                  <Success className="success-icon" />
                  {capitalize(I18n.get('planLimitReachedDescription3', 'Más bodegas para tu inventario'))}
                </p>
              </div>
            </div>
            <div className="left-content-button">
              <button
                type="button"
                className="btn btn-submit text-capitalize-first"
                onClick={() => {
                  dispatch(closeAllSideModals());
                  dispatch(closeAllModals());
                  window.open(`${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`, '_blank')
                }}
              >
                {I18n.get('changePlan', 'cambiar mi plan')}
              </button>
            </div>
            <div className="left-content-bottom h5">
              <p>{replaceAndParse(I18n.get('planLimitReachedFooter', '¿Tienes dudas de cuál elegir? <span class="font-weight-bold">Te ayudamos</span> 👉'))}</p>
              <a href="https://www.alegra.com/colombia/pos/contacto/" target="_blank" rel="noreferrer">{I18n.get('contactSupport', 'contactar a soporte')}</a>
            </div>
          </div>
          <div className="right-content">
            <img className="pos-img" src="/screen_pos.png" alt="screen pos" />
          </div>
        </div>

      </div>
    </Modal>
  )
}

export default PlanLimitReached;