// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 18.5001C16.9624 18.5001 14.5 20.9626 14.5 24.0001C14.5 27.0377 16.9624 29.5001 20 29.5001C23.0376 29.5001 25.5 27.0377 25.5 24.0001C25.5 20.9626 23.0376 18.5001 20 18.5001ZM17.5 24.0001C17.5 22.6194 18.6193 21.5001 20 21.5001C21.3807 21.5001 22.5 22.6194 22.5 24.0001C22.5 25.3808 21.3807 26.5001 20 26.5001C18.6193 26.5001 17.5 25.3808 17.5 24.0001Z" fill="#CBD5E1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 0.500122C20.4452 0.500122 20.8674 0.697857 21.1523 1.03985L30.7026 12.5001H38C38.4619 12.5001 38.8981 12.7129 39.1823 13.077C39.4666 13.4411 39.5673 13.9158 39.4552 14.3639L35.4695 30.3067C35.23 31.6988 34.6054 33.0009 33.6401 33.9661C32.6571 34.9492 31.3668 35.529 29.9829 35.5001H10.0171C8.63328 35.529 7.34298 34.9492 6.35992 33.9661C5.39465 33.0009 4.77001 31.6988 4.53048 30.3066L0.5448 14.3639C0.432772 13.9158 0.533445 13.4411 0.817705 13.077C1.10197 12.7129 1.53811 12.5001 2.00001 12.5001H9.29745L18.8477 1.03985C19.1327 0.697857 19.5548 0.500122 20 0.500122ZM3.92118 15.5001L7.45523 29.6363C7.46533 29.6767 7.47374 29.7175 7.48045 29.7586C7.62337 30.6348 7.99978 31.3634 8.48124 31.8448C8.95503 32.3186 9.4823 32.5127 9.96221 32.5006L10 32.5001H30L30.0378 32.5006C30.5177 32.5127 31.045 32.3186 31.5188 31.8448C32.0002 31.3634 32.3767 30.6348 32.5196 29.7586C32.5263 29.7175 32.5347 29.6767 32.5448 29.6363L36.0788 15.5001H3.92118ZM20 4.3432L26.7975 12.5001H13.2026L20 4.3432Z" fill="#CBD5E1" />
  </svg>

)