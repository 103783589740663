import React, { useEffect } from "react";
import { I18n } from "@aws-amplify/core";
import PropTypes from "prop-types";
import { useTable, useBlockLayout, usePagination } from "react-table";

import Loading from "../../svg/Loading";
import Pagination from "./Pagination";

const Table = ({ columns, data, loading, onFetchData, noDataText, error, onRefresh, _pageSize, controlledPageCount }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: !!onFetchData,
      pageCount: !!controlledPageCount ? controlledPageCount : Math.ceil(data.length / _pageSize),
      initialState: { pageSize: _pageSize || 10 },
    },
    useBlockLayout,
    usePagination
  );

  useEffect(() => {
    const fetchData = () => {
      if (!!onFetchData) {
        onFetchData({ pageIndex, pageSize });
      }
    };
    fetchData();
  }, [pageIndex, pageSize, onFetchData]);

  const handleChangeIndex = (event) => {
    const page = event.target.value ? +event.target.value - 1 : 0;
    gotoPage(page);
  };

  return (
    <>
      <div className="react-table w-100 table-responsive position-relative">
        <table {...getTableProps()} className="react-table__table table rounded">
          <thead className="react-table__head shadow">
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} className="w-100" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i} className="react-table__th text-left h4">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={`react-table__body w-100 d-block position-relative ${page.length === 0 ? "no-data" : ""}`} {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} className="react-table__body-tr w-100" {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td key={index} className="react-table__td react-table__body-td" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {loading && (
          <div className="react-table__loading position-absolute d-flex align-items-center justify-content-center h-2 p-3">
            <Loading className="icon-primary icon x2" />
          </div>
        )}

        {!loading && page.length === 0 && (
          <div className="react-table__no-data position-absolute d-flex align-items-center justify-content-center h-2 p-3">{noDataText}</div>
        )}

        {!loading && !data && !!error && (
          <div className="react-table__no-data position-absolute d-flex flex-column align-items-center justify-content-center h-2 p-3">
            <p>{error}</p>

            {!!onRefresh && (
              <button type="button" className="btn btn-submit" onClick={() => onRefresh({ pageIndex, pageSize })}>
                {I18n.get("retry", "reintentar")}
              </button>
            )}
          </div>
        )}
      </div>

      <Pagination
        previousPage={previousPage}
        nextPage={nextPage}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        handleChangeIndex={(e) => handleChangeIndex(e)}
      />
    </>
  );
};

Table.propTypes = {
  colums: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.object),
  onFetchData: PropTypes.func,
  loading: PropTypes.bool,
  noDataText: PropTypes.string,
};

export default Table;
