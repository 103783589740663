import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import BigNumber from 'bignumber.js'

import { calculateItemsValues } from '../../../utils';
import { decimalPrecision } from '../../../selectors/company';
import { getFmt } from '../utils'
import Info from './BodyInfo'
import Items from './Items';
import Payments from './Payments';

const updateItemPrice = (item, values, decimal) => {
  const priceList = get(values, 'priceList')
  const currency = get(values, 'currency')

  let price = get(item, 'originalPrice', 0)

  if (!get(item, 'priceModified') && !!priceList) {
    const itemPriceList = !!get(item, 'priceLists.length')
      ? get(item, 'priceLists').find(list => +get(list, 'idPriceList') === +get(priceList, 'id'))
      : null
    if (!!itemPriceList)
      price = get(itemPriceList, 'price');
  }

  if (!!currency) {
    const exchangeRate = !!get(currency, 'exchangeRate') ? +get(currency, 'exchangeRate') : 1;
    price = new BigNumber(price).dividedBy(new BigNumber(exchangeRate)).decimalPlaces(4).toNumber()
  }

  price = new BigNumber(price).decimalPlaces(decimal).toNumber()

  return { ...item, price }
}

const Body = ({ invoice, ...props }) => {
  const decimal = useSelector(decimalPrecision);

  const fmt = getFmt(null, props.values.currency);
  const itemsValues = calculateItemsValues(props.values.items, decimal);

  return (
    <div className="detail-body mb-4">
      <div className="p-4 position-relative overflow-hidden">
        <Info 
          {...props} 
          invoice={invoice}
          updateItemPrice={(item, values) => updateItemPrice(item, values, decimal)} 
        />

        <Items 
          {...props} 
          invoice={invoice}
          updateItemPrice={(item, values) => updateItemPrice(item, values, decimal)} 
        />

        <div className="d-flex justify-content-between align-items-start flex-wrap">
          <Payments invoice={invoice} {...props} />

          <table className="detail-summary-table rounded-lg mb-4 ml-auto">
            <tbody>
              {/* {(itemsValues.discount.gt(0) || !!itemsValues.taxes.length) && ( */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.subtotal.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {/* {itemsValues.discount.gt(0) && ( */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('discount', 'descuento')}</td>
                <td>{itemsValues.discount.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {/* {itemsValues.discount.gt(0) && ( */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.discSubtotal.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {itemsValues.taxes.map((tax, index) => (
                <tr key={index}>
                  <td>{tax.name}</td>
                  <td>{tax.value.toFormat(decimal, fmt)}</td>
                </tr>
              ))}
              <tr>
                <td className="text-capitalize-first font-weight-bold">{I18n.get('total', 'total')}</td>
                <td className="font-weight-bold">{itemsValues.total.toFormat(decimal, fmt)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {!!props.submitError && (
          <p style={{
            fontSize: '14px',
            fontWeight: '500',
          }} className="text-capitalize-first text-danger">
            {props.submitError}
          </p>
        )}
      </div>
    </div>
  )
}

export default Body