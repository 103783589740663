// eslint-disable-next-line
export default ({ id, className, width, height }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width={width || "24"} 
    height={height || "24"} 
    viewBox="0 0 19 19" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M14.75 14.625H4.25V4.125H9.5V2.625H4.25C3.4175 2.625 2.75 3.3 2.75 4.125V14.625C2.75 15.45 3.4175 16.125 4.25 16.125H14.75C15.575 16.125 16.25 15.45 16.25 14.625V9.375H14.75V14.625ZM11 2.625V4.125H13.6925L6.32 11.4975L7.3775 12.555L14.75 5.1825V7.875H16.25V2.625H11Z"
    />
  </svg>
)