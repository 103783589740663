import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { handleError } from '../utils/errors'
import alegraAPI from './alegraAPI'

const initialState = {
  entities: {},
  ids: [],
  loading: 'loading',
  error: null
}

export const fetchNumerations = createAsyncThunk(
  'numerations/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!preloaded)
        return preloaded

      // ex queries.allNumberTemplates

      const data = await alegraAPI.get('/number-templates', {
        ...params
      })
      return get(data, 'data', null)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'numerations',
  initialState,
  reducers: {
    addNumeration: adapter.addOne,
    updateNumeration: adapter.updateOne,
    removeNumeration: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchNumerations.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchNumerations.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchNumerations.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer, actions } = appSlice;

export const { addNumeration, updateNumeration, removeNumeration } = actions;

export const numerationSelector = adapter.getSelectors(state => state.numerations);
export const loading = state => get(state, 'numerations.loading') === 'loading'
export const error = state => get(state, 'numerations.error')

export default reducer;