function RefreshAlert({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.1097 3.33767C11.1845 3.08925 9.23098 3.48072 7.55012 4.45178C6.43655 5.0951 5.48358 5.96916 4.75009 7.00691V4.99995C4.75009 4.58574 4.41431 4.24995 4.00009 4.24995C3.58588 4.24995 3.25009 4.58574 3.25009 4.99995V8.99995C3.25009 9.41417 3.58588 9.74995 4.00009 9.74995H4.48272C4.49412 9.75021 4.5055 9.75021 4.51686 9.74995H8.00009C8.41431 9.74995 8.75009 9.41417 8.75009 8.99995C8.75009 8.58574 8.41431 8.24995 8.00009 8.24995H5.72541C6.36093 7.22198 7.24361 6.36117 8.30047 5.75061C9.69644 4.94414 11.3189 4.61902 12.9178 4.82534C14.5167 5.03165 16.0035 5.75795 17.149 6.89236C18.2945 8.02677 19.0353 9.50635 19.2572 11.1032C19.3142 11.5135 19.6931 11.7998 20.1033 11.7428C20.5136 11.6858 20.8 11.307 20.743 10.8967C20.4757 8.97399 19.5838 7.19245 18.2045 5.82653C16.8251 4.46061 15.035 3.58609 13.1097 3.33767Z'
      />
      <path
        d='M4.74295 12.8968C4.68594 12.4865 4.30713 12.2001 3.89686 12.2571C3.48658 12.3142 3.20022 12.693 3.25723 13.1032C3.52443 15.026 4.4164 16.8075 5.79572 18.1734C7.17504 19.5393 8.9652 20.4139 10.8904 20.6623C12.8157 20.9107 14.7692 20.5192 16.4501 19.5482C17.5636 18.9049 18.5166 18.0308 19.2501 16.993V19C19.2501 19.4142 19.5859 19.75 20.0001 19.75C20.4143 19.75 20.7501 19.4142 20.7501 19V15C20.7501 14.5858 20.4143 14.25 20.0001 14.25H19.5175C19.5061 14.2497 19.4947 14.2497 19.4833 14.25H16.0001C15.5859 14.25 15.2501 14.5858 15.2501 15C15.2501 15.4142 15.5859 15.75 16.0001 15.75H18.2748C17.6393 16.778 16.7566 17.6388 15.6997 18.2493C14.3037 19.0558 12.6813 19.3809 11.0824 19.1746C9.48347 18.9683 7.99673 18.242 6.85119 17.1076C5.70565 15.9732 4.96487 14.4936 4.74295 12.8968Z'
      />
      <path
        d='M12.0001 8.25C12.4143 8.25 12.7501 8.58579 12.7501 9V12C12.7501 12.4142 12.4143 12.75 12.0001 12.75C11.5859 12.75 11.2501 12.4142 11.2501 12V9C11.2501 8.58579 11.5859 8.25 12.0001 8.25Z'
      />
      <path
        d='M12.0001 14.25C11.5859 14.25 11.2501 14.5858 11.2501 15C11.2501 15.4142 11.5859 15.75 12.0001 15.75H12.0101C12.4243 15.75 12.7601 15.4142 12.7601 15C12.7601 14.5858 12.4243 14.25 12.0101 14.25H12.0001Z'
      />
    </svg>
  );
}

export default RefreshAlert;
