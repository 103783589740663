import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useHistory } from 'react-router-dom';

import { handleError } from '../../../utils/errors'
import { checkFeatureLimit } from '../../../reducers/membership'
import { openModal } from '../../../reducers/modals'
import { hasPermissionTo } from '../../../selectors/auth';
import SellerModal from '../../modals/seller/Seller'
import Tooltip from '../../common/Tooltip';
import Table from '../../common/Table';
import Header from '../common/Header';
import Actions from './Actions'
import alegraAPi from '../../../reducers/alegraAPI';

const Sellers = () => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const ref = useRef(null)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const history = useHistory();

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/settings/sellers',
      'virtualPageTitle' : 'Sellers Settings'
    });

    return () => ref.current = false
  }, []);

  const startLoading = useCallback(() => {
    if (ref.current) {
      setLoading(true);
    }
  }, [])

  const stopLoading = useCallback(() => {
    if (ref.current) {
      setLoading(false);
    }
  }, [])

  const fetchData = useCallback(async ({ pageIndex, pageSize }) => {
    startLoading()
    setError(null)
    try {
      // ex queries.allSellers
      const response = await alegraAPi.get('/sellers', {
        limit: pageSize,
        start: pageIndex * pageSize
      })

      stopLoading()
      if (ref.current)
        setData(get(response, 'data', []))

    } catch (error) {
      stopLoading()
      setError(handleError(error, I18n.get('sellersLoadError', 'Sucedió un error cargando los vendedores')))
    }
    if (ref.current) {
      setTableIndicators({ pageIndex, pageSize })
    }
  }, [stopLoading, startLoading])

  useEffect(() => {
    fetchData({
      pageIndex: 0,
      pageSize: 10,
    })
  }, [fetchData])

  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Header
          title={I18n.get('mySellers', 'mis vendedores')}
          subtitle={
            <p className="h5 text-muted text-capitalize-first">
              {I18n.get('sellersSettingSubtitle', 'Administra los vendedores de tu negocio')}{' '}
              <a className="text-capitalize-first btn-link" href="https://ayuda.alegra.com/es/crea-y-administra-tus-vendedores-desde-el-pos-punto-de-venta" target="_blank" rel="noreferrer">
                {capitalize(I18n.get('seeMore', 'ver más'))}
              </a>
            </p>
          }
          actions={(
            <div className="d-flex">
              <Tooltip
                tooltipActive={!can('add', 'sellers')}
                overlay={(
                  <p className="text-capitalize-first">
                    {I18n.get('userNotAllowed.sellers.add', 'no tienes permisos para agregar vendedores')}
                  </p>
                )}
              >
                <button
                  type="button"
                  disabled={!can('add', 'sellers')}
                  className="btn btn-submit mr-3"
                  onClick={() => dispatch(checkFeatureLimit('sellers', () => dispatch(openModal({ modal: 'seller' }))))}
                >
                  {I18n.get('newSeller', 'Nuevo vendedor')}
                </button>
              </Tooltip>
              <button
                type="button"
                className="btn btn-cancel"
                onClick={() => history.push('/settings')}
              >
                {I18n.get('goBack', 'Volver')}
              </button>
            </div>
          )}
        />

        <div className="card bg-white shadowp-4 rounded-lg">
          <Table
            loading={loading}
            data={data}
            error={error}
            onRefresh={({ pageIndex, pageSize }) => fetchData({ pageIndex, pageSize })}
            noDataText={capitalize(I18n.get('youDontHaveCreatedSellers', 'aún no tienes vendedores creados'))}
            columns={[
              {
                Header: capitalize(I18n.get('name', 'Nombre')),
                sortable: false,
                accessor: 'name',
                Cell: ({ value, row }) => (
                  <div
                    className="text-primary pointer"
                    onClick={() => dispatch(openModal({ modal: 'seller', params: { seller: row.original } }))}
                  >
                    {value}
                  </div>
                )
              },
              {
                Header: capitalize(I18n.get('identification', 'identificación')),
                accessor: 'identification'
              },
              {
                Header: capitalize(I18n.get('observations', 'observaciones')),
                accessor: 'observations'
              },
              {
                Header: capitalize(I18n.get('actions', 'Acciones')),
                id: 'actions',
                sortable: false,
                minWidth: 110,
                maxWidth: 150,
                Cell: ({ row }) => (
                  <Actions
                    seller={row.original}
                    onRefresh={() => fetchData(tableIndicators)}
                    onView={() => dispatch(openModal({ modal: 'seller', params: { seller: row.original } }))}
                    startLoading={startLoading}
                    stopLoading={stopLoading}
                  />
                )
              },
            ]}
          />
        </div>
      </div>

      <SellerModal onRefresh={() => fetchData(tableIndicators)} />
    </div>
  )
}

export default Sellers;