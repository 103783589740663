import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize, isEmpty, find, get } from 'lodash';
import { useSelector } from 'react-redux';

import { normalUsers } from '../../../selectors/users'
import { renderSelect } from '../fields/V0/Fields';

const Users = () => {
    const users = useSelector(normalUsers)
    const options =  [{ idGlobal: -1, name: capitalize(I18n.get('all', 'todos')) }].concat(users);
    const [value, setValue] = useState([]);
    
    const handleChange = values => {
        if (values.length > 1 && values[0].idGlobal === -1)
            values = values.filter(value => value.idGlobal !== -1)
        else { 
            const allSelected = find(values, value => value.idGlobal === -1);
            if (allSelected || isEmpty(values))
                values = [options[0]]
        }

        setValue(values);  
        return values;  
    }
    
    return (
        <div className="form-body__fields form-row justify-content-start">
        <Field
            name="users"
            className="col-md-6 basic-multi-select"
            classNamePrefix="select"
            component={renderSelect}
            label={capitalize(I18n.get('users', 'usuarios'))}
            options={options}
            selectInfo={null}
            getOptionLabel={option => `${!!option.name ? option.name : ''}${!!option.lastName ? ` ${option.lastName}`: ''}`}
            getOptionValue={option => option.idGlobal}
            showClearIndicator={value.length > 1 || get(value, '0.idGlobal', null) !== -1}
            onChange={handleChange}
            menuPosition="absolute"
            isMulti
        />
        </div>
    )
}

export default Users;
