// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 22.814c5.523 0 10-4.478 10-10 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 5.522 4.477 10 10 10zm0-1.91a8.09 8.09 0 1 1 0-16.18 8.09 8.09 0 0 1 0 16.18zm-4.197-9.11v2.024h3.178v3.193h2.04v-3.193h3.176v-2.023H13.02V8.616h-2.039v3.179H7.803z" />
  </svg>
)