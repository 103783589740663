import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, isEmpty, some } from 'lodash';
import { userSelector } from './auth';

export const getProductMembership = (product) => (state) =>
  get(state, `membership.${product}`, null);

export const membershipSelector = (state) =>
  getProductMembership('alegra-pos')(state);
export const companySelector = (state) => get(state, 'auth.company', null);

export const logo = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.logo : null
);

export const name = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.name : null
);

export const email = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.email : null
);

export const country = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.applicationVersion : null
);

export const identificationSelector = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? company.identification : null)
);

export const decimalPrecision = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? +company.decimalPrecision : 0)
);

export const origin = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.origin : null
);

export const address = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.address : null
);

export const currency = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.currency : null
);

export const regime = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.regime : null
);

export const timezone = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.timezone : null
);

export const dateFormat = createDraftSafeSelector(companySelector, (company) =>
  !!company ? company.dateFormat : null
);

export const ivaConditionSelector = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? get(company, 'ivaCondition', null) : null)
);

export const barcodeEnabled = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company ? get(company, 'settings.barcode') === 'active' : false
);

export const hasShowSaleTicketsEnabled = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company ? get(company, 'settings.showSaleTickets') === true : false
);

export const shiftsEnabled = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company ? get(company, 'localSettings.shiftsEnabled', false) : false
);

export const shiftsConfigured = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company ? get(company, 'localSettings.shiftsConfigured', false) : false
);

export const isActiveNoIvaDay = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company
      ? get(company, 'isActiveNoIvaDay', false) &&
        get(company, 'showNoIvaDaysOnItems', false)
      : false
);

export const firstPOSInvoiceSelector = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? get(company, 'firstPOSInvoice', false) : false)
);

export const firstInvoiceCreatedSelector = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? get(company, 'firstInvoiceCreated', false) : false)
);

export const legalRepresentativeSelector = createDraftSafeSelector(
  [companySelector, country],
  (company, country) =>
    country === 'colombia' ? get(company, 'legalRepresentative', null) : null
);

export const mandateSignatureURLSelector = createDraftSafeSelector(
  [companySelector, country],
  (company, country) =>
    country === 'colombia' ? get(company, 'mandateSignatureUrl', null) : null
);

export const printSettings = createDraftSafeSelector(
  companySelector,
  (company) => {
    if (!company) return null;

    const format = get(company, 'localSettings.printFormat', '80').match(
      /\d+/g
    )[0];
    return {
      template: get(company, 'localSettings.printTemplate', 'classic'),
      align: get(company, 'localSettings.printAlignment', 'left'),
      description: get(company, 'localSettings.ticketItemsDescription', false),
      unitPrice: get(company, 'localSettings.ticketUnitPrice', false),
      logo: get(company, 'localSettings.ticketCompanyLogo', true),
      format: format,
      leftMargin: get(company, 'localSettings.printLeftMargin', 2),
      rightMargin: get(company, 'localSettings.printRightMargin', 2),
      customPhrase: get(company, 'localSettings.customPhrase', ''),
      imageOrQr: get(company, 'localSettings.imageOrQr', ''),
      printItemFullLine: get(company, 'localSettings.printItemFullLine', false),
      unitOfMeasure: get(company, 'localSettings.printUnitOfMeasure', false),
      totalLines: get(company, 'localSettings.printTotalLines', true),
      taxesIncludedInTotal: get(
        company,
        'localSettings.taxesIncludedInTotal',
        true
      ),
    };
  }
);

export const localSettings = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company
      ? {
          idCategoryDefaultIncome: get(
            company,
            'localSettings.idCategoryDefaultIncome',
            '5065'
          ),
          idCategoryDefaultExpense: get(
            company,
            'localSettings.idCategoryDefaultExpense',
            '5093'
          ),
          shiftsEnabled: get(company, 'localSettings.shiftsEnabled', false),
          multicurrencyEnabled: get(
            company,
            'localSettings.multicurrencyEnabled',
            false
          ),
          automationFEEnabled: get(
            company,
            'localSettings.automationFEEnabled',
            false
          ),
          showReminder5UVT: get(
            company,
            'localSettings.showReminder5UVT',
            false
          ),
          showWompiIntegrationAvailable: get(
            company,
            'localSettings.showWompiIntegrationAvailable',
            false
          ),
          showNumerationBlockNotification: get(
            company,
            'localSettings.showNumerationBlockNotification',
            false
          ),
          seenTutorialWizardPOS: get(
            company,
            'localSettings.seenTutorialWizardPOS',
            false
          ),
        }
      : null
);

export const settings = createDraftSafeSelector(companySelector, (company) =>
  !!company ? get(company, 'settings', {}) : null
);

export const oldNotificationsSelector = createDraftSafeSelector(
  companySelector,
  (company) => {
    let notifications = [];

    try {
      notifications = !!company
        ? JSON.parse(JSON.parse(get(company, 'localSettings.notifications')))
        : [];
    } catch {}
    return notifications;
  }
);

export const notificationsSelector = createDraftSafeSelector(
  [companySelector, oldNotificationsSelector],
  (company, oldNotifications) => {
    if (
      !company ||
      (isEmpty(oldNotifications) &&
        !get(company, 'userNotifications.notifications'))
    )
      return [];

    let notifications = oldNotifications;
    try {
      const notificationsArray = JSON.parse(
        JSON.parse(get(company, 'userNotifications.notifications'))
      );
      notifications = notifications.concat(
        notificationsArray.map((n) => JSON.parse(n))
      );
    } catch (error) {
      console.log(error);
    }
    return notifications;
  }
);

export const electronicInvoicing = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company ? get(company, 'settings.electronicInvoicing', false) : false
);

export const termsAndConditions = createDraftSafeSelector(
  companySelector,
  (company) =>
    !!company
      ? get(company, 'invoicePreferences.defaultTermsAndConditions', false)
      : false
);

export const anotation = createDraftSafeSelector(companySelector, (company) =>
  !!company ? get(company, 'invoicePreferences.defaultAnotation', false) : false
);

export const multitax = createDraftSafeSelector(companySelector, (company) =>
  !!company
    ? get(company, 'multitax', false) &&
      get(company, 'applicationVersion') !== 'mexico'
    : false
);

export const multicurrency = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? get(company, 'multicurrency', false) : false)
);

export const idCompanySelector = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? get(company, 'id', false) : false)
);

export const isOnlyInvoicingPlan = createDraftSafeSelector(
  [country, membershipSelector],
  (country, membership) => {
    return country === 'colombia' && !!get(membership, 'plan.only-invoicing');
  }
);

export const externalProviders = createDraftSafeSelector(
  companySelector,
  (company) => (!!company ? JSON.parse(get(company, 'providers', null)) : null)
);

export const isExternalPaymentsActive = createDraftSafeSelector(
  [externalProviders, country],
  (providers, country) =>
    country === 'colombia' && !!providers
      ? some(Object.values(providers), ['state', 'active'])
      : false
);

export const externalProviderState = (provider) =>
  createDraftSafeSelector([externalProviders], (providers) =>
    !!providers ? get(providers, `${provider}.state`) : null
  );

export const featureLimitReached = createDraftSafeSelector(
  companySelector,
  membershipSelector,
  (company, membership) => (feature) => {
    const include = get(
      get(membership, 'features', []).filter(
        (f) => get(f, 'keyword') === feature
      ),
      '0.include',
      true
    );
    if (!include) return !include;

    const quantitative = get(
      get(membership, 'features', []).filter(
        (f) => get(f, 'keyword') === feature
      ),
      '0.quantitative',
      false
    );
    if (quantitative) return !include;

    const currentAmount = get(company, 'monthIncome.income', 0);
    const limit = get(
      get(membership, 'features', []).filter(
        (f) => get(f, 'keyword') === feature
      ),
      '0.maxQuantity',
      -1
    );

    return limit > 0 && currentAmount >= limit;
  }
);

export const baseEventParameters = createDraftSafeSelector(
  [companySelector, membershipSelector, userSelector],
  (company, membership, user) => {
    if (!company) return null;

    return {
      idCompany: get(company, 'id', null),
      email: get(user, 'email', null),
      idProduct: '4',
      planName: get(membership, 'plan.name', null),
      registryDate: get(company, 'registryDate', null),
      appVersion: get(company, 'applicationVersion', null),
      isPaying: get(membership, 'pay', null),
      regime: get(company, 'regime', null),
      sector: get(company, 'sector', null),
      employeesNumber: get(company, 'employeesNumber', null),
    };
  }
);

export const baseNewEventParameters = createDraftSafeSelector(
  [companySelector, membershipSelector, userSelector],
  (company, membership, user) => {
    if (!company) return null;

    return {
      idCompany: get(company, 'id', null),
      email: get(user, 'email', null),
      posPlanName: get(membership, 'plan.name', null),
      registryDate: get(company, 'registryDate', null),
      appVersion: get(company, 'applicationVersion', null),
      isPaying: get(membership, 'pay', null),
      regime: get(company, 'regime', null),
      sector: get(company, 'sector', null),
      employeesNumber: get(company, 'employeesNumber', null),
      parity:
        get(company, 'id', null) && Number(get(company, 'id', null)) % 2 === 0
          ? 'even'
          : 'odd',
    };
  }
);

export const baseCompanyParameters = createDraftSafeSelector(
  [companySelector, membershipSelector, userSelector],
  (company, membership, user) => {
    if (!company) return null;
    
    return {
      idProduct: '4',
      productKey: 'alegra-pos',
      idCompany: get(company, 'id', null),
      idUser: get(user, 'id', null),
      uuid:  get(company, 'uuid', null),
      email: get(user, 'email', null),
      appVersion: get(company, 'applicationVersion', null),
      isPaying: get(membership, 'pay', null),
      regime: get(company, 'regime', null),
      sector: get(company, 'sector', null),
      planName: get(membership, 'plan.name', null),
      employeesNumber: get(company, 'employeesNumber', null),
      parity:
        get(company, 'id', null) && Number(get(company, 'id', null)) % 2 === 0
          ? 'even'
          : 'odd',
      kindOfPerson:  get(company, 'kindOfPerson', null),
      profile:  get(user, 'profile', null),
      role:  get(user, 'role', null),
    };
  }
);

export const isTaxesResponsable = createDraftSafeSelector(
  [country, regime],
  (country, regime) => {
    switch (country) {
      case 'colombia':
        return !(
          regime === 'No responsable de IVA' ||
          regime === 'No responsable de INC'
        );

      default:
        return false;
    }
  }
);
