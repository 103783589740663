// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="48" 
    height="48" 
    viewBox="0 0 40 30" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" clipRule="evenodd"
      d="M 1.81818 5.90015 H 38.1818 V 2.27515 H 1.81818 V 5.90015 Z M 1.81818 27.6501 H 38.1818 V 13.1501 H 1.81818 V 27.6501 Z M 38.1818 0.462646 H 1.81818 C 0.814546 0.462646 0 1.27465 0 2.27515 V 27.6501 C 0 28.6516 0.814546 29.4626 1.81818 29.4626 H 38.1818 C 39.1855 29.4626 40 28.6516 40 27.6501 V 2.27515 C 40 1.27465 39.1855 0.462646 38.1818 0.462646 Z M 6.36365 24.0251 H 19.0909 C 19.5927 24.0251 20 23.6201 20 23.1189 C 20 22.6186 19.5927 22.2126 19.0909 22.2126 H 6.36365 C 5.86183 22.2126 5.45456 22.6186 5.45456 23.1189 C 5.45456 23.6201 5.86183 24.0251 6.36365 24.0251 Z M 30 24.0251 H 33.6364 C 34.1382 24.0251 34.5455 23.6201 34.5455 23.1189 V 19.4939 C 34.5455 18.9936 34.1382 18.5876 33.6364 18.5876 H 30 C 29.4982 18.5876 29.0909 18.9936 29.0909 19.4939 V 23.1189 C 29.0909 23.6201 29.4982 24.0251 30 24.0251 Z M 6.36365 20.4001 H 22.7273 C 23.2291 20.4001 23.6364 19.9951 23.6364 19.4939 C 23.6364 18.9936 23.2291 18.5876 22.7273 18.5876 H 6.36365 C 5.86183 18.5876 5.45456 18.9936 5.45456 19.4939 C 5.45456 19.9951 5.86183 20.4001 6.36365 20.4001 Z"
    />
  </svg>
)