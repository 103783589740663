import Cookie from 'js-cookie';
import http from '../utils/http';

const baseUrl = process.env.REACT_APP_ALEGRA_ENDPOINT;
const baseConfig = {
  headers: {
    'X-Data-Source': 'pos',
    Authorization: `Basic ${Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE)}`,
  }
};

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, Object.assign({}, baseConfig, userConfig));

const post = async (url, params = null, urlParams = null, userConfig = null) => await http.post(`${baseUrl}${url}`, params, urlParams, Object.assign({}, baseConfig, userConfig));

const put = async (url, params = null, urlParams = null, userConfig = null) => await http.put(`${baseUrl}${url}`, params, urlParams, Object.assign({}, baseConfig, userConfig));

const httpDelete = async (url, urlParams = null, userConfig = null) => await http.delete(`${baseUrl}${url}`, urlParams, Object.assign({}, baseConfig, userConfig));

const defaultExport = {
  get,
  post,
  put,
  delete: httpDelete,
}

export default defaultExport