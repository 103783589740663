import React, { useRef, useEffect } from 'react';
import PropsTypes from 'prop-types';
import { openModal } from '../../reducers/modals';
import { setNotificationContent } from '../../reducers/notificationsWC';
import { WebComponents } from '../../selectors/notificationsWC';
import { useDispatch } from 'react-redux';
import { handleNotificationEvent } from './utils';

const NotificationBox = ({ userId, isMobile = false, customClassName }) => {
  let ref = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ref.current?.shadowRoot.getElementById('notifications-box')) {
      handleNotificationEvent(ref, '#5uvtsettings', () =>
        dispatch(openModal({ modal: 'activate5UVT' }))
      );
      handleNotificationEvent(ref, '#activateFETutorial', () =>
        dispatch(openModal({ modal: 'electronicPayrollTutorial' }))
      );
    }
  }, [ref.current?.shadowRoot.lastChild.innerText, dispatch]);

  useEffect(() => {
    const checkContent = () => {
      const hasContent =
        !!ref.current?.shadowRoot.getElementById('notifications-box') &&
        !!ref.current?.shadowRoot.getElementById('notifications-box').innerText;
      dispatch(setNotificationContent({ webcomponent: WebComponents.componentBox, hasContent }))
    };
    checkContent();

    const observer = new MutationObserver(checkContent);
    const targetNode = ref.current?.shadowRoot;

    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    return () => {
      if (targetNode) {
        observer.disconnect();
      }
    };
  }, [ref, dispatch])

  return (
    <div className={customClassName ? customClassName : 'position-relative'}>
      <notifications-box
        class={!isMobile ? 'located-notification-webcomponent' : ''}
        app_env={
          process.env.REACT_APP_BRANCH === 'master'
            ? 'production'
            : 'testing'
        }
        application='alegra-pos'
        user_id={userId}
        section='index-pos-home'
        ref={ref}
      />
    </div>
  );
};

NotificationBox.propTypes = {
  userId: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
  isMobile: PropsTypes.bool,
};

export default NotificationBox;
