import { I18n, Hub } from '@aws-amplify/core'
import { FORM_ERROR } from 'final-form'
import { get, capitalize, isString, toUpper } from 'lodash'

export const handleError = (error, defaultMessage = '', country) => {
  let message = defaultMessage
  try {
    if (!!get(error, 'errors.0.message')) {
      if (get(error, 'errors.0.errorType') === 'Lambda:IllegalArgument')
        message = I18n.get('imageTooLargeError', 'Ocurrió un error al recibir el archivo, verifica que el tamaño de la imagen sea menor que 2MB')
      else
        message = get(error, 'errors.0.message')
    }

    if (!!get(error, 'message'))
      message = get(error, 'message')

    if (!!get(error, 'request.responseText'))
      message = get(JSON.parse(error.request.responseText), 'message')

    if (!!get(error, 'error.message'))
      message = get(error, 'error.message')

    if (!!isString(error)) {
      message = error
      if (message === 'Ya existe una factura con el número ingresado' && country === 'republicaDominicana')
        message = I18n.get('invoiceNumberError', "Ya hay una factura con el número de este comprobante, conoce cómo <a href='https://app.powerbi.com/view?r=eyJrIjoiZDdmNTBlYTktYmE0Ni00NGM3LWJhNzYtMGZhNjBlNTQyYTdmIiwidCI6IjYxOGQwYTQ1LTI1YTYtNDYxOC05ZjgwLThmNzBhNDM1ZWU1MiJ9' target='_blank' rel='noreferrer'><b>editar la numeración actual</b></a> o selecciona otra antes de vender");
    }

    if (message === 'Network Error') {
      message = capitalize(I18n.get('networkErrorMessage', 'verifica tu conexión e intente de nuevo'))
      Hub.dispatch('network', {
        event: 'status',
        data: { status: 'offline' },
        message: ''
      });
    }

    if (toUpper(message).includes('TIMEOUT') || toUpper(message).includes('TIMED OUT') || get(error, 'errors.0.errorType') === 'Runtime.ExitError')
      message = capitalize(I18n.get('runtimeErrorMessage', 'por favor, intente de nuevo'))
  } catch (err) {
    console.log("err", err);
  }

  return message
}

export const formError = (error, defaultMessage = '', country) => ({ [FORM_ERROR]: handleError(error, defaultMessage, country) })