import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash'

import { calculateSingleItemValues } from '../../../../utils'
import { removeItem, increaseItem, decreaseItem } from '../../../../reducers/editInvoice';
import { itemQuantity, currency } from '../../../../selectors/editInvoice';
import { decimalPrecision } from '../../../../selectors/company';
import { getMainCurrency } from '../../../../selectors/currencies'
import TrashSVG from '../../../svg/Trash';
import MinusSVG from '../../../svg/Minus';
import PlusSVG from '../../../svg/Plus';

const Item = ({ item, index, openEditItem }) => {
  const itemRef = useRef()
  const dispatch = useDispatch();
  const quantity = useSelector(itemQuantity(item.id));
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  useEffect(() => {
    itemRef.current.scrollIntoView({
      behavior: 'smooth', block: 'nearest'
    });
  }, [])

  const itemValues = calculateSingleItemValues(item, decimal)

  return (
    <div
      className="active-refund__item w-100 d-flex align-items-center justify-content-between py-3 pl-3"
      onClick={() => openEditItem(index)}
      ref={itemRef}
    >
      <div className="d-flex flex-column text-break text-truncate text-nowrap active-refund__item-data">
        <div className="active-refund__item-name">{item.name}</div>
        <div className="d-flex text-break text-truncate text-nowrap">
          <div className="active-refund__item-price">
            {itemValues.price.toFormat(decimal, fmt)}
          </div>
          <div className="text-primary active-refund__item-reference ml-2">
            {!!get(item, 'reference.reference')
              ? get(item, 'reference.reference') : !!get(item, 'reference')
                ? get(item, 'reference') : ''
            }
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center active-refund__item-actions">
        <button
          type="button"
          disabled={quantity <= 1}
          className="btn button-transparent btn-sm rounded-circle shadow d-flex justify-content-center align-items-center"
          onClick={event => {
            event.stopPropagation();
            dispatch(decreaseItem(index))
          }}
        >
          <MinusSVG className="icon-gray" />
        </button>
        <div className="rounded-circle d-flex justify-content-center align-items-center text-primary active-refund__item-quantity">
          {itemValues.quantity}
        </div>
        <button
          type="button"
          className="btn button-transparent btn-sm rounded-circle shadow d-flex justify-content-center align-items-center"
          onClick={event => {
            event.stopPropagation();
            dispatch(increaseItem(index))
          }}
        >
          <PlusSVG className="icon-gray" />
        </button>
      </div>

      <div className="d-flex flex-column justify-content-around align-items-end active-refund__item-total-price">
        <div className="text-right text-truncate text-nowrap">
          {itemValues.subtotal.toFormat(decimal, fmt)}
        </div>

        {!!itemValues.discountValue && itemValues.discountValue.gt(0) && (
          <div className="text-right text-truncate text-nowrap text-muted">
            -{itemValues.discountValue.toFormat(decimal, fmt)}
          </div>
        )}
      </div>

      <button
        type="button"
        className="active-refund__item-delete btn btn-sm border-secondary rounded-left d-flex justify-content-center align-items-center p-0"
        onClick={event => {
          event.stopPropagation();
          dispatch(removeItem(index))
        }}
      >
        <TrashSVG className="icon-secondary" />
      </button>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.any.isRequired
}

export default Item;

