import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { capitalize, get, isNull } from 'lodash';
// import { useHistory } from 'react-router-dom';

import { handleError } from '../../../utils/errors'
import { openModal } from '../../../reducers/modals'
import { hasPermissionTo } from "../../../selectors/auth";
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';

import Table from "../../common/GrayTable";
import Header from '../../settings/common/Header';
import Actions from '../Categories/Actions';
import Tooltip from "../../common/Tooltip";
import Plus from '../../svg/Plus';
import { useItemCategoriesQuery } from './queries';
import { Filters as FiltersClass } from '../../../utils/filter';
import { useDeleteItemCategory, useUpdateItemCategory } from './mutations';
import { useIsMutating } from '@tanstack/react-query';

const filtersInstance = new FiltersClass({
  metadata: true,
  order_field: 'name',
  order_direction: 'ASC',
  fields: 'editable,deletable'
})

const Categories = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const ref = useRef(null);
  const [error, setError] = useState();
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    ...filtersInstance.getFilters()
  })
  filtersInstance.setUpdater(setFilters);

  const { mutate: deleteItemCategory } = useDeleteItemCategory();
  const { mutate: updateItemCategory } = useUpdateItemCategory();


  const isMutating = useIsMutating();

  const parseRow = row => {
    return {
      itemCategory: {
        id: row.original.id,
        description: row.original.description,
        name: row.original.name,
        status: row.original.status,
        imageSrc: isNull(get(row, 'original.image.url', null)) ? null : get(row, 'original.image.url'),
        channel: row.original.channel,
      }

    }
  }

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageView',
      'virtualPageURL': '/inventory/categories',
      'virtualPageTitle': 'Categories',
    });

    return () => ref.current = false;
  }, []);

  const { data, isFetching, refetch, error: queryError, isError } = useItemCategoriesQuery(filters);

  useEffect(() => {
    if (isError)
      setError(handleError(queryError, I18n.get('categoriesLoadError', 'Sucedió un error cargando las categorías')))
  }, [queryError, isError])

  useEffect(() => {
    filtersInstance.updatePagination(tableIndicators);
  }, [tableIndicators])

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('name', 'Nombre')),
        sortable: false,
        accessor: 'name',
        Cell: ({ value, row }) => {
          return (
            <p
              className="h4 text-primary text-truncate pointer styled-hover"
              onClick={() => (can("edit", "categories") && get(row, 'original.editable', false)) ? dispatch(openModal({
                modal: 'itemCategory', params: {
                  ...parseRow(row),
                  onRefresh: () => refetch(),
                },
              })) : null}
              hovercontent={value}
            >
              {value}
            </p>
          )
        }
      },
      {
        Header: capitalize(I18n.get('description', 'Descripción')),
        accessor: 'description',
        Cell: ({ value }) => (
          <p className="h5 text-muted text-truncate">
            {value}
          </p>
        )
      },
      {
        Header: capitalize(I18n.get('actions', 'Acciones')),
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => (
          <Actions
            itemCategory={row.original}
            onRefresh={() => refetch()}
            onView={() => dispatch(openModal({
              modal: 'itemCategory', params: {
                ...parseRow(row),
                onRefresh: () => refetch(),
              },
            }))}
            deleteItemCategory={deleteItemCategory}
            updateItemCategory={updateItemCategory}
          />
        )
      },

    ], [refetch, dispatch, can, deleteItemCategory, updateItemCategory]
  )

  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Header
          title={<p className="text-title-secundary">{I18n.get('categories', 'Categorías')}</p>}
          subtitle={
            <p className="h5 text-muted text-capitalize-first">
              {I18n.get('categoriesSubtitle', 'Crea categorías para agrupar tus productos según su clase o tipo.')}
              <a href="https://ayuda.alegra.com/es/crea-categor%C3%ADas-y-clasifica-tus-productos-en-el-sistema-de-punto-de-venta-pos" target="_blank" rel="noreferrer">
                {I18n.get('knowMore', 'Saber más')}
              </a>
            </p>
          }
          actions={(
            <div className="d-flex">
              <Tooltip
                tooltipActive={!can("add", "categories")}
                overlay={<p className="text-capitalize-first">
                  {I18n.get("userNotAllowed.categories.add", "no tienes permisos para agregar categorías")}
                </p>}
              >
                <button
                  type="button"
                  disabled={!can("add", "categories") || !connectionStatus}
                  className="btn btn-submit-large d-flex justify-content-center align-items-center"
                  onClick={() => {
                    dispatch(openModal({
                      modal: 'itemCategory', params: {
                        onRefresh: () => refetch(),
                      }
                    }))
                  }}
                >
                  <Plus className="icon icon-white mr-2" />
                  {I18n.get('newCategory', 'Nueva categoria')}
                </button>
              </Tooltip>
            </div>
          )}
        />
        <div className="shadowp-4">
          <Table
            loading={isFetching || Boolean(isMutating)}
            data={data?.data ?? []}
            total={data?.metadata?.total || 0}
            error={error}
            onRefresh={refetch}
            columns={columns}
            onFetchData={setTableIndicators}
            _pageSize={tableIndicators.pageSize}
            noDataText={(
              <div className="text-center">
                <p>
                  {capitalize(I18n.get('categoriesNoDataText', 'Crea tu primera categoría fácil y rápido. 🚀'))}
                </p>

                <Tooltip
                  tooltipActive={!can("add", "categories")}
                  overlay={<p className="text-capitalize-first">
                    {I18n.get("userNotAllowed.categories.add", "no tienes permisos para agregar categorías")}
                  </p>}
                >
                  <button
                    type="button"
                    className="btn btn-submit mr-3"
                    onClick={() => dispatch(openModal({
                      modal: 'itemCategory', params: {
                        onRefresh: () => refetch(),
                      }
                    }))}
                  >
                    {I18n.get('newCategory', 'Nueva categoria')}
                  </button>
                </Tooltip>
              </div>
            )}
          />
        </div>
      </div>

    </div>
  )
}

export default Categories;