import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get, set } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'

import { openModal } from '../../../reducers/modals'
import { checkFeatureLimit } from '../../../reducers/membership'
import { hasPermissionTo } from '../../../selectors/auth'
import { activeCurrencies } from '../../../selectors/currencies'
import { country as countrySelector } from '../../../selectors/company';
import { decimalsVersionsGroup } from '../../../selectors/deployGroups';
import { renderField, renderSelect } from '../fields/V0/Fields';

const InvoiceSettings = ({ values }) => {
  const can = useSelector(hasPermissionTo)
  const dispatch = useDispatch()
  const currencies = useSelector(activeCurrencies)
  const country = useSelector(countrySelector)

  function maxDecimals(e, key, decimals = 6) {
    if (!decimalsVersionsGroup.includes(country))
      return e.target.value;

    const newButton = e.nativeEvent.data;
    set(values, key, !get(values, key) ? "" : get(values, key));
    e.target.value = !e.target.value ? "" : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return get(values, key);
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = get(values, key);
        return get(values, key);
      }
    }

    if (isNaN(newButton)) {
      e.target.value = get(values, key);
      return get(values, key);
    }
  }

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        <Field
          name="currency"
          className="col-12"
          component={renderSelect}
          options={!can('index', 'currencies') ? [] : currencies}
          selectInfo={!can('index', 'currencies')
            ? I18n.get('userNotAllowed.currencies.view', 'no tienes permisos para ver las monedas')
            : null}
          hideNoOptionMessage={!can('index', 'currencies')}
          getOptionLabel={option => option.code}
          getOptionValue={option => option.code}
          label={capitalize(I18n.get('currency', 'moneda'))}
          addOptionText={capitalize(I18n.get('newCurrency', 'Nueva moneda'))}
          addOptionAction={() => dispatch(checkFeatureLimit('multicurrency', () => dispatch(openModal({ modal: 'currency' }))))}
        />

        <Field
          name="currency.exchangeRate"
          className="col-12"
          component={renderField}
          type="number"
          onChange={e => maxDecimals(e, 'currency.exchangeRate', 8)}
          disabled={!get(values, 'currency.status')}
          label={capitalize(I18n.get('exchangeRate', 'tasa de cambio'))}
        />
      </div>
    </div>
  )
}

export default InvoiceSettings;