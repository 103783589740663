import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, deburr, lowerCase } from 'lodash';

import { regimesSelector } from '../reducers/regimes'
import { regime } from './company'

export const regimes = createDraftSafeSelector(
  regimesSelector.selectAll,
  regimes => !!regimes ? regimes: []
)

export const getCompanyRegime = createDraftSafeSelector(
  [regimes, regime],
  (regimes, regime) => !!regimes
    ? regimes.find(r => lowerCase(deburr(get(r, 'value', ''))) === lowerCase(deburr(regime)))
    : null
)