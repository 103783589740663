// eslint-disable-next-line
export default ({ id, className, fill, width, height }) => (
  <svg
    id={id}
    className={className}
    width={width ?? "14"}
    height={height ?? "14"}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.37609 0.577211C1.44256 0.5539 1.5125 0.541992 1.58293 0.541992H12.4163C12.4867 0.541992 12.5566 0.5539 12.6231 0.577211C12.8331 0.65087 13.0235 0.771624 13.1797 0.930221C13.3358 1.08882 13.4536 1.28106 13.524 1.4922C13.5944 1.70334 13.6155 1.9278 13.5857 2.14837C13.556 2.36894 13.4761 2.57977 13.3523 2.76471C13.3351 2.79031 13.3161 2.81461 13.2954 2.83741L9.29127 7.24195V12.8337C9.29127 13.0704 9.15752 13.2868 8.94578 13.3927C8.73404 13.4985 8.48065 13.4757 8.29127 13.3337L4.95793 10.8337C4.80056 10.7156 4.70793 10.5304 4.70793 10.3337V7.24195L0.703805 2.83741C0.683079 2.81461 0.664068 2.79031 0.646926 2.76471C0.5231 2.57977 0.443248 2.36894 0.413487 2.14837C0.383726 1.9278 0.404845 1.70335 0.475227 1.4922C0.54561 1.28105 0.663387 1.08882 0.819537 0.930221C0.975687 0.771623 1.16607 0.65087 1.37609 0.577211ZM1.72696 1.79199C1.72114 1.79675 1.71557 1.80182 1.71027 1.8072C1.68796 1.82986 1.67114 1.85732 1.66108 1.88749C1.65103 1.91765 1.64801 1.94971 1.65226 1.98122C1.65513 2.00247 1.66125 2.02308 1.67035 2.04236L5.7954 6.5799C5.89998 6.69495 5.95793 6.84485 5.95793 7.00032V10.0212L8.04127 11.5837V7.00032C8.04127 6.84485 8.09922 6.69495 8.2038 6.5799L12.3288 2.04236C12.338 2.02308 12.3441 2.00247 12.3469 1.98122C12.3512 1.94972 12.3482 1.91765 12.3381 1.88749C12.3281 1.85732 12.3112 1.82986 12.2889 1.8072C12.2836 1.80182 12.2781 1.79675 12.2722 1.79199H1.72696Z"
      fill={fill ?? "#0F172A"}
    />
  </svg>
)