function Seller ({id, className}) {
  return (
    <svg 
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.3335 1.875C6.14737 1.875 4.37516 3.64721 4.37516 5.83333C4.37516 8.01946 6.14737 9.79167 8.3335 9.79167C10.5196 9.79167 12.2918 8.01946 12.2918 5.83333C12.2918 3.64721 10.5196 1.875 8.3335 1.875ZM5.62516 5.83333C5.62516 4.33756 6.83772 3.125 8.3335 3.125C9.82927 3.125 11.0418 4.33756 11.0418 5.83333C11.0418 7.3291 9.82927 8.54167 8.3335 8.54167C6.83772 8.54167 5.62516 7.3291 5.62516 5.83333Z" fill="#334155"/>
      <path d="M4.75175 13.9183C5.25966 13.4103 5.94853 13.125 6.66683 13.125H10.0002C10.7185 13.125 11.4073 13.4103 11.9152 13.9183C12.4232 14.4262 12.7085 15.115 12.7085 15.8333V17.5C12.7085 17.8452 12.9883 18.125 13.3335 18.125C13.6787 18.125 13.9585 17.8452 13.9585 17.5V15.8333C13.9585 14.7835 13.5415 13.7767 12.7991 13.0344C12.0568 12.292 11.05 11.875 10.0002 11.875H6.66683C5.61701 11.875 4.6102 12.292 3.86786 13.0344C3.12553 13.7767 2.7085 14.7835 2.7085 15.8333V17.5C2.7085 17.8452 2.98832 18.125 3.3335 18.125C3.67867 18.125 3.9585 17.8452 3.9585 17.5V15.8333C3.9585 15.115 4.24384 14.4262 4.75175 13.9183Z" fill="#334155"/>
      <path d="M17.0835 4.79167V5H18.1252C18.4703 5 18.7502 5.27982 18.7502 5.625C18.7502 5.97018 18.4703 6.25 18.1252 6.25H16.0418C15.8761 6.25 15.7171 6.31585 15.5999 6.43306C15.4827 6.55027 15.4168 6.70924 15.4168 6.875C15.4168 7.04076 15.4827 7.19973 15.5999 7.31694C15.7171 7.43415 15.8761 7.5 16.0418 7.5H16.8752C17.3724 7.5 17.8494 7.69754 18.201 8.04917C18.5526 8.40081 18.7502 8.87772 18.7502 9.375C18.7502 9.87228 18.5526 10.3492 18.201 10.7008C17.8988 11.003 17.5041 11.1914 17.0835 11.2384V11.4583C17.0835 11.8035 16.8037 12.0833 16.4585 12.0833C16.1133 12.0833 15.8335 11.8035 15.8335 11.4583V11.25H14.7918C14.4467 11.25 14.1668 10.9702 14.1668 10.625C14.1668 10.2798 14.4467 10 14.7918 10H16.8752C17.0409 10 17.1999 9.93415 17.3171 9.81694C17.4343 9.69973 17.5002 9.54076 17.5002 9.375C17.5002 9.20924 17.4343 9.05027 17.3171 8.93306C17.1999 8.81585 17.0409 8.75 16.8752 8.75H16.0418C15.5445 8.75 15.0676 8.55246 14.716 8.20082C14.3644 7.84919 14.1668 7.37228 14.1668 6.875C14.1668 6.37772 14.3644 5.90081 14.716 5.54917C15.0182 5.247 15.4129 5.05862 15.8335 5.0116V4.79167C15.8335 4.44649 16.1133 4.16667 16.4585 4.16667C16.8037 4.16667 17.0835 4.44649 17.0835 4.79167Z" fill="#334155"/>
    </svg>
  )
}

export default Seller