import React, { useEffect } from 'react'
import { graphqlOperation } from 'aws-amplify'
import { I18n } from '@aws-amplify/core'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'

import * as queries from '../../graphql/queries'
import alegraAPI from '../../reducers/alegraAPI'
import notificationsAPI from '../../reducers/notificationsAPI'
import { userSelector } from '../../selectors/auth'
import { country as countrySelector } from '../../selectors/company'
import { APIGraphqlSelector } from '../../selectors/app'

const UserWithPendingInvoicesNotification = () => {
  const user = useSelector(userSelector)
  const country = useSelector(countrySelector)
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch()

  useEffect(() => {
    async function checkUserHasPendingInvoices() {
      if (!["colombia", "republicaDominicana", "argentina", "costaRica", "panama"].includes(country))
        return;

      const cookieName = "userHasPendingInvoices"
      const cookieValue = Cookies.get(cookieName)
      const cookieDate = !!cookieValue ? cookieValue : null;
      const sendNotification = !cookieDate ? true : dayjs().isAfter(parseInt(cookieDate))

      if (!sendNotification) return;
      try {
        const { data } = await alegraAPI.get('/invoices/pending-emission?limitDays=10');
        if (data.invoices.length > 0) {
          const response = await APIGraphql(graphqlOperation(queries.checkCreationInPOS, {
            invoicesIDs: data.invoices.join(',')
          }))
          if (response.data.checkCreationInPOS !== "[]")
            try {
              Cookies.set(cookieName, dayjs().add(1, 'day').valueOf());

              notificationsAPI.post('/notifications',
                notificationBody({
                  userId: user.idGlobal,
                  title: I18n.get('youHavePendingInvoicesToEmit', '¡Tienes facturas por emitir! 🚨'),
                  text: I18n.get('youHavePendingInvoicesToEmit.description', 'Ingresa a las facturas con estado de emisión “Por emitir” y envíalas a la DIAN con un clic.'),
                  actionCall: `https://ayuda.alegra.com/es/consulta-el-estado-de-emisi%C3%B3n-de-tus-documentos-electr%C3%B3nicos-sistema-punto-de-venta-pos?hs_preview=WMsnHQUa-80996253107#Opc2`,
                  actionLabel: I18n.get('seeInvoicesStatus', 'Ver estado de facturas'),
                  type: "warning"
                }));
            } catch (err) {
              console.log("error while creating notification", err);
            }
        }
      } catch (error) {
        console.log(error);
      }
    }
    checkUserHasPendingInvoices();
  }, [dispatch, country, user, APIGraphql])

  return (
    <>
    </>
  )
}

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type }) => ({
  name: "user-has-pending-invoices",
  teamInCharge: "pos",
  receptors: {
    users: [userId]
  },
  availability: {
    from: from ? from : dayjs().toISOString(),
    to: to ? to : dayjs().add(15, 'days').toISOString()
  },
  message: [
    {
      channel: "web",
      type: "localized",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionCall,
        actionLabel
      },
      style: {
        type,
        icon: "notification"
      }
    }
  ]
})

export default UserWithPendingInvoicesNotification