import React from 'react'
import { I18n } from '@aws-amplify/core';
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Tooltip from './index'

let OnlyInvoicing = ({ children, ...props }) => {
  return (
    <Tooltip
      {...props}
      overlay={(
        <p className="text-capitalize-first">
          {I18n.get('onlyInvoicingTooltip', 'Para disfrutar de esta funcionalidad, conoce los planes todo incluido')}{' '}
          <NavLink
            to="/plan/plans"
            className="btn-link"
          >
            {I18n.get('here', 'aquí')}
          </NavLink>
        </p>
      )}
    >
      {children}
    </Tooltip>
  )
}

OnlyInvoicing.propTypes = {
  tooltipActive: PropTypes.bool,
}

export default OnlyInvoicing;