// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.166672" y="0.00012207" width="28" height="28" rx="5" fill="#F1F5F9"/>
    <path d="M14.6667 9.33346C14.6667 9.05732 14.4428 8.83346 14.1667 8.83346C13.8905 8.83346 13.6667 9.05732 13.6667 9.33346V13.5001H9.5C9.22386 13.5001 9 13.724 9 14.0001C9 14.2763 9.22386 14.5001 9.5 14.5001H13.6667V18.6668C13.6667 18.9429 13.8905 19.1668 14.1667 19.1668C14.4428 19.1668 14.6667 18.9429 14.6667 18.6668V14.5001H18.8333C19.1095 14.5001 19.3333 14.2763 19.3333 14.0001C19.3333 13.724 19.1095 13.5001 18.8333 13.5001H14.6667V9.33346Z" fill="#0F172A"/>
    </svg>    
)