function TransactionSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.07257 0.755768C4.99888 0.725209 4.91808 0.708344 4.83333 0.708344C4.74859 0.708344 4.66778 0.725209 4.59409 0.755768C4.52038 0.786268 4.45131 0.831479 4.39139 0.891402L1.05806 4.22474C0.813981 4.46881 0.813981 4.86454 1.05806 5.10862C1.30214 5.3527 1.69786 5.3527 1.94194 5.10862L4.20833 2.84223V8.83334C4.20833 9.17852 4.48816 9.45834 4.83333 9.45834C5.17851 9.45834 5.45833 9.17852 5.45833 8.83334V2.84223L7.72473 5.10862C7.9688 5.3527 8.36453 5.3527 8.60861 5.10862C8.85269 4.86454 8.85269 4.46881 8.60861 4.22474L5.27528 0.891402C5.21535 0.831479 5.14629 0.786268 5.07257 0.755768Z"
        fill="#94A3B8"
      />
      <path
        d="M9.39139 10.8914C9.63547 10.6473 10.0312 10.6473 10.2753 10.8914L12.5417 13.1578V6.33334C12.5417 5.98817 12.8215 5.70834 13.1667 5.70834C13.5118 5.70834 13.7917 5.98817 13.7917 6.33334V13.1578L16.0581 10.8914C16.3021 10.6473 16.6979 10.6473 16.9419 10.8914C17.186 11.1355 17.186 11.5312 16.9419 11.7753L13.6086 15.1086C13.4866 15.2307 13.3266 15.2917 13.1667 15.2917C13.0819 15.2917 13.0011 15.2748 12.9274 15.2443C12.8537 15.2138 12.7846 15.1685 12.7247 15.1086L9.39139 11.7753C9.14731 11.5312 9.14731 11.1355 9.39139 10.8914Z"
        fill="#94A3B8"
      />
    </svg>
  );
}

export default TransactionSvg;
