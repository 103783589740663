// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-gray ${className}`}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12 7.186a1.687 1.687 0 1 0 0-3.374 1.687 1.687 0 0 0 0 3.374zM13.687 12a1.687 1.687 0 1 1-3.374 0 1.687 1.687 0 0 1 3.374 0zm0 6.5a1.687 1.687 0 1 1-3.374 0 1.687 1.687 0 0 1 3.374 0z" />
  </svg>
)