import React from "react";
import { I18n } from "aws-amplify";
import { BigNumber } from 'bignumber.js';
import { get, sortBy } from "lodash";

import { getColombiaTaxCode, mergeObjectArraysById } from './utilities';
import { useFormat } from '../../../hooks/useFormat';

const TaxDetail = ({
  invoice,
  taxes,
  subTotalByTax,
  subtotal,
  isMulticurrencySell,
  originalTaxes,
  originalSubTotalByTax,
  originalSubtotal,
  currentDefaultCurrency
}) => {
  const { items } = invoice;
  const { fmt, decimal } = useFormat();
  const taxesTotal = taxes.reduce((acc, curr) => acc.plus(curr.value), new BigNumber(0));
  const taxesAndSubtotal = mergeObjectArraysById(subTotalByTax, taxes);
  const originalTaxesTotal = isMulticurrencySell ? originalTaxes?.reduce((acc, curr) => acc.plus(curr.value), new BigNumber(0)) : [];
  const originalTaxesAndSubtotal = isMulticurrencySell ? mergeObjectArraysById(originalSubTotalByTax, originalTaxes) : [];
  const currentDefaultCurrencyCode = get(currentDefaultCurrency, "code");
  const currentSellCurrencyCode = get(invoice, "currency.code", get(currentDefaultCurrency, "code"));

  const taxesAndSubtotalSorted = sortBy(taxesAndSubtotal, (obj) => {
    const idValue = parseInt(obj.id);
    return isNaN(idValue) ? 0 : idValue;
  })

  const originalTaxesAndSubtotalSorted = sortBy(originalTaxesAndSubtotal, (obj) => {
    const idValue = parseInt(obj.id);
    return isNaN(idValue) ? 0 : idValue;
  })

  const dottedLineStyle = {
    borderBottom: '1px dashed #000',
    display: 'inline-block',
  };

  if (!items) return null;

  return (
    <div className="mb-3 invoice-print-tax-detail">
      <h3 className={`h4 container-text-flex"}`} style={{fontSize: '14px'}}>
        <p className='font-weight-bold text-center'>{I18n.get('taxSummary', 'Resumen de impuestos')}</p>
      </h3>

      <div className="container-text-flex justify-content-center align-items-center form-row mb-2 invoice-print-tax-detail__header">
        <div className="d-flex flex-column col-3 text-center font-weight-bold">
          {I18n.get('fee', 'Tarifa')}
          <div className="mt-2" style={dottedLineStyle}></div>
        </div>
        <div className="d-flex flex-column col-5 text-center font-weight-bold">
          {I18n.get('basePrice', 'Base')}
          <div className="mt-2" style={dottedLineStyle}></div>
        </div>
        <div className="d-flex flex-column col-4 text-center font-weight-bold">
          {I18n.get('tax', 'Impuesto')}
          <div className="mt-2" style={dottedLineStyle}></div>
        </div>
      </div>

      {taxesAndSubtotalSorted.map((tax, index) => {
        const { itemTaxCode, type } = getColombiaTaxCode(tax);
        return (
          <div key={index} className="container-text-flex justify-content-center align-items-center form-row" style={{ fontSize: "10px" }}>
            <div className="d-flex flex-column col-3 text-center">
              <div className="m-0">
                {`${itemTaxCode} = ${type} ${(!!tax.percentage || tax.percentage === 0) ? tax.percentage + '%' : ""}`}
                {/* {`${itemTaxCode} = ${tax.type === "OTRO" ? name?.substring(0, 3).replace(/\s*\(\d+(\.\d+)?%\)/, "") : name?.replace(/\s*\(\d+(\.\d+)?%\)/, "")} ${(!!tax.percentage || tax.percentage === 0) ? tax.percentage + '%' : ""}`} */}
              </div>
            </div>

            <div className="d-flex flex-column col-5 text-right">
              {isMulticurrencySell
                ? (
                  <>
                    <div>
                      {`${originalTaxesAndSubtotalSorted[index]?.subtotal?.toFormat(decimal, fmt)} ${currentDefaultCurrencyCode}`}
                    </div>
                    <div>
                      {`${tax.subtotal?.toFormat(decimal, fmt)} ${currentSellCurrencyCode}`}
                    </div>
                  </>
                )
                : <div>
                  {`${tax.subtotal?.toFormat(decimal, fmt)}`}
                </div>
              }
            </div>
            <div className="d-flex flex-column col-4 text-right">
              {isMulticurrencySell
                ? (
                  <>
                    <div>
                      {`${originalTaxesAndSubtotalSorted[index]?.value.toFormat(decimal, fmt) ?? new BigNumber(0).toFormat(decimal, fmt)} ${currentDefaultCurrencyCode}`}
                    </div>
                    <div>
                      {`${tax.value.toFormat(decimal, fmt) ?? new BigNumber(0).toFormat(decimal, fmt)} ${currentSellCurrencyCode}`}
                    </div>
                  </>
                )
                : <div>
                  {tax.value.toFormat(decimal, fmt) ?? new BigNumber(0).toFormat(decimal, fmt)}
                </div>
              }
            </div>
          </div>
        )
      })}

      <div className="container-text-flex justify-content-center align-items-center form-row mt-1 invoice-print-tax-detail__totals">
        <div className="d-flex flex-column col-3 text-center font-weight-bold">
          {""}
        </div>
        <div className="d-flex flex-column col-5 text-right font-weight-bold">
          {isMulticurrencySell
            ? (
              <>
                <div className="mb-0 pb-0 font-weight-bold">
                  {`${originalSubtotal?.toFormat(decimal, fmt)} ${currentDefaultCurrencyCode}`}
                </div>
                <div className="font-weight-bold">
                  {`${subtotal.toFormat(decimal, fmt)} ${currentSellCurrencyCode}`}
                </div>
              </>
            )
            : <div className="font-weight-bold">
              {subtotal.toFormat(decimal, fmt)}
            </div>
          }
        </div>
        <div className="d-flex flex-column col-4 text-right font-weight-bold">
          {isMulticurrencySell
            ? (
              <>
                <div className="mb-0 pb-0 font-weight-bold">
                  {`${originalTaxesTotal?.toFormat(decimal, fmt)} ${currentDefaultCurrencyCode}`}
                </div>
                <div className="font-weight-bold">
                  {`${taxesTotal.toFormat(decimal, fmt)} ${currentSellCurrencyCode}`}
                </div>
              </>
            )
            : <div className="font-weight-bold">
              {taxesTotal.toFormat(decimal, fmt)}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default TaxDetail;