import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'
import * as mutations from '../../../../graphql/mutations';
import { Accordion, Card } from 'react-bootstrap';

import { setUser } from '../../../../reducers/auth';
import { userSelector } from '../../../../selectors/auth';
import {
  companySelector,
  identificationSelector,
  email as emailSelector,
  address as addressSelector
} from '../../../../selectors/company';
import { allUsers } from '../../../../selectors/users';
import { companyTransformer } from '../../../../utils/transformers'
import { handleError as handleRequestError } from '../../../../utils/errors'
import { uploadFileToAlegraAPI } from '../utils';

import Loading from '../../../svg/Loading';
import ArrowRight from '../../../svg/ArrowRight';
import { replaceAndParse, toast } from '../../../../utils';
import { APIGraphqlSelector } from '../../../../selectors/app';


const ContractInfo = ({ nextStep }) => {
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const identification = useSelector(identificationSelector);
  const email = useSelector(emailSelector);
  const address = useSelector(addressSelector);
  const user = useSelector(userSelector);
  const employeesNumber = (useSelector(allUsers)).length;
  const documentContent = useSelector(state => get(state, 'modals.newTermsAndConditionsColombia.params.documentContent', ''));
  const values = useSelector(state => get(state, 'modals.newTermsAndConditionsColombia.params.values', {}));
  const [isLoading, setIsLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const handleError = (error) => {
    let message = handleRequestError(error)
    if (message.toUpperCase().includes('STATUS CODE 400'))
      message = I18n.get('runtimeErrorMessage', 'Por favor, intente de nuevo');

    return message;
  }

  const gtmEvent = () => {
    window.dataLayer.push({
      event: "mandate-contract-signed",
      company: {
        idCompany: identification,
        uuid: identification,
        email: email,
        idProduct: "4",
        planName: user.membership.planName,
        registryDate: company.registryDate,
        eventTimestamp: Date.now(),
        appVersion: company.applicationVersion,
        isPaying: false,
        regime: company.regime,
        employeesNumber
      }
    });
  }

  const updateUser = async (newCompanyValues, attemps = 3) => {
    let response = null;
    for (let i = 0; i < attemps; i++) {
      try {
        return await APIGraphql(graphqlOperation(mutations.updateUser, {
          user: {
            company: companyTransformer(newCompanyValues)
          }
        }))
      } catch (error) {
        if (!get(error, 'errors[0].message', '').toUpperCase().includes("ERROR: RUNTIME")) {
          throw error;
        }
      }
    }
    return response;
  }

  const submit = async () => {
    setIsLoading(true)
    try {
      const mandateSignatureUrl = await uploadFileToAlegraAPI(documentContent);
      const newCompanyValues = {
        settings: {}, address: address,
        legalRepresentative: {
          ...values
        },
        mandateSignatureUrl: mandateSignatureUrl ? mandateSignatureUrl.data.url : null
      };
      const response = await updateUser(newCompanyValues);

      if (!!get(response, 'data.updateUser.id', null)) {
        gtmEvent();
        dispatch(setUser(response.data.updateUser))
        setIsLoading(false)
        nextStep();
      }
    } catch (error) {
      setIsLoading(false)
      toast.error({
        title: 'Error al enviar la petición',
        subtitle: handleError(error),
        params: { autoClose: 3000 }
      })
    }
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="my-4 px-5">
        <p className="h1 mb-5">
          {I18n.get('termsConditionsForElectronicInvoices', 'Términos y condiciones para facturar electrónicamente')}
        </p>
        <p className="h3 text-capitalize-first mb-5" style={{ lineHeight: 1.6 }}>
          {replaceAndParse(I18n.get('termsConditionsForElectronicInvoices.description', 'Los nuevos términos y condiciones te permitirán <b>seguir usando el certificado digital de Alegra</b> para emitir tus comprobantes electrónicos.'))}
        </p>
        <label htmlFor="acceptTerms" className="h4 user-select-none" style={{ cursor: 'pointer' }}>
          <input type="checkbox" id="acceptTerms" name="acceptTerms" onClick={() => setIsAccepted(!isAccepted)} />
          <span className="ml-2 font-weight-bolder">{I18n.get('acceptTermsConditions', 'Acepto los términos y condiciones')}</span>
        </label>
        <div className="mt-3">
          <Accordion className="mb-5">
            <Accordion.Toggle as={Card.Header} className="h4 d-flex justify-content-between align-items-center" onClick={() => setIsShowing(!isShowing)} style={{ cursor: 'pointer' }} eventKey="0">
              <p className="my-1">{I18n.get('newTermsAndConditionsHere', 'Visualiza aquí el contrato')}</p>
              <p className="my-1">{isShowing ? "▴" : "▾"}</p>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div style={{ position: "relative", maxWidth: "96%", margin: "0 auto" }}>
                <iframe title="contract" src={`data:application/pdf;base64,${documentContent}`}
                  width="100%" height="570px" allowFullScreen style={{ border: "0" }}>{" "}</iframe>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </div>
        <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center">
          <div className="modal__bottom-buttons d-flex">
            <button
              type="button"
              disabled={!isAccepted}
              onClick={submit}
              data-testid="modal-submit-btn"
              className="btn btn-submit text-capitalize-first px-4"
            >
              {isLoading
                ? <Loading className="icon-primary icon" />
                : <>
                  {I18n.get('next', 'Siguiente')}
                  <ArrowRight className="icon-white" small />
                </>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContractInfo