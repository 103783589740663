import React from 'react'

const Loading = ({ id, className }) => {

  return (
    <svg
      id={id}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24,44C12.972,44,4,35.028,4,24S12.972,4,24,4s20,8.972,20,20S35.028,44,24,44z M24,8 C15.178,8,8,15.178,8,24s7.178,16,16,16s16-7.178,16-16S32.822,8,24,8z" />
      <path fill="#f2f2f2" d="M 24,44.20339 C 12.972,44.20339 3.7966102,35.028 3.7966102,24 H 8.2033898 C 8.2033898,32.822 15.178,39.389831 24,39.389831 C 32.822,39.389831 39.59322,32.822 39.59322,24 C 39.59322,15.178 32.822,8.4067797 24,8.4067797 V 3.5932203 C 35.028,3.5932203 44.40678,12.972 44.40678,24 C 44.40678,35.028 35.028,44.20339 24,44.20339 Z">
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 24 24" to="360 24 24" repeatCount="indefinite"></animateTransform>
      </path>
    </svg>
  )
}

export default Loading;