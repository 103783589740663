// eslint-disable-next-line
export default ({ id, className, width = 24, height = 24 }) => (
  <svg
    id={id}
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.438 12.503v-2.001h5.621V5h1.942v5.502h5.562v2H13v5.56h-1.942v-5.56H5.438z" />
  </svg>
)