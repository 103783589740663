// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.6465 2.25C13.32 1.09575 12.2588 0.25 11 0.25C9.74122 0.25 8.67998 1.09575 8.35352 2.25H1C0.585786 2.25 0.25 2.58579 0.25 3C0.25 3.41421 0.585786 3.75 1 3.75H8.35352C8.67998 4.90425 9.74122 5.75 11 5.75C12.2588 5.75 13.32 4.90425 13.6465 3.75H17C17.4142 3.75 17.75 3.41421 17.75 3C17.75 2.58579 17.4142 2.25 17 2.25H13.6465ZM11 1.75C10.3096 1.75 9.75 2.30964 9.75 3C9.75 3.69036 10.3096 4.25 11 4.25C11.6904 4.25 12.25 3.69036 12.25 3C12.25 2.30964 11.6904 1.75 11 1.75Z" fill={className ? '' : "#94A3B8"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5 6.25C6.25878 6.25 7.32002 7.09575 7.64648 8.25H17C17.4142 8.25 17.75 8.58579 17.75 9C17.75 9.41421 17.4142 9.75 17 9.75H7.64648C7.32002 10.9043 6.25878 11.75 5 11.75C3.74122 11.75 2.67998 10.9043 2.35352 9.75H1C0.585786 9.75 0.25 9.41421 0.25 9C0.25 8.58579 0.585786 8.25 1 8.25H2.35352C2.67998 7.09575 3.74122 6.25 5 6.25ZM5 7.75C4.30964 7.75 3.75 8.30964 3.75 9C3.75 9.69036 4.30964 10.25 5 10.25C5.69036 10.25 6.25 9.69036 6.25 9C6.25 8.30964 5.69036 7.75 5 7.75Z" fill={className ? '' : "#94A3B8"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6465 14.25C16.32 13.0957 15.2588 12.25 14 12.25C12.7412 12.25 11.68 13.0957 11.3535 14.25H1C0.585786 14.25 0.25 14.5858 0.25 15C0.25 15.4142 0.585786 15.75 1 15.75H11.3535C11.68 16.9043 12.7412 17.75 14 17.75C15.2588 17.75 16.32 16.9043 16.6465 15.75H17C17.4142 15.75 17.75 15.4142 17.75 15C17.75 14.5858 17.4142 14.25 17 14.25H16.6465ZM12.75 15C12.75 14.3096 13.3096 13.75 14 13.75C14.6904 13.75 15.25 14.3096 15.25 15C15.25 15.6904 14.6904 16.25 14 16.25C13.3096 16.25 12.75 15.6904 12.75 15Z" fill={className ? '' : "#94A3B8"} />
  </svg>

)
