import React from 'react';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { Field } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux'

import { checkFeatureLimit } from '../../../../reducers/membership'
import { hasPermissionTo } from '../../../../selectors/auth'
import { isOnlyInvoicingPlan } from '../../../../selectors/company'
import { activePriceLists } from '../../../../selectors/priceLists'
import { activePaymentTerms } from '../../../../selectors/paymentTerms'
import { activeSellers } from '../../../../selectors/sellers'
import { renderSelect } from '../../fields/V0/Fields';

const RenderCommom = props => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const priceLists = useSelector(activePriceLists);
  const paymentTerms = useSelector(activePaymentTerms);
  const sellers = useSelector(activeSellers);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)

  return (
    <>
      <Field
        name="priceList"
        component={renderSelect}
        className="col-md-6"
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        label={capitalize(I18n.get('priceList', 'Lista de precios'))}
        options={!can('index', 'price-lists') || !can('view', 'price-lists') ? []: priceLists}
        selectInfo={!can('index', 'price-lists') || !can('view', 'price-lists')
          ? I18n.get('userNotAllowed.price-lists.view', 'no tienes permisos para ver la información de las listas de precio')
          : null}
        hideNoOptionMessage={!can('index', 'price-lists') || !can('view', 'price-lists')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        addOptionText={!!props.newPriceListAction ? capitalize(I18n.get('newPriceList', 'Nueva lista de precio')) : null}
        addOptionAction={() => dispatch(checkFeatureLimit('priceLists', props.newPriceListAction))}
      />

      <Field
        name="term"
        component={renderSelect}
        className="col-md-6"
        label={capitalize(I18n.get('paymentTerm', 'plazo de pago'))}
        options={!can('index', 'terms') ? []: paymentTerms}
        selectInfo={!can('index', 'terms')
          ? I18n.get('userNotAllowed.terms.view', 'no tienes permisos para ver los plazos de pago')
          : null}
        hideNoOptionMessage={!can('index', 'terms')}
        addOptionText={!!props.newPaymentTermAction ? capitalize(I18n.get('newPaymentTerm', 'Nuevo plazo de pago')) : null}
        addOptionAction={props.newPaymentTermAction}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
      />

      <Field
        name="seller"
        component={renderSelect}
        className="col-md-6"
        label={capitalize(I18n.get('seller', 'vendedor'))}
        options={sellers}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        addOptionText={can('add', 'sellers') && !!props.newSellerAction 
          ? capitalize(I18n.get('newSeller', 'Nuevo vendedor')) : null}
        addOptionAction={() => dispatch(checkFeatureLimit('sellers', can('add', 'sellers') ? props.newSellerAction: null))}
        showClearIndicator
      />
    </>
  )
}

export default RenderCommom;