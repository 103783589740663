import alegraAPI from '../reducers/alegraAPI';
import Cookie from 'js-cookie';
import { get, isArray, difference, flattenDeep } from 'lodash';

export const isItemFromStation = (item, stationWarehouse) => {
  const itemType = get(item, 'type');
  const warehouses = get(item, 'inventory.warehouses', null);

  let invoicingWarehouse = false;

  if (itemType === 'kit') {
    invoicingWarehouse = +get(item, 'kitWarehouse.id') === +stationWarehouse;
  } else {
    if (!!warehouses) {
      if (
        !!warehouses.find(
          (warehouse) => +get(warehouse, 'id') === +stationWarehouse
        )
      )
        invoicingWarehouse = true;
    } else invoicingWarehouse = true;
  }

  const invoicingType =
    itemType === 'simple' ||
    itemType === 'kit' ||
    itemType === 'variant' ||
    itemType === 'product';

  return invoicingWarehouse && invoicingType;
};

export const checkVariantImages = (item) => {
  if (get(item, 'itemVariants.length')) {
    return item.itemVariants
      .map((variant) => ({
        image: variant.imageFile,
        variantAttributes: variant.variantAttributes,
      }))
      .filter((variant) => !!variant.image);
  }

  return [];
};

export const compareVariantsAttributes = (attA, attB) => {
  if (!attA || !attB) return false;

  if (!isArray(attA) || !isArray(attB)) return false;

  try {
    const attAIds = flattenDeep(
      attA.map(({ options }) => options.map(({ id }) => '' + id))
    );
    const attBIds = flattenDeep(
      attB.map(({ options }) => options.map(({ id }) => '' + id))
    );

    return (
      !difference(attAIds, attBIds).length &&
      !difference(attBIds, attAIds).length
    );
  } catch {
    return false;
  }
};

export const saveVariantsImages = async (itemVariants, variantsImages) => {
  try {
    await Promise.all(
      variantsImages.map(async ({ image, variantAttributes }) => {
        const variant = itemVariants.find(({ variantAttributes: itemAtt }) =>
          compareVariantsAttributes(variantAttributes, itemAtt)
        );
        if (variant) {
          await attachImagesToItem(variant.id, image);
        }
        return null;
      })
    );
  } catch {}
};

export const attachImagesToItem = async (itemId, image) => {
  let data = new FormData();
  data.append('image', image);

  await alegraAPI.post(`/items/${itemId}/attachment`, data, null, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const attachFavoriteImageToItem = async (itemId, image) => {
  let data = new FormData();
  let imageFile = await fetch(image);
  imageFile = await imageFile.blob();
  data.append('image', imageFile);

  await alegraAPI.post(
    `/items/${itemId}/attachment?favorite=true`,
    data,
    null,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Data-Source': 'pos',
        Authorization: `Basic ${Cookie.get(
          process.env.REACT_APP_ALEGRA_COOKIE
        )}`,
      },
    }
  );
};
