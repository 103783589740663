import React from 'react'
import { I18n } from '@aws-amplify/core';

import OpenInNew from '../../svg/OpenInNew';

export const secondaryText = type => {
  if (type === 'product' || type === 'service')
    return (
      <>
        <OpenInNew className="icon-gray mr-2" width="16" height="16" />
        {I18n.get('goToAdvancedForm', 'Ir al formulario avanzado')}
      </>
    )
  return null
}

export const primaryText = type => {
  switch (type) {
    case 'product':
      return I18n.get('createProduct', 'Crear producto')
    case 'service':
      return I18n.get('createService', 'Crear servicio')
    case 'kit':
      return (
        <>
          <OpenInNew className="icon-white mr-2" width="16" height="16" />
          {I18n.get('completeKit', 'Completar combo')}
        </>
      )

    default:
      return null
  }
}

export const helpText = type => {
  switch (type) {
    case 'product':
      return I18n.get('createProductHelp', 'Crea los bienes y mercancías que vendes e incluye todos sus detalles.')
    case 'service':
      return I18n.get('createServiceHelp', 'Crea las actividades comerciales o de consultoría que ofreces a tus clientes.')
    case 'kit':
      return (
        <p>
          {I18n.get('createKitHelp', 'Agrupa en un solo ítem un conjunto de productos, servicios o una combinación de ambos.')}
          <a href="https://ayuda.alegra.com/es/crea-combos-y-kits-de-productos-para-agilizar-tus-ventas-con-el-sistema-punto-de-venta-pos" target="_blank" rel="noreferrer">
            {I18n.get('knowMore', 'Saber más')}
          </a>
        </p>
      )
    default:
      return null
  }
}