import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { get, capitalize } from 'lodash';

import { renderField, renderFieldDV } from '../../fields/V0/Fields';
import { searchIdentification } from './Identification';
import { validatePanama } from '../utils';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../../selectors/app';

const colombiaName = props => {
  // const identification = get(props, 'values.identification.type.key', '');
  const kindOfPerson = get(props, 'values.kindOfPerson.key', '');
  const completeName = kindOfPerson === "PERSON_ENTITY";

  return (
    <>
      <Field
        name="firstName"
        className={`col-12 ${completeName ? 'col-md-6' : ''}`}
        component={renderField}
        data-testid="client-first-name-input"
        type="text"
        label={completeName ? capitalize(I18n.get('name', 'Nombre')) : `${capitalize(I18n.get('socialRazon', 'razón social'))} / ${capitalize(I18n.get('fullName', 'Nombre Completo'))}`}
        required
      />

      {completeName && (
        <>
          <Field
            name="lastName"
            className="col-6"
            component={renderField}
            data-testid="client-last-name-input"
            type="text"
            label={capitalize(I18n.get('lastName', 'Apellidos'))}
            required
          />
        </>
      )
      }

    </>
  )
}

const peruName = props => {
  const identification = get(props, 'values.identification.type.key', '');

  return (
    <Field
      name="firstName"
      className="col-12"
      component={renderField}
      data-testid="client-first-name-input"
      type="text"
      placeholder={identification === 'RUC' && props.isElectronicInvoicing
        ? capitalize(I18n.get('socialRazon', 'razón social'))
        : capitalize(I18n.get('name', 'Nombre'))
      }
      label={identification === 'RUC' && props.isElectronicInvoicing
        ? capitalize(I18n.get('socialRazon', 'razón social'))
        : capitalize(I18n.get('name', 'Nombre'))}
      required
    />
  )
}

const costaRicaName = props => (
  <Field
    name="firstName"
    className="col-12"
    component={renderField}
    data-testid="client-name-input"
    type="text"
    label={capitalize(I18n.get('name', 'Nombre'))}
    required
  />
)

const argentinaName = props => (
  <Field
    name="firstName"
    className="col-12 col-md-6"
    component={renderField}
    data-testid="client-name-input"
    type="text"
    label={capitalize(I18n.get('name', 'Nombre'))}
    required
  />
)

const dominicanaName = () => (
  <Field
    name="firstName"
    className="col-12"
    component={renderField}
    data-testid="client-name-input"
    type="text"
    label={`${capitalize(I18n.get('name', 'Nombre'))}/${capitalize(I18n.get('socialRazon', 'razón social'))}`}
    required
  />
)

const PanamaName = props => {

  const [searchingId, setSearchingId] = useState(false);
  const identificationType = get(props, 'values.identification.type.key', '');
  const kindOfPerson = get(props, 'values.kindOfPerson.key', '');
  const completeName = kindOfPerson === 'PERSON_ENTITY';
  const isElectronic = get(props, 'values.isElectronic', '');
  const APIGraphql = useSelector(APIGraphqlSelector);

  const isRequiredField = () => {
    let isRequired = true;
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (identificationType === 'FINAL_CONSUMER' && kindOfPerson === '') isRequired = false;
    return isRequired;
  }

  const errors = validatePanama(props.values)

  const serchIdentificationPanama = () => {
    if (!errors.identification?.ruc && kindOfPerson) {
      searchIdentification(APIGraphql, kindOfPerson, { ...props, searchingId, setSearchingId })
    }
  }

  return (
    <>
      {
        (identificationType !== 'FOREIGN' && identificationType !== 'FOREIGN_COMPANY') && (
          <>
            <Field
              name="identification.ruc"
              className="col-9"
              component={renderField}
              data-testid="client-name-input"
              type="text"
              label={I18n.get('ruc', 'RUC')}
              onBlur={serchIdentificationPanama}
              onChange={(e) => {
                const value = e.target.value;
                const isCorrectRuc = get(props, 'values.isCorrectRuc', null);
                if (isCorrectRuc === false) {
                  props.form.change('isCorrectRuc', null);
                }
                return value;
              }}
              required={isRequiredField()}
            />

            <Field
              name="identification.dv"
              className="col-3"
              component={renderFieldDV}
              type="text"
              label={I18n.get('dv', 'DV')}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]+/g, "");
                return value.length <= 2 ? value : value.slice(0, 2);
              }}
              disabled={searchingId}
              isLoading={searchingId}
              required={isRequiredField()}
            />
          </>
        )
      }

      <Field
        name="firstName"
        className={`col-12 ${completeName ? 'col-md-6' : ''}`}
        component={renderField}
        data-testid="client-name-input"
        type="text"
        label={kindOfPerson === '' ? I18n.get('name', 'Nombre') : completeName ? capitalize(I18n.get('firstName', 'Primer Nombre')) : `${capitalize(I18n.get('name', 'Nombre'))} o ${capitalize(I18n.get('socialRazon', 'razón social'))}`}
        required
      />

      {
        completeName && (
          <Field
            name="lastName"
            className="col-md-6"
            component={renderField}
            data-testid="client-last-name-input"
            type="text"
            label={capitalize(I18n.get('firstLastName', 'Primer Apellido'))}
            required
          />
        )
      }

    </>

  )
}

const defaultName = props => (
  <Field
    name="firstName"
    className="col-12"
    component={renderField}
    data-testid="client-name-input"
    type="text"
    label={capitalize(I18n.get('name', 'Nombre'))}
    required
  />
)

const renderName = props => {

  switch (props.country) {
    case 'colombia':
      return colombiaName(props);

    case 'costaRica':
      return costaRicaName(props);

    case 'peru':
      return peruName(props);

    case 'argentina':
      return argentinaName(props);

    case 'republicaDominicana':
      return dominicanaName(props);

    case 'panama':
      return PanamaName(props);

    default:
      return defaultName(props);
  }
}

export default renderName;