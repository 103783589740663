function Left({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.60796 1.44194C6.85204 1.19786 6.85204 0.802136 6.60796 0.558058C6.36388 0.313981 5.96815 0.313981 5.72407 0.558058L0.724074 5.55806C0.664151 5.61798 0.61894 5.68704 0.58844 5.76076C0.557882 5.83445 0.541016 5.91526 0.541016 6C0.541016 6.08474 0.557882 6.16555 0.58844 6.23924C0.589823 6.24258 0.591236 6.24592 0.59268 6.24924C0.62307 6.3192 0.666868 6.38474 0.724074 6.44194L5.72407 11.4419C5.96815 11.686 6.36388 11.686 6.60796 11.4419C6.85204 11.1979 6.85204 10.8021 6.60796 10.5581L2.6749 6.625H12.8327C13.1779 6.625 13.4577 6.34518 13.4577 6C13.4577 5.65482 13.1779 5.375 12.8327 5.375H2.6749L6.60796 1.44194Z" fill="#0F172A" />
    </svg>
  );
}

export default Left;
