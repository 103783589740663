import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { priceListTransformer } from '../../../utils/transformers';
import { closeModal, openModal } from '../../../reducers/modals';
import { addPriceList, updatePriceList } from '../../../reducers/priceLists';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import { validate, transform } from '../../forms/priceList/utils';
import Modal from '../../common/Modal';
import Body from '../../forms/priceList/PriceList';
import Bottom from '../common/Bottom';
import Notification from '../common/Notification';
import { replaceAndParse } from '../../../utils/index';
import alegraAPI from '../../../reducers/alegraAPI';

const PriceList = ({ onCreated }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.priceList.isOpen', false)
  );
  const onRefresh = useSelector((state) =>
    get(state, 'modals.priceList.params.onRefresh', false)
  );
  const priceList = useSelector((state) =>
    get(state, 'modals.priceList.params.priceList', false)
  );
  const [resetForm, setResetForm] = useState(false);

  const close = () => {
    dispatch(closeModal({ modal: 'priceList' }));
  };

  const open = () => {
    dispatch(
      openModal({
        modal: 'priceList',
        params: {
          onRefresh,
        },
      })
    );
  };

  const submit = async (values, index) => {
    const transformedValues = transform(values, priceList);
    // ex mutations.updatePriceList
    // ex mutations.createPriceList

    const request = !!priceList
      ? alegraAPI.put('/price-lists/' + priceList.id, {
          ...priceListTransformer({ ...priceList, ...transformedValues }),
        })
      : alegraAPI.post('/price-lists/', {
          ...priceListTransformer({ ...transformedValues }),
        });

    try {
      const response = await request;

      close();

      if (resetForm) {
        open();
        setResetForm(false);
      }

      const newPriceList = !priceList ? get(response, 'data', null) : null;

      if (!!newPriceList) {
        dispatch(sendGTMEvent('price-list-created'));
        dispatch(
          sendNewGTMEvent('pos-price-list-created', {
            error: 'no error',
            type: get(transformedValues, 'type', ''),
          })
        );
        dispatch(addPriceList(newPriceList));
        if (!!onCreated) onCreated(newPriceList);
      } else {
        dispatch(
          updatePriceList({
            id: priceList.id,
            changes: transformedValues,
          })
        );
      }

      if (!!onRefresh) onRefresh();

      if (!!priceList)
        toast.success({
          title: I18n.get(
            'priceListUpdatedSuccessfully',
            'Lista de precio actualizada con éxito'
          ),
        });
      else
        toast.success({
          title: I18n.get(
            'priceListCreatedSuccessfully',
            'Ya puedes asociar tu lista de precios. 🎊'
          ),
          subtitle: replaceAndParse(
            I18n.get(
              'priceListCreatedSuccessfullyMessage',
              'Tu lista {} ya está disponible para que la asocies a tus productos.'
            ),
            [
              `<span class="font-weight-bold">${get(
                newPriceList,
                'name',
                ''
              )}</span>`,
            ]
          ),
        });
    } catch (error) {
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-price-list-created', {
          error: parseError,
        })
      );
      return formError(
        error,
        I18n.get(
          'updatePriceListError',
          'Hubo un error al cambiar los datos de la lista de precios.'
        )
      );
    }
  };
  return (
    <Modal
      title={
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='modal__header-title text-capitalize-first h2 text-primary text-truncate'>
            {!!priceList
              ? priceList.name
              : I18n.get('newPriceListModalTitle', 'Nueva lista de precio')}
          </div>
        </div>
      }
      isOpen={isOpen}
      onRequestClose={() => close()}
      className='modal__medium-size'
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={!!priceList ? priceList : { type: 'percentage' }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, submitError, pristine }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className='px-md-5 py-md-3'>
                {!priceList && (
                  <p className='h5 text-subtitle-muted text-capitalize-first'>
                    {I18n.get(
                      'newPriceListModalSubtitle',
                      'Crea una lista para utilizar un precio diferente en tus productos o servicios.'
                    )}
                  </p>
                )}
                <Body values={values} />
              </div>
              {!!submitError && !pristine && (
                <Notification isOpen={true} text={submitError} type='error' />
              )}

              <Bottom
                submitting={submitting}
                onClose={() => close()}
                onResetForm={setResetForm}
                resetForm={resetForm}
                hideRequired={true}
                submitText={
                  !!priceList
                    ? I18n.get('updatePriceList', 'Actualizar lista')
                    : I18n.get('createPriceList', 'Crear lista')
                }
                saveText={
                  !!priceList
                    ? I18n.get('updateAndCreateNew', 'Actualizar y crear nueva')
                    : I18n.get('saveAndCreateNew', 'Guardar y crea nueva')
                }
                hideCancel
              />
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default PriceList;
