// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M7.89649 4.12062C10.3469 4.12062 12.5323 5.49777 13.5991 7.67665C12.5323 9.85553 10.3469 11.2327 7.89649 11.2327C5.44606 11.2327 3.26072 9.85553 2.19391 7.67665C3.26072 5.49777 5.44606 4.12062 7.89649 4.12062ZM7.89649 2.82751C4.66373 2.82751 1.90296 4.83829 0.784424 7.67665C1.90296 10.515 4.66373 12.5258 7.89649 12.5258C11.1293 12.5258 13.89 10.515 15.0086 7.67665C13.89 4.83829 11.1293 2.82751 7.89649 2.82751ZM7.89649 6.06027C8.78873 6.06027 9.51287 6.78441 9.51287 7.67665C9.51287 8.56889 8.78873 9.29303 7.89649 9.29303C7.00425 9.29303 6.28011 8.56889 6.28011 7.67665C6.28011 6.78441 7.00425 6.06027 7.89649 6.06027ZM7.89649 4.76717C6.29304 4.76717 4.98701 6.0732 4.98701 7.67665C4.98701 9.2801 6.29304 10.5861 7.89649 10.5861C9.49994 10.5861 10.806 9.2801 10.806 7.67665C10.806 6.0732 9.49994 4.76717 7.89649 4.76717Z"
    />
  </svg>
)
