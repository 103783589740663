// eslint-disable-next-line
export default ({ id, className }) => (
    <svg
      id={id}
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="M10.6249 3.33337C10.6249 2.9882 10.3451 2.70837 9.99992 2.70837C9.65474 2.70837 9.37492 2.9882 9.37492 3.33337V11.8245L6.27519 8.72477C6.03112 8.48069 5.63539 8.48069 5.39131 8.72477C5.14723 8.96884 5.14723 9.36457 5.39131 9.60865L9.55798 13.7753C9.6179 13.8352 9.68696 13.8804 9.76068 13.9109C9.83437 13.9415 9.91517 13.9584 9.99992 13.9584C10.0847 13.9584 10.1655 13.9415 10.2392 13.9109C10.3129 13.8804 10.3819 13.8352 10.4419 13.7753L14.6085 9.60865C14.8526 9.36457 14.8526 8.96884 14.6085 8.72477C14.3644 8.48069 13.9687 8.48069 13.7246 8.72477L10.6249 11.8245V3.33337Z"/>
      <path d="M3.33325 13.5417C3.67843 13.5417 3.95825 13.8215 3.95825 14.1667V15.8334C3.95825 16.1096 4.068 16.3746 4.26335 16.5699C4.4587 16.7653 4.72365 16.875 4.99992 16.875H14.9999C15.2762 16.875 15.5411 16.7653 15.7365 16.5699C15.9318 16.3746 16.0416 16.1096 16.0416 15.8334V14.1667C16.0416 13.8215 16.3214 13.5417 16.6666 13.5417C17.0118 13.5417 17.2916 13.8215 17.2916 14.1667V15.8334C17.2916 16.4412 17.0501 17.0241 16.6204 17.4538C16.1906 17.8836 15.6077 18.125 14.9999 18.125H4.99992C4.39213 18.125 3.80924 17.8836 3.37947 17.4538C2.94969 17.0241 2.70825 16.4412 2.70825 15.8334V14.1667C2.70825 13.8215 2.98807 13.5417 3.33325 13.5417Z"/>
    </svg>
  );
  