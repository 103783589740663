import React, { useMemo, useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, isArray, isNumber, get } from 'lodash';
import { useSelector } from 'react-redux'
import { BigNumber } from 'bignumber.js'

import { multitax, decimalPrecision, country as countrySelector } from '../../../selectors/company'
import { activeTaxes, getDefaultTax } from '../../../selectors/taxes'
import { decimalsVersionsGroup } from '../../../selectors/deployGroups';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { languageSelector } from '../../../selectors/auth';
import getNoTax from '../../countriesData/general/noTax';

const Price = props => {
  const [lastModified, setLastModified] = useState('salePrice');
  const [noTaxActive, setNoTaxActive] = useState(false);
  const taxes = useSelector(activeTaxes);
  const isMulti = useSelector(multitax);
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const language = useSelector(languageSelector);
  const defaultTax = useSelector(getDefaultTax);

  const noTax = useMemo(
    () => getNoTax(language.substring(0, 2))
    , [language])

  const allTaxes = [noTax, ...taxes]

  function maxDecimals(e, key, decimals = 6) {
    if (!decimalsVersionsGroup.includes(country))
      return e.target.value;

    const newButton = e.nativeEvent.data;
    const values = props.values;
    values[key] = !values[key] ? "" : values[key];
    e.target.value = !e.target.value ? "" : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (isNaN(newButton)) {
      e.target.value = values[key];
      return values[key];
    }
  }

  const calculateTotalPrice = (taxPercent) => {
    const salePrice = get(props, 'values.salePrice', '0');
    if (!!salePrice) {
      const price = new BigNumber(100).plus(new BigNumber(taxPercent)).dividedBy(100).multipliedBy(salePrice)
        .decimalPlaces(decimal).toNumber()
      if (isNumber(price))
        props.form.change('price', '' + price);
    }
  }

  const calculateSalePrice = (taxPercent) => {
    const price = get(props, 'values.price', '0');
    if (!!price) {
      const salePrice = new BigNumber(price).multipliedBy(100).dividedBy(new BigNumber(taxPercent).plus(100))
        .decimalPlaces(decimal).toNumber()
      if (isNumber(salePrice))
        props.form.change('salePrice', '' + salePrice);
    }
  }

  function setUpDecimals(key, decimals = 6) {
    if (!decimalsVersionsGroup.includes(country))
      return;
    const values = props.values;
    if (values[key] && values[key].includes('.')) {
      if (values[key].slice(-1) === '.') {
        values[key] = values[key].slice(0, -1);
        return;
      }

      if (values[key].split('.')[1].length > decimals)
        values[key] = Number(values[key]).toFixed(decimals)
    }
  }

  const calculatePriceValues = (lastTouched = null) => {
    let taxes = !!get(props, 'values.tax', null)
      ? get(props, 'values.tax', null) : [];
    taxes = !isArray(taxes) ? [taxes] : taxes;

    const totalPercent = taxes.reduce((prevTax, tax) => prevTax + +get(tax, 'percentage'), 0)

    if (!!lastTouched) {
      if (lastTouched === 'price')
        calculateSalePrice(totalPercent)
      else
        calculateTotalPrice(totalPercent)
      setLastModified(lastTouched)
    } else {
      if (lastModified === 'price')
        calculateSalePrice(totalPercent)
      else
        calculateTotalPrice(totalPercent)
    }
  }

  useEffect(() => {
    props.form.change('tax', !!defaultTax ? defaultTax : noTax);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTax])

  useEffect(() => {
    if (isMulti && (country !== 'peru')) {
      const taxes = props?.values?.tax;
      if (isArray(taxes))
        taxes[taxes.length - 1] === noTax
          ? setNoTaxActive(true)
          : setNoTaxActive(false)
      else
        props.form.change('tax', [defaultTax || noTax])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTax, props.values.tax])

  useEffect(() => {
    if (noTaxActive)
      props.form.change('tax', [noTax]);
    else if (!noTaxActive && !!(props?.values?.tax))
      props.form.change('tax', props?.values?.tax?.filter((tax) =>
        tax !== noTax
      ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTaxActive])

  return (
    <>
      <div className="col-sm-4 col-6 d-flex">
        <Field
          name="salePrice"
          component={renderField}
          className="col-11 p-0 pr-2"
          type="number"
          required
          label={capitalize(I18n.get('priceBeforeTaxes', 'precio sin impuestos'))}
          onChange={e => maxDecimals(e, 'salePrice', 6)}
          onBlur={() => { setUpDecimals('salePrice', 6); calculatePriceValues('salePrice') }}
        />

        <div
          className="text-primary col-1 p-0 text-center"
          style={{
            lineHeight: '25px',
            fontSize: '20px',
            marginTop: '3rem'
          }}
        >
          +
        </div>
      </div>

      <div className="col-sm-4 col-6 d-flex">
        <Field
          name="tax"
          component={renderSelect}
          className="col-11 p-0 pr-2"
          options={allTaxes}
          getOptionLabel={option => !!option.customName ? option.customName : `${option.name} (${option.percentage}%)`}
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('taxes', 'impuestos'))}
          placeholder={I18n.get('none', 'ninguno')}
          isMulti={country === "peru" ? false : isMulti}
          onInputChange={() => {
            calculatePriceValues()
          }}
          helpTooltip={(country !== "peru" && isMulti)
            ? (
              I18n.get('taxesHelp', 'Si tu producto tiene varios impuestos, puedes seleccionarlos dando clic sobre el nombre de cada uno en el listado desplegable.')
            )
            : null
          }
          menuPosition="absolute"
        />

        <div
          className="text-primary col-1 p-0 text-center"
          style={{
            lineHeight: '25px',
            fontSize: '20px',
            marginTop: '3rem'
          }}
        >
          =
        </div>
      </div>

      <Field
        name="price"
        component={renderField}
        type="number"
        className="col-sm-4 col-12"
        required
        label={capitalize(I18n.get('finalPrice', 'precio final'))}
        onChange={e => maxDecimals(e, 'price', decimal)}
        onBlur={() => { setUpDecimals('price', decimal); calculatePriceValues('price') }}
      />
    </>
  )
}

export default Price;
