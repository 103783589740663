import { createSlice } from '@reduxjs/toolkit';

const modalInitial = {
  isOpen: false,
  params: null,
}

const initialState = {
  contact: modalInitial,
  editItem: modalInitial,
  invoiceSettings: modalInitial,
  item: modalInitial,
}

const appSlice = createSlice({
  name: 'sideModals',
  initialState,
  reducers: {
    openSideModal: (state, action) => {
      state[action.payload.sideModal] = { isOpen: true, params: action.payload.params }
    },
    closeSideModal: (state, action) => {
      state[action.payload.sideModal] = { isOpen: false, params: null }
    },
    closeAllSideModals: () => {
      return initialState;
    }
  }
});

const { actions, reducer } = appSlice;

export const { openSideModal, closeSideModal, closeAllSideModals } = actions;

export default reducer;