// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 23 23" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      fill="#FFD772"
      d="M11.1689 22.4198C5.18582 22.4198 0.335571 17.5695 0.335571 11.5864C0.335571 5.60336 5.18582 0.753113 11.1689 0.753113C17.152 0.753113 22.0022 5.60336 22.0022 11.5864C22.0022 17.5695 17.152 22.4198 11.1689 22.4198ZM12.5231 17.557C12.5018 16.4667 12.4859 15.3532 12.4753 14.2165C12.4647 13.0798 12.4487 11.9663 12.4275 10.876C12.3213 10.6556 12.146 10.4933 11.9017 10.3889C11.6575 10.2845 11.3973 10.2323 11.1211 10.2323C10.8237 10.2323 10.5582 10.2903 10.3245 10.4063C10.0909 10.5223 9.92095 10.6962 9.81474 10.9282C9.83598 12.0185 9.85191 13.132 9.86253 14.2687C9.87315 15.4054 9.88908 16.5189 9.91033 17.6092C9.97405 17.8643 10.1334 18.0528 10.3883 18.1746C10.6432 18.2964 10.9193 18.3573 11.2167 18.3573C11.5353 18.3573 11.8274 18.2906 12.0929 18.1572C12.3584 18.0238 12.5018 17.8237 12.5231 17.557ZM10.2056 7.12414C9.94503 6.85761 9.81474 6.54379 9.81474 6.18268C9.81474 5.80437 9.94503 5.48195 10.2056 5.21541C10.4662 4.94888 10.7827 4.81561 11.1549 4.81561C11.5272 4.81561 11.8483 4.94888 12.1182 5.21541C12.3881 5.48195 12.5231 5.80437 12.5231 6.18268C12.5231 6.54379 12.3881 6.85761 12.1182 7.12414C11.8483 7.39068 11.5272 7.52395 11.1549 7.52395C10.7827 7.52395 10.4662 7.39068 10.2056 7.12414Z"
    />
  </svg>
)
