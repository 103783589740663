import axios from 'axios';

const get = async (url, params = null, userConfig = null) => await axios.get(url, {
  params,
  ...userConfig,
});

const post = async (url, params = null, urlParams = null, userConfig = null) => await axios.post(url, params, {
  headers: { "Content-Type": "application/json" },
  params: urlParams,
  ...userConfig,
});

const put = async (url, params = null, urlParams = null, userConfig = null) => await axios.put(url, params, {
  params: urlParams,
  ...userConfig,
});

const patch = async (url, params = null, urlParams = null, userConfig = null) => await axios.patch(url, params, {
  params: urlParams,
  ...userConfig,
});

const httpDelete = async (url, urlParams = null, userConfig = null) => await axios.delete(url, {
  params: urlParams,
  ...userConfig,
});

const exportDefault = {
  get,
  post,
  put,
  patch,
  delete: httpDelete,
}

export default exportDefault
