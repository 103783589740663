// eslint-disable-next-line
export default ({ id, className, fill }) => (
  <svg
    id={id}
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill ?? "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15.25l-5.583-6.7h11.166L12 15.25z"
    />
  </svg>
)
