import React, { useEffect } from 'react';

import renderIdentification, {
  searchIdentification,
} from './BasicData/Identification';
import renderName from './BasicData/Name';
import renderAddress from './BasicData/Address';
import renderInfo from './BasicData/Info';
import renderKindOfPerson from './BasicData/KindOfPerson';
import renderTaxLiability from './BasicData/TaxLiability';
import renderIVACondition from './BasicData/IVACondition';
import kindOfPersonOptions from '../../countriesData/colombia/kindOfPerson';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../selectors/app';
const BasicData = (props) => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const kinfOfPerson = get(props, 'values.kindOfPerson.key', '');
  const identificationRuc = get(props, 'values.identification.ruc', '');
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    if (props.country === 'colombia') {
      props.form.mutators.setValues('address', {});
      if (identificationType === 'FOREIGN_NIT') {
        props.form.mutators.setValues(
          'kindOfPerson',
          kindOfPersonOptions.find((option) => option.key === 'LEGAL_ENTITY')
        );
      } else {
        props.form.mutators.setValues(
          'kindOfPerson',
          kindOfPersonOptions.find((option) => option.key === 'PERSON_ENTITY')
        );
      }
    }
  }, [identificationType]);

  useEffect(() => {
    if (props.country === 'panama') {
      props.form.change('kindOfPerson', null);
    }
  }, [identificationType]);

  useEffect(() => {
    if (props.country === 'panama') {
      if (
        kinfOfPerson &&
        kinfOfPerson !== 'PERSON_EMPTY' &&
        identificationRuc
      ) {
        searchIdentification(APIGraphql, kinfOfPerson, props);
      }
      if (kinfOfPerson === 'PERSON_EMPTY') {
        props.form.change('kindOfPerson', null);
      }
    }
  }, [kinfOfPerson]);

  return (
    <div className='form-body__fields form-row justify-content-start'>
      {renderIdentification(props)}

      {renderKindOfPerson(props)}

      {renderTaxLiability(props)}

      {renderName(props)}

      {renderIVACondition(props)}

      {renderAddress(props)}

      {renderInfo(props)}
    </div>
  );
};

export default BasicData;
