function MoneySvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.375 11.6667C9.375 10.401 10.401 9.37499 11.6667 9.37499C12.9323 9.37499 13.9583 10.401 13.9583 11.6667C13.9583 12.9323 12.9323 13.9583 11.6667 13.9583C10.401 13.9583 9.375 12.9323 9.375 11.6667ZM11.6667 10.625C11.0914 10.625 10.625 11.0914 10.625 11.6667C10.625 12.242 11.0914 12.7083 11.6667 12.7083C12.242 12.7083 12.7083 12.242 12.7083 11.6667C12.7083 11.0914 12.242 10.625 11.6667 10.625Z'
        fill='#94A3B8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.16667 3.54166C3.55888 3.54166 2.97598 3.7831 2.54621 4.21287C2.11644 4.64264 1.875 5.22554 1.875 5.83332V10.8333C1.875 11.4411 2.11644 12.024 2.54621 12.4538C2.97598 12.8835 3.55888 13.125 4.16667 13.125H5.20833V14.1667C5.20833 15.4323 6.23435 16.4583 7.5 16.4583H15.8333C17.099 16.4583 18.125 15.4323 18.125 14.1667V9.16666C18.125 7.901 17.099 6.87499 15.8333 6.87499H14.7917V5.83332C14.7917 5.22554 14.5502 4.64264 14.1205 4.21287C13.6907 3.7831 13.1078 3.54166 12.5 3.54166H4.16667ZM13.5417 6.87499V5.83332C13.5417 5.55706 13.4319 5.2921 13.2366 5.09675C13.0412 4.9014 12.7763 4.79166 12.5 4.79166H4.16667C3.8904 4.79166 3.62545 4.9014 3.4301 5.09675C3.23475 5.2921 3.125 5.55706 3.125 5.83332V10.8333C3.125 11.1096 3.23475 11.3745 3.4301 11.5699C3.62545 11.7652 3.8904 11.875 4.16667 11.875H5.20833V9.16666C5.20833 7.901 6.23435 6.87499 7.5 6.87499H13.5417ZM6.45833 9.16666C6.45833 8.59136 6.9247 8.12499 7.5 8.12499H15.8333C16.4086 8.12499 16.875 8.59136 16.875 9.16666V14.1667C16.875 14.742 16.4086 15.2083 15.8333 15.2083H7.5C6.9247 15.2083 6.45833 14.742 6.45833 14.1667V9.16666Z'
        fill='#94A3B8'
      />
    </svg>
  );
}

export default MoneySvg;
