import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { country } from '../selectors/company'
import { handleError } from '../utils/errors'
import configAPI from './configAPI';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const adapter = createEntityAdapter();

export const fetchRegimes = createAsyncThunk(
  'regimes/fetch',
  async (params, { rejectWithValue, getState }) => {
    try {
      const applicationVersion = country(getState());
      // ex queries.allRegimes
      const data = await configAPI.get('/company/regimes', {
        application_version: applicationVersion,
        ...params,
      })
      let regimes = []

      if (!!get(data, 'data', null))
        regimes = regimes.concat(get(data, 'data'))

      return regimes;
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

const appSlice = createSlice({
  name: 'regimes',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchRegimes.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchRegimes.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchRegimes.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer } = appSlice;

export const regimesSelector = adapter.getSelectors(state => state.regimes);

export default reducer;