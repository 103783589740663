import React from 'react'
import { toast } from 'react-toastify';

import SuccessIcon from '../components/svg/toast/Success'
import InfoIcon from '../components/svg/toast/Info'
import WarningIcon from '../components/svg/toast/Warning'
import ErrorIcon from '../components/svg/toast/Error'

export const success = ({ title, titleIcon, subtitle, link, params }) => {
  toast.success((
    <div className="d-flex align-items-start justify-content-start">
      <div style={{ width: '24px' }} className="mr-4">
        <SuccessIcon />
      </div>

      <div className="d-flex flex-column">
        {!!title && (
          <div className="text-capitalize-first Toastify__toast-container--title">
            {title} {titleIcon}
          </div>
        )}
        {!!subtitle && (
          <div className="text-capitalize-first Toastify__toast-container--subtitle">
            {subtitle}
            {!!link && (
              <a className="btn-link my-3" href={link.url} target="_blank" rel="noreferrer">
                {link.message}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  ), {
    autoClose: 9000,
    ...params
  })
}

export const info = ({ title, subtitle }) => {
  toast.info((
    <div className="d-flex align-items-start justify-content-start">
      <div style={{ width: '24px' }} className="mr-2">
        <InfoIcon />
      </div>

      <div className="d-flex flex-column">
        {!!title && (
          <div className="text-capitalize-first Toastify__toast-container--title">
            {title}
          </div>
        )}
        {!!subtitle && (
          <div className="text-capitalize-first Toastify__toast-container--subtitle">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  ), {
    autoClose: 4000
  })
}

export const warning = ({ title, subtitle, params }) => {
  toast.warning((
    <div className="d-flex align-items-start justify-content-start">
      <div style={{ width: '24px' }} className="mr-2">
        <WarningIcon />
      </div>

      <div className="d-flex flex-column">
        {!!title && (
          <div className="text-capitalize-first Toastify__toast-container--title">
            {title}
          </div>
        )}
        {!!subtitle && (
          <div className="text-capitalize-first Toastify__toast-container--subtitle">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  ), {
    autoClose: 4000,
    ...params
  })
}

export const error = ({ title, subtitle, params }) => {
  toast.error((
    <div className="d-flex align-items-start justify-content-start">
      <div style={{ width: '24px' }} className="mr-2">
        <ErrorIcon />
      </div>

      <div className="d-flex flex-column">
        {!!title && (
          <div className="text-capitalize-first Toastify__toast-container--title">
            {title}
          </div>
        )}
        {!!subtitle && (
          <div className="text-capitalize-first Toastify__toast-container--subtitle">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  ), {
    autoClose: false,
    ...params,
  })
}