import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as queries from '../graphql/queries'
import { handleError } from '../utils/errors'
import { APIGraphqlSelector, station as stationSelector } from '../selectors/app'

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
}

export const fetchPayments = createAsyncThunk(
  'qr-payemnts/fetch',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const station = stationSelector(getState())
    try {
      const APIGraphql = APIGraphqlSelector(getState());

      const response = await APIGraphql(graphqlOperation(queries.allProviderPayments, {
        idStation: station.id,
      }))

      const payments = get(response, 'data.allProviderPayments', [])
      
      dispatch(receivePayments(payments))

    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
)

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => +b.id - +a.id
});

const appSlice = createSlice({
  name: 'qr-payemnts',
  initialState,
  reducers: {
    receivePayments: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    updatePayment: adapter.updateOne,
    replacePayment: (state, action) => {
      const index = state.ids.indexOf(action.payload.id)

      if (index >= 0 && !!get(action, 'payload.new.id')) {
        state.ids[index] = action.payload.new.id;
        state.entities[action.payload.new.id] = action.payload.new;
        delete state.entities[action.payload.id]
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPayments.pending, state => {
      state.error = null;
      state.loading = 'loading'
    })
    builder.addCase(fetchPayments.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = 'idle'
    })
    builder.addCase(fetchPayments.fulfilled, state => {
      state.error = null;
      state.loading = 'idle'
    })
  }
});

const { reducer, actions } = appSlice;

export const paymentsSelector = adapter.getSelectors(state => state.qrPayments);

export const { receivePayments, updatePayment, replacePayment } = actions;

export default reducer;