// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1324_10873)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.70364 2.03697C5.04745 1.69315 5.51377 1.5 6 1.5H10C10.4862 1.5 10.9525 1.69315 11.2964 2.03697C11.6402 2.38079 11.8333 2.8471 11.8333 3.33333V5.5H12.6667C13.1529 5.5 13.6192 5.69315 13.963 6.03697C14.3068 6.38079 14.5 6.8471 14.5 7.33333V10C14.5 10.4862 14.3068 10.9525 13.963 11.2964C13.6192 11.6402 13.1529 11.8333 12.6667 11.8333H11.8333V12.6667C11.8333 13.6792 11.0125 14.5 10 14.5H6C4.98748 14.5 4.16667 13.6792 4.16667 12.6667V11.8333H3.33333C2.8471 11.8333 2.38079 11.6402 2.03697 11.2964C1.69315 10.9525 1.5 10.4862 1.5 10V7.33333C1.5 6.8471 1.69315 6.38079 2.03697 6.03697C2.38079 5.69315 2.8471 5.5 3.33333 5.5H4.16667V3.33333C4.16667 2.8471 4.35982 2.38079 4.70364 2.03697ZM5.16667 12.6667C5.16667 13.1269 5.53976 13.5 6 13.5H10C10.4602 13.5 10.8333 13.1269 10.8333 12.6667V10C10.8333 9.53976 10.4602 9.16667 10 9.16667H6C5.53976 9.16667 5.16667 9.53976 5.16667 10V12.6667ZM11.8333 10.8333V10C11.8333 8.98748 11.0125 8.16667 10 8.16667H6C4.98748 8.16667 4.16667 8.98748 4.16667 10V10.8333H3.33333C3.11232 10.8333 2.90036 10.7455 2.74408 10.5893C2.5878 10.433 2.5 10.221 2.5 10V7.33333C2.5 7.11232 2.5878 6.90036 2.74408 6.74408C2.90036 6.5878 3.11232 6.5 3.33333 6.5H12.6667C12.8877 6.5 13.0996 6.5878 13.2559 6.74408C13.4122 6.90036 13.5 7.11232 13.5 7.33333V10C13.5 10.221 13.4122 10.433 13.2559 10.5893C13.0996 10.7455 12.8877 10.8333 12.6667 10.8333H11.8333ZM10.8333 3.33333V5.5H5.16667V3.33333C5.16667 3.11232 5.25446 2.90036 5.41074 2.74408C5.56702 2.5878 5.77899 2.5 6 2.5H10C10.221 2.5 10.433 2.5878 10.5893 2.74408C10.7455 2.90036 10.8333 3.11232 10.8333 3.33333Z" fill="#334155" />
    </g>
    <defs>
      <clipPath id="clip0_1324_10873">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);