import React, { useCallback, useEffect } from 'react'
import { I18n } from '@aws-amplify/core'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { graphqlOperation } from 'aws-amplify'
import { get, has } from 'lodash'

import * as mutations from '../../graphql/mutations';
import notificationsAPI from '../../reducers/notificationsAPI';
import { setUser } from '../../reducers/auth';
import { userSelector } from '../../selectors/auth';
import { notificationsSelector } from '../../selectors/company';
import { activePriceLists } from '../../selectors/priceLists'
import { APIGraphqlSelector } from '../../selectors/app';
import { notificationsTransformer } from './utils';
import {station as stationSelectors} from '../../selectors/app';

const AssociatePriceListsToTerminalsNotification = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const notifications = useSelector(notificationsSelector);
  const priceLists = useSelector(activePriceLists);
  const station = useSelector(stationSelectors);

  const selectedBody = useCallback(() => {
    const notificationInfo = {
      userId: user.idGlobal,
      title: I18n.get('AssociatePriceListsToTerminalsNotification.title', '¡Configura tu lista de precios favorita! 💡'),
      text: I18n.get('AssociatePriceListsToTerminalsNotification.body', 'Puedes definir una lista de precios en tu terminal para que venga seleccionada por defecto y ahorrar tiempo al vender.'),
      actionCall: process.env.REACT_APP_ALEGRA_COOKIE === 'al-test-days-of-thunder' ? 'https://almost-pos.alegra.com/settings/station' : 'https://pos.alegra.com/settings/station',
      actionLabel: I18n.get('AssociatePriceListsToTerminalsNotification.action', 'Ir a configurarla'),
      type: "info",
    };
    return notificationInfo;
  }, [user.idGlobal])

  useEffect(() => {
    async function checkAssociatePriceListsToTerminalsNotification() {
      if (notifications?.find(n => n['associate-price-lists-to-terminals'])) return;

      if (priceLists.length < 2) return;

      if (!has(station, 'idPriceList')) return;

      try {
        // create cookie to validate if the notification has been shown
        const response = await APIGraphql(graphqlOperation(mutations.updateUser, notificationsTransformer('associate-price-lists-to-terminals')));

        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')))
        }

        await notificationsAPI.post('/notifications',
          notificationBody({
            ...selectedBody(),
          }));
        
      } catch (err) {
        console.log("error while creating notification", err);
      }
    }
    checkAssociatePriceListsToTerminalsNotification();
    //eslint-disable-next-line
  }, [APIGraphql, dispatch, selectedBody, priceLists, notifications, station])

  return (
    <></>
  )
}

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type }) => ({
  name: "associate-price-lists-to-terminals",
  teamInCharge: "pos",
  receptors: {
    users: [userId]
  },
  availability: {
    from: from ? from : dayjs().toISOString(),
    to: to ? to : dayjs().add(30, 'days').toISOString()
  },
  message: [
    {
      channel: "web",
      type: "localized",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})

export default AssociatePriceListsToTerminalsNotification;