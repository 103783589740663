function StationNewSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H9.01C9.42421 11.25 9.76 11.5858 9.76 12C9.76 12.4142 9.42421 12.75 9.01 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12Z'
        fill='#334155'
      />
      <path
        d='M13 11.25C12.5858 11.25 12.25 11.5858 12.25 12C12.25 12.4142 12.5858 12.75 13 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H13Z'
        fill='#334155'
      />
      <path
        d='M8.25 16C8.25 15.5858 8.58579 15.25 9 15.25H9.01C9.42421 15.25 9.76 15.5858 9.76 16C9.76 16.4142 9.42421 16.75 9.01 16.75H9C8.58579 16.75 8.25 16.4142 8.25 16Z'
        fill='#334155'
      />
      <path
        d='M13 15.25C12.5858 15.25 12.25 15.5858 12.25 16C12.25 16.4142 12.5858 16.75 13 16.75H15C15.4142 16.75 15.75 16.4142 15.75 16C15.75 15.5858 15.4142 15.25 15 15.25H13Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.35352 4.25C8.67998 3.09575 9.74122 2.25 11 2.25H13C14.2588 2.25 15.32 3.09575 15.6465 4.25H17C17.7293 4.25 18.4288 4.53973 18.9445 5.05546C19.4603 5.57118 19.75 6.27065 19.75 7V19C19.75 19.7293 19.4603 20.4288 18.9445 20.9445C18.4288 21.4603 17.7293 21.75 17 21.75H7C6.27065 21.75 5.57118 21.4603 5.05546 20.9445C4.53973 20.4288 4.25 19.7293 4.25 19V7C4.25 6.27065 4.53973 5.57118 5.05546 5.05546C5.57118 4.53973 6.27065 4.25 7 4.25H8.35352ZM9.75 5C9.75 4.30964 10.3096 3.75 11 3.75H13C13.6904 3.75 14.25 4.30964 14.25 5C14.25 5.69036 13.6904 6.25 13 6.25H11C10.3096 6.25 9.75 5.69036 9.75 5ZM15.6465 5.75H17C17.3315 5.75 17.6495 5.8817 17.8839 6.11612C18.1183 6.35054 18.25 6.66848 18.25 7V19C18.25 19.3315 18.1183 19.6495 17.8839 19.8839C17.6495 20.1183 17.3315 20.25 17 20.25H7C6.66848 20.25 6.35054 20.1183 6.11612 19.8839C5.8817 19.6495 5.75 19.3315 5.75 19V7C5.75 6.66848 5.8817 6.35054 6.11612 6.11612C6.35054 5.8817 6.66848 5.75 7 5.75H8.35352C8.67998 6.90425 9.74122 7.75 11 7.75H13C14.2588 7.75 15.32 6.90425 15.6465 5.75Z'
        fill='#334155'
      />
    </svg>
  );
}

export default StationNewSvg;
