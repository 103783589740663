// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="56" 
    height="55" 
    viewBox="0 0 56 55" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" clipRule="evenodd"
      d="M19.2819 36.6667H37.6152V41.25H19.2819V36.6667ZM19.2819 27.5H37.6152V32.0833H19.2819V27.5ZM33.0319 4.58334H14.6986C12.1777 4.58334 10.1152 6.64584 10.1152 9.16668V45.8333C10.1152 48.3542 12.1548 50.4167 14.6757 50.4167H42.1986C44.7194 50.4167 46.7819 48.3542 46.7819 45.8333V18.3333L33.0319 4.58334ZM42.1986 45.8333H14.6986V9.16668H30.7402V20.625H42.1986V45.8333Z"
    />
  </svg>
)
