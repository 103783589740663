import React from 'react'
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

import Loading from '../../svg/Loading'

const Bottom = ({ closeText, submitText, onClose, disabled, submitting, hiddeRequired, requiredMessage }) => (
  <div className="settings__bottom w-100 p-3 d-flex 
    flex-column flex-sm-row justify-content-sm-between 
    align-items-sm-center align-items-start"
  >
    <div className="required-message mb-3 mb-sm-0">
      {!!requiredMessage && requiredMessage}
      {
        (!hiddeRequired && !requiredMessage) && (
          <>
            <i className="text-primary mr-2">* </i>
            <span className="text-capitalize-first text-muted">
              {I18n.get('requiredFields', 'Campos obligatorios')}
            </span>
          </>
        )
      }
    </div>
    <div className="settings__bottom-buttons d-flex">
      <button
        type="button"
        className="btn btn-cancel text-capitalize-first mr-3 shadow"
        onClick={() => onClose()}
      >
        {closeText || I18n.get('cancel', 'cancelar')}
      </button>
      <button 
        type="submit" 
        className="btn btn-submit text-capitalize-first shadow" 
        disabled={disabled || submitting}
      >
        {submitting
          ? <Loading className="icon-primary" />
          : submitText || I18n.get('save', 'guardar')
        }
      </button>
    </div>
  </div>
)

Bottom.propTypes = {
  closeText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  requiredMessage: PropTypes.element,
}

export default Bottom;