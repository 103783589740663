
import * as SentryReact from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
// import { get } from 'lodash'

const start = ({ history }) => {
  const environment = process.env.REACT_APP_BRANCH === 'master'
    ? 'production' : 'testing';

  SentryReact.init({
    dsn: 'https://ab30fdffaad343ec8998eaf87978a827@o557940.ingest.sentry.io/211813',
    environment,
    release: process.env.REACT_APP_COMMIT_ID,
    // integrations: [
    //   new Integrations.BrowserTracing({
    //     routingInstrumentation: SentryReact.reactRouterV5Instrumentation(history)
    //   })
    // ],
    whitelistUrls: [
      /alegra\.com/,
    ],
    ignoreErrors: [
      "The database connection is closing.",
      "handleIncomingSubscriptionMessage",
      "AWSAppSyncRealTimeProvider",
      "Failed to read the 'responseText'",
      "Failed to fetch"
    ],
    // beforeSend: (event, hint) => {
    //   const error = hint.originalException;
    //   if (get(error, 'message', '').match(/Cannot read properties of null \(reading 'error'\)/i))
    //     return null
    //   return event;
    // },
    normalizeDepth: 5,
    maxBreadcrumbs: 20,
    debug: true,
    tracesSampleRate: 0.1,
    sampleRate: 0.4,
  })
}

const Sentry = {
  start
}

export default Sentry;