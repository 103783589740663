import React from 'react';

import Contact from '../../forms/basicContact/Contact';

const FormBody = props => { 
  return (
    <>
      <div className="d-flex flex-column px-2 pt-2 pb-0">
        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom`}>
          <Contact.BasicData {...props}/>
        </div>
      </div>
      
    </>
  )
}

export default FormBody;
