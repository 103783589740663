import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { get, upperCase } from 'lodash';

import { toast } from '../../../../utils'
import * as contactsDB from '../../../../database/contactsDB'
import { idTypes as identificationsColombia } from '../../../countriesData/colombia/idTypes';
import identificationsCostaRica from '../../../countriesData/costaRica/identificationTypes';
import identificationsPeru from '../../../countriesData/peru/identificationTypes';
import identificationsArgentina from '../../../countriesData/argentina/identificationTypes';
import identificationsDominicana from '../../../countriesData/republicaDominicana/identificationTypes';
import identificationsPanama from "../../../countriesData/panama/identificationTypes";
import identificationsSpain from "../../../countriesData/spain/identificationTypes";
import peruAddresses from '../../../countriesData/peru/address.json';
import kindOfPersonOptions from '../../../countriesData/colombia/kindOfPerson';
import argIvaConditions from '../../../countriesData/argentina/ivaConditions';
import { renderField, renderSelect, renderIdentificationNumberField } from '../../fields/V0/Fields';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../../selectors/app';
import contactsAPI from '../../../../reducers/contactsAPI';

const identificationTypeLabel = () => I18n.get('identificationType', 'Tipo de identificación');
const identificationLabel = () => I18n.get('identification', 'identificación');

const calculateDV = props => {
  const id = get(props, 'values.identification.number', null);
  let dv = null;

  if (!!id && id.length <= 15) {
    const primeNumbers = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
    let totalSum = 0;

    for (let i = 0; i < id.length; i++) {
      totalSum += (id[i] * primeNumbers[id.length - i - 1]);
    }

    const mod = totalSum % 11;

    dv = [0, 1].indexOf(mod) !== -1 ? mod : 11 - mod;
  }

  props.form.change('identification.dv', dv);
}

const getIdentificationNumberType = identificationNumber => {
  if (/^\d{8}$/.test(identificationNumber))
    return 'DNI';
  if (/^\d{11}$/.test(identificationNumber))
    return 'RUC';
  return null;
}

const isContactCreated = ({ country, values, contactCreated }) => {
  if (country === 'colombia' || country === 'costaRica' || country === 'peru' || country === 'argentina' || country === 'republicaDominicana') {
    const type = get(values, 'identification.type.key', null);
    const number = get(values, 'identification.number', null);
    contactsDB.searchIdentification({ type, number }, country)
      .then(response => contactCreated(response))
      .catch(() => contactCreated(null))
  } else {
    const id = get(values, 'identification', null);
    contactsDB.searchIdentification(id, country)
      .then(response => contactCreated(response))
      .catch(() => contactCreated(null))
  }
}

export const searchIdentification = async (api, idType, props) => {
  // ex queries.searchClientById
  try {
    const identification = props.country === 'panama' ? get(props, 'values.identification.ruc') : get(props, 'values.identification.number');
    const identificationType = props.country === 'panama' ? { kindOfPerson: idType } : { idType: idType };
    props.setSearchingId && props.setSearchingId(true);

    const response = await contactsAPI.get('/search-by-id-number', {
      identification,
      ...identificationType,
      version: props.country
    })

    props.setSearchingId && props.setSearchingId(false);

    const data = get(response, 'data', null) ? get(response, 'data') : null

    if (!!data) {
      let propsCopy = { ...props };
      switch (props.country) {
        case 'peru':
          propsCopy.values.identification.type = identificationsPeru.find(id => id.key === idType)
          isContactCreated(propsCopy)

          props.form.change('firstName', get(data, 'name', null));
          props.form.change('identification.type', identificationsPeru.find(id => id.key === idType))
          props.form.change('address.combined', peruAddresses.find(address => address.ubigeo === get(data, 'address.ubigeo', null)));
          props.form.change('address.address', get(data, 'address.address', null));
          break;
        case 'colombia':
          if (idType !== 'NIT' && get(data, 'socialReason')) {
            const nit = identificationsColombia(false).find(id => id.key === 'NIT')
            props.form.change('identification.type', nit)
            isContactCreated({ country: propsCopy.country, values: { ...propsCopy.values, identification: { ...propsCopy.values.identification, type: nit } }, contactCreated: propsCopy.contactCreated })
          } else {
            props.form.change('identification.type', identificationsColombia(false).find(id => id.key === idType))
          }

          if (!!get(data, 'name', null)) {
            props.form.change('firstName', get(data, 'name', null));
          } else {
            if (!!get(data, 'firstName', null)) {
              props.form.change('firstName', get(data, 'firstName', null));
              props.form.change('kindOfPerson', kindOfPersonOptions[1]);
            }
            if (!!get(data, 'socialReason', null)) {
              props.form.change('firstName', get(data, 'socialReason', null));
              props.form.change('kindOfPerson', kindOfPersonOptions[0]);
            }
            if (!!get(data, 'firstLastname', null))
              props.form.change('lastName', `${get(data, 'firstLastname', null)}${!!get(data, 'secondLastname', null) ? ' ' + get(data, 'secondLastname', null) : ''}`);
            props.form.change('secondName', get(data, 'secondName', null));
          }
          if (!!get(data, 'email', null))
            props.form.change('email', get(data, 'email', null));
          break;
        case 'costaRica':
          propsCopy.values.identification.type = identificationsCostaRica.find(id => id.key === get(data, 'identificationType.key', null))
          isContactCreated(propsCopy)

          props.form.change('firstName', get(data, 'name', null));
          props.form.change('identification.type', identificationsCostaRica.find(id => id.key === get(data, 'identificationType.key', null)))
          break;
        case 'republicaDominicana':
          props.form.change('firstName', get(data, 'name', null));
          props.form.change('identification.type', identificationsDominicana.find(id => id.key === idType))
          break;
        case 'argentina':
          const _ivaCondition = get(argIvaConditions.filter(i => upperCase(i.value) === upperCase(get(data, 'ivaCondition.Name'))), '0', null)

          props.form.change('firstName', get(data, 'name', null));
          props.form.change('address.address', get(data, 'address.0.Address', null));
          props.form.change('address.postalCode', get(data, 'address.0.PostalCode', null));
          props.form.change('ivaCondition', _ivaCondition);

          if (!get(data, 'name', null)) {
            toast.warning({
              title: I18n.get('checkTheCUIT', 'Revisá el CUIT de tu cliente. 🔍'),
              subtitle: I18n.get('checkTheCUITSubtitle', 'Validá que esté bien escrito y si es correcto, te recomendamos completar los datos de forma manual.')
            })
          }
          break;
        case 'panama':
          const isSuccess = get(data, 'success', null);
          const kindOfPerson = get(props, 'values.kindOfPerson.key', null);
          if (isSuccess) {
            props.form.change('isCorrectRuc', true);
            if (kindOfPerson === 'PERSON_ENTITY') {
              const [name, lastName] = get(data, 'name', null).split(' ');
              props.form.change('firstName', name);
              props.form.change('lastName', lastName);
            } else {
              props.form.change('firstName', get(data, 'name', null));
            }
            props.form.change('identification.dv', get(data, 'checkDigit', null));
          } else {
            props.form.change('isCorrectRuc', false);
            props.form.change('firstName', null);
            props.form.change('lastName', null);
            props.form.change('identification.dv', null);
          }
          break;
        default:
          break;
      }
    }
  } catch {
    props.setSearchingId && props.setSearchingId(false);
    if (!(props.country === 'colombia')) {
      toast.warning({
        title: I18n.get(`${!!idType ? idType : 'ID'}EnteredDoesNotExistOrIsInvalidTitle`, `Revisa el ${!!idType ? idType : 'ID'} de tu cliente. 🔍`),
        subtitle: I18n.get(`${!!idType ? idType : 'ID'}EnteredDoesNotExistOrIsInvalidSubtitle`, "Verifica que esté bien escrito y si es correcto, te recomendamos agregar los datos de forma manual."),
      })
    } else {
      toast.warning({
        title: I18n.get('identificationEnteredDoesNotExistOrIsInvalidTitle', 'Revisa la identificación de tu cliente. 🔍'),
        subtitle: I18n.get('identificationEnteredDoesNotExistOrIsInvalidSubtitle', "Verifica que esté bien escrito y si es correcto, te recomendamos agregar los datos de forma manual."),
      })
    }
  }
}

const ColombiaIdentification = props => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const country = get(props, 'country', '');
  const options = identificationsColombia(props.isElectronic);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const renderIdentificationField = (identificationType) => {
    switch (identificationType) {
      case 'NIT':
        return (
          <div className='col-md-6'>
            <div className="form-row">
              <Field
                name="identification.number"
                component={renderIdentificationNumberField}
                data-testid="client-id-input"
                type="text"
                label={identificationLabel()}
                required
                className="col-9"
                onBlur={() => {
                  calculateDV(props);
                  isContactCreated(props);
                }}
                searchHelp={I18n.get(`getNIT`, `consultar NIT`)}
                searchingIdentification={props.searchingId}
                onSearchIdentification={() => searchIdentification(APIGraphql, identificationType, props)}
                country={country}
              />

              <Field
                name="identification.dv"
                className="col-3"
                component={renderField}
                type="text"
                label={I18n.get('dv', 'DV')}
                disabled
              />
            </div>
          </div>
        );

      case 'CC':
        return (
          <div className='col-md-6'>
            <div className="form-row">
              <Field
                name="identification.number"
                component={renderIdentificationNumberField}
                data-testid="client-id-input"
                type="text"
                label={identificationLabel()}
                required
                className="col-12"
                onBlur={() => {
                  isContactCreated(props)
                }}
                searchHelp={I18n.get(`getCED`, `Consultar cédula`)}
                searchingIdentification={props.searchingId}
                onSearchIdentification={async () => {
                  await searchIdentification(APIGraphql, identificationType, props)
                  calculateDV(props);
                }}
                country={country}
              />
            </div>
          </div>
        );

      default:
        return (
          <Field
            name="identification.number"
            className="col-md-6"
            component={renderField}
            data-testid="client-id-input"
            type="text"
            label={identificationLabel()}
            onBlur={() => isContactCreated(props)}
            required
            country={country}
          />
        );
    }
  }
  return (
    <>
      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        label={identificationTypeLabel()}
        options={options}
        onBlur={() => isContactCreated(props)}
        getOptionLabel={option => `${option.abbreviature} - ${option.value}`}
        getOptionValue={option => option.key}
        required
      />
      {renderIdentificationField(identificationType)}
    </>
  )
}

const CostaRicaIdentification = props => {
  const identificationNumber = get(props, 'values.identification.number', '');
  const APIGraphql = useSelector(APIGraphqlSelector);

  return (
    <>
      <Field
        name="identification.number"
        className="col-md-6"
        component={renderIdentificationNumberField}
        data-testid="client-id-input"
        type="text"
        label={identificationLabel()}
        searchLabel={I18n.get('getID', `Consultar ID`)}
        searchingIdentification={props.searchingId}
        onSearchIdentification={get(identificationNumber, 'length') >= 9 && get(identificationNumber, 'length') <= 13
          ? () => searchIdentification(APIGraphql, null, props) : null}
        onBlur={() => isContactCreated(props)}
        required
      />

      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        options={identificationsCostaRica}
        label={identificationTypeLabel()}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
        onBlur={() => isContactCreated(props)}
        required
      />
    </>
  )
}

const ArgentinaIdentification = props => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const APIGraphql = useSelector(APIGraphqlSelector);

  return (
    <>
      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        options={identificationsArgentina}
        label={identificationTypeLabel()}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
        onBlur={() => isContactCreated(props)}
        required
      />

      {identificationType === 'CUIT'
        ? (
          <Field
            name="identification.number"
            component={renderIdentificationNumberField}
            data-testid="client-id-input"
            type="text"
            label={identificationLabel()}
            required
            className="col-md-6"
            onBlur={() => isContactCreated(props)}
            searchHelp={I18n.get('getCUIT', 'consultar CUIT')}
            searchingIdentification={props.searchingId}
            onSearchIdentification={() => searchIdentification(APIGraphql, identificationType, props)}
          />
        )
        : (
          <Field
            name="identification.number"
            className="col-md-6"
            component={renderField}
            data-testid="client-id-input"
            type="text"
            label={identificationLabel()}
            onBlur={() => isContactCreated(props)}
            required
          />
        )
      }
    </>
  )
}

const PeruIdentification = props => {
  const identificationNumberType = getIdentificationNumberType(get(props, 'values.identification.number'));
  const APIGraphql = useSelector(APIGraphqlSelector);

  return (
    <>
      <Field
        name="identification.number"
        className="col-md-6"
        component={renderIdentificationNumberField}
        data-testid="client-id-input"
        type="text"
        label={identificationLabel()}
        searchLabel={!!identificationNumberType
          ? I18n.get(`get${identificationNumberType}`, `Consultar ${identificationNumberType}`)
          : I18n.get(`getRUC`, `Consultar RUC`)
        }
        searchingIdentification={props.searchingId}
        onSearchIdentification={!!identificationNumberType
          ? () => searchIdentification(APIGraphql, identificationNumberType, props) : null}
        onBlur={() => isContactCreated(props)}
        required
      />

      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        options={identificationsPeru}
        label={identificationTypeLabel()}
        onBlur={() => isContactCreated(props)}
        getOptionLabel={option => `${option.abbreviature} - ${option.value}`}
        getOptionValue={option => option.key}
        required
      />
    </>
  )
}

const DominicanaIdentification = (props) => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const isElectronic = get(props, 'isElectronic', '');
  const showSearch = identificationType === 'RNC' || identificationType === 'CED'
  const APIGraphql = useSelector(APIGraphqlSelector);

  const getDOMIdentificationLabel = (identificationType) => {
    switch (identificationType) {
      case 'RNC':
        return I18n.get('', 'Numero de RNC');
      case 'CED':
        return I18n.get('', 'Numero de Cedula');
      case 'IE':
        return I18n.get('', 'Numero');
      default:
        return I18n.get('', 'RNC o Cédula');
    }
  }

  return (
    <>
      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        options={identificationsDominicana}
        label={identificationTypeLabel()}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.key}
        onBlur={() => isContactCreated(props)}
      />

      <Field
        name="identification.number"
        className="col-md-6"
        component={!!showSearch ? renderIdentificationNumberField : renderField}
        data-testid="client-id-input"
        type="text"
        label={getDOMIdentificationLabel(identificationType)}
        searchHelp={I18n.get(`get${identificationType}`, `Consultar ${identificationType}`)}
        searchingIdentification={props.searchingId}
        onSearchIdentification={!!showSearch
          ? () => searchIdentification(APIGraphql, identificationType, props) : null}
        onBlur={() => isContactCreated(props)}
        required={!!isElectronic}
      />
    </>
  )
}

const PanamaIdentification = props => {

  const options = identificationsPanama;
  const identificationType = get(props, 'values.identification.type.key', '');

  return (
    <>
      <Field
        name="identification.type"
        className="col-md-6"
        component={renderSelect}
        data-testid="client-id-type-input"
        label={identificationTypeLabel()}
        options={options}
        onBlur={() => isContactCreated(props)}
        getOptionLabel={option => `${option.value}`}
        getOptionValue={option => option.key}
        required={props.isElectronic}
      />
      {(identificationType === 'FOREIGN' || identificationType === 'FOREIGN_COMPANY') && (
        <Field
          name="identification.number"
          className="col-md-6"
          component={renderField}
          data-testid="client-id-input"
          type="text"
          label={identificationType === 'FOREIGN' ? I18n.get('passport', 'Pasaporte') : identificationLabel()}
          onBlur={() => isContactCreated(props)}
          required
        />
      )}
    </>
  )
}

const SpainIdentification = (props) => {
  const options = identificationsSpain;
  const identificationType = get(props, 'values.identification.type.key', '');

  return (
    <>
      <Field
        name='identification.type'
        className='col-md-6'
        component={renderSelect}
        data-testid='client-id-type-input'
        label={identificationTypeLabel()}
        options={options}
        onBlur={() => isContactCreated(props)}
        getOptionLabel={(option) => `${option.value}`}
        getOptionValue={(option) => option.key}
        required={true}
      />

      <Field
        name='identification.number'
        className='col-md-6'
        component={renderField}
        data-testid='client-id-input'
        type='text'
        label={
          identificationType === 'EXT'
            ? I18n.get('identificationNumber', 'Número de Identificación')
            : identificationLabel()
        }
        onBlur={() => isContactCreated(props)}
        required
      />
    </>
  );
};

const DefaultIdentification = props => (
  <Field
    name="identification"
    className="col-12"
    component={renderField}
    data-testid="client-id-input"
    type="text"
    label={identificationLabel()}
    onBlur={() => isContactCreated(props)}
  />
)

const RenderIdentification = props => {
  const [searchingId, setSearchingId] = useState(false);

  switch (props.country) {
    case 'colombia':
      return ColombiaIdentification({ ...props, searchingId, setSearchingId });

    case 'costaRica':
      return CostaRicaIdentification({ ...props, searchingId, setSearchingId });

    case 'peru':
      return PeruIdentification({ ...props, searchingId, setSearchingId });

    case 'argentina':
      return ArgentinaIdentification({ ...props, searchingId, setSearchingId });

    case 'republicaDominicana':
      return DominicanaIdentification({
        ...props,
        searchingId,
        setSearchingId,
      });

    case 'panama':
      return PanamaIdentification({ ...props, searchingId, setSearchingId });
    case 'spain':
      return SpainIdentification({ ...props, searchingId, setSearchingId });

    default:
      return DefaultIdentification({ ...props, searchingId, setSearchingId });
  }
}

export default RenderIdentification;