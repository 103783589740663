// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.9935 1.47882C7.66601 1.47882 7.3445 1.56654 7.06239 1.73287C6.78183 1.89828 6.55042 2.13543 6.39194 2.4199L1.66091 10.5826C1.64925 10.6027 1.63901 10.6236 1.63026 10.6452C1.5202 10.9161 1.47656 11.2094 1.50298 11.5007C1.52939 11.7919 1.6251 12.0726 1.7821 12.3194C1.93911 12.5661 2.15286 12.7717 2.40551 12.919C2.65816 13.0662 2.94237 13.1509 3.23442 13.166C3.25556 13.1671 3.27646 13.1668 3.29704 13.1653C3.30908 13.1662 3.32124 13.1667 3.3335 13.1667H12.6668L12.6703 13.1666C12.9695 13.1645 13.2635 13.0893 13.5269 12.9474C13.7902 12.8056 14.0149 12.6015 14.1812 12.3529C14.3475 12.1043 14.4505 11.8187 14.4812 11.5212C14.5118 11.2236 14.4692 10.9231 14.357 10.6458C14.3482 10.624 14.3379 10.6029 14.3261 10.5826L9.59505 2.41989C9.43657 2.13543 9.20517 1.89828 8.92461 1.73287C8.6425 1.56654 8.32099 1.47882 7.9935 1.47882Z" fill="#FBBF24" />
    <path d="M8.5 6C8.5 5.72386 8.27614 5.5 8 5.5C7.72386 5.5 7.5 5.72386 7.5 6V7.33333C7.5 7.60948 7.72386 7.83333 8 7.83333C8.27614 7.83333 8.5 7.60948 8.5 7.33333V6Z" fill="#0F172A" />
    <path d="M8.5 10C8.5 9.72386 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.72386 7.5 10V10.0067C7.5 10.2828 7.72386 10.5067 8 10.5067C8.27614 10.5067 8.5 10.2828 8.5 10.0067V10Z" fill="#0F172A" />
  </svg>
)
