// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M12.002 13.773L4 8.693v7.972c0 .818.673 1.476 1.498 1.476h13c.833 0 1.502-.658 1.502-1.476V8.694l-7.998 5.08zM5.498 5.001c-.772 0-1.408.58-1.489 1.321-.004.051-.009.1-.009.155l8.002 5.076L20 6.477A1.494 1.494 0 0 0 18.497 5H5.498z"
    />
  </svg>
)