import React from 'react'
import { I18n } from 'aws-amplify';
import Loading from '../../../svg/NewLoading';

const Activate5UVTLoading = () => (
  <>
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="mb-4 mt-5 px-5 d-flex flex-column align-items-center">
        <Loading className="icon-primary icon x4" />
        <p className="h4 mt-4 uvt-description text-capitalize-first text-subtitle">
          {I18n.get('activate5UVTOnboard.loading', 'Activando el cambio automático...')}
        </p>
      </div>
    </div>

    <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center shadow">
      <div className="modal__bottom-buttons d-flex">
        <button
          type="button"
          className="btn btn-cancel btn-no-border text-capitalize-first"
          disabled
        >
          {I18n.get('avoidForNow', 'Omitir por ahora')}
        </button>
        <button
          type="button"
          data-testid="modal-submit-btn"
          className="btn btn-submit text-capitalize-first"
          disabled
        >
          {I18n.get('activateDocumentChange', 'Activar cambio de documento')}
        </button>
      </div>
    </div>
  </>
)

export default Activate5UVTLoading