import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import alegraAPI from "../../../reducers/alegraAPI";
import { useRefresh } from "../../../hooks/queryHooks/useRefresh";
import { useCancel } from "../../../hooks/queryHooks/useCancel";
import { get } from "lodash";
import { replaceAndParse, toast } from "../../../utils";
import { I18n } from "aws-amplify";
import { handleError } from "../../../utils/errors";
import { addItemCategory, removeItemCategory, updateItemCategory as updateItemCategoryReducer } from "../../../reducers/itemCategories";
import { saveImage } from "./utils";
import { sendGTMEvent, sendNewGTMEvent } from "../../../reducers/company";

const deleteItemCategory = async ({ id, itemCategory }) => {
  await alegraAPI.delete(`/item-categories/${id}`);
  return { id, itemCategory }
}

const createItemCategory = async ({ itemCategory }) => {
  const response = await alegraAPI.post('/item-categories',
    itemCategory
  );

  const image = get(itemCategory, 'image');
  if (!!image) {
    response.data.image = await saveImage(image, get(response, 'data.id'));
  }

  return response;
}

const updateItemCategory = async ({ itemCategory }) => {
  const image = get(itemCategory, 'image');
  if (!!image) {
    await saveImage(image, itemCategory?.id)
  }

  const response = await alegraAPI.put(`/item-categories/${itemCategory?.id}`,
    itemCategory
  );

  return response;
};

export const useDeleteItemCategory = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh()
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['deleteItemCategory'], {
    mutationFn: deleteItemCategory,
  });

  return useMutation({
    mutationKey: ['deleteItemCategory'],
    mutationFn: deleteItemCategory,
    onMutate: async () => {
      await cancel('itemCategories');
    },
    onError: (error) => {
      const responseError = get(error, 'response.data.message', '');

      toast.error({
        title: I18n.get('itemCategoryDeletedError', 'Error eliminando la categoría'),
        subtitle: !!responseError ? responseError : handleError(error)
      })
    },
    onSuccess: (_, variables) => {
      toast.success({
        title: I18n.get('itemCategoryDeletedSuccessfully', 'La categoría ya está eliminada.'),
      })
      dispatch(removeItemCategory(get(variables, 'id')))
    },
    onSettled: () => {
      refresh()
    }
  })
}

export const useUpdateItemCategory = () => {
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const cancel = useCancel();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['updateItemCategory'], {
    mutationFn: updateItemCategory
  });

  return useMutation({
    mutationKey: ['updateItemCategory'],
    mutationFn: updateItemCategory,
    onMutate: async () => {
      await cancel('itemCategories');
    },
    onSuccess: (data, variables) => {
      dispatch(updateItemCategoryReducer({
        id: get(variables, 'itemCategory.id'),
        changes: get(data, 'data'),
      }));
    },
    onSettled: () => {
      refresh('itemCategories');
    }
  })
}

export const useCreateItemCategory = () => {
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const cancel = useCancel();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['createItemCategory'], {
    mutationFn: createItemCategory
  });

  return useMutation({
    mutationKey: ['createItemCategory'],
    mutationFn: createItemCategory,
    onMutate: async () => {
      await cancel('itemCategories')
    },
    onError: (error) => {
      const responseError = get(error, 'respone.data.message', '');
      dispatch(
        sendNewGTMEvent('pos-category-created', {
          error: handleError(error),
        })
      );

      toast.error({
        title: I18n.get('createItemCategoryError', 'Hubo un error creando la categoria'),
        subtitle: !!responseError ? responseError : handleError(error)
      })
    },
    onSuccess: (data, variables) => {
      toast.success({
        title: I18n.get('itemCategoryCreatedSuccessfully', 'Asi se hace. 😎'),
        subtitle: replaceAndParse(I18n.get('itemCategoryCreatedSuccessfullyMessage', 'La categoria {} ya está disponible para que clasifiques tus productos.'), [
          `<span class="font-weight-bold">${get(variables, 'itemCategory.name', '')}</span>`
        ])
      });

      dispatch(sendGTMEvent("category-created"));
      dispatch(
        sendNewGTMEvent('pos-category-created', {
          error: 'no error',
        })
      );
      dispatch(addItemCategory(get(data, 'data')));
    },
    onSettled: () => {
      refresh('itemCategories');
    }
  })
}