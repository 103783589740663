import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { BigNumber } from 'bignumber.js';
import { get, capitalize, isArray, isNumber, replace } from 'lodash';
import parse from 'html-react-parser';

import { activeTaxes } from '../../../selectors/taxes';
import {
  multitax,
  country as countrySelector,
  decimalPrecision,
} from '../../../selectors/company';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { decimalsVersionsGroup } from '../../../selectors/deployGroups';
import {
  hasPermissionTo,
  permissionValue,
  languageSelector,
} from '../../../selectors/auth';
import { station as stationSelector } from '../../../selectors/app';
import getNoTax from '../../countriesData/general/noTax';
import Tooltip from '../../common/Tooltip';
import { itemHasOutdatedProductKey } from '../../home/NewActiveInvoice/utils';
import UpdateProductKey from './UpdateProductKey';

const NewEditItem = (props) => {
  const [noTaxActive, setNoTaxActive] = useState(false);
  const [lastModified, setLastModified] = useState('price');
  const [fieldError, setFieldError] = useState({
    discount: false,
    minQuantity: false,
    exceedQuantity: false,
  });
  const taxes = useSelector(activeTaxes);
  const isMultitax = useSelector(multitax);
  const country = useSelector(countrySelector);
  const language = useSelector(languageSelector);
  const decimal = useSelector(decimalPrecision);
  const station = useSelector(stationSelector);
  const can = useSelector(hasPermissionTo);
  const permission = useSelector(permissionValue);

  function maxDecimals(e, key, decimals = 6) {
    if (!decimalsVersionsGroup.includes(country))
      return e.target.value;

    const newButton = e.nativeEvent.data;
    const values = props.values;
    values[key] = !values[key] ? "" : values[key];
    e.target.value = !e.target.value ? "" : e.target.value;

    if (key === 'quantity')
      checkItemDisponibility(e)
    
    if (key === 'discount')
      checkUserDiscountAvailability(e)

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return values[key];
      }
    }

    if (isNaN(newButton)) {
      e.target.value = values[key];
      return values[key];
    }
  }

  function setUpDecimals(key, decimals = 6) {
    if (!decimalsVersionsGroup.includes(country))
      return;
    const values = props.values;

    if (values[key] && values[key].toString().includes('.')) {
      if (values[key].toString().slice(-1) === '.') {
        values[key] = values[key].slice(0, -1);
        return;
      }

      if (values[key].toString().split('.')[1].length > decimals)
        values[key] = Number(values[key]).toFixed(decimals)
    }
  }

  const checkUserDiscountAvailability = (e) => {
    if (+permission('max-discount-allow', 'invoices') < +e.target.value)
      setFieldError((prev) => ({ ...prev, discount: true }));
    else setFieldError((prev) => ({ ...prev, discount: false }));
    return e.target.value;
  };

  const checkItemDisponibility = (e) => {
    setFieldError((prev) => ({
      ...prev,
      minQuantity: false,
      exceedQuantity: false,
    }));
    if (!get(props.values, 'inventory', null)) return e.target.value;

    let availableQuantity = null;
    let minQuantity = 0;
    let itemQty = Number(e.target.value) || 0;

    const warehouses = get(props.values, 'inventory.warehouses');
    if (!!warehouses) {
      const stationWarehouse = warehouses.find(
        (warehouse) => +get(warehouse, 'id') === +get(station, 'idWarehouse')
      );

      if (!!stationWarehouse) {
        availableQuantity = +get(stationWarehouse, 'availableQuantity', null);
        minQuantity = +get(stationWarehouse, 'minQuantity', 0);
      }
    }
    minQuantity = Math.max(minQuantity, 0);

    if (availableQuantity == null) return e.target.value;

    availableQuantity = Math.max(availableQuantity, 0);
    if (
      availableQuantity - itemQty <= minQuantity &&
      availableQuantity >= itemQty
    ) {
      setFieldError((prev) => ({ ...prev, minQuantity: true }));
    } else if (availableQuantity >= itemQty) {
      return e.target.value;
    } else {
      setFieldError((prev) => ({ ...prev, exceedQuantity: true }));
    }

    return e.target.value;
  };

  const calculateTotalPrice = (taxPercent) => {
    const salePrice = get(props, 'values.salePrice', '0');
    if (!!salePrice) {
      const price = new BigNumber(salePrice).multipliedBy(100).dividedBy(new BigNumber(taxPercent).plus(100))
        .decimalPlaces(6).toNumber()
      if (isNumber(price))
        props.form.change('price', '' + price);
    }
  };

  const calculateSalePrice = (taxPercent) => {
    const price = get(props, 'values.price', '0');
    if (!!price) {
      const salePrice = new BigNumber(100)
        .plus(new BigNumber(taxPercent))
        .dividedBy(100)
        .multipliedBy(price)
        .decimalPlaces(decimal)
        .toNumber();
      if (isNumber(salePrice)) props.form.change('salePrice', '' + salePrice);
    }
  };

  const calculatePriceValues = (lastTouched = null) => {
    let taxes = !!get(props, 'values.tax', null)
      ? get(props, 'values.tax', null)
      : [];
    taxes = !isArray(taxes) ? [taxes] : taxes;

    const totalPercent = !!taxes[0]
      ? taxes.reduce((prevTax, tax) => prevTax + +get(tax, 'percentage'), 0)
      : 0;
    if (!!lastTouched) {
      if (lastTouched === 'price') calculateSalePrice(totalPercent);
      else calculateTotalPrice(totalPercent);
      setLastModified(lastTouched);
    } else {
      if (lastModified === 'price') calculateSalePrice(totalPercent);
      else calculateTotalPrice(totalPercent);
    }
  };

  const noTax = useMemo(() => getNoTax(language.substring(0, 2)), [language]);

  const allTaxes = [noTax, ...taxes];

  useEffect(() => {
    if (isMultitax && country !== 'peru') {
      const taxes = props?.values?.tax;
      if (isArray(taxes)) {
        taxes[taxes.length - 1]?.id === -1
          ? setNoTaxActive(true)
          : setNoTaxActive(false);
      } else setNoTaxActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTax, props.values.tax]);

  useEffect(() => {
    if (isMultitax && country !== 'peru') {
      if (noTaxActive) props.form.change('tax', [noTax]);
      else if (!noTaxActive && !!props?.values?.tax)
        props.form.change(
          'tax',
          props?.values?.tax?.filter((tax) => tax !== noTax)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTaxActive]);

  useEffect(() => {
    const totalTaxes = props.values.tax.reduce(
      (prevTax, tax) => prevTax + +get(tax, 'percentage'),
      0
    );
    const price = get(props, 'values.price', '0');
    if (!!price) {
      const salePrice = new BigNumber(100)
        .plus(new BigNumber(totalTaxes))
        .dividedBy(100)
        .multipliedBy(price)
        .decimalPlaces(decimal)
        .toNumber();
      if (isNumber(salePrice)) props.form.change('salePrice', '' + salePrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='form-body h-100 p-4 overflow-hidden'>
      <div className='form-body__fields form-row pl-3 pr-2 m-0 justify-content-start'>
        <div className='col-6 col-sm-4 p-0 d-flex'>
          <Field
            name='price'
            className='col-12 col-sm-11 pl-0 py-0 pr-1'
            component={renderField}
            type='number'
            label={capitalize(I18n.get('priceBeforeTaxes', 'precio base'))}
            disabled={!can('edit-items-prices', 'invoices')}
            onChange={e => maxDecimals(e, 'price', 6)}
            onBlur={() => { setUpDecimals('price', 6); calculatePriceValues('price') }}
          />
          <Tooltip
            tooltipActive={!can('edit-items-prices', 'invoices')}
            overlay={I18n.get(
              'userNotAllowed.invoices.edit-items-prices-new',
              'Necesitas permiso del administrador para editar el precio.'
            )}
            placement='bottomLeft'
            containerStyles={{
              position: 'absolute',
              width: '100%',
              height: '75%',
            }}
          >
            <div></div>
          </Tooltip>
          <div
            className='text-primary d-none d-sm-block col-1 p-0 text-center'
            style={{
              lineHeight: '25px',
              fontSize: '20px',
              marginTop: '3rem',
            }}
          >
            +
          </div>
        </div>

        <div className='col-6 col-sm-4 p-0 d-flex'>
          <Field
            name='tax'
            className='col-12 col-sm-11 pl-1 pr-0 tax-selector'
            component={renderSelect}
            options={allTaxes}
            label={capitalize(I18n.get('tax', 'impuesto'))}
            getOptionLabel={(option) =>
              `${option.name} (${option.percentage}%)`
            }
            getOptionValue={(option) => option.id}
            onInputChange={() => calculatePriceValues()}
            isMulti={country === 'peru' ? false : isMultitax}
            menuPosition='absolute'
            hideDropdownIndicator
          />
          <div
            className='text-primary d-none d-sm-block col-1 pl-1 text-center'
            style={{
              lineHeight: '25px',
              fontSize: '20px',
              marginTop: '3rem',
            }}
          >
            =
          </div>
        </div>

        <div className='col-6 col-sm-4 p-0 d-flex'>
          <Field
            name='salePrice'
            className='col-12 d-none d-sm-block p-0 pl-2 pr-1'
            component={renderField}
            type='number'
            label={capitalize(I18n.get('finalPrice', 'precio final'))}
            disabled={!can('edit-items-prices', 'invoices')}
            onBlur={() => { setUpDecimals('salePrice', 6); calculatePriceValues('salePrice') }}
          />
          <Field
            name='salePrice'
            className='col-12 d-sm-none p-0 pr-1'
            component={renderField}
            type='number'
            label={capitalize(I18n.get('finalPrice', 'precio final'))}
            disabled={!can('edit-items-prices', 'invoices')}
            onBlur={() => { setUpDecimals('salePrice', 6); calculatePriceValues('salePrice') }}
          />
          <Tooltip
            tooltipActive={!can('edit-items-prices', 'invoices')}
            overlay={I18n.get(
              'userNotAllowed.invoices.edit-items-prices-new',
              'Necesitas permiso del administrador para editar el precio.'
            )}
            placement='bottomRight'
            containerStyles={{
              position: 'absolute',
              width: '100%',
              height: '75%',
            }}
          >
            <div></div>
          </Tooltip>
        </div>

        <div className='col-6 col-sm-6 p-0 d-flex'>
          <Field
            name='quantity'
            className='col-12 d-none d-sm-block mb-0 pl-0 pr-1'
            component={renderField}
            customClassName={
              fieldError.exceedQuantity ? 'border-warning' : null
            }
            type='number'
            label={capitalize(I18n.get('quantity', 'cantidad'))}
            onChange={(e) => maxDecimals(e, 'quantity', 4)}
          />
          <Field
            name='quantity'
            className='col-12 d-sm-none mb-0 pl-1 pr-0'
            component={renderField}
            customClassName={
              fieldError.exceedQuantity ? 'border-warning' : null
            }
            type='number'
            label={capitalize(I18n.get('quantity', 'cantidad'))}
            onChange={(e) => maxDecimals(e, 'quantity', 4)}
          />
        </div>
        <div className='d-sm-none col-6 p-0'></div>
        <div className='d-sm-none col-6 p-0'>
          {fieldError.minQuantity && (
            <p style={{ fontSize: '12px', color: '#64748B' }}>
              {I18n.get(
                'youReachedMinQuantity',
                'Alcanzaste la cantidad mínima'
              )}
            </p>
          )}
          {fieldError.exceedQuantity && (
            <p style={{ fontSize: '12px', color: '#B45309' }}>
              {I18n.get(
                'quantityExceededInInventory',
                'Supera la cantidad en inventario'
              )}
            </p>
          )}
        </div>

        <div className='d-none d-sm-block my-2 mx-0' />

        <div className='col-12 col-sm-6 p-0 d-flex'>
          <div className='d-none d-sm-block ml-1 mr-0' />
          <Field
            name='discount'
            className='col-12 pr-0 pl-0 mb-0'
            component={renderField}
            customClassName={fieldError.discount ? 'border-danger' : null}
            type='number'
            label={capitalize(I18n.get('discount', 'descuento')) + '(%)'}
            placeholder='%'
            disabled={!can('edit-discount', 'invoices')}
            onChange={(e) => maxDecimals(e, 'discount', 1)}
            helpTooltip={
              <>
                {parse(
                  I18n.get(
                    'discountCalculationHelTooltip',
                    'El descuento se calculará sobre el precio base antes de impuestos, conoce más sobre'
                  )
                )}
                <a
                  href={I18n.get('discountCalculationHelTooltip.link')}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  {I18n.get(
                    'discountCalculationHelTooltip.linkText',
                    'cómo funcionan los descuentos'
                  )}
                </a>
                .
              </>
            }
          />
        </div>

        <div className='mb-4 d-flex w-100'>
          <div className='d-none d-sm-block col-md-6 p-0'>
            {fieldError.minQuantity && (
              <span style={{ fontSize: '12px', color: '#64748B' }}>
                {I18n.get(
                  'youReachedMinQuantity',
                  'Alcanzaste la cantidad mínima'
                )}
              </span>
            )}
            {fieldError.exceedQuantity && (
              <span style={{ fontSize: '12px', color: '#B45309' }}>
                {I18n.get(
                  'quantityExceededInInventory',
                  'Supera la cantidad en inventario'
                )}
              </span>
            )}
          </div>
          <div className='col-md-6 p-0'>
            {fieldError.discount && (
              <span style={{ fontSize: '12px' }} className='text-danger'>
                {replace(
                  I18n.get('maxDiscountExceeded', 'Máximo descuento: {}%'),
                  '{}',
                  permission('max-discount-allow', 'invoices')
                )}
              </span>
            )}
          </div>
        </div>

        {country === 'costaRica' &&
          Number(get(props, 'values.discount', '0')) !== 0 && (
            <Field
              name='discountNature'
              className='col-12 p-0'
              component={renderField}
              required
              type='textarea'
              label={capitalize(
                I18n.get('discountNature', 'naturaleza del descuento')
              )}
            />
          )}

        <Field
          name='description'
          className='col-12 p-0'
          component={renderField}
          type='textarea'
          label={capitalize(I18n.get('description', 'Descripción'))}
        />

        {country === 'costaRica' && itemHasOutdatedProductKey(props.values) && (
          <UpdateProductKey
            productId={props.values.id}
            country={country}
            values={props.values}
          />
        )}
      </div>
    </div>
  );
};

export default NewEditItem;
