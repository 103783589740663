import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'

import { hasPermissionTo } from '../../../../selectors/auth'
import { activeBanks } from '../../../../selectors/banks'
import { renderField, renderSelect } from '../../fields/V0/Fields';
import { maxDecimals } from '../../../../utils/decimals/maxDecimals';
import { decimalPrecision, country as countrySelector } from '../../../../selectors/company';

const Combined = ({ fmt, values }) => {
  const can = useSelector(hasPermissionTo)
  const banks = useSelector(activeBanks)
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);

  return (
    <>
      <Field
        name="cash"
        className="col-md-12"
        type="number"
        data-testid="invoice-combined-cash-input"
        component={renderField}
        label={capitalize(I18n.get('cash', 'efectivo'))}
        onChange={e => maxDecimals({e, key: 'cash', decimals: decimal, country, values})}
        placeholder={fmt.prefix}
      />

      <Field
        name="debit"
        className="col-md-12"
        type="number"
        data-testid="invoice-combined-debit-input"
        component={renderField}
        label={capitalize(I18n.get('debit', 'débito'))}
        onChange={e => maxDecimals({e, key: 'debit', decimals: decimal, country, values})}
        placeholder={fmt.prefix}
      />
      
      <Field
        name="debitBank"
        className="col-md-12"
        component={renderSelect}
        options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
        selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
          : null}
        hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
      />

      <Field
        name="credit"
        className="col-md-12"
        type="number"
        data-testid="invoice-combined-credit-input"
        component={renderField}
        label={capitalize(I18n.get('credit', 'crédito'))}
        onChange={e => maxDecimals({e, key: 'credit', decimals: decimal, country, values})}
        placeholder={fmt.prefix}
      />
        
      <Field
        name="creditBank"
        className="col-md-12"
        component={renderSelect}
        options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
        selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
          : null}
        hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
      />

      <Field
        name="transfer"
        className="col-md-12"
        type="number"
        data-testid="invoice-combined-transfer-input"
        component={renderField}
        label={capitalize(I18n.get('transfer', 'transferencia'))}
        onChange={e => maxDecimals({e, key: 'transfer', decimals: decimal, country, values})}
        placeholder={fmt.prefix}
      />
        
      <Field
        name="transferBank"
        className="col-md-12"
        component={renderSelect}
        options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
        selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
          ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
          : null}
        hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        menuPosition="absolute"
      />
    </>
  )
}

export default Combined;