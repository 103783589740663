import { matchPath } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { useHeader } from '../context/header.context';
import { useDispatch } from 'react-redux';
import { openMenu } from '../../../reducers/app';
import NewMenu from '../../svg/new/Menu';
import Typography from '../../common/Typography';
import PopoverComponent from '../../common/Popover';

function HamburgerMenu({ location }) {
  const dispatch = useDispatch();

  const { setActiveMenu, showTutorial, closeShowTutorial } = useHeader();

  const renderLocation = () => {
    if (!!matchPath(location.pathname, { path: '/', exact: true }))
      return I18n.get('checkIn', 'Facturar');
    if (!!matchPath(location.pathname, { path: '/invoices', exact: false }))
      return I18n.get('sales', 'Ventas');
    if (!!matchPath(location.pathname, { path: '/shifts', exact: false }))
      return I18n.get('shifts', 'Turnos');
    if (!!matchPath(location.pathname, { path: '/payments', exact: false }))
      return I18n.get('cashManagement', 'Gestión de Efectivo');
    if (!!matchPath(location.pathname, { path: '/refunds', exact: false }))
      return I18n.get('refunds', 'Devoluciones');
    if (!!matchPath(location.pathname, { path: '/settings', exact: false }))
      return I18n.get('settings', 'Configuración');
    if (!!matchPath(location.pathname, { path: '/statistics', exact: false }))
      return I18n.get('statistics', 'Estadísticas');
    if (!!matchPath(location.pathname, { path: '/plan', exact: false }))
      return I18n.get('mySubscription', 'Mi suscripción');
    if (!!matchPath(location.pathname, { path: '/inventory', exact: false }))
      return I18n.get('inventory', 'Inventario');
    if (
      !!matchPath(location.pathname, { path: '/global-invoices', exact: false })
    )
      return I18n.get('globalInvoices', 'Facturas globales');
    if (!!matchPath(location.pathname, { path: '/items', exact: false }))
      return I18n.get('itemsAndServices', 'Productos y servicios');
    return '';
  };

  return (
    <div className='d-flex h-100 align-items-center'>
        <div
          className='new-menu-item'
          onClick={() => {
            setActiveMenu('');
            dispatch(openMenu());
            closeShowTutorial();
          }}
        >
          <NewMenu className='icon-secondary' />
        </div>

        <PopoverComponent
          title={I18n.get(
            'new_design_tittle',
            '¡Nuevo diseño en tu punto de venta! 🖌️'
          )}
          large
          orientation='left'
          overlay={
            <Typography
              type='caption-regular'
              variant='white'
              text={I18n.get(
                'new_design_description',
                'Ahora tu menú tiene una nueva imagen y color para que puedas identificar más fácil cada sección.'
              )}
            />
          }
          popoverActive={showTutorial}
          position='bottom'
          theme='dark'
          onRequestClose={() => closeShowTutorial()}
        >
          <Typography
            type='body-2-regular'
            variant='secondary'
            text={renderLocation()}
          />
        </PopoverComponent>
    </div>
  );
}

export default HamburgerMenu;
