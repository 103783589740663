import { get } from "lodash";

export const checkClientForFE = (client) => {
    
    const identificationType = get(client, 'identificationObject.type', '');
    const identificationNumber = get(client, 'identificationObject.number', '');
    const kindOfPerson = get(client, 'kindOfPerson', '');

    if (!identificationType)
        return false;

    if (!identificationNumber)
       return false;
    
    if (identificationNumber && ['RC', 'NIT', 'CC', 'NUIP', 'TI'].includes(identificationType)) {
        const isCorrectFormat = /^[0-9]+$/.test(identificationNumber);
        if (!isCorrectFormat)
            return false;
    }

    const foreignId = identificationType === 'DIE' || identificationType === 'PP' || identificationType === 'TE' || identificationType === 'FOREIGN_NIT';
    const combinedAddress = get(client, 'address.city', '');
    const address = get(client, 'address.address', '');
    const regime = get(client, 'regime', '');
    const isRequired = () => {
      if (address || combinedAddress) {
        return true;
      }
      return false;
    }
    if (!kindOfPerson && identificationType === 'NIT')
      return false;
    if (!regime && identificationType === 'NIT')
      return false;

    if (!foreignId) {
      if (isRequired()) {
        if (!combinedAddress)
            return false;
        if (!address)
            return false;
      }
    }
    return true;
}