// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03033 6.46967C6.73744 6.17678 6.26256 6.17678 5.96967 6.46967C5.67678 6.76256 5.67678 7.23744 5.96967 7.53033L7.43934 9L5.96967 10.4697C5.67678 10.7626 5.67678 11.2374 5.96967 11.5303C6.26256 11.8232 6.73744 11.8232 7.03033 11.5303L9.03033 9.53033C9.32322 9.23744 9.32322 8.76256 9.03033 8.46967L7.03033 6.46967Z"
      fill="#94A3B8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 17.75H15.5C17.0188 17.75 18.25 16.5188 18.25 15V3C18.25 1.48122 17.0188 0.25 15.5 0.25H3.5C1.98122 0.25 0.75 1.48122 0.75 3V15C0.75 16.5188 1.98122 17.75 3.5 17.75ZM3.5 1.75C2.80964 1.75 2.25 2.30964 2.25 3V15C2.25 15.6904 2.80964 16.25 3.5 16.25H11.75V1.75H3.5ZM15.5 1.75H13.25V16.25H15.5C16.1904 16.25 16.75 15.6904 16.75 15V3C16.75 2.30964 16.1904 1.75 15.5 1.75Z"
      fill="#94A3B8"
    />
  </svg>
)