import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import { hasPermissionTo } from '../../../selectors/auth';
import { handleError } from '../../../utils/errors'
import { toast } from '../../../utils';
import Tooltip from '../../common/Tooltip';

import EditSolid from "../../svg/EditSolid.svg";
import DeleteSolid from "../../svg/DeleteSolid.svg";

import Lock from '../../svg/Lock'
import Unlock from '../../svg/Unlock'

const Actions = ({ variant, onView, filters, deleteVariant, updateVariant }) => {
  const can = useSelector(hasPermissionTo);

  return (
    <div className="d-flex align-items-center justify-content-start table-gray-actions">
      <Tooltip
        tooltipActive={!can('edit', 'variant-attributes')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.variant-attributes.edit', 'no tienes permisos para editar variantes')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'variant-attributes')
            || !get(variant, 'editable', true)
          }
          title={capitalize(I18n.get('edit', 'editar'))}
          onClick={() => onView()}
        >
          <img src={EditSolid} alt="editar" />
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can('edit', 'variant-attributes')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.variant-attributes.edit', 'no tienes permisos para editar variantes')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can('edit', 'variant-attributes')}
          onClick={() => updateVariant({
            variant: {
              id: variant?.id,
              status: !(get(variant, 'status') === 'active')
                ? 'active'
                : 'inactive'
            }
          }, {
            onSuccess: (_, variables) => {
              toast.success({
                title: get(variables, 'variant.status') === 'active'
                  ? I18n.get('variantActivatedSuccessfully', 'Variante activada con éxito')
                  : I18n.get('variantDeactivatedSuccessfully', 'Variante desactivada con éxito')
              })
            },
            onError: (error) => {
              const responseError = get(error, 'response.data.message', '');

              toast.error({
                title: I18n.get('variantStatusError', 'Error cambiando estado de la variante'),
                subtitle: !!responseError ? responseError : handleError(error)
              });
            },
          })}
          title={get(variant, 'status') === 'active'
            ? capitalize(I18n.get('deactivate', 'desactivar'))
            : capitalize(I18n.get('activate', 'activar'))
          }
        >
          {get(variant, 'status') === 'active'
            ? <Unlock className="icon-primary" />
            : <Lock className="icon-primary" />
          }
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can('delete', 'variant-attributes')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.variant-attributes.delete', 'no tienes permisos para eliminar variantes')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn button-transparent button-remove"
          disabled={!can('delete', 'variant-attributes')}
          title={capitalize(I18n.get('delete', 'eliminar'))}
          onClick={() => deleteVariant({ id: variant?.id, variant })}
        >
          <img src={DeleteSolid} alt="eliminar" />
        </button>
      </Tooltip>
    </div>
  )
}

export default Actions;

