import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { debounce, get, isString, toLower } from 'lodash'
import { BigNumber } from 'bignumber.js'

import { closeModal, openModal } from '../../../reducers/modals';
import { country as countrySelector, decimalPrecision } from '../../../selectors/company'
import Client from '../../svg/Client'
import Modal from '../../common/Modal';
import BottomInvoice5UVT from './BottomInvoice5UVT';
import { basicPOSClientData, replaceAndParse } from '../../../utils';
import { total, client as clientSelector, numeration as invoiceNumerationSelector } from '../../../selectors/activeInvoice';
import { isDefaultClient, setClient } from '../../../reducers/activeInvoice';
import Edit from '../../svg/NewEdit';
import Add from '../../svg/Plus';
import { hasPermissionTo } from '../../../selectors/auth';
import { filter } from '../../../reducers/clients';
import Select from '../../common/Select';
import { invoiceClients } from '../../../selectors/clients';
import { stationInvoiceNumeration } from '../../../selectors/app';
import { checkClientForFE } from './utils';
import { sendGTMEvent } from '../../../reducers/company';
import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';

const renderClientName = client => {
  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name')))
      return get(client, 'name')
    return `${get(client, 'name.firstName', '')}${!!get(client, 'name.secondName', null)
      ? ' ' + get(client, 'name.secondName') : ''}${!!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName') : ''}`;
  }
  return ''
}

const renderClientIdentification = client => {
  if (!client) return ''

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`
    return ` (${get(client, 'identification.number')})`
  }
  return ''
}

const Invoice5UVT = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.invoice5UVT.isOpen', false));
  const decimal = useSelector(decimalPrecision)
  const totalPrice = useSelector(total);
  const client = useSelector(clientSelector);
  const country = useSelector(countrySelector);
  const mainNumeration = useSelector(stationInvoiceNumeration)
  const invoiceNumeration = useSelector(invoiceNumerationSelector)
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration
  const clients = useSelector(invoiceClients);
  const can = useSelector(hasPermissionTo)
  const ref = useRef()
  const colombiaUVT = getColombiaUVT();
  const [changeClient, setChangeClient] = useState(false);
  const [loading, setLoading] = useState(false)

  const getClient = () => {
    if (client)
      return client;
    
    const defaultClient = basicPOSClientData(country, numeration);
    return {
      ...defaultClient,
      name: get(defaultClient, 'name.firstName', '') + ' ' + get(defaultClient, 'name.lastName', '')
    };
  }

  const search = useCallback(async (text = '') => {
    if (ref.current)
      setLoading(true)

    await dispatch(filter({ text, limit: 35 }))

    if (ref.current)
      setLoading(false)
  }, [dispatch])

  const handleInputChange = useMemo(() =>
    debounce((value) => {
      if (can('view', 'contacts'))
        search(value)
    }, 350), [can, search])

  const handleClose = () => {
    setChangeClient(false);
    dispatch(sendGTMEvent("uvt-feature-triggered", {
      changeAccepted: false
    }));
    dispatch(closeModal({ modal: 'invoice5UVT' }));
  }

  const handleConfirm = () => {
    setChangeClient(false);
    dispatch(closeModal({ modal: 'invoice5UVT' }));
    
    dispatch(sendGTMEvent("uvt-feature-triggered", {
      changeAccepted: true
    }));
    if(!isDefaultClient(getClient(), country) && !checkClientForFE(getClient())) {
      dispatch(openModal({ modal: 'invoice5UVTNewClient', params: {contact: getClient(), isUpdate: true} }))
    } 
    else {
      dispatch(openModal({ modal: 'invoice' }));
    }
  }

  const fmt = {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={replaceAndParse(I18n.get('payAnyDocument', 'pagar {}'), [toLower(I18n.get('invoice', 'factura'))])}
      className="modal__invoice"
    >
      <div className="d-flex flex-column p-3 justify-content-center align-items-center">
        <div className="d-flex align-items-center mt-4">
          <p className="h4 text-muted text-uppercase">
            {I18n.get('total', 'total')}
          </p>
        </div>
        <div className="d-flex align-items-center mb-4 font-weight-bold">
          <h1>
            {new BigNumber(totalPrice).toFormat(decimal, fmt)}
          </h1>
        </div>


        <div className="d-flex align-items-center w-75 mb-5">
          <p className="h4 text-muted text-center">
            {replaceAndParse(I18n.get('invoice5uvt.description', `La venta superó $${colombiaUVT?.UVT_FORMATED} antes de IVA y por eso se creará una <strong style="color: black;">factura electrónica</strong> a nombre de:`), [])}
          </p>
        </div>

        { isDefaultClient(getClient(), country) && !changeClient &&
          <div className="d-flex align-items-center justify-content-center mb-5 border p-3 rounded2">
            <Client className='mr-4'/>
            <p className="h4 text-muted mr-2 mb-0 font-weight-bold">
                {I18n.get('client', 'Cliente')}:
            </p>
            <p className="h4 text-muted mb-0 mr-4">
              {get(getClient(), 'name', '')}
            </p>
            <button
              type="button"
              className="btn btn-cancel btn-sm btn-no-border btn-small text-capitalize-first d-flex justify-content-center align-items-center"
              onClick={() => setChangeClient(true)}
            >
              <Edit className='icon-primary x-small-icon mr-2'/>
              <p className='mb-0'>{I18n.get('toChange', 'Cambiar')}</p>
            </button>
          </div>
        }
        
        { changeClient &&
          <div className="d-flex align-items-center justify-content-center mb-5 w-90">
            <p className="h4 text-muted mr-3 mb-0 font-weight-bold">
                {I18n.get('client', 'Cliente')}:
            </p>
            <Select
              value={getClient()}
              options={can('view', 'contacts') ? clients : []}
              isLoading={loading}
              placeholder={I18n.get('selectAClient', 'selecciona un cliente')}
              noOptionsMessage={() => can('view', 'contacts')
                ? clients.length === 0 ? I18n.get('createClientToSelect', 'Crea un nuevo cliente para seleccionarlo') : I18n.get('createThisNewClientToSelect', 'Crea este nuevo cliente para seleccionarlo')
                : I18n.get('userNotAllowed.contacts.view', 'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.')
              }
              getOptionLabel={option =>
                `${renderClientName(option)}${renderClientIdentification(option)}`
              }
              getOptionValue={option => option.id}
              onChange={client => dispatch(setClient(client))}
              isClearable={true}
              showClearIndicator={!!client}
              hideDropdownIndicator={!!client}
              onInputChange={handleInputChange}
              cacheOptions={false}
            />
            <button
              type="button"
              className="btn btn-cancel btn-text-dark text-capitalize-first d-flex justify-content-center align-items-center ml-4 w-35"
              onClick={() => {
                setChangeClient(false);
                dispatch(closeModal({ modal: 'invoice5UVT' }))
                dispatch(openModal({ modal: 'invoice5UVTNewClient' }))
              }}
            >
              <p className='mb-0  ml-2'>{I18n.get('newClient', 'nuevo cliente')}</p>
              <Add className='icon-gray ml-2'/>
            </button>
          </div>
        }

        { !isDefaultClient(getClient(), country) && !changeClient &&
          <div className="d-flex align-items-center justify-content-center mb-5">
            <Client className='mr-4'/>
            <p className="h4 text-muted mr-2 mb-0 font-weight-bold">
                {I18n.get('client', 'Cliente')}:
            </p>
            <p className="h4 text-muted mb-0">
              {get(getClient(), 'name', '')}
            </p>
          </div>
        }
        

      </div>

      <BottomInvoice5UVT 
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </Modal>
  )
}

export default Invoice5UVT;