export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      width='31.9996'
      height='31.9996'
      rx='15.9998'
      transform='matrix(1 0 -1.30562e-05 -1 0.000488281 32)'
      fill='#30ABA9'
    />
    <g filter='url(#filter0_dd_1276_20710)'>
      <path d='M16 8V15.9467L21.3855 19.6362' stroke='white' stroke-width='2' />
    </g>
    <defs>
      <filter
        id='filter0_dd_1276_20710'
        x='12'
        y='6'
        width='12.9507'
        height='18.4609'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='1' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1276_20710'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='1.5' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow_1276_20710'
          result='effect2_dropShadow_1276_20710'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_1276_20710'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
