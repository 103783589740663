// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 12,22.814 C 17.523,22.814 22,18.336 22,12.814 C 22,7.291 17.523,2.814 12,2.814 C 6.477,2.814 2,7.291 2,12.814 C 2,18.336 6.477,22.814 12,22.814 Z M 12,20.904 A 8.09,8.09 0 1 1 12,4.724 A 8.09,8.09 0 0 1 12,20.904 Z M 7.803,11.794 V 13.818 H 10.981 H 16.197 V 11.795 H 13.02 H 7.803 Z" />
  </svg>
)