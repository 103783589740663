// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="56" 
    height="55" 
    viewBox="0 0 56 55" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" clipRule="evenodd"
      d="M19.866 12.5147H46.2468L46.2468 33.2388H43.4432V19.3346C43.4432 18.5054 42.7709 17.8332 41.9417 17.8332H19.866V12.5147ZM46.2468 36.6798H43.4432V43.9376C43.4432 44.7669 42.7709 45.4391 41.9417 45.4391H8.8765C8.04725 45.4391 7.375 44.7669 7.375 43.9376V19.3346C7.375 19.1242 7.41829 18.9239 7.49644 18.7421C8.08489 11.4331 12.4886 9.27002 16.524 9.11536C16.6988 9.088 16.878 9.07379 17.0604 9.07379H46.2468C48.1471 9.07379 49.6877 10.6143 49.6877 12.5147V33.2388C49.6877 35.1392 48.1471 36.6798 46.2468 36.6798ZM8.87649 43.9376V24.6318C9.78913 25.6489 10.7966 25.9676 10.7966 25.9676V20.8829H17.7427C17.7427 20.8829 18.4716 20.1039 19.1186 19.3346H41.9417L41.9417 43.9376H8.87649ZM33.9758 31.8163C33.9758 36.5091 30.1716 40.3133 25.4788 40.3133C20.786 40.3133 16.9818 36.5091 16.9818 31.8163C16.9818 27.1235 20.786 23.3193 25.4788 23.3193C30.1716 23.3193 33.9758 27.1235 33.9758 31.8163ZM21.1463 32.8416V30.6713H24.5551V27.2625H26.7421V30.6713H30.1492V32.8416H26.7421V36.2654H24.5551V32.8416H21.1463Z"
    />
  </svg>
)