function TrailingTextIosSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='37'
      height='11'
      viewBox='0 0 37 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.28054 5.90909L6.1669 0.0227269L12.0533 5.90909H9.03054V11H3.30327V5.90909H0.28054ZM2.51278 5.0142H4.29759V10.1051H8.03622V5.0142H9.82102L6.1669 1.36008L2.51278 5.0142ZM21.6049 0.818182H26.3677V1.91193H21.6049V0.818182ZM14.595 1.91193V0.818182H18.5126L23.1064 9.90625H26.3677V11H22.4501L17.8564 1.91193H14.595ZM28.8697 11V0.818182H30.1026V5.35227H35.5316V0.818182H36.7646V11H35.5316V6.44602H30.1026V11H28.8697Z'
        fill='#64748B'
      />
    </svg>
  );
}

export default TrailingTextIosSvg;
