import React from 'react'
import { I18n } from '@aws-amplify/core';
import { BigNumber } from 'bignumber.js';
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { decimalPrecision } from '../../../selectors/company'
import { currency } from '../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../selectors/currencies'

const Change = ({ values, total }) => {
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol') 
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : '' 

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  if (!values) return null;

  const cash = new BigNumber(!!values.cash ? +values.cash : 0);
  const debit = new BigNumber(!!values.debit ? +values.debit : 0);
  const credit = new BigNumber(!!values.credit ? +values.credit : 0);
  const transfer = new BigNumber(!!values.transfer ? +values.transfer : 0);

  const payment = cash.plus(debit).plus(credit).plus(transfer);
  const change = total.minus(payment)

  return (
    <div className="d-flex justify-content-between align-items-center px-4">
      <p className="h5 text-muted text-uppercase mr-2">
        {change.lte(0)
          ? I18n.get('change', 'cambio')
          : I18n.get('toPay', 'por pagar')
        }
      </p>
      
      <div className="border-top w-75 pt-1"/>
      <p className="h3 text-danger">
        {change.abs().toFormat(decimal, fmt)}
      </p>
    </div>
  )
}

export default Change;