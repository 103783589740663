// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9884 5.24991C11.4822 5.23573 11.9708 5.35485 12.4028 5.59485C12.8388 5.83705 13.2007 6.19326 13.4498 6.62534C13.6566 6.98419 13.5334 7.4428 13.1746 7.64968C12.8157 7.85655 12.3571 7.73334 12.1502 7.37449C12.037 7.17809 11.8725 7.01618 11.6743 6.90608C11.4762 6.79599 11.2518 6.74186 11.0252 6.74949C11.0168 6.74977 11.0084 6.74991 11 6.74991H10.0116C10.0077 6.74997 10.0039 6.75 10 6.75C9.99613 6.75 9.99227 6.74997 9.98841 6.74991H9C8.66848 6.74991 8.35054 6.88161 8.11612 7.11603C7.8817 7.35045 7.75 7.66839 7.75 7.99991C7.75 8.33143 7.8817 8.64937 8.11612 8.88379C8.35054 9.11822 8.66848 9.24991 9 9.24991H11C11.7293 9.24991 12.4288 9.53964 12.9445 10.0554C13.4603 10.5711 13.75 11.2706 13.75 11.9999C13.75 12.7293 13.4603 13.4287 12.9445 13.9445C12.4288 14.4602 11.7293 14.7499 11 14.7499H10.75V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V14.7499H9.01163C8.51781 14.7641 8.02919 14.645 7.59719 14.405C7.16122 14.1628 6.79932 13.8066 6.55024 13.3745C6.34336 13.0156 6.46657 12.557 6.82542 12.3501C7.18428 12.1433 7.64289 12.2665 7.84976 12.6253C7.96298 12.8217 8.12748 12.9836 8.32565 13.0937C8.52382 13.2038 8.7482 13.258 8.97476 13.2503C8.98317 13.2501 8.99159 13.2499 9 13.2499H11C11.3315 13.2499 11.6495 13.1182 11.8839 12.8838C12.1183 12.6494 12.25 12.3314 12.25 11.9999C12.25 11.6684 12.1183 11.3505 11.8839 11.116C11.6495 10.8816 11.3315 10.7499 11 10.7499H9C8.27065 10.7499 7.57118 10.4602 7.05546 9.94446C6.53973 9.42873 6.25 8.72926 6.25 7.99991C6.25 7.27057 6.53973 6.57109 7.05546 6.05537C7.57118 5.53964 8.27065 5.24991 9 5.24991H9.25V4C9.25 3.58579 9.58579 3.25 10 3.25C10.4142 3.25 10.75 3.58579 10.75 4V5.24991H10.9884Z" fill="#94A3B8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10ZM10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75Z" fill="#94A3B8" />
  </svg>

)