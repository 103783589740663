import BasicData from './BasicData'; 
import AdvancedData from './AdvancedData'; 
import Accounting from './Accounting'; 

const contactFormParts = {
  BasicData,
  AdvancedData,
  Accounting,
}

export default contactFormParts;