// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="24" 
    height="24" 
    viewBox="0 0 512 512" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M 384,160 V 128 C 384,57.42 326.579,0 256,0 C 185.42,0 128,57.42 128,128 V 160 H 32 V 432 C 32,476.183 67.817,512 112,512 H 400 C 444.183,512 480,476.183 480,432 V 160 Z M 192,128 C 192,92.71 220.71,64 256,64 C 291.29,64 320,92.71 320,128 V 160 H 192 Z M 352,248 C 338.745,248 328,237.255 328,224 C 328,210.745 338.745,200 352,200 C 365.255,200 376,210.745 376,224 C 376,237.255 365.255,248 352,248 Z M 160,248 C 146.745,248 136,237.255 136,224 C 136,210.745 146.745,200 160,200 C 173.255,200 184,210.745 184,224 C 184,237.255 173.255,248 160,248 Z"
    />
  </svg>
)