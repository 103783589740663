import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash'
import { BigNumber } from "bignumber.js";

import { allItems } from '../../../../selectors/items';
import { searchMore } from '../../../../reducers/items';
import { addItem } from '../../../../reducers/editInvoice';
import { decimalPrecision } from '../../../../selectors/company';

const Items = () => {
  const dispatch = useDispatch();
  const decimal = useSelector(decimalPrecision);
  const itemsListRef = useRef(null)
  const items = useSelector(allItems);

  const handlerScroll = () => {
    const elem = itemsListRef.current;
    if (elem.clientHeight + elem.scrollTop >= elem.scrollHeight) {
      dispatch(searchMore())
    }
  };

  return (
    <div 
      className="w-100 h-100 bg-white refund-item-list"
      ref={itemsListRef}
      onScroll={() => handlerScroll()}
    >
      {items.map(item => {
        let price = get(item, 'price.0.price', 0);
        price = new BigNumber(price);
        
        return (
          <div 
            key={item.id} 
            className="w-100 p-4 d-flex justify-content-between align-items-center refund-item"
            onClick={() => dispatch(addItem(item))}
          >
            <p className="h4 text-truncate">
              {`${item.name}${!!get(item, 'reference.reference') 
                ? ` (${get(item, 'reference.reference')})`: !!get(item, 'reference')
                ? ` (${get(item, 'reference')})`: ''}`
              }
            </p>
            <p className="h4 text-truncate">{price.toFormat(decimal)}</p>
          </div>
        )}
      )}
    </div>
  )
}

export default Items;