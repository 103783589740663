import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get, isArray } from 'lodash'

import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';

const initialState = {
  entities: {},
  ids: [],
  debtsToPayProviders: null,
  receivableAccounts: null,
  loading: 'idle',
  error: null
}

export const fetchCategories = createAsyncThunk(
  'categories/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!preloaded)
        return preloaded
      // ex queries.allCategories

      const data = await alegraAPI.get('/categories', {
        ...params
      });
      return get(data, 'data', null);
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const fetchContactAccountingCategories = createAsyncThunk(
  'categories/fetchContactAccountingCategories',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!get(preloaded, 'contacts'))
        return get(preloaded, 'contacts')
      // ex queries.getContactAccountingCategories

      const data = await alegraAPI.get('/categories/accounting/settings');
      const debtsToPayProviders = isArray(get(data, 'data.contacts.debtsToPayProviders'))
        ? get(data, 'data.contacts.debtsToPayProviders')
        : [get(data, 'data.contacts.debtsToPayProviders')];
      const receivableAccounts = isArray(get(data, 'data.contacts.receivableAccounts'))
        ? get(data, 'data.contacts.receivableAccounts')
        : [get(data, 'data.contacts.receivableAccounts')];

      return {
        debtsToPayProviders,
        receivableAccounts
      }
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
    builder.addCase(fetchContactAccountingCategories.fulfilled, (state, action) => {
      state.debtsToPayProviders = get(action, 'payload.debtsToPayProviders')
      state.receivableAccounts = get(action, 'payload.receivableAccounts')
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchContactAccountingCategories.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchContactAccountingCategories.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer } = appSlice;

export const categoriesSelector = adapter.getSelectors(state => state.categories);

export default reducer;