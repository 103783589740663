import { handleError } from './errors'

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const fetchWithNextPage = async (callback, limit, data = [], params) => {
  let attempt = 0;
  let metadata = {
    limit: limit,
    start: data.length,
    sortDirection: 'asc'
  }

  while (true) {
    try {

      const { data: newData, hasNextPage } = await callback(metadata, params)
      if (newData.length === 0)
        break;

      data = data.concat(newData)
      metadata = {
        ...metadata,
        start: data.length,
      }
      attempt = 0;

      if (!hasNextPage)
        break;
       
    } catch (error) {
      attempt++;
      if (attempt > 2) {
        throw handleError(error);
      }
    }

    // eslint-disable-next-line
    await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(attempt + 1, 2)))
  }

  return data;
}

export const fetchById = async (callback, data = [], maxFetchPerMin) => {
  let fetchNumber = 0;
  let attempt = 0;
  let i = 0;

  for (let idx = 0; idx < data.length; idx++) {
    const element = data[idx];

    while (true) {
      try {
        if (!!maxFetchPerMin && i === 0 && fetchNumber >= 2)
          fetchNumber = maxFetchPerMin;
  
        if (!!maxFetchPerMin && fetchNumber >= maxFetchPerMin) {
          i++;
          await delay(60000)
        }
  
        await callback(element.id, data.length - idx)
        fetchNumber = !!maxFetchPerMin && fetchNumber >= maxFetchPerMin ? 1 : fetchNumber + 1;
        attempt = 0;     

        break;
      } catch (error) {
        attempt++;
        if (attempt > 2) {
          throw handleError(error);
        }
      }
      
      // eslint-disable-next-line
      await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(attempt + 1, 2)))
    }

    // eslint-disable-next-line
    await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(attempt + 1, 2)))    
  }
  
  return data;
}

export const fetch = async (callback, data = [], params, maxFetchPerMin, maxAttempts = 5) => {
  let fetchNumber = 0;
  let attempt = 0;
  let metadata = {
    limit: 30,
    start: data.length,
  }

  let i = 0;
  while (true) {
    try {
      if (!!maxFetchPerMin && i === 0 && fetchNumber >= 2)
        fetchNumber = maxFetchPerMin;

      if (!!maxFetchPerMin && fetchNumber >= maxFetchPerMin) {
        i++;
        await delay(60000)
      }

      const { data: newData, total } = await callback(metadata, params)
      fetchNumber = !!maxFetchPerMin && fetchNumber >= maxFetchPerMin ? 1 : fetchNumber + 1;

      if (newData.length === 0)
        break;

      data = data.concat(newData)
      metadata = {
        ...metadata,
        start: data.length,
      }
      attempt = 0;

      if (data.length >= total)
        break;
       
    } catch (error) {
      attempt++;
      if (attempt > maxAttempts) {
        throw handleError(error);
      }
    }

    // eslint-disable-next-line
    await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(attempt + 1, 2)))
  }

  return data;
}