import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { capitalize, get } from 'lodash';

import * as queries from '../../../graphql/queries'
import { handleError } from '../../../utils/errors'
import { openModal } from '../../../reducers/modals'
import { hasPermissionTo } from "../../../selectors/auth";
import Table from "../../common/GrayTable";
import Header from '../../settings/common/Header';
import Actions from '../PriceLists/Actions';
import Tooltip from "../../common/Tooltip";

import Plus from '../../svg/Plus';
import { APIGraphqlSelector } from '../../../selectors/app';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';

const PriceLists = () => {
  const connectionStatus = useConnectionStatus()
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const ref = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [error, setError] = useState();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageView',
      'virtualPageURL': '/inventory/price-lists',
      'virtualPageTitle': 'Price Lists',
    });

    return () => ref.current = false;
  }, []);

  const startLoading = useCallback(() => {
    if (ref.current) {
      setLoading(true);
    }
  }, []);

  const stopLoading = useCallback(() => {
    if (ref.current) {
      setLoading(false);
    }
  }, []);

  const fetchData = useCallback(async ({ pageIndex, pageSize }) => {
    startLoading();
    setError(null);

    try {
      const response = await APIGraphql(graphqlOperation(queries.allPriceLists, {
        batch: { limit: pageSize, start: pageIndex * pageSize },
        includeMetadata: true,
      }));
      stopLoading();

      if (ref.current)
        setData(get(response, 'data.allPriceLists.data', []));

      setControlledPageCount(Math.ceil(get(response, 'data.allPriceLists.metadata.total', 0) / pageSize));

    } catch (error) {
      stopLoading();
      setError(handleError(error, I18n.get('priceListsLoadError', 'Sucedió un error cargando las listas de precios.')));
    }
    if (ref.current) {
      setTableIndicators({ pageIndex, pageSize })
    }
  }, [stopLoading, startLoading, APIGraphql]);

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('name', 'Nombre')),
        sortable: false,
        accessor: 'name',
        Cell: ({ value, row }) => {
          return (
            <p
              className="h4 text-primary text-truncate pointer styled-hover"
              onClick={() => (can("edit", "price-lists") && get(row, 'original.editable', false)) ? dispatch(openModal({
                modal: 'priceList', params: {
                  priceList: { ...row.original },
                  onRefresh: () => fetchData(tableIndicators),
                },
              })) : null}
              hovercontent={value}
            >
              {value}
            </p>
          )
        }
      },
      {
        Header: capitalize(I18n.get('type', 'Tipo')),
        accessor: 'type',
        Cell: ({ value }) => (
          <p className="h5 text-muted">
            {I18n.get(`priceListType.${value}`, value)}
          </p>
        )
      },
      {
        Header: capitalize(I18n.get('actions', 'Acciones')),
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => (
          <Actions
            priceList={row.original}
            onRefresh={() => fetchData(tableIndicators)}
            onView={() => dispatch(openModal({
              modal: 'priceList', params: {
                priceList: { ...row.original },
                onRefresh: () => fetchData(tableIndicators),
              },
            }))}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />
        )
      }
    ], [fetchData, startLoading, stopLoading, dispatch, tableIndicators, can]
  )

  useEffect(() => {
    fetchData({
      pageIndex: 0,
      pageSize: 10,
    })
  }, [fetchData]);

  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Header
          title={<p className="text-title-secundary">{I18n.get('priceLists', 'Listas de precios')}</p>}
          subtitle={
            <p className="h5 text-muted text-capitalize-first">
              {I18n.get('priceListsSubtitle', 'Asigna varios precios a tus productos con un valor fijo o un porcentaje de descuento sobre el precio base.')}
              <a href="https://ayuda.alegra.com/es/crea-listas-de-precio-en-el-sistema-punto-de-venta-pos" target="_blank" rel="noreferrer">
                {I18n.get('knowMore', 'Saber más')}
              </a>
            </p>
          }
          actions={(
            <div className="d-flex">
              <Tooltip
                tooltipActive={!can("add", "price-lists")}
                overlay={<p className="text-capitalize-first">
                  {I18n.get("userNotAllowed.price-lists.add", "no tienes permisos para agregar listas de precio")}
                </p>}
              >
                <button
                  type="button"
                  className="btn btn-submit-large d-flex justify-content-center align-items-center"
                  disabled={!can("add", "price-lists") || !connectionStatus}
                  onClick={() => {
                    dispatch(openModal({
                      modal: 'priceList', params: {
                        onRefresh: () => fetchData(tableIndicators),
                      }
                    }))
                  }}
                >
                  <Plus className="icon icon-white mr-2" />
                  {I18n.get('newPriceList', 'Nueva lista')}
                </button>
              </Tooltip>
            </div>
          )}
        />
        <div className="shadowp-4">
          <Table
            loading={loading}
            data={data}
            error={error}
            showNetworkConnectionError
            onRefresh={({ pageIndex, pageSize }) => fetchData({ pageIndex, pageSize })}
            noDataText={(
              <div className="text-center">
                <p>
                  {capitalize(I18n.get('priceListsNoDataText', 'Crea tu primera lista de precios en un parpadeo. 👀'))}
                </p>

                <Tooltip
                  tooltipActive={!can("add", "price-lists")}
                  overlay={<p className="text-capitalize-first">
                    {I18n.get("userNotAllowed.price-lists.add", "no tienes permisos para agregar listas de precio")}
                  </p>}
                >
                  <button
                    type="button"
                    className="btn btn-submit mr-3"
                    onClick={() => dispatch(openModal({
                      modal: 'priceList', params: {
                        onRefresh: () => fetchData(tableIndicators),
                      }
                    }))}
                  >
                    {I18n.get('newPriceList', 'Nueva lista')}
                  </button>
                </Tooltip>
              </div>
            )}
            columns={columns}
            onFetchData={fetchData}
            controlledPageCount={controlledPageCount}
          />
        </div>
      </div>
    </div>
  )
}

export default PriceLists;