import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import Loader from 'react-content-loader';
import dayjs from 'dayjs';

import {
  decimalPrecision,
  timezone as tzSelector,
  dateFormat as dateFormatSelector,
} from '../../../selectors/company';
import {
  paymentsLoading,
  payments as paymentsSelector,
  baseAmount,
  startDate,
} from '../../../selectors/shifts';
import { lowerCase } from 'lodash';

const textLoader = () => (
  <Loader title="" height={14} width={72}>
    <rect width='100%' height='100%' x="0" y="0" rx="5" ry="5" />
  </Loader>
)


const NewDetails = () => {
  const decimal = useSelector(decimalPrecision);
  const loading = useSelector(paymentsLoading);
  const payments = useSelector(paymentsSelector);
  const base = useSelector(baseAmount);
  const start = useSelector(startDate);
  const timezone = useSelector(tzSelector);
  const dateFormat = useSelector(dateFormatSelector);

  const cash = new BigNumber(payments.cash);
  const debit = new BigNumber(payments.debit);
  const credit = new BigNumber(payments.credit);
  const transfer = new BigNumber(payments.transfer);
  const refunds = new BigNumber(payments.refunds);
  const inPayment = new BigNumber(payments.inPayment);
  const outPayment = new BigNumber(payments.outPayment);
  const cashPaymentSales = new BigNumber(0).plus(cash).plus(debit).plus(credit).plus(transfer);
  // const creditSales = new BigNumber(payments.creditSales);
  const total = new BigNumber(base)
    .plus(cash)
    .plus(inPayment)
    .minus(refunds)
    .minus(outPayment)

  return (
    <div className="d-flex flex-column">
      <div className="close-shift-date-container d-flex align-items-center">
        <span className="text-capitalize-first">
          {I18n.get('startDate', 'fecha de inicio')}
        </span>
        <span className="close-shift-date-container__date">
          {!!start ? dayjs.tz(start, timezone).format(I18n.get(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))) : null}
        </span>
      </div>

      <div className="close-shift-sales-container">
        {/* <p className="close-shift-sales-container__sales-info">
          <span>{I18n.get('immediatePaymentSale', 'Ventas a contado')}</span>
          <span>
            {!!loading
              ? textLoader()
              : cashPaymentSales.toFormat(decimal)}
          </span>
        </p> */}
        {/* <p className="close-shift-sales-container__sales-info">
          <span>{I18n.get('creditPaymentSales', 'Ventas a crédito')}</span>
          <span>
            {!!loading
              ? textLoader()
              : creditSales.toFormat(decimal)}
          </span>
        </p> */}
        <p className="close-shift-sales-container__sales-info">
          <span>{I18n.get('total', 'total') + ' ' + lowerCase(I18n.get('of', 'de') + ' ' + I18n.get('sales', 'ventas'))}</span>
          <span>
            {!!loading
              ? textLoader()
              : cashPaymentSales.toFormat(decimal)}
          </span>
        </p>
      </div>

      <div className="close-shift-info-container">
        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('base', 'base inicial')}</span>
          <span>{new BigNumber(base).toFormat(decimal)}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('cashSales', 'ventas en efectivo')}</span>
          {!!loading
            ? textLoader()
            : cash.toFormat(decimal)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</span>
          {!!loading
            ? textLoader()
            : debit.toFormat(decimal)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</span>
          {!!loading
            ? textLoader()
            : credit.toFormat(decimal)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('transferSales', 'ventas por transferencias')}</span>
          {!!loading
            ? textLoader()
            : transfer.toFormat(decimal)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('refundCash', 'Devolución de dinero')}</span>
          {!!loading
            ? textLoader()
            : <span className="text-danger">{refunds.eq(0) ? '' : '-'}{refunds.toFormat(decimal)}</span>
          }
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('invoiceCash', 'ingresos de efectivo')}</span>
          {!!loading
            ? textLoader()
            : inPayment.toFormat(decimal)}
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <span className="text-capitalize-first">{I18n.get('expenseCash', 'retiros de efectivo')}</span>
          {!!loading
            ? textLoader()
            : <span className="text-danger">{outPayment.eq(0) ? '' : '-'}{outPayment.toFormat(decimal)}</span>}
        </div>

        <div className="d-flex align-items-center justify-content-between inter-semibold w-100">
          <span className="text-capitalize-first">{I18n.get('totalOfMovements', 'Total de movimientos')}</span>
          {!!loading
            ? textLoader()
            : total.plus(debit).plus(credit).plus(transfer).toFormat(decimal)}
        </div>
      </div>

      <div className="close-shift-cash-info-container">
        <span>{I18n.get('cashExpectedInClosingShift', 'Dinero esperado en caja')}</span>
        {!!loading
            ? textLoader()
            : total.toFormat(decimal)}
      </div>
    </div>
  )
}

export default NewDetails;