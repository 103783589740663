import dayjs from "dayjs";

export const notificationsTransformer = (name, params) => {
  return {
    user: {
      company: {
        userNotifications: {
          notifications: JSON.stringify({ [name]: dayjs(), ...params })
        }
      }
    }
  }
}

export const handleNotificationEvent = (ref, eventName, action) => {
  const innerAs = ref.current?.shadowRoot.getElementById('notifications-box').getElementsByTagName('a')
  if (!!innerAs && innerAs.length >= 1 && innerAs[1]?.href?.includes(eventName)) {
    innerAs[1]?.addEventListener("click", action)
  }
}

export const createNotificationBody = ({ name, userId, from = null, to = null, type = "info", section = null, title, text, actionCall, actionLabel, actionType }) => ({
  name,
  teamInCharge: "tmd-pos",
  receptors: {
    users: [userId]
  },
  availability: {
    from: from ? from : dayjs().toISOString(),
    to: to ? to : dayjs().add(1, 'days').toISOString()
  },
  message: [
    {
      channel: "web",
      type: type,
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionType,
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})
