import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { capitalize, get, lowerCase, deburr } from 'lodash';
import { useSelector } from 'react-redux'

import { isOnlyInvoicingPlan, country, decimalPrecision } from '../../../selectors/company'
import { activeWarehouses } from '../../../selectors/warehouses'
import configAPI from '../../../reducers/configAPI';
import Trash from '../../svg/Trash'
import { renderCheckbox, renderSelect, renderField } from '../fields/V0/Fields';

const filterOptions = (query, options) =>
  options.filter(option =>
    lowerCase(deburr(option.value)).includes(lowerCase(deburr(query)))
    || lowerCase(deburr(option.code)).includes(lowerCase(deburr(query))))

const Inventory = ({ values, form, ...props }) => {
  const warehouses = useSelector(activeWarehouses)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)
  const applicationVersion = useSelector(country);
  const isInventariable = !!values.inventariable;
  const decimal = useSelector(decimalPrecision)

  const addWarehouse = () => {
    if (get(values, 'warehouses.length', 0) < warehouses.length) {
      form.mutators.push("warehouses")
      if (get(values, 'warehouses.length', 0) === 0) {
        form.change('warehouses.0.initialQuantity', get(values, 'initialQuantity', null))
        form.change('warehouses.0.minQuantity', get(values, 'minQuantity', null))
        form.change('warehouses.0.maxQuantity', get(values, 'maxQuantity', null))
      }
    }
  }

  const removeWarehouse = (callback) => {
    if (get(values, 'warehouses.length', 0) === 1) {
      form.change('initialQuantity', get(values, 'warehouses.0.initialQuantity', null))
      form.change('minQuantity', get(values, 'warehouses.0.minQuantity', null))
      form.change('maxQuantity', get(values, 'warehouses.0.maxQuantity', null))
    }
    callback()
  }

  useEffect(() => {
    if (!isInventariable) {
      if (!!values.warehouses) {
        for (let index = 0; index < values.warehouses.length; index++) {
          form.mutators.pop("warehouses")
        }
      }
    }
  }, [isInventariable, form.mutators, values.warehouses])

  return (
    <>
      <Field
        name="inventariable"
        component={renderCheckbox}
        className="col-12 my-2"
        disabled={onlyInvoicing}
        onlyInvoicingTooltip={onlyInvoicing}
        switchLabel={capitalize(I18n.get('controlInventory', 'Controlar inventario'))}
        helpTooltip={I18n.get('controlInventoryHelp', 'Activa esta opción si deseas tener el control de cantidades')}
      />

      <div className="p-0 col-12 form-row form-field-array-wrapper">
        {!!isInventariable && (
          <div className="col-12 form-row m-0 pt-2 mb-2">
            <Field
              name="unit"
              component={renderSelect}
              className={!!get(values, 'warehouses.length') ? "col-md-6" : "col-12"}
              loadOptions={async inputValue => {
                try {
                  // ex queries.allUnits
                  const response = await configAPI.v2.get('/units', {
                    application_version: country,
                  })
                  let data = get(response, 'data', []);
                  if (country === "republicaDominicana") {
                    data = data.map(unit => ({ ...unit, unitType: unit.itemType }))
                  }

                  if (applicationVersion === "republicaDominicana") {
                    data = data.filter(unit => unit.unitType === "product").length !== 0 ? data.filter(unit => unit.unitType === "product") : data;
                  }
                  return filterOptions(inputValue, data)
                } catch {
                  return []
                }
              }}
              defaultOptions
              getOptionLabel={option => option.value}
              getOptionValue={option => option.key}
              label={capitalize(I18n.get('measurementUnit', 'unidad de medida'))}
              required={!!isInventariable}
            />

            <Field
              name="unitCost"
              component={renderField}
              className="col-md-6"
              type="number"
              label={capitalize(I18n.get('unitCost', 'costo inicial'))}
              onChange={e => maxDecimals({e, key: 'unitCost', decimals: decimal, country: applicationVersion, values})}
              required={!!isInventariable}
            />

            {!get(values, 'warehouses.length') && (
              <>
                <Field
                  name='initialQuantity'
                  component={renderField}
                  className="col-md-6"
                  type="number"
                  label={capitalize(I18n.get('initialQuantity', 'cantidad inicial'))}
                  required
                />
                <Field
                  name='minQuantity'
                  component={renderField}
                  className="col-md-6"
                  type="number"
                  label={capitalize(I18n.get('minQuantity', 'cantidad mínima'))}
                  help={I18n.get('minQuantityHelp', 'Recibe alertas cuando llegues al stock mínimo que indiques.')}
                />
                <Field
                  name='maxQuantity'
                  component={renderField}
                  className="col-md-6"
                  type="number"
                  label={capitalize(I18n.get('maxQuantity', 'cantidad máxima'))}
                  help={I18n.get('maxQuantityHelp', 'Recibí alertas cuando llegués al stock máximo que indiqués.')}
                />
                {(warehouses.length > 1) && (
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-submit mb-3"
                      onClick={() => addWarehouse()}
                    >
                      {capitalize(I18n.get('distributeInWarehouses', 'distribuir en bodegas'))}
                    </button>
                  </div>
                )}

              </>
            )}
          </div>
        )}

        <FieldArray name="warehouses">
          {({ fields }) =>
            fields.map((name, index) => (
              <div key={index} className="col-12 form-row m-0">
                <Field
                  name={`${name}.warehouse`}
                  component={renderSelect}
                  className="col-6"
                  options={warehouses}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  label={capitalize(I18n.get('warehouse', 'bodega'))}
                  required
                  validate={value => !get(value, 'id', null) ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                  addOptionText={!!props.newWarehouseAction ? capitalize(I18n.get('newWarehouse', 'Nueva bodega')) : null}
                  addOptionAction={() => props.newWarehouseAction(index)}
                />
                <Field
                  name={`${name}.initialQuantity`}
                  component={renderField}
                  className="col-6"
                  type="number"
                  label={capitalize(I18n.get('initialQuantity', 'cantidad inicial'))}
                  validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                  required
                />
                <Field
                  name={`${name}.minQuantity`}
                  component={renderField}
                  className="col-6"
                  type="number"
                  label={capitalize(I18n.get('minQuantity', 'cantidad mínima'))}
                />
                <div className="col-6 form-row">
                  <Field
                    name={`${name}.maxQuantity`}
                    component={renderField}
                    className="col-10"
                    type="number"
                    label={capitalize(I18n.get('maxQuantity', 'cantidad máxima'))}
                  />
                  <button
                    type="button"
                    className="btn button-transparent col-2 mb-3"
                    style={{ 
                      height: '3.6rem', 
                      outline: 'none', 
                      boxShadow: 'none',
                      paddingTop: '2.5rem'
                    }}
                    onClick={() => removeWarehouse(() => fields.remove(index))}
                  >
                    <Trash className="icon-secondary" />
                  </button>
                </div>
              </div>
            ))
          }
        </FieldArray>
      </div>

      {(isInventariable && !!get(values, 'warehouses.length')) && warehouses.length > 1 && (
        <div className="col-12">
          <button
            type="button"
            className="btn btn-submit mb-3"
            disabled={get(values, 'warehouses.length') >= warehouses.length}
            onClick={() => addWarehouse()}
          >
            {capitalize(I18n.get('addWarehouse', 'agregar bodega'))}
          </button>
        </div>
      )}
    </>
  )
}

export default Inventory;
