import React, { useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useLocation, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get, isEmpty, isNull, isUndefined, lowerFirst } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { itemTransformer } from '../../../utils/transformers';
import { toast, replaceAndParse } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import {
  checkVariantImages,
  isItemFromStation,
  saveVariantsImages,
} from '../../../utils/items';
import {
  APIGraphqlSelector,
  station as stationSelector,
  stationWarehouse,
} from '../../../selectors/app';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import {
  decimalPrecision,
  country as countrySelector,
  electronicInvoicing,
  regime as regimeSelector,
} from '../../../selectors/company';
import {
  requiredFields,
  customFields as customFieldSelector,
} from '../../../selectors/customFields';
import { getCategoryByName } from '../../../selectors/categories';
import { refresh } from '../../../reducers/items';
import * as itemsDB from '../../../database/itemsDB';
import {
  initialValuesFromModal,
  transform,
  validate,
  validateOnSubmit,
} from '../../forms/item/utils';
import Primary from './Primary';
import Secondary from './Secondary';
import ItemCategory from '../../modals/itemCategory/ItemCategory';
import PriceList from '../../modals/priceList/PriceList';
import Warehouse from '../../modals/warehouse/Warehouse';

const NewItem = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const customFields = useSelector(requiredFields);
  const allCustomFields = useSelector(customFieldSelector);
  const station = useSelector(stationSelector);
  const mainWarehouse = useSelector(stationWarehouse);
  const regime = useSelector(regimeSelector);
  const isElectronic = useSelector(electronicInvoicing);
  const mainCategory = useSelector(getCategoryByName('Ventas', 'Sales'));
  const inventoryCategory = useSelector(getCategoryByName('Inventarios'));
  const inventariablePurchaseCategory = useSelector(
    getCategoryByName('Costos de inventario')
  );
  const [priceListIndex, setPriceListIndex] = useState(0);
  const [warehouseIndex, setWarehouseIndex] = useState(0);

  const isItemCategoryOpen = useSelector((state) =>
    get(state, 'modals.itemCategory.isOpen', false)
  );
  const isPriceListOpen = useSelector((state) =>
    get(state, 'modals.priceList.isOpen', false)
  );
  const isWarehouseOpen = useSelector((state) =>
    get(state, 'modals.warehouse.isOpen', false)
  );
  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async (values) => {
    if (validateOnSubmit(values))
      return formError(
        null,
        I18n.get('createItemError', 'hubo un error en la creación del producto')
      );

    const transformedValues = transform(values, {
      country,
      decimal,
      allCustomFields,
      categories: {
        mainCategory,
        inventoryCategory,
        inventariablePurchaseCategory,
      },
    });

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.createItem, {
          item: itemTransformer(transformedValues),
        })
      );

      const { idWarehouse } = station;
      const item = get(response, 'data.createItem', null);

      dispatch(
        sendGTMEvent('item-created', {
          creationOrigin: 'advanced form',
          hasVariants: get(item, 'type', null) === 'variantParent',
          hasAdditionalFields: !isEmpty(get(item, 'customFields', [])),
          itemType: get(item, 'type', null),
          hasCategory: !!get(item, 'itemCategory', null),
        })
      );

      dispatch(
        sendNewGTMEvent('pos-item-created', {
          id: get(item, 'id', null),
          error: 'no error',
          type: get(item, 'type', null),
          method: 'advanced',
          field: Object.keys(transformedValues).filter(
            (key) =>
              !isUndefined(transformedValues[key]) &&
              !isNull(transformedValues[key])
          ),
        })
      );

      if (get(transformedValues, 'type', '') === 'variantParent') {
        const variantsImages = checkVariantImages(transformedValues);
        if (get(variantsImages, 'length')) {
          try {
            await saveVariantsImages(
              JSON.parse(get(item, 'itemVariants')),
              variantsImages
            );
          } catch {}
        }
      }

      if (isItemFromStation(item, idWarehouse)) {
        await itemsDB.put({ ...item, id: parseInt(get(item, 'id')) });
        dispatch(refresh());
      }

      history.goBack();
      toast.success({
        title: `${I18n.get('itemCreatedSuccessfully', 'Directo al blanco')}`,
        subtitle: replaceAndParse(
          I18n.get(
            'itemCreatedSuccessfullyMessage',
            'El {} {} ya está disponible en tu cuenta.'
          ),
          [
            get(item, 'type') !== 'simple'
              ? lowerFirst(I18n.get(get(item, 'type'), 'producto'))
              : lowerFirst(I18n.get('product', 'producto')),
            `<span class="font-weight-bold">${get(item, 'name', '')}</span>`,
          ]
        ),
      });
    } catch (error) {
      toast.error({
        subtitle: handleError(
          error,
          I18n.get(
            'createItemError',
            'hubo un error en la creación del producto'
          )
        ),
      });
      const parseError = handleError(error);
      dispatch(
        sendNewGTMEvent('pos-item-created', {
          error: parseError,
          method: 'basic',
        })
      );
      return formError(
        error,
        I18n.get('createItemError', 'hubo un error en la creación del producto')
      );
    }
  };

  return (
    <Form
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={
        !!get(location, 'state.type')
          ? initialValuesFromModal({
              type: get(location, 'state.type'),
              customFields: customFields,
              initValues: !!get(location, 'state.type')
                ? get(location, 'state.initValues')
                : null,
              mainCategory,
              mainWarehouse,
            })
          : null
      }
      validate={(values) =>
        validate(values, {
          country,
          customFields,
          regime,
          isElectronic,
        })
      }
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, values, form, submitting, hasValidationErrors }) => (
        <>
          <form
            noValidate
            className='py-5 row new-item'
            onSubmit={handleSubmit}
          >
            <Primary
              form={form}
              values={values}
              submitting={submitting}
              setPriceListIndex={setPriceListIndex}
              setWarehouseIndex={setWarehouseIndex}
            />

            <Secondary
              form={form}
              values={values}
              submitting={submitting}
              hasValidationErrors={hasValidationErrors}
            />
          </form>

          {isItemCategoryOpen && (
            <ItemCategory
              onCreated={(category) => form.change('category', category)}
            />
          )}

          {isPriceListOpen && (
            <PriceList
              onCreated={(priceList) => {
                try {
                  form.change(
                    `priceLists.${priceListIndex}.priceList`,
                    priceList
                  );
                } catch {
                  //
                }
              }}
            />
          )}

          {isWarehouseOpen && (
            <Warehouse
              onCreated={(warehouse) => {
                try {
                  form.change(
                    `warehouses.${warehouseIndex}.warehouse`,
                    warehouse
                  );
                } catch {
                  //
                }
              }}
            />
          )}
        </>
      )}
    </Form>
  );
};

export default NewItem;
