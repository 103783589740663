// eslint-disable-next-line
export default ({ id, className, fill, width, height }) => (
  <svg
    id={id}
    className={className}
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M8.003 11.11 13.277 4.079c.233-.311.674-.374.984-.141.311.233.374.674.141.984l-4.957 6.609L14.403 18.141c.233.311.17.751-.141.984-.126.095-.274.141-.421.141-.214 0-.425-.097-.563-.281l-5.273-7.031C7.817 11.703 7.817 11.36 8.003 11.11z"
      fill={fill ?? "#94A3B8"} />
  </svg>
);
