// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.166672" y="0.00012207" width="28" height="28" rx="5" fill="#F1F5F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.00001 14.0001C9.00001 13.724 9.22387 13.5001 9.50001 13.5001H18.8333C19.1095 13.5001 19.3333 13.724 19.3333 14.0001C19.3333 14.2763 19.1095 14.5001 18.8333 14.5001H9.50001C9.22387 14.5001 9.00001 14.2763 9.00001 14.0001Z" fill="#0F172A" />
  </svg>
)