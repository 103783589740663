// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
  <g>
    <path
        d="M 1.2117182,6.7924089 H 2.7122036 V 41.397305 H 1.2117182 Z"
        id="rect1453" />
    <path
        d="M 12.750646,6.7924089 H 14.251131 V 41.397305 H 12.750646 Z"
        id="rect1463" />
    <path
        d="M 22.787506,6.7924089 H 24.287992 V 41.397305 H 22.787506 Z"
        id="rect1465" />
    <path
        d="M 26.501144,6.7924089 H 28.00163 V 41.397305 H 26.501144 Z"
        id="rect1467" />
    <path
        d="M 29.612572,6.7924089 H 31.113057 V 41.397305 H 29.612572 Z"
        id="rect1469" />
    <path
        d="M 32.523262,6.7924089 H 34.023747 V 41.397305 H 32.523262 Z"
        id="rect1471" />
    <path
        d="M 35.132843,6.7924089 H 36.633328 V 41.397305 H 35.132843 Z"
        id="rect1473" />
    <path
        d="M 43.363068,6.7924089 H 44.863553 V 41.397305 H 43.363068 Z"
        id="rect1475" />
    <path
        d="M 3.4198279,6.7924089 H 4.3181018 V 41.397305 H 3.4198279 Z"
        id="rect1477" />
    <path
        d="M 5.8286743,6.7924089 H 6.7269481 V 41.397305 H 5.8286743 Z"
        id="rect1479" />
    <path
        d="M 9.4419441,6.7924089 H 10.340218 V 41.397305 H 9.4419441 Z"
        id="rect1481" />
    <path
        d="M 16.367376,6.7924089 H 17.26565 V 41.397305 H 16.367376 Z"
        id="rect1483" />
    <path
        d="M 18.073645,6.7924089 H 18.971918 V 41.397305 H 18.073645 Z"
        id="rect1485" />
    <path
        d="M 19.478804,6.7924089 H 20.377077 V 41.397305 H 19.478804 Z"
        id="rect1487" />
    <path
        d="M 41.660263,6.7924089 H 42.558537 V 41.397305 H 41.660263 Z"
        id="rect1489" />
    <path
        d="M 38.849945,6.7924089 H 41.153379 V 41.397305 H 38.849945 Z"
        id="rect1491" />
  </g>
  </svg>
)