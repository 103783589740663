import { useMemo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { getMainCurrency } from "../../selectors/currencies";
import { decimalPrecision } from "../../selectors/company";

const useFormat = () => {
  const mainCurrency = useSelector(getMainCurrency);
  const decimal = useSelector(decimalPrecision);

  const prefix = !!get(mainCurrency, "symbol") ? get(mainCurrency, "symbol") : "";

  const fmt = useMemo(
    () => ({
      prefix,
      decimalSeparator: ".",
      groupSeparator: ",",
      groupSize: 3,
    }),
    [prefix]
  );

  return {
    fmt,
    decimal,
  };
};

export default useFormat;