// eslint-disable-next-line
export default ({ id, className, fill }) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" id={id} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8592 10.667C14.0259 10.1756 13.7099 9.66933 13.2075 9.5395C12.6765 9.40229 12.145 9.73874 11.9402 10.2475C11.5929 11.11 11.0491 11.868 10.3542 12.4455C9.41141 13.229 8.24878 13.6316 7.0644 13.5847C5.88001 13.5379 4.74716 13.0445 3.85886 12.1886C2.97056 11.3327 2.38178 10.1673 2.19283 8.89093C2.00389 7.61456 2.22648 6.30621 2.82266 5.1888C3.41885 4.07139 4.35175 3.21406 5.46242 2.7629C6.57308 2.31173 7.79278 2.29464 8.9137 2.71453C9.71558 3.01491 10.4314 3.52563 11.0018 4.19486C11.0877 4.29565 11.188 4.38173 11.2973 4.45058L9.40561 6.19677L14.4786 7.21525L14.3975 1.58887L12.7129 3.14388C11.886 2.03676 10.7882 1.19732 9.53861 0.729211C7.99665 0.151597 6.31881 0.17511 4.79096 0.795744C3.26311 1.41638 1.9798 2.59573 1.15967 4.13286C0.339544 5.66999 0.0333521 7.46978 0.293267 9.22557C0.553181 10.9814 1.36312 12.5845 2.58508 13.7619C3.80704 14.9393 5.36541 15.618 6.99468 15.6825C8.62394 15.7469 10.2233 15.1931 11.5202 14.1153C12.5886 13.2275 13.3973 12.0293 13.8592 10.667Z"
      fill={fill}
    />
  </svg>
);
