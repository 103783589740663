import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get, mergeWith } from 'lodash';
import { BigNumber } from 'bignumber.js'

import * as queries from '../graphql/queries'
import { handleError } from '../utils/errors'
import { APIGraphqlSelector, station as stationSelector } from '../selectors/app';
import { decimalPrecision } from '../selectors/company';
import { current } from '../selectors/shifts';

const initialState = {
  currentShiftStatus: {},
  currentShiftPayments: {
    loading: false,
    error: false,
    payments: {
      cash: 0,
      credit: 0,
      currencies: {},
      debit: 0,
      inPayment: 0,
      outPayment: 0,
      refunds: 0,
      sales: 0,
      transfer: 0,
      creditSales: 0,
    }
  }
}

export const getShiftStatus = createAsyncThunk(
  'shifts/status',
  async (params, { rejectWithValue, getState }) => {
    try {
      const { id } = stationSelector(getState())
      const APIGraphql = APIGraphqlSelector(getState());

      const data = await APIGraphql(graphqlOperation(queries.getShiftStatus, {
        idStation: id,
        ...params
      }))
      return get(data, 'data.getShiftStatus', null);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

const fetch = async (callback, decimal, limit = 30, extra) => {
  const attempt = get(extra, 'attempt', 0);
  const maxAttempts = get(extra, 'maxAttempts', 2);

  let data = {
    cash: 0,
    credit: 0,
    debit: 0,
    sales: 0,
    transfer: 0,
    currencies: {},
    inPayment: 0,
    outPayment: 0,
    refunds: 0,
    creditSales: 0,
  };
  let metadata = {
    limit: limit,
    start: 0,
  }

  try {
    while (true) {
      const { data: responseData, metadata: responseMetadata } = await callback(metadata)

      if (!responseData || !responseMetadata)
        break;

      const { from, to, total } = responseMetadata

      data = mergeWith(data, {
        cash: get(responseData, 'cash', 0),
        credit: get(responseData, 'credit', 0),
        debit: get(responseData, 'debit', 0),
        sales: get(responseData, 'sales', 0),
        transfer: get(responseData, 'transfer', 0),
        inPayment: get(responseData, 'inPayment', 0),
        outPayment: get(responseData, 'outPayment', 0),
        refunds: get(responseData, 'refunds', 0),
        currencies: JSON.parse(get(responseData, 'currencies', {})),
        creditSales: get(responseData, 'creditSales', 0),
      }, (objValue, srcValue, key) => {
        if (key !== 'currencies') return objValue + srcValue
        if (!srcValue)
          return objValue
        let result = { ...objValue }
        Object.keys(srcValue).map(key => {
          if (!!objValue[key]) {
            result[key] = {
              ...srcValue[key],
              total: new BigNumber(get(srcValue, `${key}.total`, 0))
                .plus(get(objValue, `${key}.total`, 0)).decimalPlaces(decimal)
                .toNumber()
            }
          } else {
            result[key] = srcValue[key];
          }
          return null;
        })
        return result;
      })

      if (to >= total || from >= total)
        break;

      metadata = {
        ...metadata,
        start: to,
      }
    }
  } catch (error) {
    if (get(error, 'errors.0.errorType', '') === "Runtime.ExitError" && attempt < maxAttempts) {
      return await fetch(callback, decimal, limit, { attempt: attempt + 1 });
    }
    throw handleError(error);
  }

  return data;
}

const fetchPaymentsPartial = (api, params) => async (metadata) => {
  const response = await api(graphqlOperation(queries.getShiftPayments, {
    batch: metadata,
    ...params,
  }));

  return {
    data: get(response, 'data.getShiftPayments.data', null),
    metadata: get(response, 'data.getShiftPayments.metadata', null),
  }
}

export const getShiftPayments = createAsyncThunk(
  'shifts/payments',
  async (params, { rejectWithValue, getState }) => {
    const station = stationSelector(getState());
    const shift = current(getState());
    const decimal = decimalPrecision(getState())
    const APIGraphql = APIGraphqlSelector(getState());

    try {
      const data = await Promise.all([
        fetch(fetchPaymentsPartial(APIGraphql, {
          id: shift.id,
          idStation: station.id,
          type: 'invoices',
          includeMetadata: true,
        }), decimal, 500),
        fetch(fetchPaymentsPartial(APIGraphql, {
          id: shift.id,
          idStation: station.id,
          type: 'cash-management',
          includeMetadata: true,
        }), decimal),
        fetch(fetchPaymentsPartial(APIGraphql, {
          id: shift.id,
          idStation: station.id,
          type: 'refunds',
          includeMetadata: true,
        }), decimal),
      ])

      return {
        cash: get(data, '0.cash'),
        credit: get(data, '0.credit'),
        currencies: get(data, '0.currencies'),
        debit: get(data, '0.debit'),
        inPayment: get(data, '1.inPayment'),
        outPayment: get(data, '1.outPayment'),
        refunds: get(data, '2.refunds'),
        sales: get(data, '0.sales'),
        transfer: get(data, '0.transfer'),
        creditSales: get(data, '0.creditSales'),
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

const appSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getShiftStatus.fulfilled, (state, action) => {
      state.currentShiftStatus = action.payload;
    })
    builder.addCase(getShiftPayments.fulfilled, (state, action) => {
      state.currentShiftPayments = {
        ...state.currentShiftPayments,
        loading: false,
        error: null,
        payments: action.payload
      };
    })
    builder.addCase(getShiftPayments.pending, state => {
      state.currentShiftPayments = {
        ...state.currentShiftPayments,
        loading: true,
        error: null,
      };
    })
    builder.addCase(getShiftPayments.rejected, (state, action) => {
      state.currentShiftPayments = {
        ...state.currentShiftPayments,
        loading: false,
        error: action.payload,
      };
    })
  }
});

const { reducer } = appSlice;

export default reducer;