import en from './en'

const dictionary = {
  ...en,
  "onboarding.video.url": "https://www.youtube.com/embed/d0x-2M8SHWg",
  "onboarding.video.id": "d0x-2M8SHWg",
  "notItemsYet": "To create your first sale we invite you to add a product or service. 🏷️",
  "numerationsSettingTooltip": "Manage the numbering used for invoicing in your business from “Alegra Administration”.",
  "printPersonalizedInvoices": "Ticket customization",
  "printPersonalizedInvoicesHelp": "Give your touch to the final part of your tickets with a phrase, image, or both.",
  "printMeasurement": "Include the unit of measure in your tickets",
  "printMeasurementHelp": "Highlight the unit of measurement of your products and services.",
  "printLines": "Show total lines and products in your tickets",
  "printLinesHelp": "Include the number of lines on your ticket and the total number of sold products.",
  "taxesSettingTooltip": "Configure the taxes you need for invoicing at your point of sale from “Alegra Administration”.",
  "AlegraAccounting.new": "Accounting",
  "importProducts.text": "Import products",
  "importProducts.tooltip": "We will take you to Alegra Accounting to upload new products.",
  "solutionModal.accounting.supTitle": "Clear accounts with",
  "solutionModal.accounting.buttonMainText": "Try 15 days free",
  "solutionModal.accounting.buttonSecondaryText": "More information",
  "solutionModal.accounting.description": "With Alegra Management, you control your income and expenses with a click. You can also get administrative and sales reports in real time.",
  "solutionModal.accounting.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.invoice.supTitle": "Save time with",
  "solutionModal.invoice.buttonMainText": "Try 3 months for free",
  "solutionModal.invoice.buttonSecondaryText": "More information",
  "solutionModal.invoice.description": "Generate your invoices and receipts in seconds with the fastest invoicing software in Mexico. System 100% adapted to SAT with CFDI 4.0 and 3.3.",
  "solutionModal.invoice.subTitle": "*After 3 months, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.store.supTitle": "Increase your sales with",
  "solutionModal.store.buttonMainText": "Try 15 days free",
  "solutionModal.store.buttonSecondaryText": "More information",
  "solutionModal.store.description": "Start selling online in seconds! With Alegra Store, you can create your first online store in just 4 clicks.",
  "solutionModal.store.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.pos.supTitle": "Manage your business with",
  "solutionModal.pos.buttonMainText": "Try 15 days free",
  "solutionModal.pos.buttonSecondaryText": "More information",
  "solutionModal.pos.description": "Issue your sales tickets in seconds, control all your branches from one place and generate sales and inventory reports in real-time.",
  "solutionModal.pos.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "userNotAllowed.edit.saleTicket": "You need the administrator to give you permission to edit tickets.",
}

export default dictionary;