function CashOut ({id, className}) {
  return (
    <svg 
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none"
    >
      <path d="M15.7214 4.63388V10.625C15.7214 10.9702 16.0013 11.25 16.3464 11.25C16.6916 11.25 16.9714 10.9702 16.9714 10.625V4.63388L18.4045 6.06694C18.6486 6.31102 19.0443 6.31102 19.2884 6.06694C19.5325 5.82286 19.5325 5.42714 19.2884 5.18306L16.7884 2.68306C16.7285 2.62314 16.6594 2.57792 16.5857 2.54742C16.512 2.51687 16.4312 2.5 16.3464 2.5C16.2617 2.5 16.1809 2.51687 16.1072 2.54742C16.0335 2.57792 15.9644 2.62314 15.9045 2.68306L13.4045 5.18306C13.1604 5.42714 13.1604 5.82286 13.4045 6.06694C13.6486 6.31102 14.0443 6.31102 14.2884 6.06694L15.7214 4.63388Z" fill="#334155"/>
      <path d="M3.00944 6.24825C2.43414 6.24825 1.96777 6.71461 1.96777 7.28991V13.9566C1.96777 14.5319 2.43414 14.9982 3.00944 14.9982H14.6761C15.2514 14.9982 15.7178 14.5319 15.7178 13.9566V13.5645C15.7178 13.2193 15.9976 12.9395 16.3428 12.9395C16.688 12.9395 16.9678 13.2193 16.9678 13.5645V13.9566C16.9678 15.2222 15.9418 16.2482 14.6761 16.2482H3.00944C1.74379 16.2482 0.717773 15.2222 0.717773 13.9566V7.28991C0.717773 6.02426 1.74379 4.99825 3.00944 4.99825H11.0288C11.374 4.99825 11.6538 5.27807 11.6538 5.62325C11.6538 5.96842 11.374 6.24825 11.0288 6.24825H3.00944Z" fill="#334155"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.71777 10.6232C5.71777 8.89736 7.11688 7.49825 8.84277 7.49825C10.5687 7.49825 11.9678 8.89736 11.9678 10.6232C11.9678 12.3491 10.5687 13.7482 8.84277 13.7482C7.11688 13.7482 5.71777 12.3491 5.71777 10.6232ZM8.84277 8.74825C7.80724 8.74825 6.96777 9.58771 6.96777 10.6232C6.96777 11.6588 7.80724 12.4982 8.84277 12.4982C9.87831 12.4982 10.7178 11.6588 10.7178 10.6232C10.7178 9.58771 9.87831 8.74825 8.84277 8.74825Z" fill="#334155"/>
      <path d="M3.84277 9.99825C3.4976 9.99825 3.21777 10.2781 3.21777 10.6232C3.21777 10.9684 3.4976 11.2482 3.84277 11.2482H3.85111C4.19629 11.2482 4.47611 10.9684 4.47611 10.6232C4.47611 10.2781 4.19629 9.99825 3.85111 9.99825H3.84277Z" fill="#334155"/>
    </svg>
  )
}

export default CashOut