import { I18n } from '@aws-amplify/core';
import { get, capitalize, isArray, find, isEmpty, replace, trim } from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';

import { toast } from '../../../utils';

import { referenceTypesTemporal } from './RapidData';
import getNoTax from '../../countriesData/general/noTax';

export const oldValidate = (values, { country, customFields }) => {
  let errors = {};

  const name = get(values, 'name', '');
  const price = get(values, 'price', '');
  const salePrice = get(values, 'salePrice', '');
  const isInventariable = get(values, 'inventariable', false);

  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));
  if (!price)
    errors.price = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  if (!salePrice)
    errors.salePrice = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (isInventariable) {
    if (!get(values, 'unit.key', null))
      errors.unit = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
    if (!get(values, 'unitCost', null))
      errors.unitCost = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (isInventariable && !get(values, 'warehouses.length')) {
    if (!get(values, 'initialQuantity', null))
      errors.initialQuantity = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (country === 'costaRica') {
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length < 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 12.';
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length > 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 12.';
  }

  if (country === 'mexico') {
    if (!get(values, 'productKey', null))
      errors.productKey = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  customFields.map((field) => {
    const value = get(values, `customFields.cf${get(field, 'id')}`);
    const isBoolean = get(field, 'type') === 'boolean';
    const isRequired = !!get(field, 'settings.isRequired', null);
    if (!!isRequired && !isBoolean && !value) {
      errors = {
        ...errors,
        customFields: {
          ...errors.customFields,
          [`cf${get(field, 'id')}`]: capitalize(
            I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          ),
        },
      };
    }
    return null;
  });

  return errors;
};

export const validate = (
  values,
  { country, customFields, regime, isElectronic }
) => {
  let errors = {};

  const name = get(values, 'name', '');
  const price = get(values, 'price', '');
  const salePrice = get(values, 'salePrice', '');
  const type = get(values, 'type', '');
  const hasVariants = get(values, 'hasVariants', false);

  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));
  if (!price)
    errors.price = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  if (!salePrice)
    errors.salePrice = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (type === 'kit') {
    if (!get(values, 'kitWarehouse.id', null))
      errors.kitWarehouse = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (type === 'product') {
    if (!get(values, 'unit.key', null))
      errors.unit = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
    if (!get(values, 'unitCost', null))
      errors.unitCost = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  // if (type === 'product' && !get(values, 'warehouses.length')) {
  // if (!get(values, 'initialQuantity', null))
  // errors.initialQuantity = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));
  // }

  if (country === 'costaRica') {
    if (!!get(values, 'reference') && get(values, 'reference.length') > 20)
      errors.reference = capitalize(
        replace(
          I18n.get('maxCharactersExceeded', 'Máximo {} caracteres'),
          '{}',
          20
        )
      );

    if (isElectronic && !get(values, 'productKey.key', null))
      errors.productKey = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );

    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length < 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 12.';
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length > 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 12.';
  }

  if (country === 'colombia') {
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length < 10
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 10.';
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length > 10
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 10.';
  }

  if (country === 'mexico') {
    if (isElectronic && !get(values, 'productKey.key', null))
      errors.productKey = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (country === 'republicaDominicana') {
    if (
      !!get(values, 'reference') &&
      get(values, 'reference.length') > 35 &&
      isElectronic
    )
      errors.reference = capitalize(
        replace(
          I18n.get('maxCharactersExceeded', 'Máximo {} caracteres'),
          '{}',
          35
        )
      );

    const DOMINICAN_NAME_MAX_LENGTH = 80;
    if (name?.length > DOMINICAN_NAME_MAX_LENGTH) {
      errors.name = capitalize(
        I18n.get(
          'maxLengthError',
          `Máximo ${DOMINICAN_NAME_MAX_LENGTH} caracteres`
        )?.replace('{{maxCharacters}}', DOMINICAN_NAME_MAX_LENGTH)
      );
    }
  }

  if (country === 'peru') {
    if (
      !!get(values, 'reference') &&
      get(values, 'reference.length') > 100 &&
      isElectronic
    )
      errors.reference = capitalize(
        replace(
          I18n.get('maxCharactersExceeded', 'Máximo {} caracteres'),
          '{}',
          100
        )
      );
  }

  customFields.map((field) => {
    const value = get(values, `customFields.cf${get(field, 'id')}`);
    const isBoolean = get(field, 'type') === 'boolean';
    if (!isBoolean && !value) {
      errors = {
        ...errors,
        customFields: {
          ...errors.customFields,
          [`cf${get(field, 'id')}`]: capitalize(
            I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          ),
        },
      };
    }
    return null;
  });

  if (hasVariants) {
    const itemVariants = get(values, 'itemVariants', []);
    let hasItemVariantsErrors = false;
    let itemVariantsErrors = [];
    if (get(itemVariants, 'length')) {
      itemVariantsErrors = itemVariants.map((variant) => {
        if (
          !get(variant, 'inventory.warehouses.length', false) &&
          !get(variant, 'inventory.singleWarehouse', false)
        ) {
          hasItemVariantsErrors = true;
          return capitalize(
            I18n.get(
              'indicateTheVariantQuantity',
              'Indica aquí las cantidades en tus bodegas'
            )
          );
        }
        return null;
      });
    }

    if (hasItemVariantsErrors) {
      errors = {
        ...errors,
        itemVariants: itemVariantsErrors,
      };
    }
  }

  return errors;
};

export const validateOnSubmit = (values) => {
  const type = get(values, 'type', '');
  const hasVariants = get(values, 'hasVariants', false);

  if (type === 'product' && hasVariants) {
    const itemVariants = get(values, 'itemVariants', []);
    if (!get(itemVariants, 'length')) {
      toast.error({
        title: I18n.get(
          'createItemSubmitError1',
          'Configura la sección de variantes.'
        ),
        subtitle: I18n.get(
          'createItemSubmitError1Message',
          'Desde la sección variantes debes seleccionar por lo menos una opción e indicar la cantidad disponible en bodega de las que elijas.'
        ),
      });

      return true;
    }
  }

  if (type === 'kit') {
    let kitItems = !!get(values, 'items.length') ? get(values, 'items') : [];

    kitItems = kitItems.filter((item) => get(item, 'item.id'));

    if (!get(kitItems, 'length')) {
      toast.error({
        title: I18n.get(
          'createItemSubmitError2',
          'Agrega los productos que integran tu combo.'
        ),
        subtitle: I18n.get(
          'createItemSubmitError2Message',
          'Podrás asignar la cantidad de cada uno para empezar a venderlo y tener el control de las unidades disponibles e ingresos.'
        ),
      });

      return true;
    }
  }

  return false;
};

const dynamicFieldTransform = (value, type) => {
  switch (type) {
    case 'int':
      return !!+value ? +value : null;
    case 'decimal':
      return !!+value ? +value : null;
    case 'boolean':
      return !!value;
    case 'date':
      return dayjs(value).format('YYYY-MM-DD');
    case 'optionsList':
      return get(value, 'value');
    default:
      return value;
  }
};

const transformItemVariantsWarehouse = (values, negativeSale) => {
  if (!values) {
    return null;
  }
  const newItemVariants = values.map((wh) => {
    if (get(wh, 'inventory.warehouses.length') > 1) {
      return {
        ...wh,
      };
    } else {
      return {
        ...wh,
        inventory: {
          ...get(wh, 'inventory'),
          warehouses: [
            {
              id: get(wh, 'inventory.singleWarehouse.id'),
              initialQuantity: get(
                wh,
                'inventory.singleWarehouseInitialQuantity'
              ),
              maxQuantity: get(wh, 'inventory.warehouses.0.maxQuantity'),
              minQuantity: get(wh, 'inventory.warehouses.0.minQuantity'),
              negativeSale,
            },
          ],
        },
      };
    }
  });
  return newItemVariants;
};

// const oldTransformer = (values, { country, decimal, allCustomFields }) => {
//   let taxes = !!get(values, 'tax', null)
//     ? get(values, 'tax') : [];
//   taxes = !isArray(taxes) ? [taxes] : taxes;

//   let countryHasTaxCondition = country === 'mexico';
//   let taxCondition = countryHasTaxCondition
//     ? get(taxes, '[0].customTax', false)
//       ? { value: get(taxes, '[0].id') }
//       : { value: "02" }
//     : null;

//   const price = get(values, 'salePrice', '0');

//   const distributed = !!get(values, 'inventariable', false) && !!get(values, 'warehouses.length')

//   return {
//     name: get(values, 'name', ''),
//     image: get(values, 'imageSrc', null),
//     type: country === 'republicaDominicana' ? 'product' : 'simple',
//     tax: countryHasTaxCondition
//       ? taxCondition && get(taxCondition, 'value') === "02"
//         ? (!!isArray(taxes) && taxes.length > 0)
//           ? taxes.map(tax => tax.id)
//           : null
//         : []
//       : (!!isArray(taxes) && taxes.length > 0)
//         ? taxes.map(tax => tax.id)
//         : null,
//     reference: country === 'costaRica'
//       ? (!!get(values, 'reference', null)
//         ? { reference: get(values, 'reference', null), type: get(values, 'referenceType.value', null) }
//         : null
//       )
//       : get(values, 'reference', null),
//     productKey: get(values, 'productKey.key', null),
//     itemCategory: get(values, 'category.id', null),
//     tariffHeading: get(values, 'tariffHeading', null),
//     customFields: allCustomFields.map(field => ({
//       id: get(field, 'id'),
//       value: dynamicFieldTransform(get(values, `customFields.cf${get(field, 'id')}`), get(field, 'type'))
//     })),
//     description: get(values, 'description', ''),
//     inventory: !!get(values, 'inventariable', false)
//       ? distributed
//         ? {
//           unit: get(values, 'unit.key'),
//           unitCost: get(values, 'unitCost'),
//           warehouses: values.warehouses.map(warehouse => ({
//             id: get(warehouse, 'warehouse.id'),
//             initialQuantity: get(warehouse, 'initialQuantity'),
//             minQuantity: get(warehouse, 'minQuantity'),
//             maxQuantity: get(warehouse, 'maxQuantity'),
//           }))
//         }
//         : {
//           unit: get(values, 'unit.key'),
//           unitCost: get(values, 'unitCost'),
//           initialQuantity: get(values, 'initialQuantity'),
//           minQuantity: get(values, 'minQuantity'),
//           maxQuantity: get(values, 'maxQuantity'),
//         }
//       : country === 'mexico' || country === 'peru' || country === 'costaRica'
//         || country === 'argentina' || country === 'colombia'
//         ? { unit: 'service' }
//         : null,
//     price: [{ idPriceList: '1', price }]
//       .concat(!!get(values, 'priceLists.length', false)
//         ? values.priceLists
//           .filter(priceList => !!get(priceList, 'priceList.id', null))
//           .map(priceList => ({
//             idPriceList: get(priceList, 'priceList.id'),
//             price: get(priceList, 'priceList.type') === 'percentage'
//               ? new BigNumber(100)
//                 .minus(new BigNumber(get(priceList, 'priceList.percentage')))
//                 .dividedBy(100).multipliedBy(new BigNumber(price))
//                 .decimalPlaces(decimal).toNumber()
//               : get(priceList, 'price', '0'),
//           }))
//         : []
//       ),
//     category: get(values, 'accountingAccount.id', null),
//     taxCondition,
//   }
// }

export const transform = (
  values,
  { country, decimal, allCustomFields, categories }
) => {
  let taxes = !!get(values, 'tax', null) ? get(values, 'tax') : [];
  taxes = !isArray(taxes) ? [taxes] : taxes;

  taxes = taxes.filter((tax) => get(tax, 'id') !== -1);

  let countryHasTaxCondition = country === 'mexico';
  let taxCondition = countryHasTaxCondition
    ? get(taxes, '[0].customTax', false)
      ? { value: get(taxes, '[0].id') }
      : { value: '02' }
    : null;

  const price = get(values, 'salePrice', '0');

  const usedCustomFields = allCustomFields.filter((customField) =>
    JSON.stringify(get(values, `customFields.cf${get(customField, 'id')}`))
  );

  const type = get(
    values,
    'type',
    country === 'republicaDominicana' ? 'product' : 'simple'
  );

  const distributed =
    !!get(values, 'inventariable', false) && !!get(values, 'warehouses.length');

  // const itemType = type !== 'kit'
  //   ? get(values, 'hasVariants', false)
  //     ? 'variantParent'
  //     : 'simple'
  //   : 'kit'

  const itemType =
    type !== 'kit'
      ? get(values, 'hasVariants', false)
        ? 'variantParent'
        : type
      : 'kit';

  const subitems = !!get(values, 'items.length')
    ? values.items.filter((item) => get(item, 'item.id'))
    : [];

  return {
    name: get(values, 'name', ''),
    image: get(values, 'imageSrc', null),
    type: itemType,
    tax: countryHasTaxCondition
      ? taxCondition && get(taxCondition, 'value') === '02'
        ? !!isArray(taxes) && taxes.length > 0
          ? taxes.map((tax) => tax.id)
          : null
        : []
      : !!isArray(taxes) && taxes.length > 0
      ? taxes.map((tax) => tax.id)
      : null,
    reference:
      country === 'costaRica'
        ? !!get(values, 'reference', null)
          ? {
              reference: trim(get(values, 'reference', null)),
              type: get(values, 'referenceType.code', null),
            }
          : null
        : trim(get(values, 'reference', null)),
    productKey: get(values, 'productKey.key', null),
    itemCategory: get(values, 'category.id', null),
    tariffHeading: get(values, 'tariffHeading', null),
    brand: get(values, 'brand', null),
    model: get(values, 'model', null),
    customFields: usedCustomFields.map((field) => ({
      id: get(field, 'id'),
      name: get(field, 'name'),
      type: get(field, 'type'),
      value: dynamicFieldTransform(
        get(values, `customFields.cf${get(field, 'id')}`),
        get(field, 'type')
      ),
    })),
    description: get(values, 'description', ''),
    inventory:
      type === 'product' || type === 'simple'
        ? distributed || itemType !== 'variantParent'
          ? {
              unit: get(values, 'unit.key'),
              unitCost: get(values, 'unitCost'),
              negativeSale: get(values, 'negativeSale', false),
              warehouses: values.warehouses.map((warehouse) => ({
                id: get(warehouse, 'warehouse.id'),
                initialQuantity: get(warehouse, 'initialQuantity'),
                minQuantity: get(warehouse, 'minQuantity'),
                maxQuantity: get(warehouse, 'maxQuantity'),
                negativeSale: get(values, 'negativeSale'),
              })),
            }
          : {
              unit: get(values, 'unit.key'),
              unitCost: get(values, 'unitCost'),
              negativeSale: get(values, 'negativeSale', false),
              initialQuantity: get(values, 'initialQuantity'),
            }
        : country === 'mexico' ||
          country === 'peru' ||
          country === 'costaRica' ||
          country === 'argentina' ||
          country === 'colombia'
        ? { unit: get(values, 'unit.key', 'service') }
        : null,
    price: [{ idPriceList: '1', price }]
      .concat(
        !!get(values, 'priceLists.length', false)
          ? values.priceLists
              .filter(
                (priceList) =>
                  get(priceList, 'priceList.idPriceList', null) &&
                  get(priceList, 'priceList.idPriceList', null) !== 1
              )
              .map((priceList) => ({
                idPriceList: get(priceList, 'priceList.idPriceList'),
                price: get(priceList, 'price'),
              }))
          : []
      )
      .concat(
        !!get(values, 'priceLists.length', false)
          ? values.priceLists
              .filter((priceList) => !!get(priceList, 'priceList.id', null))
              .map((priceList) => ({
                idPriceList: get(priceList, 'priceList.id'),
                price:
                  get(priceList, 'priceList.type') === 'percentage'
                    ? new BigNumber(100)
                        .minus(
                          new BigNumber(get(priceList, 'priceList.percentage'))
                        )
                        .dividedBy(100)
                        .multipliedBy(new BigNumber(price))
                        .decimalPlaces(decimal)
                        .toNumber()
                    : get(priceList, 'price', '0'),
              }))
          : []
      ),
    category: get(values, 'accountingAccount.id', null),
    kitWarehouse:
      itemType === 'kit' ? get(values, 'kitWarehouse.id', null) : null,
    accounting: {
      inventory: get(categories, 'inventoryCategory', null),
      inventoryPurchase: get(categories, 'inventoryPurchaseCategory', null),
    },
    subitems: itemType === 'kit' && !!subitems.length ? subitems : null,
    variantAttributes:
      itemType === 'variantParent'
        ? get(values, 'variantAttributes', null)
        : null,
    itemVariants:
      itemType === 'variantParent'
        ? // ? get(values, 'itemVariants', null)
          transformItemVariantsWarehouse(
            get(values, 'itemVariants', null),
            get(values, 'negativeSale')
          )
        : null,
    taxCondition,
    predial: country === 'mexico' ? get(values, 'predial', null) : null,
    unit: get(values, 'unit.key'),
  };
};

// export const transform = (values, { country, decimal, allCustomFields, categories }) => {
//   if (coutriesUpdate.includes(country)) {
//     return newTransformer(values, { country, decimal, allCustomFields, categories })
//   } else {
//     return oldTransformer(values, { country, decimal, allCustomFields })
//   }
// }

export const transformFromAPI = (
  item,
  { unitOptions, decimal, warehouses, language }
) => {
  const type = get(item, 'type', '');
  const firstPrice = get(item, 'price[0].price');
  const referenceType = !!get(item, 'reference.type', null)
    ? referenceTypesTemporal.find(
        (referenceType) =>
          get(referenceType, 'key') === get(item, 'reference.type')
      )
    : null;

  let firstTax = parseInt(get(item, 'tax[0].percentage', 0)) / 100;
  firstTax = firstTax === 0 ? 1 : firstTax + 1;

  let tax = isEmpty(get(item, 'tax'))
    ? [getNoTax(language.substring(0, 2))]
    : get(item, 'tax');

  return {
    ...item,
    accountingAccount: get(item, 'category', {}),
    customFields: get(item, 'customFields', []).reduce((prev, current) => {
      if (get(current, 'type') === 'optionsList')
        return {
          ...prev,
          ['cf' + current.id]: { value: current.value, label: current.value },
        };
      else
        return {
          ...prev,
          ['cf' + current.id]: current.value,
        };
    }, {}),
    priceLists: get(item, 'price', []).map((pL) => ({
      price: new BigNumber(pL.price).toFixed(decimal),
      priceList: pL,
    })),
    type,
    warehouses: get(item, 'inventory.warehouses', []).map((w) => {
      let warehouse = find(warehouses, { id: get(w, 'id') });
      return {
        initialQuantity: parseInt(get(w, 'initialQuantity')),
        minQuantity: get(w, 'minQuantity'),
        maxQuantity: get(w, 'maxQuantity'),
        warehouse,
      };
    }),
    hasVariants: type === 'variantParent',
    name: get(item, 'name', ''),
    unit: find(unitOptions, { key: get(item, 'inventory.unit') }),
    unitCost:
      type === 'variantParent'
        ? new BigNumber(
            get(item, 'itemVariants[0].inventory.unitCost')
          ).toFixed(decimal)
        : type === 'product' || type === 'simple'
        ? new BigNumber(get(item, 'inventory.unitCost')).toFixed(decimal)
        : 0,
    salePrice: new BigNumber(firstPrice).toFixed(decimal),
    price: new BigNumber(firstPrice * firstTax).toFixed(decimal),
    tax: tax,
    description: get(item, 'description', ''),
    negativeSale: !!get(item, 'inventory.negativeSale'),
    reference: !!get(item, 'reference.reference', null)
      ? get(item, 'reference.reference', '')
      : get(item, 'reference', ''),
    referenceType: referenceType,
    productKey: !!get(item, 'productKey')
      ? {
          key: get(item, 'productKey'),
          name: get(item, 'productKey'),
        }
      : null,
    variantAttributes: get(item, 'variantAttributes', []),
    itemVariants: get(item, 'itemVariants', []).map((variant) => ({
      ...variant,
      inventory: {
        ...get(variant, 'inventory'),
        singleWarehouse: get(variant, 'inventory.warehouses[0]'),
        singleWarehouseInitialQuantity: get(
          variant,
          'inventory.warehouses[0].initialQuantity'
        ),
      },
      status: {
        value: get(variant, 'status'),
        label: `${get(variant, 'status')}Products`,
      },
      image: get(variant, 'images[0].url', ''),
    })),
    items: get(item, 'subitems', []).map((i) => ({
      item: i.item,
      averageCost: new BigNumber(
        get(i, 'item.inventory.averageCost', 0) * Number(i.quantity)
      ),
      quantity: Number(i.quantity),
    })),
    imageSrc:
      !!get(item, 'images.length') &&
      get(item, `images.${get(item, 'images.length', 1) - 1}.url`, ''),
    category: get(item, 'itemCategory'),
    isEditing: true,
  };
};

export const transformVariants = (values, { customFields }) => {
  return {
    // image: get(values, 'image', null),
    customFields: customFields.map((field) => ({
      id: get(field, 'id'),
      value: dynamicFieldTransform(
        get(
          values,
          `customFields.cf${get(field, 'id')}`,
          get(values, `customFields[0].value`)
        ),
        get(field, 'type')
      ),
    })),
    inventory: {
      unit: get(values, 'unit.key'),
      unitCost: get(values, 'unitCost'),
      singleWarehouseInitialQuantity: get(
        values,
        'warehouses.0.initialQuantity'
      ),
      singleWarehouse: get(values, 'warehouses.0.warehouse'),
      warehouses: values.warehouses.map((warehouse) => ({
        id: get(warehouse, 'warehouse.id'),
        initialQuantity: get(warehouse, 'initialQuantity'),
        minQuantity: get(warehouse, 'minQuantity'),
        maxQuantity: get(warehouse, 'maxQuantity'),
        negativeSale: get(warehouse, 'negativeSale'),
      })),
    },
  };
};

export const initialValuesFromModal = ({
  customFields,
  type,
  initValues,
  mainCategory,
  mainWarehouse,
}) => {
  let initialValues = {
    customFields: {},
  };

  customFields.map((field) => {
    if (get(field, 'type') === 'optionsList') {
      initialValues.customFields[`cf${get(field, 'id')}`] = {
        value: get(field, 'defaultValue', null),
        label: get(field, 'defaultValue', null),
      };
    } else if (get(field, 'type') === 'boolean')
      initialValues.customFields[`cf${get(field, 'id')}`] =
        get(field, 'defaultValue', null) === '1';
    else
      initialValues.customFields[`cf${get(field, 'id')}`] = get(
        field,
        'defaultValue',
        null
      );

    return null;
  });

  if (initialValues) {
    initialValues = {
      ...initialValues,
      ...initValues,
      warehouses: [
        {
          warehouse: initValues.warehouse,
          initialQuantity: initValues.initialQuantity,
          unitCost: initValues.unitCost,
        },
      ],
      items: [{}, {}, {}],
      accountingAccount: mainCategory,
      kitWarehouse: mainWarehouse,
    };
  }

  initialValues['type'] = !!type ? type : 'product';

  initialValues = {
    ...initialValues,
    type: !!type ? type : 'product',
    hasVariants: false,
    priceLists: [{}],
  };

  return initialValues;
};
