// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.9419 8.77527C13.186 8.5312 13.186 8.13547 12.9419 7.89139C12.6979 7.64731 12.3021 7.64731 12.0581 7.89139L9.16667 10.7828L7.94194 9.55806C7.69786 9.31398 7.30214 9.31398 7.05806 9.55806C6.81398 9.80214 6.81398 10.1979 7.05806 10.4419L8.72473 12.1086C8.9688 12.3527 9.36453 12.3527 9.60861 12.1086L12.9419 8.77527Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20304 16.875 10C16.875 13.797 13.797 16.875 10 16.875C6.20304 16.875 3.125 13.797 3.125 10Z'
    />
  </svg>
);
