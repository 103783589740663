import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { graphqlOperation } from '@aws-amplify/api';
import { I18n } from 'aws-amplify';
import { Form } from 'react-final-form';
import { get, isEmpty } from 'lodash';

import { stations as stationsSelector } from '../../../selectors/station';
import { APIGraphqlSelector, station as stationSelector } from '../../../selectors/app'
import { country as countrySelector } from '../../../selectors/company';
import { getElectronicNumerations } from '../../../selectors/numerations';
import { updateStation } from '../../../reducers/app'
import { openModal, closeModal } from '../../../reducers/modals';

import * as mutations from '../../../graphql/mutations';
import { transform } from '../../forms/stationSetting5UVT/utils'
import { formError } from '../../../utils/errors';
import { stationTransformer } from '../../../utils/transformers';
import { toast, replaceAndParse } from '../../../utils';
import Body from '../../forms/stationSetting5UVT';
import Notification from '../common/Notification';
import Tooltip from '../../common/Tooltip';
import Modal from '../../common/Modal';
import Loading from '../../svg/Loading';

import image5UVTModal from '../../../assets/images/image_5UVT_activate_modal.png'
import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';

const ReconfigureNumeration5UVT = ({ isOpenStationSetting5UVT, activate5uvt }) => {
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const colombiaUVT = getColombiaUVT();
  const stations = useSelector(stationsSelector)
  const isOpen = useSelector(state => get(state, 'modals.reconfigureNumeration5UVT.isOpen', false));
  const numerations = useSelector(getElectronicNumerations)
  const currentStation = useSelector(stationSelector)
  const country = useSelector(countrySelector)
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch()

  useEffect(() => {
    if (country !== 'colombia') return
    if (!!numerations.length && !numerations.some(n => Number(n.id) === Number(get(currentStation, 'idFENumberTemplate'))))
      dispatch(openModal({ modal: 'reconfigureNumeration5UVT' }));
  }, [numerations, country, currentStation, dispatch])

  useEffect(() => {
    let _initialValues = {};
    if (numerations.length > 0)
      stations.forEach(s => {
        const selectedNumeration = numerations.some(n => Number(n.id) === Number(get(s, 'idFENumberTemplate')))
          ? numerations.find(n => Number(n.id) === Number(get(s, 'idFENumberTemplate')))
          : numerations[0];
        _initialValues['k' + s.id] = { id: selectedNumeration.id, name: selectedNumeration.name };
      });
    setInitialValues(_initialValues);
  }, [stations, numerations])

  const close = (hasFinishedProcess = true) => {
    dispatch(closeModal({ modal: 'reconfigureNumeration5UVT' }))
    if (!hasFinishedProcess)
      dispatch(openModal({ modal: 'deactivate5UVT', params: { isWarningAlert: true } }))
  }

  const submit = async values => {
    try {
      setLoading(true)
      if (isOpenStationSetting5UVT)
        await activate5uvt();

      for (const s of stations) {
        const transformedValues = transform(values, 'k' + s.id)
        const response = await APIGraphql(graphqlOperation(mutations.updateStation, {
          station: stationTransformer({ ...s, ...transformedValues })
        }))

        const station = get(response, 'data.updateStation', null);
        if (currentStation.id === station.id)
          dispatch(updateStation(station))
      }

      setLoading(false)
      close();

      if (isOpenStationSetting5UVT)
        toast.success({
          title: `${I18n.get('5uvtActivatedSuccessfully2', '¡Todo Listo!  ✨')}`,
          subtitle: replaceAndParse(I18n.get('5uvtActivatedSuccessfullyMessage2', `Ya está configurada la numeración de facturas electrónicas para ventas que superen $${colombiaUVT?.UVT_FORMATED} sin IVA.`), [])
        })
      else
        toast.success({
          title: `${I18n.get('5uvtActivatedSuccessfully', '¡Ya está activado! 🎯')}`,
          subtitle: replaceAndParse(I18n.get('5uvtActivatedSuccessfullyMessage', `Se creará una factura electrónica cuando la venta en documento POS <strong>supere los $${colombiaUVT?.UVT_FORMATED}</strong> antes de IVA. `), [])
        })
    } catch (error) {
      setLoading(false)
      return formError(error, I18n.get('updateStationsError', 'hubo un error en la actualización de las terminales'))
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeAction={() => close(false)}
        headerImg={image5UVTModal}
        className={"modal__activate-5uvt"}>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
        >
          {({ handleSubmit, values, form, error, submitting, submitError, submitFailed, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="d-flex flex-column justify-content-center align-items-center form-body modal__item-body ">
                <div className="mb-5 mt-4 px-5">
                  <p className="h3 mb-3 text-capitalize-first font-weight-bold">
                    {I18n.get('5uvt.reconfigure-numeration.title', 'Configura una nueva numeración ✍')}
                  </p>
                  <p className="h4 uvt-description text-capitalize-first text-subtitle">
                    {replaceAndParse(I18n.get('5uvt.reconfigure-numeration.description', `Desactivaste la numeración asociada al cambio de documento POS por <strong>factura electrónica desde $${colombiaUVT?.UVT_FORMATED}</strong> (5 UVT) y debes configurar una nueva.`), [])}
                  </p>

                  <Body type={values.type} values={values} form={form} />
                </div>
              </div>


              {(!!error && !!submitFailed) && (
                <Notification isOpen={true} text={error} type="error" />
              )}

              {(!!submitError && !!submitFailed) && (
                <Notification isOpen={true} text={submitError} type="error" />
              )}

              <div className="modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow">
                <div className="required-message mb-3 mb-sm-0">
                  <i className="text-primary mr-2">* </i>
                  <span className="text-capitalize-first text-muted">{I18n.get("requiredFields", "Campos obligatorios")}</span>
                </div>
                <div className="modal__bottom-buttons d-flex">
                  <Tooltip
                    tooltipActive={isEmpty(values) || !isEmpty(errors)}
                    overlay={replaceAndParse(I18n.get("noElectronicNumeration", "Debes {} para configurarla en tu terminal actual."), [
                      `<a href="https://ayuda.alegra.com/es/pasos-habilitacion-facturador-electronico-dian-colombia#paso-5" target="_blank" rel="noreferrer">
                       ${I18n.get('noElectronicNumeration1', 'crear una numeración electrónica')}
                    </a>`
                    ])}
                    placement="top"
                  >
                    <button
                      type="submit"
                      data-testid="modal-submit-btn"
                      disabled={!!submitting || loading || isEmpty(values) || !isEmpty(errors)}
                      className="btn btn-submit text-capitalize-first"
                    >
                      {submitting || loading
                        ? <Loading className="icon-primary" />
                        : I18n.get('finishProcess', 'Terminar proceso')
                      }
                    </button>
                  </Tooltip>
                </div>
              </div>
            </form>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default ReconfigureNumeration5UVT