import { randFullName, randProductName, randFloat, randNumber, randProductDescription, randEmail, randPhoneNumber } from '@ngneat/falso';

import { calculateItemsValues, countryByVersion } from '../../../utils'

const [clientName, clientLastName] = randFullName({ withAccents: false }).split(" ");

const items = [
  {
    name: randProductName(),
    price: randFloat({ min: 99, max: 2000, fraction: 2 }).toString(),
    description: randProductDescription(),
    quantity: randNumber({ min: 1, max: 10 }),
    tax: [
      { id: '1', name: 'IVA', type: 'IVA', percentage: '5.00', status: "active" },
      { id: '2', name: 'IVA', type: 'IVA', percentage: '7.00', status: "active" },
    ],
    unit: 'Unidad',
    productKey: '147415',
  },
  {
    name: randProductName(),
    price: randFloat({ min: 99, max: 2000, fraction: 2 }).toString(),
    description: randProductDescription(),
    quantity: randNumber({ min: 1, max: 10 }),
    discount: randNumber({ max: 20 }),
    tax: [
      { id: '3', name: 'IVA', type: 'IVA', percentage: '12.00', status: "active" },
    ],
    unit: 'Unidad',
    productKey: '100147',
  },
  {
    name: randProductName(),
    price: randFloat({ min: 99, max: 2000, fraction: 2 }).toString(),
    description: randFloat({ min: 99, max: 2000, fraction: 2 }).toString(),
    quantity: randNumber({ min: 1, max: 10 }),
    tax: [
      { id: '2', name: 'IVA', type: 'IVA', percentage: '7.00', status: "active" },
    ]
  }
]

const itemsValues = calculateItemsValues(items, 4);

const number = randNumber()
const barCodeContent = `NumFac: ${number}\nFecFac: 20210604000000\nHorFac: 00:00:00-00:00\nNitFac: 123456789\nDocAdq: 222222222222\nValFac: 666.00\nValIva: 0.00\nValOtroIm: 0.00\nValTolFac: 666.00\n`

const invoice = ({
  isElectronic,
  termsConditions,
  companyAnotation,
  numberTemplate,
  country
}) => ({
  items,
  client: {
    name: country === 'mexico' ? 'Público en general' : `${clientName} ${clientLastName}`,
    identification: country === 'mexico' ? 'XAXX010101000' : Date.now().toString(),
    email: randEmail({ firstName: clientName, lastName: clientLastName }),
    phonePrimary: country === 'mexico' ? null : randPhoneNumber({ countryCode: countryByVersion(country).alpha_2 })
  },
  payments: [

  ],
  seller: {
    name: randFullName({ withAccents: false })
  },
  numberTemplate: {
    ...numberTemplate,
    number,
    branchOfficeCode: '001',
  },
  totalReceived: itemsValues.total.toNumber(),
  termsConditions,
  anotation: companyAnotation,
  stamp: isElectronic ? {
    barCodeContent
  } : null,
})

export default invoice;