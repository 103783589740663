const baseIdTypes = [
  {
    id: '13',
    value: 'Cédula de ciudadanía',
    abbreviature: 'CC',
    key: 'CC',
    rules: {
      type: 'int',
    },
  },
  {
    id: '31',
    value: 'Número de identificación tributaria',
    abbreviature: 'NIT',
    key: 'NIT',
    rules: {
      type: 'int',
    },
  },
  {
    id: '41',
    value: 'Pasaporte',
    abbreviature: 'PP',
    key: 'PP',
  },
  {
    id: '22',
    value: 'Cédula de extranjería',
    abbreviature: 'CE',
    key: 'CE',
  },
  {
    id: '47',
    value: 'Permiso especial de permanencia',
    abbreviature: 'PEP',
    key: 'PEP',
  },
  {
    id: '21',
    value: 'Tarjeta de extranjería',
    abbreviature: 'TE',
    key: 'TE',
  },
  {
    id: '12',
    value: 'Tarjeta de identidad',
    abbreviature: 'TI',
    key: 'TI',
    rules: {
      type: 'int',
    },
  },
  {
    id: '11',
    value: 'Registro civil',
    abbreviature: 'RC',
    key: 'RC',
  },
  {
    id: '42',
    value: 'Documento de identificación extranjero',
    abbreviature: 'DIE',
    key: 'DIE',
  },
  {
    id: '50',
    value: 'NIT de otro país',
    abbreviature: 'NIT de otro país',
    key: 'FOREIGN_NIT',
  },
  {
    id: '91',
    value: 'NUIP',
    abbreviature: 'NUIP',
    key: 'NUIP',
  },
];

export const idTypes = (isElectronic) => baseIdTypes;
