// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.05546 1.05546C5.57118 0.539731 6.27065 0.25 7 0.25H13C13.7293 0.25 14.4288 0.539731 14.9445 1.05546C15.4603 1.57118 15.75 2.27065 15.75 3V6.25H17C17.7293 6.25 18.4288 6.53973 18.9445 7.05546C19.4603 7.57118 19.75 8.27065 19.75 9V13C19.75 13.7293 19.4603 14.4288 18.9445 14.9445C18.4288 15.4603 17.7293 15.75 17 15.75H15.75V17C15.75 18.5188 14.5188 19.75 13 19.75H7C5.48122 19.75 4.25 18.5188 4.25 17V15.75H3C2.27065 15.75 1.57118 15.4603 1.05546 14.9445C0.539731 14.4288 0.25 13.7293 0.25 13V9C0.25 8.27065 0.539731 7.57118 1.05546 7.05546C1.57118 6.53973 2.27065 6.25 3 6.25H4.25V3C4.25 2.27065 4.53973 1.57118 5.05546 1.05546ZM5.75 17C5.75 17.6904 6.30964 18.25 7 18.25H13C13.6904 18.25 14.25 17.6904 14.25 17V13C14.25 12.3096 13.6904 11.75 13 11.75H7C6.30964 11.75 5.75 12.3096 5.75 13V17ZM15.75 14.25V13C15.75 11.4812 14.5188 10.25 13 10.25H7C5.48122 10.25 4.25 11.4812 4.25 13V14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V9C1.75 8.66848 1.8817 8.35054 2.11612 8.11612C2.35054 7.8817 2.66848 7.75 3 7.75H17C17.3315 7.75 17.6495 7.8817 17.8839 8.11612C18.1183 8.35054 18.25 8.66848 18.25 9V13C18.25 13.3315 18.1183 13.6495 17.8839 13.8839C17.6495 14.1183 17.3315 14.25 17 14.25H15.75ZM14.25 3V6.25H5.75V3C5.75 2.66848 5.8817 2.35054 6.11612 2.11612C6.35054 1.8817 6.66848 1.75 7 1.75H13C13.3315 1.75 13.6495 1.8817 13.8839 2.11612C14.1183 2.35054 14.25 2.66848 14.25 3Z" fill="#94A3B8" />
  </svg>
)
