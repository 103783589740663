import React from 'react'
import { I18n } from '@aws-amplify/core';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js'

import { decimalPrecision, country as countrySelector, isTaxesResponsable as isTaxesResponsableSelector } from '../../../selectors/company'
import { activeNumerations } from '../../../selectors/numerations'
import { calculateItemsValues } from '../../../utils';
import { assingITBISTaxesToLetter, getSpainTaxCode, getSubDocumentType, isAfterDateToProduction } from './utilities'
import { useCheckTaxesSummaryAvailability } from '../../../hooks/featureAvailability/useCheckTaxesSummaryAvailability';

import taxTypeByPercentage from '../../countriesData/republicaDominicana/taxes'
import TaxDetail from './TaxDetail';

const shouldShowDiscount = (country, invoice, numerations) => {
  switch (country) {
    case 'argentina':
      const subDocumentType = getSubDocumentType(invoice, numerations)
      if (subDocumentType === 'INVOICE_B' || subDocumentType === 'INVOICE_C')
        return false
      return true

    default:
      return true
  }
}

const shouldShowTaxes = (country, invoice, numerations) => {
  switch (country) {
    case 'argentina':
      const subDocumentType = getSubDocumentType(invoice, numerations)
      if (subDocumentType === 'INVOICE_B' || subDocumentType === 'INVOICE_C')
        return false
      return true
    case 'republicaDominicana':
      return !get(invoice, 'items', []).some(item => item.tax.some(tax => tax.name.includes('ITBIS') || tax.type.includes('ITBIS')))
    default:
      return true
  }
}

const Payments = ({ invoice, setting: { template, totalLines } }) => {
  let { items, currency, totalReceived, cashReturned } = invoice
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const numerations = useSelector(activeNumerations);
  const isTaxesResponsable = useSelector(isTaxesResponsableSelector);

  const customTaxTypeByPercentage = country === 'republicaDominicana' ? assingITBISTaxesToLetter(items) : {};

  const isTaxesSummaryAvailable = useCheckTaxesSummaryAvailability();

  if (!items) return null;

  const prefix = !!get(currency, 'symbol')
    ? get(currency, 'symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : null;

  const { subtotal, total, taxes, discount, discSubtotal, subTotalByTax, taxesByType } = calculateItemsValues(items, decimal);
  const showDiscount = discount.gt(0) && shouldShowDiscount(country, invoice, numerations)
  const showTaxes = shouldShowTaxes(country, invoice, numerations)
  const showITBISTaxes = country === 'republicaDominicana' && get(invoice, 'items', []).some(item => item.tax.some(tax => tax.name.includes('ITBIS') || tax.type.includes('ITBIS')))

  const isAfterDate = isAfterDateToProduction(invoice, "2023-04-25");
  const isElectronic = get(invoice, "stamp", null);

  return (
    <div className={`w-100 py-2 d-flex flex-column ${template !== 'simple' ? 'border-top border-bottom' : ''}`}>

      <div className={`${(country === "panama" && isAfterDate && isElectronic) ? "" : "text-right"} pb-2`}>
        {(!!showDiscount || !!showTaxes) && (
          <p className={`container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
            <p className='font-weight-bold'>{(country === "panama" && isAfterDate && isElectronic) ? I18n.get('subtotalWithoutTax', 'Subtotal sin impuestos') : country === 'spain' ? I18n.get('taxableIncome', 'Base imponible') : I18n.get('subtotal', 'subtotal')}:</p>
            <p className='pl-2'>
              {subtotal.toFormat(decimal, fmt)}
            </p>
          </p>
        )}

        {
          !!showDiscount &&
          (
            <>
              <p className={`container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
                <p className={country === 'republicaDominicana' ? 'font-weight-bold' : "strong"}>{I18n.get('discount', 'descuento')}</p>
                <p className='pl-2'>
                  {`-${discount.toFormat(decimal, fmt)}`}
                </p>
              </p>
              <p className={`container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
                <p className={country === 'republicaDominicana' ? 'font-weight-bold' : "strong"}>{country === 'spain' ? I18n.get('discountedBasis', 'Base con descuento') :I18n.get('subtotal', 'subtotal')}</p>
                <p className='pl-2'>
                  {discSubtotal.toFormat(decimal, fmt)}
                </p>
              </p>
            </>
          )}

        {!!showTaxes
          ? (country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable)
            ? taxesByType.map((tax, index) => (
              <h4 key={index} className={"h4 container-text-flex"}>
                <p className='font-weight-bold'>
                  {`${I18n.get('total', 'Total')} ${tax.type}`}:
                </p>
                <p className='pl-2'>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              </h4>
            ))
            : taxes.map((tax, index) => (
              country === 'spain' ? (
              <p key={index} className='h4 container-text-flex'>
                <p className='font-weight-bold pr-2'>
                  {getSpainTaxCode(tax).itemTaxCode}
                </p>
                <p className='font-weight-bold'>
                  {(country === "panama" && isAfterDate && isElectronic) ? I18n.get('taxes', 'Impuestos') :
                    tax.name}:
                </p>
                <p className='pl-2'>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              </p>
              ):(
              <h4 key={index} className={`h4 container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>    
                <p className='font-weight-bold'>
                  {(country === "panama" && isAfterDate && isElectronic) ? I18n.get('taxes', 'Impuestos') :
                    tax.name}:
                </p>
                <p className='pl-2'>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              </h4>
              )
            ))
          : null
        }

        {!!showITBISTaxes &&
          <>
            {!(!!showDiscount && country === 'republicaDominicana') && (
              <p>
                <strong>{I18n.get('subtotal', 'subtotal')}</strong>
                {subtotal.toFormat(decimal, fmt)}
              </p>
            )}
            {(!!taxes.find(tax => get(tax, 'type', '') === "E") || !!items.filter(item => isEmpty(item, 'tax'), []).length) && (
              <p>
                <strong>{I18n.get('exemptSubtotal', 'Subtotal Exento')}</strong>
                {items.filter(item => get(item, 'tax[0].type') === "EXENTO" || isEmpty(get(item, 'tax', []))).reduce((acc, item) => acc.plus(new BigNumber(get(item, 'price')).times(get(item, 'quantity')).minus(get(item, 'discount', 0))), new BigNumber(0)).toFormat(decimal, fmt)}
              </p>
            )}
            <p>
              <strong>{I18n.get('taxedSubtotal', 'Subtotal Gravado')}</strong>
              {items.filter(item => get(item, 'tax[0].type') === "ITBIS").reduce((acc, item) => acc.plus(new BigNumber(get(item, 'price')).times(get(item, 'quantity'))), new BigNumber(0)).minus(discount).toFormat(decimal, fmt)}
            </p>
            {taxes.filter(t => get(t, 'type') === "ITBIS").map((tax, index) => {
              const taxType = get(tax, 'type') === "E"
                ? "E"
                : ![18, 16, 0].includes(Number(get(tax, 'percentage', 1)))
                  ? get(customTaxTypeByPercentage, Number(tax.percentage))
                  : get(taxTypeByPercentage, Number(tax.percentage));

              return (
                <p key={index}>
                  <span>{!!taxType ? `(${taxType}) ` : ''}{tax.name}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            <p>
              <strong>Total ITBIS</strong>
              {taxes.reduce((acc, tax) => acc.plus(get(tax, 'type') === 'ITBIS' ? tax.value : 0), new BigNumber(0)).toFormat(decimal, fmt)}
            </p>

            {taxes.filter(t => get(t, 'type') === "ISC").map((tax, index) => {
              return (
                <p key={index}>
                  <span>{tax.name}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            {taxes.filter(t => get(t, 'type') === "ISC").length > 1 && (
              <p>
                <strong>Total ISC</strong>
                {taxes.reduce((acc, tax) => acc.plus(get(tax, 'type') === 'ISC' ? tax.value : 0), new BigNumber(0)).toFormat(decimal, fmt)}
              </p>
            )}

            {taxes.filter(t => get(t, 'type') === "PROPINA").map((tax, index) => {
              return (
                <p key={index}>
                  <span>{tax.name}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            {taxes.filter(t => get(t, 'type') === "PROPINA").length > 1 && (
              <p>
                <strong>Total Propina</strong>
                {taxes.reduce((acc, tax) => acc.plus(get(tax, 'type') === 'PROPINA' ? tax.value : 0), new BigNumber(0)).toFormat(decimal, fmt)}
              </p>
            )}

            {taxes.filter(t => get(t, 'type') === "OTRO").map((tax, index) => {
              return (
                <p key={index}>
                  <span>{tax.name}: </span>
                  {tax.value.toFormat(decimal, fmt)}
                </p>
              )
            })}
            {taxes.filter(t => get(t, 'type') === "OTRO").length > 1 && (
              <p>
                <strong>Total Otros</strong>
                {taxes.reduce((acc, tax) => acc.plus(get(tax, 'type') === 'OTRO' ? tax.value : 0), new BigNumber(0)).toFormat(decimal, fmt)}
              </p>
            )}
          </>
        }

        <h3 className={`h3 container-text-${(country === "panama" && isAfterDate && isElectronic) ? "grid" : "flex"}`}>
          <p className='font-weight-bold'>{I18n.get('total', 'total')}:</p>
          <p className='pl-2'>
            {total.toFormat(decimal, fmt)}
          </p>
        </h3>
      </div>

      {(country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable) && (
        <TaxDetail invoice={invoice} taxes={taxes} subTotalByTax={subTotalByTax} subtotal={discSubtotal} total={total} />
      )}

      {country !== 'argentina' && (
        <div className="text-left">
          {!!totalReceived && (
            <p>
              <strong>{I18n.get('totalReceived', 'total recibido')}</strong>
              {new BigNumber(totalReceived).toFormat(decimal, fmt)}
            </p>
          )}
          {!!cashReturned && (
            <p>
              <strong>{I18n.get('cashReturned', 'cambio')}</strong>
              {new BigNumber(cashReturned).toFormat(decimal, fmt)}
            </p>
          )}
          {
            totalLines && (
              <>
                <p>
                  <strong>{I18n.get('totalRows', 'total de líneas')}</strong>
                  {items.length}
                </p>
                <p>
                  <strong>{I18n.get('totalItems', 'total de productos')}</strong>
                  {items.map(item => +get(item, 'quantity', 0)).reduce((prev, curr) => prev + curr, 0)}
                </p>
              </>
            )
          }
        </div>
      )}

    </div>
  )
};

export default Payments;