import React, { useState, useRef, useEffect } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, isNull, get } from 'lodash';

import { renderField } from '../fields/V0/Fields';
import Ticket from '../../svg/Ticket'

const ItemCategory = ({ form, values }) => {
  const [src, setSrc] = useState(null);
  const imageInputRef = useRef();

  useEffect(() => {
    if (!isNull(get(values, 'imageSrc', null))) {
      setSrc(values.imageSrc)
    }
  }, [values])

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start flex-wrap-reverse">
        <div className="col-sm-6 form-row">
          <Field
            name="name"
            className="col-12"
            component={renderField}
            type="text"
            label={capitalize(I18n.get('name', 'Nombre'))}
            required
          />
          <Field
            name="description"
            className="col-12"
            component={renderField}
            type="textarea"
            label={capitalize(I18n.get('description', 'descripción'))}
          />
        </div>
        <div className="col-sm-6 form-row d-flex justify-content-md-end justify-content-center align-items-center">
          <Field
            name="image"
          >
            {(props) => (
              <div className="image-container">
                {!!src
                  ? (
                    <img
                      onClick={() => imageInputRef.current.click()}
                      src={src}
                      alt={I18n.get('categoryImage', 'imagen de categoría')}
                      className="w-100 h-100 pointer"
                    />
                  )
                  : (
                    <div
                      onClick={() => imageInputRef.current.click()}
                      className="d-flex w-100 h-100 flex-column justify-content-center align-items-center pointer">
                      <Ticket className="icon-secondary icon x4 mb-2" />
                      <p className="text-capitalize-first text-secondary text-center h5">
                        {I18n.get('categoryImage', 'imagen de categoría')}
                      </p>
                    </div>
                  )
                }

                <input
                  {...props.input}
                  type="file"
                  onChange={(e) => {
                    let file = e.target.files[0], reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = ev => {
                      setSrc(ev.target.result)
                      form.change('imageSrc', ev.target.result)
                    }
                    props.input.onChange(e)
                  }}
                  ref={imageInputRef}
                  className="w-100 h-100 d-none"
                />
              </div>
            )}
          </Field>

        </div>
      </div>
    </div>
  )
}

export default ItemCategory;