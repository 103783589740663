// eslint-disable-next-line
export default ({ id, className, bg }) => (
  <svg 
    id={id}
    className={className}
    width="36" 
    height="36" 
    viewBox="0 0 48 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle fill={bg} cx="23.999" cy="24.107" r="19"/>
    <g>
      <path d="M24,21.987c2.92,0,5.286-2.364,5.286-5.284c0-2.92-2.365-5.285-5.286-5.285
        c-2.919,0-5.284,2.365-5.284,5.285C18.715,19.623,21.081,21.987,24,21.987z M24,13.928c1.534,0,2.774,1.242,2.774,2.775
        c0,1.532-1.24,2.774-2.774,2.774c-1.532,0-2.774-1.242-2.774-2.774C21.225,15.169,22.467,13.928,24,13.928z"/>
      <path d="M24,23.308c-3.527,0-10.569,1.77-10.569,5.286v3.962h21.138v-3.962C34.568,25.078,27.525,23.308,24,23.308z
        M32.057,30.044H15.941v-1.45c0-0.848,4.134-2.775,8.059-2.775c3.924,0,8.057,1.928,8.057,2.775V30.044z"/>
    </g>
  </svg>
)
