function ArrowRightUpSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66659 5.20831C6.32141 5.20831 6.04159 5.48814 6.04159 5.83331C6.04159 6.17849 6.32141 6.45831 6.66659 6.45831H12.6577L5.39131 13.7247C5.14723 13.9688 5.14723 14.3645 5.39131 14.6086C5.63539 14.8527 6.03112 14.8527 6.27519 14.6086L13.5416 7.3422V13.3333C13.5416 13.6785 13.8214 13.9583 14.1666 13.9583C14.5118 13.9583 14.7916 13.6785 14.7916 13.3333V5.83331C14.7916 5.6614 14.7222 5.50569 14.6099 5.3927L14.6085 5.39137L14.6072 5.39005C14.5476 5.33078 14.479 5.28601 14.4058 5.25574C14.3321 5.22518 14.2513 5.20831 14.1666 5.20831H6.66659Z'
        fill='#94A3B8'
      />
    </svg>
  );
}

export default ArrowRightUpSvg;
