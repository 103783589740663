import http from '../utils/http'

const baseUrl = process.env.REACT_APP_NOTIFICATIONS_ENDPOINT
const baseConfig = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_NOTIFICATIONS_AUTHORIZATION}`
  }
}

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, { ...baseConfig, ...userConfig });

const post = async (url, params = null, urlParams = null, userConfig = null) => await http.post(`${baseUrl}${url}`, params, urlParams, { ...baseConfig, ...userConfig });

const put = async (url, params = null, urlParams = null, userConfig = null) => await http.put(`${baseUrl}${url}`, params, urlParams, { ...baseConfig, ...userConfig });

const httpDelete = async (url, urlParams = null, userConfig = null) => await http.delete(`${baseUrl}${url}`, urlParams, { ...baseConfig, ...userConfig });

const defaultExport = {
  get,
  post,
  put,
  delete: httpDelete,
}

export default defaultExport