import React from 'react'
import { I18n } from '@aws-amplify/core';

import Loading from '../../svg/Loading'

const Bottom = ({ onCancel, submitting }) => {
  return (
    <div className="detail-buttons d-flex align-items-top justify-content-between">
      <div className="required-message mb-3 mb-sm-0">
        <span className="text-capitalize-first text-muted">
          {I18n.get('requiredFields1', 'Los campos marcados con')}
        </span>
        <i className="text-primary mr-2"> * </i>
        <span className="text-capitalize-first text-muted">
          {I18n.get('requiredFields2', 'son obligatorios')}
        </span>
      </div>

      <div className="modal__bottom-buttons d-flex">
        <button
          type="button"
          className="btn btn-cancel text-capitalize-first"
          onClick={() => onCancel()}
        >
          {I18n.get('cancel', 'cancelar')}
        </button>

        <button
          type="submit"
          data-testid={`modal-submit-btn`}
          className="btn btn-submit text-capitalize-first"
          disabled={!!submitting}
        >
          {submitting
            ? <Loading className="icon-primary" />
            : I18n.get('save', 'guardar')
          }
        </button>
      </div>
    </div>
  )
}

export default Bottom