import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import UnAllow from '../svg/UnAllow';

function TableNetworkMessage() {
  const history = useHistory();

  return (
    <div className='react-table-gray__connection-issue position-absolute d-flex align-items-center justify-content-center flex-column h-2 p-3'>
      <UnAllow className='un-allow-icon' />

      <section>
        <h2> {I18n.get('connectionIssueTitle', 'Trabajando sin internet')}</h2>
        <p className='mb-0'>
          {I18n.get(
            'connectionIssueDescription',
            'Podrás consultar tus datos al recuperar la conexión.'
          )}
        </p>
        <button
          type="button"
          className="btn btn-action button-menu-action text-lowercase text-capitalize-first"
          onClick={() => history.push('/')}
        >
          {[I18n.get('goToMenu', 'Ir al menu de'), " ", I18n.get('checkIn', 'Facturar')].join('')}
        </button>
      </section>
    </div>
  );
}

TableNetworkMessage.propTypes = {
  onReload: PropTypes.func,
};

TableNetworkMessage.defaultProps = {
  onReload: null,
};

export default TableNetworkMessage;
