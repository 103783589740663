function RefreshNewSvg({ id, className, notification }) {
  return (
    <svg
      id={id}
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.1097 0.337671C8.1845 0.0892524 6.23098 0.480723 4.55012 1.45178C3.43655 2.0951 2.48358 2.96916 1.75009 4.00691V1.99995C1.75009 1.58574 1.41431 1.24995 1.00009 1.24995C0.585878 1.24995 0.250092 1.58574 0.250092 1.99995V5.99995C0.250092 6.41417 0.585878 6.74995 1.00009 6.74995H1.48272C1.49412 6.75021 1.5055 6.75021 1.51686 6.74995H5.00009C5.41431 6.74995 5.75009 6.41417 5.75009 5.99995C5.75009 5.58574 5.41431 5.24995 5.00009 5.24995H2.72541C3.36093 4.22198 4.24361 3.36117 5.30047 2.75061C6.69644 1.94414 8.31886 1.61902 9.91778 1.82534C11.5167 2.03165 13.0035 2.75795 14.149 3.89236C15.2945 5.02677 16.0353 6.50635 16.2572 8.10319C16.3142 8.51346 16.6931 8.79983 17.1033 8.74281C17.5136 8.6858 17.8 8.30699 17.743 7.89672C17.4757 5.97399 16.5838 4.19245 15.2045 2.82653C13.8251 1.46061 12.035 0.586089 10.1097 0.337671Z" />
      <path d="M1.74295 9.89676C1.68594 9.48649 1.30713 9.20012 0.896856 9.25714C0.486585 9.31416 0.200215 9.69297 0.257231 10.1032C0.524435 12.026 1.4164 13.8075 2.79572 15.1734C4.17504 16.5393 5.9652 17.4139 7.89044 17.6623C9.81568 17.9107 11.7692 17.5192 13.4501 16.5482C14.5636 15.9049 15.5166 15.0308 16.2501 13.993V16C16.2501 16.4142 16.5859 16.75 17.0001 16.75C17.4143 16.75 17.7501 16.4142 17.7501 16V12C17.7501 11.5858 17.4143 11.25 17.0001 11.25H16.5175C16.5061 11.2497 16.4947 11.2497 16.4833 11.25H13.0001C12.5859 11.25 12.2501 11.5858 12.2501 12C12.2501 12.4142 12.5859 12.75 13.0001 12.75H15.2748C14.6393 13.778 13.7566 14.6388 12.6997 15.2493C11.3037 16.0558 9.68133 16.3809 8.0824 16.1746C6.48347 15.9683 4.99673 15.242 3.85119 14.1076C2.70565 12.9732 1.96487 11.4936 1.74295 9.89676Z" />
    </svg>
  );
}

export default RefreshNewSvg;
