import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { get, zipWith, concat, isUndefined, isNull, capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { renderSelect, renderField } from '../fields/V0/Fields';
import { openModal } from '../../../reducers/modals';
import { activeWarehouses } from '../../../selectors/warehouses';
import { activeVariants as activeVariantsSelector } from '../../../selectors/variants';
import { stationWarehouse } from '../../../selectors/app';

import Edit from '../../svg/Edit'
import EditItemVariant from './modals/EditItemVariant';
import VariantsImage from './VariantsImage';
import Plus from '../../svg/Plus';
import Lock from '../../svg/Lock'
import Unlock from '../../svg/Unlock'


const productStatusOptions = [
  {
    value: 'active',
    label: 'activeProducts',
    defaultLabel: 'Activos'
  },
  {
    value: 'inactive',
    label: 'inactiveProducts',
    defaultLabel: 'Inactivos'
  }
];

const transformVariantWarehouses = (variant, warehouses) => {
  if (!!get(variant, 'inventory.singleWarehouse', null) &&
    (!get(variant, 'inventory.warehouses.length') || get(variant, 'inventory.warehouses.length') === 1)) {
    return {
      ...variant,
      inventory: {
        warehouses: [{
          initialQuantity: get(variant, 'inventory.singleWarehouseInitialQuantity'),
          minQuantity: get(variant, 'inventory.warehouses[0].minQuantity'),
          maxQuantity: get(variant, 'inventory.warehouses[0].maxQuantity'),
          warehouse: warehouses.find(t => t.id === get(variant, 'inventory.singleWarehouse.id')),
        }]
      }
    }
  }

  if (!!get(variant, 'inventory.warehouses.length')) {
    const variantWarehouses = get(variant, 'inventory.warehouses')
    return {
      ...variant,
      inventory: {
        warehouses: variantWarehouses.map(w => ({
          ...w,
          warehouse: warehouses.find(t => t.id === w.id)
        }))
      }
    }
  }
  return variant
}

const Variants = ({ values, form, ...props }) => {
  const [variantModal, setVariantModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const dispatch = useDispatch();
  const activeVariants = useSelector(activeVariantsSelector);
  const warehouses = useSelector(activeWarehouses);
  const currentWarehouse = useSelector(stationWarehouse);

  const variants = get(values, 'variantAttributes');
  const itemVariants = get(values, 'itemVariants');
  ;
  const openItemVariants = () => {
    dispatch(openModal({ modal: 'variant' }))
  }

  const openVariantItemModal = index => {
    setSelectedVariant(transformVariantWarehouses(get(itemVariants, `${index}`), warehouses))
    setSelectedIndex(index)
    setVariantModal(true)
  }

  const combineVariants = (variants, index = 0, previous = []) => {
    if (index >= variants.length)
      return previous;
    
    const { id, options } = variants[index];

    let result = []

    if (!!previous.length) {
      let previousOptions = []
      let newOptions = []

      previous.map(p => {
        for (let index = 0; index < options.length; index++) {
          previousOptions.push(p)
          newOptions.push({ id, options: options[index] })
        }
        return null
      })

      result = zipWith(previousOptions, newOptions, concat)
    } else
      result = options.map(opt => ([{ id, options: opt }]))

    return combineVariants(variants, index + 1, result);
  }

  const calculateItemVariants = variantAttributes => {
    return combineVariants(variantAttributes).map(variantAtt => ({
      inventory: {
        initialQuantity: null,
        singleWarehouse: currentWarehouse,
      },
      status: productStatusOptions[0],
      variantAttributes: variantAtt.map(({ id, options }) => ({
        id, options: [options]
      }))
    }))
  }

  const toggleItemVariant = variant => {
    let selectedVariants = !!variants ? [...variants] : []

    if (!!selectedVariants.find(v => v.id === variant.id))
      selectedVariants.splice(variants.findIndex(v => v.id === variant.id), 1)
    else
      selectedVariants.push(variant)

    selectedVariants.sort((a, b) => a.id - b.id);

    form.change('variantAttributes', selectedVariants)
    form.change('itemVariants', calculateItemVariants(selectedVariants))
  }

  const renderVariantInventory = variantWarehouses => {
    if (!variantWarehouses || !get(variantWarehouses, 'length'))
      return null

    if (get(variantWarehouses, 'length') > 1)
      return `${get(variantWarehouses, 'length')} ${I18n.get('warehouses', 'bodegas')}`

    const data = []
    data.push(warehouses.find(t => t.id === get(variantWarehouses, '0.id'))?.name || "")
    data.push(`${I18n.get('quantity', 'cantidad')} ${parseInt(get(variantWarehouses, '0.initialQuantity', 0))}`)

    if (!isUndefined(get(variantWarehouses, '0.minQuantity')) && !isNull(get(variantWarehouses, '0.minQuantity')))
      data.push(`${I18n.get('minQuantity', 'cantidad')} ${get(variantWarehouses, '0.minQuantity')}`)

    if (!isUndefined(get(variantWarehouses, '0.maxQuantity')) && !isNull(get(variantWarehouses, '0.maxQuantity')))
      data.push(`${I18n.get('maxQuantity', 'cantidad')} ${get(variantWarehouses, '0.maxQuantity')}`)

    return data.join(' - ')
  }

  const handleVariantStatus = (index) => {
    if (get(itemVariants, `${index}.status.value`) === 'active') {
      form.mutators.update(`itemVariants`, index, {
        ...get(itemVariants, `${index}`),
        inventory: {
          ...get(itemVariants, `${index}.inventory`),
          singleWarehouseInitialQuantity: get(itemVariants, `${index}.inventory.singleWarehouseInitialQuantity`, 0),
        },
        status: productStatusOptions[1],
      });
    } else {
      form.mutators.update(`itemVariants`, index, {
        ...get(itemVariants, `${index}`),
        status: productStatusOptions[0],
      });
    }
  }
  return (
    <>
      <div className="p-0 col-12 form-row form-field-array-wrapper">
        <div className="d-flex py-3 flex-wrap aling-items-start">
          <button
            type="button"
            className="btn btn-secondary btn d-flex justify-content-center align-items-center mb-3 mr-4"
            onClick={() => openItemVariants()}
          >
            <Plus className="icon icon-primary mr-2" />
            {I18n.get('newVariant', 'Nueva variante')}
          </button>

          {activeVariants.map((variant, index) => (
            <button
              key={index}
              type="button"
              className={`btn mb-3 mr-4 btn-variant 
                ${!!variants && !!variants.find(v => v.id === variant.id) ? 'active' : ''}`}
              onClick={() => toggleItemVariant(variant)}
            >
              {get(variant, 'name')}
            </button>
          ))}
        </div>

        <FieldArray name="itemVariants">
          {({ fields, meta }) =>
            fields.map((name, index) => {
              return (
                <div
                  key={index} className="w-100"
                >
                  <div
                    className={`combo-item-container d-flex aling-items-center form-row mx-0
                    ${meta.submitFailed && !!meta.error && !!meta.error[index] ? 'border-danger border' : ''}`}
                    style={{ backgroundColor: `${get(values, `itemVariants.${index}.status.value`) === 'active' ? '' : '#F4F5FB'}` }}
                  >
                    <div className="pointer col-md-2 col-lg-2 d-none d-md-block">
                      <VariantsImage
                        form={form}
                        values={values}
                        index={index}
                        disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                      />
                    </div>

                    <div
                      className="col-md-10 col-lg-9 item-container d-flex flex-column justify-content-center"
                    >
                      <p
                        className="pointer h4 mb-1 text-primary font-weight-bold"
                        onClick={() => {
                          if (get(values, `itemVariants.${index}.status.value`) === 'active')
                            openVariantItemModal(index)
                        }}
                      >
                        {!!get(fields, `value.${index}.variantAttributes.length`)
                          ? get(fields, `value.${index}.variantAttributes`)
                            .map(variantAtt => get(variantAtt, 'options.0.value')).join(' - ')
                          : null
                        }
                      </p>

                      <div className='d-flex justify-content-between align-items-start'>

                        {(!get(fields, `value.${index}.inventory.warehouses.length`, false) || get(fields, `value.${index}.inventory.warehouses.length`) <= 1) && (
                          <>
                            <Field
                              name={`${name}.inventory.singleWarehouse`}
                              component={renderSelect}
                              className="col-md-4 col-lg-5 pl-0"
                              options={warehouses}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                              label={capitalize(I18n.get('warehouse', 'bodega'))}
                              validate={value => !get(value, 'id', null) ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                              addOptionText={!!props.newWarehouseAction ? capitalize(I18n.get('newWarehouse', 'Nueva bodega')) : null}
                              addOptionAction={() => props.newWarehouseAction(index)}
                              defaultValue={currentWarehouse}
                              height={"3rem"}
                              fontSize={"12px"}
                              disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                              required
                            />
                            <Field
                              name={`${name}inventory.singleWarehouseInitialQuantity`}
                              component={renderField}
                              className="col-md-4 col-lg-5 pl-0"
                              type="number"
                              label={capitalize(I18n.get('initialQuantity', 'cantidad inicial'))}
                              // validate={value => !(value) ? capitalize(I18n.get('indicateTheVariantQuantity', 'Indica aquí las cantidades en tus bodegas')) : null}
                              height={"3rem"}
                              fontSize={"12px"}
                              disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                              required
                            />
                          </>
                        )}

                        {get(fields, `value.${index}.inventory.warehouses.length`) > 1 && (
                          <p className={` pl-1 h5 m-0 text-subtitle
                        ${meta.submitFailed && !!meta.error && !!meta.error[index] ? 'text-danger' : ''} align-self-center`}
                          >
                            {renderVariantInventory(get(fields, `value.${index}.inventory.warehouses`))}
                          </p>
                        )}
                        <Field
                          name={`${name}.status`}
                          className="d-none"
                          component={renderSelect}
                          getOptionLabel={option => capitalize(I18n.get(option.label, option.defaultLabel))}
                          getOptionValue={option => option.value}
                          options={productStatusOptions}
                          defaultValue={productStatusOptions[0]}
                        />
                        <div className='d-flex mt-4'>
                          <button
                            type="button"
                            className="btn button-transparent px-0 mx-1 mt-3"
                            style={{ border: "none", outline: "none", boxShadow: "none" }}
                            onClick={() => openVariantItemModal(index)}
                            title={capitalize(I18n.get('edit', 'Editar'))}
                            disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                          >
                            <Edit className="icon-primary ml-1" />
                          </button>
                          <button
                            type="button"
                            className="btn button-transparent px-0 mx-1 mt-3"
                            style={{ border: "none", outline: "none", boxShadow: "none" }}
                            onClick={() => handleVariantStatus(index, meta)}
                            title={get(values, `itemVariants.${index}.status.value`) === 'active'
                              ? capitalize(I18n.get('deactivate', 'desactivar'))
                              : capitalize(I18n.get('activate', 'activar'))
                            }
                          >
                            {get(values, `itemVariants.${index}.status.value`) === 'active'
                              ? <Unlock className="icon-primary" />
                              : <Lock className="icon-primary" />
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </FieldArray>
      </div>

      <EditItemVariant
        isOpen={variantModal}
        onRequestClose={() => setVariantModal(false)}
        variant={selectedVariant}
        form={form}
        index={selectedIndex}
      />
    </>
  )
}

export default Variants;