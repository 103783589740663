import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';


const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchVariants = createAsyncThunk(
  'variants/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!preloaded)
        return preloaded

      // ex queries.allVariants

      const response = await alegraAPI.get('/variant-attributes', {
        fields: 'deletable,editable,options.editable',
        ...params
      })

      const data = get(response, 'data', null)
      if (data)
        return data;
      return [];
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter({
  selectId: variant => variant.id
});

const appSlice = createSlice({
  name: 'variants',
  initialState,
  reducers: {
    addVariant: adapter.addOne,
    updateVariant: adapter.updateOne,
    removeVariant: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchVariants.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchVariants.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchVariants.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer, actions } = appSlice;

export const { addVariant, updateVariant, removeVariant } = actions

export const variantsSelector = adapter.getSelectors(state => state.variants);

export default reducer;