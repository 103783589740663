import React from 'react'
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { handleError } from '../../../utils/errors';
import { hasPermissionTo } from '../../../selectors/auth';
import { offlineData } from '../../../selectors/app';
import { paymentsLoading, paymentsError } from '../../../selectors/shifts';

import Loading from "../../svg/Loading";
import Notification from '../common/Notification';

const CloseShiftBottom = ({ closeModalAction, form, submitting, submitError }) => {
  const can = useSelector(hasPermissionTo);
  const loading = useSelector(paymentsLoading);
  const error = useSelector(paymentsError);
  const offlineStatus = useSelector(offlineData);

  return (
    <div className="close-shift-bottom">
      <Notification
        type="warning"
        isOpen={!can('close', 'pos-cashier')}
        text={I18n.get('userNotAllowed.pos-cashier.close', 'no tienes permisos para cerrar turnos')}
      />

      <Notification
        isOpen={!!error}
        text={`${I18n.get('thereWasAnErrorLoadingShiftValues', 'Hubo un error cargando los valores del turno.')} ${handleError(error)}`}
      />

      <Notification
        isOpen={offlineStatus}
        text={`${I18n.get('youMustSynchronizeBeforeCloseShift', 'Debes sincronizar tu información antes de poder cerrar turno')} ${handleError(error)}`}
      />

      <Notification isOpen={!!submitError} text={submitError} />

      <div className="d-flex flex-row">
        <div className="required-message">
          <i className="text-primary mr-1">* </i>
          <span className="text-capitalize-first text-muted">{I18n.get("requiredFields", "Campos obligatorios")}</span>
        </div>
        <div className="d-flex flex-row justify-content-end align-items-center">
          <button
            type="button"
            className="btn btn-cancel text-capitalize-first"
            onClick={closeModalAction}>
            {I18n.get("cancel", "cancelar")}
          </button>
          <button
            type="submit"
            className="btn btn-submit text-capitalize-first"
            onClick={form.submit}
            disabled={!!submitting || (!!loading || !!error || !can('close', 'pos-cashier') || offlineStatus)}>
            {submitting
              ? <Loading className="icon-primary" />
              : I18n.get("save", "guardar")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CloseShiftBottom