import { useHeader } from '../../header/context/header.context';
import TooltipComponent from '../Tooltip';
import Typography from '../Typography';

function MenuOption({
  children,
  header,
  id,
  headerClassName,
  extraClassName,
  tooltip,
  eventName,
}) {
  const { activeMenu, setActiveMenu } = useHeader();

  return (
    <li className='h-100 py-2 new-menu d-flex align-items-center'>
      <div
        className={` ${headerClassName ? headerClassName : 'new-menu-item'}`}
        onClick={() => setActiveMenu(activeMenu === id ? '' : id, eventName)}
      >
        <TooltipComponent
          overlay={
            typeof tooltip === 'string' ? (
              <Typography type='caption-bold' variant='white' text={tooltip} />
            ) : (
              tooltip
            )
          }
          placement='bottom'
          tooltipActive={!!tooltip && activeMenu !== id}
        >
          {header}
        </TooltipComponent>
      </div>

      {activeMenu === id && (
        <>
          <div className={`menu-options ${extraClassName}`}>{children}</div>
          <div
            className='menu-options-overlay'
            onClick={() => setActiveMenu('')}
          />
        </>
      )}
    </li>
  );
}

export default MenuOption;
