// eslint-disable-next-line
export default ({ id, className, width, height }) => (
  <svg
    id={id}
    width={width || "4"}
    height={height || "18"}
    className={className}
    viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0.25C1.0335 0.25 0.25 1.0335 0.25 2C0.25 2.9665 1.0335 3.75 2 3.75C2.9665 3.75 3.75 2.9665 3.75 2C3.75 1.0335 2.9665 0.25 2 0.25Z" fill="#94A3B8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.25 9C0.25 8.0335 1.0335 7.25 2 7.25C2.9665 7.25 3.75 8.0335 3.75 9C3.75 9.9665 2.9665 10.75 2 10.75C1.0335 10.75 0.25 9.9665 0.25 9Z" fill="#94A3B8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.25 16C0.25 15.0335 1.0335 14.25 2 14.25C2.9665 14.25 3.75 15.0335 3.75 16C3.75 16.9665 2.9665 17.75 2 17.75C1.0335 17.75 0.25 16.9665 0.25 16Z" fill="#94A3B8" />
  </svg>
)