import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, flow, deburr, lowerCase, isArray } from 'lodash';

import { categoriesSelector } from '../reducers/categories'

const accountReceivable = state => get(state, 'categories.receivableAccounts', null);
const debtToPay = state => get(state, 'categories.debtsToPayProviders', null);

const addDepth = (tree, initialDepth = 0) =>
  tree.map(child => ({
    ...child,
    depth: initialDepth,
    children: (!!child?.children && !!child?.children?.length)
      ? addDepth(child?.children, initialDepth + 1)
      : child?.children
  }))

const flattenTree = tree => {
  let arr = [];

  tree.map(leaf => {
    arr.push(leaf);

    if (!!leaf.children && !!leaf.children.length)
      arr.push(...flattenTree(leaf.children));

    return null;
  });

  return arr;
}

export const categories = createDraftSafeSelector(
  categoriesSelector.selectAll,
  categories => {
    categories = flow([
      addDepth,
      flattenTree,
    ])(categories);

    categories = categories.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
    return categories;
  }
)

export const getCategoryByName = (name, altName = false) => createDraftSafeSelector(
  categories,
  categories => {
    if (!categories)
      return null;
    if (!categories.find(cat => get(cat, 'name') === name) && !!altName)
      return categories.find(cat => get(cat, 'name') === altName)
    return categories.find(cat => get(cat, 'name') === name)
  }
)

export const accountReceivableCategories = createDraftSafeSelector(
  accountReceivable,
  categories => {
    if (!!categories) {
      categories = isArray(categories) ? categories : [categories]

      categories = flow([
        addDepth,
        flattenTree,
      ])(categories);

      categories = categories.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
      return categories;
    }
    return []
  }
);

export const debtToPayCategories = createDraftSafeSelector(
  debtToPay,
  categories => {
    if (!!categories) {
      categories = isArray(categories) ? categories : [categories]

      categories = flow([
        addDepth,
        flattenTree,
      ])(categories);

      categories = categories.filter(cat => !get(cat, 'readOnly') && get(cat, 'blocked') !== 'yes');
      return categories;
    }
    return []
  }
);

export const getCategoryByType = type => createDraftSafeSelector(
  categories,
  categories => categories.filter(cat => get(cat, 'type') === type)
)

export const groupedCategories = state => {
  const categories = categoriesSelector.selectAll(state);
  return !!categories
    ? categories.map(rootCat => ({
      label: get(rootCat, 'name', ''),
      options: getCategoryByType(get(rootCat, 'type', ''))(state),
    }))
    : []
}

export const defaultInCategory = createDraftSafeSelector(
  getCategoryByType('income'),
  categories => categories.find(category =>
    deburr(lowerCase(get(category, 'name', ''))) === deburr(lowerCase('otros ingresos')))
    || null
)

export const defaultOutCategory = createDraftSafeSelector(
  getCategoryByType('expense'),
  categories => categories.find(category =>
    deburr(lowerCase(get(category, 'name', ''))) === deburr(lowerCase('otros gastos')))
    || null
)

export const getCategoryById = id => createDraftSafeSelector(
  categories,
  categories => !!categories ? categories.find(cat => +cat.id === +id) || null : null
)

export const getDefaultAccountReceivableCategory = createDraftSafeSelector(
  accountReceivableCategories,
  categories => categories.find(category => get(category, 'parameterizedMovement', false))
)

export const getDefaultDebtToPayCategory = createDraftSafeSelector(
  debtToPayCategories,
  categories => categories.find(category => get(category, 'parameterizedMovement', false))
)