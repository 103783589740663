import React from 'react';

import Cash from './Cash';
import Card from './Card';
import Combined from './Combined';
import Transfer from './Transfer';
import QRTransfer from './QRTransfer';
import BancolombiaQR from './BancolombiaQR';
import PushNotification from './PushNotification';
import NequiQR from './NequiQR';

const BasicData = ({ values, form, total, fmt, currencyCode, activeNequiPush, setExternalPaymentSuccess }) => {
  if (!values) return null;

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row justify-content-start">
        {values.method === 'cash' && (
          <Cash total={total} fmt={fmt} currencyCode={currencyCode} values={values} />
        )}

        {(values.method === 'debit' || values.method === 'credit') && (
          <Card form={form} values={values} total={total}  />
        )}

        {values.method === 'combined' && (
          <Combined fmt={fmt} values={values} />
        )}

        {values.method === 'transfer' && (
          <Transfer values={values} />
        )}

        {values.method === 'nequi' && (
          <QRTransfer form={form} values={values} total={total} />
        )}

        {values.method === 'bancolombiaQR' && (
          <BancolombiaQR form={form} values={values} total={total} setExternalPaymentSuccess={setExternalPaymentSuccess} />
        )}

        {(values.method === 'nequiPush' && activeNequiPush) && (
          <PushNotification form={form} values={values} total={total} setExternalPaymentSuccess={setExternalPaymentSuccess} />
        )}

        {(values.method === 'nequiPush' && !activeNequiPush) && (
          <NequiQR form={form} values={values} total={total} setExternalPaymentSuccess={setExternalPaymentSuccess} />
        )}
      </div>
    </div>
  )
}

export default BasicData;