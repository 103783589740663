import { I18n } from '@aws-amplify/core';
import { toLower } from 'lodash';

import { COUNTRIES } from '../../../utils/enums/countries';
import { PRODUCTS } from '../../../utils/enums/products';

import AlegraAccountingLogo from '../../svg/new/logos/accounting/AlegraAccounting';
import AlegraManagementLogo from '../../svg/new/logos/accounting/AlegraManagement';

import AlegraPosLogo from '../../svg/new/logos/pos/AlegraPos';
import AlegraPointOfSaleLogo from '../../svg/new/logos/pos/AlegraPointOfSale';
import AlegraTPVLogo from '../../svg/new/logos/pos/AlegraTPV';
import AlegraInvoiceLogo from '../../svg/new/logos/invoice/AlegraInvoice';
import AlegraPayrollLogo from '../../svg/new/logos/payroll/AlegraPayroll';
import AlegraStoreLogo from '../../svg/new/logos/store/AlegraStore';

import AlegraShop from '../../../assets/images/illustrations/alegra-shop.png';
import AlegraAccounting from '../../../assets/images/illustrations/alegra-accounting.png';
import AlegraPayroll from '../../../assets/images/illustrations/alegra-payroll.png';
import AlegraPos from '../../../assets/images/illustrations/alegra-pos.png';
import AlegraInvoice from '../../../assets/images/illustrations/alegra-invoice.png';

const solutionLogos = {
  accounting: {
    default: <AlegraAccountingLogo />,
    management: <AlegraManagementLogo />,
  },
  pos: {
    default: <AlegraPosLogo className='solutions-pos-logo' />,
    pointOfSale: <AlegraPointOfSaleLogo className='solutions-pos-logo' />,
    tpv: <AlegraTPVLogo className='solutions-pos-logo' />,
  },
  invoice: {
    default: <AlegraInvoiceLogo />,
  },
  payroll: {
    default: <AlegraPayrollLogo />,
  },
  shop: {
    default: <AlegraStoreLogo />,
  },
};

const getLogo = (country, type) => {
  const solution = solutionLogos[type];

  if (solution) {
    switch (type) {
      case 'accounting':
        if (
          [
            COUNTRIES.ARGENTINA,
            COUNTRIES.BOLIVIA,
            COUNTRIES.CHILE,
            COUNTRIES.SPAIN,
            COUNTRIES.INTERNATIONAL,
            COUNTRIES.USA,
          ].includes(country)
        )
          return solution.management;
        return solution.default;
      case 'pos':
        if ([COUNTRIES.ARGENTINA, COUNTRIES.PERU].includes(country))
          return solution.pointOfSale;
        if ([COUNTRIES.SPAIN].includes(country)) return solution.tpv;
        return solution.default;
      case 'invoice':
        return solution.default;
      case 'payroll':
        return solution.default;
      case 'shop':
        return solution.default;
    }
  }

  return <></>;
};

const getHelpUri = (country, type) => {
  switch (type) {
    case 'accounting':
      if (
        [
          COUNTRIES.COSTA_RICA,
          COUNTRIES.COLOMBIA,
          COUNTRIES.PANAMA,
          COUNTRIES.MEXICO,
          COUNTRIES.ARGENTINA,
          COUNTRIES.PERU,
          COUNTRIES.USA,
        ].includes(country)
      )
        return `https://www.alegra.com/${toLower(country)}/contabilidad/`;
      if ([COUNTRIES.REPUBLICA_DOMINICANA].includes(country))
        return 'https://www.alegra.com/rdominicana/contabilidad/';
      if ([COUNTRIES.SPAIN].includes(country))
        return 'https://www.alegra.com/es/contabilidad/';
      return 'https://www.alegra.com/contabilidad/';
    case 'payroll':
      if ([COUNTRIES.COLOMBIA].includes(country))
        return 'https://www.alegra.com/colombia/nomina-electronica/';
      return '';
    case 'invoice':
      if ([COUNTRIES.MEXICO, COUNTRIES.COSTA_RICA,COUNTRIES.PANAMA].includes(country))
        return `https://www.alegra.com/${toLower(country)}/facturacion/`;
      if ([COUNTRIES.SPAIN].includes(country))
        return 'https://www.alegra.com/es/facturacion/ ';
      return '';
    case 'shop':
      if (
        [
          COUNTRIES.COSTA_RICA,
          COUNTRIES.COLOMBIA,
          COUNTRIES.PANAMA,
          COUNTRIES.MEXICO,
          COUNTRIES.ARGENTINA,
          COUNTRIES.PERU,
          COUNTRIES.USA,
        ].includes(country)
      )
        return `https://www.alegra.com/${toLower(country)}/tienda/`;
      if ([COUNTRIES.REPUBLICA_DOMINICANA].includes(country))
        return 'https://www.alegra.com/rdominicana/tienda/';
      if ([COUNTRIES.SPAIN].includes(country))
        return 'https://www.alegra.com/es/tienda/';
      return 'https://www.alegra.com/tienda/';
  }
  return '';
};

export const solutionUris = (country) => {
  return {
    accounting: {
      uri: process.env.REACT_APP_ALEGRA,
      helpUrl: getHelpUri(country, 'accounting'),
    },
    invoice: {
      uri: process.env.REACT_APP_ALEGRA_INVOICE,
      helpUrl: getHelpUri(country, 'invoice'),
    },
    shop: {
      uri: process.env.REACT_APP_ALEGRA_SHOP,
      helpUrl: getHelpUri(country, 'shop'),
    },
    payroll: {
      uri: process.env.REACT_APP_ALEGRA_PAYROLL,
      helpUrl: getHelpUri(country, 'payroll'),
    },
    pos: {
      uri: process.env.REACT_APP_ALEGRA,
      helpUrl: getHelpUri(country, 'pos'),
    },
  };
};

export const solutionTypes = (country) => {
  return {
    accounting: {
      product: PRODUCTS.WEB,
      supTitle: I18n.get(
        'solutionModal.accounting.supTitle',
        'Tus cuentas claras con'
      ),
      buttonMainText: I18n.get(
        'solutionModal.accounting.buttonMainText',
        'Prueba 15 días gratis'
      ),
      buttonSecondaryText: I18n.get(
        'solutionModal.accounting.buttonSecondaryText',
        'Más información'
      ),
      description: I18n.get(
        'solutionModal.accounting.description',
        'Con Alegra Contabilidad controlas tus ingresos y gastos con un clic, y obtienes reportes inteligentes para que estés siempre al día con la DIAN.'
      ),
      subTitle: I18n.get(
        'solutionModal.accounting.subTitle',
        '*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.'
      ),
      illustration: <img src={AlegraAccounting} />,
      logo: getLogo(country, 'accounting'),
    },
    invoice: {
      product: PRODUCTS.INVOICE,
      supTitle: I18n.get('solutionModal.invoice.supTitle', 'Ahorra tiempo con'),
      buttonMainText: I18n.get(
        'solutionModal.invoice.buttonMainText',
        'Prueba 3 meses gratis'
      ),
      buttonSecondaryText: I18n.get(
        'solutionModal.invoice.buttonSecondaryText',
        'Más información'
      ),
      description: I18n.get(
        'solutionModal.invoice.description',
        'Genera en segundos tus facturas y tiquetes con el software de Facturación más seguro del país. 100% adaptados a la normatividad del Ministerio de Hacienda.'
      ),
      subTitle: I18n.get(
        'solutionModal.invoice.subTitle',
        '*Después de los 3 meses puedes elegir un plan desde {{price}} {{currency}} al mes.'
      ),
      illustration: <img src={AlegraInvoice} />,
      logo: getLogo(country, 'invoice'),
    },
    shop: {
      product: PRODUCTS.SHOP,
      supTitle: I18n.get(
        'solutionModal.store.supTitle',
        'Incrementa tus ventas con'
      ),
      buttonMainText: I18n.get(
        'solutionModal.store.buttonMainText',
        'Prueba 15 días gratis'
      ),
      buttonSecondaryText: I18n.get(
        'solutionModal.store.buttonSecondaryText',
        'Más información'
      ),
      description: I18n.get(
        'solutionModal.store.description',
        '¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.'
      ),
      subTitle: I18n.get(
        'solutionModal.store.subTitle',
        '*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.'
      ),
      illustration: <img src={AlegraShop} />,
      logo: getLogo(country, 'shop'),
    },
    payroll: {
      product: PRODUCTS.NE,
      supTitle: I18n.get(
        'solutionModal.payroll.supTitle',
        'Tu equipo al día con'
      ),
      buttonMainText: I18n.get(
        'solutionModal.payroll.buttonMainText',
        'Prueba 15 días gratis'
      ),
      buttonSecondaryText: I18n.get(
        'solutionModal.payroll.buttonSecondaryText',
        'Más información'
      ),
      description: I18n.get(
        'solutionModal.payroll.description',
        'Con Alegra Nómina resuelves en 10 minutos tu nómina electrónica y emites en segundos tus comprobantes de pago a la DIAN.'
      ),
      subTitle: I18n.get(
        'solutionModal.payroll.subTitle',
        '*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.'
      ),
      illustration: <img src={AlegraPayroll} />,
      logo: getLogo(country, 'payroll'),
    },
    pos: {
      product: PRODUCTS.POS,
      supTitle: I18n.get(
        'solutionModal.pos.supTitle',
        'Gestiona tu negocio con'
      ),
      buttonMainText: I18n.get(
        'solutionModal.pos.buttonMainText',
        'Prueba 15 días gratis'
      ),
      buttonSecondaryText: I18n.get(
        'solutionModal.pos.buttonSecondaryText',
        'Más información'
      ),
      description: I18n.get(
        'solutionModal.pos.description',
        'Factura en segundos con el sistema POS más veloz e intuitivo del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡'
      ),
      subTitle: I18n.get(
        'solutionModal.pos.subTitle',
        '*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.'
      ),
      illustration: <img src={AlegraPos} />,
      logo: getLogo(country, 'pos'),
    },
  };
};
