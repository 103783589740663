import React from 'react';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import CashOut from '../../../svg/new/CashOut';
import CreditCard from '../../../svg/new/CreditCard';
import ArrowsCross from '../../../svg/new/ArrowsCross'
import Seller from '../../../svg/new/Seller'
import { companySelector } from '../../../../selectors/company';
import { get } from 'lodash';

const renderIcon = (icon) => {
  switch (icon) {
    case 'cashOut':
      return <CashOut />
    case 'creditCard':
      return <CreditCard />
    case 'arrowsCross':
      return <ArrowsCross />
    case 'seller':
      return <Seller />
  }
}

const MethodBody = ({icon,title,description, showTagElectronic = false, onClick}) => {
  return (
    <button onClick={onClick}
      className='btn btn-refound-method-new p-4 w-100 d-flex flex-column justify-content-start'>
      <div className='d-flex flex-row align-items-center flex-start'>
        <div className='icon-refund-method mr-3'>
          {renderIcon(icon)}
        </div>
        {
          showTagElectronic && <div className='tag-electronic'>
            {I18n.get('electronicRefund', 'Nota electrónica')}
          </div>
        }
      </div>

      <div className='text-left'>
        <p className='title-method-refund mb-1'>
          {title}
        </p>
        <p className='description-method-refund'>{description}</p>
      </div>

    </button>
  )
}

const Methods = ({ onChooseMethod }) => {
  const company = useSelector(companySelector);
  const hasFEactive = get(company, 'settings.electronicInvoicing', false);
  return (
    <div className="container container-methods">
      <div className="row mb-4">
        <div
          className={`col-md-6 col-sm-12 p-0 pb-3 pr-md-3`}
        >
          <MethodBody 
            icon={'cashOut'} 
            onClick={() => onChooseMethod('cash')}
            title={I18n.get('refundCash', 'Devolución de dinero')} 
            description={I18n.get('newRefundRefundCashMethodDescription', 'Devuelve a tu cliente el total o parte del dinero de sus pagos.')} 
          />

        </div>        
        <div
          className={`col-md-6 col-sm-12 p-0 pl-md-3 pb-3`}
        >
          <MethodBody 
            icon={'creditCard'} 
            title={I18n.get('creditToSales', 'Crédito a ventas')} 
            showTagElectronic={!!hasFEactive}
            onClick={() => onChooseMethod('creditToSales')}
            description={I18n.get('newRefundCreditToSaleshMethodDescription', 'Disminuye el valor por cobrar en una factura de tu cliente.')} 
          />
        </div>
  
        <div
          className={`col-md-6 col-sm-12 p-0 pr-md-3 pt-md-3`}
        >
          <MethodBody 
            icon={'arrowsCross'} 
            onClick={() => onChooseMethod('combined')}
            title={I18n.get('combined', 'Combinado')} 
            description={I18n.get('newRefundCombinedMethodDescription', 'Devuelve dinero a tu cliente y a la vez aplica crédito a una de sus ventas.')} 
          />
        </div>

        <div
          className={`col-md-6 col-sm-12 p-0 pl-md-3 pt-3 pt-3`}
        >
          <MethodBody 
            icon={'seller'} 
            onClick={() => onChooseMethod('positiveBalance')}
            title={I18n.get('positiveBalance', 'Saldo a favor ')} 
            description={I18n.get('newrefundPositiveBalanceMethodDescription', 'Registra la devolución de productos y deja el valor como anticipo de tu cliente.')} 
          />
        </div>
      </div>
    </div>
  )
}

export default Methods;