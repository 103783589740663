import React, { useEffect } from 'react';

import renderIdentification, {
  searchIdentification,
} from './BasicData/Identification';
import renderName from './BasicData/Name';
import renderAddress from './BasicData/Address';
import renderKindOfPerson from './BasicData/KindOfPerson';
import renderTaxLiability from './BasicData/TaxLiability';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../selectors/app';
const BasicData = (props) => {
  const identificationType = get(props, 'values.identification.type.key', '');
  const kinfOfPerson = get(props, 'values.kindOfPerson.key', '');
  const identificationRuc = get(props, 'values.identification.ruc', '');
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    if (props.country === 'panama') {
      props.form.change('kindOfPerson', null);
    }
  }, [identificationType]);

  useEffect(() => {
    if (props.country === 'panama') {
      if (
        kinfOfPerson &&
        kinfOfPerson !== 'PERSON_EMPTY' &&
        identificationRuc
      ) {
        searchIdentification(APIGraphql, kinfOfPerson, props);
      }
      if (kinfOfPerson === 'PERSON_EMPTY') {
        props.form.change('kindOfPerson', null);
      }
    }
  }, [kinfOfPerson]);

  return (
    <div className='form-body__fields form-row justify-content-start'>
      {renderIdentification(props)}

      {renderKindOfPerson(props)}

      {renderTaxLiability(props)}

      {renderName(props)}

      {renderAddress(props)}
    </div>
  );
};

export default BasicData;
