import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { capitalize, get } from 'lodash';
// import { useHistory } from 'react-router-dom';

import * as queries from '../../../graphql/queries'
import { handleError } from '../../../utils/errors'
import { openModal } from '../../../reducers/modals'
import { hasPermissionTo } from "../../../selectors/auth";
import { country as countrySelector } from '../../../selectors/company';
import Table from "../../common/GrayTable";
import Header from '../../settings/common/Header';
import Actions from './Actions'
import Tooltip from "../../common/Tooltip";

import Plus from '../../svg/Plus';
import { APIGraphqlSelector } from '../../../selectors/app';
import alegraAPI from '../../../reducers/alegraAPI';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';

const parseOptions = options => {
  if (!!options) {
    const parsedOptions = (options.map((option, index) => {
      return {
        id: index,
        value: option,
      }
    }));
    return parsedOptions;
  }
  return options;
}

const CustomFields = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const ref = useRef(null);
  const country = useSelector(countrySelector);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [error, setError] = useState();
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/inventory/custom-fields',
      'virtualPageTitle': 'Custom fields'
    });

    return () => ref.current = false
  }, []);

  const startLoading = useCallback(() => {
    if (ref.current) {
      setLoading(true);
    }
  }, [])

  const stopLoading = useCallback(() => {
    if (ref.current) {
      setLoading(false);
    }
  }, [])

  const fetchData = useCallback(async ({ pageIndex, pageSize }) => {
    startLoading()
    setError(null)
    try {
      // ex queries.allCustomFields
      const response = await alegraAPI.get('/custom-fields', {
        start: pageIndex * (pageSize),
        limit: pageSize + 1,
        metadata: true,
        fields: 'deletable,editable',
      })
      stopLoading()

      const customFields = country === 'mexico'
        ? get(response, 'data.data', []).filter(customField => get(customField, 'key') !== 'importRequest')
        : get(response, 'data.data', [])

      if (ref.current)
        // setData(get(response, 'data.data', []).splice(1))
        // setData(get(response, 'data.data', []))
        setData(customFields);

      setControlledPageCount(Math.ceil(get(response, 'data.metadata.total', 0) / pageSize));

    } catch (error) {
      stopLoading()
      setError(handleError(error, I18n.get('customFieldsLoadError', 'Sucedió un error cargando los campos adicionales')))
    }
    if (ref.current) {
      setTableIndicators({ pageIndex, pageSize })
    }
  }, [stopLoading, startLoading, country, APIGraphql]);

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('name', 'Nombre')),
        sortable: false,
        accessor: 'name',
        Cell: ({ value, row }) => (
          <p
            className="h4 text-primary text-truncate pointer styled-hover"
            onClick={() =>
              (can("edit", "custom-fields") && get(row, 'original.editable', true)) ?
                dispatch(openModal({
                  modal: 'customField', params: {
                    customField: {
                      ...row.original,
                      type: { value: row.original.type, label: I18n.get(`customFieldType.${row.original.type}`, row.original.type) },
                      options: parseOptions(row.original.options),
                    },
                    onRefresh: () => fetchData(tableIndicators),
                  }
                })) : null
            }
            hovercontent={value}
          >
            {value}
          </p>
        )
      },
      {
        Header: capitalize(I18n.get('description', 'descripción')),
        accessor: 'description',
        Cell: ({ value }) => (
          <p className="h5 text-truncate text-muted">
            {value}
          </p>
        )
      },
      {
        Header: capitalize(I18n.get('customFieldType', 'tipo de campo')),
        accessor: 'type',
        Cell: ({ value }) => (
          <p className="h5 text-muted">
            {I18n.get(`customFieldType.${value}`, value)}
          </p>
        )
      },
      {
        Header: capitalize(I18n.get('actions', 'Acciones')),
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => (
          <Actions
            customField={row.original}
            onRefresh={() => fetchData(tableIndicators)}
            onView={() => dispatch(openModal({
              modal: 'customField', params: {
                customField: {
                  ...row.original,
                  type: { value: row.original.type, label: I18n.get(`customFieldType.${row.original.type}`, row.original.type) },
                  options: parseOptions(row.original.options),
                },
                onRefresh: () => fetchData(tableIndicators),
              }
            }))}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />
        )
      }
    ], [fetchData, startLoading, stopLoading, dispatch, tableIndicators, can]
  );

  useEffect(() => {
    fetchData({
      pageIndex: 0,
      pageSize: 10,
    })
  }, [fetchData])


  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Header
          title={<p className="text-title-secundary">{I18n.get('customFields', 'Campos adicionales')}</p>}
          subtitle={
            <p className="h5 text-muted text-capitalize-first">
              {I18n.get('customFieldsSubtitle', 'Crea campos personalizables para agregar información adicional de tus productos o servicios.')}
              <a href="https://ayuda.alegra.com/es/crea-campos-adicionales-y-as%C3%B3cialos-a-tus-productos-con-el-sistema-punto-de-venta-pos" target="_blank" rel="noreferrer">
                {I18n.get('knowMore', 'Saber más')}
              </a>
            </p>
          }
          actions={(
            <div className="d-flex">
              <Tooltip
                tooltipActive={can("add", "custom-fields")}
                overlay={<p className="text-capitalize-first">
                  {I18n.get("userNotAllowed.custom-fields.add", "no tienes permisos para agregar campos adicionales")}
                </p>}
              >
                <button
                  type="button"
                  disabled={!can("add", "custom-fields") || !connectionStatus}
                  className="btn btn-submit-large d-flex justify-content-center align-items-center"
                  onClick={() => {
                    dispatch(openModal({
                      modal: 'customField', params: {
                        onRefresh: () => fetchData(tableIndicators),
                      }
                    }))
                  }}
                >
                  <Plus className="icon icon-white mr-2" />
                  {I18n.get('newCustomField', 'Nuevo campo adicional')}
                </button>
              </Tooltip>

              {/* <button
                type="button"
                className="btn btn-cancel"
                onClick={() => history.goBack()}
              >
                {I18n.get('goBack', 'Volver')}
              </button> */}
            </div>
          )}
        />

        <div className="shadowp-4">
          <Table
            loading={loading}
            data={data}
            error={error}
            showNetworkConnectionError
            onRefresh={({ pageIndex, pageSize }) => fetchData({ pageIndex, pageSize })}
            noDataText={(
              <div className="text-center">
                <p>
                  {capitalize(I18n.get('customFieldNoDataText', 'Crea tu primer campo adicional ahora. 🙌'))}
                </p>

                <Tooltip
                  tooltipActive={can("add", "custom-fields")}
                  overlay={<p className="text-capitalize-first">
                    {I18n.get("userNotAllowed.custom-fields.add", "no tienes permisos para agregar campos adicionales")}
                  </p>}
                >
                  <button
                    type="button"
                    className="btn btn-submit mr-3"
                    onClick={() => dispatch(openModal({
                      modal: 'customField', params: {
                        onRefresh: () => fetchData(tableIndicators),
                      }
                    }))}
                  >
                    {I18n.get('newCustomField', 'Nuevo campo adicional')}
                  </button>
                </Tooltip>
              </div>
            )}
            columns={columns}
            onFetchData={fetchData}
            controlledPageCount={controlledPageCount}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomFields;