// eslint-disable-next-line
export default ({ id, className, fill }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill={fill || "none"} 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd" 
      d="M16.216 4.856h-1.034c0-.013.003-.027.005-.04.002-.015.004-.03.004-.045 0-.976-.795-1.771-1.771-1.771h-2.255c-.975 0-1.77.795-1.77 1.771 0 .016.001.032.004.048l.004.037H8.368C7.06 4.856 6 5.635 6 6.596v.062h12.585v-.063c0-.96-1.06-1.739-2.369-1.739zm-9.51 12.776V7.572H17.88v10.06A2.368 2.368 0 0 1 15.513 20h-6.44a2.368 2.368 0 0 1-2.368-2.368zm2.622-.057a.55.55 0 0 0 .549-.548v-7.26a.549.549 0 0 0-1.097 0v7.26c0 .302.246.548.548.548zm3.513-.548a.548.548 0 1 1-1.098 0v-7.26a.548.548 0 0 1 1.098 0v7.26zm2.416.548a.549.549 0 0 0 .548-.548v-7.26a.548.548 0 0 0-1.099 0v7.26a.55.55 0 0 0 .55.548zM11.165 3.721c-.579 0-1.05.471-1.05 1.05 0 .02.003.041.006.061l.003.024h4.338c0-.011.002-.023.004-.034a.354.354 0 0 0 .004-.05c0-.58-.471-1.051-1.05-1.051h-2.255z" 
    />
  </svg>
)