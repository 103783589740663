import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Cash } from './Cash';
import { CreditToSales } from './CreditToSales';
import { Combined } from './Combined';
import { PositiveBalance } from './PositiveBalance';

import { companySelector } from '../../../../selectors/company';
import { isSelectingItems } from "../../../../selectors/activeRefund";
import { get } from 'lodash';

import Left from '../../../svg/new/Left';
import { getPrevStep } from '../utils';

const getHelpLink = (hasFEactive, method) => {
  if (!method) return null;

  switch (method) {
    case 'cash':
      return 'https://ayuda.alegra.com/es/crea-devoluciones-de-tus-productos-desde-el-sistema-punto-de-venta-pos#Devolucion_dinero';
    case 'creditToSales':
      if (hasFEactive) {
        return 'https://ayuda.alegra.com/es/realiza-notas-credito-electronicas-desde-el-pos-colombia';
      } else {
        return 'https://ayuda.alegra.com/es/crea-devoluciones-de-tus-productos-desde-el-sistema-punto-de-venta-pos#Credito_factura';
      }
    case 'combined':
      return 'https://ayuda.alegra.com/es/crea-devoluciones-de-tus-productos-desde-el-sistema-punto-de-venta-pos#Combinado';
    case 'positiveBalance':
      return 'https://ayuda.alegra.com/es/crea-devoluciones-de-tus-productos-desde-el-sistema-punto-de-venta-pos#Saldo_favor';
    default:
      return null;
  }
}

const ButtonControl = ({ step, changeStep, method, closeModal, disabled, submitting, editItemIsOpen, lastStepError }) => {
  const isSelectingMode = useSelector(isSelectingItems);
  const company = useSelector(companySelector);
  const hasFEactive = get(company, 'settings.electronicInvoicing', false);
  
  if (!!editItemIsOpen) return null;
  
  const prevStep = getPrevStep(step, method);
  const helpLink = getHelpLink(hasFEactive, method);
  
  return (   
    <div className='bottom'>
      {(step === 1 || step === 2) && (
        <div className="help-link d-flex justify-content-center d-md-none mb-2 w-100">
          <a className='d-block text-center' href={helpLink} target="_blank" rel="noreferrer">
            {I18n.get('seeHelp', 'Ver ayuda')}
          </a>
        </div>
        )
      }
      <div className={`d-flex ${step === 0 ? 'justify-content-end' : 'justify-content-between'}`}>
        {step === 0 && (
          <button type='button' className='refunds-cancel-button' onClick={closeModal}>
            {I18n.get('cancel','Cancelar')}
          </button>
        )}

        {(step === 1 || step === 2) && (
          <div className="help-link d-md-block d-none">
            <a href={helpLink} target="_blank" rel="noreferrer">
              {I18n.get('seeHelp', 'Ver ayuda')}
            </a>
          </div>
          )
        }

        {step > 2 && !isSelectingMode && (
          <div className="help-link d-md-block d-none">
            <button
              type='button'
              className='refunds-cancel-button'
              style={{ border: 'none', fontWeight: '500' }}
              onClick={() => changeStep(prevStep)}>
              <Left className="icon-black mr-2" />
              {I18n.get('goBack', 'Volver')}
            </button>
          </div>
        )}

        {method === 'cash' && (
          <Cash 
            changeStep={changeStep} 
            closeModal={closeModal} 
            step={step} 
            disabled={disabled} 
            submitting={submitting}
          />
        )}
        
        {method === 'creditToSales' && (
          <CreditToSales 
            changeStep={changeStep} 
            closeModal={closeModal} 
            step={step} 
            disabled={disabled} 
            lastStepError={lastStepError}
            submitting={submitting}
          />
        )}
        
        {method === 'combined' && (
          <Combined 
            changeStep={changeStep} 
            closeModal={closeModal} 
            step={step} 
            disabled={disabled} 
            submitting={submitting}
          />
        )}

        {
          method === 'positiveBalance' && (
            <PositiveBalance 
              changeStep={changeStep} 
              closeModal={closeModal} 
              step={step} 
              disabled={disabled} 
              submitting={submitting}
            />
          )
        }
      </div>
    </div> 
  )
}

export default ButtonControl;