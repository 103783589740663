import { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { API, graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';

import * as subscriptions from '../graphql/subscriptions';
import { station as stationSelector } from '../selectors/app';
import { idCompanySelector } from '../selectors/company';
import { changeItemInventory, refresh } from '../reducers/items'
import alegraAPI from '../reducers/alegraAPI'
import {
  updateItemByEvent as updateActiveInvoice,
  removeItemByEvent as removeActiveInvoice,
} from '../reducers/activeInvoice'
import {
  updateItemByEvent as updateEditInvoice,
  removeItemByEvent as removeEditInvoice,
} from '../reducers/editInvoice'
import {
  updateItemByEvent as updatePendingInvoices,
  removeItemByEvent as removePendingInvoices,
} from '../reducers/pendingInvoices'
import {
  updateItemByEvent as updateActiveRefund,
  removeItemByEvent as removeActiveRefund,
} from '../reducers/activeRefund'
import { put, remove } from '../database/itemsDB';
// import { isItemFromStation } from '../utils/items';

const updateItemsGlobal = (item) => {
  return (dispatch) => {
    if (!item) return

    dispatch(updateActiveInvoice(item))
    dispatch(updateEditInvoice(item))
    dispatch(updateActiveRefund(item))
    dispatch(updatePendingInvoices(item))
  }
}

const removeItemsGlobal = (id) => {
  return (dispatch) => {
    dispatch(removeActiveInvoice(id))
    dispatch(removeEditInvoice(id))
    dispatch(removeActiveRefund(id))
    dispatch(removePendingInvoices(id))
  }
}

const ItemsSns = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const idCompany = useSelector(idCompanySelector)
  const station = useSelector(stationSelector)
  const networkStatus = useSelector(state => get(state, 'app.networkStatus', 'online'))
  const [onCreateItemError, setOnCreateItemError] = useState(false);
  const [onUpdateItemError, setOnUpdateItemError] = useState(false);
  const [onDeleteItemError, setOnDeleteItemError] = useState(false);
  const [onItemQuantityChangeError, setOnItemQuantityChangeError] = useState(false);

  const getItem = useCallback(
    async (id) => {
      try {
        const fields = "ledger,itemVariants,variantAttributes,editable,settingsOnShop,remissionedQuantity,negativeSale";
        const response = await alegraAPI.get(`/items/${id}?fields=${fields}`);

        return get(response, 'data', null)
      } catch {
        return null
      }
    },
    [],
  )

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onCreateItemNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const putItem = async data => {
              try {
                const { idWarehouse } = station;
                const itemId = get(data, 'value.data.onCreateItemNotify.id', null);
                const item = await getItem(itemId)
                const warehouses = get(item, 'inventory.warehouses', null);

                let invoicingWarehouse = false

                if (!!warehouses) {
                  if (!!warehouses.find(warehouse => +get(warehouse, 'id') === +idWarehouse))
                    invoicingWarehouse = true
                } else
                  invoicingWarehouse = true

                const addItem = invoicingWarehouse && get(item, 'type', null) !== 'variantParent';

                if (addItem && get(item, 'status') === 'active') {
                  await put(item)
                  dispatch(refresh())
                }
              } catch {
              }
            }

            putItem(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnCreateItemError(!onCreateItemError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onCreateItemError, dispatch, idCompany, station, getItem])

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onUpdateItemNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const updateItem = async data => {
              try {
                const { idWarehouse } = station;
                const itemId = get(data, 'value.data.onUpdateItemNotify.id', null);
                const item = await getItem(itemId)
                const warehouses = get(item, 'inventory.warehouses', null);

                let invoicingWarehouse = false

                if (!!warehouses) {
                  if (!!warehouses.find(warehouse => +get(warehouse, 'id') === +idWarehouse))
                    invoicingWarehouse = true
                } else
                  invoicingWarehouse = true

                const addItem = invoicingWarehouse && get(item, 'type', null) !== 'variantParent';

                if (addItem) {
                  if (get(item, 'status') === 'active') {
                    await put(item)
                    dispatch(updateItemsGlobal(item))
                  } else {
                    await remove(+itemId)
                    dispatch(removeItemsGlobal(+itemId))
                  }
                  dispatch(refresh())
                }
              } catch {
              }
            }

            updateItem(data);
          },
          error: () =>
            setTimeout(() => {
              if (!!ref.current)
                setOnUpdateItemError(!onUpdateItemError)
            }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onUpdateItemError, dispatch, idCompany, station, getItem])

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onItemQuantityChangeNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const updateItemQuantity = async data => {
              try {
                const { idWarehouse } = station;
                const { id: itemId, quantity, warehouse: itemWarehouse } = get(data, "value.data.onItemQuantityChangeNotify");

                let isItemWarehouse = idWarehouse === parseInt(itemWarehouse);

                if (isItemWarehouse) {
                  await dispatch(changeItemInventory({ id: parseInt(itemId), quantity }));
                  dispatch(refresh())
                }
              } catch {
              }
            }
            updateItemQuantity(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnItemQuantityChangeError(!onItemQuantityChangeError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onItemQuantityChangeError, dispatch, idCompany, station])

  useEffect(() => {
    let subsciption = null
    if (networkStatus === 'online') {
      subsciption = API.graphql(graphqlOperation(subscriptions.onDeleteItemNotify, {
        channel: `pos_${idCompany}`
      }))
        .subscribe({
          next: (data) => {
            const removeItem = async data => {
              try {
                const itemId = get(data, 'value.data.onDeleteItemNotify.id', null);

                await remove(+itemId)
                dispatch(removeItemsGlobal(+itemId))
                dispatch(refresh())
              } catch {
              }
            }

            removeItem(data);
          },
          error: () => setTimeout(() => {
            if (!!ref.current)
              setOnDeleteItemError(!onDeleteItemError)
          }, 60000)
        })
    }
    return () => {
      if (subsciption)
        subsciption.unsubscribe();
    }
  }, [networkStatus, onDeleteItemError, dispatch, idCompany])

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

  return null;
}

export default ItemsSns;