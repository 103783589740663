/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allUsers = /* GraphQL */ `
  query AllUsers {
    allUsers {
      id
      idGlobal
      name
      lastName
      email
      role
      status
      position
      language
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      channel
      id
      idGlobal
      company {
        id
        name
        identification
        phone
        website
        economicActivity
        economicActivities {
          id
          code
          value
          main
        }
        email
        regime
        ivaCondition
        applicationVersion
        registryDate
        timezone
        profile
        decimalPrecision
        multitax
        isActiveNoIvaDay
        showNoIvaDaysOnItems
        legalRepresentative {
          name
          identification
          email
        }
        mandateSignatureUrl
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        currency {
          code
          symbol
          exchangeRate
          name
          status
        }
        multicurrency
        decimalSeparator
        invoicePreferences {
          defaultAnotation
          defaultTermsAndConditions
        }
        logo
        origin
        kindOfPerson
        nameObject {
          firstName
          lastName
        }
        identificationObject {
          type
          number
          kindOfPerson
          dv
          nationalityKindOfPerson
        }
        seenTutorialWizard
        firstPOSInvoice
        subscription
        settings {
          canStampInvoices
          electronicInvoicing
          canCreateAiuInvoice
          canCreateExportInvoice
          showSaleTickets
          isActiveAutomationElectronicInvoice
          automationElectronicInvoiceIdResolution
          barcode
        }
        localSettings {
          idCategoryDefaultIncome
          idCategoryDefaultExpense
          shiftsEnabled
          shiftsConfigured
          multicurrencyEnabled
          automationFEEnabled
          showReminder5UVT
          ticketItemsDescription
          ticketCompanyLogo
          ticketUnitPrice
          printFormat
          printTemplate
          printAlignment
          printRightMargin
          printLeftMargin
          customPhrase
          imageOrQr
          notifications
          printItemFullLine
          tradeName
          printUnitOfMeasure
          printTotalLines
          showWompiIntegrationAvailable
          taxesIncludedInTotal
          showNumerationBlockNotification
          seenTutorialWizardPOS
        }
        providers
        numberIibb
        conditionIibb
        activityStartDate
        monthIncome {
          income
          invoices
        }
        numberIibb
        conditionIibb
        activityStartDate
        dateFormat
        employeesNumber
        sector
        tradeName
        fiscalResponsabilities
        firstInvoiceCreated
        userNotifications {
          notifications
        }
      }
      hubspot {
        id
        cookieIdentify
        cookieMessages
        token
        tokenExpiration
      }
      membership {
        planName
        planKeyword
        dateStart
        dateEnd
        nextPaymentDate
        payRetryDate
        isPaid
      }
      permissions
      name
      lastName
      email
      role
      status
      position
      language
      seenWelcome
      seenNoIVADayNotification
      seenColombianNormChangeNotification
    }
  }
`;
export const getUserHubspot = /* GraphQL */ `
  query GetUserHubspot {
    getUserHubspot {
      channel
      id
      hubspot {
        id
        cookieIdentify
        cookieMessages
        token
        tokenExpiration
      }
      permissions
      name
      lastName
      email
      role
      sector
      status
      position
      language
    }
  }
`;
export const allStations = /* GraphQL */ `
  query AllStations {
    allStations {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const getStation = /* GraphQL */ `
  query GetStation($id: ID!) {
    getStation(id: $id) {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const getStationUsers = /* GraphQL */ `
  query GetStationUsers($idStation: Int!) {
    getStationUsers(idStation: $idStation) {
      id
      idCompany
      idUser
      idStation
    }
  }
`;
export const allShifts = /* GraphQL */ `
  query AllShifts(
    $idStation: Int!
    $batch: BatchInput
    $includeMetadata: Boolean
    $filter: ShiftFilter
  ) {
    allShifts(
      idStation: $idStation
      batch: $batch
      includeMetadata: $includeMetadata
      filter: $filter
    ) {
      data {
        channel
        id
        idLocal
        idCompany
        idStation
        start
        end
        startingAmount
        transfer
        refunds
        cash
        debit
        credit
        inPayment
        outPayment
        observations
        creditSales
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const getShiftStatus = /* GraphQL */ `
  query GetShiftStatus($idStation: Int!) {
    getShiftStatus(idStation: $idStation) {
      id
      idLocal
      start
      status
      startingAmount
    }
  }
`;
export const getShift = /* GraphQL */ `
  query GetShift($id: Int!) {
    getShift(id: $id) {
      end
    }
  }
`;
export const getShiftPayments = /* GraphQL */ `
  query GetShiftPayments(
    $id: Int!
    $idStation: Int!
    $type: String!
    $batch: BatchInput
    $includeMetadata: Boolean
  ) {
    getShiftPayments(
      id: $id
      idStation: $idStation
      type: $type
      batch: $batch
      includeMetadata: $includeMetadata
    ) {
      data {
        cash
        debit
        credit
        transfer
        sales
        currencies
        inPayment
        outPayment
        refunds
        creditSales
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allInvoices = /* GraphQL */ `
  query AllInvoices(
    $idStation: Int!
    $batch: BatchInput
    $includeMetadata: Boolean
  ) {
    allInvoices(
      idStation: $idStation
      batch: $batch
      includeMetadata: $includeMetadata
    ) {
      data {
        channel
        id
        idStation
        idLocal
        idShift
        totalReceived
        cashReturned
        anotation
        balance
        barCodeContent
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        currency {
          code
          symbol
          exchangeRate
          name
          status
        }
        economicActivity
        endDateService
        date
        datetime
        dueDate
        items {
          id
          description
          discount
          importRequest
          name
          price
          quantity
          reference
          remission
          tax {
            id
            name
            percentage
            description
            type
            status
          }
          total
          applyNoIvaDays
          productKey
          unit
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        observations
        operationType
        payments {
          channel
          id
          idStation
          idLocal
          idShift
          number
          date
          amount
          bankAccount {
            id
            name
            description
            initialBalance
            initialBalanceDate
            number
            status
            type
          }
          categories {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          client {
            channel
            id
            email
            fax
            identification
            kindOfPerson
            mobile
            name
            phonePrimary
            phoneSecondary
            regime
            ivaCondition
            observations
            statementAttached
            status
          }
          costCenter {
            id
            code
            name
            description
            status
          }
          numberTemplate {
            id
            name
            prefix
            nextInvoiceNumber
            maxInvoiceNumber
            invoiceText
            isDefault
            status
            autoincrement
            isElectronic
            documentType
            branchOffice
            branchOfficeCode
            startDate
            endDate
            resolutionNumber
            subDocumentType
            number
            text
            fullNumber
            formattedNumber
            secondaryNumber
          }
          paymentMethod
          observations
          anotation
          status
          type
        }
        paymentMethod
        paymentForm
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        retentions {
          id
          name
          percentage
          amount
        }
        saleConcept
        saleCondition
        saleType
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        stamp {
          uuid
          satSeal
          stampDate
          cfdSeal
          cufe
          cae
          caeDueDate
          caea
          caeaDueDate
          satCertificateNumber
          expeditionPlace
          certificateNumber
          datetime
          date
          paymentMethod
          accountNumber
          legalStatus
          entityResponse
          electronicInvoicingVersion
          barCodeContent
          generateStamp
          mode
          fiscalQrUrl
          securityCode
          url
          warnings
          observations
          authorizationNumber
          authorizationDate
        }
        stampFiles
        startDateService
        status
        termsConditions
        type
        total
        totalPaid
        warehouse {
          channel
          id
          isDefault
          name
          observations
          status
          costCenter {
            id
            code
            name
            description
            status
          }
          address
        }
        monthlyIncome
        monthlyIncomeLimit
        firstInvoice
        printHelp
        localCreationDatetime
        createdAt
        saleTicketHasItemsWithNoIvaDays
        monthIncome {
          income
          invoices
        }
        paymentType
        decimalPrecision
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const checkCreationInPOS = /* GraphQL */ `
  query checkCreationInPOS($invoicesIDs: String) {
    checkCreationInPOS(invoicesIDs: $invoicesIDs)
}
`;
export const getClientInvoices = /* GraphQL */ `
  query GetClientInvoices(
    $batch: BatchInput
    $includeMetadata: Boolean
    $clientName: String
  ) {
    getClientInvoices(
      batch: $batch
      includeMetadata: $includeMetadata
      clientName: $clientName
    ) {
      data {
        channel
        id
        idStation
        idLocal
        idShift
        totalReceived
        cashReturned
        anotation
        balance
        barCodeContent
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        currency {
          code
          symbol
          exchangeRate
          name
          status
        }
        economicActivity
        endDateService
        date
        datetime
        dueDate
        items {
          id
          description
          discount
          importRequest
          name
          price
          quantity
          reference
          remission
          tax {
            id
            name
            percentage
            description
            type
            status
          }
          total
          applyNoIvaDays
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        observations
        operationType
        payments {
          channel
          id
          idStation
          idLocal
          idShift
          number
          date
          amount
          bankAccount {
            id
            name
            description
            initialBalance
            initialBalanceDate
            number
            status
            type
          }
          categories {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          client {
            channel
            id
            email
            fax
            identification
            kindOfPerson
            mobile
            name
            phonePrimary
            phoneSecondary
            regime
            ivaCondition
            observations
            statementAttached
            status
          }
          costCenter {
            id
            code
            name
            description
            status
          }
          numberTemplate {
            id
            name
            prefix
            nextInvoiceNumber
            maxInvoiceNumber
            invoiceText
            isDefault
            status
            autoincrement
            isElectronic
            documentType
            branchOffice
            branchOfficeCode
            startDate
            endDate
            resolutionNumber
            subDocumentType
            number
            text
            fullNumber
            formattedNumber
            secondaryNumber
          }
          paymentMethod
          observations
          anotation
          status
          type
        }
        paymentMethod
        paymentForm
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        retentions {
          id
          name
          percentage
          amount
        }
        saleConcept
        saleCondition
        saleType
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        stamp {
          uuid
          satSeal
          stampDate
          cfdSeal
          cufe
          cae
          caeDueDate
          caea
          caeaDueDate
          satCertificateNumber
          expeditionPlace
          certificateNumber
          datetime
          date
          paymentMethod
          accountNumber
          legalStatus
          entityResponse
          electronicInvoicingVersion
          barCodeContent
          generateStamp
          mode
          fiscalQrUrl
          authorizationNumber
          authorizationDate
        }
        startDateService
        status
        termsConditions
        type
        total
        totalPaid
        warehouse {
          channel
          id
          isDefault
          name
          observations
          status
          costCenter {
            id
            code
            name
            description
            status
          }
          address
        }
        monthlyIncome
        monthlyIncomeLimit
        firstInvoice
        printHelp
        localCreationDatetime
        createdAt
        saleTicketHasItemsWithNoIvaDays
        monthIncome {
          income
          invoices
        }
        paymentType
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allRefunds = /* GraphQL */ `
  query AllRefunds(
    $idStation: Int!
    $batch: BatchInput
    $includeMetadata: Boolean
  ) {
    allRefunds(
      idStation: $idStation
      batch: $batch
      includeMetadata: $includeMetadata
    ) {
      data {
        channel
        id
        idLocal
        idStation
        idShift
        anotation
        balance
        barCodeContent
        cause
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        currency {
          code
          symbol
          exchangeRate
          name
          status
        }
        economicActivity
        endDateService
        date
        dateTime
        dueDate
        invoices {
          id
          prefix
          number
          date
          dueDate
          amount
          total
          balance
          stamp {
            cufe
          }
        }
        items {
          id
          description
          discount
          importRequest
          name
          price
          quantity
          reference
          remission
          tax {
            id
            name
            percentage
            description
            type
            status
          }
          total
          applyNoIvaDays
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        refunds {
          id
          number
          date
          account {
            id
            name
            description
            initialBalance
            initialBalanceDate
            number
            status
            type
          }
          amount
          observations
        }
        saleConcept
        saleCondition
        saleType
        stamp {
          uuid
          satSeal
          stampDate
          cfdSeal
          cufe
          cae
          caeDueDate
          caea
          caeaDueDate
          satCertificateNumber
          expeditionPlace
          certificateNumber
          datetime
          date
          paymentMethod
          accountNumber
          legalStatus
          entityResponse
          electronicInvoicingVersion
          barCodeContent
          generateStamp
          mode
          fiscalQrUrl
          authorizationNumber
          authorizationDate
          warnings
        }
        startDateService
        status
        termsConditions
        type
        total
        totalApplied
        warehouse {
          channel
          id
          isDefault
          name
          observations
          status
          costCenter {
            id
            code
            name
            description
            status
          }
          address
        }
        monthIncome {
          income
          invoices
        }
        note
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allItems = /* GraphQL */ `
  query AllItems(
    $batch: BatchInput
    $includeMetadata: Boolean
    $type: String
    $status: String
    $idWarehouse: Int
  ) {
    allItems(
      batch: $batch
      includeMetadata: $includeMetadata
      type: $type
      status: $status
      idWarehouse: $idWarehouse
    ) {
      data {
        channel
        id
        name
        description
        reference
        price {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        category {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        inventory {
          unit
          availableQuantity
          unitCost
          initialQuantity
          warehouses {
            id
            name
            observations
            isDefault
            address
            status
            initialQuantity
            availableQuantity
            minQuantity
            maxQuantity
            negativeSale
          }
        }
        status
        type
        itemType
        productKey
        tariffHeading
        brand
        model
        itemCategory {
          channel
          id
          name
          description
          status
          image {
            id
            name
            url
          }
        }
        hasNoIvaDays
        customFields {
          id
          name
          key
          value
          description
          defaultValue
          resourceType
          status
          type
          settings
          options
        }
        images {
          id
          name
          url
        }
        kitWarehouse {
          id
          name
          observations
          isDefault
          address
          status
          initialQuantity
          availableQuantity
          minQuantity
          maxQuantity
          negativeSale
        }
        subitems
        variantAttributes
        itemVariants
        predial
        taxCondition {
          value
          description
        }
        deletable
        editable
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const getFavoriteItems = /* GraphQL */ `
  query GetFavoriteItems($batch: BatchInput, $includeMetadata: Boolean) {
    getFavoriteItems(batch: $batch, includeMetadata: $includeMetadata) {
      data {
        id
        isFavorite
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const getClientsUpdated = /* GraphQL */ `
  query GetClientsUpdated($lastSyncDatetime: String, $batch: BatchInput, $includeMetadata: Boolean) {
    getClientsUpdated(lastSyncDatetime: $lastSyncDatetime, batch: $batch, includeMetadata: $includeMetadata) {
      data {
        id
        lastUpdateDatetime
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allItemCategories = /* GraphQL */ `
  query AllItemCategories($batch: BatchInput, $includeMetadata: Boolean) {
    allItemCategories(batch: $batch, includeMetadata: $includeMetadata) {
      data {
        channel
        id
        name
        description
        status
        image {
          id
          name
          url
        }
        deletable
        editable
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allClients = /* GraphQL */ `
  query AllClients(
    $batch: BatchInput
    $includeMetadata: Boolean
    $filter: BasicClientFilter
  ) {
    allClients(
      batch: $batch
      includeMetadata: $includeMetadata
      filter: $filter
    ) {
      data {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const searchClientByID = /* GraphQL */ `
  query SearchClientByID($filter: ClientFilter) {
    searchClientByID(filter: $filter)
  }
`;
export const allPriceLists = /* GraphQL */ `
  query AllPriceLists($batch: BatchInput, $includeMetadata: Boolean) {
    allPriceLists(batch: $batch, includeMetadata: $includeMetadata) {
      data {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
        editable
        deletable
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allBanks = /* GraphQL */ `
  query AllBanks($batch: BatchInput, $includeMetadata: Boolean) {
    allBanks(batch: $batch, includeMetadata: $includeMetadata) {
      data {
        id
        name
        description
        initialBalance
        initialBalanceDate
        number
        status
        type
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allCostCenters = /* GraphQL */ `
  query AllCostCenters($batch: BatchInput, $includeMetadata: Boolean) {
    allCostCenters(batch: $batch, includeMetadata: $includeMetadata) {
      data {
        id
        code
        name
        description
        status
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allNumberTemplates = /* GraphQL */ `
  query AllNumberTemplates {
    allNumberTemplates {
      id
      name
      prefix
      nextInvoiceNumber
      maxInvoiceNumber
      invoiceText
      isDefault
      status
      autoincrement
      isElectronic
      documentType
      branchOffice
      branchOfficeCode
      startDate
      endDate
      resolutionNumber
      subDocumentType
      number
      text
      fullNumber
      formattedNumber
      secondaryNumber
    }
  }
`;
export const allCategories = /* GraphQL */ `
  query AllCategories {
    allCategories
  }
`;
export const getContactAccountingCategories = /* GraphQL */ `
  query GetContactAccountingCategories {
    getContactAccountingCategories {
      contacts {
        debtsToPayProviders {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
            parameterizedMovement
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
          parameterizedMovement
        }
        receivableAccounts {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
            parameterizedMovement
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
          parameterizedMovement
        }
      }
    }
  }
`;
export const allPayments = /* GraphQL */ `
  query AllPayments(
    $idStation: Int!
    $batch: BatchInput
    $includeMetadata: Boolean
  ) {
    allPayments(
      idStation: $idStation
      batch: $batch
      includeMetadata: $includeMetadata
    ) {
      data {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allProviderPayments = /* GraphQL */ `
  query AllProviderPayments($idStation: Int!) {
    allProviderPayments(idStation: $idStation) {
      channel
      id
      external_id
      merchant_provider_id
      terminal_id
      amount
      created_at
      metadata
      origen
      provider
      requestBody
      resource
      resource_id
      resource_type
      response_body
      state
      updated_at
      code
      type
    }
  }
`;
export const getProviderPayment = /* GraphQL */ `
  query GetProviderPayment($payment: BasicProviderPaymentInput) {
    getProviderPayment(payment: $payment) {
      channel
      id
      external_id
      merchant_provider_id
      terminal_id
      amount
      created_at
      metadata
      origen
      provider
      requestBody
      resource
      resource_id
      resource_type
      response_body
      state
      updated_at
      code
      type
    }
  }
`;
export const allTaxes = /* GraphQL */ `
  query AllTaxes {
    allTaxes {
      id
      name
      percentage
      description
      type
      status
    }
  }
`;
export const allCurrencies = /* GraphQL */ `
  query AllCurrencies {
    allCurrencies {
      code
      symbol
      exchangeRate
      name
      status
    }
  }
`;
export const allAlegraCurrencies = /* GraphQL */ `
  query AllAlegraCurrencies($codesExcludes: String) {
    allAlegraCurrencies(codesExcludes: $codesExcludes) {
      code
      symbol
      exchangeRate
      name
      status
    }
  }
`;
export const allVariants = /* GraphQL */ `
  query AllVariants {
    allVariants {
      id
      name
      status
      editable
      deletable
      options {
        id
        value
        editable
      }
    }
  }
`;
export const allWarehouses = /* GraphQL */ `
  query AllWarehouses($batch: BatchInput, $includeMetadata: Boolean) {
    allWarehouses(batch: $batch, includeMetadata: $includeMetadata) {
      data {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
        editable
        deletable
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allSellers = /* GraphQL */ `
  query AllSellers {
    allSellers {
      channel
      id
      name
      identification
      observations
      status
    }
  }
`;
export const allPaymentTerms = /* GraphQL */ `
  query AllPaymentTerms {
    allPaymentTerms {
      channel
      id
      days
      name
    }
  }
`;
export const allCustomFields = /* GraphQL */ `
  query AllCustomFields($batch: BatchInput, $includeMetadata: Boolean) {
    allCustomFields(batch: $batch, includeMetadata: $includeMetadata) {
      data {
        id
        name
        key
        value
        description
        defaultValue
        resourceType
        status
        type
        settings
        options
        deletable
        editable
      }
      metadata {
        total
        from
        to
        limit
        hasNextPage
      }
    }
  }
`;
export const allUnits = /* GraphQL */ `
  query AllUnits($applicationVersion: String) {
    allUnits(applicationVersion: $applicationVersion) {
      id
      code
      key
      unitType
      value
    }
  }
`;
export const allItemCodes = /* GraphQL */ `
  query AllItemCodes($applicationVersion: String, $filter: ItemCodeFilter) {
    allItemCodes(applicationVersion: $applicationVersion, filter: $filter) {
      id
      key
      tax
      name
      deprecated
    }
  }
`;
export const allCreditNoteTypes = /* GraphQL */ `
  query AllCreditNoteTypes(
    $applicationVersion: String
    $filter: CreditNoteTypeFilter
  ) {
    allCreditNoteTypes(
      applicationVersion: $applicationVersion
      filter: $filter
    ) {
      key
      value
      electronicInvoicingVersion
    }
  }
`;
export const allRegimes = /* GraphQL */ `
  query AllRegimes($applicationVersion: String) {
    allRegimes(applicationVersion: $applicationVersion) {
      id
      key
      value
    }
  }
`;
export const allSaleConditions = /* GraphQL */ `
  query AllSaleConditions($applicationVersion: String) {
    allSaleConditions(applicationVersion: $applicationVersion) {
      id
      key
      value
    }
  }
`;
export const allCompanySectors = /* GraphQL */ `
  query AllCompanySectors($applicationVersion: String, $lang: String) {
    allCompanySectors(applicationVersion: $applicationVersion, lang: $lang) {
      id
      key
      value
    }
  }
`;
export const allPaymentMethods = /* GraphQL */ `
  query AllPaymentMethods($applicationVersion: String) {
    allPaymentMethods(applicationVersion: $applicationVersion) {
      id
      code
      key
      value
    }
  }
`;
export const allEconomicActivities = /* GraphQL */ `
  query AllEconomicActivities($applicationVersion: String) {
    allEconomicActivities(applicationVersion: $applicationVersion) {
      id
      code
      value
    }
  }
`;
export const getCompanyValues = /* GraphQL */ `
  query GetCompanyValues {
    getCompanyValues
  }
`;
export const getCurrentMonthIncome = /* GraphQL */ `
  query GetCurrentMonthIncome {
    getCurrentMonthIncome {
      income
      invoices
    }
  }
`;
export const getDailyReport = /* GraphQL */ `
  query GetDailyReport($date: String, $type: String, $resolution_ids: String) {
    getDailyReport(date: $date, type: $type, resolution_ids: $resolution_ids) {
      report
    }
  }
`;
