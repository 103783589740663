import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';

import { handleError } from '../../../utils/errors'
import { openModal } from '../../../reducers/modals'
import { hasPermissionTo } from "../../../selectors/auth";
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { useVariantsQuery } from './queries';
import { Filters as FiltersClass } from '../../../utils/filter';

import Table from "../../common/GrayTable";
import Header from '../../settings/common/Header';
import Actions from '../Variants/Actions';
import Tooltip from "../../common/Tooltip";
import Plus from '../../svg/Plus';
import { useDeleteVariant, useUpdateVariant } from './mutations';
import { useIsMutating } from '@tanstack/react-query';

const filtersInstance = new FiltersClass({
  metadata: true,
  order_field: 'name',
  order_direction: 'ASC',
  fields: 'editable,deletable'
})

const parseOptions = options => {
  const parsedOptions = options.map((option) => {
    return option.value;
  })
  return parsedOptions;
}

const Variants = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const ref = useRef(null);
  const [error, setError] = useState();
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    ...filtersInstance.getFilters()
  })
  filtersInstance.setUpdater(setFilters);

  const { mutate: deleteVariant } = useDeleteVariant();
  const { mutate: updateVariant } = useUpdateVariant();

  const isMutating = useIsMutating();

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageView',
      'virtualPageURL': '/inventory/variants',
      'virtualPageTitle': 'Variants',
    });

    return () => ref.current = false;
  }, []);

  const { data, isFetching, refetch, error: queryError, isError } = useVariantsQuery(filters);

  useEffect(() => {
    if (isError)
      setError(handleError(queryError, I18n.get('variantsLoadError', 'Sucedió un error cargando las variantes')))
  }, [queryError, isError])

  useEffect(() => {
    filtersInstance.updatePagination(tableIndicators);
  }, [tableIndicators])

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('name', 'Nombre')),
        sortable: false,
        accessor: 'name',
        Cell: ({ value, row }) => {
          return (
            <p
              className="h4 text-primary text-truncate pointer styled-hover"
              onClick={() => (can("edit", "categories") && get(row, 'original.editable', false)) ? dispatch(openModal({
                modal: 'variant', params: {
                  variant: { ...row.original },
                  onRefresh: () => refetch(),
                },
              })) : null}
              hovercontent={value}
            >
              {value}
            </p>
          )
        }
      },
      {
        Header: capitalize(I18n.get('options', 'Opciones')),
        accessor: 'options',
        Cell: ({ value }) => (
          <p className="h5 text-muted text-truncate">
            {parseOptions(value).join(", ")}
          </p>
        )
      },
      {
        Header: capitalize(I18n.get('actions', 'Acciones')),
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => (
          <Actions
            variant={row.original}
            onRefresh={() => refetch()}
            onView={() => dispatch(openModal({
              modal: 'variant', params: {
                variant: { ...row.original },
                onRefresh: () => refetch(),
              }
            }))}
            deleteVariant={deleteVariant}
            updateVariant={updateVariant}
          />
        )
      },
    ], [refetch, dispatch, can, deleteVariant, updateVariant]
  )

  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Header
          title={<p className="text-title-secundary">{I18n.get('variants', 'Variantes')}</p>}
          subtitle={
            <p className="h5 text-muted text-capitalize-first">
              {I18n.get('variantsSubtitle', 'Agrega atributos como talla, color u otra cualidad para categorizar tus productos.')}
              <a href="https://ayuda.alegra.com/es/organiza-tus-%C3%ADtems-de-venta-con-la-funci%C3%B3n-variantes-en-alegra-pos" target="_blank" rel="noreferrer">
                {I18n.get("knowMore", "Saber más")}
              </a>
            </p>
          }
          actions={(
            <div className="d-flex">
              <Tooltip
                tooltipActive={!can("add", "variant-attributes")}
                overlay={<p className="text-capitalize-first">
                  {I18n.get("userNotAllowed.variant-attributes.add", "no tienes permisos para agregar variantes")}
                </p>}
              >
                <button
                  type="button"
                  disabled={!can("add", "variant-attributes") || !connectionStatus}
                  className="btn btn-submit-large d-flex justify-content-center align-items-center"
                  onClick={() => {
                    dispatch(openModal({
                      modal: 'variant', params: {
                        onRefresh: () => refetch(),
                      }
                    }))
                  }}
                >
                  <Plus className="icon icon-white mr-2" />
                  {I18n.get('newVariant', 'Nueva variante')}
                </button>
              </Tooltip>
            </div>
          )}
        />
        <div className="shadowp-4">
          <Table
            loading={isFetching || Boolean(isMutating)}
            data={data?.data ?? []}
            total={data?.metadata?.total || 0}
            error={error}
            onRefresh={refetch}
            columns={columns}
            onFetchData={setTableIndicators}
            _pageSize={tableIndicators.pageSize}
            noDataText={(
              <div className="text-center">
                <p>
                  {capitalize(I18n.get('variantsNoDataText', 'Crea tu primera variante sin pararte de la silla. 🎨'))}
                </p>

                <Tooltip
                  tooltipActive={!can("add", "variant-attributes")}
                  overlay={<p className="text-capitalize-first">
                    {I18n.get("userNotAllowed.variant-attributes.add", "no tienes permisos para agregar variantes")}
                  </p>}
                >
                  <button
                    type="button"
                    className="btn btn-submit mr-3"
                    onClick={() => dispatch(openModal({
                      modal: 'variant', params: {
                        onRefresh: () => refetch(),
                      }
                    }))}
                  >
                    {I18n.get('newVariant', 'Nueva variante')}
                  </button>
                </Tooltip>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default Variants;