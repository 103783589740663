// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="56" 
    height="55" 
    viewBox="0 0 56 55" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" clipRule="evenodd"
      d="M31.2698 8.21855C30.3468 8.0884 29.4036 8.02109 28.4447 8.02109C17.3702 8.02109 8.39258 16.9987 8.39258 28.0732C8.39258 39.1476 17.3702 48.1253 28.4447 48.1253C39.5191 48.1253 48.4967 39.1476 48.4967 28.0732C48.4967 26.4778 48.3104 24.926 47.9584 23.4383L44.9872 26.5151C45.0349 27.0281 45.0592 27.5478 45.0592 28.0732C45.0592 37.2492 37.6206 44.6878 28.4447 44.6878C19.2687 44.6878 11.8301 37.2492 11.8301 28.0732C11.8301 18.9996 19.1036 11.6248 28.1382 11.4614L31.2698 8.21855ZM35.325 24.6249L36.1153 30.4203L39.5001 30.9777L37.9445 19.5698L26.5365 18.0141L27.094 21.399L32.8981 22.1904L21.1814 33.9072C20.5101 34.5784 20.5101 35.6667 21.1814 36.3379C21.8526 37.0091 22.9408 37.0091 23.612 36.3379L35.325 24.6249Z"
    />
  </svg>
)
