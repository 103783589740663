import React from 'react'
import { I18n } from '@aws-amplify/core';
import { isAdmin as isAdminSelector} from '../../../selectors/auth';
import { useSelector } from 'react-redux';

const Bottom = ({onClose, onConfirm}) => {
    const isAdmin = useSelector(isAdminSelector);
    
    return (
        <div className="modal__bottom w-100 p-3 pl-5 d-flex justify-content-end align-items-center shadow">
            <button 
            type="button" 
            className="btn btn-cancel btn-no-border text-capitalize-first mr-4" 
            onClick={() => onClose()}
            >
                {isAdmin 
                    ? I18n.get("reminder5UVT.admin.cancel", "Omitir por ahora")
                    : I18n.get("reminder5UVT.user.cancel", "Omitir")
                }
            </button>
            <button
                type="button"
                className="btn btn-submit text-btn-normal text-capitalize-first"
                onClick={() => onConfirm()}
            >
                {isAdmin 
                    ? I18n.get("reminder5UVT.admin.continue", "Cumplir con la regulación") 
                    : I18n.get("reminder5UVT.user.continue", "Conocer más sobre la regulación")
                }
            </button>
        </div>
    )
}

export default Bottom;