import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from '@sentry/react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import Amplify from '@aws-amplify/core';
import Modal from 'react-modal';
import Cookie from 'js-cookie';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { Chart, registerables } from 'chart.js';
import { es } from 'date-fns/esm/locale';
import { registerLocale } from 'react-datepicker';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import 'react-datepicker/dist/react-datepicker.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'flag-icons/sass/flag-icons.scss';
import './styles/app.scss';
import App from './components/App';

import Support from './components/support/HubspotChat';
import reportWebVitals from './reportWebVitals';
import Sentry from './sentry';
import configureStore from './store';
import { createIDBPersister } from './idbPersister';

Chart.register(...registerables);

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);
registerLocale('es', es);

const history = createBrowserHistory();

const persister = createIDBPersister();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

const cookie = Cookie.get(process.env.REACT_APP_ALEGRA_COOKIE);

if (!!cookie) {
  Amplify.configure({
    aws_project_region: process.env.REACT_APP_ProjectRegion,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GraphQLAPIURL,
    aws_appsync_region: process.env.REACT_APP_APIRegion,
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: process.env.REACT_APP_GraphQLAPIKey,
    graphql_headers: () => ({
      token: `Basic ${cookie}`,
    }),
  });

  Sentry.start({ history });

  Modal.setAppElement('#modal-root');

  const root = createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <Router history={history}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
          onSuccess={() => {
            queryClient.resumePausedMutations();
          }}
        >
          <Provider store={configureStore()}>
            <ErrorBoundary>
              <>
                <App />
                <Support />
              </>
            </ErrorBoundary>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        </PersistQueryClientProvider>
      </Router>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
} else {
  window.location.replace(
    `${process.env.REACT_APP_ALEGRA}user/login/pos?url=${window.location.href}`
  );
}
