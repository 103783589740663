import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

export const validate = values => {
  let errors = {};
  
  if (!get(values, 'base'))
    errors.base = I18n.get('requiredField', 'este campo es obligatorio')

  if (!get(values, 'baseBank.id'))
    errors.baseBank = I18n.get('requiredField', 'este campo es obligatorio')

  return errors;
}

export const transform = values => {
  return {
    idStation: get(values, 'idStation', null),
    startingAmount: get(values, 'base', null),
    baseBank: get(values, 'baseBank.id', null),
  }
}