// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M6.375 3.784h-3v3.133h3V3.784zm14.5 0H8.125v3.133h12.75V3.784zm0 6.573H8.125v3.132h12.75v-3.132zm-12.75 6.572h12.75v3.134H8.125v-3.134zm-4.75-6.572h3v3.132h-3v-3.132zm3 6.572h-3v3.134h3v-3.134z"
    />
  </svg>
)