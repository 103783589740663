// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="24" 
    height="24" 
    viewBox="0 0 48 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M9.109,12.952H5.309v26.599c0,2.09,1.713,3.801,3.801,3.801h26.6v-3.801h-26.6V12.952z M39.508,5.352H16.71
      c-2.092,0-3.802,1.708-3.802,3.8v22.799c0,2.09,1.71,3.799,3.802,3.799h22.798c2.088,0,3.801-1.709,3.801-3.799V9.152
      C43.309,7.061,41.596,5.352,39.508,5.352z M37.607,22.451h-7.6v7.6h-3.799v-7.6h-7.6v-3.8h7.6v-7.6h3.799v7.6h7.6V22.451z"
    />
  </svg>
)