import React from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { clear } from '../../../../reducers/editInvoice';
import CustomizeSVG from '../../../svg/Customize';
import TrashSVG from '../../../svg/Trash';

const Header = ({ openSettings }) => {
  const dispatch = useDispatch()

  return (
    <div className="refund-top__header d-flex w-100 bg-secondary text-white text-uppercase align-items-center justify-content-between">
      <button 
        type="button"
        className="btn button-transparent"
        onClick={() => openSettings()}
      >
        <CustomizeSVG className="icon-white"/>
      </button>

      <button 
        type="button"
        className="btn button-transparent"
        onClick={() => dispatch(clear())}
      >
        <TrashSVG className="icon-white"/>
      </button>
    </div>
  )
}

Header.propTypes = {
  openSettings: PropTypes.func,
}

export default Header;