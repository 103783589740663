// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0366211 15.2613H17.5477L8.79218 0.138062L0.0366211 15.2613ZM9.58813 12.8734H7.99622V11.2815H9.58813V12.8734ZM9.58813 9.68958H7.99622V6.50574H9.58813V9.68958Z" fill="#FF9800" />
  </svg>
)
