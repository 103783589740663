import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get, toLower } from 'lodash'
import { BigNumber } from 'bignumber.js'

import { closeModal, openModal } from '../../../reducers/modals';
import { country as countrySelector, decimalPrecision } from '../../../selectors/company'
import Modal from '../../common/Modal';
import Bottom from './Bottom';
import { replaceAndParse } from '../../../utils';
import { total } from '../../../selectors/activeInvoice';
import { hasPermissionTo } from '../../../selectors/auth';
import { replaceOfflineClient, saveClient } from '../../../reducers/clients';
import { validate, transform, validationPreviousCreation } from '../../forms/basicContact/utils';
import { Form } from 'react-final-form';
import Notification from '../common/Notification';
import Body from './FormBody';
import { setClient } from '../../../reducers/activeInvoice';
import { formError } from '../../../utils/errors';
import { initialValues } from './InitialValues';


const Invoice5UVTNewClient = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.invoice5UVTNewClient.isOpen', false));
  const error = useSelector(state => get(state, 'modals.invoice5UVTNewClient.params.error', null));
  const contact = useSelector(state => get(state, 'modals.invoice5UVTNewClient.params.contact', null));
  const isUpdate = useSelector(state => get(state, 'modals.invoice5UVTNewClient.params.isUpdate', false));
  const decimal = useSelector(decimalPrecision)
  const totalPrice = useSelector(total);
  const country = useSelector(countrySelector);
  const formRef = useRef(null);

  const can = useSelector(hasPermissionTo);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(closeModal({ modal: 'invoice5UVTNewClient' }));
  }

  const handleBack = () => {
    dispatch(closeModal({ modal: 'invoice5UVTNewClient' }));
    dispatch(openModal({ modal: 'invoice5UVT' }));
  }

  const submit = async values => {
    setLoading(true);

    const transformedValues = transform(values, { country, isElectronic: true, contact })
    try {
      validationPreviousCreation(values, { country, isElectronic: true })
      if (!!contact) {
        await dispatch(replaceOfflineClient(contact.id, transformedValues, "basicNewClient", isUpdate, true))
      } else {
        const client = await dispatch(saveClient(transformedValues, "basicNewClient"))
        await dispatch(setClient(client));
      }
      
      setLoading(false);
      dispatch(closeModal({ modal: 'invoice5UVTNewClient' }));
      dispatch(openModal({ modal: 'invoice' }));
    } catch (error) {
      setLoading(false)
      return formError(error, I18n.get('createClientError', 'hubo un error en la creación del contacto'))
    }
  }

  const fmt = {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={replaceAndParse(I18n.get('payAnyDocument', 'pagar {}'), [toLower(I18n.get('invoice', 'factura'))])}
      className="modal__invoice modal__invoice-5uvt-new-client"
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values, { country, isElectronic: true })}
        initialValues={initialValues({contact})}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting, submitError, invalid }) => {
          formRef.current = values;
          return (
            <>
            <form
              noValidate
              className="w-100 h-100 bg-white d-flex flex-column"
              onSubmit={handleSubmit}
            >
              <div className="d-flex flex-column p-3 justify-content-center align-items-center">
                <div className="d-flex align-items-center mt-4">
                  <p className="h4 text-muted text-uppercase">
                    {I18n.get('total', 'total')}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-4 font-weight-bold">
                  <h1>
                    {new BigNumber(totalPrice).toFormat(decimal, fmt)}
                  </h1>
                </div>


                <div className="d-flex align-items-left w-95 border-bottom pb-3">
                  <p className="h4 text-muted text-center">
                    {isUpdate 
                      ? I18n.get('invoice5uvtNewClient.edit.title', 'Completa los datos del cliente para su factura electrónica. ✏️') 
                      : I18n.get('invoice5uvtNewClient.title', 'Nuevo cliente para factura electrónica')
                    }
                  </p>
                </div>

                <Body
                  values={values}
                  form={form}
                  country={country}
                  isElectronic={true}
                  contactCreated={(contact) => {}}
                />
              </div>

              <Notification isOpen={!!submitError} text={submitError} />
              <Notification isOpen={!!error} text={error} />
              <Notification
                isOpen={!can('add', 'contacts')}
                type="warning"
                text={I18n.get('userNotAllowed.contacts.add', 'no tienes permisos para agregar clientes')}
              />

              <Bottom 
                onClose={handleBack}
                disabled={invalid || submitting || loading}
                submitting={submitting || loading}
              />
            </form>
            </>
          )
        }}
        
      </Form>
      
    </Modal>
  )
}

export default Invoice5UVTNewClient;