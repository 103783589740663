// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 23 23" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      fill="#AAD572"
      d="M22.0022 11.5864C22.0022 17.5695 17.152 22.4198 11.1689 22.4198C5.18582 22.4198 0.335571 17.5695 0.335571 11.5864C0.335571 5.60334 5.18582 0.75309 11.1689 0.75309C17.152 0.75309 22.0022 5.60334 22.0022 11.5864ZM15.3052 7.05613L10.0775 14.0265L6.94102 10.89L5.54823 12.2828L10.2906 17.0252L16.881 8.23794L15.3052 7.05613Z"
    />
  </svg>
)
