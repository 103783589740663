import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphqlOperation } from 'aws-amplify';
import { get } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import {
  country as countrySelector,
  membershipSelector,
  notificationsSelector,
} from '../../../selectors/company';
import { APIGraphqlSelector } from '../../../selectors/app';
import { notificationsTransformer } from '../../notifications/utils';
import { sendNewGTMEvent } from '../../../reducers/company';
import { isAfter } from 'date-fns';

const HeaderContext = createContext({
  activeMenu: '',
  setActiveMenu: () => null,
  showTutorial: false,
  closeShowTutorial: () => null,
});

export const HeaderProvider = ({ children }) => {
  const notifications = useSelector(notificationsSelector);
  const country = useSelector(countrySelector);
  const [activeMenu, setMenu] = useState('');
  const membership = useSelector(membershipSelector);
  const [showTutorial, setShowTutorial] = useState(false);
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);

  const availableNotification = useCallback(() => {
    if (
      notifications?.find(
        (notification) => notification[`activate-redesign-tutorial-${country}`]
      )
    )
      return false;

    const dateStart = get(membership, 'dateStart');
    if (isAfter(new Date(dateStart), new Date('2023-08-01'))) {
      return false;
    }

    const membershipPlan = get(membership, 'plan.keyword', '');
    if (
      !membershipPlan ||
      membershipPlan === '' ||
      membershipPlan.includes('demo') ||
      membershipPlan.includes('readonly')
    ) {
      return false;
    }

    return true;
  }, [country, membership, notifications]);

  useEffect(() => {
    function checkNotificationAvailability() {
      if (availableNotification()) {
        setShowTutorial(true);
      }
    }
    checkNotificationAvailability();
  }, [availableNotification]);

  const setActiveMenu = (menu = '', eventName = '') => {
    setMenu(menu);
    if (eventName !== '') {
      dispatch(
        sendNewGTMEvent('pos-topbar-menu-opened', {
          name: eventName,
        })
      );
    }
  };

  const closeShowTutorial = async () => {
    setShowTutorial(false);

    const cookieName = `activate-redesign-tutorial-${country}`;
    if (!availableNotification()) return;

    try {
      const response = await APIGraphql(
        graphqlOperation(
          mutations.updateUser,
          notificationsTransformer(cookieName)
        )
      );
      if (!!get(response, 'data.updateUser.id', null)) {
        dispatch(setUser(get(response, 'data.updateUser')));
      }
    } catch (err) {
      console.log('error while creating notification', err);
    }
  };

  return (
    <HeaderContext.Provider
      value={{ activeMenu, showTutorial, setActiveMenu, closeShowTutorial }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};
