import { uniqueId } from 'lodash';
import { NavigationProvider } from './context';
import NavigationItem from './item';
import PropTypes from 'prop-types';

/**
 * Represents a navigation menu containing a list of navigation items.
 *
 * @component
 * @param {object} props - The properties passed to the NavigationMenu component.
 * @param {object[]} props.items - An array of navigation items to be displayed in the menu.
 * Each item should have the following properties:
 * @param {string} props.items.menuName - The name of the menu this item belongs to.
 * @param {JSX.Element} props.items.icon - The icon element for the navigation item.
 * @param {string} props.items.title - The title or label of the navigation item.
 * @param {function} props.items.action - The action to perform when the item is clicked.
 * @param {string} props.items.to - The target route or URL for the navigation item.
 * @param {object} props.items.extra - Extra information associated with the navigation item.
 * @param {Array} props.items.items - An array of sub-items to be displayed as submenu items.
 * @param {boolean} props.items.onlyInvoicingPlan - Whether the navigation item is available only in invoicing plans.
 * @param {string} props.items.target - The target attribute for the navigation link.
 * @param {JSX.Element} props.items.extraIcon - An extra icon element associated with the navigation item.
 * @param {boolean} props.items.isMenu - Whether the navigation item represents a menu.
 * @param {boolean} props.items.hidden - Whether the navigation item should be hidden.
 * @param {boolean} props.items.layout - Whether the navigation item should be hidden.
 *
 * @returns {JSX.Element} A React element representing the navigation menu.
 */
function NavigationMenu({ items }) {
  return (
    <NavigationProvider>
      <div className='menu-content'>
        <ul>
          {items.map((item, key) => {
            if (item?.hidden) return null;
            return <NavigationItem key={`${uniqueId()}-${key}`} {...item} />;
          })}
        </ul>
      </div>
    </NavigationProvider>
  );
}

NavigationMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string,
      icon: PropTypes.element,
      title: PropTypes.string,
      action: PropTypes.func,
      to: PropTypes.string,
      extra: PropTypes.shape({
        title: PropTypes.string,
        variant: PropTypes.string,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          action: PropTypes.func,
          title: PropTypes.string,
          to: PropTypes.string,
          menuName: PropTypes.string,
          extra: PropTypes.shape({
            title: PropTypes.string,
            variant: PropTypes.string,
          }),
          component: PropTypes.object,
          layout: PropTypes.oneOf(['normal', 'big']),
        })
      ),
      onlyInvoicingPlan: PropTypes.bool,
      target: PropTypes.string,
      extraIcon: PropTypes.element,
      isMenu: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ),
};

export default NavigationMenu;
