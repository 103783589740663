// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.07 14.774c.21-.541-.146-1.11-.71-1.244-.564-.134-1.12.222-1.357.75a5.588 5.588 0 1 1-.978-6.05c.083.09.176.168.277.232l-2.05 1.735 5.535 1.018-.089-5.626-1.838 1.555a7.687 7.687 0 1 0 1.21 7.63z" />
  </svg>
)