import React, { useEffect, useRef, forwardRef } from 'react'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash'
import PropTypes from 'prop-types';
import { useTable, useBlockLayout, usePagination, useRowSelect, } from 'react-table';

import Loading from '../../svg/Loading'
import Pagination from './Pagination';

// La funcion a continuacion fue adaptada de:
// https://github.com/TanStack/table/issues/2988

const getConditionalSelectHeaderCheckboxProps = ({
  headerProps,
  checkIfRowIsSelectable,
  shouldSelectPage = true,
}) => {
  const checkIfAllSelectableRowsSelected = (rows) =>
    rows.filter(checkIfRowIsSelectable).every(row => row.isSelected)

  const isSelectPage =
    shouldSelectPage &&
    headerProps.page
      .filter(checkIfRowIsSelectable)
      .some(row => !row.isSelected)

  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps()

  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0
  const checked = !disabled && (headerProps.rows.some(row => row.isSelected) || checkIfAllSelectableRowsSelected(headerProps.rows));
  const indeterminate = !checked && headerProps.rows.some(row => row.isSelected)

  const onChange = () => {
    if (!isSelectPage && checkIfAllSelectableRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach(row => {
        headerProps.toggleRowSelected(row.id, false)
      })
    } else {
      const rows = isSelectPage ? headerProps.page : headerProps.rows
      rows.forEach(row => {
        const checked = checkIfRowIsSelectable(row)
        headerProps.toggleRowSelected(row.id, checked)
      })
    }
  }
  return {
    ...checkboxProps,
    checked,
    indeterminate,
    onChange,
    disabled,
  }
}

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  )
}
)

const InventoryAdjustmentsTable = ({ columns, data, loading, onFetchData, noDataText, error, setSelectedIds, onRefresh, _pageSize, controlledPageCount, total, multipleActions }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    toggleAllRowsSelected,
    setPageSize,
    rows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: !!onFetchData,
      pageCount: !!controlledPageCount ? controlledPageCount : Math.ceil(total / _pageSize),
      initialState: { pageSize: _pageSize || 10 },
    },
    useBlockLayout,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: (props) => {
            const checkboxProps = getConditionalSelectHeaderCheckboxProps({
              headerProps: props,
              checkIfRowIsSelectable: row => row.original.status !== 'offline'
            })
            return (
              <>
                <IndeterminateCheckbox className="hyphen" {...checkboxProps} title={capitalize(I18n.get('all', 'Todos'))} />
              </>
            )
          },
          Cell: ({ row }) => (
            get(row, 'original.status') !== 'offline' &&
            <>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} title="" />
            </>
          )
          ,
        },
        ...columns,
      ])
    }
  );

  useEffect(() => {
    const fetchData = () => {
      if (!!onFetchData) {
        onFetchData({ pageIndex, pageSize })
      }
    }
    fetchData();
  }, [pageIndex, pageSize, onFetchData]);

  useEffect(() => {
    const idsMap = selectedFlatRows.map((selectedRow) => ({
      ...get(selectedRow, 'original')
    }))
    setSelectedIds(idsMap)
    // eslint-disable-next-line
  }, [selectedFlatRows.length, setSelectedIds]);

  const handleChangeIndex = (event, value) => {
    let page;
    if (event)
      page = event.target.value ? +event.target.value - 1 : 0;
    else
      page = value;
    gotoPage(page);
  };

  const handleChangePageSize = (value) => {
    setPageSize(+value);
  };

  return (
    <>
      <div className="react-table-light w-100 table-responsive position-relative">
        <table {...getTableProps()} className="react-table-light__table table">
          <thead className="react-table-light__head shadow">
            {multipleActions(toggleAllRowsSelected) ?? (
              <tr className="text-muted w-100 d-flex align-items-center pl-4">
                <th>{`${rows.length} ${rows.length === 1 ? I18n.get('result', 'resultado') : I18n.get('results', 'resultados')}`}</th>
              </tr>
            )}
            {/* <button type='button' onClick={() => toggleAllRowsSelected(false)}>Test</button> */}
            {headerGroups.map((headerGroup, index) => (

              <tr key={index} className="w-100" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    className="react-table-light__th text-left h4"
                    style={{ maxWidth: i === 0 ? '40px' : '' }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className={`react-table-light__body w-100 d-block position-relative ${page.length === 0 ? 'no-data' : ''}`}
            {...getTableBodyProps()}
          >
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i} className={`react-table-light__body-tr${row.isSelected ? '-active' : ''} w-100`} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return <td
                      {...cell.getCellProps()}
                      key={index}
                      className="react-table-light__td react-table-light__body-td"
                      style={{ maxWidth: index === 0 ? '40px' : '' }}>{cell.render('Cell')}
                    </td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>

        {loading && (
          <div className="react-table-light__loading position-absolute d-flex align-items-center justify-content-center h-2 p-3">
            <Loading className="icon-primary icon x2" />
          </div>
        )}

        {(!loading && page.length === 0) && (
          <div className="react-table-light__no-data position-absolute d-flex align-items-center justify-content-center h-2 p-3">
            {noDataText}
          </div>
        )}

        {(!loading && !data && !!error) && (
          <div className="react-table__no-data position-absolute d-flex flex-column align-items-center justify-content-center h-2 p-3">
            <p>
              {error}
            </p>

            {!!onRefresh && (
              <button
                type="button"
                className="btn btn-submit"
                onClick={() => onRefresh({ pageIndex, pageSize })}
              >
                {I18n.get('retry', 'reintentar')}
              </button>
            )}
          </div>
        )}

      </div>

      <Pagination
        previousPage={previousPage}
        nextPage={nextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        handleChangePageSize={(value) => handleChangePageSize(value)}
        handleChangeIndex={(e, value) => handleChangeIndex(e, value)}
        onRefresh={() => onRefresh()}
        pageSize={pageSize}
        total={total}
        results={rows.length}
      />
    </>
  )
}

InventoryAdjustmentsTable.propTypes = {
  colums: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.object),
  onFetchData: PropTypes.func,
  loading: PropTypes.bool,
  noDataText: PropTypes.object,
}

export default InventoryAdjustmentsTable;
