function HomeSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.55808 2.05806C9.80215 1.81398 10.1979 1.81398 10.442 2.05806L17.942 9.55806C18.1207 9.73681 18.1742 10.0056 18.0774 10.2392C17.9807 10.4727 17.7528 10.625 17.5 10.625H16.4584V15.8333C16.4584 16.4411 16.2169 17.024 15.7871 17.4538C15.3574 17.8836 14.7745 18.125 14.1667 18.125H5.83335C5.22556 18.125 4.64267 17.8836 4.2129 17.4538C3.78313 17.024 3.54168 16.4411 3.54168 15.8333V10.625H2.50002C2.24723 10.625 2.01933 10.4727 1.92259 10.2392C1.82586 10.0056 1.87933 9.73681 2.05808 9.55806L9.55808 2.05806ZM8.12502 16.875H11.875V12.5C11.875 12.2237 11.7653 11.9588 11.5699 11.7634C11.3746 11.5681 11.1096 11.4583 10.8334 11.4583H9.16668C8.89042 11.4583 8.62547 11.5681 8.43012 11.7634C8.23477 11.9588 8.12502 12.2237 8.12502 12.5V16.875ZM13.125 16.875V12.5C13.125 11.8922 12.8836 11.3093 12.4538 10.8795C12.024 10.4498 11.4411 10.2083 10.8334 10.2083H9.16668C8.5589 10.2083 7.976 10.4498 7.54623 10.8795C7.11646 11.3093 6.87502 11.8922 6.87502 12.5V16.875H5.83335C5.55708 16.875 5.29213 16.7653 5.09678 16.5699C4.90143 16.3746 4.79168 16.1096 4.79168 15.8333V10C4.79168 9.65482 4.51186 9.375 4.16668 9.375H4.0089L10 3.38388L15.9911 9.375H15.8334C15.4882 9.375 15.2084 9.65482 15.2084 10V15.8333C15.2084 16.1096 15.0986 16.3746 14.9033 16.5699C14.7079 16.7653 14.443 16.875 14.1667 16.875H13.125Z'
        fill='#94A3B8'
      />
    </svg>
  );
}

export default HomeSvg;
