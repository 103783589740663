import { useSelector } from 'react-redux';
import {
  country as companyCountry,
  electronicInvoicing,
} from '../../selectors/company';

const useElectronicPayrollStatus = () => {
  const isElectronic = useSelector(electronicInvoicing);
  const country = useSelector(companyCountry);

  const availableCountries = [
    'peru',
    'panama',
    'republicaDominicana',
    'colombia',
    'argentina',
    'costaRica',
  ];

  return {
    isActive: isElectronic,
    availableCountries,
    canElectronicPayroll: availableCountries.includes(country),
  };
};

export default useElectronicPayrollStatus;
