import { useMemo } from "react";
import { renderDate, renderSelect } from "../../fields/V0/Fields";
import { useDispatch, useSelector } from "react-redux";
import { getRefundNumerationsNoElectronic } from "../../../../selectors/numerations";
import { Field } from "react-final-form";
import { I18n } from "aws-amplify";
import { SelectClient } from "./SelectClients";
import Warning from "../../../svg/Warning";
import { setNumeration } from "../../../../reducers/activeRefund";

export const Cash = () => {
  const dispatch = useDispatch();
  const today = useMemo(() => new Date(), [])
  const numerations = useSelector(getRefundNumerationsNoElectronic);
  
  return (
    <>
    <div className="row m-0">
      <SelectClient numerations={numerations} />

      <Field 
        type="select" 
        name="refund.numeration"
        component={renderSelect}
        options={numerations}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        noOptionsMessage={() => {
          let text = I18n.get('noResultsNoElectronic', 'No tienes ninguna numeración de nota crédito');
          return (
            <div className="w-100 d-flex">
              <div className="col-2 pr-0"><Warning /></div>
              <div className="d-flex flex-column">
                <p className="warning-text mb-1">{text}</p>
                <a className="warning-text" href="https://ayuda.alegra.com/es/configuraci%C3%B3n-de-la-numeraci%C3%B3n-de-tus-comprobantes-en-alegra" target="_blank" rel="noreferrer">
                  {I18n.get('learnToCreateIt', 'Aprender a crearla')}
                </a>
              </div>
            </div>
          )
        }}
        className="col-12 col-md-6 p-0 pr-md-0 pl-md-2 new-refund-input select-refund"
        onChange={option => {
          dispatch(setNumeration(option));
          return option;
        }}
        required
        cacheOptions={false}
        label={I18n.get('numeration', 'Numeración')} 
      />

      <Field 
        type="date"
        name="refund.date"
        className="col-12 p-0 col-md-6 date-refund"
        component={renderDate}  
        required
        label={I18n.get('date', 'Fecha')} 
        defaultValue={today}
        portalId="refund-modal-portal"
      />
    </div>
    </>
  )
}