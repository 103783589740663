import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { syncData } from '../../../reducers/app';
import { offlineData } from '../../../selectors/app';
import { hasPermissionTo } from '../../../selectors/auth';
import NewCheckCircle from '../../svg/new/CheckCircle';
import RefreshNewSvg from '../../svg/new/Refresh';
import Typography from '../../common/Typography';
import Button from '../../common/Button';
import MenuOption from '../../common/MenuOption';
import RefreshAlert from '../../svg/new/RefreshAlert';
import { NavLink } from 'react-router-dom';
import WarningCircleSvg from '../../svg/new/WarningCircle';
import Download from '../../svg/new/Download';

const OfflineStatus = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const offlineStatus = useSelector(offlineData);
  const syncing = useSelector((state) => get(state, 'app.syncing', false));
  const itemsPercent = useSelector((state) =>
    get(state, 'items.loadingPercent', false)
  );
  const clientsPercent = useSelector((state) =>
    get(state, 'clients.loadingPercent', false)
  );

  return (
    <MenuOption
      id='offlineStatus'
      tooltip={I18n.get('synchronization', 'Sincronización')}
      eventName='Synchronization'
      extraClassName='mobile-right'
      header={
        offlineStatus ? (
          <RefreshAlert className='icon-danger2' />
        ) : (
          <RefreshNewSvg className='icon-secondary' />
        )
      }
    >
      <div className='menu-options-item d-flex align-items-center justify-content-between'>
        <div>
          <Typography
            text={
              offlineStatus
                ? I18n.get('dataOffline', 'datos sin sincronizar')
                : I18n.get('noDataOffline', 'datos sincronizados')
            }
            variant='primary'
            type='body-3-bold'
          />

          <Typography
            text={
              offlineStatus
                ? I18n.get(
                    'dataOfflineSubtitles',
                    'hay datos en offline que no se han sincronizado. Se recomienda tener los datos siempre sincronizados para no perder información valiosa'
                  )
                : (syncing && (itemsPercent < 100 || clientsPercent < 100) 
                    ? I18n.get(
                      'loadingResourcesSubtitle',
                      'Puedes vender mientras cargan tus productos y clientes'
                    )
                    : I18n.get(
                      'noDataOfflineSubtitles',
                      'tus datos están sincronizados con Alegra'
                    ))
            }
            variant='secondary'
            type='caption-regular'
          />
        </div>

        <Button
          type='button'
          disabled={syncing}
          onClick={() => dispatch(syncData())}
          size='icon'
        >
          <RefreshNewSvg
            className={`icon-white ${syncing && 'rotate-animation'}`}
          />
        </Button>
      </div>

      <div className='px-4 py-1 d-flex flex-column align-items-start justify-content-center'>
        <div className='d-flex align-items-center justify-content-between py-2 w-100'>
          <div className='d-flex align-items-center'>
            {!offlineStatus ? (
              <NewCheckCircle className='icon-gray mr-2' />
            ) : (
              <WarningCircleSvg className='icon-danger2 mr-2' />
            ) }

            <Typography
              text={I18n.get('sales', 'ventas')}
              variant='secondary'
              type='body-3-regular'
            />
          </div>

          {offlineStatus && (
            <NavLink to='/invoices'>
              <Typography
                type='label-2'
                variant='danger2'
                text={I18n.get('reviewPending', 'Revisar pendientes')}
              />
            </NavLink>
          )}
        </div>

        <div className='d-flex align-items-center justify-content-between py-2 w-100'>
          <div className='d-flex align-items-center'>
            {itemsPercent >= 100 
              ? (<NewCheckCircle className='icon-gray mr-2' />) 
              : (<Download className='icon-gray mr-2' />)}

            <Typography
              text={I18n.get('items', 'Productos')}
              variant='secondary'
              type='body-3-regular'
            />
          </div>

          {itemsPercent < 100 && (
            <Typography
              text={`${itemsPercent}%`}
              variant='tertiary'
              type='body-3-regular'
            />
          )}
        </div>

        <div className='d-flex align-items-center justify-content-between py-2 w-100'>
          <div className='d-flex align-items-center'>
            {clientsPercent >= 100 
              ? (<NewCheckCircle className='icon-gray icon-primary mr-2' />)
              : (<Download className='icon-gray mr-2' />)}

            <Typography
              text={I18n.get('clients', 'Clientes')}
              variant='secondary'
              type='body-3-regular'
            />
          </div>

          {clientsPercent < 100 && (
            <Typography
              text={`${clientsPercent}%`}
              variant='tertiary'
              type='body-3-regular'
            />
          )}
        </div>
      </div>
    </MenuOption>
  );
};

export default OfflineStatus;
