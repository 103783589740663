import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'aws-amplify'
import http from '../../../../utils/http'
import { Form, Field } from 'react-final-form'
import { get, capitalize } from 'lodash'

import { renderField } from '../../../forms/fields/V0/Fields';
import Tooltip from '../../../common/Tooltip'
import InfoSVG from '../../../svg/InfoOutline';
import Loading from '../../../svg/Loading';
import ArrowRight from '../../../svg/ArrowRight';
import { toast } from '../../../../utils';

import { identificationSelector, name as nameSelector } from '../../../../selectors/company';
import { handleError as handleRequestError } from '../../../../utils/errors'
import { openModal } from '../../../../reducers/modals'
import { calculateDV } from '../../../print/invoice/utilities'

const LegalRepresentativeForm = ({ nextStep }) => {
  const dispatch = useDispatch();
  const identification = useSelector(identificationSelector);
  const name = useSelector(nameSelector);
  const [isLoading, setIsLoading] = useState(false)

  const handleError = (error) => {
    let message = handleRequestError(error)
    if (message.toUpperCase().includes('STATUS CODE 400'))
      message = I18n.get('runtimeErrorMessage', 'Por favor, intente de nuevo');

    return message;
  }

  const submit = async values => {
    setIsLoading(true)
    try {
      const legalUserData = {
        identification: values.identification,
        name: values.name,
        company: {
          name: name,
          identification: identification.toString(),
          DV: calculateDV(identification).toString(),
        }
      };
      toast.info({
        title: I18n.get('generatingYourContract', 'Estamos generando tu contrato.⏳'),
        subtitle: I18n.get('generatingYourContract.description', 'En unos segundos podrás terminar de aceptar los nuevos términos y condiciones.')
      })
      const response = await http.post('https://wdn3cujik9.execute-api.us-east-1.amazonaws.com/mandate-signatures', legalUserData);

      if (!!get(response, 'data.document', null)) {
        setIsLoading(false);
        dispatch(openModal({ modal: 'newTermsAndConditionsColombia', params: { documentContent: response.data.document, values } }))
        nextStep();
      }
    } catch (error) {
      setIsLoading(false)
      // in case pdf api creator gives error => retry
      if (get(error, 'response.data') && error.response.data.message &&
        error.response.data.message.toUpperCase().includes("SERVER ERROR")) {
        return submit(values);
      }
      toast.error({
        title: 'Error al enviar la petición',
        subtitle: handleError(error),
        params: { autoClose: 3000 }
      })
    }
  }

  const validate = values => {
    let errors = {};

    const name = get(values, 'name', '');
    const identification = get(values, 'identification', '');
    const email = get(values, 'email', '');

    if (!name)
      errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));

    if (!identification)
      errors.identification = capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

    if (!email && !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email))
      errors.email = capitalize(I18n.get('enterAValidEmail', 'Ingresa un correo válido'));

    return errors;
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="my-4 px-4">
        <p className="h1 mb-5">
          {I18n.get('legalRepresentativeData', 'Datos del representante legal')}
          <Tooltip
            className="ml-2 "
            tooltipActive={true}
            overlay={(
              <p className="text-capitalize-first">
                {I18n.get('legalRepresentativeData.tooltip', 'Es la persona que actúa en nombre de la empresa y está registrada en el RUT')}
              </p>
            )}
          >
            <InfoSVG className="icon-primary" small />
          </Tooltip>
        </p>
        <p className="h4 text-capitalize-first mb-5">
          {I18n.get('beforeContinueCompleteFields', 'Antes de continuar, completa los siguientes datos.')}
        </p>
        <Form
          onSubmit={submit}
          validate={validate}
        >
          {({ handleSubmit }) => (
            <form
              noValidate
              onSubmit={handleSubmit}>
              <div className="form-body form-body__fields form-row">
                <Field
                  name="name"
                  label={capitalize(I18n.get('name', 'nombre'))}
                  className="col-6"
                  component={renderField}
                  required
                  type="text"
                />
                <Field
                  name="identification"
                  label="Número de identificación"
                  className="col-6"
                  component={renderField}
                  required
                  type="number"
                />
                <Field
                  name="email"
                  className="col-6"
                  label="Correo electrónico"
                  component={renderField}
                  required
                  type="text"
                />
              </div>
              <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center">
                <div className="modal__bottom-buttons d-flex">
                  <button
                    type="submit"
                    data-testid="modal-submit-btn"
                    className="btn btn-submit text-capitalize-first px-4"
                  >
                    {isLoading
                      ? <Loading className="icon-primary icon" />
                      : <span>
                        {I18n.get('next', 'Siguiente')}
                        <ArrowRight className="icon-white" small />
                      </span>
                    }
                  </button>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

export default LegalRepresentativeForm