import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get, set, difference, isEmpty, trim } from 'lodash'
import { BigNumber } from 'bignumber.js'
import alegraAPI from './alegraAPI'

import * as queries from '../graphql/queries'
import { APIGraphqlSelector, station } from '../selectors/app'
import { selectedCategories } from '../selectors/itemCategories'
import { fetch } from '../utils'
import { handleError } from '../utils/errors'
import * as itemsDB from '../database/itemsDB'
import { country as countrySelector, idCompanySelector } from '../selectors/company';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  loadingPercent: 0,
  searching: false,
  filters: {
    text: '',
    categories: [],
    limit: 30,
  },
}

const MAX_FETCH_PER_MIN = 10;

export const adapter = createEntityAdapter();

// const fetchItemsPartial = (dispatch, state) => async (metadata, params) => {
//   const { idWarehouse } = station(state)
//   const response = await APIGraphql(graphqlOperation(queries.allItems, { 
//     includeMetadata: true, 
//     type: "simple,kit,variant",
//     status: "active",
//     batch: metadata,
//     idWarehouse,
//     ...params,
//   }));

//   const data = get(response, 'data.allItems.data', null)
//   if (data) {
//     let total = new BigNumber(get(response, 'data.allItems.metadata.total', 0))
//     itemsDB.bulkPut(data, async () => {
//       dispatch(refresh())

//       if (get(state, 'items.loadingPercent') !== 100) {
//         let localTotal = await itemsDB.total()
//         if (total.isZero())
//           dispatch(setLoadingPercent(100))
//         else {
//           if (!!localTotal) {
//             localTotal = new BigNumber(localTotal)
//             const loadingPercent = localTotal.div(total).multipliedBy(100).decimalPlaces(0).toNumber()

//             dispatch(setLoadingPercent(loadingPercent))
//           }
//         }
//       }
//     })
//     return data;
//   }
//   return [];
// } 

const fetchItemsPartialFromAlegra = (dispatch, state) => async (metadata, params) => {
  const { idWarehouse } = station(state)

  const response = await alegraAPI.get('/items', {
    metadata: true,
    type: "simple,kit,variant",
    status: "active",
    fields: "negativeSale,currentWarehouseOnly",
    idWarehouse,
    // batch: metadata,
    ...metadata,
    ...params,
  });

  const data = get(response, 'data.data', null)
  let total = 0;
  if (data) {
    total = new BigNumber(get(response, 'data.metadata.total', 0))
    itemsDB.bulkPut(data, async () => {
      dispatch(refresh())

      if (get(state, 'items.loadingPercent') !== 100) {
        let localTotal = await itemsDB.total()
        if (total.isZero())
          dispatch(setLoadingPercent(100))
        else {
          if (!!localTotal) {
            localTotal = new BigNumber(localTotal)
            const loadingPercent = localTotal.div(total).multipliedBy(100).decimalPlaces(0).toNumber()

            dispatch(setLoadingPercent(loadingPercent))
          }
        }
      }
    })
    return { data, total };
  }
  return { data: [], total };
}

export const fetchItems = createAsyncThunk(
  'items/fetch',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      return await fetch(fetchItemsPartialFromAlegra(dispatch, getState()), [], params, MAX_FETCH_PER_MIN)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const syncItems = createAsyncThunk(
  'items/sync',
  async (params, { dispatch, getState }) => {
    try {
      let data = await fetch(fetchItemsPartialFromAlegra(dispatch, getState()), [], params, MAX_FETCH_PER_MIN)
      let localData = await itemsDB.getAll()

      data = data.map(item => get(item, 'id'))
      localData = localData.map(item => get(item, 'id'))

      await itemsDB.bulkDelete(difference(localData, data))

      dispatch(refresh())
    } catch {
    }
  }
)

const fetchFavoritesPartial = (api) => async (metadata, params) => {
  const response = await api(graphqlOperation(queries.getFavoriteItems, {
    includeMetadata: true,
    batch: metadata,
    ...params,
  }));

  const data = get(response, 'data.getFavoriteItems.data', null)
  const total = get(response, 'data.getFavoriteItems.metadata.total', 0)
  if (data) {
    return { data, total };
  }
  return { data: [], total };
}

export const fetchFavorites = (params) => async (dispatch, getState) => {
  const APIGraphql = APIGraphqlSelector(getState());
  const favorites = await fetch(fetchFavoritesPartial(APIGraphql), [], params, MAX_FETCH_PER_MIN);
  itemsDB.setFavorites(favorites);
  dispatch(refresh);
}


const filterFromAlegra = async (idWarehouse, metadata, params) => {
  const response = await alegraAPI.get('/items', {
    metadata: true,
    type: "simple,kit,variant",
    status: "active",
    fields: "negativeSale",
    idWarehouse,
    ...metadata,
    ...params
  });

  const data = get(response, 'data.data', null)
  let total = 0;
  if (data) {
    total = new BigNumber(get(response, 'data.metadata.total', 0))
    itemsDB.bulkPut(data)
    return { data, total };
  }

  return { data: [], total: 0 }
}

const searchFromAlegra = async (state, text, metadata = {}) => {
  try {
    if (!text || isEmpty(trim(text)))
      return []

    const { idWarehouse } = station(state)

    let response = {}
    // try to search by name
    response = await filterFromAlegra(idWarehouse, metadata, { name: text })

    if (response.data && response.data.length > 0)
      return response.data;

    // then by reference
    response = await filterFromAlegra(idWarehouse, metadata, { reference: text })
    return response.data;
  } catch (error) {
    return []
  }
}

const searchFromLocalDB = async (state, params) => {
  const companyId = idCompanySelector(state);

   if (["593526"].includes(companyId))
     return await itemsDB.filter(params)
   else
     return await itemsDB.specialFilter(params)
}

export const search = async (state, dispatch, params, useAlegraAPI = false) => {
  let data = []

  if (useAlegraAPI) dispatch(setSearching(true));
  try {
    // first try to search locally 
    data = await searchFromLocalDB(state, params)

    // then try to search in Alegra
    if (useAlegraAPI && get(state, 'items.loadingPercent') !== 100 && (!data || data.length === 0)) {
      data = await searchFromAlegra(state, params.text)
    }
  } catch (error) {
    throw error
  } finally {
    if (useAlegraAPI) dispatch(setSearching(false));
  }

  return data;
}

export const filter = createAsyncThunk(
  'items/filter',
  async (params, { dispatch, rejectWithValue, getState }) => {
    try {
      const categories = selectedCategories(getState())

      const newParams = {
        text: params.text,
        limit: !!params.limit ? params.limit : getState().items.filters.limit,
        categories: !!params.searchCategories ? categories : []
      }

      let data = await search(getState(), dispatch, newParams, true);
      return { data, params: newParams }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const refresh = createAsyncThunk(
  'items/refresh',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const filters = getState().items.filters;

      let data = await search(getState(), dispatch, filters);

      return { data }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const searchMore = createAsyncThunk(
  'items/searchMore',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const filters = getState().items.filters;
      const params = { ...filters, limit: filters.limit + 30 }

      let data = await search(getState(), dispatch, params);

      return { data, params }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const findBarcode = barcode => itemsDB.findByBarcode(barcode)

export const changeItemInventory = createAsyncThunk(
  'items/changeItemInventory',
  async ({ id, quantity }, { getState }) => {
    try {
      const { idWarehouse } = station(getState())
      let item = await itemsDB.getItem(id)

      if (!!item) {
        let warehouses = get(item, 'inventory.warehouses', null)
        if (!!warehouses) {
          const index = warehouses.findIndex(warehouse => +get(warehouse, 'id') === +idWarehouse)
          if (index >= 0)
            set(item, `inventory.warehouses.${index}.availableQuantity`, +get(item, `inventory.warehouses.${index}.availableQuantity`) + quantity)
        }
        await itemsDB.put(item)
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const changeItemsByApi = createAsyncThunk(
  'items/changeItemsByApi',
  async (idArray, { rejectWithValue, dispatch }) => {
    try {
      const fields = "ledger,itemVariants,variantAttributes,editable,settingsOnShop,remissionedQuantity,negativeSale";
      const items = await Promise.all(idArray.map(async (id) => {
        const response = await alegraAPI.get(`/items/${id}?fields=${fields}`);
        if (response.data.id) {
          return (response.data)
        }
      }))

      await Promise.all(items.map((item) => {
        return itemsDB.put(item)
      }))

      dispatch(refresh())
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateItemImage = createAsyncThunk(
  'items/updateItemImage',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await alegraAPI.get(`/items/${id}`);

      if (response.data.id) {
        itemsDB.put(response.data)

        return (response.data)
      }

    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const appSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setLoadingPercent: (state, action) => {
      state.loadingPercent = action.payload
    },
    setSearching: (state, action) => {
      state.searching = action.payload
    },
    updateItem: adapter.updateOne,
    removeItem: adapter.removeOne,
    removeItems: adapter.removeMany,
  },
  extraReducers: builder => {
    builder.addCase(fetchItems.pending, (state) => {
      state.loading = 'pending'
      state.error = null
    })
    builder.addCase(fetchItems.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.error = action.payload
      }
    })
    builder.addCase(fetchItems.fulfilled, state => {
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(filter.fulfilled, (state, action) => {
      state.filters = action.payload.params
      adapter.setAll(state, action.payload.data)
    })
    builder.addCase(refresh.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload.data)
    })
    builder.addCase(searchMore.fulfilled, (state, action) => {
      state.filters = action.payload.params
      adapter.setAll(state, action.payload.data)
    })
  }
});

const { reducer, actions } = appSlice;

export const { setLoadingPercent, setSearching, updateItem, removeItem, removeItems } = actions;

export const itemsSelector = adapter.getSelectors(state => state.items);

export default reducer;