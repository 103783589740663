export const regimes = [
  {
    id: "0",
    display: "No responsable del IVA",
    value: "Régimen simplificado",
    key: "SIMPLIFIED_REGIME",
    electronicInvoicingVersion: "2.0"
  },
  {
    id: "2",
    display: "Responsable del IVA",
    value: "Régimen común",
    key: "COMMON_REGIME",
    electronicInvoicingVersion: "2.0"
  },
  {
    id: "3",
    dianId: "01",
    display: "Régimen simple de tributación",
    value: "Régimen simple de tributación",
    nameDian: "IVA",
    listNameDian: "48",
    key: "SIMPLE_REGIME_OF_TAXATION",
    electronicInvoicingVersion: "2.1"
  },
  {
    id: "4",
    dianId: "01",
    display: "Responsable del IVA",
    value: "Régimen común",
    nameDian: "IVA",
    listNameDian: "48",
    key: "COMMON_REGIME",
    electronicInvoicingVersion: "2.1"
  },
  {
    id: "5",
    dianId: "ZZ",
    display: "No responsable del IVA",
    value: "Régimen simplificado",
    nameDian: "No aplica",
    listNameDian: "49",
    key: "SIMPLIFIED_REGIME",
    electronicInvoicingVersion: "2.1"
  },
  {
    id: "6",
    dianId: "ZZ",
    display: "No responsable del consumo",
    value: "No responsable del consumo",
    nameDian: "No aplica",
    listNameDian: "49",
    key: "NOT_REPONSIBLE_FOR_CONSUMPTION",
    electronicInvoicingVersion: "2.1"
  },
  {
    id: "7",
    dianId: "01",
    display: "Regimen especial",
    value: "Régimen especial",
    nameDian: "IVA",
    listNameDian: "48",
    key: "SPECIAL_REGIME",
    electronicInvoicingVersion: "2.1"
  }
]