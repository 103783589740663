import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { capitalize, get, set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux'

import { activeWarehouses } from '../../../selectors/warehouses'
import { country as countrySelector } from '../../../selectors/company';
import { decimalsVersionsGroup } from '../../../selectors/deployGroups';
import { openModal } from '../../../reducers/modals';
import { renderSelect, renderField } from '../fields/V0/Fields';

import Close from '../../svg/Close';
import Plus from '../../svg/Plus';
import { getWarehouseHelpUrl } from '../../../utils';

const NewInventory = ({ values, form, ...props }) => {
  const warehouses = useSelector(activeWarehouses);
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);

  function maxDecimals(e, key, decimals = 6) {
    if (!decimalsVersionsGroup.includes(country))
      return e.target.value;

    const newButton = e.nativeEvent.data;
    set(values, key, !get(values, key) ? "" : get(values, key));
    e.target.value = !e.target.value ? "" : e.target.value;

    if (newButton === '.') {
      if (!e.target.value.slice(0, -1).includes('.')) {
        return e.target.value;
      } else {
        e.target.value = values[key];
        return get(values, key);
      }
    }

    if (!isNaN(newButton)) {
      if (!e.target.value.includes('.') || (e.target.value.includes('.') && e.target.value.split('.')[1].length <= decimals)) {
        return e.target.value;
      } else {
        e.target.value = get(values, key);
        return get(values, key);
      }
    }

    if (isNaN(newButton)) {
      e.target.value = get(values, key);
      return get(values, key);
    }
  }

  const addWarehouse = () => {
    if (get(values, 'warehouses.length', 0) < warehouses.length) {
      form.mutators.push("warehouses")
      if (get(values, 'warehouses.length', 0) === 0) {
        form.change('warehouses.0.initialQuantity', get(values, 'initialQuantity', null))
        form.change('warehouses.0.minQuantity', get(values, 'minQuantity', null))
        form.change('warehouses.0.maxQuantity', get(values, 'maxQuantity', null))
      }
    }
  }

  const removeWarehouse = (callback) => {
    if (get(values, 'warehouses.length', 0) === 1) {
      form.change('initialQuantity', get(values, 'warehouses.0.initialQuantity', null))
      form.change('minQuantity', get(values, 'warehouses.0.minQuantity', null))
      form.change('maxQuantity', get(values, 'warehouses.0.maxQuantity', null))
    }
    callback()
  }

  useEffect(() => {
    if (!values.warehouses || values.warehouses.length === 0)
      form.mutators.push("warehouses")
  }, [form.mutators, values.warehouses])

  return (
    <>
      <div className="p-0 col-12 form-row form-field-array-wrapper">
        <FieldArray name="warehouses">
          {({ fields }) =>
            fields.map((name, index) => (
              <div key={index} className="col-12 form-row m-0 p-0">

                {!!(warehouses.length === 1) && (
                  <Field
                    name={`${name}.warehouse`}
                    component={renderField}
                    className="col-6 col-lg-3"
                    type="number"
                    label={capitalize(I18n.get('warehouse', 'bodega'))}
                    validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                    placeholder={get(warehouses[0], 'name')}
                    helpTooltip={(
                      <>
                        {I18n.get('productDistributionHelp.init', 'Conoce')}
                        <a href={getWarehouseHelpUrl(country)} target="_blank" rel="noreferrer">
                          {I18n.get('productDistributionHelp.mid', ' cómo distribuir en diferentes bodegas')}
                        </a>
                        {I18n.get('productDistributionHelp.end', ' este producto.')}
                      </>
                    )}
                    disabled
                  />
                )}

                {!!(warehouses.length > 1) && (
                  <Field
                    name={`${name}.warehouse`}
                    component={renderSelect}
                    className="col-6 col-lg-3"
                    options={warehouses}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    label={capitalize(I18n.get('warehouse', 'bodega'))}
                    required
                    validate={value => !get(value, 'id', null) ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                    addOptionText={`+ ${capitalize(I18n.get('newWarehouse', 'Nueva bodega'))}`}
                    addOptionAction={(index) => {
                      dispatch(openModal({ modal: 'warehouse' }))
                      props.setWarehouseIndex(index)
                    }}
                  />
                )}

                <Field
                  name={`${name}.initialQuantity`}
                  component={renderField}
                  className="col-6 col-lg-3"
                  type="number"
                  label={capitalize(I18n.get('initialQuantity', 'cantidad inicial'))}
                  validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                  onChange={e => maxDecimals(e, `${name}.initialQuantity`, 4)}
                  required
                />
                <Field
                  name={`${name}.minQuantity`}
                  component={renderField}
                  className="col-6 col-lg-3"
                  type="number"
                  label={capitalize(I18n.get('minQuantity', 'cantidad mínima'))}
                  helpTooltip={capitalize(I18n.get('minQuantityHelp', 'Recibe alertas cuando llegues al stock mínimo que indiques.'))}
                  onChange={e => maxDecimals(e, `${name}.minQuantity`, 4)}
                />
                <div className="col-6 col-lg-3 form-row m-0 p-0">
                  <Field
                    name={`${name}.maxQuantity`}
                    component={renderField}
                    className="col-10"
                    type="number"
                    label={capitalize(I18n.get('maxQuantity', 'cantidad máxima'))}
                    helpTooltip={capitalize(I18n.get('maxQuantityHelp', 'Recibe alertas cuando llegues al stock máximo que indiques.'))}
                    onChange={e => maxDecimals(e, `${name}.maxQuantity`, 4)}
                  />
                  <button
                    type="button"
                    className="btn button-transparent col-2 mb-3 mx-0 px-0"
                    disabled={fields.length <= 1}
                    style={{
                      height: '3.6rem',
                      outline: 'none',
                      boxShadow: 'none',
                      paddingTop: '3rem',
                      display: values?.warehouses?.length > 1 ? 'inline' : 'none'
                    }}
                    onClick={() => removeWarehouse(() => fields.remove(index))}
                  >
                    <Close className="icon-secondary" />
                  </button>
                </div>
              </div>
            ))
          }
        </FieldArray>

        {!!(warehouses.length > 1) && (
          <div className="col-12 text-right">
            <button
              type="button"
              className="btn btn-secondary primary mb-3 mr-5"
              style={{ border: "none", outline: "none", boxShadow: "none" }}
              disabled={get(values, 'warehouses.length') >= warehouses.length}
              onClick={() => addWarehouse()}
            >
              <Plus className="icon icon-primary mr-2" />
              {capitalize(I18n.get('addWarehouse', 'agregar bodega'))}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default NewInventory;
