import dayjs from "dayjs"

const countries = {
  ARG: 'argentina',
  COL: 'colombia',
  PER: 'peru',
  PAN: 'panama',
  DOM: 'republicaDominicana',
  CRI: 'costaRica',
  MEX: 'mexico',
  CHL: 'chile',
  ESP: 'spain',
  USA: 'usa',
  INT: 'other',
}

const decimalsVersionsGroup = [];

// TODO: delete usage of this variable when finished all groups
const refundsDatesGroups = [dayjs('2021-12-31'), dayjs('2022-12-31') ,dayjs('2023-12-31')]

export { countries, decimalsVersionsGroup, refundsDatesGroups }
