import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import { I18n } from 'aws-amplify';
import BigNumber from "bignumber.js"

import { isSelectingItems, items, itemsDiscarded } from '../../../../selectors/activeRefund';
import { allItems as allItemsSelector } from '../../../../selectors/items';
import { updateItem, setIsSelectingItems, populateItem, clearDiscardedItems, updateIsAllItemsPupaleted } from '../../../../reducers/activeRefund';
import { search } from '../../../../reducers/items';

import AddIcon from '../../../svg/Plus';
import Left from '../../../svg/new/Left';
import Loading from '../../../../components/svg/Loading';

import Item from './Item';
import Summary from './Summary';
import Search from '../../../modals/refunds/SearchItems/Search';
import SearchableItems from './SearchableItems';
import Button from '../../../common/Button';
import { transformItemFromInvoice } from '../utils';
import { useFormat } from '../../../../hooks/useFormat';
import alegraAPI from '../../../../reducers/alegraAPI';
import { station } from '../../../../selectors/app';

const transformItemOfInvoiceNotFound = (item) => {
  return {
    id: item.id,
    name: item.name,
    description: item.description,
    price: [{
      price: item.price,
    }],
    quantity: item.quantity,
    discount: item.discount,
    tax: item.tax
  }
}

const ItemsForm = ({ changeStep, openEditItem }) => {
  const isSelectingMode = useSelector(isSelectingItems);
  const allItems = useSelector(allItemsSelector);
  const invoiceSelected = useSelector(state => get(state, 'modals.newRefunds.params.invoice', null));
  const itemsInRefund = useSelector(items);
  const state = useSelector(state => state);
  const { values } = useFormState();
  const { decimal, fmt } = useFormat();
  const [loadingItems, setLoadingItems] = useState(false);
  const dispatch = useDispatch();
  const devolutionType = get(values, 'refund.method.value');
  const outstandingInvoiceAmount = new BigNumber(get(values, 'refund.document.balance')).toFormat(decimal, fmt);
  const { idWarehouse } = useSelector(station);

  const getItemInactiveFromAlegra = async ({ metadata, params }) => {
    const response = await alegraAPI.get('/items', {
      metadata: true,
      type: "simple,kit,variant",
      status: "inactive",
      // batch: metadata,
      ...metadata,
      ...params,
    });

    const data = get(response, 'data.data', null)
    return data;
  }

  useEffect(() => {
    if (!isSelectingMode) {
      dispatch(clearDiscardedItems());
    }
  }, [isSelectingMode])
    
  useEffect(() => {
    if (itemsInRefund.length) return
    const populateItemsFromInvoice = async () => {
      const devolutionType = get(values, 'refund.method.value')
      const populatedInvoice = !!invoiceSelected
        ? invoiceSelected
        : devolutionType === 'creditToSales' ? get(values, 'refund.document', null) : null
      let unloadedItems = []
      let countUnloadedItems = 0

      if (!!populatedInvoice && !!get(populatedInvoice, 'items.length', 0)) {
        populatedInvoice.items.forEach((item, index) => {
          console.log('item received::: ', item);
          let itemFound = allItems.find(i => i.id === Number(item.id));
          itemFound = !!itemFound ? transformItemFromInvoice(itemFound, item) : itemFound;
          if (!!itemFound) {
            dispatch(populateItem(itemFound));
            if (item.quantity > 1) {
              dispatch(updateItem({
                index,
                values: {
                  quantity: item.quantity
                }
              }));
            }
          } else {
            unloadedItems.push(item);
          }
        })

        if(!unloadedItems.length) dispatch(updateIsAllItemsPupaleted(true));

        if (!!unloadedItems.length) {
          console.log('unloadedItems: ', unloadedItems);
          setLoadingItems(true);
          try {
            unloadedItems.forEach(async (item) => {
              let itemFromAlegra = await search(state, dispatch, { text: item.name, limit: 1 }, true);
              let itemFromAlegraWithInvoicePrices = !!itemFromAlegra.length ? transformItemFromInvoice(itemFromAlegra[0], item) : item;
              if (!!itemFromAlegra.length && Number(itemFromAlegra[0].id) === Number(item.id)) {
                dispatch(populateItem(itemFromAlegraWithInvoicePrices));
                if (item.quantity > 1) {
                  dispatch(updateItem({
                    index: itemsInRefund.length,
                    values: {
                      quantity: item.quantity
                    }
                  }));
                }
              } else { 
                const itemInactie = await getItemInactiveFromAlegra({ metadata: {}, params: { name: item.name, limit: 1 }, idWarehouse });
                
                if (!!itemInactie.length && Number(itemInactie[0].id) === Number(item.id) ) {
                  let itemFromAlegraWithInvoicePrices = transformItemFromInvoice(itemInactie[0], item)
                  dispatch(populateItem({...itemFromAlegraWithInvoicePrices, status: 'inactive', quantity: item.quantity}));
                } else {
                  let itemFromAlegraWithInvoicePrices = transformItemOfInvoiceNotFound(item)
                  dispatch(populateItem({...itemFromAlegraWithInvoicePrices, quantity: item.quantity}));
                }

              }
              countUnloadedItems++;
              if (countUnloadedItems === unloadedItems.length) {
                dispatch(updateIsAllItemsPupaleted(true));
                setLoadingItems(false);
              }
            })
          } catch (error) {
            console.log("error while bringing items from api");
          }
        }
        dispatch(setIsSelectingItems(false));
      }
    }
    populateItemsFromInvoice();
  }, [])

  return (
    <div className="new-refund-items-form">
      <p className="items-form_subtitle">
        {devolutionType === 'creditToSales' 
          ? I18n.get('itemsToAddInDevolution.creditToSales', 'Productos que se incluirán en la devolución de tu cliente')
          : I18n.get('itemsToAddInDevolution', 'Elige los productos del documento asociado que devuelve el cliente')
        }
      </p>
      <div className="items-form_items-container">
        <div className="d-flex flex-column justify-content-between items-container-header">
          <p className="items-container-header_title mb-0">{get(values, 'refund.client.name')}</p>
          {
            devolutionType === 'creditToSales' && !!outstandingInvoiceAmount &&
            <div className='d-flex flex-column mt-4'>
              <p className="items-form_items-caption p-0 mb-md-1">
                {I18n.get('associatedDocument', 'Documento asociado')}:{" "}
                {get(values, 'refund.document.numberTemplate.fullNumber')}
              </p>
              <p className="items-form_items-caption p-0">
                  {I18n.get('outstandingInvoiceAmount', 'Pendiente por cobrar')}:{" "}{outstandingInvoiceAmount}
              </p>
            </div>
          }
        </div>
        {isSelectingMode && (
          <>
            <Search newRefund />
            <SearchableItems />
          </>
        )}
        {!isSelectingMode && (
          <>
            <p className="items-form_items-caption font-weight-bold">
              {I18n.get('productsToRefund', 'Productos a devolver')}
            </p>
            <div className="d-flex flex-column justify-content-between h-100" style={{position: "relative", height: "100%"}}>
              <section className="items-list">
                {itemsInRefund.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      index={index}
                      item={item}
                      openEditItem={openEditItem}
                    />
                  )
                })}
                {loadingItems && (
                  <div className="d-flex w-100 justify-content-center">
                    <Loading className='icon-primary icon x4' />
                  </div>
                )}
                
                <button className="btn-md-default-filled" onClick={() => dispatch(setIsSelectingItems(true))}>
                  <AddIcon className="icon-white" width={16} height={16} />
                  {I18n.get('addItems', 'Agregar productos')}
                </button>
              </section>
              <section className="items-summary">
                <Summary />
              </section>
            </div>
          </>
        )}
      </div>
      {isSelectingMode && (
        <div className='d-flex flex-end justify-content-between mt-3 bg-white'>
          <button
            type='button'
            className='refunds-cancel-button'
            style={{ border: 'none', fontWeight: '500' }}
            onClick={!itemsInRefund.length ? () => changeStep(2) : () => dispatch(setIsSelectingItems(false))}>
            <Left className="icon-black mr-2" />
            {I18n.get('goBack', 'Volver')}
          </button>
          <Button
            className='ml-4'
            disabled={!itemsInRefund.length}
            type='button'
            onClick={() => { dispatch(setIsSelectingItems(false)) }}>
            {I18n.get('addItems', 'Agregar productos')}
          </Button>
        </div>
      )}
    </div >
  )
}

export default ItemsForm