import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { activeWarehouses } from './warehouses';
import { activeUsers } from './users';

// main selectors
const membershipSelector = state => get(state, 'membership.alegra-pos', null);
export const membershipACSelector = state => get(state, 'membership.alegra-web', null);
export const companySelector = state => get(state, 'auth.company', null);

const whitelist = [
  'demo:income',
  'plus:*',
  'pyme:*',
  'pro:*',
  'erp1:*',
  'erp2:*',
  'erp3:*'
]

const isInWhiteList = (plan, feature) => {
  if(whitelist.includes(`${plan}:*`))
    return true;
  
  return whitelist.includes(`${plan}:${feature}`)
}

// limit validation selectors
export const featureCurrentAmount = createDraftSafeSelector(
  companySelector, activeWarehouses, activeUsers,
  (company, activeWarehouses, activeUsers) => feature => {
    switch (feature) {
      case 'income':
        return get(company, 'monthIncome.income', 0);
      case 'invoices': 
        return get(company, 'monthIncome.invoices', 0);
      case 'warehouses':
        return activeWarehouses.length;
      case 'users':
        return activeUsers.filter(user => !get(user, 'email', '').includes('.contador@alegra.com')).length;
      default:
        return 0;
    }
  }
)

export const featureIncluded = createDraftSafeSelector(
  membershipSelector,
  membership => feature => {
    return get(get(membership, 'features', []).filter(f => get(f, 'keyword') === feature), '0.include', true);
  }
) 

export const featureQuantitative = createDraftSafeSelector(
  membershipSelector,
  membership => feature => {
    return get(get(membership, 'features', []).filter(f => get(f, 'keyword') === feature), '0.quantitative', false);
  }
)

export const featureLimit = createDraftSafeSelector(
  membershipSelector,
  membership => feature => {
    return get(get(membership, 'features', []).filter(f => get(f, 'keyword') === feature), '0.maxQuantity', -1);
  }
)

export const featureUnlimited = createDraftSafeSelector(
  featureLimit,
  featureLimit => feature => {
    return featureLimit(feature) <= 0;
  }
)

export const featureLimitReached = createDraftSafeSelector(
  companySelector, featureIncluded, featureQuantitative, featureCurrentAmount, featureLimit, membershipSelector, membershipACSelector,
  (company, featureIncluded, featureQuantitative, featureCurrentAmount, featureLimit, membership, membershipAC) => feature => {
    const registryDate = new Date(company.registryDate);
    const startDate = new Date('2021-10-25');

    if (registryDate < startDate)
      return false;

    if (get(membership, 'plan.keyword', '') !== 'readonly')
      return false;

    if(get(get(membershipAC, 'features', []).filter(f => get(f, 'keyword') === 'pos'), '0.include', false))
      return false;

    if(isInWhiteList(get(membership, 'plan.keyword', ''), feature))
      return false;
    
    const included = featureIncluded(feature);
    if(!included)
      return true;

    if(!featureQuantitative(feature))
      return !included;
    
    const currentAmount = featureCurrentAmount(feature);
    const limit = featureLimit(feature);
    
    return limit > 0 && currentAmount >= limit;
  }
)

export const newFeatureLimitReached = createDraftSafeSelector(
  companySelector, featureIncluded, featureQuantitative, featureCurrentAmount, featureLimit, membershipSelector, membershipACSelector,
  (company, featureIncluded, featureQuantitative, featureCurrentAmount, featureLimit, membership, membershipAC) => feature => {
    const registryDate = new Date(company.registryDate);
    const startDate = new Date('2021-10-25');

    if (registryDate < startDate)
      return false;

    if(get(get(membershipAC, 'features', []).filter(f => get(f, 'keyword') === 'pos'), '0.include', false))
      return false;

    if(isInWhiteList(get(membership, 'plan.keyword', ''), feature))
      return false;
    
    const included = featureIncluded(feature);
    if(!included)
      return true;

    if(!featureQuantitative(feature))
      return !included;
    
    const currentAmount = featureCurrentAmount(feature);
    const limit = featureLimit(feature);
    
    return limit > 0 && currentAmount >= limit;
  }
)

export const isFeatureLimitReached = createDraftSafeSelector(
  companySelector, featureIncluded, featureQuantitative, featureCurrentAmount, featureLimit, membershipSelector, membershipACSelector,
  (company, featureIncluded, featureQuantitative, featureCurrentAmount, featureLimit, membership, membershipAC) => feature => {
    const registryDate = new Date(company.registryDate);
    const startDate = new Date('2021-10-25');

    if (registryDate < startDate)
      return false;

    if(get(get(membershipAC, 'features', []).filter(f => get(f, 'keyword') === 'pos'), '0.include', false))
      return false;

    if (get(membership, 'plan.keyword', '') === 'readonly')
      return true;

    if(isInWhiteList(get(membership, 'plan.keyword', ''), feature))
      return false;
    
    const included = featureIncluded(feature);
    if(!included)
      return true;

    if(!featureQuantitative(feature))
      return !included;
    
    const currentAmount = featureCurrentAmount(feature);
    const limit = featureLimit(feature);
    
    return limit > 0 && currentAmount >= limit;
  }
)

export const anyFeatureLimitReached = createDraftSafeSelector(
  newFeatureLimitReached,
  (featureLimitReached) => {
    const features = ['income', 'invoices', 'warehouses', 'users'];
    for (let i = 0; i < features.length; i++) {
      if (featureLimitReached(features[i]))
        return true;
    }
    return false;
  }
)

export const invoiceFeatureLimitReached = createDraftSafeSelector(
  newFeatureLimitReached,
  (featureLimitReached) => {
    const features = ['income', 'invoices'];
    for (let i = 0; i < features.length; i++) {
      if (featureLimitReached(features[i]))
        return true;
    }
    return false;
  }
)

export const unlimitedFeatures = createDraftSafeSelector(
  featureIncluded, featureQuantitative, featureUnlimited,
  (featureIncluded, featureQuantitative, featureUnlimited) => features => {
    const featDetails = {};

    features.forEach(f => {
      featDetails[f] = !featureIncluded(f) || !featureQuantitative(f) || featureUnlimited(f)
    });

    return featDetails;
  }
) 

export const getPlanKeyword = createDraftSafeSelector(
  membershipSelector,
  membership => {
    return get(membership, 'plan.keyword', '');
  }
)

export const getPlanName = createDraftSafeSelector(
  membershipSelector,
  membership => {
    const plan = get(membership, 'plan.keyword', '')
    if (isInWhiteList(plan, '*'))
      return '-';
    
    return get(membership, 'plan.name', '-')
  }
)

export const isHappyWeekAvailable = createDraftSafeSelector(
  companySelector, membershipSelector,
  (company, membership) => {
    if (company.applicationVersion === 'other')
      return false;
    return get(membership, 'happyWeek', false);
  }
)