import { get } from "lodash";
import { I18n } from '@aws-amplify/core';
import { getDateTime } from "./utilities";

const getRefundType = (refund) => {
  const documentType = get(refund, 'numberTemplate.documentType', null);
  const isElectronic = !!get(refund, 'numberTemplate.isElectronic', null);

  if (documentType === 'creditNote' && isElectronic) {
    return I18n.get('refund.electronicCreditNote','Nota crédito electrónica');
  }

  if (documentType === 'creditNote' && !isElectronic) {
    return I18n.get('refund.creditNote', 'Nota de crédito');
  }

  if (documentType === 'adjustmentNote' && isElectronic) {
    return I18n.get('refund.adjustemntNoteDocumentPos', 'Nota de ajuste del documento equivalente electrónico');
  }
}

const RefundInfo = ({ refund, setting: { align } }) => {
  const refundType = getRefundType(refund);
  const fullNumber = get(refund, 'numberTemplate.fullNumber', null);
  const dateTime = getDateTime(refund);
  const dueDate = get(refund, 'dueDate', null);

  return (
    <div className={`w-100 text-${align} py-2 d-flex flex-column border-bottom`}>
      <p>{refundType} N° {fullNumber}</p>
      <p><strong>{I18n.get('generationDate', 'Fecha de generación')}</strong> {dateTime}</p>
      {
        !!dueDate ? <p><strong>{I18n.get('dueDate', 'vencimiento')}</strong> {dueDate}</p> : null
      }
    </div>
  )
}

export default RefundInfo;