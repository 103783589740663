import es from './es'

const dictionary = {
  ...es,
  "AlegraAccounting": "Contabilidad",
  "AlegraAccounting.description": "Gestiona las cuentas de tu negocio",
  "AlegraPOS": "Punto de venta",
  "AlegraPOS.description": "Factura fácil en tus puntos de venta",
  "AlegraShop.description": "Crea tu tienda virtual fácil y rápido",
  "allInvoicesMadeFrom": "Todos los tiquetes realizados desde",
  "cashToInvoices": "Crédito a tiquetes",
  "changeInvoiceError": "No pudimos modificar el tiquete. Inténtalo de nuevo",
  "choosePlanNote2": "En todos los planes podrás generar documentos electrónicos",
  "clientInvoice": "Tiquete del cliente",
  "companySettingSubtitle": "Actualiza los datos de tu negocio que aparecerán en tus tiquetes",
  "createFirstInvoice": "Crear primer tiquete",
  "createInvoiceError": "Error en la creación de tiquete",
  "creditToInvoice": "Crédito a tiquete",
  "currentShiftDetails.description": "Conoce los movimientos de efectivo en tu turno de caja actual.",
  "decimalPrecisionTooltip": "Selecciona el número de decimales que utilizarás para los precios de tus productos y valores de tus tiquetes",
  "editInvoice": "Editar tiquete",
  "electronicTicket": "Tiquete electrónico",
  "electronicPaymentsTotalMustBeLTETotal": "El total de los pagos electrónicos debe ser igual o menor al total del tiquete",
  "electronicSalesInvoice": "Tiquete electrónico de venta",
  "emissionStatus": "Estado D.G.T",
  "immediatePaymentSale": "Ventas de contado",
  "includeAccountStatementSubtitle": "Al enviar un tiquete al correo, esta irá acompañada del resumen de todos sus tiquetes",
  "invoice": "Factura",
  "invoiceModalTitle.saleTicket": "Tiquete",
  "invoiceModalTitle.invoice": "Factura",
  "invoiceNumbering": "Numeración de tiquete",
  "saleTicket": "Tiquete",
  "invoicePrintSettingSubtitle": "Elige los parámetros que utilizarás y serán visibles en tus tiquetes.",
  "invoiceSaved": "Factura guardada",
  "invoiceSavedInOffline": "Tu tiquete se está sincronizando, no te detengas, sigue facturando",
  "invoicesPerMonth": "tiquetes por mes",
  "invoiceSyncErrorSubtitle": "Uno de los tiquetes de venta está pendiente por sincronizar. Más detalles",
  "invoiceType": "Tipo de tiquete",
  "learnToUseShifts.link": "https://ayuda.alegra.com/es/gestiona-los-turnos-de-caja-pos-costa-rica",
  "multicurrencyFieldHelp": "Genera tus tiquetes POS en diferentes monedas",
  "networkErrorMessage": "Revisa tu conexión a internet e intenta emitir de nuevo tu documento",
  "newClientSubtitle": "Crea los contactos que asociarás en tus tiquetes de venta.",
  "notItemsYet": "Para crear tu primera venta te invitamos a agregar un producto o servicio. 🏷️",
  "noSelectedInvoice": "Selecciona un tiquete para conocer el detalle o crea una nueva venta ahora.",
  "onboarding.video.url":"https://www.youtube.com/watch?v=nMxAI9_G28w",
  "onboarding.video.id":"nMxAI9_G28w",
  "payInvoice": "Pagar tiquete",
  "pendingInvoiceSettingsTutorial": "Crea varios tiquetes a la vez para manejar por ejemplo el control de mesas.",
  "planIncomeFeature": "Ingresos hasta {}{} al mes",
  "planInvoiceUnlimitedFeature": "Facturas y tiquetes ilimitados al mes",
  "preInvoice": "Pre-Tiquete",
  "printDescription": "Incluir descripción de tus productos en tus tiquetes",
  "printedRepresentationOfElectronicInvoice": "Representación impresa de tiquete electrónico",
  "printLogo": "Agregar tu logo en la impresión de tus tiquetes",
  "printTemplateHelp": "Selecciona la plantilla para la impresión de tus tiquetes.",
  "printPersonalizedInvoices": "Personalización de documentos",
  "printPersonalizedInvoicesHelp": "Dale tu toque a la parte final de tus tiquetes y facturas con una frase, imagen o ambas.",
  "productKeyHelp": "Conoce los {} para los productos y servicios",
  "productKeyHelp1": "códigos definidos en el Cabys",
  "referenceHelp": "Código de máximo <b>20 caracteres</b> que te servirá para identificar tus {}s.",
  "renamePendingInvoice": "Renombrar tiquete pendiente",
  "saleTicketSaved": "Tiquete guardado",
  "selectProductsToCreateYourInvoice": "Selecciona al menos un producto para crear tu tiquete",
  "sellerCreatedSuccessfullyMessage": "Ya puedes asociar en tus tiquetes a {} como vendedor.",
  "ticketSyncErrorOfflineSubtitle": "Podrás consultar el tiquete en el historial de ventas en cuanto recuperes tu conexión.",
  "youHavePendingInvoicesToEmit": "¡Tienes ventas por emitir! 🚨",
  "youHavePendingInvoicesToEmit.description": "Ingresa a las facturas o tiquetes con estado de emisión “Por emitir” y envíalas a Hacienda con un clic.",
  "youHavePendingInvoicesToEmit.link": "https://ayuda.alegra.com/es/conoce-estado-emision-facturas-alegra-pos-costa-rica",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Crea tus facturas en segundos",
  "AlegraAccounting.new": "Contabilidad",
  "AlegraAccounting.description.new": "Contabiliza, factura y gestiona tu stock",
  "AlegraPOS.new": "POS",
  "AlegraPOS.description.new": "Agiliza tu ventas y controla tu efectivo",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "electronicPayrollHabilitation": "Conoce cómo habilitarte para emitir facturas y boletas electrónicas.",
  "electronicPayrollTutorial.video.id": "j1kgBN4Ngn4",
  "helpedArticle": "Ir a habilitarme",
  "youCanNowMakeElectronicInvoices.description": "Actívate ante Hacienda y empieza a crear facturas electrónicas en tu punto de venta.",
  "AlegraInvoicing.description.new": "Crea tus facturas en segundos",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Contabilidad para que los importes de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Contabilidad controlas tus ingresos y gastos con un clic y obtienes reportes inteligentes para que estés siempre al día con el Ministerio de Hacienda.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.invoice.supTitle": "Ahorra tiempo con",
  "solutionModal.invoice.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.invoice.buttonSecondaryText": "Más información",
  "solutionModal.invoice.description": "Genera en segundos tus facturas y tiquetes con el software de Facturación más seguro del país. 100% adaptado a la normatividad del Ministerio de Hacienda.",
  "solutionModal.invoice.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Factura en segundos con el Software Punto de Venta más veloz del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "userNotAllowed.edit.saleTicket": "Necesitas permiso del administrador para editar tiquetes.",
  "userNotAllowed.edit.invoice": "Necesitas permiso del administrador para editar facturas."
}

export default dictionary;