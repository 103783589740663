import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';

import Company from '../../forms/company/Company';
import CarretSVG from '../../svg/CarretDown';

const FormBody = props => { 
  const [section, setSection] = useState('main')

  return (
    <>
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <div 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('main')}
        >
          {I18n.get('generalInfo', 'información general')}
          {<CarretSVG className="icon-primary" />}
        </div>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom ${section === 'main'? 'show': ''}`}>
          <Company.BasicData {...props}/>
        </div>
      </div>
      
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <p 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('advanced')}
        >
          {I18n.get('POSSetting', 'configuración de POS')}
          {<CarretSVG className="icon-primary" />}
        </p>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('POSSettingTutorial', 'aquí puedes configurar los datos que intervienen directamente con el POS')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom ${section === 'advanced'? 'show': ''}`}>
          <Company.POSSettings {...props}/>
        </div>
      </div>
      
    </>
  )
}

export default FormBody;
