import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import alegraAPI from "../../../reducers/alegraAPI";
import { useRefresh } from "../../../hooks/queryHooks/useRefresh";
import { useCancel } from '../../../hooks/queryHooks/useCancel';
import { get } from 'lodash';
import { replaceAndParse, toast } from '../../../utils';
import { I18n } from '@aws-amplify/core';
import { handleError } from '../../../utils/errors';
import { addVariant, removeVariant, updateVariant as updateVariantReducer } from "../../../reducers/variants";
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';

const deleteVariant = async ({ id, variant }) => {
  await alegraAPI.delete(`/variant-attributes/${id}`);
  return { id, variant }
};

const createVariant = async ({ variant }) => {
  const response = await alegraAPI.post('/variant-attributes',
    variant,
  );

  return response;
};

const updateVariant = async ({ variant }) => {
  const response = await alegraAPI.put(`/variant-attributes/${variant?.id}`, {
    ...variant,
    id: +variant?.id
  });

  return response;
};

export const useDeleteVariant = () => {
  const queryClient = useQueryClient();
  const cancel = useCancel();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['deleteVariant'], {
    mutationFn: deleteVariant,
  });

  return useMutation({
    mutationKey: ['deleteVariant'],
    mutationFn: deleteVariant,
    onMutate: async () => {
      await cancel('variants');
    },
    onError: (error) => {
      const responseError = get(error, 'response.data.message', '');

      toast.error({
        title: I18n.get('variantDeletedError', 'Error eliminando la variante'),
        subtitle: responseError ?? handleError(error),
      })
    },
    onSuccess: (_, variables) => {
      toast.success({
        title: I18n.get('variantDeletedSuccessfully', 'La variante ya está eliminada.'),
      });

      dispatch(removeVariant(get(variables, 'id')));
    },
    onSettled: () => {
      refresh('variants');
    }
  })
};

export const useUpdateVariant = () => {
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const cancel = useCancel();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['updateVariant'], {
    mutationFn: updateVariant
  });

  return useMutation({
    mutationKey: ['updateVariant'],
    mutationFn: updateVariant,
    onMutate: async () => {
      await cancel('variants');
    },
    onSuccess: (data, variables) => {
      dispatch(updateVariantReducer({
        id: get(variables, 'variant.id'),
        changes: get(data, 'data')
      }));
    },
    onSettled: () => {
      refresh('variants');
    }
  })
};

export const useCreateVariant = () => {
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const cancel = useCancel();
  const dispatch = useDispatch();

  queryClient.setMutationDefaults(['createVariant'], {
    mutationFn: createVariant
  });

  return useMutation({
    mutationKey: ['createVariant'],
    mutationFn: createVariant,
    onMutate: async () => {
      await cancel('variants');
    },
    onError: (error) => {
      const responseError = get(error, 'response.data.message');
      dispatch(
        sendNewGTMEvent('pos-variant-created', {
          error: handleError(error)
        })
      );

      toast.error({
        title: I18n.get('createVariantError', 'Hubo un error creando la variante'),
        subtitle: responseError ?? handleError(error)
      });
    },
    onSuccess: (data, variables) => {
      toast.success({
        title: `${I18n.get(
          'variantCreatedSuccessfully',
          'Ya está lista. 👈'
        )}`,
        subtitle: replaceAndParse(
          I18n.get(
            'variantCreatedSuccessfullyMessage',
            'Tu variante {} está disponible para asociarla a tus productos.'
          ),
          [
            `<span class="font-weight-bold">${get(
              variables,
              'variant.name',
              ''
            )}</span>`,
          ]
        ),
      });

      dispatch(sendGTMEvent('variant-created'));
      dispatch(sendNewGTMEvent('pos-variant-created', {
        error: 'no error'
      }));
      dispatch(addVariant(get(data, 'data')));
    },
    onSettled: () => {
      refresh('variants');
    }
  })
};
