import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import React from 'react'
import Tooltip from 'rc-tooltip'
import TicketSVG from '../../svg/Ticket';
import TrashSVG from '../../svg/NewTrash';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem } from '../../../reducers/activeInvoice';
import { renderErrors } from '../../home/NewActiveInvoice/utils';
import { country as countrySelector } from '../../../selectors/company';
import { station as stationSelector, electronicInvoicing } from '../../../selectors/app'
import { numeration as numerationSelector } from '../../../selectors/activeInvoice'

const ItemInfo = ({ item, index, handleClose }) => {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector)
  const numeration = useSelector(numerationSelector)
  const station = useSelector(stationSelector)
  const isElectronic = useSelector(electronicInvoicing(numeration))

  const handleDelete = () => {
    dispatch(removeItem(index))
    handleClose()
  }

  return (
    <>
      <div className="item-info p-3">
        <div className="item-info__image ml-4 mt-4 d-flex align-items-center">
          {item.images
            ? <img src={item.images[0].url} alt={item.name} />
            : <TicketSVG className="icon-gray icon x2" />}
        </div>
        <div className="item-info__detail mt-4 ml-4">
          <p className="item-info__detail-product-type">
            {I18n.get(item.type === 'service'
              ? 'service'
              : item.type === 'kit'
                ? 'kit'
                : 'product')}
          </p>
          <p className="item-info__detail-product-name">{item.name}</p>
          {(!!get(item, 'reference.reference') || !!get(item, 'reference')) && (
            <p className="item-info__detail-product-reference">
              {get(item, 'reference.reference') || get(item, 'reference')}
            </p>
          )}
        </div>
        <Tooltip
          tooltipActive={true}
          placement="top"
          overlay="Quitar">
          <div className="item-info__delete mt-4 mr-4"
            onClick={handleDelete}>
            <TrashSVG className="mt-2 mr-2 x2" />
          </div>
        </Tooltip>
      </div>
      {renderErrors({ item, country, station, isElectronic }) && (
        <div className="pl-4">
          {renderErrors({ item, country, station, isElectronic })}
        </div>
      )}
      <div className="mx-3 mt-1">
        <div className="mx-4" style={{ background: "#E2E8F0", height: "1px" }} />
      </div>
    </>
  )
}

export default ItemInfo