import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { hasPermissionTo } from '../selectors/auth'
import { handleError } from '../utils/errors'
import { fetch } from '../utils'
import { APIGraphqlSelector } from '../selectors/app';
import alegraAPI from '../reducers/alegraAPI';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchPriceListsPartial = (api) => async (metadata, params) => {
  // ex queries.allPriceLists
  const response = await alegraAPI.get('/price-lists', {
      metadata: true,
      fields: 'deletable,editable',
      ...metadata,
      ...params
  })

  const data = get(response, 'data.data', null)
  const total = get(response, 'data.metadata.total', 0)
  if (data)
    return { data, total };
  return { data: [], total };
}

export const fetchPriceLists = createAsyncThunk(
  'priceLists/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const can = hasPermissionTo(getState())
      const APIGraphql = APIGraphqlSelector(getState());
      if (!can('view', 'price-lists') || !can('index', 'price-lists'))
        return []

      return await fetch(fetchPriceListsPartial(APIGraphql), !!preloaded ? preloaded : [], params)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'priceLists',
  initialState,
  reducers: {
    addPriceList: adapter.addOne,
    updatePriceList: adapter.updateOne,
    removePriceList: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchPriceLists.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchPriceLists.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchPriceLists.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { actions, reducer } = appSlice;

export const { addPriceList, updatePriceList, removePriceList } = actions;

export const priceListsSelector = adapter.getSelectors(state => state.priceLists);

export default reducer;