// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 0.25C12.5858 0.25 12.25 0.585786 12.25 1C12.25 1.41421 12.5858 1.75 13 1.75H15.1893L10.4697 6.46967C10.1768 6.76256 10.1768 7.23744 10.4697 7.53033C10.7626 7.82322 11.2374 7.82322 11.5303 7.53033L16.25 2.81066V5C16.25 5.41421 16.5858 5.75 17 5.75C17.4142 5.75 17.75 5.41421 17.75 5V1C17.75 0.808058 17.6768 0.616117 17.5303 0.46967C17.4584 0.397763 17.3755 0.343509 17.2871 0.306909C17.1987 0.270239 17.1017 0.25 17 0.25H13Z"
      fill="#94A3B8"
    />
    <path
      d="M1 12.25C1.41421 12.25 1.75 12.5858 1.75 13V15.1893L6.46967 10.4697C6.76256 10.1768 7.23744 10.1768 7.53033 10.4697C7.82322 10.7626 7.82322 11.2374 7.53033 11.5303L2.81066 16.25H5C5.41421 16.25 5.75 16.5858 5.75 17C5.75 17.4142 5.41421 17.75 5 17.75H1C0.808058 17.75 0.616117 17.6768 0.46967 17.5303C0.397763 17.4584 0.343509 17.3755 0.306909 17.2871C0.270239 17.1987 0.25 17.1017 0.25 17V13C0.25 12.5858 0.585786 12.25 1 12.25Z"
      fill="#94A3B8"
    />
    <path
      d="M17.75 13V17C17.75 17.1017 17.7298 17.1987 17.6931 17.2871C17.6565 17.3755 17.6022 17.4584 17.5303 17.5303C17.4584 17.6022 17.3755 17.6565 17.2871 17.6931C17.2099 17.7251 17.1262 17.7446 17.0386 17.749C17.0258 17.7497 17.0129 17.75 17 17.75H13C12.5858 17.75 12.25 17.4142 12.25 17C12.25 16.5858 12.5858 16.25 13 16.25H15.1893L10.4697 11.5303C10.1768 11.2374 10.1768 10.7626 10.4697 10.4697C10.7626 10.1768 11.2374 10.1768 11.5303 10.4697L16.25 15.1893V13C16.25 12.5858 16.5858 12.25 17 12.25C17.4142 12.25 17.75 12.5858 17.75 13Z"
      fill="#94A3B8"
    />
    <path
      d="M0.712912 0.306909C0.801342 0.270239 0.898308 0.25 1 0.25H5C5.41421 0.25 5.75 0.585786 5.75 1C5.75 1.41421 5.41421 1.75 5 1.75H2.81066L7.53033 6.46967C7.82322 6.76256 7.82322 7.23744 7.53033 7.53033C7.23744 7.82322 6.76256 7.82322 6.46967 7.53033L1.75 2.81066V5C1.75 5.41421 1.41421 5.75 1 5.75C0.585786 5.75 0.25 5.41421 0.25 5V1C0.25 0.793702 0.333292 0.606858 0.468082 0.471263L0.46967 0.46967L0.471263 0.468082C0.5428 0.396963 0.625106 0.343239 0.712912 0.306909Z"
      fill="#94A3B8"
    />
  </svg>
);