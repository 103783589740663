import { useEffect, useMemo, useState, useRef, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Field, useForm, useFormState } from "react-final-form"
import { I18n } from "aws-amplify"
import { get } from "lodash"
import { graphqlOperation } from "aws-amplify"
import BigNumber from "bignumber.js"

import * as queries from "../../../../graphql/queries"
import { useFormat } from "../../../../hooks/useFormat"
import { APIGraphqlSelector, stationCostCenter } from '../../../../selectors/app';
import { getRefundNumerationsElectronic, getRefundNumerationsNoElectronic, getAdjustmentNoteNumerations } from "../../../../selectors/numerations";
import { getCreditNoteTypeLabel } from "../utils"
import { renderSelect, renderDate, renderField } from "../../fields/V0/Fields"
import newCreditNoteTypes from "../../../countriesData/colombia/newCreditNoteTypes"

import { SelectClient } from "./SelectClients"
import { setNumeration } from "../../../../reducers/activeRefund";
import { isAccepted } from "../utils";
import Warning from "../../../svg/Warning";

const getLabelCreditNoteType = (invoice) => {
  const typeNumeration = get(invoice, 'numberTemplate.documentType', null);
  const isElectronic = !!get(invoice, 'numberTemplate.isElectronic', false);
  if (typeNumeration === 'saleTicket' && !!isElectronic) return I18n.get('typeAdjustmentNote', 'Tipo de nota de ajuste');
  return I18n.get('typeCreditNote', 'Tipo de nota crédito');
}

export const CreditToSales = ({ changeStep }) => {
  const { values } = useFormState();
  const { decimal, fmt } = useFormat();
  const dispatch = useDispatch();
  const form = useForm();
  const today = useMemo(() => new Date(), []);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeNumeration, setTypeNumeration] = useState("");
  const costCenter = useSelector(stationCostCenter) || {};
  const [numerations, setNumerations] = useState([]);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const ref = useRef();

  const invoiceSelected = useSelector(state => get(state, 'modals.newRefunds.params.invoice', null));
  const numerationsEletronic = useSelector(getRefundNumerationsElectronic);
  const numerationsNoElectronic = useSelector(getRefundNumerationsNoElectronic);
  const numerationsSaleTicket = useSelector(getAdjustmentNoteNumerations);

  useEffect(() => {
    if (!!invoiceSelected) {
      const invoiceTypeNumeration = get(invoiceSelected, 'numberTemplate.documentType', null);
      const invoiceSelectedIsElectronic = !!get(invoiceSelected, 'numberTemplate.isElectronic', false);
      if (invoiceTypeNumeration === 'saleTicket') setNumerations(numerationsSaleTicket)
      if (invoiceTypeNumeration === 'invoice' && invoiceSelectedIsElectronic) setNumerations(numerationsEletronic)
      if (invoiceTypeNumeration === 'invoice') setNumerations(numerationsEletronic)
      return;
    }
    if (typeNumeration === 'saleTicket') setNumerations(numerationsSaleTicket)
    if (typeNumeration === 'creditNote') setNumerations(numerationsEletronic)
    if (typeNumeration === 'noElectronic') setNumerations(numerationsNoElectronic)
  }, [typeNumeration, numerationsSaleTicket, numerationsEletronic, invoiceSelected])

  const isElectronic = useMemo(() => {
    return !!get(values, 'refund.document.numberTemplate.isElectronic', false);
  }, [values?.refund?.document]);

  const isAllFieldsFilled = () => {
    const client = get(values, 'refund.client', false);
    const name = get(values, 'refund.document', false);
    const date = get(values, 'refund.date', false);
    const numeration = get(values, 'refund.numeration', false);

    return !!client && !!name && !!date && !!numeration;
  }

  useEffect(() => {
    let step = 1;
    if (isAllFieldsFilled())
      step = 2;
    changeStep(step);

  }, [values]);

  useEffect(() => {
    ref.current = true;
    if (!invoiceSelected)
      search();
    return () => ref.current = false;
  }, [values?.refund?.client]);

  const search = useCallback(async () => {
    if (!values?.refund?.client) return;
    setDocuments([]);
    form.change('refund.document', null);
    setTypeNumeration(null);
    form.change('refund.numeration', null);

    if (ref.current)
      setLoading(true);

    try {
      const response = await APIGraphql(graphqlOperation(queries.getClientInvoices, {
        clientName: values.refund.client.name
      }));

      const dataDocuments = get(response, 'data.getClientInvoices.data', [])
        .filter(d => {
          const invoiceCostCenter = d.costCenter || {};
          const invoiceTypeNumeration = get(d, 'numberTemplate.documentType', null);
          const isElectronic = !!get(d, 'numberTemplate.isElectronic', false);
          let isEligible = true;
          const stamp = get(d, 'stamp', null);

          if (isElectronic && invoiceTypeNumeration === 'saleTicket')
            isEligible = false;

          if (isElectronic && invoiceTypeNumeration !== 'saleTicket') {
            if (!stamp) isEligible = false;
            else isEligible = isAccepted(stamp);
          }

          return (!invoiceCostCenter || invoiceCostCenter.id === costCenter.id) && isEligible;
        });

      setDocuments(dataDocuments);
    } catch {
      return [];
    } finally {
      if (ref.current)
        setLoading(false);
    }
  }, [APIGraphql, values?.refund?.client]);

  return (
    <>
      <div className="row m-0 overflow-hidden">
        <SelectClient numerations={numerations} changeNumeration={false} />

        <Field
          type="select"
          name="refund.document"
          className="col-12 col-md-6 p-0 pl-md-2 new-refund-input select-refund"
          placeholder={I18n.get('select', 'Seleccionar')}
          options={documents}
          isLoading={loading}
          component={renderSelect}
          getOptionValue={(option) => option.numberTemplate.fullNumber}
          getOptionLabel={option => {
            const balance = new BigNumber(option.total);
            const fullNumber = option.numberTemplate.fullNumber;

            return (
              <div className="d-flex justify-content-between">
                <span className='document-fullNumber' style={{ display: 'none', color: "#0F172A"}}>
                  {fullNumber}
                </span>
                <span className='refund-document-label mb-0 mr-2' style={{ fontWeight: 600, color: "#0F172A"}}>
                  {fullNumber}
                </span>
                <span className='refund-document-balance-label text-wrap mb-0' style={{ fontSize: 12, color: "#64748B" }}>
                  {balance.toFormat(decimal, fmt)}
                </span>
              </div>
            )
          }}
          onChange={(values) => {
            const invoiceSelectedIsElectronic = !!get(values, 'numberTemplate.isElectronic', false);
            if (values.numberTemplate.documentType === 'saleTicket' && invoiceSelectedIsElectronic) {
              setTypeNumeration('saleTicket')
              if (numerationsSaleTicket.length > 0) {
                const newNumeration = numerationsSaleTicket.filter(n => n.isDefault)[0]
                form.change('refund.numeration', newNumeration)
              } else {
                form.change('refund.numeration', null)
              }
            } else if (values.numberTemplate.documentType === 'invoice' && invoiceSelectedIsElectronic) {
              setTypeNumeration('creditNote')
              if (numerationsEletronic.length > 0) {
                const newNumeration = numerationsEletronic.filter(n => n.isDefault)[0]
                form.change('refund.numeration', newNumeration)
              } else {
                form.change('refund.numeration', null)
              }
            } else {
              setTypeNumeration('noElectronic')
              if (numerationsNoElectronic.length > 0) {
                const newNumeration = numerationsNoElectronic.filter(n => n.isDefault)[0]
                form.change('refund.numeration', newNumeration)
              } else {
                form.change('refund.numeration', null)
              }
            }
            return values
          }}
          disabled={!!invoiceSelected}
          required
          label={I18n.get('associatedDocument', 'Documento asociado')}
        />

        <Field
          type="select"
          name="refund.numeration"
          component={renderSelect}
          noOptionsMessage={() => {
            let text = '';
            if (typeNumeration === 'saleTicket') text = I18n.get('noResultsSaleTicket', 'No tienes ninguna numeración de nota de ajuste');
            else if (typeNumeration === 'creditNote') text = I18n.get('noResultsElectronic', 'No tienes ninguna numeración de nota crédito electrónica');
            else return I18n.get('noResultsWereFound', 'No se encontraron resultados.');
            
            return (
              <div className="w-100 d-flex">
                <div className="col-2 pr-0"><Warning /></div>
                <div className="d-flex flex-column">
                  <p className="warning-text mb-1">{text}</p>
                  <a className="warning-text" href="https://ayuda.alegra.com/es/configuraci%C3%B3n-de-la-numeraci%C3%B3n-de-tus-comprobantes-en-alegra" target="_blank" rel="noreferrer">
                    {I18n.get('learnToCreateIt', 'Aprender a crearla')}
                  </a>
                </div>
              </div>
            )
          }}
          options={!!numerations.length ? numerations : []}
          onChange={option => {
            dispatch(setNumeration(option));
            return option;
          }}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          className="col-12 col-md-6 p-0 pr-md-2 new-refund-input select-refund"
          required
          cacheOptions={false}
          label={I18n.get('numeration', 'Numeración')}
        />

        <Field
          name="refund.date"
          className="col-12 col-md-6 select-refund date-refund no-padding p-0 pl-md-2"
          component={renderDate}
          defaultValue={today}
          required
          label={I18n.get('date', 'Fecha')}
          portalId="refund-modal-portal"
        />
      </div>

      {
        isAllFieldsFilled() && (
          <div className="row m-0 appear-element">
            <div className="divider"></div>
            {
              !!isElectronic &&(
                <Field
                  type="select"
                  name="refund.creditNoteType"
                  component={renderSelect}
                  options={newCreditNoteTypes}
                  getOptionLabel={option => getCreditNoteTypeLabel(get(values, 'refund.document'), option)}
                  getOptionValue={option => option.key}
                  className="col-12 col-md-6 p-0 pr-md-2 select-refund new-refund-input"
                  required
                  label={getLabelCreditNoteType(get(values, 'refund.document'))}
                />
              )
            }

            <Field
              type="text"
              name="refund.cause"
              component={renderField}
              className={`col-12 ${!!isElectronic && 'col-md-6 pl-md-2 '} p-0 select-refund new-refund-input`}
              label={I18n.get('reasonRefund', 'Razón')}
            />

            <Field
              type="text"
              name="refund.note"
              component={renderField}
              className="col-12 p-0 select-refund new-refund-input"
              helpTooltip={I18n.get('notesHelpTooltip', 'Visible en la impresión del documento')}
              label={I18n.get('notes', 'Notas')}
            />
          </div>
        )
      }
    </>
  )
}