// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M6.72951 5.68404C7.13243 5.30631 7.76526 5.32672 8.14299 5.72964L12.4135 10.2848L16.6839 5.72964C17.0616 5.32672 17.6945 5.30631 18.0974 5.68404C18.5003 6.06177 18.5207 6.69461 18.143 7.09752L13.7842 11.7469L18.143 16.3963C18.5207 16.7992 18.5003 17.4321 18.0974 17.8098C17.6945 18.1875 17.0616 18.1671 16.6839 17.7642L12.4135 13.209L8.14299 17.7642C7.76526 18.1671 7.13243 18.1875 6.72951 17.8098C6.3266 17.4321 6.30619 16.7992 6.68392 16.3963L11.0427 11.7469L6.68392 7.09752C6.30619 6.69461 6.3266 6.06177 6.72951 5.68404Z"
    />
  </svg>
)
