// eslint-disable-next-line
export default ({ id, className, width, height, fill }) => (
  <svg
    id={id}
    className={className}
    width={width ?? "18"}
    height={height ?? "18"}
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 0.921875C12.5509 0.921875 11.6408 1.29889 10.9697 1.96997L9.97531 2.96433C9.97342 2.96618 9.97154 2.96804 9.96967 2.96991C9.9678 2.97179 9.96594 2.97367 9.96409 2.97556L0.46967 12.47C0.329018 12.6106 0.25 12.8014 0.25 13.0003V17.0003C0.25 17.4145 0.585786 17.7503 1 17.7503H5C5.19891 17.7503 5.38968 17.6713 5.53033 17.5306L16.0303 7.03063C16.7014 6.35955 17.0784 5.44936 17.0784 4.5003C17.0784 3.55124 16.7014 2.64106 16.0303 1.96997C15.3592 1.29889 14.4491 0.921875 13.5 0.921875ZM10.5 4.56093L1.75 13.311V16.2503H4.68934L13.4394 7.50027L10.5 4.56093ZM14.5 6.43961L14.9697 5.96997C15.3595 5.58019 15.5784 5.05154 15.5784 4.5003C15.5784 3.94907 15.3595 3.42041 14.9697 3.03063C14.5799 2.64085 14.0512 2.42188 13.5 2.42188C12.9488 2.42188 12.4201 2.64085 12.0303 3.03063L11.5607 3.50027L14.5 6.43961Z"
      fill={fill ?? "#94A3B8"} />
  </svg>
)