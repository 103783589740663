import React from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";

let TooltipComponent = ({
  children,
  tooltipActive,
  containerStyles,
  ...props
}) => {
  if (!tooltipActive) return children;

  return (
    <Tooltip {...props}>
      <span style={containerStyles ? containerStyles : {}}>{children}</span>
    </Tooltip>
  );
};

TooltipComponent.propTypes = {
  tooltipActive: PropTypes.bool,
};

export default TooltipComponent;
