import React, { useEffect, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';

import * as mutations from '../../graphql/mutations';
import notificationsAPI from '../../reducers/notificationsAPI';
import { setUser } from '../../reducers/auth';
import { userSelector } from '../../selectors/auth';
import {
  companySelector,
  country as countrySelector,
  membershipSelector,
  notificationsSelector,
} from '../../selectors/company';
import { notificationsTransformer } from './utils';
import { APIGraphqlSelector } from '../../selectors/app';
import useElectronicPayrollStatus from '../../hooks/useElectronicPayrollStatus/hook';
import { openModal } from '../../reducers/modals';

const ActivateFETutorialNotification = () => {
  const user = useSelector(userSelector);
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const { availableCountries } = useElectronicPayrollStatus();
  const notifications = useSelector(notificationsSelector);
  const hasFEactive = get(company, 'settings.electronicInvoicing', false);
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const membership = useSelector(membershipSelector);

  const selectedBody = useCallback(() => {
    return {
      userId: user.idGlobal.toString(),
      title: I18n.get(
        'youCanNowMakeElectronicInvoices',
        '¡Habilítate para emitir Facturas Electrónicas! 💥'
      ),
      text: I18n.get(
        'youCanNowMakeElectronicInvoices.description',
        'Actívate ante la DIAN en menos de 10 minutos y empieza a crear facturas electrónicas en tu punto de venta.'
      ),
      country,
      actionCall: 'activateFETutorial',
      actionLabel:
        country === 'argentina'
          ? I18n.get('goToTutorial', 'Ir al tutorial')
          : I18n.get('howToEnableMe', 'Cómo habilitarme'),
      type: 'info',
      actionType: 'event',
    };
  }, [user, country]);

  useEffect(() => {
    const handleHashChange = (hashChangeEvent) => {
      let hashValue = hashChangeEvent.newURL.split('/').at(-1);
      if (hashValue === '#activateFETutorial') {
        dispatch(openModal({ modal: 'electronicPayrollTutorial' }));
      }
    };
    async function checkUserFENotification() {
      const cookieName = `activate-fe-tutorial-${country}`;
      if (notifications.find((n) => n[cookieName])) return;
      const membershipPlan = get(membership, 'plan.keyword', '');
      if (
        !membershipPlan ||
        membershipPlan === '' ||
        membershipPlan.includes('demo') ||
        membershipPlan.includes('readonly')
      ) {
        return;
      }

      if (hasFEactive) return;
      if (!availableCountries.includes(country)) return;

      try {
        // create cookie to validate if the notification has been shown
        const response = await APIGraphql(
          graphqlOperation(
            mutations.updateUser,
            notificationsTransformer(cookieName)
          )
        );
        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')));
        }
        await notificationsAPI.post(
          '/notifications',
          notificationBody({
            ...selectedBody(),
          })
        );
      } catch (err) {
        console.log('error while creating notification', err);
      }
    }
    window.addEventListener('hashchange', handleHashChange);
    checkUserFENotification();
  }, [
    country,
    notifications,
    dispatch,
    selectedBody,
    APIGraphql,
    availableCountries,
    hasFEactive,
    membership,
  ]);

  return <></>;
};

const notificationBody = ({
  userId,
  from = null,
  to = null,
  section = null,
  title,
  text,
  actionCall,
  actionType,
  actionLabel,
  type,
  country,
}) => ({
  name: 'activate-fe-tutorial-' + country,
  teamInCharge: 'pos',
  receptors: {
    users: [userId],
  },
  availability: {
    from: from ?? dayjs().toISOString(),
    to: to ?? dayjs().add(1, 'days').toISOString(),
  },
  message: [
    {
      channel: 'web',
      type: 'localized',
      closeable: true,
      section: section ? section : 'index-pos-home',
      title,
      text,
      application: 'alegra-pos',
      action: {
        actionType,
        actionCall,
        actionLabel,
      },
      style: {
        type,
        icon: 'notification',
      },
    },
  ],
});

export default ActivateFETutorialNotification;
