import React, { useState } from "react";
import { I18n } from "@aws-amplify/core";

import Modal from "../../../../common/Modal";
import BackgroundSVG from "../../../../svg/header/ShopApp.svg";
import Loading from "../../../../svg/Loading";

const ShopModal = ({ isOpen, onClose, onRedirect }) => {
  const [loaded, setIsLoaded] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal__products-onboarding"
    >
      <div className="p-4 pb-5 d-flex h-100 overflow-hidden">
        {!loaded ? (
          <div
            className="d-flex justify-content-center align-items-center col-12"
            style={{ height: "300px" }}
          >
            <Loading className="icon-primary" />
          </div>
        ) : (
          <div className="body-content col-sm-7 col-12 d-flex flex-column justify-content-center py-md-4">
            <h1 className="text-primary mb-5">
              {I18n.get(
                "onboarding.shop.header",
                "¡Tu negocio siempre en línea con Alegra Tienda!"
              )}
            </h1>
            <p className="mb-5">
              {I18n.get(
                "onboarding.shop.message.1",
                "Empieza a vender por internet, promociona tus productos o servicios y mantén tu negocio"
              )}{" "}
              <b>
                {I18n.get(
                  "onboarding.shop.message.2",
                  "a solo un clic de distancia,"
                )}
              </b>{" "}
              {I18n.get(
                "onboarding.shop.message.3",
                "obteniendo más clientes con un nuevo canal de ventas."
              )}
            </p>
            <div className="">
              <a
                className="btn btn-primary px-4 text-uppercase"
                href={process.env.REACT_APP_ALEGRA_SHOP}
                target="_blank"
                rel="noreferrer"
                onClick={onRedirect}
              >
                {I18n.get("onboarding.shop.button", "Probar 15 días gratis")}
              </a>
            </div>
          </div>
        )}
        <div className="col-sm-5 col-0">
          <img
            src={BackgroundSVG}
            style={loaded ? {} : { display: "none" }}
            onLoad={() => {
              setIsLoaded(true);
            }}
            alt=""
          />
        </div>
      </div>
    </Modal>
  );
};

export default ShopModal;
