import React from 'react'
import { I18n } from '@aws-amplify/core'
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { get } from 'lodash'

import { decimalPrecision } from '../../../selectors/company';
import { getMainCurrency } from '../../../selectors/currencies';
import Item from '../../forms/item/Item';
import Bottom from './Bottom';

const Secondary = props => {
  const mainCurrency = useSelector(getMainCurrency)
  const decimal = useSelector(decimalPrecision)

  const { name, price, type } = props.values;

  const prefix = !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  return (
    <div className="new-item-secondary-view bg-white d-none d-lg-block">
      <Item.Image {...props} name="rightImage" />

      <div className="text-center py-2">
        <p className={`h3 ${!name ? 'text-muted' : ''}`}>
          {!!name ? name : I18n.get(`${type ? type : 'product'}Name`, 'nombre del producto')}
        </p>
        <p className={`text-capitalize-first h2 ${!price ? 'text-muted' : ''}`}>
          {new BigNumber(!!price ? price : 0).toFormat(decimal, fmt)}
        </p>
      </div>

      <div className="form-body p-3">
        <Item.RapidData {...props} />
      </div>

      <Bottom {...props} />
    </div>
  )
}

export default Secondary;