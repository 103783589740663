import React from 'react'
import { I18n } from '@aws-amplify/core';

const Bottom = ({ onClose, onConfirm, onOpenHelp, isWarningAlert }) => {
  return (
    <div className="modal__bottom w-100 p-3 pl-5 d-flex justify-content-end align-items-center shadow">
      <button
        type="button"
        className="btn btn-cancel text-btn-normal text-capitalize-first mr-4"
        onClick={() => onClose()}
      >
        {isWarningAlert
          ? I18n.get("continue", "continuar")
          : I18n.get("deactivate5UVT.cancel", "Mantenerlo activo")}
      </button>
      <button
        type="button"
        className="btn btn-submit text-btn-normal text-capitalize-first"
        onClick={isWarningAlert ? () => onOpenHelp() : () => onConfirm()}
      >
        {isWarningAlert
          ? I18n.get("moreInfo", "Más información")
          : I18n.get("deactivate5UVT.continue", "Desactivar cambio automático")}
      </button>
    </div>
  )
}

export default Bottom;