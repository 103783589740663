import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import BigNumber from 'bignumber.js';
import alegraAPI from '../../../reducers/alegraAPI';
import { capitalize, get } from 'lodash';
import { useHistory } from 'react-router-dom';

import Actions from './Actions';
import { handleError } from '../../../utils/errors';
// import { toast } from '../../../utils';
// import Switch from '../../common/Switch';
import { openModal } from '../../../reducers/modals';
import {
  hasPermissionTo,
  isAdmin as isAdminSelector,
} from '../../../selectors/auth';
import {
  country as countrySelector,
  decimalPrecision,
} from '../../../selectors/company';
import { getMainCurrency } from '../../../selectors/currencies';
import { stationWarehouse } from '../../../selectors/app';
import Tooltip from '../../common/Tooltip';
import Table from '../../common/ItemsTable';
import MultipleItemsActions from './MultipleItemsActions';
import Filters from './Filters';
import WarehouseFilter from './WarehouseFilter';

import InfoSVG from '../../svg/InfoOutline';
import Header from '../../settings/common/Header';
import Plus from '../../svg/Plus';
import Filter from '../../svg/Filter';
import ArrowRightUp from '../../svg/new/ArrowRightUp';
import Box from '../../svg/new/Box';
import { getInventoryHelpUrl } from '../../../utils';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import Button from '../../common/Button';
import Typography from '../../common/Typography';

const Items = () => {
  const connectionStatus = useConnectionStatus();
  const history = useHistory();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const currentWarehouse = useSelector(stationWarehouse);
  const isAdmin = useSelector(isAdminSelector);
  const country = useSelector(countrySelector);

  const ref = useRef(null);
  const [data, setData] = useState([]);
  const [pageCount, setControlledPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    order_field: 'name',
    order_direction: 'ASC',
    fields: 'deletable,editable,settingsOnShop,inventory.availableQuantity',
  });
  const prefix = !!get(mainCurrency, 'symbol')
    ? get(mainCurrency, 'symbol')
    : '';
  const fmt = useMemo(
    () => ({
      prefix,
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    }),
    [prefix]
  );

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      event: 'VirtualPageView',
      virtualPageURL: '/inventory/items',
      virtualPageTitle: 'Items',
    });

    return () => (ref.current = false);
  }, []);

  const startLoading = useCallback(() => {
    if (ref.current) {
      setLoading(true);
    }
  }, []);

  const stopLoading = useCallback(() => {
    if (ref.current) {
      setLoading(false);
    }
  }, []);

  const fetchData = useCallback(
    async ({ pageIndex, pageSize }) => {
      startLoading();
      setError(null);

      try {
        const response = await alegraAPI.get('/items', {
          ...filters,
          metadata: true,
          start: pageIndex * pageSize,
          limit: pageSize,
          idWarehouse: !!get(filters, 'idWarehouse', null)
            ? get(filters, 'idWarehouse')
            : isAdmin
            ? null
            : get(currentWarehouse, 'id', null),
          mode: 'simple',
        });

        stopLoading();

        if (ref.current) setData(get(response, 'data.data', []));

        setControlledPageCount(
          Math.ceil(get(response, 'data.metadata.total', 0) / pageSize)
        );
      } catch (error) {
        stopLoading();
        setError(
          handleError(
            error,
            I18n.get(
              'itemsLoadError',
              'Sucedió un error cargando los productos'
            )
          )
        );
      }
      if (ref.current) {
        setTableIndicators({ pageIndex, pageSize });
      }
    },
    [stopLoading, startLoading, filters, isAdmin, currentWarehouse]
  );

  // const toggleItemVisiblity = async (id, status) => {
  //   startLoading()
  //   try {
  //     stopLoading()
  //     toast.success({
  //       title: I18n.get('editItemSuccessfully', '¡Ya se aplicaron tus cambios!')
  //     })
  //   } catch (err) {
  //     stopLoading()
  //     toast.error({
  //       title: I18n.get('itemStatusError', 'error cambiando estado del item'),
  //       subtitle: handleError(err)
  //     })
  //   }
  // }

  const columns = useMemo(
    () => [
      {
        Header: I18n.get('name', 'Nombre'),
        sortable: false,
        accessor: 'name',
        Cell: ({ value, row }) => <>
          <p
            className={`h5 text-truncate styled-hover m-0 ${can('edit', 'items') && 'text-primary  pointer'}`}
            onClick={() => {
              if (!get(row, 'original.editable', true) || !can('edit', 'items')) return null;
              history.push(`/items/edit/${row.original.id}`);
            }}
            hovercontent={value}
          >
            {value}
          </p>
          {get(row, 'original.type') === 'variant' && (
            <Tooltip
              tooltipActive={true}
              overlay={
                <p className='text-capitalize-first'>
                  {I18n.get(
                    'updateItemVariantHelp',
                    'La variante del producto no se puede editar directamente, edite el producto original.'
                  )}
                </p>
              }
            >
              <InfoSVG className='icon-secondary ml-3' small />
            </Tooltip>
          )}
        </>
      },
      {
        Header: I18n.get('reference', 'referencia'),
        Cell: ({ row }) => {
          const item = row.original;
          return (
            <p className='h5 text-muted'>
              {typeof get(item, 'reference') === 'string'
                ? item.reference
                : `${get(item, 'reference.reference', '')}${
                    !!get(item, 'reference.key')
                      ? ` (${get(item, 'reference.key')})`
                      : ''
                  }`}
            </p>
          );
        },
      },
      {
        Header: I18n.get('price', 'precio'),
        Cell: ({ row }) => {
          const item = row.original;
          const sumTaxes = (prev, current) => prev + Number(current.percentage);
          const tax = get(item, 'tax', []).reduce(sumTaxes, 0) / 100 + 1;
          const price = !!item.price[0].price ? item.price[0].price * tax : 0;
          return (
            <p className='h5 text-muted text-truncate'>
              {new BigNumber(price).toFormat(decimal, fmt)}
            </p>
          );
        },
      },
      // {
      //   Header: I18n.get("description", "descripción"),
      //   Cell: ({ row }) => {
      //     const item = row.original;
      //     return (
      //       <p className="h5 text-muted text-truncate">
      //         {get(item, "description", "")}
      //       </p>
      //     );
      //   },
      // },
      {
        Header: !!get(filters, 'idWarehouse', null)
          ? I18n.get('warehouseQuantity', 'Cantidad en Bodega')
          : I18n.get('totalQuantity', 'Cantidad Total'),
        Cell: ({ row }) => {
          const item = row.original;
          return (
            <p className='h5 text-muted text-truncate'>
              {get(item, 'inventory.availableQuantity', '-')}
            </p>
          );
        },
      },
      // {
      //   Header: "Visible para facturar",
      //   Cell: ({ row }) => (
      //     <div onClick={() => toggleItemVisiblity(row.original.id, row.original.status)}>
      //       <Switch active={row.original.status === "active" ? true : false} />
      //     </div>
      //   )
      // },
      {
        Header: I18n.get('actions', 'Acciones'),
        id: 'actions',
        sortable: false,
        minWidth: 110,
        maxWidth: 150,
        Cell: ({ row }) => (
          <Actions
            item={row.original}
            onRefresh={() => fetchData(tableIndicators)}
            onView={() => history.push(`/items/edit/${row.original.id}`)}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />
        ),
      },
    ],
    [
      decimal,
      fetchData,
      fmt,
      history,
      startLoading,
      stopLoading,
      tableIndicators,
      filters,
    ]
  );

  useEffect(() => {
    fetchData({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [fetchData]);

  return (
    <div className='container p-5'>
      <div className='d-flex flex-column'>
        <Header
          title={
            <Typography
              variant='primary'
              type='heading-4'
              text={I18n.get('itemsAndServices', 'productos y servicios')}
            />
          }
          subtitle={
            <p className='h5 text-muted text-capitalize-first'>
              <Typography
                htmlType='span'
                variant='secondary'
                type='body-3-regular'
                text={I18n.get(
                  'itemManagementInfo',
                  'crea, edita y administra cada detalle de los productos o servicios que vendes.'
                )}
              />
              <a
                href={getInventoryHelpUrl(country)}
                target='_blank'
                rel='noreferrer'
              >
                {I18n.get('knowMore', 'Saber más')}
              </a>
            </p>
          }
          actions={
            <div className='gap-3 d-flex column-mobile'>
              <Tooltip
                tooltipActive={true}
                placement='bottom'
                overlay={
                  <Typography
                    type='caption-bold'
                    variant='inverse'
                    text={I18n.get(
                      'importProducts.tooltip',
                      'Te llevaremos a Alegra Contabilidad para que los importes de manera masiva.'
                    )}
                  />
                }
              >
                <Button
                  size='sm'
                  variant='outline'
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_ALEGRA}item/import`,
                      '_blank'
                    )
                  }
                >
                  {I18n.get('importProducts.text', 'Importar productos')}
                  <ArrowRightUp className='icon-secondary' />
                </Button>
              </Tooltip>
              <Tooltip
                tooltipActive={!can('add', 'items')}
                placement='bottom'
                overlay={
                  <Typography
                    type='caption-bold'
                    variant='inverse'
                    text={I18n.get(
                      'userNotAllowed.items.add',
                      'no tienes permisos para agregar productos'
                    )}
                  />
                }
              >
                <Button
                  size='sm'
                  disabled={!can('add', 'items') || !connectionStatus}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modal: 'item',
                        params: {
                          onRefresh: () => fetchData(tableIndicators),
                        },
                      })
                    )
                  }
                >
                  <Plus className='icon-white' />
                  {I18n.get('newItemButton', 'nuevo producto')}
                </Button>
              </Tooltip>
            </div>
          }
        />

        <WarehouseFilter filters={filters} setFilters={setFilters} />

        <div className='rounded-lg'>
          <MultipleItemsActions
            selectedItems={selectedIds}
            show={selectedIds.length > 1}
            onRefresh={() => fetchData(tableIndicators)}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />

          {!openFilters && (
            <div className='single-action-button mb-4'>
              <button
                type='button'
                className='btn-action d-flex justify-content-center align-items-center'
                onClick={() => setOpenFilters(true)}
              >
                <div className='mr-2'>
                  <Filter fill='#00b19d' />
                </div>
                {capitalize(I18n.get('filters', 'Filtros'))}
              </button>
            </div>
          )}

          {openFilters && (
            <Filters
              setFilters={setFilters}
              filters={filters}
              setOpenFilters={setOpenFilters}
            />
          )}

          <Table
            loading={loading}
            data={data}
            onFetchData={fetchData}
            controlledPageCount={pageCount}
            error={error}
            setSelectedIds={setSelectedIds}
            onRefresh={({ pageIndex, pageSize }) =>
              fetchData({ pageIndex, pageSize })
            }
            showNetworkConnectionError
            noDataText={
              <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                <Box size={48} />
                <Typography
                  type='heading-3'
                  variant='primary'
                  text={I18n.get(
                    'noCreatedProducts.tittle',
                    '¡A un clic de tu primer producto!'
                  )}
                />
                <Typography
                  variant='secondary'
                  type='body-3-regular'
                  text={I18n.get(
                    'noCreatedProducts.subtitle',
                    'Crea tus productos y empieza a registrar tus ventas'
                  )}
                />
                <Button
                  onClick={() =>
                    dispatch(
                      openModal({
                        modal: 'item',
                        params: { onRefresh: () => fetchData(tableIndicators) },
                      })
                    )
                  }
                  type='button'
                  variant='outline'
                  size='md'
                >
                  <Plus className='icon-secondary' />
                  <Typography
                    variant='primary'
                    type='label-1'
                    text={I18n.get('newItemButton', 'nuevo producto')}
                  />
                </Button>
              </div>
            }
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default Items;
