import React from 'react'
import { I18n } from '@aws-amplify/core';

import OnlyInvoicingTooltip from '../../../common/Tooltip/OnlyInvoicing'
import Switch from '../../../common/Switch';
import Tooltip from '../../../common/Tooltip';
import InfoSVG from '../../../svg/InfoOutline';
import { capitalize } from 'lodash';
import ExternalLink from '../../../svg/ExternalLink';

const baseElement = ({ field, meta, className, label, help, helpTooltip, warning, required, input, headText, ...props }) => {
  const { touched, error } = meta;
  let isErrorIdentificationNumberCol = false;
  if (label === I18n.get('identification', 'identificación') && props.country === "colombia") {
    if (error === I18n.get('onlyNumericValuesAreSupported', 'Solo se admiten valores numéricos')) {
      isErrorIdentificationNumberCol = true;
    }
  }
  return (
    <div className={`border-bottom pb-3 mb-5 form-group ${isErrorIdentificationNumberCol ? 'has-error' : touched && error ? 'has-error' : ''} ${!!className ? className : ''} ${input.type === 'date' ? 'input-date-picker' : ''}`}>
      <div className="form-group__label mb-2">
        {!!headText && (
          <div className="d-flex align-items-center">
            <h4 htmlFor={input.name}>
              {headText}
            </h4>
          </div>
        )}
        
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            {!!label && (
              <div className="label-wrapper d-flex align-items-center">
                <label htmlFor={input.name} className={`h3 mb-3 inter-semibold ${required ? 'required' : ''}`}>
                  {label}
                </label>
                {!!helpTooltip &&
                  <Tooltip
                    tooltipActive={true}
                    overlay={(
                      <p className="text-capitalize-first">
                        {helpTooltip}
                      </p>
                    )}
                  >
                    <InfoSVG className="icon-primary" small />
                  </Tooltip>
                }
              </div>
            )}

            {!!help && <p className="text-muted h5 text-capitalize-first inter-regular">{help}</p>}
          </div>
          {field}
        </div>
        

        {!!warning && <p className="h5 text-capitalize-first text-warning py-2">{warning}</p>}

        {(!touched && error && isErrorIdentificationNumberCol) && !props.hideErrorLabel && <p className={`h5 text-capitalize-first text-danger py-2 ${props.errorRight ? 'text-right' : ''}`}>{error}</p>}

        {(touched && error) && !props.hideErrorLabel && <p className={`h5 text-capitalize-first text-danger py-2 ${props.errorRight ? 'text-right' : ''}`}>{error}</p>}

      </div>
    </div>
  )
}


export const renderBasicCheckbox = props => {
  return (
    <div>
      <label className='d-flex justify-content-center align-items-center h5 text-subtitle'>
        <input
          type="checkbox"
          checked={props.input.checked}
          onChange={props.input.onChange}
          className='d-flex justify-content-center align-items-center mr-3'
        />
        {props.label}
      </label>
    </div>
  )
}

export const renderCheckbox = props => {
  let field = (
    <div>
      <OnlyInvoicingTooltip
        tooltipActive={!!props.onlyInvoicingTooltip}
      >
        <div className='d-flex mr-4'
          onClick={() => !props.disabled ? props.onClick(!props.input.value, props.input.onChange): null}>
           <Tooltip
              tooltipActive={props.disabled && props.disabledHelp}
              overlay={(
                <p className="text-capitalize-first m-0">
                  {props.disabledHelp}
                </p>
              )}
            >
               <Switch
                active={props.input.value}
                disabled={props.disabled}
                small={false}
                large={true}
              />
            </Tooltip>
        </div>
      </OnlyInvoicingTooltip>
    </div>
  )

  return baseElement({ ...props, field });
}

export const renderExternalActivateButton = props => {
  let field = (
    <div>
      <div className='d-flex mr-4'>
        { !props.activated ?
          <button
            type="button"
            className="btn-action"
            onClick={props.onClick}
          >
            {I18n.get('activate', 'activar')}
            <ExternalLink className='ml-3'/>
          </button> :
          <div className='btn-activate external-option-activated text-capitalize-first justify-content-center'>
            <p className='m-0'>
              {capitalize(I18n.get('activated', 'activada'))}
            </p>
          </div>
        }
        
      </div>
    </div>
  )

  return baseElement({ ...props, field });
}
