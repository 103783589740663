import React from 'react'
import { I18n } from '@aws-amplify/core';
import Tooltip from '../../common/Tooltip'
import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';

const Bottom = ({onClose, onConfirm}) => {
    const colombiaUVT = getColombiaUVT();
    return (
        <div className="modal__bottom w-100 p-3 pl-5 d-flex justify-content-between align-items-center shadow">
            <Tooltip
                tooltipActive={true}
                placement="bottom"
                overlayClassName="tooltip-white"
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                overlay={(
                    <div style={{width: "25rem"}}>
                        <p className="text-capitalize-first font-weight-bold">
                            {I18n.get('invoice5uvt.help.tooltip.title', 'Cambio automático de documento ✨')}
                        </p>
                        <p className="text-capitalize-first">
                            {I18n.get('invoice5uvt.help.tooltip.description', `Tienes activo el cambio de documento POS por factura electrónica en ventas que superen $${colombiaUVT?.UVT_FORMATED} antes de IVA.`)}
                        </p>
                    </div>
                
                )}
            >
                <a className="text-capitalize-first h4" href="https://ayuda.alegra.com/es" target="_blank" rel="noreferrer">
                    {I18n.get('invoice5UVT.help', '¿Por qué se crea una factura electrónica?')}
                </a>
            </Tooltip>
            

            <div className="modal__bottom-buttons d-flex">
                <button 
                type="button" 
                className="btn btn-cancel text-capitalize-first" 
                onClick={() => onClose()}
                >
                    {I18n.get("cancel", "cancelar")}
                </button>
                <button
                    type="button"
                    className="btn btn-submit text-capitalize-first"
                    onClick={() => onConfirm()}
                >
                    {I18n.get("continue", "continuar")}
                </button>
            </div>
        </div>
    )
}

export default Bottom;