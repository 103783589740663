import React, { useRef } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import Attributes from "./Attributes/Attributes";
import Items from "./Items/Items";
import Warehouses from "./Warehouses/Warehouses";
import CustomFields from './CustomFields/CustomFields';
import Categories from "./Categories/Categories";
import PriceLists from "./PriceLists/PriceLists";
import Variants from "./Variants/Variants";
import IventoryAdjustments from "./InventoryAdjustments/InventoryAdjustments";
import NewIventoryAdjustment from "./InventoryAdjustments/newInventoryAdjustment/NewInventoryAdjustment";
import EditInventoryAdjustment from "./InventoryAdjustments/editInventoryAdjustment/EditInventoryAdjustment";

const Inventory = () => {
  const location = useLocation();
  const containerRef = useRef();

  React.useEffect(() => {
    containerRef.current.scrollTo({ top: 0 });
  }, [location]);

  return (
    <div ref={containerRef} className="h-100 inventory">
      <Switch>
        <Route path="/inventory/attributes" component={Attributes} />
        <Route path="/inventory/items" component={Items} />
        <Route path="/inventory/warehouses" component={Warehouses} />
        <Route path="/inventory/custom-fields" component={CustomFields} />
        <Route path="/inventory/categories" component={Categories} />
        <Route path="/inventory/price-lists" component={PriceLists} />
        <Route path="/inventory/variants" component={Variants} />
        <Route path="/inventory/inventory-adjustments/edit/:id" component={EditInventoryAdjustment} />
        <Route path="/inventory/inventory-adjustments/add" component={NewIventoryAdjustment} />
        <Route path="/inventory/inventory-adjustments" component={IventoryAdjustments} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default Inventory;
