import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';

import { decimalPrecision, country as countrySelector } from '../../../../selectors/company'
import { renderField } from '../../fields/V0/Fields';
import { maxDecimals } from '../../../../utils/decimals/maxDecimals';

const countryDenominations = code => {
  switch (code) {
    case 'COP':
      return [1000, 2000]
    case 'CRC':
      return [1000, 2000]
    case 'DOP':
      return [20, 50]
    case 'MXN':
      return [20, 50]
    case 'PEN':
      return [10, 20]
    case 'ARL':
      return [20, 50]
    case 'CLP':
      return [1000, 2000]
    default:
      return [5, 10]
  }
}

const fastOptions = (code, total) => {
  const denominations = countryDenominations(code)
  const aux = Math.floor(total / denominations[0]);
  return [total, denominations[0] * (aux + 1), denominations[0] * (aux + 2)];
}

const Cash = ({ total, fmt, currencyCode, values }) => {
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const cashFastOptions = fastOptions(currencyCode, total.toNumber())

  return (
    <>
      <Field
        name="cash"
        className="col-md-12"
        type="number"
        label={capitalize(I18n.get('cash', 'efectivo'))}
        onChange={e => maxDecimals({e,key: 'cash', decimals: decimal, country, values})}
        required
      >
        {props => (
          <>
            {renderField(props)}

            {!!total && (
              <>
                <div className="form__invoice-divider position-relative justify-content-center d-flex w-100 my-3">
                  <div className="form__invoice-divider-line">
                    <div className="border-bottom" />
                  </div>
                  <p className="form__invoice-divider-text text-muted h5 text-center text-uppercase">
                    {I18n.get('fastOptions', 'opciones rápidas')}
                  </p>
                </div>

                <div className="col-md-12 d-flex form-row m-0 form__invoice-cash-options">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={() => props.input.onChange(new BigNumber(cashFastOptions[0]).decimalPlaces(decimal).toString())}
                  >
                    {new BigNumber(cashFastOptions[0]).toFormat(decimal, fmt)}
                  </button>

                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={() => props.input.onChange(cashFastOptions[1])}
                  >
                    {new BigNumber(cashFastOptions[1]).toFormat(decimal, fmt)}
                  </button>

                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={() => props.input.onChange(cashFastOptions[2])}
                  >
                    {new BigNumber(cashFastOptions[2]).toFormat(decimal, fmt)}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Field>
    </>
  )
}

export default Cash;