import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { I18n } from '@aws-amplify/core';
import { get, set, capitalize, isArray } from 'lodash'
import BigNumber from 'bignumber.js';

import { setActiveInvoice, clear as clearActiveInvoice } from '../reducers/activeInvoice'
import { station as stationSelector } from '../selectors/app'
import { activeInvoice as activeInvoiceSelector } from '../selectors/activeInvoice'
import * as pendingInvoicesDB from '../database/pendingInvoicesDB'

const initialState = {
  invoices: [],
  selected: 0
}

const mainInvoice = {
  id: 0,
  name: capitalize(I18n.get('mainSale', 'venta principal'))
}

const invoiceInitialState = {
  items: [],
  originalItems: [],
  client: null,
  priceList: null,
  currency: null,
  operationType: 'STANDARD',
  type: 'NATIONAL',
  paymentTerm: 1,
  deliveryTerm: null,
}

const findIndex = (items = [], id) => items
  .findIndex(item => get(item, 'id') === id && !get(item, 'modified'));

const updateItemPrice = (item, priceList, currency) => {
  let price = get(item, 'originalPrice', 0)

  if (!get(item, 'priceModified') && !!priceList) {
    const itemPriceList = !!get(item, 'priceLists.length')
      ? get(item, 'priceLists').find(list => +get(list, 'idPriceList') === +get(priceList, 'id'))
      : null
    if (!!itemPriceList)
      price = get(itemPriceList, 'price');
  }

  if (!!currency) {
    const exchangeRate = !!get(currency, 'exchangeRate') ? +get(currency, 'exchangeRate') : 1;
    price = new BigNumber(price).dividedBy(new BigNumber(exchangeRate)).decimalPlaces(4).toNumber()
  }

  return { ...item, price }
}

export const addInvoice = createAsyncThunk(
  'pendingInvoice/addInvoice',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState()
      const prefix = get(stationSelector(state), 'pendingInvoicesPrefix', '');
      let invoices = [...get(state, 'pendingInvoices.invoices')]
      const selected = get(state, 'pendingInvoices.selected')

      let activeInvoice = !!selected && !!get(invoices, '0.invoice')
        ? get(invoices, '0.invoice') : { ...activeInvoiceSelector(state) };

      if (!!selected)
        invoices[selected] = { ...invoices[selected], invoice: activeInvoiceSelector(state) }

      const maxId = invoices[invoices.length > 1 ? 1 : 0].id
      const newPendingInvoice = {
        id: maxId + 1,
        name: `${prefix} ${maxId + 1}`,
        invoice: activeInvoice,
      }

      invoices = [{ ...mainInvoice, invoice: invoiceInitialState }, newPendingInvoice, ...invoices.slice(1)]

      dispatch(setInvoices(invoices))
      dispatch(clearActiveInvoice())
      dispatch(setSelected(0))

      await pendingInvoicesDB.bulkPut(invoices)
    } catch {
    }
  }
)

export const changeInvoice = createAsyncThunk(
  'pendingInvoice/changeInvoice',
  async (params, { dispatch, getState }) => {
    try {
      const { index } = params

      const state = getState()
      const invoices = [...get(state, 'pendingInvoices.invoices')]
      const selected = get(state, 'pendingInvoices.selected')

      if (index === selected) return;

      const activeInvoice = { ...activeInvoiceSelector(state) };

      invoices[selected] = { ...invoices[selected], invoice: activeInvoice }
      dispatch(setInvoices(invoices))
      dispatch(setActiveInvoice(get(invoices, `${index}.invoice`)))
      dispatch(setSelected(index))

      await pendingInvoicesDB.bulkPut(invoices)
    } catch {
    }
  }
)

export const removeInvoice = createAsyncThunk(
  'pendingInvoice/removeInvoice',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState()
      const selected = get(state, 'pendingInvoices.selected')
      const invoices = get(state, 'pendingInvoices.invoices')

      if (!!selected) {
        const { id } = invoices[selected];
        await pendingInvoicesDB.remove(id)

        const { invoice } = await pendingInvoicesDB.get(0)
        dispatch(setActiveInvoice(invoice))

        dispatch(remove(selected))
        dispatch(setSelected(0))
      } else {
        dispatch(clearActiveInvoice())
      }

    } catch {
    }
  }
)

export const renameInvoice = createAsyncThunk(
  'pendingInvoice/renameInvoice',
  async (params, { dispatch, getState }) => {
    try {
      const { index, name } = params;
      if (!!index) {
        const state = getState()
        let invoices = [...get(state, 'pendingInvoices.invoices')]

        const { id } = invoices[index];

        await pendingInvoicesDB.update(id, { name })

        invoices[index] = { ...invoices[index], name }

        dispatch(setInvoices(invoices))
      }

    } catch {
    }
  }
)

export const updateItemByEvent = item => {
  return async (dispatch, getState) => {
    let invoices = [...get(getState(), 'pendingInvoices.invoices')]
    let shouldChange = false

    invoices = invoices.map(invoice => {
      let items = isArray(get(invoice, 'invoice.items'))
        ? [...get(invoice, 'invoice.items')]
        : []
      let originalItems = isArray(get(invoice, 'invoice.originalItems'))
        ? [...get(invoice, 'invoice.originalItems')]
        : []
      const priceList = get(invoice, 'invoice.priceList')
      const currency = get(invoice, 'invoice.currency')
      const itemsMap = get(invoice, 'itemsMap')

      const index = findIndex(items, item.id);

      if (index >= 0) {
        shouldChange = true

        items[index] = {
          ...items[index],
          ...updateItemPrice({
            ...item,
            originalPrice: get(item, 'price.0.price', 0),
            price: get(item, 'price.0.price', 0),
            priceLists: get(item, 'price'),
          }, priceList, currency)
        }

        originalItems[get(itemsMap, `${index}`, 0)] = {
          ...originalItems[get(itemsMap, `${index}`, 0)],
          ...updateItemPrice({
            ...item,
            originalPrice: get(item, 'price.0.price', 0),
            price: get(item, 'price.0.price', 0),
            priceLists: get(item, 'price'),
          }, priceList, currency)
        }

        return {
          ...invoice,
          invoice: {
            ...get(invoice, 'invoice'),
            items,
            originalItems,
          }
        }
      }

      return invoice
    })

    if (shouldChange) {
      dispatch(setInvoices(invoices))
      await pendingInvoicesDB.bulkPut(invoices)
    }

  }
}

export const removeItemByEvent = id => {
  return async (dispatch, getState) => {
    let invoices = [...get(getState(), 'pendingInvoices.invoices')]
    let shouldChange = false

    invoices = invoices.map(invoice => {
      let items = isArray(get(invoice, 'invoice.items'))
        ? [...get(invoice, 'invoice.items')]
        : []
      let originalItems = isArray(get(invoice, 'invoice.originalItems'))
        ? [...get(invoice, 'invoice.originalItems')]
        : []
      const itemsMap = get(invoice, 'itemsMap')

      const index = findIndex(items, id);

      if (index >= 0 && get(items, 'length')) {
        shouldChange = true

        const deletedItem = items.splice(index, 1)[0]
        const deletedQuantity = get(deletedItem, 'quantity')

        const originalItem = get(originalItems, `${get(itemsMap, `${index}`, 0)}`)
        const originalQuantity = get(originalItem, 'quantity', 0)

        if (get(originalItem, 'quantity') === deletedQuantity)
          originalItems.splice(get(itemsMap, `${index}`, 0), 1)
        else
          set(originalItems, `${get(itemsMap, `${index}`, 0)}.quantity`, originalQuantity - deletedQuantity)

        return {
          ...invoice,
          invoice: {
            ...get(invoice, 'invoice'),
            items,
            originalItems,
          }
        }
      }

      return invoice
    })

    if (shouldChange) {
      dispatch(setInvoices(invoices))
      await pendingInvoicesDB.bulkPut(invoices)
    }
  }
}

export const updateClientByEvent = client => {
  return async (dispatch, getState) => {
    let invoices = [...get(getState(), 'pendingInvoices.invoices')]
    let shouldChange = false

    invoices = invoices.map(invoice => {
      const currentClientId = get(invoice, 'invoice.client.id')
      let items = isArray(get(invoice, 'invoice.items'))
        ? [...get(invoice, 'invoice.items')]
        : []
      let originalItems = isArray(get(invoice, 'invoice.originalItems'))
        ? [...get(invoice, 'invoice.originalItems')]
        : []
      let currency = get(invoice, 'invoice.currency', null)
      let priceList = get(client, 'priceList', null)
        ? get(client, 'priceList', null)
        : get(invoice, 'invoice.priceList')

      if (get(client, 'id') === currentClientId) {
        shouldChange = true

        return {
          ...invoice,
          invoice: {
            ...get(invoice, 'invoice'),
            client,
            priceList,
            items: items.map(item => updateItemPrice(item, priceList, currency)),
            originalItems: originalItems.map(item => updateItemPrice(item, priceList, currency)),
          }
        }
      }

      return invoice
    })

    if (shouldChange) {
      dispatch(setInvoices(invoices))
      await pendingInvoicesDB.bulkPut(invoices)
    }
  }
}

export const removeClientByEvent = id => {
  return async (dispatch, getState) => {
    let invoices = [...get(getState(), 'pendingInvoices.invoices')]
    let shouldChange = false

    invoices = invoices.map(invoice => {
      const currentClientId = get(invoice, 'invoice.client.id')

      if (id === currentClientId) {
        shouldChange = true

        return {
          ...invoice,
          invoice: {
            ...get(invoice, 'invoice'),
            client: null,
          }
        }
      }

      return invoice
    })

    if (shouldChange) {
      dispatch(setInvoices(invoices))
      await pendingInvoicesDB.bulkPut(invoices)
    }
  }
}

export const updateNumerationByEvent = numeration => {
  return async (dispatch, getState) => {
    let invoices = [...get(getState(), 'pendingInvoices.invoices')]
    let shouldChange = false

    invoices = invoices.map(invoice => {
      const currentNumerationId = get(invoice, 'invoice.numeration.id')

      if (get(numeration, 'id') === currentNumerationId) {
        shouldChange = true

        return {
          ...invoice,
          invoice: {
            ...get(invoice, 'invoice'),
            numeration: numeration,
          }
        }
      }
      return invoice
    })

    if (shouldChange) {
      dispatch(setInvoices(invoices))
      await pendingInvoicesDB.bulkPut(invoices)
    }
  }
}

export const removeNumerationByEvent = id => {
  return async (dispatch, getState) => {
    let invoices = [...get(getState(), 'pendingInvoices.invoices')]
    let shouldChange = false

    invoices = invoices.map(invoice => {
      const currentNumerationId = get(invoice, 'invoice.numeration.id')

      if (id === currentNumerationId) {
        shouldChange = true

        return {
          ...invoice,
          invoice: {
            ...get(invoice, 'invoice'),
            numeration: null,
          }
        }
      }

      return invoice
    })

    if (shouldChange) {
      dispatch(setInvoices(invoices))
      await pendingInvoicesDB.bulkPut(invoices)
    }
  }
}

const appSlice = createSlice({
  name: 'pendingInvoice',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    setInvoices: (state, action) => {
      state.invoices = action.payload
    },
    remove: (state, action) => {
      let prevInvoices = [...state.invoices]
      prevInvoices.splice(action.payload, 1)
      state.invoices = prevInvoices
    },
    clear: () => initialState,
  },
});

const { actions, reducer } = appSlice

export const {
  setSelected,
  setInvoices,
  remove,
  clear
} = actions;

export default reducer

