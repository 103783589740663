import { merge } from 'lodash'

import http from '../utils/http'

const baseUrl = process.env.REACT_APP_CONTACTS_ENDPOINT
const baseConfig = {
  headers: {
    'X-Data-Source': 'pos',
    'origin': 'https://pos.alegra.com',
    'x-access-key': process.env.REACT_APP_CONTACTS_AUTHORIZATION
  }
}

const get = async (url, params = null, userConfig = null) => await http.get(`${baseUrl}${url}`, params, merge(baseConfig, userConfig));

const post = async (url, params = null, urlParams = null, userConfig = null) => await http.post(`${baseUrl}${url}`, params, urlParams, merge(baseConfig, userConfig));

const put = async (url, params = null, urlParams = null, userConfig = null) => await http.put(`${baseUrl}${url}`, params, urlParams, merge(baseConfig, userConfig));

const httpDelete = async (url, urlParams = null, userConfig = null) => await http.delete(`${baseUrl}${url}`, urlParams, merge(baseConfig, userConfig));

const contactsAPI = {
  get,
  post,
  put,
  delete: httpDelete,
}

export default contactsAPI