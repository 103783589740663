// eslint-disable-next-line
export default ({ id, className, fill }) => (
  <svg
    id={id}
    className={className}
    width="24"
    height="24"
    viewBox="0 0 48 48"
    fill={fill ?? 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.993,28.524h-1.718l-0.609-0.585c2.13-2.479,3.413-5.692,3.413-9.191 c0-7.801-6.322-14.123-14.121-14.123c-7.802,0-14.124,6.322-14.124,14.123c0,7.798,6.322,14.122,14.124,14.122 c3.496,0,6.711-1.281,9.189-3.411l0.584,0.608v1.715l10.865,10.844l3.237-3.237L32.993,28.524z M19.958,28.524 c-5.414,0-9.779-4.366-9.779-9.776c0-5.41,4.365-9.777,9.779-9.777c5.408,0,9.773,4.367,9.773,9.777 C29.731,24.158,25.366,28.524,19.958,28.524z"
    />
  </svg>
)