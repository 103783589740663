import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useSelector } from 'react-redux'

import { activeWarehouses } from '../../../../selectors/warehouses'
import Trash from '../../../svg/Trash'
import { renderSelect, renderField } from '../../fields/V0/Fields';
import { stationWarehouse } from '../../../../selectors/app';

const Inventory = ({ values, form, ...props }) => {
  const warehouses = useSelector(activeWarehouses);
  const currentWarehouse = useSelector(stationWarehouse);


  const addWarehouse = () => {
    if (get(values, 'warehouses.length', 0) < warehouses.length) {
      form.mutators.push("warehouses")
    }
  }

  return (
    <>
      <div className="form-row">

        <FieldArray name="warehouses">
          {({ fields }) =>
            fields.map((name, index) => {
              return (
                <div key={index} className="col-12 form-row m-0 p-0">
                  <Field
                    name={`${name}.warehouse`}
                    component={renderSelect}
                    className="col-12"
                    options={warehouses}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    label={capitalize(I18n.get('warehouse', 'bodega'))}
                    required
                    validate={value => !get(value, 'id', null) ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                    addOptionText={!!props.newWarehouseAction ? capitalize(I18n.get('newWarehouse', 'Nueva bodega')) : null}
                    addOptionAction={() => props.newWarehouseAction(index)}
                    defaultValue={currentWarehouse}
                  />
                  <Field
                    name={`${name}.initialQuantity`}
                    component={renderField}
                    className="col-4"
                    type="number"
                    label={capitalize(I18n.get('initialQuantity', 'cantidad inicial'))}
                    validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                    required
                  />
                  <Field
                    name={`${name}.minQuantity`}
                    component={renderField}
                    className="col-4"
                    type="number"
                    label={capitalize(I18n.get('minQuantity', 'cantidad mínima'))}
                  />
                  <div className="col-4 form-row">
                    <Field
                      name={`${name}.maxQuantity`}
                      component={renderField}
                      className="col-10"
                      type="number"
                      label={capitalize(I18n.get('maxQuantity', 'cantidad máxima'))}
                    />
                    <button
                      type="button"
                      className="btn button-transparent col-2 mb-3"
                      disabled={fields.length <= 1}
                      style={{
                        height: '3.6rem',
                        outline: 'none',
                        boxShadow: 'none',
                        paddingTop: '2.5rem'
                      }}
                      onClick={() => fields.remove(index)}
                    >
                      <Trash className="icon-secondary" />
                    </button>
                  </div>
                </div>
              )
            })
          }
        </FieldArray>


        <div className="col-12">
          <button
            type="button"
            className="btn btn-submit mb-3"
            disabled={get(values, 'warehouses.length') >= warehouses.length}
            onClick={() => addWarehouse()}
          >
            {capitalize(I18n.get('addWarehouse', 'agregar bodega'))}
          </button>
        </div>
      </div>
    </>
  )
}

export default Inventory;
