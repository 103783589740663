import React, { useEffect, useState } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { country as countrySelector, origin as originSelector } from '../../../selectors/company';
import { closeTour } from '../../../reducers/tours'
import { openModal } from '../../../reducers/modals'
import { isClothingStore } from '../../../utils';
import { sendGTMEvent } from '../../../reducers/company'

const Onboarding = () => {
  const history = useHistory()
  const [index, setIndex] = useState(0)
  const dispatch = useDispatch()
  const isOpen = useSelector(state => get(state, 'tours.onboarding.isOpen'))
  const discountAvailability = useSelector(state => get(state, 'tours.onboarding.params.discountAvailability', false))
  const country = useSelector(countrySelector)
  const origin = useSelector(originSelector)

  useEffect(() => {
    if (!!isOpen)
      setIndex(0)
  }, [isOpen])

  const handleSendEvent = (step) => {
    switch (step) {
      case 0:
        return 'new-product-step-completed'
      case 1:
        return 'create-contact-step-completed'
      case 2:
        return 'choose-product-step-completed'
      case 3:
        return 'finish-tour-step-completed'
      default:
        return '';
    }
  }

  const handleJoyrideCallback = data => {
    const { action, index, status, type, lifecycle } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(closeTour({ tour: 'onboarding' }))

      !isClothingStore(country, origin) && dispatch(openModal({ modal: 'onboardingEnd', params: { discountAvailability } }))
    }

    if (lifecycle === 'complete')
      dispatch(sendGTMEvent(handleSendEvent(index)));

  };
  let steps = [
    {
      target: '#add-item-button',
      title: `${isClothingStore(country, origin) ? I18n.get('tours.onboarding.title.1.clothing.stores', '¡Tus productos con un clic!') : I18n.get('tours.onboarding.title.1', '¡Tus productos con un clic!')}`,
      content: `${isClothingStore(country, origin) ? I18n.get('tours.onboarding.content.1.clothing.stores', 'Esta opción te va a permitir crear los productos y servicios que ofreces.') : I18n.get('tours.onboarding.content.1', 'Aquí puedes crear los productos y servicios que vendes en tu negocio.')}`,
      disableBeacon: true,
    },
    {
      target: '#add-client-button',
      title: `${isClothingStore(country, origin) ? I18n.get('tours.onboarding.title.2.clothing.stores', 'Crea tus contactos') : I18n.get('tours.onboarding.title.2', 'Crea tus contactos. 👥')}`,
      content: I18n.get('tours.onboarding.content.2', 'Añade tus clientes o utiliza el consumidor final que viene creado por defecto.'),
      disableBeacon: true,
    },
    {
      target: '.new-active-invoice__item-container',
      title: `${isClothingStore(country, origin) ? I18n.get('tours.onboarding.title.3.clothing.stores', 'Elige los productos a vender') : I18n.get('tours.onboarding.title.3', '👈 Elige los productos a vender')}`,
      content: I18n.get('tours.onboarding.content.3', 'Puedes seleccionarlos con un clic sobre los recuadros que visualizas al lado izquierdo.'),
      disableBeacon: true,
    },
    {
      target: '#invoice-button',
      title: `${isClothingStore(country, origin) ? I18n.get('tours.onboarding.title.4.clothing.stores', '¡Boom, tu primera factura! 🎉') : I18n.get('tours.onboarding.title.4', '¡Boom, tu primera factura! 🎉')}`,
      content: I18n.get('tours.onboarding.content.4', 'Con esta opción creas tu factura, eliges el método de pago e imprimes tu recibo.'),
      disableBeacon: true,
    },
  ]

  steps = country === 'mexico' ? steps.filter(step => step.target !== "#add-client-button") : steps;

  return (
    <Joyride
      steps={steps}
      run={isOpen}
      callback={handleJoyrideCallback}
      stepIndex={index}
      continuous
      hideBackButton
      showProgress
      hideCloseButton
      disableCloseOnEsc
      disableOverlayClose
      spotlightPadding={5}
      onRequestClose={() => {
        dispatch(closeTour({ tour: 'onboarding' }));
        if (country === 'mexico' && dayjs().isBefore('2022-09-17')) {
          history.push('/plan/plans')
        }
      }}
      locale={{
        back: I18n.get('joyride.back', 'Volver'),
        close: I18n.get('joyride.close', 'Cerrar'),
        last: I18n.get('joyride.last', 'Finalizar'),
        next: I18n.get('joyride.next', 'Siguiente'),
        open: I18n.get('joyride.open', 'Abrir recorrido'),
        skip: I18n.get('joyride.skip', 'Saltar')
      }}
      tooltipComponent={({
        continuous,
        step,
        index,
        size,
        primaryProps,
        tooltipProps,
      }) => (
        <div
          className="bg-white p-4 rounded"
          {...tooltipProps}
          style={{
            maxWidth: '36rem'
          }}
        >
          {step.title && <div className="text-title text-capitalize-first font-weight-bold h4">{step.title}</div>}
          <div className="text-muted text-capitalize-first h4 py-3">{step.content}</div>

          <div className="pt-2 d-flex justify-content-between align-items-center">
            <p className="text-muted h5">
              {`${index + 1} ${I18n.get('of', 'de')} ${size}`}
            </p>
            {continuous && (
              <button
                type="button"
                className="btn btn-submit text-capitalize"
                {...primaryProps}
                title=""
              >
                {primaryProps.title}
              </button>
            )}
          </div>
        </div>
      )}
    />
  )
}

export default Onboarding