import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { get, has } from 'lodash';

import { setMembership } from '../../../../../reducers/membership';
import {
  country as countrySelector,
  getProductMembership,
  idCompanySelector,
} from '../../../../../selectors/company';
import membershipsAPI from '../../../../../reducers/membershipsAPI';
import { sendGTMEvent } from '../../../../../reducers/company';
import AlegraApp from '../../../../svg/AlegraAppNew';
import AlegraShop from '../../../../svg/AlegraShopNew';
import AlegraPos from '../../../../svg/AlegraPosNew';
import AlegraPayroll from '../../../../svg/AlegraPayrollNew';
import AlegraInvoiceNew from '../../../../svg/AlegraInvoiceNew';
import AccountingModal from '../modals/Accounting';
import ShopModal from '../modals/Shop';
import PayrollModal from '../modals/Payroll';
import SettingsSVG from '../../../../svg/header/SettingsIcon';
import AppsNewSVG from '../../../../svg/new/Apps';
import Typography from '../../../../common/Typography';
import MenuOption from '../../../../common/MenuOption';
import { openModal as openGlobalModal } from '../../../../../reducers/modals';
import { PRODUCTS } from '../../../../../utils/enums/products';
import { useHeader } from '../../../context/header.context';

const hasPlanInProduct = (membership, product) => {
  if (has(membership, 'product.keyword'))
    return get(membership, 'product.keyword') === product;
  return true;
};

const Apps = () => {
  const dispatch = useDispatch();
  const { setActiveMenu } = useHeader();
  const companyId = useSelector(idCompanySelector);
  const [modals, setModals] = useState({
    accounting: false,
    shop: false,
    payroll: false,
  });

  const country = useSelector(countrySelector);
  const enableCountries = ['mexico', 'costaRica', 'spain','panama'];
  const memberships = {
    'alegra-web': useSelector(getProductMembership('alegra-web')),
    'alegra-shop': useSelector(getProductMembership('alegra-shop')),
    'alegra-ne': useSelector(getProductMembership('alegra-ne')),
    'alegra-invoicing': useSelector(getProductMembership('alegra-invoicing')),
  };

  const setProductPlanKeyword = (product) => {
    let productMembership = memberships[product];
    dispatch(
      setMembership({
        product,
        plan: {
          ...productMembership,
          product: {
            ...get(productMembership, 'product', null),
            keyword: product,
          },
        },
      })
    );
  };

  const openModal = (modal) =>
    setModals({
      ...modals,
      [modal]: true,
    });

  const closeModal = (modal) =>
    setModals({
      ...modals,
      [modal]: false,
    });

  const toggleSolution = async (product, url, type) => {
    try {
      const solution = await membershipsAPI.get(
        `/subscribers/${companyId}/subscriptions/current`,
        {
          product,
        }
      );
      dispatch(
        sendGTMEvent('launcher-icon-used', {
          productDestination: type,
        })
      );
      if (solution && solution?.data?.product?.keyword !== product) {
        dispatch(openGlobalModal({ modal: 'solutions', params: { type } }));
      } else {
        window.open(url, '_blank');
      }
    } catch (error) {
      dispatch(openGlobalModal({ modal: 'solutions', params: { type } }));
    }
    setActiveMenu('');
  };

  return (
    <>
      <MenuOption
        id='apps'
        eventName='Solutions'
        tooltip={I18n.get('solutions', 'Soluciones')}
        header={<AppsNewSVG className='icon-secondary' />}
        extraClassName='header__apps new-menu-container-small'
      >
        <div className='menu-options-header mt-2'>
          <Typography
            type='label-3'
            variant='tertiary'
            text={I18n.get(
              'solutions.description.new',
              'Más soluciones para tu negocio'
            )}
          />
        </div>

        <div className='d-flex flex-column justify-content-center px-2 mt-1 body-apps'>
          {/* Contabilidad */}
          <div
            className='d-flex align-items-center pointer'
            onClick={(event) => {
              event.preventDefault();
              toggleSolution(
                PRODUCTS.WEB,
                process.env.REACT_APP_ALEGRA,
                'accounting'
              );
            }}
          >
            <button className='btn btn-sm button-transparent'>
              <AlegraApp />
            </button>
            <div className='pl-3'>
              <Typography
                variant='secondary'
                type='body-3-bold'
                text={I18n.get('AlegraAccounting.new', 'Contabilidad')}
              />
              <Typography
                variant='tertiary'
                type='caption-regular'
                text={I18n.get(
                  'AlegraAccounting.description.new',
                  'Gestiona las cuentas de tu negocio'
                )}
              />
            </div>
          </div>

          {/* POS */}
          <div className='d-flex align-items-center pointer'>
            <NavLink to='/' className='btn btn-sm button-transparent'>
              <AlegraPos />
            </NavLink>

            <div className='pl-3'>
              <Typography
                variant='secondary'
                type='body-3-bold'
                text={I18n.get('AlegraPOS.new', 'POS')}
              />
              <Typography
                variant='tertiary'
                type='caption-regular'
                text={I18n.get(
                  'AlegraPOS.description.new',
                  'Factura fácil en tus puntos de venta'
                )}
              />
            </div>
          </div>

          {/* Nómina */}
          {country === 'colombia' && (
            <div
              className='d-flex align-items-center pointer'
              onClick={(event) => {
                event.preventDefault();
                toggleSolution(
                  PRODUCTS.NE,
                  process.env.REACT_APP_ALEGRA_PAYROLL,
                  'payroll'
                );
              }}
            >
              <button className='btn btn-sm button-transparent'>
                <AlegraPayroll />
              </button>

              <div className='pl-3 text-left'>
                <Typography
                  variant='secondary'
                  type='body-3-bold'
                  text={I18n.get('AlegraPayroll.new', 'Nómina')}
                />
                <Typography
                  variant='tertiary'
                  type='caption-regular'
                  text={I18n.get(
                    'AlegraPayroll.description.new',
                    'Emite y liquida el pago de tu equipo'
                  )}
                />
              </div>
            </div>
          )}

          {/* Tienda */}
          <div
            className='d-flex py-2 align-items-center pointer'
            onClick={(event) => {
              event.preventDefault();
              toggleSolution(
                PRODUCTS.SHOP,
                process.env.REACT_APP_ALEGRA_SHOP,
                'shop'
              );
            }}
          >
            <button className='btn btn-sm button-transparent'>
              <AlegraShop />
            </button>

            <div className='pl-3'>
              <Typography
                variant='secondary'
                type='body-3-bold'
                text={I18n.get('AlegraShop.new', 'Tienda')}
              />
              <Typography
                variant='tertiary'
                type='caption-regular'
                text={I18n.get(
                  'AlegraShop.description.new',
                  'Vende más con tu negocio en internet'
                )}
              />
            </div>
          </div>

          {/* Facturación - de momento no se incluye */}
          {enableCountries.includes(country) && (
            <div
              className='d-flex py-2 align-items-center pointer'
              onClick={(event) => {
                event.preventDefault();
                toggleSolution(
                  PRODUCTS.INVOICE,
                  process.env.REACT_APP_ALEGRA_INVOICE,
                  'invoice'
                );
              }}
            >
              <button className='btn btn-sm button-transparent'>
                <AlegraInvoiceNew />
              </button>

              <div className='pl-3 text-left'>
                <Typography
                  variant='secondary'
                  type='body-3-bold'
                  text={I18n.get('AlegraInvoicing.new', 'Facturación')}
                />
                <Typography
                  variant='tertiary'
                  type='caption-regular'
                  text={I18n.get(
                    'AlegraInvoicing.description.new',
                    'Vende más con tu negocio en internet'
                  )}
                />
              </div>
            </div>
          )}
        </div>

        <div
          className='menu-options-footer'
          onClick={(event) => {
            window.open(
              `${process.env.REACT_APP_ALEGRA_SMILE}solutions`,
              '_blank'
            );
          }}
        >
          <SettingsSVG />

          <Typography
            type='label-3'
            text={I18n.get('solutions.managment', 'Administrar soluciones')}
          />
        </div>
      </MenuOption>

      <AccountingModal
        isOpen={modals.accounting}
        onClose={() => closeModal('accounting')}
        onRedirect={() => {
          dispatch(
            sendGTMEvent('launcher-icon-used', {
              productDestination: 'accounting',
            })
          );
          setProductPlanKeyword('alegra-web');
          closeModal('accounting');
        }}
      />
      <ShopModal
        isOpen={modals.shop}
        onClose={() => closeModal('shop')}
        onRedirect={() => {
          dispatch(
            sendGTMEvent('launcher-icon-used', {
              productDestination: 'shop',
            })
          );
          setProductPlanKeyword('alegra-shop');
          closeModal('shop');
        }}
      />
      <PayrollModal
        isOpen={modals.payroll}
        onClose={() => closeModal('payroll')}
        onRedirect={() => {
          dispatch(
            sendGTMEvent('launcher-icon-used', {
              productDestination: 'payroll',
            })
          );
          setProductPlanKeyword('alegra-ne');
          closeModal('payroll');
        }}
      />
    </>
  );
};

export default Apps;
