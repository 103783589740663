function AppsNewSVG({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.25 5C3.25 4.0335 4.0335 3.25 5 3.25C5.9665 3.25 6.75 4.0335 6.75 5C6.75 5.9665 5.9665 6.75 5 6.75C4.0335 6.75 3.25 5.9665 3.25 5Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.25 5C10.25 4.0335 11.0335 3.25 12 3.25C12.9665 3.25 13.75 4.0335 13.75 5C13.75 5.9665 12.9665 6.75 12 6.75C11.0335 6.75 10.25 5.9665 10.25 5Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 3.25C18.0335 3.25 17.25 4.0335 17.25 5C17.25 5.9665 18.0335 6.75 19 6.75C19.9665 6.75 20.75 5.9665 20.75 5C20.75 4.0335 19.9665 3.25 19 3.25Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.25 12C3.25 11.0335 4.0335 10.25 5 10.25C5.9665 10.25 6.75 11.0335 6.75 12C6.75 12.9665 5.9665 13.75 5 13.75C4.0335 13.75 3.25 12.9665 3.25 12Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 10.25C11.0335 10.25 10.25 11.0335 10.25 12C10.25 12.9665 11.0335 13.75 12 13.75C12.9665 13.75 13.75 12.9665 13.75 12C13.75 11.0335 12.9665 10.25 12 10.25Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.25 12C17.25 11.0335 18.0335 10.25 19 10.25C19.9665 10.25 20.75 11.0335 20.75 12C20.75 12.9665 19.9665 13.75 19 13.75C18.0335 13.75 17.25 12.9665 17.25 12Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 17.25C4.0335 17.25 3.25 18.0335 3.25 19C3.25 19.9665 4.0335 20.75 5 20.75C5.9665 20.75 6.75 19.9665 6.75 19C6.75 18.0335 5.9665 17.25 5 17.25Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.25 19C10.25 18.0335 11.0335 17.25 12 17.25C12.9665 17.25 13.75 18.0335 13.75 19C13.75 19.9665 12.9665 20.75 12 20.75C11.0335 20.75 10.25 19.9665 10.25 19Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 17.25C18.0335 17.25 17.25 18.0335 17.25 19C17.25 19.9665 18.0335 20.75 19 20.75C19.9665 20.75 20.75 19.9665 20.75 19C20.75 18.0335 19.9665 17.25 19 17.25Z'
        fill='#334155'
      />
    </svg>
  );
}

export default AppsNewSVG;
