// eslint-disable-next-line
export default ({ id, className, width, height, fill }) => (
  <svg
    id={id}
    className={className}
    width={width ?? "18"}
    height={height ?? "22"}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.97061 9.05563L9.97061 8.77783C9.97061 8.31756 9.59748 7.94443 9.13721 7.94443C8.67693 7.94443 8.30381 8.31756 8.30381 8.77783L8.30381 9.07111C7.74292 9.1338 7.21662 9.385 6.81369 9.78793C6.34482 10.2568 6.0814 10.8927 6.0814 11.5558C6.0814 12.2189 6.34482 12.8549 6.81369 13.3238C7.28257 13.7926 7.91851 14.056 8.58161 14.056L9.69281 14.056C9.91384 14.056 10.1258 14.1438 10.2821 14.3001C10.4384 14.4564 10.5262 14.6684 10.5262 14.8894C10.5262 15.1105 10.4384 15.3225 10.2821 15.4787C10.1258 15.635 9.91384 15.7228 9.69281 15.7228L6.9148 15.7228C6.45453 15.7228 6.0814 16.096 6.0814 16.5562C6.0814 17.0165 6.45453 17.3897 6.9148 17.3897L8.30381 17.3897L8.30381 17.6675C8.30381 18.1277 8.67693 18.5009 9.13721 18.5009C9.59748 18.5009 9.97061 18.1277 9.97061 17.6675L9.97061 17.3742C10.5315 17.3115 11.0578 17.0603 11.4607 16.6574C11.9296 16.1885 12.193 15.5525 12.193 14.8894C12.193 14.2263 11.9296 13.5904 11.4607 13.1215C10.9918 12.6527 10.3559 12.3892 9.69281 12.3892L8.58161 12.3892C8.36057 12.3892 8.1486 12.3014 7.9923 12.1451C7.83601 11.9888 7.74821 11.7769 7.74821 11.5558C7.74821 11.3348 7.83601 11.1228 7.9923 10.9665C8.1486 10.8102 8.36057 10.7224 8.58161 10.7224L11.3596 10.7224C11.8199 10.7224 12.193 10.3493 12.193 9.88904C12.193 9.42876 11.8199 9.05563 11.3596 9.05563L9.97061 9.05563Z"
      fill="#94A3B8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5812 0.166016C2.77075 0.166016 1.99349 0.487966 1.42042 1.06104C0.847341 1.63412 0.525391 2.41137 0.525391 3.22182L0.525391 18.7787C0.525391 19.5891 0.847341 20.3664 1.42042 20.9394C1.99349 21.5125 2.77075 21.8345 3.5812 21.8345L14.6932 21.8345C15.5037 21.8345 16.2809 21.5125 16.854 20.9394C17.4271 20.3664 17.749 19.5891 17.749 18.7787L17.749 6.55543C17.749 6.3344 17.6612 6.12242 17.5049 5.96612L11.9489 0.410113C11.7926 0.25382 11.5806 0.166016 11.3596 0.166016L3.5812 0.166016ZM2.59902 2.23965C2.85951 1.97916 3.21281 1.83282 3.5812 1.83282L10.5262 1.83282L10.5262 5.44413C10.5262 5.95987 10.7311 6.45449 11.0958 6.81917C11.4605 7.18385 11.9551 7.38873 12.4708 7.38873L16.0822 7.38873L16.0822 18.7787C16.0822 19.147 15.9359 19.5003 15.6754 19.7608C15.4149 20.0213 15.0616 20.1677 14.6932 20.1677L3.5812 20.1677C3.21281 20.1677 2.85951 20.0213 2.59902 19.7608C2.33853 19.5003 2.19219 19.147 2.19219 18.7787L2.19219 3.22182C2.19219 2.85344 2.33853 2.50014 2.59902 2.23965ZM14.9035 5.72193L12.193 3.01143L12.193 5.44413C12.193 5.5178 12.2223 5.58846 12.2744 5.64056C12.3265 5.69266 12.3971 5.72193 12.4708 5.72193L14.9035 5.72193Z"
      fill={fill ?? "#94A3B8"} />
  </svg>
)