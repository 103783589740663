const dictionary = {
  "aboutPOS": "¿Cómo usar Alegra POS?",
  "accept": "Aceptar",
  "accepted": "aceptado",
  "acceptedWithObservations": "aceptado con observaciones",
  "acceptTermsConditions": "Acepto los términos y condiciones",
  "accountingAccount": "Cuenta contable",
  "accountingOptions": "Configuración contable",
  "accountingOrFinancialServices": "Servicios contables o financieros",
  "accountReceivable": "Cuenta por cobrar",
  "actions": "Acciones",
  "activate": "Activar",
  "active": "Activo",
  "activateIntegration": "Activar integración",
  "activeProducts": "Activos",
  "activateWompiIntegrationModal.title": "¡Nueva integración de pagos en línea! ⚡",
  "activateWompiIntegrationModal.content": "Recibe tus pagos desde <b>Bancolombia</b> o <b>Nequi</b> y valida el estado   de cada transacción en el momento de la venta.",
  "actuallyYouHave": "Actualmente te encuentras en",
  "actualShiftIsClosed": "Abre un nuevo turno y continúa gestionando el efectivo de tu negocio",
  "actualStation": "Terminal actual",
  "add": "Agregar",
  "addAnotherItem": "Agregar otro producto",
  "addItem": "Agregar producto",
  "additionalSettings": "Configuraciones adicionales",
  "addOption": "Agregar opción",
  "addPriceList": "Agregar lista de precio",
  "address": "Dirección",
  "addressTooltip": "Debes incluir la dirección cuando realizas la venta porfuera de tu local o si eliges el municipio de tu cliente",
  "addCustomFieldHelp": "Selecciona los campos adicionales que deseas agregar",
  "addOptions": "Agregar opciones",
  "addWarehouse": "Agregar bodega",
  "adjusted": "Ajustado",
  "adjustedProducts": "Productos ajustados",
  "adjustedTotal": "Total ajustado",
  "advancedOptions": "Opciones avanzadas",
  "advancedOptionsSubtitles": "Puedes configurar los datos tributarios de tu cliente, plazo de pago, vendedor y lista de precio",
  "advertisingDigitalMedia": "Publicidad / Medios digitales",
  "agricultureLivestock": "Agricultura / Ganadería",
  "Alegra": "Contabilidad",
  "AlegraAccounting": "Gestión",
  "AlegraAccounting.description": "Reportes, control de inventario y más",
  "AlegraAppsDescription": "Navega con un clic entre tus productos de Alegra",
  "alegraAppsTitle": "Alegra apps",
  "alegraAccount": "Cuenta de Alegra",
  "AlegraInvoicing": "Facturación",
  "AlegraInvoicing.description.modern": "Ventas electrónicas y tradicionales",
  "AlegraPayroll": "Nómina",
  "AlegraPayroll.description": "Liquida y emite los pagos de tu equipo",
  "AlegraPayroll.description.modern": "Emite y liquida los pagos de tu equipo",
  "AlegraPlan": "Plan de Alegra",
  "AlegraPOS": "Punto de Venta",
  "AlegraPOS.description": "Agiliza tus ventas y controla tu efectivo",
  "AlegraPOS.description.modern": "Vende desde tu tienda física",
  "AlegraShop": "Tienda",
  "AlegraShop.description": "Crea tu tienda online fácil y rápido",
  "AlegraShop.description.modern": "Vende más con tu negocio en internet",
  "alegraWeekIsHere1": "¡Llegó el Happy Week con grandes ofertas!",
  "alegraWeekIsHere1.demo": "Elige tu plan hoy con 50% OFF por 2 meses o continúa tus {} {} gratis.",
  "alegraWeekIsHere1.readOnly": "Elige tu plan hoy y obtén un 50% OFF por 2 meses.",
  "alegraWeekIsHere2": "Compra dos meses de tu plan favorito de Alegra POS y recibe el",
  "alegraWeekIsHere3": "70% de descuento",
  "all": "todos",
  "allF": "todas",
  "allInvoicesMadeFrom": "Todas las facturas realizadas desde",
  "alwaysVisibleInFE": "Siempre será visible en tus facturas electrónicas",
  "amount": "Monto",
  "andLoseSavedData": "y pierde los datos guardados",
  "aNewRegulationIsComing": "¡Se viene una nueva regulación en Colombia! 📢",
  "aNewRegulationIsComing.description": "A partir de abril tus clientes podrán exigirte una factura electrónica en las ventas que superen <strong>$235.325 antes de IVA.</strong>",
  "aNewRegulationIsComing.action": "Quiero saber más",
  "annotations": "observaciones",
  "anyDocumentSavedInOffline": "Sigue vendiendo mientras tu {} se guarda o consulta el detalle en el",
  "applyRefund.creditNote": "Aplicar nota crédito",
  "applyRefund.adjustmentNote": "Aplicar nota de ajuste",
  "architectureConstruction": "Arquitectura / Construcción",
  "areAutomaticallySavedInYour": "quedan automáticamente guardadas en tu",
  "areYouSureToDeletePendingInvoice": "¿Seguro que quieres eliminar la venta pendiente?",
  "areYouSureToVoidInvoice": "Confirma que deseas anular la venta <strong> {} </strong> para que se elimine de tus registros.",
  "artsCrafts": "Artes / Manualidades",
  "AssociatePriceListsToTerminalsNotification.title": "¡Configura tu lista de precios favorita! 💡",
  "AssociatePriceListsToTerminalsNotification.body": "Puedes definir una lista de precios en tu terminal para que venga seleccionada por defecto y ahorrar tiempo al vender.",
  "AssociatePriceListsToTerminalsNotification.action": "Ir a configurarla",
  "associatedDocument": "Documento asociado",
  "attributes": "Atributos",
  "attributedRent": "Renta atribuida",
  "automotiveAndParts": "Automotores y partes",
  "auxiliaryElectronicInvoiceReceipt": "Comprobante Auxiliar de Factura Electrónica",
  "avoidUsingSpacesAccentsAndSpecialSigns": "Evite el uso de espacios, acentos y signos especiales",
  "back": "Atrás",
  "bankAccount": "Cuenta bancaria",
  "bankAccounts": "Cuentas de banco",
  "bankAccountsTutorial": "Selecciona el banco al que ingresará el dinero según el método de pago.",
  "banksSetting": "Configuración de bancos",
  "barcode": "Código de barras",
  "base": "Base inicial",
  "baseBank": "Banco base",
  "baseBankHelp": "Elige el banco del cual saldrá el dinero para la base inicial",
  "baseHelp": "Indica el dinero en efectivo con el que inicias el turno",
  "basePrice": "Base",
  "basicInformation": "Información basica",
  "basicInformation.subtitle": "Configura algunos datos necesarios para la generación de tus documentos y transacciones.",
  "basicItemForm": "Formulario básico de items",
  "beforeContinueCompleteFields": "Antes de continuar, completa los siguientes datos.",
  "branch": "Sucursal",
  "brand": "Marca",
  "business": "negocio",
  "businessActivitiesRegime": "Régimen de actividades empresariales",
  "businessData": "Datos del negocio",
  "buyerProductCode": "Código del producto del comprador",
  "cae": "CAE",
  "caea": "CAEA",
  "caeaDueDate": "Fecha de vencimiento CAEA",
  "caeDueDate": "Fecha de vencimiento CAE",
  "cancel": "Cancelar",
  "cancelItemCreation": "Cancelar creación del producto",
  "cancelItemCreationModalConfirmation": "No volver a pedirme confirmación.",
  "cancelItemCreationModalContent": "Ten en cuenta que al confirmar la cancelación se perderán los datos que hayas ingresado del producto.",
  "cancelItemCreationModalTitle": "¿Deseas cancelar la creación de este producto?",
  "canceled": "Cancelado",
  "cannotUpdateProductImage": "Ingresa a Alegra Contabilidad para cambiar la imagen del producto.",
  "canton": "Cantón",
  "card": "Tarjeta",
  "cardType": "Tipo de tarjeta",
  "cash": "Efectivo",
  "cashAmount": "Cantidad de efectivo",
  "cashExpectedInClosingShift": "Dinero esperado en caja",
  "cashIn": "Ingresos",
  "cashInClosingShift": "Dinero en efectivo del cierre",
  "cashInClosingShift.description": "Base inicial más las ventas e ingresos en efectivo, menos las devoluciones y retiros.",
  "cashManagement": "Gestión de efectivo",
  "cashManagementExpenseHelp": "Selecciona la cuenta contable por defecto para el registro de tus gastos desde el POS",
  "cashManagementExpenseLabel": "Cuenta contable para salidas de efectivo",
  "cashManagementExpenseLabel.subtitle": "Elige la cuenta contable para el registro de tus gastos desde el POS",
  "cashManagementIncomeHelp": "Elige la cuenta contable por defecto para el registro de ingresos diferentes a ventas",
  "cashManagementIncomeLabel": "Cuenta contable para ingresos de efectivo",
  "cashManagementIncomeLabel.subtitle": "Elige la cuenta contable para el registro de ingresos diferentes a ventas",
  "cashManagment": "Gestión de efectivo",
  "cashOperationsByShifts": "Movimientos de Turnos",
  "cashOut": "Gastos",
  "cashReturned": "Cambio",
  "cashSales": "Ventas en efectivo",
  "cashToInvoices": "Crédito a facturas",
  "categories": "Categorías",
  "categoriesNoDataText": "Crea tu primera categoría fácil y rápido. 🚀",
  "categoriesSubtitle": "Crea categorías para agrupar tus productos según su clase o tipo.",
  "category": "Categoría",
  "categoryDescription": "Las categorías te sirven para agrupar tus productos y servicios según su tipo o clase.",
  "categoryImage": "Imagen de la categoría",
  "categoryType": "Tipo de referencia",
  "cause": "Razón",
  "CEDNumber": "Número de Cedula",
  "change": "Cambio",
  "changeCostConfirmationModal.confirm": "Sí, deseo ajustar el costo",
  "changeCostConfirmationModal.content.body": "Al cambiar el costo en una disminución de inventario pueden generarse diferencias en tus reportes de rentabilidad.",
  "changeCostConfirmationModal.content.title": "¿Deseas ajustar el costo?",
  "changeCostConfirmationModal.title": "Ajuste del costo promedio",
  "changeInvoiceError": "No pudimos modificar la factura. Inténtalo de nuevo",
  "changeItemImage.kit": "Cambiar imagen del combo",
  "changeItemImage.product": "Cambiar imagen del producto",
  "changeItemImage.service": "Cambiar imagen del servicio",
  "changeMethod": "Cambiar método",
  "changePlan": "cambiar mi plan",
  "characters": "carecteres",
  "checkIn": "Facturar",
  "checkingPayment": "Verificando el pago...",
  "checkingQRPayment": "Verificando estado de pago...",
  "checkInInPOS": "Facturación en POS",
  "checkInSection": "Facturar",
  "checkQrCodeError": "Esto no salió como esperábamos, intente de nuevo",
  "checkQRPayment": "Verificar pago",
  "checkTheCUIT": "Revisá el CUIT de tu cliente. 🔍",
  "checkTheCUITSubtitle": "Validá que esté bien escrito y si es correcto, te recomendamos completar los datos de forma manual.",
  "chooseMyPlan": "Elegir mi plan",
  "choosePlan": "Elegir plan",
  "choosePlanContactUs1": "¿Tienes dudas sobre cuál elegir?",
  "choosePlanContactUs2": "Te ayudamos 👉🏻",
  "choosePlanNote1": "(*) Tu plan de Alegra POS dependerá de tus ingresos mensuales.",
  "choosePlanNote2": "En todos tendrás disponible Facturación Electrónica.",
  "choosePlanSubtitle": "Elige tu plan ideal para seguir vendiendo fácil y rápido en tu negocio",
  "chooseRefundMethod": "Selecciona que tipo de devolución le vas a generar a tu cliente",
  "chooseRefundMethodNew": "Elige el tipo de devolución que le vas a registrar a tu cliente:",
  "electronicRefund": "Nota electrónica",
  "choosePlanSecondaries": "¿Aún no encuentras el mejor plan para ti?",
  "city": "Ciudad",
  "claroBusinessAccountingSoftwareAccountPoweredByAlegra": "Cuenta de Software Contable Claro Negocios con tecnología de Alegra",
  "classic": "Clásico",
  "clean": "Limpiar",
  "cleanSale": "Cancelar venta",
  "cleanSaleMessage": "Los productos serán eliminados de la venta actual",
  "cleanSaleMessageQuestion": "¿Desea continuar?",
  "clearSelection": "Borrar selección",
  "clickHereToChangeItemImage": "Haz click aquí para cambiar la imagen del producto",
  "client": "Cliente",
  "client.accountingOptionsSubtitles": "Elige la cuenta contable en la que se registrarán las cuentas por cobrar de tus ventas a crédito",
  "client.advancedOptions": "Información de venta",
  "client.advancedOptionsSubtitles": "Configura las condiciones de venta para tu cliente, como vendedor y plazo de pago",
  "client.generalInfoSubtitles": "Ingresa la información principal de tu cliente",
  "clientEmail": "Correo del cliente",
  "clients": "Clientes",
  "clientInvoice": "Factura del cliente",
  "clientIsSyncing": "El cliente se está sincronizando",
  "clientPhone": "Celular del cliente",
  "clientSyncError": "Error en la sincronización del cliente, por favor edite el cliente de la factura antes de sincronizarla nuevamente.",
  "clientSyncErrorMessage": "Error en la sincronización del cliente. Conoce <a class='text-danger font-weight-bold' href='https://www.youtube.com/watch?v=Fl51wZ9ugNE' target='_blank'><u>comó editar el cliente</u></a> en esta factura antes de sincronizarla de nuevo",
  "close": "Cerrar",
  "closed": "Cerrado",
  "closeDetail": "Cerrar detalle",
  "closeNo": "Cierre No",
  "closeShift": "Cerrar turno",
  "closeShiftError": "No pudimos cerrar el turno. Inténtalo de nuevo",
  "closure": "Cierre",
  "code": "clave",
  "codeForInternalUse": "Código uso interno",
  "colony": "Colonia",
  "combined": "Combinado",
  "commercialName": "Nombre comercial",
  "company": "Empresa",
  "companyName": "Nombre del negocio",
  "companySetting": "Configura tu negocio",
  "companySettingInfo": "Ingresa tu información y adapta Alegra POS a tu negocio",
  "companySettingSubtitle": "Actualiza los datos de tu negocio que aparecerán en tus facturas",
  "companyUpdatedSuccessfully": "Compañía configurada con éxito",
  "companyUpdatedSuccessfullyPrintTitle": "¡Ya está! 🎨",
  "companyUpdatedSuccessfullyPrintSubTitle": "La plantilla de tus documentos de venta se editó con éxito.",
  "companyUpdateError": "Error a la hora de configurar la compañía",
  "completeKit": "Completar combo",
  "concept": "Concepto",
  "configureCompany": "Configurar mi negocio",
  "configureCompanyMessage": "Para enviar ventas por email primero tiene que configurar los datos de la compañía",
  "configureFEmodal.title": "¡Ya llegó la facturación electrónica! ✨",
  "configureFEmodal.description":
    "Empezá a emitir comprobantes electrónicos en tu local hoy mismo. <b>Activalos ahora</b> siguiendo el tutorial paso a paso.",
  "configureNumberTemplate": "Configurar numeración",
  "configureShifts": "Configura turnos",
  "configureStationWarehouse": "Configurar bodega",
  "configureTemplate": "configurar plantilla",
  "configureYourCAEANumeration": "¡Configurá tu numeración CAEA! ✌",
  "configureYourCAEANumeration.description":
    "Para crear facturas electrónicas en tus puntos de venta, debés configurar tu <b>numeración de contingencia CAEA</b> con la que podrás vender incluso si AFIP está caída.",
  "configureYourCAEANumeration.button": "Configurarla ahora",
  "configuredPaymentMethodNequiMessage": "Tus clientes te pagarán en segundos leyendo el QR con su app.",
  "configuredPaymentMethodTpagaMessage": "Tus clientes podrán pagarte en segundos desde un código QR.",
  "configuredPaymentMethodWompiMessage": "Conecta tu cuenta de Wompi y valida el estado de cada transacción al vender.",
  "confirm": "Confirmar",
  "confirmPlanSubtitle": "Confirma que este es el plan que deseas adquirir y actívalo a partir del primer pago que realices en el mes de octubre.",
  "confirmPlanTitle": "¡Estás por elegir el",
  "confirmVoid": "Confirmar anulación",
  "connectWith": "Conectar con",
  "connectYourPointOfSaleWith": "Conecta tu punto de venta con",
  "consulting": "Consultoría",
  "consumptionInvoice": "Factura de Consumo",
  "consumptionElectronicInvoice": "Factura de Consumo Electrónica",
  "contactAccountingError": "Algo impidió traer la configuración contable para tu cliente, recarga e intenta crearlo de nuevo.",
  "contactAlreadyExists1": "El contacto con la identificación",
  "contactAlreadyExists2": "ya existe. Su nombre es",
  "contactSupport": "contactar a soporte",
  "contactUs": "Contáctanos",
  "contingency": "Contingencia",
  "contingencyWarning1": "Recuerda enviar a la DIAN",
  "contingencyWarning2": "desde Alegra Contabilidad",
  "contingencyWarning3": "los documentos equivalentes que generaste durante el día sin IVA, conoce cómo hacerlo",
  "continue": "Continuar",
  "contractUpdated.success": "¡El contrato se actualizó correctamente!",
  "contractUpdated.info1": "Consulta o descarga el contrato con los",
  "contractUpdated.info2": "* Podrás acceder a tu contrato de facturación desde",
  "controlInventory": "Controlar inventario",
  "controlInventoryHelp": "Activa esta opción si deseas tener el control de cantidades",
  "cost": "costo",
  "costCenter": "Centro de costo",
  "costCenterSetting": "Centro de costo",
  "costCenterSettingTutorial":
    "Selecciona centro de costos para distribuir los ingresos y gastos según las áreas, proyectos o divisiones de tu negocio.",
  "country": "País",
  "createCustomField": "Crear campo",
  "createClient": "Crear cliente",
  "createClientError": "Error en la creación del contacto",
  "createClientToSelect": "Crea un nuevo cliente para seleccionarlo",
  "createThisNewClientToSelect": "Crea este nuevo cliente para seleccionarlo",
  "createdUsers": "Usuarios creados",
  "createFirstInvoice": "Crear mi primera factura",
  "createInvoiceError": "Error en la creación de la factura",
  "createItemCategory": "Crear categoría",
  "createItemError": "Error en la creación del producto",
  "createItemSubmitError1": "Configura la sección de variantes.",
  "createItemSubmitError1Message":
    "Desde la sección variantes debes seleccionar por lo menos una opción e indicar la cantidad disponible en bodega de las que elijas.",
  "createItemSubmitError2": "Agrega los productos que integran tu combo.",
  "createItemSubmitError2Message":
    "Podrás asignar la cantidad de cada uno para empezar a venderlo y tener el control de las unidades disponibles e ingresos.",
  "createKit": "Crear combo",
  "createKitHelp": "Agrupa en un solo ítem un conjunto de productos, servicios o una combinación de ambos.",
  "createPaymentError": "Error al crear el pago",
  "createPaymentTermError": "Error en la creación del plazo de pago",
  "createPriceList": "Crear lista",
  "createPriceListError": "Error en la creación de la lista de precio",
  "createProduct": "Crear producto",
  "createProductHelp": "Crea los bienes y mercancías que vendes e incluye todos sus detalles.",
  "createRefundError": "Error a la hora de crear la devolución",
  "createSellerError": "Error en la creación del vendedor",
  "createService": "Crear servicio",
  "createServiceHelp": "Crea las actividades comerciales o de consultoría que ofreces a tus clientes.",
  "createVariant": "Crear variante",
  "createVariantError": "Hubo un error creando la nueva variante.",
  "createVariantHelp": "Crea una nueva característica con sus opciones, como por ejemplo diferentes colores o tamaños.",
  "createWarehouse": "Crear bodega",
  "createWarehouseError": "Error en la creación de bodega",
  "createdAt": "Creado el",
  "creation": "Creación",
  "credit": "Crédito",
  "credit-card": "Crédito",
  "creditBank": "Banco crédito",
  "creditCard": "Tarjeta de crédito",
  "creditNoteType": "Tipo de nota crédito",
  "creditPaymentSales": "Ventas a crédito",
  "creditSales": "Ventas por tarjeta de crédito",
  "creditToInvoice": "Crédito a factura",
  "creditToSales": "Crédito a ventas",
  "cude": "CUDE",
  "cufe": "CUFE",
  "CUITIdentificationIsANumericalValue": "El CUIT debe ser un valor numérico, puede tener guiones",
  "currency": "Moneda",
  "currenciesSettings": "configuración de monedas",
  "currentPlan": "Plan actual",
  "currentPOSPlan": "Plan actual",
  "currentQuantity": "Cantidad actual",
  "currentShift": "Turno en curso",
  "currentShiftDetails": "Detalles del turno en curso",
  "currentShiftDetails.description": "Conoce los movimientos de efectivo en tu turno actual.",
  "custom": "Personalizado",
  "customFieldActivated": "Campo adicional activado con éxito",
  "customFieldCreatedSuccessfully": "Ya quedó guardado. 😎",
  "customFieldCreatedSuccessfullyMessage": "Tu campo adicional {} está disponible para asociarlo a tus productos.",
  "customFieldDeactivated": "Campo adicional desactivado con éxito",
  "customFieldDeleted": "Campo adicional eliminado con éxito",
  "customFieldDeletedError": "Error eliminando el campo adicional",
  "customFieldNoDataText": "Crea tu primer campo adicional ahora. 🙌",
  "customFields": "Campos adicionales",
  "customFieldsInventoryInfo": "Crea campos adicionales para agregar información extra en tus items, como el código de barras.",
  "customFieldStatusError": "Error cambiando el estado del campo adicional",
  "customFieldsSubtitle": "Crea campos personalizables para agregar información adicional de tus productos o servicios.",
  "customFieldType": "Tipo de campo",
  "customFieldType.text": "Texto",
  "customFieldType.int": "Número",
  "customFieldType.decimal": "Número decimal",
  "customFieldType.date": "Fecha",
  "customFieldType.boolean": "Si/No",
  "customFieldType.optionsList": "Lista de opciones",
  "customFieldTypeHelp": "Con esta opción podrás definir la estructura y formato de tu campo.",
  "customFieldUpdated": "Campo adicional actualizado con éxito",
  "dailyReport": "Comprobante de informe diario",
  "dailyReportDescription": "Exporta el <a href='https://ayuda.alegra.com/es/conoce-el-comprobante-de-informe-diario-del-sistema-punto-de-venta-Colombia' target='_blank' rel='noreferrer'>resumen diario</a> de tus ventas registradas en documentos equivalentes.",
  "dataOffline": "Sincronización pendiente",
  "dataOfflineSubtitles": "Te recomendamos sincronizar ahora para evitar la perdida de información valiosa.",
  "date": "Fecha",
  "dateFormat": "DD/MM/YYYY",
  "dateFormatDateFNS": "dd/MM/yyyy",
  "dateTimeFormat": "DD/MM/YYYY h:mm a",
  "day": "día",
  "days": "días",
  "daysQuantity": "Cantidad de días",
  "deactivate": "Desactivar",
  "debit": "Débito",
  "debit-card": "Débito",
  "debitBank": "Banco débito",
  "debitCard": "Tarjeta de débito",
  "debitSales": "Ventas por tarjeta de débito",
  "debtToPay": "Cuenta por pagar",
  "decimalPrecision": "Precisión decimal",
  "decimalPrecisionTooltip": "Selecciona el número de decimales que utilizarás para los precios de tus productos y valores de tus facturas",
  "decimalSeparator": "Separador decimal",
  "decrement": "Disminuir",
  "defaultCashBankTutorial": "Esta cuenta de banco se asigna por defecto a cada terminal para que tengas un mejor control del efectivo.",
  "defaultClientIVAError": "Recuerda que para emitir facturas electrónicas con productos sin IVA, debes crear tu cliente con su identificación",
  "defaultSeller": "Vendedor por defecto",
  "defaultValue": "Valor por defecto",
  "defaultValueHelp.customField": "Define el valor predeterminado que tomará este campo al asociarlo a un producto.",
  "defaultValueHelp.customField.boolean": "Marca esta opción para que este campo venga activo al asociarlo a uno de tus productos.",
  "delete": "Eliminar",
  "deleteInventoryAdjustment": "Eliminar ajuste",
  "deleteInventoryAdjustment.info": "Ten en cuenta que al eliminar el <b> ajuste de inventario No. {} </b> se borrará la variación de cantidades que había generado en tus productos.",
  "deleteInventoryAdjustment.modalTitle": "Eliminar ajuste de inventario",
  "deleteInventoryAdjustments": "Eliminar ajustes",
  "deleteInventoryAdjustments.info": "Ten en cuenta que al eliminar los <b> ajustes de inventario seleccionados</b>, se borrará la variación de cantidades que habían generado en tus productos.",
  "deleteInventoryAdjustments.modalTitle": "Eliminar ajustes de inventario",
  "deletePendingInvoice": "Eliminar venta pendiente",
  "deliveryTerm": "Condición de entrega",
  "department": "Departamento",
  "description": "Descripción",
  "detail": "Detalle",
  "dianValidation": "Validación DIAN",
  "disable": "Desactivar",
  "disableMulticurrency": "Deshabilitar multimoneda",
  "disablePendingInvoices": "Deshabilitar ventas pendientes",
  "disableShifts": "Deshabilitar turnos de caja",
  "discount": "Descuento",
  "discountCalculationHelTooltip": "El descuento se calculará sobre el precio base antes de<br/>impuestos, conoce más sobre",
  "discountCalculationHelTooltip.link": "https://ayuda.alegra.com/es/edita-tus-productos-mientras-vendes-pos",
  "discountCalculationHelTooltip.linkText": "cómo funcionan los descuentos",
  "discountNature": "Naturaleza del descuento",
  "discoverAvailablePlans": "¡Conoce todos los planes disponibles para ti!",
  "distributeInWarehouses": "Distribuir en bodegas",
  "district": "Distrito",
  "DNIEnteredDoesNotExistOrIsInvalid": "El DNI ingresado no existe o contiene datos inválidos, verifique la información y vuelva a realizar la consulta o continúe agregando los datos manualmente.",
  "document": "Documento",
  "download": "Descargar",
  "doYouWantToCreateAnotherContactWithSameId": "¿Estás seguro que deseas crear otro contacto con ese número de identificación?",
  "dueDate": "vencimiento",
  "dv": "DV",
  "dvTooltip": "Este campo se carga de forma automática según el código de verificación asignado por la DIAN a tu número de identificación",
  "eCommerce": "Comercio electrónico",
  "economicActivity": "Actividad económica",
  "economicActivityTooltip": "Selecciona todas tus actividades económicas y marca una como preferida desde la ⭐",
  "edit": "Editar",
  "editAndCompleteClientData": "Edita y completa los datos de tu cliente",
  "editAndCompleteClientData.description": "Para crearle una factura electrónica debe contar con el nombre o razón social y un RNC válido, {}",
  "editInventoryAdjustment.title": "Editar ajuste de inventario",
  "editInventoryAdjustment.subtitle": "Cambia los datos del ajuste de inventario que habías creado.",
  "editInvoice": "Editar factura",
  "editInvoiceSettingsError": "Error en el cambio de las configuraciones",
  "editItem": "Editar producto",
  "editItemError": "Error en la edición del producto",
  "editItemSuccessfully": "¡Ya se aplicaron tus cambios!",
  "editVariant": "Editar variante",
  "editVariantHelp": "La configuración general no puede ser editada en variantes que tengan items asociados, únicamente puedes agregar nuevas opciones.",
  "editPaymentWarning": "El nuevo valor no se reflejará en los ingresos del turno actual.",
  "education": "Educación",
  "electronicInvoice": "Factura electrónica",
  "electronicInvoiceSaved": "Factura electrónica guardada",
  "electronicMail": "Correo electrónico",
  "electronicNumerationNeeded": "Para usar esta funcionalidad debes configurar una numeración electrónica.",
  "electronicPaymentsTotalMustBeLTETotal": "El total de los pagos electrónicos debe ser igual o menor al total de la factura",
  "electronicSalesInvoice": "Factura electrónica de venta",
  "electronicSellInvoice": "Factura de venta electrónica",
  "electronicTicket": "Boleta electrónica",
  "email": "Correo",
  "emissionDate": "Fecha de emisión",
  "emissionStatus": "Estado de emisión",
  "emissionType": "Tipo de emisión",
  "emissionError": "La factura no se pudo emitir porque no cumple con las validaciones necesarias:",
  "emissionError.itbis": "El item {} solo puede tener un impuesto de tipo ITBIS o EXENTO asociado",
  "emit": "Emitir",
  "employeeRegime": "Régimen asalariados",
  "enable": "Activar",
  "enableMulticurrency": "Habilitar multimoneda",
  "enablePendingInvoices": "Habilitar ventas pendientes",
  "enableShifts": "Habilitar turnos de caja",
  "endDate": "Fecha de cierre",
  "enterValidPhoneNumber": "Ingrese un número de teléfono válido",
  "enterAmount": "Ingresar monto",
  "enterAName": "Ingresa un nombre",
  "enterAnEmail": "Ingresa un correo electrónico",
  "enterAnID": "Ingresa una identificación",
  "enterAValidEmail": "Ingresa un correo válido",
  "enterAValidIdentification": "Ingrese una identificación válida",
  "enterAPhone": "Ingresa un número de teléfono",
  "entertainment": "Entretenimiento",
  "equivalentDocumentPOSSystem": "Documento equivalente sistema P.O.S.",
  "error": "Error",
  "examiner": "Examinar",
  "exchangeRate": "Tasa de cambio",
  "exemptSubtotal": "Subtotal Exento",
  "expectedAmount": "Dinero esperado en caja",
  "expectedCash": "Dinero real en caja",
  "expectedCashHelp": "En caso de ser diferente al esperado en caja puedes realizar un ingreso o retiro de efectivo mientras válidas a que se debe.",
  "expectedCashShouldBeEqualToTotal":
    "El valor real en caja debe coincidir con el dinero esperado, puedes realizar un ingreso o retiro de efectivo mientras validas y dejar la observación.",
  "expenseCash": "Retiros de efectivo",
  "expired": "Expirado",
  "export": "exportar",
  "exteriorNumber": "Número exterior",
  "externalPaymentDeclined": "el pago ha sido rechazado",
  "fastOptions": "Opciones rápidas",
  "feArgError": "Revisá los datos de tu factura",
  "feArgValidateError": "Revisá que el tipo de documento que tiene tu numeración corresponda con tu condición frente al IVA y la de tu cliente.",
  "feArgValidateErrorMore": "Más información haciendo clic",
  "feArgValidateErrorTitle": "Tu factura no se emitió por el tipo de documento",
  "fee": "Tarifa",
  "feeRegimeProfessionalServices": "Régimen honorarios (servicios profesionales)",
  "feNumeration": "Numeración de facturas electrónicas",
  "fieldsMarkedRequired": "Los campos marcados con <span class='text-primary'>*</span> son obligatorios",
  "filter": "Filtro",
  "filter.active": "Activos",
  "filter.inactive": "Inactivos",
  "filters": "Filtros",
  "finalPrice": "Precio final",
  "finalPriceHelp": "Se calcula de forma automática o indícalo para encontrar el precio sin impuestos",
  "finalQuantity": "Cantidad final",
  "finalQuantityAbbreviation": "Cant. final",
  "firstName": "Primer nombre",
  "firstYouNeedTypeCloseToCloseSession": 'Para cerrar sesión primero confirma escribiendo "Cerrar"',
  "fiscalIncorporationRegime": "Régimen de Incorporación Fiscal",
  "fiscalResponsabilities": "Responsabilidades fiscales",
  "foodGastronomy": "Alimentación / Gastronomía",
  "forMakingInvoicesThatHasBeenOptimizedForPointsOfSale": "es un sistema ágil de facturación, optimizado para puntos de venta",
  "formatNotValid": "El formato no es válido",
  "from": "Desde",
  "fullName": "Nombre completo",
  "fvArgError": "Revisá los datos de tu factura",
  "fvArgValidateError": "Revisá que el tipo de documento que tiene tu numeración corresponda con tu condición frente al IVA y la de tu cliente.",
  "fvArgValidateErrorMore": "Más información haciendo clic",
  "fvArgValidateErrorTitle": "Tu factura no se creó por el tipo de documento",
  "generalConsumption": "Consumo general",
  "generalInfo": "Datos generales",
  "generalFeatures": "Funciones generales",
  "generalPOSFeatures": "Funciones generales del POS",
  "generalPOSFeatures.desc": "Configura las funcionalidades que vas a usar en todas las terminales de tu punto de venta",
  "generalStationInfoTutorial": "Configura los datos de tu terminal y la información necesaria para crear tus documentos",
  "generalInfoTutorial": "Configura la información de tu negocio",
  "generalRegime": "Régimen general",
  "generalRegimeOfTheLawForLegalEntities": "Régimen General de Ley Personas Morales",
  "generateQR": "Generar código QR",
  "generated": "Generado",
  "generatingYourContract": "Estamos generando tu contrato.⏳",
  "generatingYourContract.description": "En unos segundos podrás terminar de aceptar los nuevos términos y condiciones.",
  "generationDate": "Fecha de generación",
  "geographicCoordinates": "Ubicación geográfica",
  "getCED": "Consultar cédula",
  "getCUIT": "Consultar CUIT",
  "getDNI": "Consultar DNI",
  "getID": "Consultar ID",
  "getIntoOtherTerminal": "Ingresar a otra terminal",
  "getNIT": "Consultar NIT",
  "getToKnowAlegra": "Conocer Alegra Contabilidad",
  "getQrCodeError": "Esto no salió como esperábamos, intente de nuevo",
  "getRNC": "Consultar RNC",
  "getRUC": "Consultar RUC",
  "goBack": "Volver",
  "goToMenu": "Ir al menú de",
  "goToAlegra": "Ir a Alegra",
  "goToAlegraAccounting": "Ir a Alegra Contabilidad",
  "goToAdvancedForm": "Ir al formulario avanzado",
  "goToSales": "Ir a ventas",
  "goToStart": "Ir al inicio",
  "goToSubscriptions": "Ir a suscripciones",
  "goToTutorial": "Ir al tutorial",
  "governmentInvoice": "Factura Gubernamental",
  "groupData": "Agrupar datos",
  "here": "aquí",
  "hideDescription": "Ocultar descripción",
  "hideLogo": "Ocultar logo",
  "hideUnitPrice": "Ocultar precios",
  "history": "Historial",
  "shiftHistory": "Historial de turnos",
  "happyWeekTitle1": "¡Ahorra con la",
  "happyWeekTitle2": "Happy Week!",
  "happyWeekSubtitle1": "Paga",
  "happyWeekSubtitle2": "2 meses",
  "happyWeekSubtitle3": "de Alegra POS y recibe el",
  "happyWeekSubtitle4": "50% OFF",
  "happyWeekSubSubtitle": "* Válido del 9 al 16 de mayo.",
  "happyWeekTerms": "* El descuento aplica en los planes Pyme, Pro y Plus",
  "hiWeWelcomeYou": "¡Hola, te damos la bienvenida!",
  "hotelsTourism": "Hotelería / Turismo",
  "howItWorks": "Cómo funciona",
  "id": "ID",
  "IDEnteredDoesNotExistOrIsInvalid":
    "La identificacón ingresada no existe o contiene datos inválidos, verifique la información y vuelva a realizar la consulta o continúe agregando los datos manualmente.",
  "identification": "Identificación",
  "identificationEnteredDoesNotExistOrIsInvalidTitle": "Revisa la identificación de tu cliente. 🔍",
  "identificationEnteredDoesNotExistOrIsInvalidSubtitle": "Verifica que esté bien escrito y si es correcto, te recomendamos agregar los datos de forma manual.",
  "identificationNumber": "Número de identificación",
  "identificationType": "Tipo de identificación",
  "ifYouCloseSessionThenYouLostData": "Puedes cancelar para sincronizarlos y evitar perderlos permanentemente.",
  "itemsLoadError": "Sucedió un error cargando los productos, intentalo nuevamente",
  "imageSelector": "Selector de imagen",
  "imageTooLargeError": "Ocurrió un error al recibir el archivo, verifica que el tamaño de la imagen sea menor que 2MB",
  "imABusinessman": "Soy empresario",
  "imAnAccountant": "Soy contador",
  "imAStudent": "Soy estudiante",
  "imFreelancerIndependent": "Soy freelancer/independiente",
  "immediatePaymentSale": "Ventas al contado",
  "inactive": "Desactivado",
  "inactivePaymentMethod": "Método de pago desactivado",
  "inactiveProducts": "Inactivos",
  "inAmount": "Valor ingresado",
  "includeAccountStatement": "Incluir estado de cuenta",
  "includeAccountStatementSubtitle": "Al enviar una factura al correo, esta irá acompañada del resumen de todas sus facturas",
  "includesIVA": "Incluye IVA",
  "income": "Ingresos",
  "incomeLimitNotification":
    "Has excedido el límite de ingresos mensuales de  tu plan actual, elige un nuevo plan para realizar esta acción y seguir creciendo con Alegra POS.",
  "incomesTo": "Ingresos hasta",
  "increment": "Incrementar",
  "indicateTheVariantQuantity": "Indica aquí las cantidades en tus bodegas",
  "indicationsWillArriveToEmail": "El paso a paso para configurar tu método de pago llegará al correo",
  "initAppError": "Ups! El POS no se ha podido cargar",
  "initAppErrorMessage": "Por favor intenta alguna de las siguientes acciones:",
  "initAppOfflineDataErrorMessage": "Hay datos que no han sido sincronizados pertenecientes a otra terminal. Si continúa podría perderlos.",
  "initialQuantity": "Cantidad inicial",
  "initialQuantityAbbreviation": "Cant. inicial",
  "inOctober": "en octubre",
  "inOutCash": "Nuevo movimiento de efectivo",
  "inPayment": "ingreso",
  "inProgress": "En progreso",
  "integrateYourPaymentMethods": "Integra tus métodos de pago",
  "interiorNumber": "Número interior",
  "internalOperationInvoice": "Factura de operación interna",
  "inType": "Ingreso",
  "inventariable": "Inventariable",
  "inventariableHelp": "Distribuye tus productos entre tus bodegas",
  "inventory": "Inventario",
  "inventoryAdjustment": "Ajuste de inventario",
  "inventoryAdjustments": "Ajustes de inventario",
  "inventoryAdjustmentstInfo": "Registra aumentos y disminuciones manuales para controlar las cantidades de tus productos.",
  "inventoryAdjustmentCreateError": "Error creando ajuste de inventario",
  "inventoryAdjustmentCreated.title": "¡Ajustado! ⚖",
  "inventoryAdjustmentCreated.subtitle": "Ya se guardó el ajuste <span class='font-weight-bold'>No. {}</span> y se hicieron los movimientos de cantidades.",
  "inventoryAdjustmentCreatedLocally.title": "¡Creado como pendiente! 📌",
  "inventoryAdjustmentCreatedLocally.subtitle": "El ajuste se guardará en tu punto de venta cuando recuperes tu conexión.",
  "inventoryAdjustmentDeleted.title": "Ya se eliminó tu ajuste 🗑",
  "inventoryAdjustmentDeleted.subtitle": "El ajuste de inventario <span class='font-weight-bold'>No. {}</span> ya no hace parte de tus registros.",
  "inventoryAdjustmentsDeleted.title": "Ya se eliminaron tus ajustes 🗑",
  "inventoryAdjustmentsDeleted.subtitle": "Los ajustes de inventario que seleccionaste ya no hacen parte de tus registros.",
  "inventoryAdjustmentDeletedLocally.title": "¡Eliminación programada! 🗑",
  "inventoryAdjustmentDeletedLocally.subtitle": "El ajuste se borrará de forma definitiva en cuanto recuperes tu conexión.",
  "inventoryAdjustmentEdited.title": "¡Cambios listos! ✨",
  "inventoryAdjustmentEdited.subtitle": "El ajuste de inventario <span class='font-weight-bold'>No. {}</span> ya tiene la forma que le diste.",
  "inventoryAdjustmentEditedLocally.title": "Cambios pendientes ✍",
  "inventoryAdjustmentEditedLocally.subtitle": "Los datos editados del ajuste se guardarán cuando recuperes tu conexión.",
  "inventoryWarehouses": "Bodegas de inventario",
  "invoice": "Factura",
  "invoiceCash": "Ingresos en efectivo",
  "invoiceMethod": "Método de pago",
  "invoiceNFC": "Factura/NCF",
  "invoiceNumbering": "Numeración de facturas",
  "invoiceNumberError": "Ya hay una factura con el número de este comprobante, conoce cómo <a href='https://app.powerbi.com/view?r=eyJrIjoiZDdmNTBlYTktYmE0Ni00NGM3LWJhNzYtMGZhNjBlNTQyYTdmIiwidCI6IjYxOGQwYTQ1LTI1YTYtNDYxOC05ZjgwLThmNzBhNDM1ZWU1MiJ9' target='_blank' rel='noreferrer'><b>editar la numeración actual</b></a> o selecciona otra antes de vender",
  "invoiceOfSale": "Factura de venta",
  "invoicePoint": "Punto de facturación",
  "invoicePreview": "Vista previa de la tirilla",
  "invoicePrint": "Impresión de tirilla",
  "invoicePrintFooter": "Generado en Alegra POS - alegra.com/pos",
  "invoicePrintFooterClaro": "Generado en Software Contable Claro Negocios con tecnología de Alegra - software-contable.com.co",
  "invoicePrintSetting": "Configura tu plantilla de impresión",
  "invoicePrintSettingSubtitle": "Elige los parámetros que utilizarás y serán visibles en tus facturas.",
  "invoiceSaved": "Factura guardada",
  "invoiceSavedInOffline": "Sigue vendiendo mientras tu factura se guarda o consulta el detalle en el",
  "invoicesCreatedInTheMonth": "Facturas creadas en el mes",
  "invoiceSendByEmailError": "Error al tratar de enviar la venta por correo",
  "invoiceSendByEmailSuccessfully": "Se ha enviado con éxito",
  "invoiceSettings": "Configuración de venta",
  "invoicesInfo": "configura los documentos de venta que usas en tu negocio",
  "invoicesLoadError": "Revisa que tengas conexión a internet y recarga para ver tus ventas",
  "invoicesPerMonth": "facturas por mes",
  "invoicesSettingInfo": "configura los documentos de venta que usas en tu negocio",
  "invoiceStampedSuccess": "La factura electrónica de venta se emitió con éxito",
  "invoiceStatusclosed": "Cobrada",
  "invoiceStatusopen": "Por cobrar",
  "invoiceStatusvoid": "Anulada",
  "invoiceSyncError": "Error al sincronizar",
  "invoiceSyncErrorOffline": "Se guardará cuando tengas internet ☝️",
  "invoiceSyncErrorOfflineSubtitle": "Podrás consultar la factura en el historial de ventas en cuanto recuperes tu conexión.",
  "invoiceSyncErrorSubtitle": "Una de las facturas de venta está pendiente por sincronizar. Más detalles",
  "invoiceType": "Tipo de factura",
  "invoiceWithoutClientError": "No se pudo asociar el contacto genérico, intenta facturar de nuevo por favor",
  "invoices": "Facturas",
  "isMomentToChoseYourPlan": "Es momento de elegir tu plan ideal para seguir vendiendo fácil y rápido en tu negocio.",
  "item": "Producto",
  "itemActivated": "Item activado con éxito",
  "itemAlreadyInactive": "El item {} ya se encontraba desactivado",
  "itemWithNameActivated": "Item {} activado con éxito",
  "itemWithNameDectivated": "Item {} desactivado con éxito",
  "item.accountingOptions": "Configuración contable",
  "item.accountingOptionsSubtitles": "Elige la cuenta en donde se registrarán los ingresos de este producto",
  "item.adicionalDataOptions": "Datos adicionales para facturas de exportación",
  "item.adicionalDataOptionsSubtitles": "Completa estos datos si el producto que estás creando se usará en facturas de exportación.",
  "item.advancedOptionsSubtitles": "Agrega el código de barras y la descripción de tus productos",
  "item.electronicFieldsOptions": "Campos adicionales",
  "item.electronicFieldsOptionsSubtitles": "Selecciona los campos que deseas incluir para personalizar tus productos y servicios.",
  "item.generalInfo": "Información general",
  "item.generalInfo.subtitle": "Configura la información principal y los datos de contacto de tu negocio.",
  "item.generalInfoSubtitles": "Indica los datos básicos de tu producto o servicio",
  "item.inventoryOptions": "Detalles de inventario",
  "item.inventoryOptionsSubtitles": "Distribuye y controla las cantidades de tus productos en diferentes lugares.",
  "item.kitListOptions": "Combo",
  "item.kitListOptionsSubtitles": "Selecciona los productos y sus cantidades para armar un combo.",
  "item.priceListOptions": "Listas de precios",
  "item.priceListOptionsSubtitles": "Agrega diferentes precios a tus productos.",
  "item.variantsListOptions": "Variantes",
  "item.variantsListOptionsSubtitles": "Agrega atributos como talla, color u otra cualidad para categorizar tus productos.",
  "items": "Productos",
  "itemAdded": "Producto agregado",
  "itemsAdded": "Productos agregados",
  "itemsAndServices": "Productos y servicios",
  "itemsAssociatedToInvoice": "Productos de tu documento asociado",
  "itemStatusError": "Error al cambiar estado del item",
  "itemCategoryActivatedSuccessfully": "Categoría activada con éxito.",
  "itemCategoryCreatedSuccessfully": "Así se hace. 😎",
  "itemCategoryCreatedSuccessfullyMessage": "La categoría {} ya está disponible para que clasifiques tus productos.",
  "itemCategoryDeactivatedSuccessfully": "Categoría desactivada con éxito.",
  "itemCategoryDeletedSuccessfully": "La categoría ya está eliminada.",
  "itemCategoryDeletedError": "Error eliminando la categoría.",
  "itemCategoryStatusError": "Error cambiando el estado de la categoría.",
  "itemCategoryUpdatedSuccesfully": "Categoría actualizada con éxito.",
  "itemCreatedSuccessfully": "Directo al blanco. 🎯",
  "itemDeactivated": "Item desactivado con éxito",
  "itemCategoriesAreEmpty": "Las categorías que seleccionaste están vacías en la bodega de esta terminal. 🔍",
  "itemCategoryIsEmpty": "La categoría {} está vacía en la bodega de esta terminal. 🌪️",
  "itemCreatedSuccessfullyAlter": "Ya está creado tu producto. 🏷",
  "itemCreatedSuccessfullyMessage": "El {} {} ya está disponible en tu cuenta y se encuentra listo para la venta.",
  "itemCreatedSuccessfullyMessageAlter": "Ten en cuenta que no lo asociaste a la bodega de tu terminal actual y para venderlo debes ",
  "itemCreatedSuccessfullyMessageLink": "cambiar de terminal o editar esta.",
  "itemDescriptionHelp": "Clasifica tus productos y servicios por tipos o grupos",
  "itemDeleted": "Producto eliminado con éxito",
  "itemDeletedError": "Error eliminando producto",
  "itemImage": "Imagen del producto",
  "itemImage.kit": "Imagen del combo",
  "itemImage.product": "Imagen del producto",
  "itemImage.service": "Imagen del servicio",
  "itemManagementInfo": "Crea, edita y administra cada detalle de los productos o servicios que vendes.",
  "itemLimitWarningTitle": "Ya vendiste todas las unidades. 🏁",
  "itemLimitWarningTitle.kit": "Revisa los productos del combo 🔍",
  "itemLimitWarningTitle.kitAvailable": "¡Puedes vender máximo {}! ✋",
  "itemLimitWarningSubtitle.kitAvailable": 'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.',
  "itemLimitWarningSubtitle": "Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo",
  "itemLimitWarningSubtitle.kit": 'El Combo "{}" no se puede vender porque tiene productos sin unidades disponibles.',
  "itemMinimumQuantityReached": "Llegó a la cantidad mínima configurada, recuerda abastecerte.",
  "itemQuantityIsGreaterThanAvailable": "La cantidad vendida supera la disponible en el inventario",
  "itemsAreLoadingInBackground": "Seguimos cargando tus productos, sentimos que esté tardando más de los esperado",
  "itemsInitialLoad": "Cargando tus productos, danos un tiempo más",
  "itemsInSaleExceededReference": "Debes editar los ítems seleccionados que tengan una \"referencia\" con más de 20 caracteres.",
  "itemsPerPage": "Items por página",
  "itemsSearching": "buscando los productos",
  "itemVariantHelp": "Indica aquí las cantidades en tus bodegas",
  "itemsToAddInDevolution": "Productos que se incluirán en la devolución de tu cliente",
  "itemsToAddInDevolution.creditToSales": "Elige los productos del documento asociado que devuelve el cliente",
  "itemsToAdjust": "Productos a ajustar",
  "ivaCondition": "Condición de IVA",
  "joyride.back": "Volver",
  "joyride.close": "Cerrar",
  "joyride.last": "Finalizar",
  "joyride.next": "Siguiente",
  "joyride.open": "Abrir recorrido",
  "joyride.skip": "Saltar",
  "keepInMind": "Ten en cuenta",
  "KeepTheFormEmail": "Conserva la forma: ejemplo@correo.com",
  "keyboardShortcuts": "Atajos del teclado",
  "kindOfPerson": "Tipo de persona",
  "kit": "Combo",
  "kitKey": "Código del producto",
  "kitName": "Nombre del combo",
  "kits": "combos",
  "knowMore": " Saber más.",
  "knowRecentChanges": "Conoce los cambios recientes",
  "knowTheFirstStepsInPOS": "Conoce los primeros pasos en POS",
  "knowTheNewRegulation": "¡Conoce la nueva regulación para puntos de venta! 🤓",
  "knowTheNewRegulation.description": "Desde el 1 de abril se deberá crear facturas electrónicas en lugar de documentos equivalentes en las ventas que superen $235.325",
  "knowTheNewRegulation.action": "Quiero saber más",
  "knowUpdatedData": "Conocer datos actualizados",
  "lastDate": "Última fecha",
  "lastName": "Apellidos",
  "later": "Más tarde",
  "learnHowItWorks": "Aprender cómo funciona",
  "leasingRegime": "Régimen de arrendamiento",
  "learnToCreateIt": "Aprender a crearla",
  "learnToUseShifts": "Aprende a usar los turnos",
  "learnToUseShifts.link": "https://ayuda.alegra.com/es/realiza-una-apertura-y-cierre-de-turnos-en-el-sistema-punto-de-venta-pos",
  "legalPerson": "Persona jurídica",
  "legalRepresentativeData": "Datos del representante legal",
  "legalRepresentativeData.tooltip": "Es la persona que actúa en nombre de la empresa y está registrada en el RUT",
  "legalStatus.inProcess": "En proceso",
  "legalStatus.issued": "Emitida",
  "legalStatus.rejected": "Rechazada",
  "legalStatus.toBeIssued": "Por emitir",
  "legalStatus.contingency": "Contingencia",
  "limitedPlanNotification": "¡Actualiza tu suscripción y no pares tu facturación!🚨",
  "limitedPlanNotification.description": "Superaste las características de tu plan y a partir del lunes 13 de marzo solo podrás ver la información creada hasta ese día.",
  "limitedPlanNotification.action": "Actualizar suscripción",
  "limitedQueryPlan": "¡Actualiza tu suscripción y no pares tu facturación!🚨",
  "limitedQueryPlan.description": "Te encuentras en un Plan Consulta y a partir del lunes 13 de marzo solo podrás ver la información creada hasta ese día.",
  "limitedQueryPlan.action": "Actualizar suscripción",
  "loadingQRPayment": "Generando código para pago...",
  "loadingResourcesSubtitle": "Puedes vender mientras cargan tus productos y clientes",
  "localData": "Datos locales",
  "locality": "Localidad",
  "logoImageSize": "178x53 pixeles",
  "logout": "Cerrar sesión",
  "logoutMessage": "Verifica la sincronización de los datos antes de cerrar",
  "logoutMessageQuestion": "¿Desea cerrar sesión?",
  "logoSubtitle": "Se mostrará en la parte superior de tus facturas de venta.",
  "logoSubtitle.text1": "Si necesitas ayuda, puedes conocer",
  "logoSubtitle.text2": "cómo agregar tu logo",
  "mainSale": "Venta principal",
  "makeSureYouAreRegisteredWithTheLastStation": "Asegurate de estar registrado en la última estación con la que se trabajó",
  "makeSureYouHaveTheNecessaryPermissions": "Asegurate de tener los permisos necesarios para ingresar al POS",
  "makeVisible": "Hacer visible",
  "manageShifts": "Administrar turnos",
  "manageShiftsHelp": "Te permite controlar tus operaciones por periodos de tiempos definidos",
  "manageStations": "gestionar terminales",
  "manufacture": "Manufactura",
  "maxCharactersExceeded": "Máximo {} caracteres",
  "maxDiscountExceeded": "Máximo descuento: {}%",
  "maxQuantity": "Cantidad máxima",
  "maxQuantityHelp": "Recibe alertas cuando llegues al stock máximo que indiques.",
  "measurement": "Medida",
  "measurementUnit": "Unidad de medida",
  "membershipError": "Parece que ocurrio un error 🙁. Lo estamos solucionando por favor intenta de nuevo más tarde o ponte en contacto con soporte",
  "messageDeleteMainActivity": "Antes de eliminar esta actividad debes marcar la que quedara como preferida.",
  "minQuantity": "Cantidad mínima",
  "minQuantityHelp": "Recibe alertas cuando llegues al stock mínimo que indiques.",
  "mobilePhone": "Celular",
  "model": "Modelo",
  "modern": "Moderno",
  "month": "Mes",
  "monthly": "Mensual",
  "monthlyPlanButton": "Mensual",
  "moreInfo": "Más información",
  "moreSettingInfo": "Puedes encontrar más funcionalidades para tu negocio en Alegra Contabilidad",
  "moreSettings": "Más configuraciones",
  "multicurrency": "Multimoneda",
  "multicurrency.subtitle": "Registra tus documentos de venta en diferentes monedas",
  "multicurrencyField": "Utilizar multimoneda",
  "multicurrencyFieldHelp": "Genera tus facturas POS en diferentes monedas",
  "municipality": "Municipio",
  "myBusiness": "Mi negocio",
  "myCompany": "Mi empresa",
  "myCustomFields": "Mis campos adicionales",
  "myProfile": "Mi perfil",
  "mySellers": "Mis vendedores",
  "mySubscription": "Mi suscripción",
  "myWarehouses": "mis bodegas",
  "mypeTaxRegime": "Régimen MYPE tributario",
  "myVariants": "Mis variantes",
  "name": "Nombre",
  "nameOrSocialReason": "Nombre o razón social",
  "naturalPerson": "Persona natural",
  "needConnectionToEditAdjustment": "Necesitas tener conexión para editar un ajuste.",
  "negativeSale": "Vender sin unidades disponibles",
  "neighborhood": "Barrio",
  "nequi": "Nequi",
  "networkErrorMessage": "Verifica tu conexión e inténtalo de nuevo",
  "new": "Nuevo",
  "new.alter": "Nuevo 🔥",
  "newCategory": "Nueva categoría",
  "newClient": "Nuevo cliente",
  "newClientSubtitle": "Crea los contactos que asociarás en tus facturas de venta.",
  "newCurrency": "nueva moneda",
  "newCustomField": "Nuevo campo adicional",
  "newCustomFieldModalSubtitle": "Agrega información adicional para personalizar tus productos y servicios.",
  "newCustomFieldModalTitle": "Nuevo campo adicional",
  "newInventoryAdjustment": "Nuevo ajuste de inventario",
  "newInventoryAdjustmentSubtitle": "Modifica las cantidades de los productos que tienes en la bodega de tu terminal actual.",
  "newItem": "Nuevo producto o servicio",
  "newItemButton": "Nuevo producto",
  "newItemClothingStores": "Nuevo producto",
  "newItemCategory": "Nueva categoría",
  "newItemCategoryModalTitle": "Nueva categoría",
  "newItemCategoryModalSubtitle": "Crea nuevas categorías para clasificar tus productos y ubicarlos fácilmente.",
  "newItemSubtitle": "Crea los productos o servicios que ofreces en tu negocio.",
  "newNumeration": "Nueva numeración",
  "newPaymentTerm": "Nuevo plazo de pago",
  "newPriceList": "Nueva lista",
  "newPriceListModalTitle": "Nueva lista de precio",
  "newPriceListModalSubtitle": "Crea una lista para utilizar un precio diferente en tus productos o servicios.",
  "newRefund": "Nueva devolución",
  "newRefundRefundCashMethodDescription": "Devuelve a tu cliente el total o parte del dinero de sus pagos.",
  "newRefundCreditToSaleshMethodDescription": "Disminuye el valor por cobrar en una factura de tu cliente.",
  "newRefundCombinedMethodDescription": "Devuelve dinero a tu cliente y a la vez aplica crédito a una de sus ventas.",
  "newrefundPositiveBalanceMethodDescription": "Registra la devolución de productos y deja el valor como anticipo de tu cliente.",
  "newRegulationForPOS": "¡Nueva regulación para puntos de venta! 📢",
  "newRegulationForPOS.description": "Desde el 1 de abril tus ventas en documento POS que superen <strong>$235.325 antes de IVA</strong> deberán ir en factura electrónica.",
  "newRegulationForPOS.action": "Cumplir desde ahora",
  "newRUS": "Nuevo RUS",
  "newSale": "Nueva venta",
  "newSeller": "Nuevo vendedor",
  "newStation": "Nueva terminal",
  "newVariant": "Nueva variante",
  "newTermsAndConditions": "Nuevos términos y condiciones",
  "newTermsAndConditionsHere": "Visualiza aquí el contrato",
  "newWarehouse": "Nueva bodega",
  "newWarehouseSubtitle": "Crea todos tus puntos de almacenamiento de tu inventario",
  "next": "Siguiente",
  "nextPaymentDate": "Próxima fecha de pago",
  "NITEnteredDoesNotExistOrIsInvalid":
    "El NIT ingresado no existe o contiene datos inválidos, verifique la información y vuelva a realizar la consulta o continúe agregando los datos manualmente.",
  "IDEnteredDoesNotExistOrIsInvalidTitle": "Verifica el número de identificación. 🔍",
  "IDEnteredDoesNotExistOrIsInvalidSubtitle": "Parece que no está asociado a un contribuyente en Hacienda y los comprobantes electrónicos que generes a su nombre podrían ser rechazados.",
  "no": "No",
  "noCashAvailable": "El valor del retiro debe ser menor al efectivo disponible en caja",
  "noClientIdentificationError": "Elige un cliente con identificación",
  "noClientIdentificationErrorSubtitle": "En facturas con un valor superior a RD$ 250,000 debes elegir un cliente con RNC o cédula.",
  "noCustomFieldType": "Elige un tipo de campo",
  "noDataFound": "No hay datos que coincidan con los filtros aplicados",
  "noDataOffline": "Sincronización al día",
  "noDataOfflineSubtitles": "Todo está disponible para que registres tus ventas",
  "noDate": "Elige una fecha",
  "noElectronicNumeration": "Debes {} para configurarla en tu terminal actual.",
  "noElectronicNumeration1": "crear una numeración electrónica",
  "noEligibleStation": "No hay terminales para elegir",
  "noEligibleStationMessage": "Verifica que hay por lo menos una terminal activa",
  "noFiscalValue": "Sin valor fiscal",
  "noInventoryAdjustmentsFound.title": "Sin resultados 🔦",
  "noInventoryAdjustmentsFound.subtitle": "Ajusta los filtros para encontrar lo que estás buscando",
  "noITBMS": "Debes incluir el impuesto ITBMS o exento para facturarlo.",
  "noItbmsWarning.summary": "Parece que seleccionaste productos que no tienen impuestos ITBMS y debes validarlos para crear tu factura.",
  "noItemsInInventoryAdjustmentError": "Debes agregar al menos un producto a tu ajuste",
  "noIvaDay": "Día sin IVA",
  "noIVADayItemsLimitWarning":
    "Ya este cliente adquirió las 3 unidades de esta categoría que se encuentran exentas y en la próxima se le cobrará el IVA.",
  "noIVADayItemsLimitWarningInEdit1": "Te recomendamos cobrar este producto con IVA, porque ya seleccionaste las",
  "noIVADayItemsLimitWarningInEdit2": "3 unidades exentas",
  "noIVADayItemsLimitWarningInEdit3": "que pertenecen a esta categoría",
  "noIVADaysNotification": "Prepárate para el día sin IVA",
  "noIVADaysNotification2": "Prepara tu facturación de contingencia",
  "noItbisWarning.items": "Debes incluir el impuesto ITBIS o exento para facturarlo.",
  "noItbisWarning.summary": "Parece que seleccionaste productos que no tienen impuestos ITBIS y debes validarlos para crear tu factura.",
  "notItemsYet": "Para crear tu primera factura te invitamos a agregar un producto o servicio. 🏷️",
  "none": "Ninguno",
  "notItemsYetClothingStores": "Agrega un producto para asociarlo en tu primera factura. 👔👚",
  "notValidInvoiceArg": "Factura no valida como comprobante fiscal",
  "nonGovernmentalOrganization": "Organización no gubernamental",
  "noProductsSatisfySearch": "no hay productos que satisfacen la búsqueda",
  "noResultsElectronic": "No tienes ninguna numeración de nota crédito electrónica",
  "noResultsNoElectronic": "No tienes ninguna numeración de nota crédito",
  "noResultsSaleTicket": "No tienes ninguna numeración de nota de ajuste",
  "noResultsWereFound": "No se encontraron resultados",
  "noSelectedInvoice": "Selecciona una factura para conocer el detalle o crea una nueva venta ahora.",
  "noSelectedPayment": "Selecciona una operación de efectivo para ver el detalle o crea una nueva",
  "noSelectedQRPayment": "Selecciona por lo menos un pago de la lista para ver el detalle o crea el primero aquí",
  "noSelectedRefund": "Elige una devolución para conocer el detalle o crea una nueva ahora.",
  "noShiftInQuery": "Selecciona un nuevo período de consulta que cuente con turnos cerrados.",
  "noShiftsConfigured": "Los turnos son intervalos de tiempo que sirven para administrar tus operaciones en tu compañía. Comienza a utilizarlos",
  "noStationPermissionError": "¿Necesitas acceder a esta terminal?",
  "noStationPermissionWithNameError": "¿Necesitas ingresar a la terminal{}?",
  "noStationPermissionErrorMessage": "Solicita acceso a un perfil administrador o ingresa desde otra terminal en la que tengas permiso.",
  "noStationPermissionErrorMessageStart": "Solicita al administrador de tu cuenta que te vincule a",
  "noStationPermissionErrorMessageEnd": "desde la configuración de las terminales.",
  "noClosedShiftsYet.payments": "Aquí conocerás el resumen de los métodos de pago utilizados por tus clientes en los turnos cerrados.",
  "noClosedShiftsYet.shifts": "En este espacio verás el comportamiento de tus ingresos y gastos incluidos en los turnos cerrados.",
  "notes": "Notas",
  "notesHelpTooltip": "Visible en la impresión del documento",
  "notConfiguredPaymentMethod": "Método de pago no configurado",
  "notConfiguredPaymentMethodMessage": "no está configurado aún. Si quieres configurarlo debes ir",
  "notInvoiceYet": "Crea tu primera venta en segundos.",
  "notifications": "Notificaciones",
  "notificationTomorrowForFE": "Mañana te notificaremos de nuevo.☝️",
  "notificationTomorrowForFE.description": "Ten en cuenta que es necesario aceptar los nuevos términos y condiciones para facturar electrónicamente.",
  "notPaymentYet": "Crea tu primer movimiento de efectivo para registrar un ingreso o salida de dinero.",
  "notQRPaymentYet": "No has creado tu primer pago, créalo aquí",
  "notRefundYet": "Crea en segundos la primera devolución que te realice un cliente.",
  "notResponsibleForConsumption": "No responsable de consumo",
  "notResponsibleForConsumptionSimplifiedRegimeOfTheINC": "No responsable de consumo (Régimen simplificado del INC)",
  "notResponsibleForVAT": "No responsable de IVA",
  "notResponsibleForVATSimplifiedRegime": "No responsable de IVA (Régimen simplificado)",
  "notSelected": "No seleccionado",
  "noZeroAllowed.title": "Indica la cantidad a ajustar",
  "noZeroAllowed.subtitle": "Ten en cuenta que los valores de la columna <span class='font-weight-bold'>“Cantidad”</span> deben ser diferentes a cero.",
  "number": "Número",
  "numberOfInvoices": "número de facturas",
  "numeration": "Numeración",
  "numerationExpired": "Numeración vencida 📅",
  "numerationExpired.notification.title": "¡Se venció la fecha de tu numeración! 📅",
  "numerationExpired.notification.text": "Ten en cuenta que la numeración {} ya está vencida y a partir del 17 de julio no podrás usarla al vender.",
  "numerationExpired.body": "Elige otra numeración o configura una nueva para seguir facturando.",
  "numerationExpired.button": "Cómo configurar otra",
  "numerationExpired.tooltip": "La numeración está vencida.",
  "numerationMaxNumberReached": "La numeración se quedo sin consecutivos",
  "numerationMaxNumberReached.body": "Elige otra numeración o configura una nueva para seguir facturando.",
  "numerationMaxNumberReached.button": "Cómo configurar otra",
  "numerationMaxNumberReached.notification.title": "¡Se acabaron los consecutivos de tu numeración! 🚨",
  "numerationMaxNumberReached.notification.text": "Ten en cuenta que la numeración {} se agotó y a partir del 17 de julio no podrás usarla al vender.",
  "numerationMaxNumberReached.tooltip": "La numeración se quedó sin consecutivos.",
  "numerations": "Numeraciones",
  "numerationsSetting": "Configuración de numeraciones",
  "numerationsSettingTooltip": 'Gestiona desde "Alegra Contabilidad" las numeraciones que usas para facturar en tu negocio.',
  "objective": "objetivo",
  "obligatoryItemsDescription1": "La descripción de tus ítems es obligatoria.",
  "obligatoryItemsDescription2": "Agrégala para emitir tus documentos electrónicos en la SUNAT, conoce cómo hacerlo",
  "observations": "Observaciones",
  "of": "de",
  "ofFreeTrialToDiscoverHowEasyItIsToRunYourBusiness": "de prueba gratis para descubrir lo fácil que es gestionar tu negocio.",
  "offlineNotificationTitle": "¡Estás usando Alegra POS sin internet!",
  "offlineNotification": "Puedes seguir facturando y tus ventas se guardarán al recuperar la conexión.",
  "onboardingEndSubtitle": "Crear facturas en segundos, controlar tu inventario y gestionar el efectivo de tu negocio ¡Ya es posible con Alegra Punto de venta! 🙌",
  "onboardingEndTitle": "¡Ahora es tu turno!",
  "onboardingWelcomeSubtitle": "Conoce en este recorrido cómo <b>crear más rápido tus facturas de venta</b> y empieza a darle superpoderes a tu negocio.",
  "onboardingWelcomeSubtitleClothingStore": "Descubre en el siguiente recorrido lo fácil que es gestionar las ventas de tu tienda 👕🕶 y empieza a darle superpoderes a tu negocio.",
  "onboardingWelcomeTitle": "¡Te damos la bienvenida a Alegra POS! 😉",
  "onboardingWizardSubtitleStep0": "Es el primer paso para personalizar tu punto de venta",
  "onboardingWizardSubtitleStep1": "Dale play y descubre lo fácil que vas a gestionar tu negocio con Alegra POS",
  "onboardingWizardSubtitleStep1ClothingStore": "Dale play y descubre lo fácil que vas a <b>gestionar tu tienda de ropa</b> a partir de hoy",
  "onboardingWizardTitleStep0": "¡Configura los datos de tu negocio!",
  "onboardingWizardTitleStep0ClothingStore": "¡Agrega los datos de tu tienda de ropa!",
  "onboardingWizardTitleStep1": "¡Toma el control de tu punto de venta!",
  "onboarding.accounting.button": "Probar 15 días gratis",
  "onboarding.accounting.header": "¡Dale superpoderes a tu empresa!",
  "onboarding.accounting.message.1": "Obtén una",
  "onboarding.accounting.message.2": "visión completa de toda la información",
  "onboarding.accounting.message.3":
    "de tu negocio con Alegra Contabilidad, gestiona tus compras, ventas e inventario y además genera reportes inteligentes.",
  "onboarding.payroll.button": "Probar 15 días gratis",
  "onboarding.payroll.header": "Resuelve tu Nómina Electrónica en segundos",
  "onboarding.payroll.message.1": "Prueba ahora con Alegra Nómina cómo",
  "onboarding.payroll.message.2": "emitir en tiempo récord",
  "onboarding.payroll.message.3": "los comprobantes del pago que haces a tus empleados y cumple de forma fácil con la DIAN.",
  "onboarding.readonly.button": "ELEGIR PLAN",
  "onboarding.readonly.header": "¡Sigue vendiendo fácil y rápido! 😎",
  "onboarding.readonly.message.1": "Ya disfrutaste de tu prueba gratis,",
  "onboarding.readonly.message.2": "elige tu plan ahora",
  "onboarding.readonly.message.3": "y continúa teniendo el control de tus puntos de venta.",
  "onboarding.shop.button": "Probar 15 días gratis",
  "onboarding.shop.header": "¡Tu negocio siempre en línea con Alegra Tienda!",
  "onboarding.shop.message.1": "Empieza a vender por internet, promociona tus productos o servicios y mantén tu negocio",
  "onboarding.shop.message.2": "a solo un clic de distancia,",
  "onboarding.shop.message.3": "obteniendo más clientes con un nuevo canal de ventas.",
  "onboarding.suspendedPlan.button": "Activar mi cuenta",
  "onboarding.suspendedPlan.header": "Esta función está inactiva",
  "onboarding.suspendedPlan.message":
    "Hemos presentado inconvenientes con el cobro de tu plan y tu cuenta ha sido suspendida. Puedes activarla realizando tu pago y si tienes alguna inquietud, escríbenos al correo",
  "onboarding.video.url": "https://www.youtube.com/embed/sFZpQMY6P3Q",
  "onboarding.video.id": "sFZpQMY6P3Q",
  "onlyInvoicingTooltip": "Para disfrturar de esta funcionalidad, adquiere un plan todo incluido",
  "onlyNumericValuesAreSupported": "Solo se admiten valores numéricos",
  "open": "Abierto",
  "openShift": "Abrir turno",
  "openShiftError": "Error en la apertura del turno",
  "openShiftMessage": "Con los turnos vas a tener una mejor gestión del efectivo en tu punto de venta.",
  "openShiftMoreMessage": "Aprende más sobre los turnos",
  "operationType": "Tipo de operación",
  "option": "Opción",
  "options": "Opciones",
  "orRestartYourBrowser": "o reinicia tu navegador",
  "other": "otro",
  "otherMethods": "Otros métodos",
  "others": "Otros",
  "outAmount": "Valor retirado",
  "outPayment": "Retiro",
  "outstandingInvoiceAmount": "Pendiente por cobrar",
  "outType": "Retiro",
  "paid": "Pagado",
  "paperSalesInvoice": "Factura de venta de papel",
  "payInvoice": "Pagar factura",
  "payAnyDocument": "Pagar {}",
  "paymentDate": "Fecha de pago",
  "paymentDateDay": "1 de octubre del 2021",
  "paymentDeclined8.title": "Actualiza tus datos de pago",
  "paymentDeclined8.text": "Hemos detectado un problema con tu método de pago al realizar el cobro de tu suscripción. <b>¿Deseas agregar un nuevo método de pago?</b>",
  "paymentDeclined8.init": "Si necesitas ayuda puedes ",
  "paymentDeclined8.link": "contactar a soporte.",
  "paymentDeclined8.action": "Agregar método de pago",
  "paymentDeclined12.title": "Tu suscripción será suspendida",
  "paymentDeclined12.text": "No fue posible procesar tu último pago, por favor, <b>agrega un nuevo método de pago</b> para evitar que tu cuenta sea suspendida.",
  "paymentDeclined12.init": "Si necesitas ayuda puedes ",
  "paymentDeclined12.link": "contactar a soporte.",
  "paymentDeclined12.action": "Agregar método de pago ahora",
  "paymentForm": "Forma de pago",
  "paymentForm.Cash": "Contado",
  "paymentFrequency": "Frecuencia de pago",
  "paymentMethod": "Método de pago",
  "paymentMethodHelp": "En Alegra POS puedes crear facturas de contado, conoce como generar tus facturas a crédito en Alegra Contabilidad",
  "paymentNotVerified": "Pago sin verificar",
  "paymentOrder": "Orden de pago",
  "paymentsClientHelp": "Marca si la opción está ligada a un cliente",
  "paymentsLoadError": "Error cargando las operaciones de efectivo de la terminal",
  "paymentTerm": "Plazo de pago",
  "paymentTermCreatedSuccessfully": "Plazo de pago creado con éxito",
  "paymentType": "Tipo de pago",
  "paymentTypes": "Tipos de pago",
  "payYourPlan": "Paga tu plan",
  "payYourPlanIndications1": "Sigue disfrutando de tu demo, el 1 de octubre enviaremos a tu correo los pasos para",
  "payYourPlanIndications2": "configurar tu método de pago",
  "payYourPlanIndications3": "y activar tu plan.",
  "pending": "Pendiente",
  "pendingInvoices": "Ventas pendientes",
  "pendingInvoiceSettingsTutorial": "Crea varias facturas a la vez para manejar por ejemplo el control de mesas.",
  "pendingInvoicesPrefix": "Prefijo de ventas pendientes",
  "pendingPaymentMethod": "Método de pago pendiente",
  "pendingPaymentMethodMessage": "Está en proceso de activación. Esto puede tardar un tiempo",
  "pendingSales": "Ventas pendientes",
  "percentage": "Porcentaje",
  "periodDifference": "Diferencia",
  "periodExpense": "Gastos del período",
  "periodIncome": "Ingresos del período",
  "periods": "Períodos",
  "personalCareAestheticsHealth": "Cuidado personal / Estética / Salud",
  "personTypeByNacionality": "Tipo de persona según nacionalidad",
  "phone": "Teléfono",
  "physicalPerson": "Persona física",
  "planConsumption": "Consumo del plan",
  "planIncomeFeature": "Ingresos hasta {}{} {} al mes",
  "planInvoicesFeature": "{} facturas de venta al mes",
  "planInvoiceUnlimitedFeature": "Facturas de venta ilimitadas al mes",
  "planLimitReached": "Superaste los límites de tu {}",
  "planLimitReachedTitle": "¡Tu negocio sigue creciendo! 🙌",
  "planLimitReachedSubtitle": "Cámbiate a un plan hecho a tu medida para seguir vendiendo fácil y rápido en tu punto de venta con mayores beneficios.",
  "planLimitReachedDescription1": "Más ingresos mensuales",
  "planLimitReachedDescription2": "Mayor cantidad de usuarios",
  "planLimitReachedDescription3": "Más bodegas para tu inventario",
  "planLimitReachedFooter": "¿Tienes dudas de cuál elegir? <span class='font-weight-bold'>Te ayudamos</span> 👉'",
  "planName.pyme-pos": "Plan Pyme",
  "planName.pro-pos": "Plan Pro",
  "planName.plus-pos": "Plan Plus",
  "planName.emprendedor-pos": "Plan Emprendedor",
  "planName.corporativo-pos": "Plan Corporativo",
  "pos": "POS",
  "posDocument": "Documento POS",
  "positiveBalance": "Saldo a favor",
  "POSSetting": "Funcionalidades POS",
  "posSettingsSubtitles": "Aquí puedes configurar los datos que intervienen directamente con el POS",
  "POSSettingTutorial": "Activa las funcionalidades que utilizarás en todas tus terminales",
  "POSStations": "Terminales POS",
  "POSStationsFeature": "{} Puntos de venta",
  "POSStationsFeatureUnlimited": "Puntos de venta ilimitados",
  "POSTutorial": "Tutorial POS",
  "preInvoice": "Pre-Factura",
  "prepareFEServiceDown": "¡Prepárate para el mantenimiento de la DIAN!",
  "prepareFEServiceDown.description": "Hoy {} de {} entre las {} y las {} el Sistema de Facturación Electrónica estará en mantenimiento.",
  "prepareNow": "Prepararme ahora",
  "presumptiveRent": "Renta presunta",
  "previous": "Anterior",
  "price": "Precio",
  "priceBeforeTaxes": "Precio base",
  "priceList": "Lista de precio",
  "priceListActivatedSucessfully": "Lista de precio activada con éxito",
  "priceListCreatedSuccessfully": "Ya puedes asociar tu lista de precios. 🎊",
  "priceListCreatedSuccessfullyMessage": "Tu lista {} ya está disponible para que la asocies a tus productos.",
  "priceListDeactivatedSuccessfully": "Lista de precio desactivada con éxito",
  "priceListDeletedError": "Error eliminando la lista de precio",
  "priceListDeletedSuccessfully": "Lista de precio eliminada con éxito",
  "priceListStatusError": "Error cambiando estado de la lista de precio",
  "priceListType.percentage": "Porcentaje",
  "priceListType.amount": "Valor",
  "priceListUpdatedSuccessfully": "Lista de precio actualizada con éxito",
  "priceLists": "Listas de precios",
  "priceListsHelp": "Usa listas de precios para cambiar el precio de tus productos de forma rápida",
  "priceListsHelpTooltip": "Las listas de precio te permiten definir precios especiales para clientes o grupos de productos y servicios. ¡Configúralas desde tu punto de venta!",
  "priceListsKnowMore": "Saber más",
  "priceListsLoadError": "Sucedió un error cargando las listas de precios.",
  "priceListsNoDataText": "Crea tu primera lista de precios en un parpadeo. 👀",
  "priceListsSubtitle": "Asigna varios precios a tus productos con un valor fijo o un porcentaje de descuento sobre el precio base.",
  "print": "imprimir",
  "printAlign": "Alineación del encabezado",
  "printAlignHelp": "Elige la forma en que se mostrarán los datos de tu negocio.",
  "printDescription": "Incluir descripción de tus productos en tus facturas",
  "printDescriptionHelp": "Esta opción mostrará la descripción de los productos en la impresión",
  "printedRepresentationOfElectronicInvoice": "Representación impresa de la factura electrónica",
  "printedRepresentationOfElectronicCreditNote": "Representación impresa de la nota crédito electrónica",
  "printFormat": "Formato de impresión",
  "printFormatHelp": "Selecciona el tamaño y las márgenes de tu plantilla en milímetros (mm)",
  "printItemFullLine": "Agrupar en una columna los datos del producto",
  "printItemFullLineHelp": "Activa para mostrar el nombre, cantidad y precio en una sola columna.",
  "printLeftMargin": "Márgen izquierdo",
  "printLeftMarginHelp": "Configura el márgen en mm",
  "printLogo": "Agregar tu logo en la impresión de tus facturas",
  "printOnInvoice": "Imprimir en facturas",
  "printCustomPhrase": "Frase Personalizada:",
  "printCustomPhrasePlaceholder": "Procura que quede en máximo 200 caracteres",
  "printImageOrQr": "Imagen o QR",
  "printPersonalizedInvoices": "Personalización de facturas",
  "printPersonalizedInvoicesHelp": "Dale tu toque a la parte final de tus facturas con una frase, imagen o ambas.",
  "printRightMargin": "Márgen derecho",
  "printRightMarginHelp": "Configura el márgen en mm",
  "printSize": "tamaño de tirilla",
  "printTemplate": "Plantilla de impresión",
  "printTemplateField": "Tipo de tirilla",
  "printTemplateHelp": "Selecciona la plantilla para la impresión de tus facturas.",
  "printTemplateSettingInfo": "Configura cómo se verán las facturas de tu negocio",
  "printUnitPrice": "Mostrar precio unitario de tus productos en tus facturas",
  "printUnitPriceHelp": "Esta opción mostrará el precio unitario de los productos en la impresión",
  "printMeasurement": "Incluir la unidad de medida en tus facturas",
  "printMeasurementHelp": "Has visible la unidad de medida de tus productos y servicios.",
  "printLines": "Mostrar total de líneas y productos en tus facturas",
  "printLinesHelp": "Incluye el número de líneas de tu factura y el total de productos vendidos.",
  "product": "Producto",
  "productCodeAssignedByTheIndustry": "Código del producto asignado por la industria",
  "productDistributionHelp.init": "Conoce",
  "productDistributionHelp.mid": " cómo distribuir en diferentes bodegas",
  "productDistributionHelp.end": " este producto.",
  "productKey": "Código del producto",
  "productKeyHelp": "Es un campo obligatorio si generas facturas electrónicas, conoce {} a tus productos.",
  "productKeyHelp1": "cómo agregar los códigos",
  "productName": "Nombre del producto",
  "productsToRefund": "Productos a devolver",
  "productWithoutVariants": "Producto sin variantes",
  "productWithVariants": "Producto con variantes",
  "productWithVariantsHelp": "Indica si este producto tiene variantes como color, talla u otra cualidad.",
  "province": "Provincia",
  "pstRegime": "Régimen PST",
  "qrPayments": "Pagos QR",
  "QRPaymentsLoadError": "Error cargando los pagos de proveedores externos",
  "quantity": "Cantidad",
  "quantityExceededInInventory": "Supera la cantidad en inventario",
  "quater": "Trimestre",
  "readAndAccept": "Leer y aceptar",
  "realEstate": "Inmobiliario",
  "reason": "motivo",
  "reasonRefund": "Razón",
  "receiptNumbering": "Numeración de recibos de caja",
  "receiveYourPaymentsFrom": "Recibe pagos desde",
  "recommendedPlan": "Recomendado ⭐",
  "recordSale": "Registrar venta",
  "reference": "Referencia",
  "referenceHelp": "Código único para identificar tus productos, ejemplo CAS002",
  "refund.electronicCreditNote": "Nota crédito electrónica",
  "refund.creditNote": "Nota crédito",
  "refund.adjustmentNote": "Nota de ajuste",
  "refund.adjustemntNoteDocumentPos": "Nota de ajuste del documento equivalente electrónico",
  "referenceMaximumCharactersForEInvoice": "La referencia puede tener máximo 20 caracteres para elegirlo en un documento electrónico.",
  "refundAccountWarning": "Solo se descontarán del valor en caja de tu cierre de turno los valores asociados a la cuenta bancaria del Efectivo POS.",
  "refundErrorTotalAmount": "El monto asociado a las ventas debe ser igual al monto total de la nota de crédito",
  "refundCash": "Devolución de dinero",
  "refundCombinedMethodDescription": "Podrás utilizar devolución de dinero y crédito a factura",
  "refundCreatedSuccessfully": "Devolución creada con éxito",
  "refundCreditToInvoiceMethodDescription": "Disminuirá el valor por pagar en la factura de tu cliente",
  "refundNumbering": "Numeración de devolución",
  "refundPositiveBalanceMethodDescription": "Quedará como anticipo para una nueva factura",
  "refundRefundCashMethodDescription": "Se descontará el efectivo de la cuenta que elijas",
  "refundAmount": "Monto a devolver",
  "refundCreated": "Devolución creada 🎉",
  "refundCreatedMessage.adjustNote": "Tu nota de ajuste se registró con éxito.",
  "refundCreatedMessage.creditNote": "Tu nota de crédito se registró con éxito.",
  "refundItems": "Productos devueltos",
  "refundItemInactive": "desactivado, actívalo de nuevo para poder devolverlo.",
  "refunds": "Devoluciones",
  "refundSettingsError": "Error a la hora de cambiar las configuraciones",
  "refundsLoadError": "Error cargando las devoluciones de la terminal",
  "refundTotalExceedsBalance": "El total a devolver debe ser menor o igual al valor pendiente por cobrar.",
  "refundType": "Tipo de nota de crédito",
  "regime": "Régimen",
  "regimeOfAgriculturalLivestockSilvicultureFishingActivities": "Régimen de Actividades Agrícolas, Ganaderas, Silvicolas y Pesqueras",
  "regimeOfLegalEntitiesForNonProfitPurposes": "Régimen de las Personas Morales con Fines No Lucrativos",
  "regimeOfTheCoordinates": "Régimen de los Coordinados",
  "rejected": "rechazado",
  "reloadPage": "recarga la página",
  "rememberConfigureCAEA": "¡Recordá configurar tu numeración CAEA! 👉",
  "rememberConfigureCAEA.description":
    "Si necesitás emitir comprobantes electrónicos en tu local, debés solicitar y configurar una numeración CAEA de contingencia.",
  "rememberCloseTheShift": "Recuerda cerrar el turno o desactivar la opción de controlar turnos para tener una mejor experiencia.",
  "rename": "Renombrar",
  "renamePendingInvoice": "Renombrar factura pendiente",
  "renamePendingInvoiceHelp": "Para cambiar el prefijo general tienes que dirigirte a",
  "required": "Obligatorio",
  "requiredCustomFields": "Campos adicionales obligatorios",
  "requiredHelp.customField": "Si activas esta opción, siempre deberás definir un valor para este campo en tus productos.",
  "requiredCustomFieldsHelp": "Completa tus campos obligatorios antes de crear tu producto",
  "requiredField": "Este campo es obligatorio",
  "requiredFields": "Campos obligatorios",
  "requiredFieldsMissing": "Hay algunos campos obligatorios sin completar",
  "resolution": "Resolución",
  "responsibility": "Responsabilidad",
  "responsibleForVAT": "Responsable del IVA",
  "responsibleForVATCommonRegime": "Responsable del IVA (Régimen común)",
  "result": "resultado",
  "results": "resultados",
  "resultsPerPage": "Resultados por página",
  "retailTrade": "Comercio al detal",
  "retry": "Reintentar",
  "reversed": "Revertido",
  "reviewPending": "Revisar pendientes",
  "reviewRequirements": "Revisar requisitos",
  "rucNotAvailable.summary": "Para emitir una boleta electrónica tu cliente debe tener un tipo de identificación diferente a RUC.",
  "rucNotAvailable.new.summary": "Para clientes con tipo de identificación 'RUC' debes elegir una numeración de facturas electrónicas.",
  "identificationNotAvailable.new.summary": "Debes elegir una numeración de boletas porque el cliente tiene un tipo de identificación diferente a 'RUC'.",
  "RNCEnteredDoesNotExistOrIsInvalid": "El RNC ingresado no existe o contiene datos inválidos, verifique la información y vuelva a realizar la consulta o continúe agregando los datos manualmente.",
  "RNCNumber": "Número de RNC",
  "RNCorCED": "RNC o Cédula",
  "RUCEnteredDoesNotExistOrIsInvalid": "El RUC ingresado no existe o contiene datos inválidos, verifique la información y vuelva a realizar la consulta o continúe agregando los datos manualmente.",
  "RUCNotValidHelp": "El RUC ingresado tiene un formato no válido",
  "runtimeErrorMessage": "Por favor, intente de nuevo",
  "rucIsInvalidFormat": "Ingrese un formato correcto para el RUC",
  "rucIsInvalid": "El RUC es inválido para el tipo de receptor",
  "sale": "Venta",
  "saleCondition": "Condición",
  "salesHistory": "Historial de ventas",
  "salesHistoryLink": "Historial de ventas →",
  "salePrice": "Precio de venta",
  "salePriceHelp": "Precio del producto antes de impuestos",
  "sales": "Ventas",
  "saleTicket": "Factura",
  "saleTicketSaved": "Factura guardada",
  "invoiceModalTitle.saleTicket": "Factura",
  "saleTickets": "Documentos equivalentes POS",
  "saleTickets.subtitle": "Crea documentos equivalentes para registrar y soportar las ventas de tu negocio.",
  "saleTicketsFieldHelp": "Crea documentos equivalentes a la factura electrónica para puntos de venta.",
  "save": "Guardar",
  "saveAndCreateNew": "Guardar y crear nueva",
  "saveAndCreateNewAlt": "Guardar y crear nuevo",
  "saveChanges": "Guardar cambios",
  "saveInventoryAdjustment": "Guardar ajuste",
  "savePayment": "Guardar pago",
  "savingChanges": "Guardando tus cambios...",
  "scanQRCode": "Escanear código QR",
  "scanTheCode": "Escanea este código con tu app",
  "searchItemByBarcode": "Código de barras",
  "search": "Buscar",
  "searchActivity": "Buscar actividad",
  "searchItemByText": "Buscar productos",
  "secondName": "Segundo nombre",
  "secondNoIvaDayItsComing": "¡Se viene el segundo Día sin IVA del 2022! 🚀",
  "secondNoIvaDayItsComing.description": "Conoce todo lo que debes tener en cuenta este 17 de junio para incrementar tus ventas durante el segundo Día sin IVA del año.",
  "sector": "Sector",
  "security": "Seguridad",
  "securityCode": "Código de seguridad",
  "seeContract": "Ver contrato",
  "seeMore": "Ver más",
  "seePrices": "Ver precios",
  "seeHelp": "Ver ayuda",
  "select": "Seleccionar",
  "selectACause": "Selecciona un motivo",
  "selectAClient": "Selecciona un cliente",
  "selectAItemAtLeast": "Seleccione un elemento al menos",
  "selectAStation": "Selecciona una terminal",
  "selectAStationHeadline": "Selecciona la terminal para ejecutar tus operaciones",
  "selectProduct": "Seleccionar producto",
  "selectProductHelp": "Agrega aquí los productos de tu combo",
  "selectProducts": "Selecciona los productos",
  "selectProductsToCreateYourInvoice": "Aquí verás los productos que elijas en tu próxima venta",
  "selectProductsToCreateYourRefund": "Selecciona al menos un producto para crear tu devolución",
  "selectTheIdentificationType": "Seleccione el tipo de identificación",
  "selected": "seleccionado",
  "selectedPlural": "seleccionados",
  "sell": "Vender",
  "sellDetails": "Detalles en ventas",
  "sellDetailsInfo": "gestiona los datos relacionados con el registro de tus ventas",
  "seller": "Vendedor",
  "sellerActivated": "Vendedor activado con éxito",
  "sellerCreated": "Vendedor creado con éxito",
  "sellerCreatedSuccessfully": "Muy bien.",
  "sellerCreatedSuccessfullyMessage": "Ya puedes asociar en tus facturas a {} como vendedor.",
  "sellerDeactivated": "Vendedor desactivado con éxito",
  "sellerDeleted": "Vendedor eliminado con éxito",
  "sellerDeletedError": "Error eliminando vendedor",
  "sellerProductCode": "Código del producto del vendedor",
  "sellers": "Vendedores",
  "sellersLoadError": "Sucedió un error cargando los vendedores",
  "sellersSettingInfo": "Gestiona la información de los integrantes de tu equipo de ventas",
  "sellersSettingSubtitle": "Crea y administra los integrantes de tu equipo de ventas.",
  "sellerStatusError": "Error cambiando estado del vendedor",
  "sellerUpdated": "Vendedor actualizado con éxito",
  "sellSaved": "Venta guardada",
  "semiIntegrated": "Semi integrado",
  "send": "Enviar",
  "sendByMail": "Enviar por correo",
  "sendEmail": "Enviar correo electrónico",
  "sendNequiNotificationToClient": "Envía una notificación de pago al Nequi de tu cliente.",
  "sendPaymentRequest": "Enviar solicitud de pago",
  "sendRequestToApp": "Enviar solicitud a la app",
  "service": "Servicio",
  "serviceKey": "Código del servicio",
  "serviceName": "Nombre del servicio",
  "setStationWarehouse": "Configura una bodega para tu terminal",
  "setStationWarehouseHelp": "Parece que se desactivó o eliminó la bodega que estaba asociada a tu terminal, selecciona una para seguir vendiendo.",
  "settings": "Configuraciones",
  "shiftClosed": "Turno cerrado",
  "shiftClosedSuccessfully": "¡Boom!",
  "shiftClosedSuccessfullyMessage": "Tu turno se encuentra cerrado y ya estás teniendo el control del efectivo en tu punto de venta.",
  "shiftClosingTitle": "Conoce los movimientos de efectivo en tu turno actual.",
  "shiftIsOpenToMuchTime":
    "Tienes un turno abierto hace más de una semana. Te recomendamos cerrarlo para controlar tus ingresos o desactivar esta opción desde la configuración de tu negocio.",
  "shiftNumber": "Número de turno",
  "shiftOpen": "Turno abierto",
  "shiftOpenSuccessfully": "Turno abierto con éxito",
  "shifts": "Turnos",
  "shiftSetting": "Configuración de turnos",
  "shiftSettingTutorial": "Elige el banco de apertura y de cierre de los turnos para esta terminal. ",
  "shiftsField": "Turnos de caja",
  "shiftsField.subtitle": "Ten el control de tu dinero usando la apertura y cierre de caja",
  "shiftsFieldHelp": "Ten el control de tu dinero utilizando la apertura y cierre de caja",
  "shiftsLoadError": "Error cargando el historial de turnos de la terminal",
  "shiftsStatisticsHelpText": "Conoce el detalle de tus turnos cerrados en el período de tiempo que elijas.",
  "shiftsStatisticsInfo": "Conoce la información de tus ingresos y egresos en los turnos cerrados.",
  "shiftStatistics": "Reportes de turnos",
  "showDescription": "Mostrar descripción",
  "showing": "Mostrando",
  "showInfo": "Mostrar información",
  "showingPage": "Mostrando página",
  "showLogo": "Mostrar logo",
  "showShortcuts": "Mostrar atajos",
  "showUnitPrice": "Mostrar precios",
  "sign": "Firmar",
  "simple": "Simple",
  "simpleEstimationRegime": "Régimen de estimación simple",
  "simpleTaxationRegime": "Régimen Simple de Tributación",
  "simplifiedRegime": "Régimen simplificado",
  "simplifiedTaxation": "Tributación simplificada",
  "smartDataUpdate": "Actualización inteligente de datos ✨",
  "smartDataUpdate.description": "A partir de mayo, <b>tu nombre o razón social en Alegra debe coincidir con tu RUT</b> según la DIAN. Lo actualizamos por ti desde ya para que tu negocio esté siempre al día.",
  "socialRazon": "Razón social",
  "solutions": "Soluciones",
  "solutions.description": "Soluciones que le dan superpoderes a tu negocio",
  "solutions.managment": "Administrar soluciones",
  "specializedServices": "Servicios especializados",
  "specialRegime": "Régimen especial",
  "specialTaxationRegimeInvoice": "Factura de Régimen Especial de Tributación",
  "specialTaxationRegimes": "Regímenes especiales de tributación",
  "stamped": "Emitida",
  "stamped03": "Emitida-03",
  "stampedWithObservations": "Emitida con observaciones",
  "start": "Iniciar",
  "startDate": "Fecha de inicio",
  "startPreparing": "Empezar a prepararme",
  "startTour": "Empezar el recorrido",
  "state": "Estado",
  "station": "Terminal",
  "stationActivated": "Terminal activada con éxito",
  "stationCreatedSuccessfully": "¡Lista!",
  "stationCreatedSuccessfullyMessage": "La terminal {} ya está disponible para que gestiones las ventas de tu negocio.",
  "stationDeactivated": "Terminal desactivada con éxito",
  "stationDeleted": "Terminal eliminada con éxito",
  "stationDeletedError": "Error eliminando terminal",
  "stationName": "Nombre de tu terminal",
  "stationRefundNumbering": "numeración para devoluciones de clientes",
  "stations": "Terminales",
  "stationSetting": "Configuración de terminal",
  "stationSettingInfo": "Administra tus puntos de atención y controla las ventas de cada uno",
  "stationSettingSubtitle": "Configura los datos de tu terminal y la información necesaria para facturar.",
  "stationSettingTitle": "Terminal actual",
  "stationsForYourPointOfSale": "Terminales para tus puntos de venta",
  "stationsLoadError": "Sucedió un error cargando las terminales",
  "stationsSettingSubtitle": "Crea y administra las terminales que usas para la gestión de tus puntos de venta.",
  "stationsSettingTitle": "Gestiona tus terminales POS",
  "stationStatusError": "Error cambiando estado de terminal",
  "stationUpdated": "Terminal actualizada",
  "stationWarehouseDeletedError": "Solo puedes eliminar bodegas que no estén asociadas a una terminal.",
  "stationWarehouseStatusError": "Solo puedes desactivar bodegas que no estén asociadas a una terminal.",
  "statistics": "Reportes",
  "status": "Estado",
  "stopMakeVisible": "Dejar de hacer visible",
  "stopShowInfo": "Dejar de mostrar información",
  "street": "Calle",
  "subscriptionHeader": "¡Ya facturas fácil y rápido!",
  "subscriptionIndications": "Con Alegra POS vendes en segundos y haces crecer tu negocio. Conoce a continuación los detalles de tu suscripción:",
  "subscriptionLoadingMessage": "Espera un momento mientras se carga la información de tu plan. ⌛",
  "subscriptionStatus": "Estado de suscripción",
  "subtotal": "Subtotal",
  "success": "Éxito",
  "successful": "Exitoso",
  "successfulPayment": "¡Pago exitoso!",
  "sumary": "Resumen",
  "sunatCode": "Código producto SUNAT",
  "sunatCodeHelp": "Conoce cómo agregar el código de SUNAT a tus productos y servicios ",
  "sync": "Sincronizar",
  "syncError": "Error de sincronización",
  "syncErrorSubtitle": "Cuando hay errores en la sincronización debes validar los permisos o problemas de conexión",
  "synchronizeData": "Sincronizar datos",
  "syncing": "Sincronizando",
  "tariffHeading": "Partida arancelaria",
  "tariffHeadingHelp": "De uso exclusivo para exportadores en la venta de mercancías",
  "tax": "Impuesto",
  "taxCreditInvoice": "Factura de Crédito Fiscal",
  "taxElectronicCreditInvoice": "Factura de Crédito Fiscal Electrónica",
  "taxedSubtotal": "Subtotal Gravado",
  "taxName": "Nombre fiscal",
  "taxSummary": "Resumen de impuestos",
  "taxes": "Impuestos",
  "taxesHelp": "Si tu producto tiene varios impuestos, puedes seleccionarlos dando clic sobre el nombre de cada uno en el listado desplegable.",
  "taxesSettingTooltip": 'Configura desde "Alegra Contabilidad" los impuestos que necesitas para facturar en tu punto de venta.',
  "taxLiability": "Responsabilidad tributaria",
  "technologyTelecommunications": "Tecnología / Telecomunicaciones",
  "tellUsMoreAboutYourBusiness": "Cuéntanos más sobre tu negocio para que Alegra trabaje especialmente para ti",
  "termsConditions": "Términos y condiciones",
  "termsConditionsForElectronicInvoices": "Términos y condiciones para facturar electrónicamente",
  "termsConditionsForElectronicInvoices.description": "Los nuevos términos y condiciones te permitirán <b>seguir usando el certificado digital de Alegra</b> para emitir tus comprobantes electrónicos.",
  "theAmountCannotBeGreaterThanRefundTotal": "El monto debe ser menor o igual que el total de la devolución",
  "theCombinedAmountCannotBeGreaterThanRefundTotal": "El monto combinado debe ser menor o igual al total de la devolución",
  "theDIMEXIsANumericalValue": "El DIMEX es un valor numérico, sin ceros al inicio y sin guiones.",
  "theLegalIDMustBeWithoutHyphens": "La cédula jurídica debe estar sin guiones.",
  "theMaximumSizeForThisFieldIs": "El tamaño máximo del campo es",
  "theMinimumSizeForThisFieldIs": "El tamaño mínimo del campo es",
  "theNITEMustBeWithoutHyphens": "El NITE debe estar sin guiones.",
  "thereWasAnErrorLoadingShiftValues": "Hubo un error cargando los valores del turno.",
  "theValueOfPaymentsShouldBeGreaterThanZero": "El valor de los pagos debe ser mayor que cero",
  "thisFieldIsRequired": "Este campo es obligatorio",
  "ticket": "Boleta",
  "ticketsPerMonth": "Tickets mensuales",
  "to": "Hasta",
  "today": "Hoy",
  "todayIsSecondNoIvaDay": "¡Es hoy, es hoy el segundo Día sin IVA! 🙌",
  "todayIsSecondNoIvaDay.description": "Revisa que tengas todo listo para incrementar tus ventas en este gran día y síguele dando superpoderes a tu negocio.",
  "toPay": "Por pagar",
  "total": "Total",
  "totalCost": "Costo total",
  "totalItems": "Total de productos",
  "totalOfMovements": "Total de movimientos",
  "totalOfShiftMovements": "Total de movimientos del turno",
  "totalQuantity": "Cantidad Total",
  "totalReceived": "Total recibido",
  "totalRows": "Total de líneas",
  "totalToRefund": "Total a devolver",
  "tours.onboarding.content.1": "Aquí puedes crear los productos y servicios que vendes en tu negocio.",
  "tours.onboarding.content.1.clothing.stores": "Esta opción te va a permitir crear los productos que vendes en tu tienda.",
  "tours.onboarding.content.2": "Añade tus clientes o utiliza el consumidor final que viene creado por defecto.",
  "tours.onboarding.content.3": "Puedes seleccionarlos con un clic sobre los recuadros que visualizas al lado izquierdo.",
  "tours.onboarding.content.4": "Con esta opción creas tu factura, eliges el método de pago e imprimes tu recibo.",
  "tours.onboarding.title.1": "¡Tus productos con un clic!",
  "tours.onboarding.title.1.clothing.stores": "¡Tus productos con un clic! 👖👚",
  "tours.onboarding.title.2": "Crea tus contactos. 👥",
  "tours.onboarding.title.2.clothing.stores": "Crea tus clientes 👥",
  "tours.onboarding.title.3": "👈 Elige los productos a vender",
  "tours.onboarding.title.3.clothing.stores": "👈 Elige los productos a vender",
  "tours.onboarding.title.4": "¡Boom, tu primera factura! 🎉",
  "tours.onboarding.title.4.clothing.stores": "¡Ya está lista tu factura! 🎉",
  "township": "Asentamiento",
  "tpaga": "Tpaga",
  "tradeName": "Nombre comercial",
  "traditionalRegime": "Régimen tradicional",
  "transfer": "Transferencia",
  "transferBank": "Banco transferencias",
  "transferSales": "Ventas por transferencias",
  "transportLogistics": "Transporte / Logística",
  "tryNow": "¡Probar ahora!",
  "tutorial": "Tutorial",
  "type": "Tipo",
  "typeAdjustmentNote": "Tipo de nota de ajuste",
  "twoMonths": "2 Meses",
  "ubigeoCode": "Código ubigeo",
  "understood": "Entendido",
  "ungroupData": "Desagrupar datos",
  "unitCost": "Costo inicial por unidad",
  "unitCostHelp": "Indica el valor promedio de adquisición de las unidades iniciales de este producto.",
  "unitCostSimplified": "Costo inicial",
  "units.centimeter": "Centímetro",
  "units.centimeterSquared": "Centímetro cuadrado",
  "units.day": "Día",
  "units.foot": "Pie",
  "units.gallon": "Galón",
  "units.gram": "Gramo",
  "units.hour": "Hora",
  "units.inch": "Pulgada",
  "units.inchSquared": "Pulgada cuadrada",
  "units.kilogram": "Kilogramo",
  "units.kit": "Kit",
  "units.liter": "Litro",
  "units.meter": "Metro",
  "units.meterCubic": "Metro cúbico",
  "units.meterSquared": "Metro cuadrado",
  "units.mililiter": "Mililitro",
  "units.minute": "Minuto",
  "units.notApplicable": "No aplica",
  "units.onza": "Onza",
  "units.piece": "Pieza",
  "units.pound": "Libra",
  "units.second": "Segundo",
  "units.service": "Servicio",
  "units.thousand": "Millar",
  "units.ton": "Tonelada",
  "units.unit": "Unidad",
  "unlimited": "Ilimitadas",
  "unlimitedM": "Ilimitados",
  "unlimitedSellers": "Vendedores ilimitados",
  "update": "actualizar",
  "updateAndCreateNew": "Actualizar y crear nueva",
  "updateAndCreateNewAlt": "Actualizar y crear nuevo",
  "updateCustomField": "Actualizar campo",
  "updateCompanyError": "Error al intentar actualizar los datos de la compañía",
  "updateDefaultValue": "Actualiza el valor por defecto",
  "updateItemCategory": "Actualizar categoría",
  "updateItemCategoryError": "Hubo un error al cambiar los datos de la categoría.",
  "updateItemVariantHelp": "La variante del producto no se puede editar directamente, edite el producto original.",
  "updatePriceList": "Actualizar lista",
  "updatePriceListError": "Hubo un error al cambiar los datos de la lista de precios.",
  "updateInTermsAndConditions": "Actualización de términos y condiciones",
  "updateInTermsAndConditions.description.1": "Se han actualizado los <b>términos y condiciones sobre la facturación electrónica. </b>Para aceptarlos solo debes tener a la mano los datos del represente legal.",
  "updateInTermsAndConditions.description.2": "¡Con Alegra estás siempre al día con la DIAN!",
  "updatePlan": "Actualizar suscripción",
  "updateRequiredSettingsError": "Error al guardar los valores",
  "updateSellerError": "Error al cambiar los datos del vendedor",
  "updateStationError": "Error al cambiar los datos de la terminal",
  "updateVariant": "Actualizar variante",
  "updateVariantError": "Hubo un error al cambiar los datos de la variante.",
  "updateWarehouse": "Actualizar bodega",
  "updateWarehouseError": "Error al cambiar los datos de la bodega",
  "updateYourPlanNotification": "¡Actualiza tu suscripción y no pares tu facturación!🚨",
  "updateYourPlanNotification.description": "Te encuentras en un Plan Consulta y a partir del lunes 18 de julio solo podrás ver la información creada hasta ese día.",
  "urbanization": "Urbanización",
  "useMyLogo": "Utilizar mi logo",
  "usePriceLists": "Utilizar listas de precios",
  "userAssociation": "asociación de usuarios",
  "userAssociationTutorial": "elige los usuarios que tendrán acceso al uso de esta terminal",
  "userNotAllowed.bank-accounts.view": "No tienes permisos para ver la información de las cuentas de bancos",
  "userNotAllowed.categories.add": "No tienes permisos para agregar categorías",
  "userNotAllowed.categories.edit": "No tienes permisos para editar categorías",
  "userNotAllowed.categories.delete": "No tienes permisos para eliminar categorías",
  "userNotAllowed.contacts.add": "No tienes permisos para agregar contactos",
  "userNotAllowed.contacts.view": "No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.",
  "userNotAllowed.company.edit": "No tienes permisos suficientes para editar la información de la compañía",
  "userNotAllowed.company.edit-invoice-template": "No tienes permisos suficientes para editar la información de impresión de tu compañía",
  "userNotAllowed.cost-centers.view": "No tienes permisos suficientes para ver detalles de centros de costo",
  "userNotAllowed.credit-notes.add": "No tienes permisos para agregar devoluciones",
  "userNotAllowed.credit-notes.view": "No tienes permiso para ver detalles de las devoluciones",
  "userNotAllowed.currencies.view": "No tienes permisos para ver las monedas",
  "userNotAllowed.custom-fields.add": "No tienes permisos para agregar campos adicionales",
  "userNotAllowed.custom-fields.edit": "No tienes permisos para editar campos adicionales",
  "userNotAllowed.custom-fields.delete": "No tienes permisos para eliminar campos adicionales",
  "userNotAllowed.inventory-adjustments.view": "No tienes permisos para ver los ajustes de inventario",
  "userNotAllowed.inventory-adjustments.add": "No tienes permisos para agregar ajustes de inventario",
  "userNotAllowed.inventory-adjustments.edit": "No tienes permisos para editar ajustes de inventario",
  "userNotAllowed.inventory-adjustments.delete": "No tienes permisos para eliminar ajustes de inventario",
  "userNotAllowed.invoices.edit-discount": "No tienes permisos para editar el descuento de los productos",
  "userNotAllowed.invoices.edit-items-prices": "No tienes permisos para editar el precio de los productos",
  "userNotAllowed.invoices.edit-items-prices-new": "Necesitas permiso del administrador para editar el precio.",
  "userNotAllowed.invoices.max-discount-allow": "El porcentaje máximo de descuento permitido para tu usuario es",
  "userNotAllowed.invoices.view": "No tienes permisos suficientes para ver detalles de las facturas",
  "userNotAllowed.items.add": "No tienes permisos para agregar productos",
  "userNotAllowed.items.edit": "No tienes permisos suficientes para editar la información del producto",
  "userNotAllowed.items.delete": "No tienes permisos para eliminar productos",
  "userNotAllowed.payments.add": "No tienes permisos para la operación deseada",
  "userNotAllowed.payments.view": "No tienes permisos suficientes para ver detalles de los pagos",
  "userNotAllowed.pos-cashier.close": "No tienes permisos para cerrar turnos",
  "userNotAllowed.pos-cashier.open": "No tienes permisos para abrir turnos",
  "userNotAllowed.pos-cashier.view": "No tienes permiso para ver los cierres de turno anteriores",
  "userNotAllowed.pos-station.edit": "No tienes permisos suficientes para editar la información de la terminal",
  "userNotAllowed.pos-station.index": "No tienes permiso para ver las terminales",
  "userNotAllowed.pos-station.view": "No tienes permisos para ver detalle de terminales",
  "userNotAllowed.price-lists.add": "No tienes permisos para agregar listas de precio",
  "userNotAllowed.price-lists.edit": "No tienes permisos para editar listas de precio",
  "userNotAllowed.price-lists.delete": "No tienes permisos para eliminar listas de precio",
  "userNotAllowed.price-lists.view": "No tienes permisos para ver la información de las listas de precio",
  "userNotAllowed.sellers.add": "No tienes permisos para agregar vendedores",
  "userNotAllowed.sellers.delete": "No tienes permisos para eliminar vendedores",
  "userNotAllowed.sellers.edit": "No tienes permisos para editar vendedores",
  "userNotAllowed.terms.view": "No tienes permisos para ver los plazos de pago",
  "userNotAllowed.variant-attributes.add": "No tienes permisos para agregar variantes",
  "userNotAllowed.variant-attributes.edit": "No tienes permisos para editar variantes",
  "userNotAllowed.variant-attributes.delete": "No tienes permisos para eliminar variantes",
  "userNotAllowed.warehouses.add": "No tienes permisos para agregar bodegas",
  "userNotAllowed.warehouses.delete": "No tienes permisos para eliminar bodegas",
  "userNotAllowed.warehouses.edit": "No tienes permisos para editar bodegas",
  "users": "usuarios",
  "validDateTo": "Valido hasta",
  "value": "Valor",
  "variant": "Variante",
  "variantActivatedSuccessfully": "Variante activada con éxito",
  "variantCreatedSuccessfully": "Ya está lista. 👈",
  "variantCreatedSuccessfullyMessage": "Tu variante {} está disponible para asociarla a tus productos.",
  "variantDeactivatedSuccessfully": "Variante desactivada con éxito",
  "variantDeletedError": "Error eliminando la variante",
  "variantDeletedSuccesfully": "Variante eliminada con éxito",
  "variantStatusError": "Error cambiando el estado de la variante",
  "variantUpdatedSuccessfully": "Variante actualizada con éxito.",
  "variants": "Variantes",
  "variantsInventoryInfo": "Configura atributos que definen las características de tus productos como color, talla u otra cualidad.",
  "variantsNoDataText": "Crea tu primera variante sin pararte de la silla. 🎨",
  "variantsSubtitle": "Agrega atributos como talla, color u otra cualidad para categorizar tus productos.",
  "videoIntroductionPOS": "Video introductorio POS",
  "void": "Anular",
  "voidInvoice": "Anular venta",
  "voidInvoiceCause": "motivo de anulación de venta",
  "voidInvoiceCauseHelp": "esta información es necesaria para generar tus reportes correctamente.",
  "wantToParticipate": "Quiero participar",
  "wantToParticipateOfSecondNoIvaDay": "¿Quieres participar en el próximo Día sin IVA?☝️",
  "wantToParticipateOfSecondNoIvaDay.description": "Ten en cuenta que para ofrecer este beneficio debes tener activa tu facturación electrónica y aquí te ayudamos a hacerlo.",
  "warehouse": "Bodega",
  "warehouses": "Bodegas",
  "warehouseActivated": "bodega activada con éxito",
  "warehouseChangeError": "No tienes los permisos para ver productos de otras bodegas.",
  "warehouseChangeInfo": "Cambia de bodega para visualizar los productos presentes en cada una.",
  "warehouseCreatedSuccessfully": "Que cool.",
  "warehouseCreatedSuccessfullyMessage": "La bodega {} ya está disponible para que distribuyas tu inventario.",
  "warehouseDeactivated": "bodega desactivada con éxito",
  "warehouseDeleted": "bodega eliminada con éxito",
  "warehouseDeletedError": "error eliminando bodega",
  "warehouseQuantity": "Cantidad en Bodega",
  "warehouseSetting": "Configuración de bodega",
  "warehousesLoadError": "Sucedió un error cargando las bodegas",
  "warehousesSettingSubtitle": "Administra las bodegas de tu negocio",
  "warehousesInventorySubtitle": "Crea bodegas para gestionar tu inventario en diferentes lugares de almacenamiento y distribución.",
  "warehouseModalSubtitle": "Crea todos los puntos de almacenamiento y distribución de tus productos.",
  "warehouseNoDataText": "Crea tu primera bodega en segundos. 🙌",
  "warehouseStatusError": "error cambiando estado de la bodega",
  "warehouseUpdated": "bodega actualizada con éxito",
  "warehouse_name": "Bodega",
  "website": "Sitio web",
  "week": "Semana",
  "welcomeToAlegraPOS": "¡Bienvenido a Alegra POS!",
  "welcomeToYourPOSSystem": "¡Bienvenido a tu sistema POS!",
  "wellCovered": "Bien cubierto",
  "whatYouDo": "A qué te dedicas",
  "without": "Sin",
  "wompi": "wompi",
  "writeToConfirm": "Confirma escribiendo la palabra",
  "writeWhatYouDo": "Escribe a qué te dedicas",
  "xmlDocument": "Documento XML",
  "yearly": "Anual",
  "yearlyPlanButton": "Anual con 10% dcto",
  "yearlyTotal": "Total anual",
  "yes": "Si",
  "yesterday": "Ayer",
  "youCanConfigureYourEconomicActivities": "¡Configura todas tus actividades económicas! ⚡",
  "youCanConfigureYourEconomicActivities.description": "Marca como preferida la que más utilizas y cámbiala si es necesario al momento de vender desde tu Punto de venta.",
  "youCanNowMakeElectronicInvoices": "¡Habilítate para emitir Facturas Electrónicas! 💥",
  "youCanNowMakeElectronicInvoices.description": "Actívate ante la DIAN **en menos de 10 minutos* y empieza a crear facturas electrónicas en tu punto de venta.",
  "youCanSeeIt": "Puedes verla",
  "youDontHaveCreatedItems": "No has creado tu primer producto, créalo aquí.",
  "youCreated": "Ya creaste",
  "youDontHaveCreatedInventoryAdjustments.title": "¡Tu primer ajuste en segundos! ⚖",
  "youDontHaveCreatedInventoryAdjustments.subtitle": "Mantén actualizada la cantidad de tus productos",
  "youDontHaveCreatedSellers": "No has creado tu primer vendedor, créalo aquí.",
  "youDontHaveCreatedStations": "No has creado tu primera terminal, créala aquí",
  "youDontHaveCreatedWarehouses": "No has creado tu primera bodega, créala aquí.",
  "youHave": "Tienes",
  "youHaveA15DayFreeTrial": "Tienes {} {} de prueba gratis para descubrir lo fácil que es gestionar tu negocio.",
  "youHaveOfflineData": "Tienes datos pendientes por sincronizarse.",
  "youMustChooseAMethod": "Debes elegir un método de devolución",
  "youMustEditThisItem": "Debes editar este ítem",
  "youMustSynchronizeBeforeCloseShift": "Debes sincronizar tu información antes de poder cerrar turno",
  "youMustIndicateTheNameOfYourClient": "Debes indicar el nombre de tu cliente",
  "youNeedPermissionsToViewList": "Necesitas permiso para ver el listado",
  "youMustIncludeAtLeastOneLastName": "Debe incluir al menos un apellido",
  "youNeedToAddDiscountNature": "Necesitas indicar la naturaleza del descuento",
  "youNeedToAddThePaymentValue": "Necesitas adicionar el valor del pago",
  "youNeedToAskForPermissions.view-numerations": "Solicita el permiso a un administrador para ver el listado de numeraciones.",
  "youNeedToConfigYourCompanyEmail": "Primero necesitas configurar el correo electrónico de tu negocio. Luego reintenta",
  "yourBusinessData": "Datos de tu negocio",
  "yourBusinessData.subtitle": "Actualiza la información de tu negocio que aparecerá en tus facturas",
  "yourNumerationIsAboutToExpire": "¡Tu numeración está por vencer! 📢",
  "yourNumerationIsAboutToExpire.description": "La numeración {} vencerá pronto. Te recomendamos revisarla y solicitar una nueva en la DIAN.",
  "yourNumerationIsAlmostOver": "¡Ya casi se te acaban los consecutivos!⚠️",
  "yourNumerationIsAlmostOver.description": "Dispones de pocos documentos en tu numeración {}. Pide una nueva a la DIAN y configúrala en segundos.",
  "yourNumerationIsExpired": "¡Tu numeración se encuentra vencida! 🚨",
  "yourNumerationIsExpired.description": "La numeración {} está vencida. Solicita una nueva a la DIAN y configúrala para seguir facturando.",
  "yourNumerationIsOver": "¡Se acabaron los consecutivos de tu numeración! 🚨",
  "yourNumerationIsOver.description": "La numeración {} se quedó sin consecutivos. Solicita una nueva a la DIAN para seguir facturando",
  "youReceived": "Recibiste {}",
  "youRegistered": "Has registrado",
  "yourInvoiceIsBeingAuthorizedByTheDGI": "Tu factura está siendo autorizada por la DGI y en un momento podrás imprimirla desde la sección de ventas.",
  "yourPOSSystem": "tu sistema POS",
  "youShouldSelectANumeration": "Debes seleccionar una numeración, solicita a un administrador el permiso para ver el listado.",
  "zipCode": "Código postal",
  "reload": "Recargar",
  "connectionIssueTitle": "Trabajando sin internet",
  "connectionIssueDescription": "Podrás consultar tus datos al recuperar la conexión.",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Crea tus facturas en segundos",
  "AlegraAccounting.new": "Gestión",
  "AlegraAccounting.description.new": "Factura, administra y gestiona tu stock",
  "AlegraPOS.new": "POS",
  "AlegraPOS.description.new": "Agiliza tus ventas y controla tu efectivo",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "helpedArticle": "Leer artículo de ayuda",
  "electronicPayrollHabilitation": "Conoce cómo habilitarte para emitir facturas electrónicas.",
  "deactivated": "Desactivada",
  "activated": "Activada",
  "electronicInvoicing": "Facturación electrónica",
  "avoidForNow": "Omitir por ahora",
  "howToEnableMe": "Cómo habilitarme",
  "lastSynchronizeData": "Última sincronización",
  "help": "Ayuda",
  "terminal_summary": "Resumen de terminal",
  "synchronization": "Sincronización",
  "new_design_tittle": "¡Nuevo diseño en tu punto de venta! 🖌️",
  "maxLengthError": "Máximo {{maxCharacters}} caracteres",
  "new_design_description": "Ahora tu menú tiene una nueva imagen y color para que puedas identificar más fácil cada sección.",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Gestión para que los subas de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Gestión controlas tus ingresos y gastos con un clic, y obtienes reportes administrativos y de ventas en tiempo real.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Factura en segundos con el Sistema Punto de Venta más veloz del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "maxReferenceCharactersExceeded": "Código de máximo {} caracteres que te servirá para identificar tus productos.",
  "clientPortal": "Portal de clientes",
  "userNotAllowed.edit.saleTicket": "Necesitas permiso del administrador para editar tickets.",
  "userNotAllowed.edit.invoice": "Necesitas permiso del administrador para editar facturas.",
  "simplifiedInvoice": "Factura Simplificada",
  "ordinaryInvoice": "Factura ordinaria",
  "theDNIhasEightNumbersAndOneDigitVerificationCode": "Debe tener ocho números y una letra.",
  "mustHaveALetterAndSevenNumbersAndOneVerificationCode": "Debe tener una letra, siete números y un dígito de control.",
  "thePostalCodeMustHaveFiveNumbers": "Debe tener cinco números.",
  "writeThreeLettersToSearch": "Escribe al menos tres caracteres para obtener resultados.",
  "noResultsTryAgain": "Búsqueda sin resultados, intenta escribirlo de nuevo",
};

export default dictionary;
