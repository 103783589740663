import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

import { regimes } from '../../../countriesData/colombia/regimes';
import { renderSelect } from '../../fields/V0/Fields';

const regimeOptions = props => regimes
  .filter(regime => props.isElectronic 
    ? regime.electronicInvoicingVersion === "2.1" 
    : regime.electronicInvoicingVersion === "2.0"
  )

const renderTaxLiability = props => {
  const identification = get(props, 'values.identification.type.key', '');

  return props.country === 'colombia' && identification === 'NIT'
    ? (
      <Field
        name="regime"
        className="col-md-6"
        component={renderSelect}
        label={capitalize(I18n.get('taxLiability', 'responsabilidad tributaria'))}
        options={regimeOptions(props)}
        getOptionLabel={option => option.display}
        getOptionValue={option => option.key}
        required={props.isElectronic}
      />
    ) : null;
}

export default renderTaxLiability;
