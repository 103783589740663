// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5303 2.4697C1.2374 2.1768.7626 2.1768.4697 2.4697.1768 2.7626.1768 3.2374.4697 3.5303L3.4697 6.5303C3.7626 6.8232 4.2374 6.8232 4.5303 6.5303L7.5303 3.5303C7.8232 3.2374 7.8232 2.7626 7.5303 2.4697 7.2374 2.1768 6.7626 2.1768 6.4697 2.4697L4 4.9393 1.5303 2.4697Z"
      fill="#0F172A" />
  </svg>
)