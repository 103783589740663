// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5303 5.4697C4.2374 5.1768 3.7626 5.1768 3.4697 5.4697L.4697 8.4697C.1768 8.7626.1768 9.2374.4697 9.5303.7626 9.8232 1.2374 9.8232 1.5303 9.5303L4 7.0607 6.4697 9.5303C6.7626 9.8232 7.2374 9.8232 7.5303 9.5303 7.8232 9.2374 7.8232 8.7626 7.5303 8.4697L4.5303 5.4697Z"
      fill="#0F172A" />
  </svg>
)