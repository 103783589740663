import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

export const validate = (values) => {
  let errors = {};

  const email = get(values, 'email', '');

  if (!email)
    errors.email = capitalize(I18n.get('requiredField', 'campo requerido'));

  if (!!email && !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email))
    errors.email = capitalize(I18n.get('enterAValidEmail', 'Ingresa un correo válido'));
  
  return errors;
}