import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useConnectionStatus } from '../../hooks/useConnectionStatus';
import { fetchRefunds } from '../../reducers/refunds'
import { hasPermissionTo } from '../../selectors/auth'
import { country as countrySelector } from '../../selectors/company';
import { refunds, loadingSelector, errorSelector, totalSelector } from '../../selectors/refunds'
import MasterDetail from '../common/DetailView';
import Refund from './Refund';
import DetailHeader from './DetailHeader';
import DetailBody from './DetailBody';
import NewDetailHeader from './new/DetailHeader';
import NewDetailBody from './new/DetailBody';
import EmptyDetail from './EmptyDetail';
import ErrorElement from './ErrorElement';
import NoSelectedElement from './NoSelectedElement';
import PaginationHeader from './PaginationHeader';
import NotAllowedToView from './NotAllowedToView'
import NotAllowedToIndex from './NotAllowedToIndex'
import PageNetworkMessage from '../network/PageNetworkMessage';

const Refunds = () => {
  const connectionStatus = useConnectionStatus();
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const ref = useRef(null)
  const [selected, setSelected] = useState(null);
  const [metadata, setMetadata] = useState({
    start: 0,
    limit: 20,
    sortDirection: 'desc'
  })

  const country = useSelector(countrySelector);
  const elements = useSelector(refunds);
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector)
  const total = useSelector(totalSelector)
  const selectedRefund = selected === -1 ? null : elements[selected]

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/refunds',
      'virtualPageTitle': 'Refunds'
    });

    return () => ref.current = false
  }, []);

  const fetchData = useCallback(async batch => {
    if (can('view', 'credit-notes') && can('index', 'credit-notes'))
      await dispatch(fetchRefunds(batch))

    if (ref.current)
      setMetadata(batch)
  }, [dispatch, can])

  useEffect(() => {
    fetchData({
      start: 0,
      limit: 20,
      sortDirection: 'desc'
    });
  }, [fetchData]);

  if (!connectionStatus) {
    return <PageNetworkMessage />
  }

  return (
    <div className="refunds d-flex">
      <MasterDetail
        loading={loading}
        error={error}
        selected={!!selectedRefund}
        options={elements.map((option, index) => (
          <Refund
            key={option.id}
            refund={option}
            idSelected={!!selectedRefund ? selectedRefund.id : null}
            onSelect={() => setSelected(index)}
          />
        ))}
        onFetchData={fetchData}
        paginationData={{ ...metadata, total }}
        paginationHeader={<PaginationHeader />}
        detailHeader={
          ["colombia"].includes(country)
            ? <NewDetailHeader refund={selectedRefund} onListView={() => setSelected(-1)} />
            : <DetailHeader refund={selectedRefund} onListView={() => setSelected(-1)} />}
        detailChildren={
          ["colombia"].includes(country)
            ? <NewDetailBody refund={selectedRefund} />
            : <DetailBody refund={selectedRefund} />}
        detailClassName={!!selectedRefund ? 'item-selected' : ''}
        emptyElement={<EmptyDetail />}
        errorElement={<ErrorElement onRefresh={() => fetchData(metadata)} />}
        noSelectedElement={<NoSelectedElement />}
        notAllowedToIndex={!can('view', 'credit-notes')
          ? <NotAllowedToIndex /> : null}
        notAllowedToView={!can('view', 'credit-notes') || !can('index', 'credit-notes')
          ? <NotAllowedToView /> : null}
      />
    </div>
  )
}

export default Refunds;