// eslint-disable-next-line
export default ({ id, className, width, height, fill }) => (
  <svg
    id={id}
    className={className}
    width={width ?? "13"}
    height={height ?? "14"}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1867 0.646447C9.99146 0.451184 9.67488 0.451184 9.47962 0.646447C9.28435 0.841709 9.28435 1.15829 9.47962 1.35355L10.6261 2.5H3.1665C2.50346 2.5 1.86758 2.76339 1.39874 3.23223C0.929896 3.70107 0.666504 4.33696 0.666504 5V7C0.666504 7.27614 0.890362 7.5 1.1665 7.5C1.44265 7.5 1.6665 7.27614 1.6665 7V5C1.6665 4.60218 1.82454 4.22064 2.10584 3.93934C2.38715 3.65804 2.76868 3.5 3.1665 3.5H10.6261L9.47962 4.64645C9.28435 4.84171 9.28435 5.15829 9.47962 5.35355C9.67488 5.54882 9.99146 5.54882 10.1867 5.35355L12.1867 3.35355C12.382 3.15829 12.382 2.84171 12.1867 2.64645L10.1867 0.646447Z"
      fill={fill ?? "#0F172A"} />
    <path d="M12.3332 9V7C12.3332 6.72386 12.1093 6.5 11.8332 6.5C11.557 6.5 11.3332 6.72386 11.3332 7V9C11.3332 9.39783 11.1751 9.77936 10.8938 10.0607C10.6125 10.342 10.231 10.5 9.83317 10.5H2.37361L3.52006 9.35355C3.71532 9.15829 3.71532 8.84171 3.52006 8.64645C3.3248 8.45118 3.00821 8.45118 2.81295 8.64645L0.812951 10.6464C0.617688 10.8417 0.617688 11.1583 0.812951 11.3536L2.81295 13.3536C3.00821 13.5488 3.3248 13.5488 3.52006 13.3536C3.71532 13.1583 3.71532 12.8417 3.52006 12.6464L2.37361 11.5H9.83317C10.4962 11.5 11.1321 11.2366 11.6009 10.7678C12.0698 10.2989 12.3332 9.66304 12.3332 9Z"
      fill={fill ?? "#0F172A"} />
  </svg>
)