// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.99984 2.1665C9.72369 2.1665 9.49984 2.39036 9.49984 2.6665C9.49984 2.94265 9.72369 3.1665 9.99984 3.1665H12.1261L6.31295 8.97962C6.11769 9.17488 6.11769 9.49146 6.31295 9.68672C6.50821 9.88199 6.82479 9.88199 7.02006 9.68672L12.8332 3.87361V5.99984C12.8332 6.27598 13.057 6.49984 13.3332 6.49984C13.6093 6.49984 13.8332 6.27598 13.8332 5.99984V2.6665C13.8332 2.53854 13.7844 2.41058 13.6867 2.31295C13.6388 2.26501 13.5835 2.22884 13.5246 2.20444C13.4656 2.18 13.401 2.1665 13.3332 2.1665H9.99984Z" fill="#0F172A"/>
    <path d="M2.70347 4.70347C3.04729 4.35966 3.51361 4.1665 3.99984 4.1665H7.33317C7.60931 4.1665 7.83317 4.39036 7.83317 4.6665C7.83317 4.94265 7.60931 5.1665 7.33317 5.1665H3.99984C3.77882 5.1665 3.56686 5.2543 3.41058 5.41058C3.2543 5.56686 3.1665 5.77882 3.1665 5.99984V11.9998C3.1665 12.2209 3.2543 12.4328 3.41058 12.5891C3.56686 12.7454 3.77882 12.8332 3.99984 12.8332H9.99984C10.2209 12.8332 10.4328 12.7454 10.5891 12.5891C10.7454 12.4328 10.8332 12.2209 10.8332 11.9998V8.6665C10.8332 8.39036 11.057 8.1665 11.3332 8.1665C11.6093 8.1665 11.8332 8.39036 11.8332 8.6665V11.9998C11.8332 12.4861 11.64 12.9524 11.2962 13.2962C10.9524 13.64 10.4861 13.8332 9.99984 13.8332H3.99984C3.51361 13.8332 3.04729 13.64 2.70347 13.2962C2.35966 12.9524 2.1665 12.4861 2.1665 11.9998V5.99984C2.1665 5.51361 2.35966 5.04729 2.70347 4.70347Z" fill="#0F172A"/>
  </svg>
)