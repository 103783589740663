export const COUNTRIES = {
  COLOMBIA: 'colombia',
  PERU: 'peru',
  CHILE: 'chile',
  SPAIN: 'spain',
  MEXICO: 'mexico',
  COSTA_RICA: 'costaRica',
  REPUBLICA_DOMINICANA: 'republicaDominicana',
  PANAMA: 'panama',
  ARGENTINA: 'argentina',
  BOLIVIA: 'bolivia',
  USA: 'usa',
  INTERNATIONAL: 'other',
};
