import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as queries from '../graphql/queries'
import { handleError } from '../utils/errors'
import { APIGraphqlSelector } from '../selectors/app';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchUsers = createAsyncThunk(
  'users/fetch',
  async (params, { rejectWithValue, getState }) => {
    try {
      const APIGraphql = APIGraphqlSelector(getState());
      const data = await APIGraphql(graphqlOperation(queries.allUsers, {
        ...params
      }));
      return get(data, 'data.allUsers', null);
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer } = appSlice;

export const usersSelector = adapter.getSelectors(state => state.users);

export default reducer;