const identificationTypes = [
  {
    id:"01",
    value:"Cédula Física",
    abbreviature:"Cédula Física",
    key:"CF",
    rules: {
      minLength:9,
      maxLength:9,
      regex: {
        regex:"^[1-9]+[0-9]*$",
        invalidRegexText:"La cédula física debe ser un valor numérico, sin ceros al inicio y sin guiones"
         }
    }
  },
  {
    id:"02",
    value:"Cédula Jurídica",
    abbreviature:"Cédula Jurídica",
    key:"CJ",
    rules: {
      minLength:10,
      maxLength:10,
      regex: {
        regex:"^[^-]+$",
        invalidRegexText:"La cédula jurídica debe estar sin guiones"
         }
    }
  },
  {
    id:"03",
    value:"DIMEX",
    abbreviature:"DIMEX",
    key:"DIMEX",
    rules: {
      minLength:11,
      maxLength:12,
      regex: {
        regex:"^[1-9]+[0-9]*$",
        invalidRegexText:"El DIMEX es un valor numérico, sin ceros al inicio y sin guiones"
         }
    }
  },
  {
    id:"04",
    value:"NITE",
    abbreviature:"NITE",
    key:"NITE",
    rules: {
      minLength:10,
      maxLength:10,
      regex: {
        regex:"^[^-]+$",
        invalidRegexText:"El NITE debe estar sin guiones"
         }
    }
  },
  {
    id:"00",
    value:"Identificación extranjero",
    abbreviature:"Identificación extranjero",
    key:"PE",
    rules: {
      maxLength:20
      }
  }
]

export default identificationTypes