import React, { useRef } from 'react'
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { get } from 'lodash';

import Ticket from '../../svg/Ticket'

const Image = props => {
  const imageInputRef = useRef()

  const loadImage = (e, fieldProps, props) => {

    let file = e.target.files[0], reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ev => {
      props.form.change('imageSrc', ev.target.result)
    }
    fieldProps.input.onChange(e)
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Field
        name={props.name || 'name'}
      >
        {(fieldProps) => (
          <div className="image-container">
            {!!get(props, 'values.imageSrc')
              ? (
                <>
                  <span className="w-100 h-100 image-help-text position-absolute"
                    title={I18n.get('imageSelector', 'Selector de imagen')}
                    onClick={() => {
                      imageInputRef.current.click()
                    }}>
                    {I18n.get(('changeItemImage.' + get(props, 'values.type', 'product')), 'Cambiar imagen del producto')}
                  </span>
                  <img
                    src={get(props, 'values.imageSrc')}
                    alt={I18n.get('itemImage', 'imagen de producto')}
                    className={`w-100 h-100 d-block ${!!get(props, 'values.id') ? "" : "pointer"}`}
                  />
                </>
              )
              : (
                <div
                  onClick={() => imageInputRef.current.click()}
                  className={`d-flex w-100 h-100 flex-column justify-content-center align-items-center pointer`}
                >
                  <Ticket className="icon-secondary icon x4 mb-2 pointer" />
                  <p className="text-capitalize-first text-secondary h5 pointer">
                    {I18n.get(('itemImage.' + get(props, 'values.type', 'product')), 'imagen de producto')}
                  </p>
                </div>

              )
            }

            <input
              {...fieldProps.input}
              type="file"
              onChange={(e) => loadImage(e, fieldProps, props)}
              ref={imageInputRef}
              className="w-100 h-100 d-none"
              accept='.jpg, .png, .jpeg'
            />
          </div>
        )}
      </Field>

    </div>
  )
}

export default Image;