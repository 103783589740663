import { decimalsVersionsGroup } from '../../selectors/deployGroups';

export const MAX_ALLOWED_DECIMALS = 6;

export function maxDecimals({
  e,
  key,
  decimals = MAX_ALLOWED_DECIMALS,
  country,
  values = {},
}) {
  if (!decimalsVersionsGroup.includes(country)) return e.target.value;

  const newButton = e.nativeEvent.data;
  const targetValue = e.target.value || '';
  const currentValue = values[key] || '';

  if (newButton === '.' && !targetValue.slice(0, -1).includes('.')) {
    if(!targetValue) return currentValue
    return targetValue
  }

  if (
    !isNaN(newButton) &&
    (!targetValue.includes('.') ||
      (targetValue.includes('.') &&
        targetValue.split('.')[1].length <= decimals))
  ) {
    return targetValue;
  }

  e.target.value = currentValue;
  return currentValue;
}
