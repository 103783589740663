import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { requiredFields } from '../../../selectors/customFields'
import Carret from '../../svg/CarretDownOutline';
import { renderDynamicField } from '../fields/V0/Fields';

const CustomField = () => {
  const [open, setOpen] = useState(true)
  const adicionalFields = useSelector(requiredFields);

  if (!get(adicionalFields, 'length')) return null

  return (
    <div className="mt-2 col-12">
      <p
        className="h4 d-flex align-items-center pointer"
        style={{ width: 'fit-content', userSelect: 'none' }}
        onClick={() => setOpen(prev => !prev)}
      >
        <span className="badge bg-warning text-white mr-2">
          {adicionalFields.length}
        </span>
        {I18n.get('requiredCustomFields', 'Campos adicionales obligatorios')}
        <Carret className={`icon-gray accordion-carret accordion-carret-${open ? 'up' : 'down'}`} />
      </p>

      <p className="h6 text-subtitle">
        {I18n.get('requiredCustomFieldsHelp', 'Completa tus campos obligatorios antes de crear tu producto')}
      </p>

      <div
        className="form-row overflow-hidden accordion-body"
        style={{
          opacity: open ? '1' : '0',
          maxHeight: open ? '100%' : '0px'
        }}
      >
        {adicionalFields.map((field, index) => {
          return (
            <Field
              key={index}
              name={`customFields.cf${get(field, 'id')}`}
              label={get(field, 'name')}
              fieldType={get(field, 'type')}
              options={get(field, 'options')}
              component={renderDynamicField}
              defaultValue={get(field, 'defaultValue')}
              className="col-6"
              portalId={"item-modal-portal"}
              required
            />
          )
        })}
      </div>
    </div>
  )
}

export default CustomField;
