import alegraAPI from "../../../reducers/alegraAPI";
import { useQuery } from "@tanstack/react-query";

const itemCategoriesKeys = {
  all: 'itemCategories',
  single: 'itemCategory'
}

const fetchItemCategories = async (filters) => {
  const response = await alegraAPI.get("/item-categories", {
    ...filters,
  });

  return response.data;
}

const fetchSingleItemCategory = async (id) => {
  const response = await alegraAPI.get(`/item-categories/${id}`);

  return response.data;
}

export const useItemCategoriesQuery = (filters) => {
  return useQuery({
    queryKey: [itemCategoriesKeys.all, filters ?? {}],
    queryFn: () => fetchItemCategories(filters ?? {}),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
};

export const useSingleItemCategoryQuery = (id) => {
  return useQuery({
    queryKey: [itemCategoriesKeys.single, { id }],
    queryFn: () => fetchSingleItemCategory(id),
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnReconnect: "always",
  })
};