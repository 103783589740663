// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0246 2.5716C10.6894 2.52441 10.3469 2.5 9.9987 2.5C5.97162 2.5 2.70703 5.76459 2.70703 9.79167C2.70703 13.8187 5.97162 17.0833 9.9987 17.0833C14.0258 17.0833 17.2904 13.8187 17.2904 9.79167C17.2904 9.21106 17.2225 8.64631 17.0943 8.10491L16.014 9.22357C16.0314 9.41059 16.0404 9.60009 16.0404 9.79167C16.0404 13.1284 13.3354 15.8333 9.9987 15.8333C6.66198 15.8333 3.95703 13.1284 3.95703 9.79167C3.95703 6.49273 6.60107 3.81133 9.8856 3.75104L11.0246 2.5716ZM9.5766 11.4602L13.8375 7.19924C14.0816 6.95516 14.0816 6.55943 13.8375 6.31536C13.5935 6.07128 13.1977 6.07128 12.9536 6.31536L8.69271 10.5763L8.40512 8.4673L7.17426 8.26459L7.73995 12.4129L11.8883 12.9786L11.6856 11.7478L9.5766 11.4602Z" fill="#47536B" />
  </svg>
);