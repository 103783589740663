// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M11.7758 5.4138H11.1293V4.1207C11.1293 2.33622 9.68102 0.887939 7.89653 0.887939C6.11205 0.887939 4.66378 2.33622 4.66378 4.1207V5.4138H4.01722C3.30602 5.4138 2.72412 5.9957 2.72412 6.7069V13.1724C2.72412 13.8836 3.30602 14.4655 4.01722 14.4655H11.7758C12.4871 14.4655 13.0689 13.8836 13.0689 13.1724V6.7069C13.0689 5.9957 12.4871 5.4138 11.7758 5.4138ZM5.95688 4.1207C5.95688 3.04742 6.82326 2.18104 7.89653 2.18104C8.96981 2.18104 9.83619 3.04742 9.83619 4.1207V5.4138H5.95688V4.1207ZM11.7758 13.1724H4.01722V6.7069H11.7758V13.1724ZM7.89653 11.2328C8.60774 11.2328 9.18964 10.6509 9.18964 9.93966C9.18964 9.22846 8.60774 8.64656 7.89653 8.64656C7.18533 8.64656 6.60343 9.22846 6.60343 9.93966C6.60343 10.6509 7.18533 11.2328 7.89653 11.2328Z"
    />
  </svg>
)
