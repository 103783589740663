// eslint-disable-next-line
export default ({ id, className, width, height }) => (
  <svg
    id={id}
    className={className}
    width={width ?? "16"}
    height={height ?? "16"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6866 4.31295C13.8818 4.50821 13.8818 4.8248 13.6866 5.02006L7.01989 11.6867C6.82463 11.882 6.50805 11.882 6.31279 11.6867L2.97945 8.35339C2.78419 8.15813 2.78419 7.84155 2.97945 7.64628C3.17472 7.45102 3.4913 7.45102 3.68656 7.64628L6.66634 10.6261L12.9795 4.31295C13.1747 4.11769 13.4913 4.11769 13.6866 4.31295Z"
      fill="#15803D" />
  </svg>
)