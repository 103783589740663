import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { isString, get, debounce } from 'lodash'

import { openSideModal } from '../../../reducers/sideModals';
import { filter } from '../../../reducers/clients';
import { setClient } from '../../../reducers/activeInvoice';
import { sendGTMEvent } from '../../../reducers/company';
import { hasPermissionTo } from '../../../selectors/auth'
import { country as countrySelector } from '../../../selectors/company';
import { invoiceClients } from '../../../selectors/clients';
import { client as clientSelector, numeration as numerationSelector } from '../../../selectors/activeInvoice';
import { stationInvoiceNumeration } from '../../../selectors/app';
import { basicPOSClientData, isDefaultClient  } from '../../../utils';

import Tooltip from '../../common/Tooltip';
import Select from '../../common/Select';
import AddUserSVG from '../../svg/AddUser';

const renderClientName = client => {
  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name')))
      return get(client, 'name')
    return `${get(client, 'name.firstName', '')}${!!get(client, 'name.secondName', null)
      ? ' ' + get(client, 'name.secondName') : ''}${!!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName') : ''}`;
  }
  if (!!get(client, 'nameObject')) {
    let nameObject = get(client, 'nameObject');
    return `${get(nameObject, 'firstName', '')}${!!get(nameObject, 'secondName', null)
      ? ' ' + get(nameObject, 'secondName') : ''}${!!get(nameObject, 'lastName', null)
        ? ' ' + get(nameObject, 'lastName') : ''}`;
  }
  return ''
}

const renderClientIdentification = (client, country) => {
  if (!client || isDefaultClient(client, country)) return ''

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`
    if (!!get(client, 'identification.number'))
      return ` (${get(client, 'identification.number')})`
    return ''
  }
  if(!!get(client, 'identificationObject')) {
    if (!!isString(get(client, 'identificationObject.number')))
      return ` (${get(client, 'identificationObject.number')})`
  }
  return ''
}

const Client = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const clients = useSelector(invoiceClients);
  const client = useSelector(clientSelector);
  const numeration = useSelector(numerationSelector);
  const stationNumeration = useSelector(stationInvoiceNumeration);

  const ref = useRef()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ref.current = true
    return () => ref.current = false
  }, [])

  const domClientNumerationCondition = country === 'republicaDominicana'
    && (!!numeration ? get(numeration, 'prefix') === 'E31' : get(stationNumeration, 'prefix') === 'E31')
    && !client
  
  const spainClientCodition =
    country === 'spain' &&
    !!numeration &&
    get(numeration, 'documentType') === 'invoice' &&
    !client;

  const search = useCallback(async (text = '') => {
    if (ref.current)
      setLoading(true)

    await dispatch(filter({ text, limit: 35 }))

    if (ref.current)
      setLoading(false)
  }, [dispatch])

  const getClient = () => {
    if (client)
      return client;
    return basicPOSClientData(country, !!numeration ? numeration : stationNumeration);
  }

  const openCreateClientSideModal = () => {
    try {
      dispatch(openSideModal({ sideModal: 'contact' }));
      dispatch(sendGTMEvent("client-creation-attempted", {
        creationStatus: "success"
      }));
    } catch {
      dispatch(sendGTMEvent("client-creation-attempted", {
        creationStatus: "failure"
      }));
    }
  }

  return (
    <div className="invoice-top__client p-3 d-flex w-100 align-items-center">
      <p className="invoice-top__client-title fast-option-title m-auto pl-3 pr-2">{I18n.get('client', 'Cliente')}:</p>
      <div style={{ marginRight: ".9rem" }} className="w-100 pl-2">
        <Tooltip
          tooltipActive={domClientNumerationCondition || spainClientCodition}
          overlay="Es necesario asignar un contacto en la factura"
          placement="top">
          <Select
            value={getClient()}
            options={can('view', 'contacts') ? clients : []}
            isLoading={loading}
            placeholder={I18n.get('selectAClient', 'selecciona un cliente')}
            noOptionsMessage={() => can('view', 'contacts')
              ? clients.length === 0 ? I18n.get('createClientToSelect', 'Crea un nuevo cliente para seleccionarlo') : I18n.get('createThisNewClientToSelect', 'Crea este nuevo cliente para seleccionarlo')
              : I18n.get('userNotAllowed.contacts.view', 'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.')
            }
            getOptionLabel={option =>
              `${renderClientName(option)}${renderClientIdentification(option, country)}`
            }
            getOptionValue={option => option.id}
            onChange={client => dispatch(setClient(client))}
            isClearable={country !== 'mexico'}
            isDisabled={country === 'mexico'}
            showClearIndicator={!!client}
            hideDropdownIndicator={!!client}
            onInputChange={useMemo(() =>
              debounce((value) => {
                if (can('view', 'contacts'))
                  search(value)
              }, 350), [can, search]
            )}
            cacheOptions={false}
            className={domClientNumerationCondition || spainClientCodition ? "invoice-top__client-selector border border-danger rounded" : ""}
          />
        </Tooltip>
      </div>
      {country !== "mexico" &&
        <Tooltip
          tooltipActive={!can('add', 'contacts')}
          overlay={(
            <p className="text-capitalize-first">
              {I18n.get('userNotAllowed.contacts.add', 'no tienes permisos para agregar contactos')}
            </p>
          )}
        >
          <button
            id="add-client-button"
            type="button"
            disabled={!can('add', 'contacts')}
            className="btn button-transparent d-flex justify-content-between align-items-center btn-add-contact mr-3"
            onClick={openCreateClientSideModal}
          >
            <AddUserSVG className="icon-primary" />
            <div className="text-capitalize-first ml-2">{I18n.get('new', 'nuevo')}</div>
          </button>
        </Tooltip>
      }

    </div>
  )
}

export default Client;