// eslint-disable-next-line
export default ({ id, className, width, height, fill }) => (
  <svg
    id={id}
    className={className}
    width={width ?? "19"}
    height={height ?? "20"}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 8.25024C7.91421 8.25024 8.25 8.58603 8.25 9.00024V15.0002C8.25 15.4145 7.91421 15.7502 7.5 15.7502C7.08579 15.7502 6.75 15.4145 6.75 15.0002V9.00024C6.75 8.58603 7.08579 8.25024 7.5 8.25024Z"
      fill={fill ?? "#94A3B8"} />
    <path d="M12.25 9.00024C12.25 8.58603 11.9142 8.25024 11.5 8.25024C11.0858 8.25024 10.75 8.58603 10.75 9.00024V15.0002C10.75 15.4145 11.0858 15.7502 11.5 15.7502C11.9142 15.7502 12.25 15.4145 12.25 15.0002V9.00024Z"
      fill={fill ?? "#94A3B8"} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 4.25024V2.00024C5.75 1.53611 5.93437 1.091 6.26256 0.762807C6.59075 0.434619 7.03587 0.250244 7.5 0.250244H11.5C11.9641 0.250244 12.4092 0.434619 12.7374 0.762807C13.0656 1.091 13.25 1.53611 13.25 2.00024V4.25024H17.5C17.9142 4.25024 18.25 4.58603 18.25 5.00024C18.25 5.41446 17.9142 5.75024 17.5 5.75024H17.1901L16.2498 17.0339C16.241 17.7511 15.9523 18.437 15.4445 18.9448C14.9288 19.4605 14.2293 19.7502 13.5 19.7502H5.5C4.77065 19.7502 4.07118 19.4605 3.55546 18.9448C3.04767 18.437 2.75898 17.7511 2.75021 17.0339L1.8099 5.75024H1.5C1.08579 5.75024 0.75 5.41446 0.75 5.00024C0.75 4.58603 1.08579 4.25024 1.5 4.25024H5.75ZM7.32322 1.82347C7.37011 1.77658 7.4337 1.75024 7.5 1.75024H11.5C11.5663 1.75024 11.6299 1.77658 11.6768 1.82347C11.7237 1.87035 11.75 1.93394 11.75 2.00024V4.25024H7.25V2.00024C7.25 1.93394 7.27634 1.87035 7.32322 1.82347ZM3.3151 5.75024L4.24741 16.938C4.24914 16.9587 4.25 16.9795 4.25 17.0002C4.25 17.3318 4.3817 17.6497 4.61612 17.8841C4.85054 18.1185 5.16848 18.2502 5.5 18.2502H13.5C13.8315 18.2502 14.1495 18.1185 14.3839 17.8841C14.6183 17.6497 14.75 17.3318 14.75 17.0002C14.75 16.9795 14.7509 16.9587 14.7526 16.938L15.6849 5.75024H3.3151Z"
      fill={fill ?? "#94A3B8"} />
  </svg>
)