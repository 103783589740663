import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import dayjs from 'dayjs';

import * as queries from '../../graphql/queries'
import { hasPermissionTo } from '../../selectors/auth';
import { APIGraphqlSelector, station as stationSelector } from '../../selectors/app';
import { timezone as tzSelector, dateFormat as dateFormatSelector } from '../../selectors/company';
import { isOpen } from '../../selectors/shifts';
import Loading from '../svg/Loading';
import Retry from '../svg/Retry';
import Pagination from '../common/Pagination'

const ShiftsList = ({ isListOpen, onSelect, onShowCurrent, selected }) => {
  const can = useSelector(hasPermissionTo);
  const shiftOpen = useSelector(isOpen);
  const timezone = useSelector(tzSelector);
  const dateFormat = useSelector(dateFormatSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [total, setTotal] = useState(null);
  const [metadata, setMetadata] = useState({
    start: 0,
    limit: 20,
    sortDirection: 'desc'
  })

  const { id } = useSelector(stationSelector);

  const fetchData = useCallback(async batch => {
    if (!can('index', 'pos-cashier') || !can('view', 'pos-cashier')) return;

    setError(false);
    setLoading(true);
    try {
      const response = await APIGraphql(graphqlOperation(queries.allShifts, {
        idStation: id,
        includeMetadata: true,
        batch,
      }))
      setLoading(false);
      setElements(get(response, 'data.allShifts.data', []))
      if (!!get(response, 'data.allShifts.metadata.total')) {
        setTotal(get(response, 'data.allShifts.metadata.total'))
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
    setMetadata(batch)
  }, [id, can, APIGraphql])

  useEffect(() => {
    fetchData({
      start: 0,
      limit: 20,
      sortDirection: 'desc'
    });
  }, [fetchData]);

  useEffect(() => {
    if (shiftOpen === false && metadata.start === 0) {
      fetchData({
        start: 0,
        limit: 20,
        sortDirection: 'desc'
      });
    }
  }, [shiftOpen, metadata.start, fetchData])

  return (
    <div className={`shifts__list h-100 d-flex flex-column ${isListOpen ? 'open' : 'closed'}`}>
      <div className="shifts__list-header d-flex p-4 justify-content-between align-items-center">
        <h2 className="text-capitalize-first">
          {I18n.get('currentShift', 'turno en curso')}
        </h2>

        {shiftOpen !== null && (
          <div
            className={`text-uppercase p-3 pointer font-weight-normal
              badge badge-pill badge-${!!shiftOpen ? 'primary' : 'danger'}`}
            onClick={() => onShowCurrent()}
          >
            {!!shiftOpen
              ? I18n.get('open', 'abierto')
              : I18n.get('closed', 'cerrado')
            }
          </div>
        )}
      </div>

      {!can('index', 'pos-cashier') || !can('view', 'pos-cashier')
        ? (
          <div className="shifts__list-body d-flex flex-column h-100 overflow-hidden">
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <div className="p-4 text-center">
                <p className="text-capitalize-first">
                  {I18n.get('userNotAllowed.pos-cashier.view', 'no tienes permiso para ver los cierres de turno anteriores')}
                </p>
              </div>
            </div>
          </div>

        ) : (
          <div className="shifts__list-body d-flex flex-column h-100 overflow-hidden">
            {!!loading
              ? (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Loading className="icon-primary icon x2" />
                </div>
              )
              : !!error
                ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div className="p-4 text-center">
                      <p className="text-capitalize-first">
                        {I18n.get('shiftsLoadError', 'ocurrió un error cargando el historial de turnos de la terminal')}
                      </p>
                      <button
                        type="button"
                        className="btn btn-submit"
                        onClick={() => fetchData(metadata)}
                      >
                        <Retry className="icon-white" />
                      </button>
                    </div>
                  </div>
                )
                : (
                  <>
                    <div className="shifts__list-body-shifts h-100">
                      {elements.map((shift, index) => (
                        <div
                          key={index}
                          className={`p-4 d-flex flex-column pointer border-bottom shift
                            ${(!!selected && selected.id === shift.id) ? 'active' : ''}`}
                          onClick={() => onSelect(shift)}
                        >
                          <p className="h3 text-capitalize-first">
                            {`${I18n.get('closeNo', 'Cierre No')} ${shift.idLocal}`}
                          </p>
                          <p className="h4 text-capitalize-first text-muted">
                            {dayjs.tz(shift.end, timezone).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))}
                          </p>
                        </div>
                      ))}
                    </div>

                    <Pagination
                      onFetchData={fetchData}
                      paginationData={{ ...metadata, total }}
                    />
                  </>
                )
            }
          </div>

        )
      }

    </div>
  )
}

export default ShiftsList;