// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`${className}`}
    width="24" 
    height="25" 
    viewBox="0 0 24 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.18 6.086a1.261 1.261 0 1 0 0 2.523 1.261 1.261 0 0 0 0-2.523zm0 1.681a.42.42 0 1 1 0-.84.42.42 0 0 1 0 .84zm6.308 7.147a.422.422 0 0 0 .298-.122l1.682-1.681a.418.418 0 0 0 .122-.299.42.42 0 0 0-.72-.298l-1.68 1.681a.42.42 0 0 0 .298.72zm8.287-1.98l-9.25-9.249a.42.42 0 0 0-.299-.122H4.658a1.261 1.261 0 0 0-1.261 1.262v7.567a.42.42 0 0 0 .122.298l9.25 9.25a.418.418 0 0 0 .597 0l8.409-8.409a.42.42 0 0 0 .09-.46.42.42 0 0 0-.09-.137zm-8.708 8.11l-8.83-8.829v-7.39a.42.42 0 0 1 .421-.42h7.396l8.83 8.828-7.817 7.81zm0-2.346a.417.417 0 0 0 .299-.122l2.522-2.523a.42.42 0 0 0 .122-.298.422.422 0 0 0-.719-.298l-2.522 2.522a.42.42 0 0 0 .298.719zm-1.98-2.4a.422.422 0 0 0 .597.596l.84-.84a.418.418 0 0 0 .123-.299.42.42 0 0 0-.72-.298l-.84.84z"
    />
  </svg>
)