// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M7.2 4h9.6v3.333H7.2V4zm-.8 4.167h11.2c1.328 0 2.4 1.116 2.4 2.5v5h-3.2V19H7.2v-3.333H4v-5c0-1.384 1.072-2.5 2.4-2.5zm2.4 9.166h6.4v-4.166H8.8v4.166zm8.8-5.833c-.44 0-.8-.375-.8-.833 0-.459.36-.834.8-.834.44 0 .8.375.8.834 0 .458-.36.833-.8.833z"
    />
  </svg>
)