import React from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useSelector, useDispatch } from 'react-redux'
import { capitalize, get, lowerCase } from 'lodash'
import { NavLink } from 'react-router-dom'

import * as mutations from '../../../graphql/mutations'
import { hasPermissionTo } from '../../../selectors/auth';
import { APIGraphqlSelector, station as stationSelector } from '../../../selectors/app'
import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { updateStation as update } from '../../../reducers/app'
import { handleError } from '../../../utils/errors'
import { stationTransformer } from '../../../utils/transformers'
import { toast } from '../../../utils'
import Tooltip from '../../common/Tooltip';
import View from '../../svg/View'
import Lock from '../../svg/Lock'
import Unlock from '../../svg/Unlock'
import Close from '../../svg/Close'

const Actions = ({ station, onRefresh, onView, startLoading, stopLoading }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)
  const currentStation = useSelector(stationSelector)
  const APIGraphql = useSelector(APIGraphqlSelector);

  const deleteStation = async () => {
    startLoading()
    try {
      await APIGraphql(graphqlOperation(mutations.deleteStation, {
        id: station.id
      }))
      stopLoading()
      toast.success({
        title: I18n.get('stationDeleted', 'terminal eliminada con éxito')
      })
      onRefresh()
    } catch (error) {

      stopLoading()
      toast.error({
        title: I18n.get('stationDeletedError', 'error eliminando terminal'),
        subtitle: handleError(error).replace("terminal", lowerCase(I18n.get('station', 'terminal')))
      })
    }
  }

  const updateStation = async isActive => {
    startLoading()
    try {
      const response = await APIGraphql(graphqlOperation(mutations.updateStation, {
        station: { ...stationTransformer(station), isActive }
      }))
      stopLoading()
      toast.success({
        title: isActive
          ? I18n.get('stationActivated', 'terminal activada con éxito')
          : I18n.get('stationDeactivated', 'terminal desactivada con éxito')
      })

      const updatedStation = get(response, !!station ? 'data.updateStation' : 'data.createStation')
      if (currentStation.id === updatedStation.id)
        dispatch(update(updatedStation))
      onRefresh()
    } catch (error) {
      stopLoading()
      toast.error({
        title: I18n.get('stationStatusError', 'error cambiando estado de terminal'),
        subtitle: handleError(error).replace("terminal", lowerCase(I18n.get('station', 'terminal')))
      })
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-start table-actions">
      <Tooltip
        tooltipActive={!can('view', 'pos-station') || (station.id !== currentStation.id && onlyInvoicing)}
        overlay={!onlyInvoicing
          ? (
            <p className="text-capitalize-first">
              {I18n.get('userNotAllowed.pos-station.view', 'no tienes permisos para ver detalle de terminales')}
            </p>
          ) : (
            <p className="text-capitalize-first">
              {I18n.get('onlyInvoicingTooltip', 'Para disfrutar de esta funcionalidad, conoce los planes todo incluido')}{' '}
              <NavLink
                to={{ pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans` }}
                className="btn-link"
                target="_blank"
              >
                {I18n.get('here', 'aquí')}
              </NavLink>
            </p>
          )
        }
      >
        <button
          type="button"
          disabled={!can('view', 'pos-station') || (station.id !== currentStation.id && onlyInvoicing)}
          className="btn button-transparent"
          title={capitalize(I18n.get('detail', 'detalle'))}
          onClick={() => onView()}
        >
          <View className="icon-primary" />
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can('edit', 'pos-station') || (station.id !== currentStation.id && onlyInvoicing)}
        overlay={!onlyInvoicing
          ? (
            <p className="text-capitalize-first">
              {I18n.get('userNotAllowed.pos-station.view', 'no tienes permisos para ver detalle de terminales')}
            </p>
          ) : (
            <p className="text-capitalize-first">
              {I18n.get('onlyInvoicingTooltip', 'Para disfrutar de esta funcionalidad, conoce los planes todo incluido')}{' '}
              <NavLink
                to={{ pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans` }}
                className="btn-link"
                target="_blank"
              >
                {I18n.get('here', 'aquí')}
              </NavLink>
            </p>
          )
        }
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={station.id === currentStation.id || !can('edit', 'pos-station') || (station.id !== currentStation.id && onlyInvoicing)}
          onClick={() => updateStation(!station.isActive)}
          title={!!station.isActive
            ? capitalize(I18n.get('deactivate', 'desactivar'))
            : capitalize(I18n.get('activate', 'activar'))
          }
        >
          {!!station.isActive
            ? <Unlock className="icon-primary" />
            : <Lock className="icon-primary" />
          }
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can('delete', 'pos-station') || (station.id !== currentStation.id && onlyInvoicing)}
        overlay={!onlyInvoicing
          ? (
            <p className="text-capitalize-first">
              {I18n.get('userNotAllowed.pos-station.view', 'no tienes permisos para ver detalle de terminales')}
            </p>
          ) : (
            <p className="text-capitalize-first">
              {I18n.get('onlyInvoicingTooltip', 'Para disfrutar de esta funcionalidad, conoce los planes todo incluido')}{' '}
              <NavLink
                to={{ pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans` }}
                className="btn-link"
                target="_blank"
              >
                {I18n.get('here', 'aquí')}
              </NavLink>
            </p>
          )
        }
      >
        <button
          type="button"
          className="btn button-transparent button-remove"
          title={capitalize(I18n.get('delete', 'eliminar'))}
          disabled={station.id === currentStation.id || !can('delete', 'pos-station') || (station.id !== currentStation.id && onlyInvoicing)}
          onClick={() => deleteStation()}
        >
          <Close className="icon-danger" />
        </button>
      </Tooltip>
    </div>
  )
}

export default Actions;