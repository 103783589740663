function PaperSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_952_401)">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.99984 2.16699C3.51361 2.16699 3.04729 2.36015 2.70347 2.70396C2.35966 3.04778 2.1665 3.5141 2.1665 4.00033V12.0003C2.1665 12.4866 2.35966 12.9529 2.70347 13.2967C3.04729 13.6405 3.51361 13.8337 3.99984 13.8337H8.6665C8.79447 13.8337 8.92243 13.7848 9.02006 13.6872L13.6867 9.02055C13.7347 8.97261 13.7708 8.91736 13.7952 8.85838C13.8166 8.80693 13.8296 8.75115 13.8325 8.69272C13.833 8.6842 13.8332 8.67562 13.8332 8.66699V4.00033C13.8332 3.51409 13.64 3.04778 13.2962 2.70396C12.9524 2.36015 12.4861 2.16699 11.9998 2.16699H3.99984ZM12.1261 9.16699H9.33317C9.28897 9.16699 9.24658 9.18455 9.21532 9.21581C9.18406 9.24706 9.1665 9.28946 9.1665 9.33366V12.1266L12.1261 9.16699ZM3.41058 3.41107C3.56686 3.25479 3.77882 3.16699 3.99984 3.16699H11.9998C12.2209 3.16699 12.4328 3.25479 12.5891 3.41107C12.7454 3.56735 12.8332 3.77931 12.8332 4.00033V8.16699H9.33317C9.02375 8.16699 8.727 8.28991 8.50821 8.5087C8.28942 8.72749 8.1665 9.02424 8.1665 9.33366V12.8337H3.99984C3.77882 12.8337 3.56686 12.7459 3.41058 12.5896C3.2543 12.4333 3.1665 12.2213 3.1665 12.0003V4.00033C3.1665 3.77931 3.2543 3.56735 3.41058 3.41107Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_952_401">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PaperSvg;
