// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M 11.7758,5.8205797 H 11.1293 V 3.6122255 C 11.1293,1.8277455 9.68102,0.37946446 7.89653,0.37946446 C 6.11205,0.37946446 4.66378,1.8277455 4.66378,3.6122255 V 5.8205797 H 4.01722 C 3.30602,5.8205797 2.72412,6.4024797 2.72412,7.1136797 V 13.57918 C 2.72412,14.29038 3.30602,14.87228 4.01722,14.87228 H 11.7758 C 12.4871,14.87228 13.0689,14.29038 13.0689,13.57918 V 7.1136797 C 13.0689,6.4024797 12.4871,5.8205797 11.7758,5.8205797 Z M 5.95688,3.6122255 C 5.95688,2.5389455 6.82326,1.6725655 7.89653,1.6725655 C 8.96981,1.6725655 9.83619,2.5389455 9.83619,3.6122255 H 11.1293 V 5.8205797 H 11.7758 H 5.95688 Z M 11.7758,13.57918 H 4.01722 V 7.1136797 H 11.7758 Z M 7.89653,11.63958 C 8.60774,11.63958 9.18964,11.05768 9.18964,10.34644 C 9.18964,9.6352397 8.60774,9.0533397 7.89653,9.0533397 C 7.18533,9.0533397 6.60343,9.6352397 6.60343,10.34644 C 6.60343,11.05768 7.18533,11.63958 7.89653,11.63958 Z"
    />
  </svg>
)
