import { capitalize } from 'lodash';
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';

import { closeMenu } from '../../../reducers/app';
import { openModal } from '../../../reducers/modals';
import Tag from '../../common/Tag';
import Typography from '../../common/Typography';

function ElectronicPayrollStatus() {
  const dispatch = useDispatch();
  const { isActive } = useElectronicPayrollStatus();

  const handleOpenModal = (e) => {
    e.preventDefault();

    if (isActive) {
      return;
    }

    dispatch(closeMenu());
    dispatch(
      openModal({
        modal: 'electronicPayrollTutorial',
      })
    );
  };

  return (
    <div
      className='electronic-payroll-status w-100 d-flex align-items-center justify-content-between p-0'
      onClick={(e) => handleOpenModal(e)}
    >
      <Typography
        type='body-3-regular'
        variant='secondary'
        text={I18n.get('electronicInvoicing', 'Facturación electrónica')}
      />

      <Tag
        text={capitalize(
          isActive
            ? I18n.get('activated', 'activada')
            : I18n.get('deactivated', 'desactivada')
        )}
        variant={isActive ? 'green' : 'slate'}
      />
    </div>
  );
}

export default ElectronicPayrollStatus;
