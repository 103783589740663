import en from './en';

const dictionary = {
  ...en,
  "cashReturned": "Change",
  "dgi": "DGI",
  "dueDate": "Due date",
  "onboarding.video.url": "https://www.youtube.com/embed/6TLSRE-D9bg",
  "onboarding.video.id": "6TLSRE-D9bg",
  "numerationsSettingTooltip": "Manage the numbering used for invoicing in your business from “Alegra Accounting”.",
  "subtotalWithoutTax": "Subtotal without tax",
  "taxesSettingTooltip": "Configure the taxes you need for invoicing at your point of sale “Alegra Accounting”.",
  "youCanNowMakeElectronicInvoices.description": "Activate yourself in 5 minutes and start creating electronic invoices at your POS with all the requirements of the DGI.",
  "helpedArticle": "Go to enable",
  "AlegraAccounting.new": "Accounting",
  "importProducts.text": "Import products",
  "importProducts.tooltip": "We will take you to Alegra Accounting to upload new products.",
  "solutionModal.accounting.supTitle": "Clear accounts with",
  "solutionModal.accounting.buttonMainText": "Try 15 days free",
  "solutionModal.accounting.buttonSecondaryText": "More information",
  "solutionModal.accounting.description": "With Alegra Management, you control your income and expenses with a click. You also get administrative and sales reports in real time.",
  "solutionModal.accounting.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.store.supTitle": "Increase your sales with",
  "solutionModal.store.buttonMainText": "Try 15 days free",
  "solutionModal.store.buttonSecondaryText": "More information",
  "solutionModal.store.description": "Start selling online in seconds! With Alegra Store, you can create your first online store in just 4 clicks.",
  "solutionModal.store.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
  "solutionModal.pos.supTitle": "Manage your business with",
  "solutionModal.pos.buttonMainText": "Try 15 days free",
  "solutionModal.pos.buttonSecondaryText": "More information",
  "solutionModal.pos.description": "Invoice in seconds with the fastest Point of Sale System on the market. In addition, you can generate your sales and inventory reports in seconds.⚡",
  "solutionModal.pos.subTitle": "*After 15 days, you can choose a plan from {{price}} {{currency}} per month.",
}

export default dictionary;