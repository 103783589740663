import React, { useState } from 'react'
import { I18n } from '@aws-amplify/core'
import { useSelector } from 'react-redux'

import { country as countrySelector, electronicInvoicing } from '../../../selectors/company'
import { customFields as customFieldsSelector } from '../../../selectors/customFields'

import Item from '../../forms/item/Item';
import KitInfo from './KitInfo';
import Accordion from '../../common/Accordion'
import Bottom from '../NewItem/Bottom';

const Primary = props => {
  const [accordion, setAccordion] = useState({
    main: true,
    variants: true,
    inventory: true,
    price: false,
    kit: false,
    electronicFields: true,
    accountant: false,
    adicionalData: false,
  })

  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing)
  const customFields = useSelector(customFieldsSelector)

  const toggleSection = section => {
    setAccordion(prev => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  return (
    <div className="bg-white p-5 new-item-primary-view">
      <Accordion
        isOpen={accordion['main']}
        onToggle={() => toggleSection('main')}
        header={I18n.get('generalInfo', 'información general')}
        subheader={I18n.get('item.generalInfoSubtitles', 'Configura la información principal de tu producto')}
        body={(
          <div className="form-body">
            <div className="mt-3">
              <Item.EditBasicData {...props} />
            </div>

            <div className="my-3 d-lg-none">
              <Item.Image {...props} name="leftImage" />

              <Item.RapidData {...props} />
            </div>
          </div>
        )}
        headerStyle={{
          width: 'fit-content'
        }}
        carretClassName="icon-primary ml-2"
        headerClassName="text-primary h3"
        subheaderClassName="h5 text-subtitle mb-2"
        containerClassName="border-bottom mb-4"
        bodyClassName="form-body"
      />

      {!!props.values.warehouses?.length && (
        <Accordion
          isOpen={accordion['inventory']}
          onToggle={() => toggleSection('inventory')}
          header={I18n.get('item.inventoryOptions', 'Detalles de inventario')}
          subheader={I18n.get('item.inventoryOptionsSubtitles', 'Distribuye y controla las cantidades de tus productos en diferentes lugares.')}
          body={(
            <div className="form-body px-2">
              <Item.NewInventory {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-primary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      {props.values.type === 'variantParent' && !!props.values.hasVariants && (
        <Accordion
          isOpen={accordion['variants']}
          onToggle={() => toggleSection('variants')}
          header={I18n.get('item.variantsListOptions', 'Variantes')}
          subheader={I18n.get('item.variantsListOptionsSubtitles', 'Agrega atributos como talla, color u otra cualidad para categorizar tus productos.')}
          body={(
            <div className="form-body px-2">
              <Item.Variants {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-primary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      {props.values.type === 'kit' && (
        <Accordion
          isOpen={accordion['kit']}
          onToggle={() => toggleSection('kit')}
          header={I18n.get('item.kitListOptions', 'Combo')}
          subheader={I18n.get('item.kitListOptionsSubtitles', 'Selecciona los productos y sus cantidades para armar un combo')}
          body={(
            <div className="form-body px-2">
              <KitInfo {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-primary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      <Accordion
        isOpen={accordion['price']}
        onToggle={() => toggleSection('price')}
        header={I18n.get('item.priceListOptions', 'Listas de precios')}
        subheader={
          <p>
            {I18n.get('item.priceListOptionsSubtitles', 'Agrega diferentes precios a tus productos')}
          </p>
        }
        body={(
          <div className="form-body px-2">
            <Item.PriceLists {...props} />
          </div>
        )}
        headerStyle={{
          width: 'fit-content'
        }}
        carretClassName="icon-primary ml-2"
        headerClassName="text-primary h3"
        subheaderClassName="h5 text-subtitle mb-2"
        containerClassName="border-bottom mb-4"
        bodyClassName="form-body"
      />

      {!!customFields.length && (
        <Accordion
          isOpen={accordion['electronicFields']}
          onToggle={() => toggleSection('electronicFields')}
          header={I18n.get('item.electronicFieldsOptions', 'Campos adicionales')}
          subheader={
            <p>
              {I18n.get('item.electronicFieldsOptionsSubtitles', 'Agrega información adicional para personalizar tu servicio.')}
              <a href="https://ayuda.alegra.com/es/crea-campos-adicionales-y-as%C3%B3cialos-a-tus-productos-con-el-sistema-punto-de-venta-pos" target="_blank" rel="noreferrer">
                {I18n.get('knowMore', 'Saber más')}
              </a>
            </p>
          }
          body={(
            <div className="form-body px-2">
              <Item.AdicionalFields {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-primary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      <Accordion
        isOpen={accordion['accountant']}
        onToggle={() => toggleSection('accountant')}
        header={I18n.get('item.accountingOptions', 'Configuración contable')}
        subheader={I18n.get('item.accountingOptionsSubtitles', 'Elige la cuenta en donde se registrarán los ingresos de este producto')}
        body={(
          <div className="form-body px-2">
            <Item.Accounting {...props} />
          </div>
        )}
        headerStyle={{
          width: 'fit-content'
        }}
        carretClassName="icon-primary ml-2"
        headerClassName="text-primary h3"
        subheaderClassName="h5 text-subtitle mb-2"
        containerClassName="border-bottom mb-4"
        bodyClassName="form-body"
      />

      {false && isElectronic && country === 'colombia' && (
        <Accordion
          isOpen={accordion['adicionalData']}
          onToggle={() => toggleSection('adicionalData')}
          header={I18n.get('item.adicionalDataOptions', 'Datos adicionales para facturas de exportación')}
          subheader={I18n.get('item.adicionalDataOptionsSubtitles', 'Completa estos datos si el producto que estás creando se usará en facturas de exportación.')}
          body={(
            <div className="form-body px-2">
              <Item.ElectronicFields {...props} />
            </div>
          )}
          headerStyle={{
            width: 'fit-content'
          }}
          carretClassName="icon-primary ml-2"
          headerClassName="text-primary h3"
          subheaderClassName="h5 text-subtitle mb-2"
          containerClassName="border-bottom mb-4"
          bodyClassName="form-body"
        />
      )}

      <div className="d-lg-none">
        <Bottom {...props} />
      </div>
    </div>
  )
}

export default Primary;