import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import dayjs from 'dayjs';

import {
  contactUsLink,
  aboutPOSLink,
  createElectronicInvoicesLink,
} from '../../../../../utils';
import { openTour } from '../../../../../reducers/tours';
import {
  country as countrySelector,
  regime as regimeSelector,
} from '../../../../../selectors/company';
import HelpNewSvg from '../../../../svg/new/Help';
import Typography from '../../../../common/Typography';
import ArrowRightSvg from '../../../../svg/new/ArrowRightUp';
import MenuOption from '../../../../common/MenuOption';
import { COUNTRIES } from '../../../../../utils/enums/countries';

const Help = () => {
  // const [showNovelties, setShowNovelties] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const country = useSelector(countrySelector);
  const regime = useSelector(regimeSelector);

  let showIvaNotification =
    country === 'colombia' &&
    dayjs().isBefore('2022-03-12') &&
    (regime === 'Responsable de IVA' || regime === 'Responsable de IVA e INC');

  let showNotification = showIvaNotification;

  const hanldeOpenTour = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if(country === COUNTRIES.COLOMBIA) {
      window.open('https://aleg.la/152', '_blank','noopener noreferrer')
    } else {
      history.push('/');
      dispatch(openTour({ tour: 'onboarding' }));
    }
  }

  return (
    <MenuOption
      id='help'
      eventName='Help'
      tooltip={I18n.get('help', 'Ayuda')}
      header={
        <HelpNewSvg
          className='icon-secondary'
          notification={showNotification}
        />
      }
    >
      {!!showNotification && (
        <>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href='https://ayuda.alegra.com/es/ayuda-dia-sin-iva-pos'
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get(
                  'noIVADaysNotification',
                  'Prepárate para el día sin IVA'
                )}
                variant='secondary'
              />
              <ArrowRightSvg />
            </a>
            <div className='btn-link-notification' />
          </div>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href='https://ayuda.alegra.com/es/ayuda-contingencia-dia-sin-iva-pos'
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get(
                  'noIVADaysNotification2',
                  'Prepara tu facturación de contingencia'
                )}
                variant='secondary'
              />
              <ArrowRightSvg />
            </a>
            <div className='btn-link-notification' />
          </div>
        </>
      )}

      {!showIvaNotification && (
        <>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href='/#'
              onClick={hanldeOpenTour}
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get('POSTutorial', 'Tutorial POS')}
                variant='secondary'
              />
              <ArrowRightSvg />
            </a>
          </div>
          <div className='menu-option p-3'>
            <a
              className='p-2 d-flex align-items-center justify-content-between'
              href={aboutPOSLink(country)}
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                type='body-3-regular'
                text={I18n.get('aboutPOS', 'acerca de POS')}
                variant='secondary'
              />
              <ArrowRightSvg />
            </a>
          </div>
        </>
      )}

      {country === 'colombia' && (
        <div className='menu-option p-3'>
          <a
            className='p-2 d-flex align-items-center justify-content-between'
            href={createElectronicInvoicesLink(country)}
            target='_blank'
            rel='noreferrer'
          >
            <Typography
              type='body-3-regular'
              text={I18n.get('', 'Facturas electrónicas desde POS')}
              variant='secondary'
            />
            <ArrowRightSvg />
          </a>
        </div>
      )}

      <div className='menu-option p-3'>
        <a
          className='p-2 d-flex align-items-center justify-content-between'
          href={contactUsLink(country)}
          target='_blank'
          rel='noreferrer'
        >
          <Typography
            type='body-3-regular'
            text={I18n.get('contactUs', 'contáctanos')}
            variant='secondary'
          />
          <ArrowRightSvg />
        </a>
      </div>
    </MenuOption>
  );
};

export default Help;
