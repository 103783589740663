import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get} from 'lodash'

import { closeModal } from '../../../reducers/modals';
import Modal from '../../common/Modal';
import { replaceAndParse } from '../../../utils';

const LimitedUser5UVT = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.limitedUser5UVT.isOpen', false));
  const isActivate = useSelector(state => get(state, 'modals.limitedUser5UVT.params.isActivate', true));
  
  const handleClose = () => {
    dispatch(closeModal({ modal: 'limitedUser5UVT' }));
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="modal__limited-user-5uvt"
      bordelessHeader={true}
    >
      <div className='d-flex justify-content-center pr-5'>
        <div className='d-flex mb-5 pb-3'>
          <div className='w-30 mx-3 ml-5 d-flex justify-content-center align-items-center'>
            <img className='w-90' alt="error" src="/error_permissions.png" />
          </div>
          <div className="d-flex flex-column pl-3 pr-5 justify-content-center align-items-start w-70">
            <div className="d-flex align-items-center mt-4 mb-2">
              <p className="inter-semibold h1 font-weight-bold">
                {replaceAndParse(I18n.get('limitedUser5UVT.title', 'Necesitas permiso para {} esta opción'), [
                  isActivate ? 'activar' : 'desactivar'
                ])}
              </p>
            </div>

            <div className="d-flex align-items-center mb-4">
              <p className="inter-regular h4 text-muted">
                {replaceAndParse(I18n.get('limitedUser5UVT.description', 'El cambio automático de documento POS a factura electrónica solo lo puede activar un usuario con perfil administrador.'), [])}
              </p>
            </div>

            <button 
              type="button"
              className="inter-regular btn btn-submit text-capitalize-firs mb-4"
              onClick={handleClose}
              >
                  {I18n.get("deactivate5UVT.cancel", "Volver a la configuración")}
              </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LimitedUser5UVT;