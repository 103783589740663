import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'
import dayjs from 'dayjs'

import * as queries from '../graphql/queries'
import { handleError } from '../utils/errors'
import { APIGraphqlSelector, station as stationSelector } from '../selectors/app'
import { total as localTotal, get as getInvoices, getAll } from '../database/invoicesDB'

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  offlineTotal: 0,
  metadata: {}
}

export const fetchInvoices = createAsyncThunk(
  'invoices/fetch',
  async (batch = null, { rejectWithValue, dispatch, getState }) => {
    const station = stationSelector(getState())
    const APIGraphql = APIGraphqlSelector(getState());

    try {
      let invoicesTotal = await localTotal();
      invoicesTotal = !!invoicesTotal ? invoicesTotal : 0;

      let localInvoicesInRange = Math.max(invoicesTotal - batch.start, 0)

      const getInvoicesPromise = APIGraphql(graphqlOperation(queries.allInvoices, {
        idStation: station.id,
        includeMetadata: true,
        batch: {
          ...batch,
          start: Math.max(batch.start - invoicesTotal, 0),
          limit: Math.max(batch.limit - localInvoicesInRange, 0),
        },
      }))

      const response = await getInvoicesPromise;

      let invoices = await getInvoices(batch);

      if (batch.limit - localInvoicesInRange > 0)
        invoices = invoices.concat(get(response, 'data.allInvoices.data', []))

      // invoicesTotal += !!get(response, 'data.allInvoices.metadata.total')
      //   ? get(response, 'data.allInvoices.metadata.total') : 0

      dispatch(receiveInvoices(invoices))
      dispatch(setOfflieTotal(invoicesTotal))
      dispatch(setMetadata(get(response, 'data.allInvoices.metadata')))

    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
)

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => {
    if (!!a.offlineStatus)
      return dayjs(a.timestamp).unix() - dayjs(b.timestamp).unix()
    if (!!a.timestamp && !!b.timestamp)
      return dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix()
    if (!!a.timestamp) return -1
    if (!!b.timestamp) return 1
    return +b.id - +a.id
  }
});

const appSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    receiveInvoices: (state, action) => {
      adapter.setAll(state, action.payload)
    },
    updateInvoice: adapter.updateOne,
    replaceInvoice: (state, action) => {
      const index = state.ids.indexOf(action.payload.id)

      if (index >= 0 && !!get(action, 'payload.new.id')) {
        state.ids[index] = action.payload.new.id;
        state.entities[action.payload.new.id] = action.payload.new;
        delete state.entities[action.payload.id]
      }
    },
    setOfflieTotal: (state, action) => {
      state.offlineTotal = action.payload
    },
    setMetadata: (state, action) => {
      state.metadata = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchInvoices.pending, state => {
      state.error = null;
      state.loading = 'loading'
    })
    builder.addCase(fetchInvoices.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = 'idle'
    })
    builder.addCase(fetchInvoices.fulfilled, state => {
      state.error = null;
      state.loading = 'idle'
    })
  }
});

const { reducer, actions } = appSlice;

export const invoicesSelector = adapter.getSelectors(state => state.invoices);

export const { receiveInvoices, updateInvoice, replaceInvoice, setOfflieTotal, setMetadata } = actions;

export default reducer;

export const updateOfflineInvoices = () => {
  return async (dispatch) => {
    const invoices = await getAll();

    invoices.map(invoice => {
      dispatch(updateInvoice({
        id: invoice.id,
        changes: { ...invoice }
      }))
      return null;
    })
  }
}