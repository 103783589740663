import React from "react";
import { I18n } from "@aws-amplify/core";
import { useSelector } from "react-redux";
import { capitalize, get } from "lodash";

import { hasPermissionTo } from "../../../selectors/auth";
import { handleError } from "../../../utils/errors";
import { warehouseTransformer } from "../../../utils/transformers";
import { stationWarehouse } from "../../../selectors/app";
import { toast } from "../../../utils";

import Unlock from "../../svg/Unlock";
import Tooltip from "../../common/Tooltip";
import EditSolid from "../../svg/EditSolid.svg";
import DeleteSolid from "../../svg/DeleteSolid.svg";
import Lock from "../../svg/Lock";

const Actions = ({ warehouse, onView, filters, deleteWarehouse, updateWarehouse }) => {
  const can = useSelector(hasPermissionTo);
  const currentWarehouse = useSelector(stationWarehouse);

  return (
    <div className="d-flex align-items-center justify-content-start table-gray-actions">
      <Tooltip
        tooltipActive={!can("edit", "warehouses")}
        overlay={<p className="text-capitalize-first">{I18n.get("userNotAllowed.warehouses.edit", "no tienes permisos para editar bodegas")}</p>}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can("edit", "warehouses") || !get(warehouse, 'editable', true)}
          onClick={() => onView()}
          title={capitalize(I18n.get("edit", "editar"))}
        >
          <img src={EditSolid} alt="editar" />
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can("edit", "warehouses") || (get(warehouse, 'id') === get(currentWarehouse, 'id'))}
        overlay={(get(warehouse, 'id') === get(currentWarehouse, 'id'))
          ? <p className="text-capitalize-first">{I18n.get("stationWarehouseStatusError", "Solo puedes desactivar bodegas que no estén asociadas a una terminal.")}</p>
          : <p className="text-capitalize-first">{I18n.get("userNotAllowed.warehouses.edit", "no tienes permisos para editar bodegas")}</p>}
      >
        <button
          type="button"
          className="btn button-transparent"
          disabled={!can("edit", "warehouses") || (get(warehouse, 'id') === get(currentWarehouse, 'id'))}
          onClick={() => updateWarehouse({
            warehouse: { ...warehouseTransformer(warehouse), status: !(get(warehouse, 'status') === 'active') ? 'active' : 'inactive' },
            currentWarehouseId: get(currentWarehouse, 'id'),
            isStatusChange: true,
          }, {
            onSuccess: (_, variables) => {
              toast.success({
                title: get(variables, 'warehouse.status') === 'active'
                  ? I18n.get('warehouseActivated', 'Bodega activada con éxito')
                  : I18n.get('warehouseDeactivated', 'Bodega desactivada con éxito')
              })
            },
            onError: (error) => {
              const responseError = get(error, 'response.data.message', '');

              toast.error({
                title: I18n.get('warehouseStatusError', 'Error cambiando estado de la categoria'),
                subtitle: !!responseError ? responseError : handleError(error)
              });
            },
          })}
          title={
            get(warehouse, "status") === "active" ? capitalize(I18n.get("deactivate", "desactivar")) : capitalize(I18n.get("activate", "activar"))
          }
        >
          {get(warehouse, "status") === "active" ? <Unlock className="icon-primary" /> : <Lock className="icon-primary" />}
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can("delete", "warehouses") || (get(warehouse, 'id') === get(currentWarehouse, 'id'))}
        overlay={(get(warehouse, 'id') === get(currentWarehouse, 'id'))
          ? <p className="text-capitalize-first">{I18n.get("stationWarehouseDeletedError", "Solo puedes eliminar bodegas que no estén asociadas a una terminal.")}</p>
          : <p className="text-capitalize-first">{I18n.get("userNotAllowed.warehouses.delete", "no tienes permisos para eliminar bodegas")}</p>}
      >
        <button
          type="button"
          disabled={!can("delete", "warehouses") || !get(warehouse, 'deletable', true) || (get(warehouse, 'id') === get(currentWarehouse, 'id'))}
          className="btn button-transparent button-remove"
          title={(get(warehouse, 'id') === get(currentWarehouse, 'id')) ? "" : capitalize(I18n.get("delete", "eliminar"))}
          onClick={() => deleteWarehouse({
            id: get(warehouse, 'id'),
            warehouse,
            currentWarehouse: currentWarehouse,
          })}
        >
          <img src={DeleteSolid} alt="" />
        </button>
      </Tooltip>
    </div>
  );
};

export default Actions;
