import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { fiscalResponsabilities } from '../../../countriesData/colombia/fiscalResponsabilities';
import { renderCheckbox } from '../../fields/V0/Fields';

const renderFiscalResponsabilities = props => {
  return props.country === 'colombia' && props.isElectronic ? (
    <div className="col-12">
      <div className="subsection-title">
        {capitalize(I18n.get('fiscalResponsabilities', 'responsabilidades fiscales'))}
      </div>

      <div className="nowrap w-100">
        {fiscalResponsabilities.map(responsability => (
          <Field
            key={responsability.id}
            className="col-12 my-2 px-2 px-md-3"
            name={`fiscalResponsabilities.${responsability.id}`}
            component={renderCheckbox}
            label={responsability.value}
          />
        ))}
      </div>
    </div>
  ) : null;
}

export default renderFiscalResponsabilities;