import React from 'react';
import { I18n } from '@aws-amplify/core';

import ProfileSVG from '../../../svg/header/Profile.svg';
import PlanSVG from '../../../svg/header/Plan.svg';
import SecuritySVG from '../../../svg/header/Security.svg';
import SettingsSVG from '../../../svg/header/Settings.svg';
import SignOutSVG from '../../../svg/header/SignOut.svg';
import RedirectSVG from '../../../svg/header/Redirect.svg';
import ElectronicPayrollStatus from '../../../settings/Invoices/ElectronicPayrollStatus';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';
import NavigationMenu from '../../../common/Navigation';
import { openModal } from '../../../../reducers/modals';
import { useDispatch } from 'react-redux';
import { useHeader } from '../../context/header.context';

const MenuOptions = () => {
  const { setActiveMenu } = useHeader();
  const { isActive, canElectronicPayroll } = useElectronicPayrollStatus();
  const dispatch = useDispatch();

  return (
    <>
      <NavigationMenu
        items={[
          {
            to: 'electronicMenu',
            menuName: 'electronicMenu',
            isMenu: false,
            hover: !isActive,
            hidden: !canElectronicPayroll,
            component: <ElectronicPayrollStatus />,
            action: () => setActiveMenu(''),
            layout: 'big',
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}consumption`,
            menuName: 'planConsumption',
            target: '_blank',
            icon: <img className='icon-left-menu' src={PlanSVG} alt='' />,
            title: I18n.get('planConsumption', 'Consumo del plan'),
            isMenu: false,
            extraIcon: <img src={RedirectSVG} alt='' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}profile`,
            menuName: 'profile',
            target: '_blank',
            icon: <img className='icon-left-menu' src={ProfileSVG} alt='' />,
            title: I18n.get('profile', 'Mi Perfil'),
            isMenu: false,
            extraIcon: <img src={RedirectSVG} alt='' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}security`,
            target: '_blank',
            menuName: 'security',
            icon: <img className='icon-left-menu' src={SecuritySVG} alt='' />,
            title: I18n.get('security', 'Seguridad'),
            isMenu: false,
            extraIcon: <img src={RedirectSVG} alt='' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `/settings`,
            menuName: 'security',
            icon: <img className='icon-left-menu' src={SettingsSVG} alt='' />,
            title: I18n.get('settings', 'Configuraciones'),
            isMenu: false,
            action: () => setActiveMenu(''),
          },
          {
            menuName: 'logout',
            icon: <img className='icon-left-menu' src={SignOutSVG} alt='' />,
            title: I18n.get('logout', 'cerrar sesión'),
            action: () => dispatch(openModal({ modal: 'logoutConfirm' })),
            isMenu: false,
          },
        ]}
      />
    </>
  );
};

export default MenuOptions;
