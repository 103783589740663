import React from 'react'
import { I18n } from '@aws-amplify/core';
import { find, get } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from "bignumber.js";

import { useFormat } from "../../../hooks/useFormat";
import { country as countrySelector, isActiveNoIvaDay, isTaxesResponsable as isTaxesResponsableSelector } from '../../../selectors/company'
import { calculateSingleItemValues } from '../../../utils';
import { allProductUnits } from '../../../selectors/units';
import taxTypeByPercentage from '../../countriesData/republicaDominicana/taxes'
import { assingITBISTaxesToLetter, getColombiaTaxCode } from './utilities';
import { useCheckTaxesSummaryAvailability } from '../../../hooks/featureAvailability/useCheckTaxesSummaryAvailability';

const renderNoIvaDayMessage = item => {
  return get(item, 'applyNoIvaDays', '') === 'yes'
    ? `- ${I18n.get('wellCovered', 'bien cubierto')} -`
    : null
}

const Items = ({ items, isElectronic, setting: { unitPrice, description, template, unitOfMeasure, totalLines, printItemFullLine, taxesIncludedInTotal } }) => {
  const { decimal, fmt } = useFormat();  
  const country = useSelector(countrySelector)
  const isIvaDay = useSelector(isActiveNoIvaDay)
  const allUnits = useSelector(allProductUnits);
  const isTaxesResponsable = useSelector(isTaxesResponsableSelector);

  const isTaxesSummaryAvailable = useCheckTaxesSummaryAvailability();

  const customTaxTypeByPercentage = country === 'republicaDominicana' ? assingITBISTaxesToLetter(items) : {};

  if (!items) return null;

  if (printItemFullLine) return newItemsTemplateDesing({ items, decimal, fmt, printDemo, country, template, unitPrice, unitOfMeasure, totalLines, description, taxesIncludedInTotal, isElectronic, isTaxesSummaryAvailable, isTaxesResponsable, customTaxTypeByPercentage, allUnits });
  return (
    <div className={`w-100 py-2 d-flex flex-column justify-content-center align-items-center border-bottom`}>

      {items.map((item, index) => {
        const { price, discount, discountValue, total, taxValue } = calculateSingleItemValues(item, decimal);
        const currentUnitItem = allUnits.find((i) => i.key === get(item, 'unit', get(item, 'inventory.unit', '')));
        
        return (
          <div key={index} className="w-100 d-flex flex-wrap form-row items-table mb-2">
            {country === 'colombia' && totalLines && <p className="col-1 break-all">{index + 1}</p>}
            <p className={`${!!unitPrice ? 'col-3' : 'col-5'} text-left break-all`}>
              {get(item, 'name', '')}
                
              {!!item && !!currentUnitItem && unitOfMeasure && !!get(currentUnitItem, 'value', '') && (
                <>
                  <br />
                  <span className="col-1 break-all">{`(${get(currentUnitItem, 'value', '')})`}</span >
                </>
              )}
              {country === 'colombia' && !!item && !!get(item, 'productKey', '') && (
                <>
                  <br />
                  <span className="col-1 break-all">{get(item, 'productKey', '')}</span >
                </>
              )}
              
            </p>
            <p className={`text-center break-all ${!!unitPrice ? "col-1" : "col-2"}`}>{+get(item, 'quantity', 0)}</p>
            {!!unitPrice && (
              <p className="col-3 text-right break-all">
                {country === 'republicaDominicana' && taxesIncludedInTotal
                  ? price.plus(taxValue.toNumber() / +get(item, 'quantity', 0)).toFormat(decimal, fmt)
                  : (country === 'colombia' && isTaxesSummaryAvailable)
                    ? price.plus(taxValue.toNumber() / +get(item, 'quantity', 0)).toFormat(decimal, fmt)
                    : price.toFormat(decimal, fmt)}
              </p>
            )}
            <div className={`${(country === 'colombia' && isTaxesSummaryAvailable) ? 'col-4' : 'col-3'}`}>
              <p className='text-right break-all'>
                {taxesIncludedInTotal
                  ? total.toFormat(decimal, fmt)
                  : total.minus(taxValue.toNumber()).toFormat(decimal, fmt)}
              </p>

            </div>

            {description && !!get(item, 'description', '') && <p className="text-left break-all col-12">{get(item, 'description')}</p>}

            {isIvaDay && !!renderNoIvaDayMessage(item) && (
              <p className="text-left break-all col-12">{renderNoIvaDayMessage(item)}</p>
            )}

            {discount > 0 && (
              <>
                {country === 'colombia' && <p className="col-1 break-all" />}
                <p className={`${!!unitPrice ? 'col-3' : 'col-5'} text-left text-nowrap text-capitalize-first`}>
                  {`${I18n.get('discount', 'descuento')} ${discount}%`}
                </p>
                <p className="col-2" />
                <p className={`${!!unitPrice ? 'col-3' : 'd-none'}`} />
                <p className={`${!!unitPrice ? 'col-3' : 'col-4'} text-center break-all`}>
                  {`-${new BigNumber(discountValue).toFormat(decimal, fmt)}`}
                </p>
              </>
            )}
          </div>
        )
      }
      )}
    </div>
  )
};

const newItemsTemplateDesing = ({ items, decimal, fmt, printDemo, country, template, unitPrice, unitOfMeasure, totalLines, description, taxesIncludedInTotal, isElectronic, isTaxesSummaryAvailable, isTaxesResponsable, customTaxTypeByPercentage, allUnits }) => (
  <div className={`w-100 py-2 d-flex flex-column justify-content-center align-items-center ${template !== 'simple' ? 'border-top' : ''}`}>
    <div className="w-100 d-flex flex-wrap form-row items-table mb-2">
      <p className="text-left break-all col-8 font-weight-bold">Item</p>
      <p className="text-center break-all col-3 font-weight-bold">Total</p>
      <p className="col-1" />
    </div>

    {items.map((item, index) => {
      const { quantity, discount, discountValue, price, taxValue, discSubtotal } = calculateSingleItemValues(item, decimal);
      const hasITBISTax = country === 'republicaDominicana' && !!find(get(item, 'tax', []), tax => tax.type === 'ITBIS');
      const taxType = find(get(item, 'tax', []), tax => tax.type === 'EXENTO')
        ? "E"
        : hasITBISTax && ![18, 16, 0].includes(Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 1)))
          ? get(customTaxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)))
          : get(taxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)));
      const productUnitOfMeasure = get(item, 'unit', get(item, 'inventory.unit', null)) ? get(item, 'unit', get(item, 'inventory.unit', '')) : 'UND';
      const currentUnitItem = allUnits.find((i) => i.key === get(item, 'unit', get(item, 'inventory.unit', '')));

      return (
        <div key={index} className="w-100 d-flex flex-wrap form-row items-table mb-2">
          {country === 'colombia' && !totalLines && <b className="col-1 break-all">{index + 1}.</b>}
          <div className={`col-${country === 'colombia' && !totalLines ? '7' : '8'}`}>
            <p className="m-0">{get(item, 'name', '')} {country === 'colombia' && !!get(item, 'productKey') && `(${get(item, 'productKey', '')})`}</p>
            <p className="m-0">
              {!printDemo && (
                <b>{quantity} {(isElectronic && country === 'republicaDominicana') || unitOfMeasure
                  ? !!get(currentUnitItem, 'value', '') && currentUnitItem.value
                  : ''}</b>
              )}
              {printDemo && (
                <b>{quantity} {(isElectronic && country === 'republicaDominicana') || unitOfMeasure
                  ? productUnitOfMeasure
                  : ''}</b>
              )}

              {(unitPrice || (isElectronic && country === 'republicaDominicana')) && <span>{" "}x {country === 'republicaDominicana' && taxesIncludedInTotal
                ? price.plus(taxValue.toNumber() / +get(item, 'quantity', 0)).toFormat(decimal, fmt)
                : price.toFormat(decimal, fmt)}</span>}
              {discount !== 0 && (
                <span>{" "}(-{discount}%)</span>
              )}
            </p>
            {country === 'republicaDominicana' && (
              <p className="m-0">{get(item, 'tax[0].type') === "EXENTO" ? <>EXENTO (E)</>
                : hasITBISTax ? <>ITBIS {get(item, 'tax[0].percentage')}% {taxType === "E" ? "E" : `(${taxType})`}</> : ''}</p>
            )}
          </div>
          <p className="text-center break-all col-3">
            <p className="m-0">
              {country === 'republicaDominicana' && taxesIncludedInTotal
                ? discSubtotal.plus(taxValue.toNumber()).toFormat(decimal, fmt)
                : discSubtotal.toFormat(decimal, fmt)}
            </p>
            <p className="m-0">
              {discountValue.toNumber() !== 0 ? (
                <>-{discountValue.toFormat(decimal, fmt)}</>
              ) : <br />}
            </p>
            {country === 'republicaDominicana' && (
              <p className="m-0">
                {hasITBISTax ? taxValue.toFormat(decimal, fmt) : ''}
              </p>
            )}
          </p>
          {(country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable) && (
            <p className={`col-1 text-center break-all`}>
              {get(item, 'tax', []).length === 0
                ? <span>{getColombiaTaxCode().itemTaxCode}</span>
                : get(item, 'tax').map((tax, index) => <span key={index}>{getColombiaTaxCode(tax).itemTaxCode}</span>)
              }
            </p>
          )}
          {description && !!get(item, 'description', '') && <p className="text-left break-all col-12">{get(item, 'description')}</p>}
        </div>
      )
    })}
  </div >
)

export default Items