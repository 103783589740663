import { BigNumber } from 'bignumber.js';
import { countryConfig } from './countryConfig';

export const formatCurrencyMoney = (amount, country) => {
  const decimalSeparator =
    countryConfig[country]?.decimalSeparator ||
    countryConfig.other.decimalSeparator;
  const groupSeparator =
    countryConfig[country]?.groupSeparator ||
    countryConfig.other.groupSeparator;
  const currencyPrefix =
    countryConfig[country]?.currencyPrefix ||
    countryConfig.other.currencyPrefix;

  const format = {
    decimalSeparator: decimalSeparator,
    groupSeparator: groupSeparator,
    prefix: currencyPrefix,
    groupSize: 3,
  };

  return new BigNumber(amount).toFormat(format);
};
