function ArrowBarRightSvg({ id, className, notification }) {
  return (
    <svg
      id={id}
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9585 3.33334C3.9585 2.98817 3.67867 2.70834 3.3335 2.70834C2.98832 2.70834 2.7085 2.98817 2.7085 3.33334V16.6667C2.7085 17.0119 2.98832 17.2917 3.3335 17.2917C3.67867 17.2917 3.9585 17.0119 3.9585 16.6667V3.33334Z"
        fill="#94A3B8"
      />
      <path
        d="M13.7754 6.22474C13.5314 5.98066 13.1356 5.98066 12.8916 6.22474C12.6475 6.46881 12.6475 6.86454 12.8916 7.10862L15.1579 9.37501H8.3335C7.98832 9.37501 7.7085 9.65483 7.7085 10C7.7085 10.3452 7.98832 10.625 8.3335 10.625H15.1579L12.8916 12.8914C12.6475 13.1355 12.6475 13.5312 12.8916 13.7753C13.1356 14.0194 13.5314 14.0194 13.7754 13.7753L17.1088 10.442C17.2303 10.3204 17.2914 10.1612 17.2918 10.0019L17.2918 10V9.99907C17.2916 9.83943 17.2306 9.67987 17.1088 9.55807L13.7754 6.22474Z"
        fill="#94A3B8"
      />
    </svg>
  );
}

export default ArrowBarRightSvg;
