function NewMenu({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H17C17.4142 0.25 17.75 0.585786 17.75 1C17.75 1.41421 17.4142 1.75 17 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1Z"
        fill="#334155"
      />
      <path
        d="M0.25 7C0.25 6.58579 0.585786 6.25 1 6.25H17C17.4142 6.25 17.75 6.58579 17.75 7C17.75 7.41421 17.4142 7.75 17 7.75H1C0.585786 7.75 0.25 7.41421 0.25 7Z"
        fill="#334155"
      />
      <path
        d="M1 12.25C0.585786 12.25 0.25 12.5858 0.25 13C0.25 13.4142 0.585786 13.75 1 13.75H17C17.4142 13.75 17.75 13.4142 17.75 13C17.75 12.5858 17.4142 12.25 17 12.25H1Z"
        fill="#334155"
      />
    </svg>
  );
}

export default NewMenu;
