// eslint-disable-next-line
export default ({ id, className, ...props }) => (
  <svg 
    id={id}
    className={className}
    width={!!props.width ? props.width : "24"}
    height={!!props.height ? props.height : "24"}
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M13.231 3.086a8.75 8.75 0 1 0 7.284 6.64l-1.297 1.342a7.25 7.25 0 1 1-7.354-6.567l1.367-1.415zm-1.738 10.666l5.114-5.113a.75.75 0 1 0-1.061-1.06l-5.113 5.113-.345-2.531-1.477-.243.679 4.978 4.978.678-.244-1.477-2.53-.345z"
    />
  </svg>
)