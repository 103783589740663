function HelpNewSvg({ id, className, notification }) {
  return (
    <svg
      id={id}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 16.25C12.4142 16.25 12.75 16.5858 12.75 17V17.01C12.75 17.4242 12.4142 17.76 12 17.76C11.5858 17.76 11.25 17.4242 11.25 17.01V17C11.25 16.5858 11.5858 16.25 12 16.25Z'
        fill='#334155'
      />
      <path
        d='M12.0806 6.22145C11.5621 6.22001 11.0504 6.33895 10.5856 6.5689C10.1209 6.79885 9.7159 7.13353 9.40251 7.5466C9.15214 7.87659 9.21669 8.34705 9.54668 8.59742C9.87667 8.84778 10.3471 8.78323 10.5975 8.45324C10.7706 8.22513 10.9942 8.0403 11.2509 7.91332C11.5075 7.78633 11.7901 7.72065 12.0764 7.72144C12.3628 7.72224 12.645 7.78949 12.901 7.91789C13.1569 8.0463 13.3795 8.23237 13.5513 8.46144C13.7231 8.69051 13.8394 8.95633 13.891 9.23797C13.9426 9.51962 13.9282 9.8094 13.8487 10.0845C13.7693 10.3596 13.6271 10.6125 13.4334 10.8233C13.2415 11.0321 13.0042 11.194 12.7398 11.2964C12.2846 11.4592 11.8938 11.7644 11.6255 12.1669C11.3549 12.5727 11.2236 13.0555 11.2512 13.5424C11.2747 13.9559 11.6289 14.2722 12.0425 14.2487C12.456 14.2253 12.7723 13.871 12.7488 13.4575C12.7396 13.2951 12.7834 13.1342 12.8736 12.999C12.9637 12.8637 13.0954 12.7614 13.2488 12.7075L13.2679 12.7004C13.7522 12.5152 14.1869 12.2202 14.5378 11.8384C14.8886 11.4567 15.1461 10.9987 15.2899 10.5005C15.4337 10.0024 15.4599 9.47761 15.3664 8.9676C15.273 8.45759 15.0624 7.97624 14.7513 7.56144C14.4402 7.14663 14.0371 6.80971 13.5736 6.57718C13.1102 6.34466 12.5991 6.22289 12.0806 6.22145Z'
        fill='#334155'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75Z'
        fill='#334155'
      />
      {!!notification && <circle fill='#e85e42' r='4' cx='20' cy='4' />}
    </svg>
  );
}

export default HelpNewSvg;
