import React from 'react'
import { I18n } from 'aws-amplify'
import { capitalize } from 'lodash'
import CustomizeSVG from '../../svg/NewCustomize';
import PrintSVG from '../../svg/NewPrint';
import CashOutlineSVG from '../../svg/NewCashOutline';


const SmallDevicesSettings = ({ printAction, currencyAction, settingsAction }) => {
  return (
    <div className="invoice-top__header-invoice-settings p-0">
      <p className="invoice-top__header-invoice-settings-small-options"
        onClick={printAction}>
        <PrintSVG className="icon-gray mr-3" />
        {capitalize(I18n.get('print', 'Imprimir'))}
      </p>
      <p className="invoice-top__header-invoice-settings-small-options"
        onClick={currencyAction}>
        <CashOutlineSVG className="icon-gray mr-3" />
        {capitalize(I18n.get('currency', 'Moneda'))}
      </p>
      <p className="invoice-top__header-invoice-settings-small-options"
        onClick={settingsAction}>
        <CustomizeSVG className="icon-gray mr-3" />
        {capitalize(I18n.get('invoiceSettings', 'Configuración de venta'))}
      </p>
    </div>
  )
}

export default SmallDevicesSettings