import PropTypes from 'prop-types';

import Button from '../../../Button';
import Typography from '../../../Typography';

import Trash from '../../../../svg/new/Trash';

function FilterHeader({ title, action }) {
  return (
    <div className='custom-filter-header'>
      <Typography type='label-3' text={title} variant='tertiary' />
      <Button
        size='icon'
        variant='ghost'
        onClick={action ? () => action() : null}
      >
        <Trash />
      </Button>
    </div>
  );
}

FilterHeader.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func,
};

export default FilterHeader;
