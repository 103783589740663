import React from 'react'
import { I18n } from '@aws-amplify/core';
import Loading from '../../svg/Loading';

const Bottom = ({onClose, disabled, submitting }) => {
    return (
        <div className="modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow">
            <div className="required-message mb-3 mb-sm-0">
                <i className="text-primary mr-2">* </i>
                <span className="text-capitalize-first text-muted">{I18n.get("requiredFields", "Campos obligatorios")}</span>
            </div>

            <div className="modal__bottom-buttons d-flex">
                <button 
                type="button" 
                className="btn btn-cancel text-capitalize-first" 
                onClick={() => onClose()}
                >
                    {I18n.get("back", "atrás")}
                </button>
                <button
                    type="submit"
                    className="btn btn-submit text-capitalize-first"
                >
                    {submitting
                        ? <Loading className="icon-primary" />
                        : I18n.get("continue", "continuar")
                    }
                </button>
            </div>
        </div>
    )
}

export default Bottom;