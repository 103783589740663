import React, { useMemo } from "react";
import parse from 'html-react-parser';
import { I18n } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import { closeModal } from "../../../reducers/modals";

import Modal from "../../common/Modal";
import Bottom from "./Bottom";
import PaymentDeclined from '../../../assets/images/payment_declined.png'
import Typography from "../../common/Typography";

const MembershipPaymentDeclined = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.membershipPaymentDeclined.isOpen', false));
  const paymentAttemp = useSelector(state => get(state, 'modals.membershipPaymentDeclined.params.attempt', null));

  const paymentDeclinedMessage = useMemo(() => {
    switch (paymentAttemp) {
      case 8:
        return {
          title: I18n.get('paymentDeclined8.title', 'Actualiza tus datos de pago'),
          text: I18n.get('paymentDeclined8.text', 'Hemos detectado un problema con tu método de pago al realizar el cobro de tu suscripción. <b>¿Deseas agregar un nuevo método de pago?</b>'),
          help: {
            init: I18n.get('paymentDeclined8.init', 'Si necesitas ayuda puedes '),
            link: I18n.get('paymentDeclined8.link', 'contactar a soporte.'),
          },
          action: I18n.get('paymentDeclined8.action', 'Agregar método de pago'),
        }
      case 12:
        return {
          title: I18n.get('paymentDeclined12.title', 'Tu suscripción será suspendida'),
          text: I18n.get('paymentDeclined12.text', 'No fue posible procesar tu último pago, por favor, <b>agrega un nuevo método de pago</b> para evitar que tu cuenta sea suspendida.'),
          help: {
            init: I18n.get('paymentDeclined12.init', 'Si necesitas ayuda puedes '),
            link: I18n.get('paymentDeclined12.link', 'contactar a soporte.'),
          },
          action: I18n.get('paymentDeclined12.action', 'Agregar método de pago ahora'),
        }

      default:
        return {
          title: '',
          text: '',
          help: {
            init: '',
            link: '',
          },
          action: '',
        }
    }
  }, [paymentAttemp])

  const handleClose = () => {
    dispatch(closeModal({ modal: 'membershipPaymentDeclined' }));
  }

  return (
    <Modal
      isOpen={isOpen}
      className="modal__payment-declined"
      headerImg={PaymentDeclined}
      bordelessHeader
      hideClose
    >
      <Typography
        type='heading-4'
        variant='primary'
        text={paymentDeclinedMessage?.title}
      />
      <Typography
        type='body-3-regular'
        variant='primary'
        text={parse(paymentDeclinedMessage?.text)}
      />
      <div className="d-flex flex-column flex-sm-row">
        <Typography
          type='body-3-regular'
          variant='primary'
          text={paymentDeclinedMessage?.help?.init}
        />
        <a
          href="https://share.hsforms.com/1IuHm6VhsRniWi0MxJ0dEgA3ogwa"
          target="_blank"
          rel="noreferrer"
          className="ml-1"
        >
          <Typography
            type='body-3-underline'
            variant='primary'
            text={<b>{paymentDeclinedMessage?.help?.link}</b>} />
        </a>
      </div>
      <Bottom
        onConfirm={() => {
          window.open(`${process.env.REACT_APP_ALEGRA}payment-method/add`, '_blank')
        }}
        onRequestClose={handleClose}
        actionMessage={paymentDeclinedMessage?.action}
      />
    </Modal>
  )
}

export default MembershipPaymentDeclined;