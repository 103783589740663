// eslint-disable-next-line
export default ({ id, className, fill }) => (
    <>
        <svg id={id} className={className} width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.69141 8.25L0.566407 4.24969L4.69141 0.25L4.69141 8.25Z" fill={fill} />
        </svg>
        <svg id={id} className={className} style={{ marginLeft: "0.5rem" }} width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.69141 8.25L0.566407 4.24969L4.69141 0.25L4.69141 8.25Z" fill={fill} />
        </svg>
    </>
);
