import React from 'react';
import ReactSelect, { components } from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import { get } from 'lodash';
import { I18n } from 'aws-amplify';

import Loading from '../../svg/Loading';
import Check from '../../svg/Check';
import TooltipComponent from '../Tooltip';
import AlertCircle from '../../svg/AlertCircle';

const colors = {
  primary: '#00b19d',
  primary75: 'rgba(0, 177, 157, 0.75)',
  primary50: 'rgba(0, 177, 157, 0.50)',
  primary25: 'rgba(0, 177, 157, 0.25)',
  primaryLight: 'rgba(0, 177, 157, 0.2)',
  primaryDark: '#008e79',
  secondary: '#809bb6',
  danger: '#e85e42',
  font: '#474747',
  gray1: '#f2f2f2',
  gray2: '#cccccc',
  gray3: '#b3b3b3',
  gray4: '#999999',
  gray5: '#808080',
  gray6: '#595959',
  blueDark: '#00535e',
  disabled: '#E9ECEF',
  disabledText: '#707070',
  groupHeading: '#64748B',
  border: '#E2E8F0',
  optionHover: '#E2E8F073',
  optionSelected: '#E2E8F0B2',
};

export const styles = (props) => ({
  container: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    height: !!props.height ? props.height : '3.8rem',
    // border: isFocused ? colors.primary : null,
    border: 'red',
    width: props.width ? props.width : '100%',
    background: isDisabled ? colors.gray1 : provided.background,
    fontSize: !!props.fontSize ? props.fontSize : '14px',
  }),
  control: (provided, { isDisabled }) => ({
    fontSize: !!props.fontSize ? props.fontSize : '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '2rem',
    height: !!props.height ? props.height : '3.8rem',
    borderRadius: '5px',
    border: `1px solid ${!!props.hasError ? colors.danger : colors.gray2}`,
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
    background: isDisabled ? colors.disabled : provided.background,
    ':focus': {
      ...styles[':focus'],
      border: `1px solid ${colors.primary}`,
    },
    ':focus-within': {
      ...styles[':focus-within'],
      border: `1px solid ${colors.primary}`,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap',
    flex: '1 1 0%',
    alignItems: 'center',
    width: 0,
    justifyContent: 'flex-start',
    color: colors.font,
    fontSize: !!props.fontSize ? props.fontSize : '14px',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexShrink: '0',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  input: (provided) => ({
    ...provided,
    height: !!props.height ? props.height : '3.8rem',
    fontSize: !!props.fontSize ? props.fontSize : '14px',
    fontFamily: 'inter',
    color: colors.font,
    padding: '0 2px',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: !!props.placeholderColor ? props.placeholderColor : colors.gray3,
    fontSize: !!props.fontSize ? props.fontSize : '14px',
    fontFamily: 'inter',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '::first-letter': {
      textTransform: 'uppercase !important',
    },
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? colors.disabledText : colors.font,
    fontSize: !!props.fontSize ? props.fontSize : '14px',
    fontFamily: 'inter',
  }),
  multiValue: (provided) => ({
    ...provided,
    color: colors.font,
    fontSize: !!props.fontSize ? props.fontSize : '14px',
    fontFamily: 'inter',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '225px',
    '::-webkit-scrollbar': {
      width: '20px',
      height: '3px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#CBD5E1',
      border: '7px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      borderRadius: '20px',
    },
  }),
  option: (provided, { data, value, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    fontSize: !!props.fontSize ? props.fontSize : '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '2rem',
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? colors.optionSelected
      : isFocused
      ? colors.optionHover
      : undefined,
    // color: !!data.action ? colors.primary : isDisabled || !value ? colors.gray2 : isSelected ? colors.primary : colors.font,
    color: !!data.action
      ? colors.primary
      : isDisabled || !value
      ? colors.disabled
      : colors.font,
    // color: colors.danger,
    cursor: !!data.action ? 'pointer' : 'default',
    whiteSpace: 'pre-wrap',
    textAlign: 'start',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? colors.primary : colors.optionHover),
    },
    paddingLeft: !isSelected ? '4rem' : '1.5rem',
    borderRadius: '0.8rem',
  }),
  group: (provided) => ({
    ...provided,
    paddingTop: '5px',
    paddingBottom: '5px',
    borderTop: `1px solid ${colors.border}`,
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: colors.groupHeading,
    fontWeight: '500',
    fontSize: !!props.fontSize ? props.fontSize : '1.2rem',
    textAlign: 'left',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '1.6rem',
    textTransform: 'capitalize',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    '::first-letter': {
      textTransform: 'uppercase !important',
    },
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
});

const EmptyComponent = () => null;

const MultiValueComponent = (props) => {
  return <span>{props.selectProps.getOptionLabel(props.data)}, </span>;
};

const OptionComponent = (props) =>
  get(props, 'data.action') ? (
    <div onClick={props.data.action}>
      <components.Option {...props} />
    </div>
  ) : !!props.data.additionalInfo ? (
    <components.Option {...props}>
      <div>
        <p className='mb-0' style={{ fontWeight: 500 }}>
          {props.data.name}
        </p>
        <p className=' text-wrap mb-0' style={{ fontSize: 12 }}>
          {props.data.additionalInfo}
        </p>
      </div>
    </components.Option>
  ) : get(props, 'isMulti', false) ? (
    <components.Option {...props}>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
          className='my-0'
        />{' '}
        <label className='my-0 mx-2'>{props.label}</label>
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>
      <TooltipComponent
        tooltipActive={props.isDisabled}
        placement='left'
        overlay={I18n.get(
          `${props.data.disabledReason}.tooltip`,
          'Esta opción no está disponible'
        )}
      >
        <div className='d-flex align-items-center justify-items-center'>
          <Check className={`mr-3 ${props.isSelected ? '' : 'd-none'}`} />
          {props.label}
        </div>
      </TooltipComponent>
    </components.Option>
  );

const MenuComponent = ({ children, ...rest }) => (
  <components.Menu {...rest}>
    <>
      {!!rest.selectProps.selectInfo && (
        <p className='p-3 text-center text-capitalize-first'>
          {rest.selectProps.selectInfo}
        </p>
      )}

      {children}

      {!!get(rest, 'selectProps.selectAction') &&
        !!get(rest, 'selectProps.selectActionText') && (
          <button
            type='button'
            className='btn w-100 button-transparent text-capitalize-first text-primary text-left'
            style={{
              fontSize: !!get(rest, 'selectProps.fontSize')
                ? get(rest, 'selectProps.fontSize')
                : '14px',
              fontFamily: 'inter',
              height: '3.8rem',
              zIndex: '10',
            }}
            onClick={() => rest.selectProps.selectAction()}
          >
            {rest.selectProps.selectActionText}
          </button>
        )}
    </>
  </components.Menu>
);

const IndicatorSeparator = ({ selectProps }) => {
  if (!selectProps.hasError) return null;

  if (selectProps?.tooltip) {
    return (
      <TooltipComponent
        overlay={selectProps?.tooltip?.overlay}
        tooltipActive={selectProps?.tooltip?.active}
        placement='bottom'
      >
        <span>
          <AlertCircle />
        </span>
      </TooltipComponent>
    );
  }

  return (
    <span>
      <AlertCircle />
    </span>
  );
};

const Select = (props) => {
  return !props.loadOptions ? (
    <ReactSelect
      {...props}
      className={`Select ${!!props.className ? props.className : ''}`}
      classNamePrefix='Select'
      components={{
        DropdownIndicator: !!props.hideDropdownIndicator
          ? EmptyComponent
          : components.DropdownIndicator,
        NoOptionsMessage: !!props.hideNoOptionMessage
          ? EmptyComponent
          : components.NoOptionsMessage,
        IndicatorSeparator: IndicatorSeparator,
        ClearIndicator: !!props.showClearIndicator
          ? components.ClearIndicator
          : EmptyComponent,
        MultiValueRemove: EmptyComponent,
        MultiValueContainer: MultiValueComponent,
        LoadingIndicator: () => <Loading className='icon-primary' />,
        Option: OptionComponent,
        Menu:
          !!props.selectAction || !!props.selectInfo
            ? MenuComponent
            : components.Menu,
      }}
      menuPlacement='auto'
      hideSelectedOptions={false}
      closeMenuOnSelect={!!props.isMulti ? false : true}
      styles={styles(props)}
      theme={(theme) => ({
        ...theme,
        borderRadius: 16,
        colors: {
          ...theme.colors,
          ...colors,
        },
        spacing: {
          ...theme.spacing,
          controlHeight: 32,
        },
      })}
    />
  ) : (
    <ReactAsyncSelect
      {...props}
      className={`Select ${!!props.className ? props.className : ''}`}
      classNamePrefix='Select'
      components={{
        DropdownIndicator: !!props.hideDropdownIndicator
          ? EmptyComponent
          : components.DropdownIndicator,
        NoOptionsMessage: !!props.hideNoOptionMessage
          ? EmptyComponent
          : components.NoOptionsMessage,
        IndicatorSeparator: EmptyComponent,
        ClearIndicator: !!props.showClearIndicator
          ? components.ClearIndicator
          : EmptyComponent,
        MultiValueRemove: EmptyComponent,
        MultiValueContainer: MultiValueComponent,
        LoadingIndicator: () => <Loading className='icon-primary' />,
        Option: OptionComponent,
        Menu:
          !!props.selectAction || !!props.selectInfo
            ? MenuComponent
            : components.Menu,
      }}
      menuPlacement='auto'
      hideSelectedOptions={false}
      closeMenuOnSelect={!!props.isMulti ? false : true}
      styles={styles(props)}
      theme={(theme) => ({
        ...theme,
        borderRadius: 16,
        colors: {
          ...theme.colors,
          ...colors,
        },
        spacing: {
          ...theme.spacing,
          controlHeight: 32,
        },
      })}
    />
  );
};

export const SelectWithIcon = (props) => {
  return (
    <ReactSelect
      {...props}
      defaultValue={props.defaultValue}
      formatOptionLabel={props.formatOptionLabel}
      options={props.options}
      styles={styles(props)}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          ...colors,
        },
        spacing: {
          ...theme.spacing,
          controlHeight: 32,
        },
      })}
    />
  );
};

export default Select;
