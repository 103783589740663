import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import {
  companySelector,
  country as countrySelector,
} from '../../../selectors/company';
import Close from '../../svg/Close';
import Info from '../../svg/toast/Info';
import Warning from '../../svg/Warning';
import { replaceAndParse } from '../../../utils';
import { allNumerations } from '../../../selectors/numerations';
// import { openModal } from '../../../reducers/modals';

const ActivateFENotification = () => {
  // const dispatch = useDispatch()
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const numerations = useSelector(allNumerations);
  const [notification, setNotification] = useState({
    fe: false,
    caea: false,
    serviceDown: false,
  });
  const hasFEactive = get(company, 'settings.electronicInvoicing', false);
  const hasCAEAactive = useMemo(
    () => numerations.some((num) => num.documentType === 'contingency'),
    [numerations]
  );

  const closeNotification = (key) =>
    setNotification((prev) => ({ ...prev, [key]: false }));
  const openNotification = (key) =>
    setNotification((prev) => ({ ...prev, [key]: true }));

  useEffect(() => {
    if (country === 'argentina') {
      const showFEnotif = !Cookies.get('_fe-arg-n');
      const showCAEAnotif = !Cookies.get('_caea-arg-n');

      if (!hasFEactive && showFEnotif) {
        openNotification('fe');
      }
      if (
        numerations.length !== 0 &&
        hasFEactive &&
        !hasCAEAactive &&
        showCAEAnotif
      ) {
        openNotification('caea');
      }
    }
    if (country === 'peru') {
      const showFEnotif = !Cookies.get('_fe-per-n');

      if (!hasFEactive && showFEnotif) {
        openNotification('fe');
      }
    }
    if (country === 'colombia') {
      const showServiceDown = !Cookies.get('_service_down-col-n');
      const isDayToShow = dayjs().format('YYYY-MM-DD') === '2022-06-01';
      if (hasFEactive && showServiceDown && isDayToShow) {
        openNotification('serviceDown');
      }
    }
  }, [company, country, hasFEactive, hasCAEAactive, numerations]);

  // const buttonActionFE = () => {
  //     dispatch(openModal({ modal: 'electronicPayrollTutorial' }));
  // }

  // const closeActionFE = () => {
  //   closeNotification('fe');
  //   if (country === 'argentina') Cookies.set('_fe-arg-n', Date.now());
  //   if (country === 'peru') Cookies.set('_fe-per-n', Date.now());
  // }

  const buttonActionServiceDown = () => {
    if (country === 'colombia')
      window.open(
        'https://ayuda.alegra.com/es/plan-de-contingencia-facturacion-electronica-en-colombia1'
      );
  };

  const closeActionServiceDown = () => {
    closeNotification('serviceDown');
    if (country === 'colombia') Cookies.set('_service_down-col-n', Date.now());
  };

  const buttonActionCAEA = () => {
    window.open(
      'https://ayuda.alegra.com/es/c%C3%B3mo-activo-mis-facturas-electr%C3%B3nicas-en-el-sistema-punto-de-venta-alegra-argentina',
      '_blank'
    );
  };

  const closeActionCAEA = () => {
    closeNotification('caea');
    Cookies.set('_caea-arg-n', Date.now());
  };

  // if (notification.fe) {
  //   return (
  //     <Notification
  //       info
  //       title={I18n.get(
  //         'youCanNowMakeElectronicInvoices',
  //         '¡Habilítate para emitir Facturas Electrónicas! 💥'
  //       )}
  //       description={I18n.get(
  //         'youCanNowMakeElectronicInvoices.description',
  //         'Actívate ante la DIAN **en menos de 10 minutos* y empieza a crear facturas electrónicas en tu punto de venta.'
  //       )}
  //       buttonText={
  //         country === 'argentina'
  //           ? I18n.get('goToTutorial', 'Ir al tutorial')
  //           : I18n.get('howToEnableMe', 'Cómo habilitarme')
  //       }
  //       buttonAction={buttonActionFE}
  //       closeAction={closeActionFE}
  //     />
  //   );
  // }

  if (notification.caea) {
    return (
      <Notification
        info
        title={I18n.get(
          'rememberConfigureCAEA',
          '¡Recordá configurar tu numeración CAEA! 👉'
        )}
        description={I18n.get(
          'rememberConfigureCAEA.description',
          'Si necesitás emitir comprobantes electrónicos en tu local, debés solicitar y configurar una numeración CAEA de contingencia.'
        )}
        buttonText={I18n.get('goToTutorial', 'Ir al tutorial')}
        buttonAction={buttonActionCAEA}
        closeAction={closeActionCAEA}
      />
    );
  }

  if (notification.serviceDown) {
    return (
      <Notification
        warning
        title={I18n.get(
          'prepareFEServiceDown',
          '¡Prepárate para el mantenimiento de la DIAN!'
        )}
        description={replaceAndParse(
          I18n.get(
            'prepareFEServiceDown.description',
            'Hoy {} de {} entre las {} y las {} el Sistema de Facturación Electrónica estará en mantenimiento.'
          ),
          ['1', 'junio', '8:00 p.m.', '10:00 p.m.']
        )}
        buttonText={I18n.get('prepareNow', 'Prepararme ahora')}
        buttonAction={buttonActionServiceDown}
        closeAction={closeActionServiceDown}
      />
    );
  }

  return null;
};

export const Notification = ({
  title,
  description,
  buttonText,
  buttonAction,
  closeAction,
  warning,
  info,
}) => {
  if (warning && info)
    throw new Error(
      'La notificación no puede ser de tipo warning y info a la misma vez'
    );

  const styles = {
    border: warning ? '#F99D36' : '#4A90E2',
    background: warning ? '#FFF7EB' : '#E0E7FF',
    iconBackground: warning ? '#FF980033' : '',
    buttonColor: warning ? '#FF9811' : '#4F46E5',
  };

  return (
    <div className='main-notification d-block d-md-block'>
      <div
        className='main-notification__notification d-flex align-items-start justify-content-between p-2'
        style={{ backgroundColor: styles.background }}
      >
        <div className='d-flex main-notification__notification-info'>
          <div
            className='d-flex align-items-center justify-content-center ml-3'
            style={{
              backgroundColor: styles.iconBackground,
              minWidth: '36px',
              height: '36px',
              borderRadius: '.7rem',
            }}
          >
            {warning ? <Warning /> : <Info />}
          </div>

          <div className=' d-flex flex-column align-items-start justify-content-between p-1 px-3'>
            <b>{title}</b>
            <p className='m-0 mt-1'>{description}</p>
          </div>
        </div>

        <div className='d-flex main-notification__notification-actions'>
          {buttonText && (
            <button
              onClick={buttonAction}
              className='btn btn-link text-capitalize-first'
              style={{
                margin: 0,
                minWidth: '11rem',
                boxSizing: 'border-box',
                borderRadius: '3px',
                fontSize: '1.4rem',
                fontWeight: 900,
                color: styles.buttonColor,
                padding: '.3rem 0',
                outline: 'none',
                '&:hover': {
                  '&:not(:disabled)': {
                    border: `1px solid ${styles.border} !important`,
                  },
                },
                '&:focus': {
                  border: `1px solid ${styles.border} !important`,
                },
              }}
            >
              {buttonText}
            </button>
          )}
          <div
            className='text-right px-2 pointer close-action-button'
            onClick={closeAction}
          >
            <Close className='icon icon-title' />
          </div>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  warning: PropTypes.bool,
  info: PropTypes.bool,
};

export default ActivateFENotification;
