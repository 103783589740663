import React from 'react'
import { I18n } from '@aws-amplify/core';
import PropTypes from 'prop-types'

import Retry from '../svg/Retry'

const ErrorElement = ({ onRefresh }) => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="p-4 text-center">
        <p className="text-capitalize-first">
          {I18n.get('invoicesLoadError', 'ocurrió un error cargando las facturas de la terminal')}
        </p>
        <button
          type="button"
          className="btn btn-submit"
          onClick={() => onRefresh()}
        >
          <Retry className="icon-white" />
        </button>
      </div>
    </div>
  )
}

ErrorElement.propTypes = {
  onRefresh: PropTypes.func
}

export default ErrorElement;