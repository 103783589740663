import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize, get, includes } from 'lodash';

import { setSettings } from '../../../reducers/activeInvoice';
import { hasPermissionTo } from '../../../selectors/auth';
import { stationInvoiceNumeration } from '../../../selectors/app';
import {
  activePriceLists,
  getMainPriceList,
} from '../../../selectors/priceLists';
import {
  getInvoiceNumerations,
  groupedNumerations as groupedNumerationsSelector,
} from '../../../selectors/numerations';
import { priceList, numeration } from '../../../selectors/activeInvoice';
import {
  companySelector,
  country as countrySelector,
  regime as regimeSelector,
} from '../../../selectors/company';
import { economicActivitiesSelector } from '../../../selectors/auth';
import { checkNumerationBlock } from './utils';

import Select from '../../common/Select';
import StarSVG from '../../svg/Star';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import Typography from '../../common/Typography';
import Tooltip from '../../common/Tooltip';
import { stationPriceList as stationPriceListSelector } from '../../../selectors/app';
import { COUNTRIES } from '../../../utils/enums/countries';

const FastOptions = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const priceLists = useSelector(activePriceLists);
  const currentNumeration = useSelector(stationInvoiceNumeration);
  const numerations = useSelector(getInvoiceNumerations);
  const invoicePriceList = useSelector(priceList);
  const invoiceNumeration = useSelector(numeration);
  const mainPriceList = useSelector(getMainPriceList);
  const economicActivities = useSelector(economicActivitiesSelector);
  const country = useSelector(countrySelector);
  const aditionalSettings = useSelector(
    (state) => state.aditionalSettings.settings
  );
  const groupedNumerations = useSelector(groupedNumerationsSelector);
  const [numerationBlocked, setNumerationBlocked] = useState(null);
  const { availableCountries } = useElectronicPayrollStatus()
  const { registryDate } = useSelector(companySelector);
  const noViewNumerationPermission = !can('index', 'number-templates');
  const stationPriceList = useSelector(stationPriceListSelector);
  const regime = useSelector(regimeSelector)

  useEffect(() => {
    if(!invoiceNumeration && country === COUNTRIES.PERU) {
      if(currentNumeration) {
        dispatch(setSettings({ numeration: currentNumeration }))
      }
    }
  }, [invoiceNumeration,currentNumeration, country, dispatch])

  useEffect(() => {
    if (country !== COUNTRIES.PERU) return;
    if (!regime?.includes('RUS')) return;

    const currentDefaultNumeration = currentNumeration || invoiceNumeration;
    const saleTicketNumeration = numerations?.find(numeration => numeration.documentType === 'saleTicket');
    
    if (!currentDefaultNumeration) {
      if (saleTicketNumeration) {
        dispatch(setSettings({ numeration: saleTicketNumeration }));
      }
    }
  
    if (currentDefaultNumeration?.documentType !== 'saleTicket') {
      if (saleTicketNumeration) {
        dispatch(setSettings({ numeration: saleTicketNumeration }));
      }
    }
  }, [country, currentNumeration, regime, numerations, dispatch]);
  

  const setDefaultPriceList = useCallback(() => {
    if (!stationPriceList) {
      return;
    }

    const priceList = priceLists.find(priceList => Number(priceList.id) === Number(stationPriceList.id));

    if (!priceList) {
      dispatch(setSettings({ mainPriceList }));
      return;
    }
    
    dispatch(setSettings({ priceList }));  
  }, [priceLists, mainPriceList, stationPriceList, dispatch]);

  useEffect(() => {
    setDefaultPriceList();
  }, [setDefaultPriceList]);

  const getNumerationBlock = useCallback(() => {
    setNumerationBlocked(checkNumerationBlock(invoiceNumeration ?? currentNumeration, country, registryDate))
  }, [invoiceNumeration, currentNumeration, country, registryDate])

  useEffect(() => {
    getNumerationBlock();
  }, [getNumerationBlock]);

  if (!get(priceLists, 'length') && !get(numerations, 'length')) return null;

  return (
    <div className='px-3 pt-3 pb-1 d-sm-flex align-items-center form-row m-0 d-none'>
      {!!get(priceLists, 'length') && aditionalSettings['priceList'] && (
        <div style={{ paddingRight: '.9rem', height: '100%' }} className='col-6 pl-3'>
          <p className='text-capitalize-first fast-option-title'>
            {I18n.get('priceList', 'lista de precio')}
          </p>
          <Select
            value={!!invoicePriceList ? invoicePriceList : stationPriceList}
            options={
              !can('index', 'price-lists') || !can('view', 'price-lists')
                ? []
                : priceLists
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            backspaceRemovesValue={false}
            placeholder={capitalize(I18n.get('priceList', 'lista de precio'))}
            onChange={(value) => dispatch(setSettings({ priceList: value }))}
          />
        </div>
      )}

      {!!get(numerations, 'length') && (
        <div style={{ paddingLeft: '.9rem', height: '100%' }} className='col-6 pr-3'>
          <p className='text-capitalize-first fast-option-title'>
            {I18n.get('numeration', 'Numeración')}
          </p>

          <Select
            value={!!invoiceNumeration ? invoiceNumeration : currentNumeration}
            options={
              availableCountries.includes(country)
                ? groupedNumerations
                : numerations
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            backspaceRemovesValue={false}
            placeholder={capitalize(I18n.get('numeration', 'Numeración'))}
            onChange={(value) => dispatch(setSettings({ numeration: value }))}
            hasError={!!numerationBlocked}
            tooltip={{
              active: !!numerationBlocked,
              overlay: (
                <Typography
                  type='caption-bold'
                  variant='white'
                  text={I18n.get(
                    numerationBlocked?.name,
                    'La numeración se quedo sin consecutivos.'
                  )}
                />
              ),
            }}
          />

          {/* <PopoverComponent
            title={I18n.get(
              numerationBlocked?.name,
              'Hay problemas con esta numeracion'
            )}
            overlay={
              <div className='pr-5'>
                <p className='h5' style={{ fontWeight: '400', opacity: '0.7' }}>
                  {I18n.get(
                    `${numerationBlocked?.name}.body`,
                    'Elige otra numeración o solicita una nueva ante la DIAN para seguir facturando.'
                  )}
                </p>
                <button
                  className='btn-sm-contrast-outline mt-4'
                  onClick={() =>
                    window.open(
                      getResolutionHelpUrl(
                        invoiceNumeration,
                        country,
                        numerationBlocked
                      ),
                      '_blank'
                    )
                  }
                >
                  {I18n.get(
                    `${numerationBlocked?.name}.button`,
                    'Cómo solicitar otra'
                  )}
                </button>
              </div>
            }
            popoverActive={!!numerationBlocked && showNumerationBlockPopover}
            position='bottom'
            theme='dark'
            onRequestClose={() => setShowNumerationBlockPopover(false)}
          >
            <Select
              value={
                !!invoiceNumeration ? invoiceNumeration : currentNumeration
              }
              options={
                availableCountries.includes(country)
                  ? groupedNumerations
                  : numerations
              }
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              backspaceRemovesValue={false}
              placeholder={capitalize(I18n.get('numeration', 'Numeración'))}
              onChange={(value) => dispatch(setSettings({ numeration: value }))}
              hasError={!!numerationBlocked}
            />
          </PopoverComponent> */}
        </div>
      )}

      {noViewNumerationPermission && (
        <div style={{ paddingLeft: ".9rem", height: '100%' }} className='col-6 pr-3'>
          <p className="text-capitalize-first fast-option-title">{I18n.get('numeration', 'Numeración')}</p>
          <Tooltip
            overlay={I18n.get('youNeedToAskForPermissions.view-numerations', 'Solicita el permiso a un administrador para ver el listado de numeraciones.')}
            overlayInnerStyle={{ maxWidth: '200px' }}
            tooltipActive={true}
            placement="top"
          >
            <Select
              options={[]}
              backspaceRemovesValue={false}
              placeholder={capitalize(I18n.get('numeration', 'Numeración'))}
              onChange={value => dispatch(setSettings({ numeration: value }))}
              isDisabled
              hasError={true}
            />
          </Tooltip>
          <div className='pt-1'>
            <Typography
              type='label-3'
              variant='danger'
              text={I18n.get('youNeedPermissionsToViewList', 'Necesitas permiso para ver el listado')}
            />
          </div>
        </div>
      )}

      {!!get(economicActivities, 'length') &&
        aditionalSettings['economicActivity'] && (
          <div className='col-6 pl-3'>
            <p className='text-capitalize-first fast-option-title mt-3'>
              {I18n.get('economicActivity', 'Actividad económica')}
            </p>
            <Select
              defaultValue={economicActivities.find(
                (activity) => activity.main === true
              )}
              formatOptionLabel={(props) => (
                <div style={{ display: 'flex' }} className='m-0'>
                  <p className='m-0'>
                    {props.code} - {props.value}{' '}
                    {props.main && (
                      <span>
                        <StarSVG className='svg-icon-primary' />
                      </span>
                    )}
                  </p>
                </div>
              )}
              options={economicActivities}
              onChange={(value) =>
                dispatch(setSettings({ economicActivity: value }))
              }
            />
          </div>
        )}
    </div>
  );
};

export default FastOptions;
