// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="72" 
    height="72" 
    viewBox="0 0 13 13" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      style={{
        opacity: 1, 
        fill: 'none', 
        stroke: '#00b19d',
        fillOpacity: 1, 
        strokeWidth: 0.529167, 
        strokeLinecap: 'round',
        strokeLinejoin: 'miter', 
        strokeMiterlimit: 4,
        strokeOpacity: 1,
      }}
      d="M 11.376999,4.3772964 A 5.4002113,5.4002113 0 0 1 8.8048526,11.159988 5.4002113,5.4002113 0 0 1 1.8031347,9.2634685 5.4002113,5.4002113 0 0 1 3.0058452,2.1098452 5.4002113,5.4002113 0 0 1 10.242796,2.6072175"
    />
    <path 
      style={{
        opacity: 1, 
        fill: 'none', 
        stroke: '#00b19d',
        fillOpacity: 1, 
        strokeWidth: 0.529167, 
        strokeLinecap: 'round',
        strokeLinejoin: 'miter', 
        strokeMiterlimit: 4,
        strokeOpacity: 1,
      }}
      d="M 3.7414012,5.5814327 6.2807706,8.0864862 11.668352,2.527326"
    />
  </svg>
)