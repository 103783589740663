function TrailingTextSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width="38"
      height="11"
      viewBox="0 0 38 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.581321 5.90909L6.46768 0.0227269L12.354 5.90909H9.33132V11H3.60405V5.90909H0.581321ZM2.81357 5.0142H4.59837V10.1051H8.337V5.0142H10.1218L6.46768 1.36008L2.81357 5.0142ZM21.9057 0.818182H26.6685V1.91193H21.9057V0.818182ZM14.8958 1.91193V0.818182H18.8134L23.4071 9.90625H26.6685V11H22.7509L18.1571 1.91193H14.8958ZM32.3125 11H29.1705V0.818182H32.4517C33.4394 0.818182 34.2846 1.02202 34.9872 1.42969C35.6899 1.83404 36.2285 2.41572 36.603 3.17472C36.9775 3.9304 37.1648 4.83523 37.1648 5.8892C37.1648 6.94981 36.9759 7.86293 36.598 8.62855C36.2202 9.39086 35.67 9.97751 34.9474 10.3885C34.2249 10.7962 33.3466 11 32.3125 11ZM30.4034 9.90625H32.233C33.0748 9.90625 33.7725 9.74384 34.326 9.41903C34.8795 9.09422 35.2921 8.63187 35.5639 8.03196C35.8357 7.43205 35.9716 6.7178 35.9716 5.8892C35.9716 5.06723 35.8374 4.35961 35.5689 3.76633C35.3004 3.16974 34.8994 2.71236 34.3658 2.39418C33.8321 2.07268 33.1676 1.91193 32.3722 1.91193H30.4034V9.90625Z"
        fill="#64748B"
      />
    </svg>
  );
}

export default TrailingTextSvg;
