import React, { useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import { I18n } from "aws-amplify";
import { capitalize, get, isEmpty } from "lodash";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { useFormat } from "../../../hooks/useFormat";
import { styleBigNumber } from "../../../utils";
import { checkFeatureLimit } from "../../../reducers/membership";
import { hasPermissionTo } from "../../../selectors/auth";
import { stationWarehouse as stationWarehouseSelector } from "../../../selectors/app";
import { useRefresh } from "../../../hooks/queryHooks/useRefresh";
import { replaceAndParse } from "../../../utils";
import { useConnectionStatus } from "../../../hooks/useConnectionStatus";
import { sendGTMEvent } from "../../../reducers/company";

import DeleteInventoryAdjustmentConfirmation from "../../modals/deleteInventoryAdjustmentConfirmation/DeleteInventoryAdjustmentConfirmation";
import Tooltip from "../../common/Tooltip";

import Trash from '../../svg/NewTrash';
import Edit from '../../svg/NewEdit';
import CloseDetails from "../../svg/CloseDetails";
import ExpandDetails from "../../svg/ExpandDetails";
import MinimalTable from "../../common/MinimalTable";
import ArrowLeftSolid from "../../svg/ArrowLeftSolid";
import { useSingleInventoryAdjustmentQuery } from "./queries";
import Loading from "../../svg/Loading";

const Details = ({ inventoryAdjustment, setOpenDetail, mutate, filters, detailFullscreen, setDetailFullscreen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { fmt, decimal } = useFormat();
  const refresh = useRefresh();
  const isOnline = useConnectionStatus();
  const can = useSelector(hasPermissionTo);
  const stationWarehouse = useSelector(stationWarehouseSelector);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { data } = useSingleInventoryAdjustmentQuery(get(inventoryAdjustment, 'id'));

  let mergedArray = [];

  if (!!data) {
    const fullInventoryAdjustmentItems = data?.items?.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
    mergedArray = inventoryAdjustment?.items?.map(item => ({
      ...item,
      inventory: (fullInventoryAdjustmentItems[item.id]?.inventory || {})
    }));
  }

  const openEdit = () => {
    history.push(`/inventory/inventory-adjustments/edit/${inventoryAdjustment.id}`);
  }

  const isInventoryAdjustmentInMemory = queryClient.getQueryData(["inventoryAdjustment", { "id": get(inventoryAdjustment, 'id') }]) !== undefined;

  const columns = useMemo(() => [
    {
      header: () => (
        <div style={{ textAlign: "start" }}>
          {capitalize(I18n.get("product", "Producto"))}
        </div>
      ),
      Render: ({ row }) => (
        <div style={{ textAlign: "start" }}>
          <p className="h4 text-truncate styled-hover m-0" hovercontent={get(row, 'name')}>
            {get(row, 'name')}
          </p>
          <p className={`h4 text-muted mt-3 ${!get(row, 'reference') ? "d-none" : ""}`}>
            {`Ref. ${get(row, 'reference')}`}
          </p>
        </div>
      )
    },
    {
      header: capitalize(I18n.get("cost", "Costo")),
      Render: ({ row }) => (
        <p className="h4 text-muted text-truncate styled-hover m-0 p-0" hovercontent={new BigNumber(get(row, 'unitCost', 0)).toFormat(decimal, fmt)}>
          {new BigNumber(get(row, 'unitCost', 0)).toFormat(decimal, fmt)}
        </p>
      )
    },
    {
      header: capitalize(I18n.get("initialQuantityAbbreviation", "Cant. inicial")),
      Render: ({ row }) => {
        if (!(get(row, 'inventory.availableQuantity')))
          return (<Loading className="icon-primary" />)

        return (
          <p className="h4 text-muted text-truncate styled-hover m-0 p-0" hovercontent={get(row, 'inventory.availableQuantity')}>
            {get(row, 'inventory.availableQuantity')}
          </p>
        )
      }
    },
    {
      header: capitalize(I18n.get("adjusted", "Ajuste")),
      Render: ({ row }) => {
        const quantityAdjusted = parseFloat(get(row, 'quantity'));
        return (
          <p className="h4 text-muted text-truncate styled-hover m-0 p-0" hovercontent={get(row, 'quantity')}>
            {`${get(row, 'type') === 'in' ? "+" : "-"}${quantityAdjusted}`}
          </p>
        )
      }
    },
    {
      header: capitalize(I18n.get("finalQuantityAbbreviation", "Cant. final")),
      Render: ({ row }) => {
        if (!(get(row, 'inventory.availableQuantity')))
          return (<Loading className="icon-primary" />)

        return (
          <p className="h4 text-muted text-truncate styled-hover m-0 p-0" hovercontent={get(row, 'inventory.finalQuantity')}>
            {get(row, 'inventory.finalQuantity')}
          </p>
        )
      }
    },
    {
      header: capitalize(I18n.get("adjustedTotal", "Total Ajustado")),
      Render: ({ row }) => {
        const totalAdjusted = new BigNumber(get(row, 'unitCost', 0) * get(row, 'quantity') * (get(row, 'type') === 'in' ? 1 : -1)).toFormat(decimal, fmt);
        const {
          intPart: totalIntPart,
          decPart: totalDecPart
        } = styleBigNumber(totalAdjusted, decimal);
        return (
          <div className="d-flex justify-content-end h4 text-truncate styled-hover d-flex m-0 p-0"
            hovercontent={totalAdjusted}
          >
            <p className="text-truncate m-0">
              {totalIntPart}
            </p>
            <p className="text-truncate text-muted m-0">
              {totalDecPart}
            </p>
          </div>
        )
      },
      width: "20%",
    },
  ], [decimal, fmt]);

  const optimisticTotalCost = get(inventoryAdjustment, 'totalAdjusted');
  const bigNumber = new BigNumber(get(inventoryAdjustment, 'totalCostOnlyAdjustment', 0)).toFormat(decimal, fmt);

  const { intPart: totalIntPart, decPart: totalDecPart } = styleBigNumber(!!optimisticTotalCost ? optimisticTotalCost : bigNumber, decimal);

  return (
    <div className={`details${detailFullscreen ? "-full-screen" : ""}`}>
      <div className="details__options">
        {!detailFullscreen && (
          <div className="d-flex align-items-center justify-content-center pointer details__options-close-details"
            onClick={() => {
              setOpenDetail(false);
              setDetailFullscreen(false);
            }}>
            <CloseDetails className="mr-3" />
            <p className="h5 text-muted p-0 m-0">
              {I18n.get("closeDetail", "Cerrar detalle")}
            </p>
          </div>
        )}
        <div className={`d-flex align-items-center justify-content-center pointer ${detailFullscreen ? "" : "details__options-back"}`}
          onClick={() => {
            setOpenDetail(false);
            setDetailFullscreen(false);
          }}>
          <ArrowLeftSolid className="mr-3" />
          <p className="h5 text-muted p-0 m-0">
            {I18n.get("goBack", "Volver")}
          </p>
        </div>
        <div>
          <Tooltip
            tooltipActive={!can('edit', 'inventory-adjustments') || (!isInventoryAdjustmentInMemory && !isOnline)}
            overlay={(!isInventoryAdjustmentInMemory && !isOnline)
              ? (
                <p className="text-capitalize-first">
                  {I18n.get('needConnectionToEditAdjustment', 'Necesitas tener conexión para editar este ajuste.')}
                </p>
              ) : (
                <p className="text-capitalize-first">
                  {I18n.get('userNotAllowed.inventory-adjustments.edit', 'no tienes permisos para editar ajustes de inventario')}
                </p>
              )
            }
          >
            <button
              type="button"
              className="btn btn-transparent mr-2"
              disabled={!can('edit', 'inventory-adjustments')
                || !get(inventoryAdjustment, 'editable', true)
                || get(inventoryAdjustment, 'warehouse.id') !== get(stationWarehouse, 'id')
                || get(inventoryAdjustment, 'status') === 'offline'
                || (!isInventoryAdjustmentInMemory && !isOnline)
              }
              onClick={() => dispatch(checkFeatureLimit("inventoryAdjustments", () => openEdit()))}
              title={capitalize(I18n.get('edit', 'editar'))}
            >
              <Edit />
            </button>
          </Tooltip>
          <Tooltip
            tooltipActive={!can('delete', 'inventory-adjustments')}
            overlay={(
              <p className="text-capitalize-first">
                {I18n.get('userNotAllowed.inventory-adjustments.delete', 'no tienes permisos para eliminar ajustes de inventario')}
              </p>
            )}
          >
            <button
              type="button"
              className="btn btn-transparent mr-2"
              disabled={!can('delete', 'inventory-adjustments')
                || !get(inventoryAdjustment, 'deletable', true)
                || get(inventoryAdjustment, 'warehouse.id') !== get(stationWarehouse, 'id')
                || get(inventoryAdjustment, 'status') === 'offline'
              }
              title={capitalize(I18n.get('delete', 'eliminar'))}
              onClick={() => dispatch(checkFeatureLimit("inventoryAdjustments", () => setOpenConfirmationModal(true)))}
            >
              <Trash />
            </button>
          </Tooltip>
          {!detailFullscreen && (
            <button
              type="button"
              className="btn btn-transparent d-none d-lg-inline"
              onClick={() => setDetailFullscreen(true)}
            >
              <ExpandDetails />
            </button>
          )}
        </div>
      </div>

      <div className="d-flex flex-column details__body">
        <div className="mb-5">
          <p className="h1 fw-bold">
            {`${I18n.get("inventoryAdjustment", "Ajuste de inventario")} ${get(inventoryAdjustment, 'number')}`}
          </p>
          <p className="h4 text-muted">
            {`${I18n.get("createdAt", "Creado el")} ${!!get(inventoryAdjustment, 'date') ? dayjs(inventoryAdjustment.date).format(I18n.get("dateFormat", "YYYY/MM/DD")) : ""}`}
          </p>
        </div>

        <div className="details__body__info mb-5">
          <div>
            <p className="h4 text-muted mb-3">
              {I18n.get('warehouse', 'Bodega')}
            </p>
            <p className="h4 mb-3">
              {get(inventoryAdjustment, 'warehouse.name', "-")}
            </p>
          </div>
          <div>
            <p className="h4 text-muted mb-3">
              {I18n.get("observations", "Observaciones")}
            </p>
            <p className="h4 mb-3">
              {!!get(inventoryAdjustment, "observations") ? get(inventoryAdjustment, "observations") : "-"}
            </p>
          </div>
        </div>

        <MinimalTable
          title={capitalize(I18n.get("adjustedProducts", "Productos ajustados"))}
          columns={columns}
          data={isEmpty(mergedArray) ? inventoryAdjustment.items : mergedArray}
        />

      </div>
      <div className="details__footer">
        <div className="details__footer__limitator">
          <div className="h3 d-flex justify-content-end mt-5">
            {`${I18n.get("total", "Total")}: `}
            <p className="m-0 ml-1">
              {totalIntPart}
            </p>
            <p className="m-0 text-muted">
              {totalDecPart}
            </p>
          </div>
        </div>
      </div>
      <DeleteInventoryAdjustmentConfirmation
        isOpen={openConfirmationModal}
        onRequestClose={() => {
          dispatch(sendGTMEvent("inventory-adjustment-removal-attempted", {
            inventoryAdjustmentsNumber: 1,
            inventoryAdjustmentRemovalStatus: false,
          }))
          setOpenConfirmationModal(false)
        }}
        onConfirm={() => {
          setOpenConfirmationModal(false);
          setOpenDetail(false);
          mutate(
            {
              id: inventoryAdjustment.id,
              filters, inventoryAdjustment: inventoryAdjustment
            },
            {
              onSettled: async () => {
                await refresh('inventoryAdjustments');
              }
            }
          )
          dispatch(sendGTMEvent("inventory-adjustment-removal-attempted", {
            inventoryAdjustmentsNumber: 1,
            inventoryAdjustmentRemovalStatus: true,
          }))
        }
        }
        body={<p className="h4 mb-5">
          {replaceAndParse(I18n.get("deleteInventoryAdjustment.info", "Ten en cuenta que al eliminar el <b> ajuste de inventario No. {} </b> se borrará la variación de cantidades que había generado en tus productos."), [
            get(inventoryAdjustment, "number")
          ])}
        </p>}
        submitText={I18n.get("deleteInventoryAdjustment", "Eliminar ajuste")}
        modalTitle={I18n.get("deleteInventoryAdjustment.modalTitle", "Eliminar ajuste de inventario")}
      />
    </div>
  )
}

Details.propTypes = {
  inventoryAdjustment: PropTypes.object,
  setOpenDetail: PropTypes.func,
}

export default Details;
