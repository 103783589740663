import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react'
import { get } from 'lodash'

import reducer from './reducers'

const transformState = state => {
  return {
    app: {
      station: get(state, 'app.station'),
      offlineStatus: get(state, 'app.offlineStatus'),
    },
    user: {
      idGlobal: get(state, 'auth.idGlobal'),
      email: get(state, 'auth.email'),
      role: get(state, 'auth.role'),
      status: get(state, 'auth.status'),
      position: get(state, 'auth.position'),
      language: get(state, 'auth.language'),
    },
    company: {
      id: get(state, 'auth.company.id'),
      name: get(state, 'auth.company.name'),
      email: get(state, 'auth.company.email'),
      applicationVersion: get(state, 'auth.company.applicationVersion'),
      settings: get(state, 'auth.company.settings'),
      localSettings: get(state, 'auth.company.localSettings'),
      multitax: get(state, 'auth.company.multitax'),
      multicurrency: get(state, 'auth.company.multicurrency'),
    },
    activeInvoice: get(state, 'activeInvoice'),
    shifts: get(state, 'shifts'),
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state) => {
    scope.setUser({
      id: get(state, 'auth.company.id'),
      company: get(state, 'auth.company.name'),
      user_id: get(state, 'auth.idGlobal'),
      email: get(state, 'auth.email'),
    })

    scope.setTag('version', get(state, 'auth.company.applicationVersion'))

    scope.setExtra('state', transformState(state))
  },
  stateTransformer: state => transformState(state),
})

const state = (preloadedState = {}) => {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, }),
    devTools: process.env.REACT_APP_BRANCH !== 'master',
    preloadedState,
    enhancers: [sentryReduxEnhancer]
  })

  if (process.env.REACT_APP_BRANCH !== 'master' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(reducer))
  }

  return store;
}

export default state;