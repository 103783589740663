import { Field } from 'react-final-form';
import { renderField, renderSelect } from '../../forms/fields/V0/Fields';
import { capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';
import PropsTypes from "prop-types";

import Search from "../../svg/Search";
import DeleteSolid from "../../svg/DeleteSolid.svg";


const productStatusOptions = [
  {
    value: 'active',
    label: 'activeProducts',
    defaultLabel: 'Activos'
  },
  {
    value: 'inactive',
    label: 'inactiveProducts',
    defaultLabel: 'Inactivos'
  }
];

const FilterProducts = ({ resetFiltersAndClose }) => {
  return (
    <>
      <div className="action-buttons mb-4">
        <button
          type='submit'
          className='btn btn-action d-flex justify-content-center align-items-center mr-3'
        >
          <div className="mr-2"
          >
            <p className='h5 m-0 p-0'>
              {capitalize(I18n.get('search', 'Buscar'))}
            </p>
          </div>
          <Search className="icon-primary" />
        </button>
        <button
          type="button"
          className="btn btn-action d-flex justify-content-center align-items-center"
          onClick={() => resetFiltersAndClose()}
        >
          <div className="mr-2"
          >
            <img src={DeleteSolid} alt="eliminar" />
          </div>
          <p className='h5 m-0 p-0'>
            {capitalize(I18n.get('close', 'Cerrar'))}
          </p>
        </button>
      </div>
      <div className='form-filter'>
        <div className='form-filter__fields'>
          <Field
            name="name"
            className="col-4"
            component={renderField}
            type="text"
            placeholder={capitalize(I18n.get("name", "Nombre"))}
          />
          <Field
            name="reference"
            className="col-4"
            component={renderField}
            type="text"
            placeholder={capitalize(I18n.get("reference", "Referencia"))}
          />
          <Field
            name="price"
            className="col-4 ml-6"
            component={renderField}
            type="number"
            placeholder={capitalize(I18n.get("price", "Precio"))}
          />
        </div>
        <div className='form-filter__selects'>
          <Field
            name="status"
            className="col-6 mr-0 pr-0"
            component={renderSelect}
            height={"3rem"}
            fontSize={"12px"}
            placeholder={capitalize(I18n.get("actions", "Acciones"))}
            options={productStatusOptions}
            getOptionLabel={option => capitalize(I18n.get(option.label, option.defaultLabel))}
            getOptionValue={option => option.value}
          />
        </div>
      </div>
    </>
  )
}

FilterProducts.propTypes = {
  resetFilters: PropsTypes.func,
}

export default FilterProducts