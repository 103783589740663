// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M7.796 17.375a3.001 3.001 0 0 1 5.658 0h6.171a1 1 0 1 1 0 2h-6.17a3.001 3.001 0 0 1-5.66 0h-2.17a1 1 0 1 1 0-2h2.17zm6-6a3.001 3.001 0 0 1 5.658 0h.171a1 1 0 1 1 0 2h-.17a3.001 3.001 0 0 1-5.66 0h-8.17a1 1 0 1 1 0-2h8.17zm-7-6a3.001 3.001 0 0 1 5.658 0h7.171a1 1 0 1 1 0 2h-7.17a3.001 3.001 0 0 1-5.66 0h-1.17a1 1 0 0 1 0-2h1.17zm2.829 3a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm7 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-6 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
    />
  </svg>
)