// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33335 0.166668C1.05721 0.166668 0.833354 0.390526 0.833354 0.666668C0.833354 0.94281 1.05721 1.16667 1.33335 1.16667H6.12625L0.313134 6.97978C0.117871 7.17504 0.117871 7.49163 0.313134 7.68689C0.508396 7.88215 0.824978 7.88215 1.02024 7.68689L6.83335 1.87377V6.66667C6.83335 6.94281 7.05721 7.16667 7.33335 7.16667C7.6095 7.16667 7.83335 6.94281 7.83335 6.66667V0.666668C7.83335 0.529136 7.77783 0.404573 7.68797 0.314176L7.68691 0.313115L7.68584 0.312056C7.63815 0.264643 7.58328 0.228827 7.52474 0.204607C7.46579 0.180161 7.40115 0.166668 7.33335 0.166668H1.33335Z"
      fill="#0F172A"
    />
  </svg>
);