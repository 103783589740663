import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';

import Contact from '../../forms/contact/Contact';
import CarretSVG from '../../svg/CarretDown';

const FormBody = props => { 
  const [section, setSection] = useState('main')

  return (
    <>
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <div 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('main')}
        >
          {I18n.get('generalInfo', 'información general')}
          {<CarretSVG className="icon-primary" />}
        </div>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('client.generalInfoSubtitles', 'Ingresa la información principal de tu cliente')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom ${section === 'main'? 'show': ''}`}>
          <Contact.BasicData {...props}/>
        </div>
      </div>
      
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <p 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('advanced')}
        >
          {I18n.get('client.advancedOptions', 'opciones avanzadas')}
          {<CarretSVG className="icon-primary" />}
        </p>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('client.advancedOptionsSubtitles', 'puedes configurar los datos tributarios de tu cliente, plazo de pago, vendedor y lista de precio')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom ${section === 'advanced'? 'show': ''}`}>
          <Contact.AdvancedData {...props}/>
        </div>
      </div>
      
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <p 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('accounting')}
        >
          {I18n.get('accountingOptions', 'configuración contabilidad')}
          {<CarretSVG className="icon-primary" />}
        </p>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('client.accountingOptionsSubtitles', 'Elige la cuenta contable en la que se registrarán las cuentas por cobrar de tus ventas a crédito')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden ${section === 'accounting'? 'show': ''}`}>
          <Contact.Accounting />
        </div>
      </div>
    </>
  )
}

export default FormBody;
