import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { openModal } from '../../../reducers/modals';
import { hasPermissionTo } from '../../../selectors/auth';
import { companySelector, country as countrySelector } from '../../../selectors/company';
import Tooltip from '../../common/Tooltip';
import ListSVG from '../../svg/List';
import Typography from '../../common/Typography';
import RefundIn from '../../svg/new/RefundIn';
import Email from '../../svg/new/Email';
import Print from '../../svg/new/Print';
import { setPrint, setTypeToPrint } from '../../../reducers/print';
import { isUserElegibleForNewRefund } from '../utils';

const DetailHeader = ({ refund, onListView }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)

  if (!refund) return null;

  useEffect(() => {
    if (!!refund)
      dispatch(setPrint({ type: 'refund', value: refund }))
  }, [refund, dispatch])

  const getNumber = () => {
    const valuePrefix = I18n.get('number', 'número');

    const number = get(refund, 'numberTemplate.number', null);
    const fullNumber = get(refund, 'numberTemplate.fullNumber', null);
    const prefix = !!get(refund, 'numberTemplate.prefix', null)
      ? get(refund, 'numberTemplate.prefix', null) : '';

    if (!!fullNumber)
      return `${valuePrefix || ''} ${fullNumber || ''}`

    return `${valuePrefix || ''} ${prefix || ''}${number || ''}`
  }

  const getRefundTitle = () => {
    const numberTemplate = get(refund, 'numberTemplate');
    if (!numberTemplate)
      return I18n.get('refund.creditNote');
    if (numberTemplate.documentType !== "creditNote")
      return I18n.get('refund.adjustmentNote')
    else
      return I18n.get('refund.creditNote');

  }

  return (
    <>
      <div className="d-flex justify-content-end pb-5 new-refund-button-container">
        <Tooltip
          tooltipActive={!can('add', 'credit-notes')}
          overlay={(
            <p className="text-capitalize-first">
              {I18n.get('userNotAllowed.credit-notes.add', 'no tienes permisos para agregar devoluciones')}
            </p>
          )}
        >
          <button
            type="button"
            disabled={!can('add', 'credit-notes')}
            className="btn btn-submit d-none d-sm-block new-refund-button"
            onClick={() => {
              isUserElegibleForNewRefund(country, company)
                ? dispatch(openModal({ modal: 'newRefunds' }))
                : dispatch(openModal({ modal: 'refunds' }))
            }}
          >
            {I18n.get('newRefund', 'nueva devolución')}
          </button>
        </Tooltip>
      </div>
      <div className='px-4 pt-4 new-refund-header'>
        <div className="d-flex justify-content-between align-items-center new-refund-header">
          <Typography
            htmlType="h2"
            type="body-2-bold title"
            variant="primary"
            text={
              <>
                <RefundIn />
                <span className="title-main">
                  {getRefundTitle()}
                </span>
                <span className="title-secondary">
                  {getNumber()}
                </span>
              </>} />
          <div className="new-refund-header_buttons d-none d-sm-block">
            <button
              className="btn btn-action">
              <Email className="icon-black" />
              {I18n.get("send", "enviar")}
            </button>
            <button
              onClick={() => dispatch(setTypeToPrint('refund'))}
              className="btn btn-action">
              <Print className="icon-black" />
              {I18n.get("print", "imprimir")}
            </button>
          </div>
          <div className="actions d-flex align-items-center d-block d-sm-none">
            <div className="w-100 h-25 d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-submit d-block d-sm-none"
                onClick={() => onListView()}
              >
                <ListSVG className="icon-white" />
              </button>
            </div>
          </div>
        </div>
        <div className="new-refund-header_buttons d-sm-none d-block">
          <button
            className="btn btn-action">
            <Email className="icon-black" />
            {I18n.get("send", "enviar")}
          </button>
          <button
            onClick={() => dispatch(setTypeToPrint('refund'))}
            className="btn btn-action">
            <Print className="icon-black" />
            {I18n.get("print", "imprimir")}
          </button>
        </div>
      </div>
    </>
  )
}

DetailHeader.propTypes = {
  refund: PropTypes.object,
  onListView: PropTypes.func
}

export default DetailHeader;