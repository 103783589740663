import React from 'react'
import { I18n } from '@aws-amplify/core';
import Button from '../../common/Button';

const Bottom = ({ onConfirm, onRequestClose, actionMessage }) => {
  return (
    <div className="modal__bottom h-100">
      <button
        type="button"
        className="w-100 btn-action text-capitalize-first"
        onClick={() => onRequestClose()}
      >
        {I18n.get("cancel", "Cancelar")}
      </button>
      <Button
        type="button"
        size='sm'
        variant='filled'
        buttonType='default'
        onClick={() => onConfirm()}
      >
        {actionMessage}
      </Button>
    </div>
  )
}

export default Bottom;