// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={`icon-primary ${className}`}
    width="48" 
    height="48" 
    viewBox="0 0 25 22" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" clipRule="evenodd"
      d="M 12.8641 0.344021 C 12.63 0.252514 12.37 0.252514 12.1359 0.344021 L 0.802612 4.77425 C 0.419097 4.92416 0.166687 5.29384 0.166687 5.70562 V 5.95128 C 0.166687 6.50357 0.614402 6.95128 1.16669 6.95128 H 23.8334 C 24.3856 6.95128 24.8334 6.50357 24.8334 5.95128 V 5.70562 C 24.8334 5.29384 24.5809 4.92416 24.1974 4.77425 L 12.8641 0.344021 Z M 2.76318 9.89652 C 2.76318 9.34424 3.21089 8.89652 3.76318 8.89652 H 5.65792 C 6.2102 8.89652 6.65792 9.34424 6.65792 9.89652 V 15.7839 C 6.65792 16.3362 6.2102 16.7839 5.65792 16.7839 H 3.76318 C 3.21089 16.7839 2.76318 16.3362 2.76318 15.7839 V 9.89652 Z M 10.5527 9.89652 C 10.5527 9.34424 11.0004 8.89652 11.5527 8.89652 H 13.4474 C 13.9997 8.89652 14.4474 9.34424 14.4474 9.89652 V 15.7839 C 14.4474 16.3362 13.9997 16.7839 13.4474 16.7839 H 11.5527 C 11.0004 16.7839 10.5527 16.3362 10.5527 15.7839 V 9.89652 Z M 0.166687 20.6666 C 0.166687 21.2189 0.614402 21.6666 1.16669 21.6666 H 23.8334 C 24.3856 21.6666 24.8334 21.2189 24.8334 20.6666 V 20.0375 C 24.8334 19.4852 24.3856 19.0375 23.8334 19.0375 H 1.16669 C 0.614402 19.0375 0.166687 19.4852 0.166687 20.0375 V 20.6666 Z M 18.3421 9.89652 C 18.3421 9.34424 18.7898 8.89652 19.3421 8.89652 H 21.2369 C 21.7891 8.89652 22.2369 9.34424 22.2369 9.89652 V 15.7839 C 22.2369 16.3362 21.7891 16.7839 21.2369 16.7839 H 19.3421 C 18.7898 16.7839 18.3421 16.3362 18.3421 15.7839 V 9.89652 Z M 12.1359 2.91311 C 12.37 2.8216 12.63 2.8216 12.8641 2.91311 L 15.4012 3.90485 C 15.511 3.94781 15.5834 4.05372 15.5834 4.17169 C 15.5834 4.32992 15.4551 4.45819 15.2969 4.45819 H 9.70318 C 9.54496 4.45819 9.41669 4.32992 9.41669 4.17169 C 9.41669 4.05372 9.489 3.94781 9.59888 3.90485 L 12.1359 2.91311 Z"
    />
  </svg>
)