import React from 'react'
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { renderSelect } from '../fields/V0/Fields';
import { getElectronicNumerations } from '../../../selectors/numerations'
import { stations as stationsSelector } from '../../../selectors/station';
import { station as stationSelector } from '../../../selectors/app';

const StationSetting5UVT = ({ values, ...props }) => {
  const numerations = useSelector(getElectronicNumerations)
  const stations = useSelector(stationsSelector);
  const currentStation = useSelector(stationSelector);

  return (
    <div className="form-body__fields form-row justify-content-between">
      <>
        {stations.map((station, index) => (
          <Field
            name={'k' + station.id}
            key={index}
            component={renderSelect}
            className={`col-6 ${index % 2 === 0 ? 'pr-4' : 'pl-4'}`}
            options={numerations}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            addOptionText={`+ ${capitalize(I18n.get('newNumeration', 'Nueva numeración'))}`}
            addOptionAction={() => window.open(`${process.env.REACT_APP_ALEGRA}resolution`, '_blank')}
            label={capitalize(I18n.get('feNumeration', 'Numeración de facturas electrónicas'))}
            headText={currentStation.id === station.id ? "Terminal actual" : station.name}
            menuPosition="absolute"
            validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
            required
          />
        ))
        }
      </>
    </div>
  )
}

export default StationSetting5UVT;