import db from './database'
import _, { deburr, isNull, isEmpty, isUndefined } from 'lodash'

export const bulkPut = async (contacts, callback) => {
  await db.contacts.bulkPut(contacts)

  if (!!callback) callback()
}

export const put = async (contact, callback) => {
  await db.contacts.put({ ...contact })
  if (!!callback)
    callback();
}

export const total = () => db.contacts.count()

export const totalOffline = () => db.contacts.filter(client => !!client.offline).count()

export const get = async ({ start, limit }) => {
  try {
    return await db.contacts
      .offset(start)
      .limit(limit)
      .toArray()
  } catch {
    return [];
  }
}

export const getAll = async () => {
  try {
    return await db.contacts.toArray()
  } catch {
    return [];
  }
}

export const getAllOffline = async () => {
  try {
    return await db.contacts.filter(client => !!client.offline).toArray()
  } catch {
    return [];
  }
}

export const getByOfflineId = async offlineId => {
  try {
    if (offlineId) {
      const contacts = await db.contacts
        .filter(contact => contact.offlineId === offlineId)
        .toArray();
      return contacts.length > 0 ? contacts[0] : {};
    }

    return {};
  } catch {
    return {};
  }
}

export const getClientById = async clientId => {
  try {
    if (clientId) {
      const client = await db.contacts.get(clientId)
      return client
    }
  } catch {
    return {};
  }
}

const hasIdentification = ({ identification: id }, text) =>
  !(isNull(id) || isEmpty(id) || isUndefined(id) || id.toString().toLowerCase().indexOf(text) === -1)

export const filter = async ({ text, limit }) => {
  try {
    return await db.contacts
      .orderBy('name')
      .filter(contact => {
        const contactName = deburr(contact.name.toLowerCase());
        return contact.status === 'active' && (contactName.indexOf(deburr(text.toLowerCase())) !== -1 || hasIdentification(contact, text))
      })
      .limit(limit)
      .toArray();
  } catch (error) {
    return []
  }
}

export const clear = () => db.contacts.clear()

export const update = async (id, changes, callback) => {
  await db.contacts.update(id, changes)
  if (callback)
    callback();
}

export const remove = id => db.contacts.delete(id)

export const getPOSClient = (country) => {
  switch (country) {
    case 'costaRica':
      return db.contacts.where('[name+identification]')
        .equals(['Cliente de contado', '100000000']).first()
    case 'peru':
      return db.contacts.where('[name+identification]')
        .equals(['Cliente varios', '00000000']).first()
    case 'colombia':
      return db.contacts.where('[name+identification]')
        .equals(['Consumidor final', '222222222222']).first()
    case 'mexico':
      return db.contacts.where('[name+identification]')
        .equals(['Público en General', 'XAXX010101000']).first()
    case 'argentina':
      return db.contacts.where('[name+identification]')
        .equals(['Consumidor final', '1']).first()
    case 'republicaDominicana':
      return db.contacts.where('name')
        .equals('Consumidor final').first()
    case 'panama':
      return db.contacts.where('name')
        .equals('Consumidor final').first()
    case 'spain':
      return db.contacts.where('name')
        .equals('Público en General').first()
    default:
      return db.contacts.where('name').equals('POS').first()
  }
}

export const bulkDelete = (keys) => db.contacts.bulkDelete(keys)

export const searchIdentification = (identification, country) => {
  try {
    if (country === 'colombia' || country === 'costaRica' || country === 'argentina') {
      if (_.get(identification, 'type', null) && _.get(identification, 'number', null)) {
        return db.contacts
          .filter(contact => {
            const eqType = _.get(identification, 'type') === _.get(contact, 'identificationObject.type', null);
            const eqNumber = _.get(identification, 'number') === _.get(contact, 'identificationObject.number', null);
            return eqType && eqNumber;
          })
          .first();
      }
    }
    else if (country === 'republicaDominicana') {
      if(_.get(identification, 'number', null)) {
        return db.contacts
        .filter((contact) => {
            const eqNumber = _.get(identification, 'number') === (_.get(contact, 'identification.number', null) || _.get(contact, 'identificationObject.number', null));
            return  eqNumber;
          })
          .first()
      }
    }
    else if (country === 'peru') {
      if (_.get(identification, 'type', null) && _.get(identification, 'number', null)) {
        return db.contacts
          .filter(contact => {
            const eqType = _.get(identification, 'type') === (_.get(contact, 'identification.type', null) || _.get(contact, 'identificationObject.type', null));
            const eqNumber = _.get(identification, 'number') === (_.get(contact, 'identification.number', null) || _.get(contact, 'identificationObject.number', null));
            return eqType && eqNumber;
          })
          .first()
      }
    }
    else {
      if (!!identification) {
        return db.contacts
          .filter(contact => {
            return identification === _.get(contact, 'identification', null);
          })
          .first()
      }
    }
    return Promise.resolve();
  } catch {
    return Promise.resolve();
  }
}
