// eslint-disable-next-line
export default ({ id, className, width, height, fill }) => (
  <svg 
    id={id}
    className={className}
    width={width || "24"} 
    height={height || "24"} 
    viewBox="0 0 24 28" 
    fill={fill || "none"} 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M20 7.73l-3.723-3.722-9.574 9.577 3.723 3.722L20 7.73zM4 19.95l.521-4.186 3.726 3.725-4.247.46z"
    />
  </svg>
)