// eslint-disable-next-line
export default ({ id, className, fill, width, height }) => (
  <svg
    id={id}
    className={className}
    width={width || "24"}
    height={height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M16.559 10.594 11.285 3.563c-.233-.311-.674-.374-.984-.141-.311.233-.374.674-.141.984l4.957 6.609L10.16 17.625c-.233.311-.17.751.141.984.126.095.274.141.421.141.214 0 .425-.097.563-.281l5.273-7.031C16.746 11.187 16.746 10.844 16.559 10.594z" fill={fill} />
  </svg>
);
