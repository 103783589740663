import React from 'react';

import renderCommom from './AdvancedData/CommomFields';
import renderStatementAttached from './AdvancedData/StatementAttached';
import renderFiscalResponsabilities from './AdvancedData/FiscalResponsabilities';

const AdvancedData = props => {
  return (
    <div className="form-fields form-row justify-content-start">
      {renderCommom(props)}

      {renderStatementAttached(props)}

      {renderFiscalResponsabilities(props)}
    </div>
  )
}

export default AdvancedData;
