import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs'
import { get, capitalize } from 'lodash'
import { BigNumber } from 'bignumber.js'
import Loader from 'react-content-loader';

import { languageSelector } from '../../../selectors/auth'
import { decimalPrecision } from '../../../selectors/company'
import Select from '../../common/Select'

const calculateValues = (data, decimal) => {
  return data.map(shift => {
    const cashIn = new BigNumber(0)
      .plus(get(shift, 'cash', 0))
      .plus(get(shift, 'debit', 0))
      .plus(get(shift, 'credit', 0))
      .plus(get(shift, 'transfer', 0))
      .plus(get(shift, 'inPayment', 0))
      .decimalPlaces(decimal)
    const cashOut = new BigNumber(0)
      .plus(get(shift, 'outPayment', 0))
      .plus(get(shift, 'refunds', 0))
      .decimalPlaces(decimal)

    return {
      cashIn: cashIn,
      cashOut: cashOut,
      diff: cashIn.minus(cashOut).decimalPlaces(decimal)
    }
  }).reduce((prev, curr) => ({
    cashIn: prev.cashIn.plus(curr.cashIn),
    cashOut: prev.cashOut.plus(curr.cashOut),
    diff: prev.diff.plus(curr.diff),
  }), {
    cashIn: new BigNumber(0),
    cashOut: new BigNumber(0),
    diff: new BigNumber(0),
  })
}

const textLoader = () => (
  <Loader title="" height={16} width={72}>
    <rect width='100%' height='100%' x="0" y="0" rx="5" ry="5" />
  </Loader>
)

let Header = ({ startDate, endDate, setStartDate, setEndDate, data, loading, error }) => {
  const [selectValue, setSelectValue] = useState({ value: 'week', label: capitalize(I18n.get('weekly', 'semanal')) });

  const decimal = useSelector(decimalPrecision)
  let language = useSelector(languageSelector)
  language = language.slice(0, 2);

  const shiftsValues = calculateValues(data, decimal)

  return (
    <div className="card col-12 p-3">
      <div className="d-flex form-row">
        <div className="col-md-2 col-sm-4 py-3 d-flex flex-column text-md-left">
          <p className="text-capitalize-first h4 text-title">
            {I18n.get('periods', 'períodos')}
          </p>
          <Select
            value={selectValue}
            options={[
              { value: 'week', label: capitalize(I18n.get('week', 'semana')) },
              { value: 'month', label: capitalize(I18n.get('month', 'mes')) },
              { value: 'quarter', label: capitalize(I18n.get('quater', 'trimestre')) },
              { value: -1, label: capitalize(I18n.get('custom', 'personalizado')) },
            ]}
            onChange={value => {
              if (value.value !== -1) {
                setEndDate(dayjs().toDate())
                setStartDate(dayjs().subtract(1, value.value).toDate())
              }
              setSelectValue(value)
            }}
            hideDropdownIndicator
            isSearchable={false}
            height={'3rem'}
            menuPosition='fixed'
          />
        </div>
        <div className="col-md-2 col-sm-4 py-3 d-flex flex-column text-md-left">
          <p className="text-capitalize-first h4 text-title">
            {I18n.get('from', 'desde')}
          </p>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setSelectValue({ value: -1, label: capitalize(I18n.get('custom', 'personalizado')) })
              setStartDate(date)
            }}
            dateFormat={I18n.get('dateFormatDateFNS', 'dd/MM/yyyy')}
            locale={language}
          />
        </div>
        <div className="col-md-2 col-sm-4 py-3 d-flex flex-column text-md-left">
          <p className="text-capitalize-first h4 text-title">
            {I18n.get('to', 'hasta')}
          </p>
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setSelectValue({ value: -1, label: capitalize(I18n.get('custom', 'personalizado')) })
              setEndDate(date)
            }
            }
            dateFormat={I18n.get('dateFormatDateFNS', 'dd/MM/yyyy')}
            locale={language}
          />
        </div>
        <div className="col-md-2 col-sm-4 py-3 d-flex flex-column text-center">
          <p className="text-capitalize-first h4 text-title">
            {I18n.get('periodIncome', 'ingresos del período')}
          </p>
          <p className="h3 py-2 text-primary">
            {!!loading
              ? textLoader()
              : !!error
                ? new BigNumber(0).toFormat(decimal)
                : shiftsValues.cashIn.toFormat(decimal)
            }
          </p>
        </div>
        <div className="col-md-2 col-sm-4 py-3 d-flex flex-column text-center">
          <p className="text-capitalize-first h4 text-title">
            {I18n.get('periodExpense', 'gastos del período')}
          </p>
          <p className="h3 py-2 text-danger">
            {!!loading
              ? textLoader()
              : !!error
                ? new BigNumber(0).toFormat(decimal)
                : shiftsValues.cashOut.toFormat(decimal)
            }
          </p>
        </div>
        <div className="col-md-2 col-sm-4 py-3 d-flex flex-column text-center">
          <p className="text-capitalize-first h4 text-title">
            {I18n.get('periodDifference', 'diferencia')}
          </p>
          <p className="h3 py-2 text-primary">
            {!!loading
              ? textLoader()
              : !!error
                ? new BigNumber(0).toFormat(decimal)
                : shiftsValues.diff.toFormat(decimal)
            }
          </p>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

export default Header;