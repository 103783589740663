import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { initApp, loadData, setStation } from '../reducers/app';
import { openModal } from '../reducers/modals';
import {
  error as errorSelector,
  loading as loadingSelector,
  station as stationSelector,
} from '../selectors/app';
import { stations as stationsSelector } from '../selectors/station';
import { seenTutorialWizard, seenWelcome } from '../selectors/onboarding';
import { canUseStation as canUseStationSelector } from '../selectors/permissions';
import Loading from '../components/svg/Loading';
import Select from '../components/common/Select';
import Print from '../components/print/Print';
import Subscriptions from '../subscriptions';
import Header from './header/Header';
import Notifications from './notifications/Notifications';
import Navigation from './navigation/Navigation';
import Onboarding from './onboarding/Onboarding';
import SideModals from './sideModals';
import Modals from './modals';
import Tours from './tours';
import Main from './Main';
import LoadError from './error/LoadError';
import NoStationsError from './error/NoStationsError';
import NoStationPermissionError from './error/NoStationPermissionError';
import { usePageTracking } from '../hooks/usePageTracking';

function App() {
  usePageTracking();
  const dispatch = useDispatch();
  const history = useHistory();
  const dataLoaded = useRef(false);
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const selectedStation = useSelector(stationSelector);
  const stations = useSelector(stationsSelector);
  const tutorialWizardModal = useSelector(seenTutorialWizard);
  const seenWelcomeModal = useSelector(seenWelcome);
  const canUseStation = useSelector(canUseStationSelector);

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !!selectedStation && !dataLoaded.current) {
      dispatch(loadData());
      dataLoaded.current = true;
    }
  }, [loading, selectedStation, dispatch]);

  useEffect(() => {
    if (!loading && !!selectedStation && !tutorialWizardModal)
      history.replace('/onboarding');
  }, [loading, selectedStation, tutorialWizardModal, history]);

  useEffect(() => {
    if (
      !loading &&
      !!selectedStation &&
      !!tutorialWizardModal &&
      !seenWelcomeModal &&
      history.location.pathname !== '/onboarding'
    )
      dispatch(openModal({ modal: 'welcome' }));
  }, [
    loading,
    selectedStation,
    tutorialWizardModal,
    seenWelcomeModal,
    history,
    dispatch,
  ]);

  if (!loading && !!error) {
    return <LoadError />;
  }

  if (!loading && !!selectedStation && !canUseStation)
    return <NoStationPermissionError stationsQty={stations.length} />;

  if (!loading && !!selectedStation) {
    return (
      <>
        <div className='app d-flex flex-column'>
          <Switch>
            <Route path='/onboarding' component={Onboarding} />
            <Route>
              {pathname !== '/plan/plans' && pathname !== '/plan/checkout' && (
                <Notifications />
              )}
              <Header />
              <Main />
            </Route>
          </Switch>

          <Navigation />
          <SideModals />
          <Modals />
          <Tours />
        </div>

        <Print />

        <Subscriptions />

        <ToastContainer
          limit={5}
          position='top-center'
          hideProgressBar
          autoClose={5000}
        />
      </>
    );
  }

  if (!loading && stations.length === 1) dispatch(setStation(stations[0]));

  if (!loading && stations.length > 1) {
    return (
      <Form onSubmit={({ station }) => dispatch(setStation(station))}>
        {({ handleSubmit, valid }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className='app d-flex justify-content-center align-items-center'
          >
            <div className='card app-select-station rounded p-5'>
              <h2 className='text-primary mb-4'>
                {capitalize(
                  I18n.get(
                    'selectAStationHeadline',
                    'Selecciona dónde hacer tus operaciones'
                  )
                )}
              </h2>

              <Field
                name='station'
                validate={(value) => (!value ? 'required' : undefined)}
                render={(props) => (
                  <Select
                    {...props.input}
                    className='mb-4'
                    options={stations}
                    placeholder={capitalize(
                      I18n.get('selectAStation', 'selecciona una terminal')
                    )}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />

              <div className='w-100'>
                <button
                  type='submit'
                  className='btn btn-submit float-right'
                  data-testid='select-station-submit-btn'
                  disabled={!valid}
                >
                  {I18n.get('start', 'iniciar')}
                </button>
              </div>
            </div>
          </form>
        )}
      </Form>
    );
  }

  if (!loading && !stations.length) {
    return <NoStationsError />;
  }

  return (
    <div className='app d-flex justify-content-center align-items-center'>
      <Loading className='icon-primary icon x4' />
    </div>
  );
}

export default Sentry.withProfiler(App);
