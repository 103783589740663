import React, { useState } from "react";
import { I18n } from "@aws-amplify/core";

import Modal from "../../../../common/Modal";
import BackgroundSVG from "../../../../svg/header/AccountingApp.svg";
import Loading from "../../../../svg/Loading";

const AccountingModal = ({ isOpen, onClose, onRedirect }) => {
  const [loaded, setIsLoaded] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal__products-onboarding"
    >
      <div className="p-4 pb-5 d-flex h-100 overflow-hidden">
        {!loaded ? (
          <div
            className="d-flex justify-content-center align-items-center col-12"
            style={{ height: "300px" }}
          >
            <Loading className="icon-primary" />
          </div>
        ) : (
          <div className="body-content col-sm-7 col-12 d-flex flex-column justify-content-center py-md-4">
            <h1 className="text-primary mb-5">
              {I18n.get(
                "onboarding.accounting.header",
                "¡Dale superpoderes a tu empresa!"
              )}
            </h1>
            <p className="mb-5">
              {I18n.get("onboarding.accounting.message.1", "Obtén una")}{" "}
              <b>
                {I18n.get(
                  "onboarding.accounting.message.2",
                  "visión completa de toda la información"
                )}
              </b>{" "}
              {I18n.get(
                "onboarding.accounting.message.3",
                "de tu negocio con Alegra Contabilidad, gestiona tus compras, ventas e inventario y además genera reportes inteligentes."
              )}
            </p>
            <div className="">
              <a
                className="btn btn-primary px-4 text-uppercase"
                href={process.env.REACT_APP_ALEGRA}
                target="_blank"
                rel="noreferrer"
                onClick={onRedirect}
              >
                {I18n.get(
                  "onboarding.accounting.button",
                  "Probar 15 días gratis"
                )}
              </a>
            </div>
          </div>
        )}
        <div className="col-sm-5 col-0">
          <img
            src={BackgroundSVG}
            style={loaded ? {} : { display: "none" }}
            onLoad={() => {
              setIsLoaded(true);
            }}
            alt=""
          />
        </div>
      </div>
    </Modal>
  );
};

export default AccountingModal;
