// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.53614 4.42317L8 8.06574L13.4639 4.42317C13.3597 4.08172 13.0422 3.83333 12.6667 3.83333H3.33333C2.95781 3.83333 2.6403 4.08172 2.53614 4.42317ZM13.5 5.60093L8.27735 9.08269C8.1094 9.19466 7.8906 9.19466 7.72265 9.08269L2.5 5.60093V11.3333C2.5 11.7936 2.8731 12.1667 3.33333 12.1667H12.6667C13.1269 12.1667 13.5 11.7936 13.5 11.3333V5.60093ZM1.5 4.66667C1.5 3.65414 2.32081 2.83333 3.33333 2.83333H12.6667C13.6792 2.83333 14.5 3.65414 14.5 4.66667V11.3333C14.5 12.3459 13.6792 13.1667 12.6667 13.1667H3.33333C2.32081 13.1667 1.5 12.3459 1.5 11.3333V4.66667Z" fill="#334155" />
  </svg>
);