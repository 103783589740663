import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';

import Item from '../../forms/item/Item';
import CarretSVG from '../../svg/CarretDown';

const FormBody = props => { 
  const [section, setSection] = useState('main')

  return (
    <>
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <div 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('main')}
        >
          {I18n.get('generalInfo', 'información general')}
          {<CarretSVG className="icon-primary" />}
        </div>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('item.generalInfoSubtitles', 'Indica los datos básicos de tu producto o servicio')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom ${section === 'main'? 'show': ''}`}>
          <Item.BasicData {...props}/>
        </div>
      </div>

      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <p 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('advanced')}
        >
          {I18n.get('item.advancedOptions', 'opciones avanzadas')}
          {<CarretSVG className="icon-primary" />}
        </p>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('item.advancedOptionsSubtitles', 'Agrega el código de barras y la descripción de tus productos')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom ${section === 'advanced'? 'show': ''}`}>
          <Item.AdvancedData {...props}/>
        </div>
      </div>
      
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <p 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('priceList')}
        >
          {I18n.get('item.priceListOptions', 'Listas de precios')}
          {<CarretSVG className="icon-primary" />}
        </p>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('item.priceListOptionsSubtitles', 'Agrega diferentes precios a tus productos')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden border-bottom ${section === 'priceList'? 'show': ''}`}>
          <Item.PriceLists {...props}/>
        </div>
      </div>
      
      <div className="d-flex flex-column my-1 px-4 pt-4 pb-0">
        <p 
          className="h4 text-primary text-capitalize-first pointer"
          onClick={() => setSection('accounting')}
        >
          {I18n.get('item.accountingOptions', 'Configuración contable')}
          {<CarretSVG className="icon-primary" />}
        </p>
        <p className="h5 text-muted text-capitalize-first">
          {I18n.get('item.accountingOptionsSubtitles', 'Elige la cuenta en donde se registrarán los ingresos de este producto')}
        </p>

        <div className={`section-collapse form-body w-100 p-3 overflow-hidden ${section === 'accounting'? 'show': ''}`}>
          <Item.Accounting />
        </div>
      </div>
    </>
  )
}

export default FormBody;
