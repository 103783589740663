// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="40" 
    height="40" 
    viewBox="0 0 40 40" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#FEF3C7"/>
    <path d="M20.0002 17V19V17ZM20.0002 23V23.01V23ZM13.0002 27H27.0002C27.3265 26.9977 27.6473 26.9156 27.9346 26.7609C28.2219 26.6061 28.4669 26.3835 28.6484 26.1123C28.8299 25.841 28.9422 25.5296 28.9756 25.205C29.0091 24.8804 28.9626 24.5525 28.8402 24.25L21.7402 12C21.5672 11.6874 21.3137 11.4268 21.0059 11.2454C20.6982 11.064 20.3474 10.9683 19.9902 10.9683C19.6329 10.9683 19.2822 11.064 18.9744 11.2454C18.6667 11.4268 18.4131 11.6874 18.2402 12L11.1402 24.25C11.0201 24.5456 10.9725 24.8656 11.0013 25.1833C11.0302 25.501 11.1346 25.8073 11.3058 26.0764C11.4771 26.3456 11.7103 26.5698 11.9859 26.7305C12.2615 26.8912 12.5716 26.9836 12.8902 27" fill="#FBBF24"/>
    <path d="M20.0002 17V19M20.0002 23V23.01M13.0002 27H27.0002C27.3265 26.9977 27.6473 26.9156 27.9346 26.7609C28.2219 26.6061 28.4669 26.3835 28.6484 26.1123C28.8299 25.841 28.9422 25.5296 28.9756 25.205C29.0091 24.8804 28.9626 24.5525 28.8402 24.25L21.7402 12C21.5672 11.6874 21.3137 11.4268 21.0059 11.2454C20.6982 11.064 20.3474 10.9683 19.9902 10.9683C19.6329 10.9683 19.2822 11.064 18.9744 11.2454C18.6667 11.4268 18.4131 11.6874 18.2402 12L11.1402 24.25C11.0201 24.5456 10.9725 24.8656 11.0013 25.1833C11.0302 25.501 11.1346 25.8073 11.3058 26.0764C11.4771 26.3456 11.7103 26.5698 11.9859 26.7305C12.2615 26.8912 12.5716 26.9836 12.8902 27" stroke="#FBBF24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20 17V19M20 23V23.01" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>  
  </svg>
)
