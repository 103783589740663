// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.25 10.125h13.625v2.754H5.25z" />
  </svg>
)