import React from 'react'
import { I18n } from 'aws-amplify';
import { Field } from 'react-final-form';
import { get } from 'lodash';
import { renderField } from '../../forms/fields/V0/Fields';
import Label from '../../common/Label';

const CompanySettings = (props) => {
  const companyIsElectronic = get(props, 'values.settings.electronicInvoicing', false);
  return (
    <div className="w-100">
      <div className="form-row" style={{ margin: "0 -1.5rem" }}>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('identification', 'identificación')}
            required={companyIsElectronic} />
          <Field
            name="identification"
            component={renderField}
            data-testid="client-id-input"
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('taxName', 'Nombre')}
            required={true} />
          <Field
            name="firstName"
            component={renderField}
            data-testid="client-name-input"
            type="text"
            required
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('tradeName', 'Nombre')}
            required={companyIsElectronic} />
          <Field
            name="tradeName"
            component={renderField}
            data-testid="client-name-input"
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('address', 'Dirección')}
            required={companyIsElectronic} />
          <Field
            name="address.address"
            component={renderField}
            type="text"
            required={companyIsElectronic}
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('city', 'ciudad')}
            required={companyIsElectronic} />
          <Field
            name="address.city"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('electronicMail', 'Correo electrónico')} />
          <Field
            name="email"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('phone', 'Teléfono')} />
          <Field
            name="phone"
            component={renderField}
            type="text"
          />
        </div>
        <div className="col-md-6 px-4 pb-4">
          <Label title={I18n.get('website', 'Sitio web')} />
          <Field
            name="website"
            component={renderField}
            type="text"
          />
        </div>
      </div>
    </div>
  )
}


export default CompanySettings