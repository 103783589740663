import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash'
import BigNumber from 'bignumber.js';

import { decimalPrecision } from '../../../../selectors/company';
import { currency } from '../../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../../selectors/currencies'
import { subtotal, discount, discSubtotal, taxes, total as totalSelector } from '../../../../selectors/activeRefund';

const Summary = () => {
  const invoiceSubtotal = useSelector(subtotal);
  const invoiceDiscount = useSelector(discount);
  const invoiceDiscSubtotal = useSelector(discSubtotal);
  const invoiceTaxes = useSelector(taxes);
  const decimal = useSelector(decimalPrecision);
  const total = useSelector(totalSelector);
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  return (
    <div className="refund-bottom__summary d-flex flex-column mx-4 mt-3">
      {(invoiceDiscount.gt(0) || !!invoiceTaxes.length) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceDiscount.gt(0) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('discount', 'descuento')}
          </div>
          <div className="text-capitalize-first text-muted number">
            -{invoiceDiscount.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceDiscount.gt(0) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceDiscSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceTaxes.map((tax, index) => (
        <div key={index} className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {tax.name}
          </div>
          <div className="text-capitalize-first text-muted number">
            {tax.value.toFormat(decimal, fmt)}
          </div>
        </div>
      ))}

      {new BigNumber(total).gt(0) && (
        <div className="refund-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc" style={{ fontWeight: '600' }}>
            {I18n.get('totalToRefund', 'total a devolver')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {new BigNumber(total).toFormat(decimal, fmt)}
          </div>
        </div>
      )}
    </div>
  )
}

export default Summary;
