import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { get } from 'lodash';

import { syncData } from '../../reducers/app';
import { dateFormat as dateFormatSelector } from '../../selectors/company';
import Button from '../common/Button';
import RefreshNewSvg from '../svg/new/Refresh';
import Typography from '../common/Typography';

const Bottom = () => {
  const dispatch = useDispatch();
  const syncing = useSelector((state) => get(state, 'app.syncing', false));
  const lastDate = useSelector((state) => get(state, 'app.lastSync', null));
  const dateFormat = useSelector(dateFormatSelector);

  return (
    <div className='menu-footer'>
      <div className='text-left'>
        <Typography
          variant='secondary'
          type='body-3-regular'
          text={I18n.get('lastSynchronizeData', 'Última sincronización')}
        />
        <Typography
          variant='tertiary'
          type='body-3-regular'
          text={`${
            !!lastDate
              ? dayjs(lastDate).format(
                  I18n.get(
                    !!dateFormat
                      ? `${dateFormat.toUpperCase()} h:mm a`
                      : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')
                  )
                )
              : dayjs().format(
                  I18n.get(
                    !!dateFormat
                      ? `${dateFormat.toUpperCase()} h:mm a`
                      : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')
                  )
                )
          }`}
        />
      </div>

      <Button
        size='icon'
        variant='outline'
        type='button'
        disabled={syncing}
        onClick={() => dispatch(syncData())}
      >
        <RefreshNewSvg
          className={`icon-secondary ${syncing && 'rotate-animation'}`}
        />
      </Button>
    </div>
  );
};

export default Bottom;
