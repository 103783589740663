// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M13.231 3.586a8.75 8.75 0 1 0 7.284 6.64l-1.297 1.342a7.25 7.25 0 1 1-7.354-6.567l1.367-1.415zm1.771 7.157l.345 2.531 1.477.244-.679-4.978-4.977-.68.243 1.478 2.53.345-5.113 5.113a.75.75 0 0 0 1.061 1.06l5.113-5.113z"
    />
  </svg>
)