import _ from 'lodash'
import dayjs from 'dayjs'

import db from './database'
import { STATUS_IN_PROCESS } from '../utils/invoices';


export const put = async (invoice, callback) => {
  const dataInvoice = {
    ...invoice,
    statusInProcess: STATUS_IN_PROCESS.inFrontend,
  }
  await db.invoices.put({ ...dataInvoice, offlineStatus: 'pending' })
  if (!!callback)
    callback();
}

export const total = () => db.invoices.count()

export const get = async ({ start, limit }) => {
  try {
    return await db.invoices
      .orderBy('timestamp')
      .offset(start)
      .limit(limit)
      .toArray()
  } catch {
    return [];
  }
}

export const getAll = async () => {
  try {
    return await db.invoices
      .orderBy('timestamp')
      .toArray()
  } catch {
    return [];
  }
}

export const getByClient = async clientId => {
  return await db.invoices.filter(invoice => get(invoice, 'client.id') === clientId).toArray()
}

export const update = async (id, changes) => {
  try {
    await db.invoices.update(id, changes)
  } catch (error) {
    console.error('Error updating invoice:', error);
    throw error;
  }
}

// export const remove = id => db.invoices.delete(id);
export const remove = async (id) => {
  try {
    await db.invoices.delete(id);
  } catch (error) {
    console.error('Error deleting invoice:', error);
    throw error; // Re-throwing to ensure calling functions know about the error.
  }
};

export const updateNumerations = async () => {
  try {
    const invoices = await getAll()
    const updatedInvoices = invoices.map(invoice => ({
      ...invoice,
      numberTemplate: {
        ...invoice.numberTemplate,
        number: +_.get(invoice, 'numberTemplate.nextInvoiceNumber', 0)
          + _.get(invoices
            .filter(i => _.get(i, 'numberTemplate.id') === _.get(invoice, 'numberTemplate.id')
              && dayjs(_.get(i, 'timestamp')).isBefore(dayjs(_.get(invoice, 'timestamp'))))
            , 'length', 0)
      }
    }))

    await updatedInvoices.map(async invoice => await update(_.get(invoice, 'id'), invoice))
  } catch {

  }
}

export const getByIdInvoice = id => db.invoices.get(id);

export const putInvoiceFe = async (invoice) => {
  await db.invoicesFe.put({ ...invoice })
}

export const getByIdInvoiceFe = id => db.invoicesFe.get(id);

export const getAllInvoicesFe = async () => {
  try {
    return await db.invoicesFe
      .orderBy('timestamp')
      .toArray()
  } catch {
    return [];
  }
}

// export const removeInvoiceFe = id => db.invoicesFe.delete(id)

export const removeInvoiceFe = async (id) => {
  try {
    await db.invoicesFe.delete(id);
  } catch (error) {
    console.error(`Error deleting invoice with ID ${id}:`, error);
    throw error;
  }
}

export const removeAllInvoicesFe = async () => {
  const invoicesFe = await getAllInvoicesFe();
  await Promise.all(invoicesFe.map(async invoice => await removeInvoiceFe(invoice.id)))
}


