import React, { useState, useEffect, useCallback } from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import dayjs from 'dayjs';

import { contactUsLink } from '../../utils'
// import BackgroundSVG from '../svg/HappyWeekBackground.svg'
import HappyWeekIMG from '../images/new_happy_week.png'
import membershipsAPI from '../../reducers/membershipsAPI'
import { membershipSelector, country as countrySelector } from '../../selectors/company'
import PrimaryPlan from './PrimaryPlan'
import SecondaryPlan from './SecondaryPlan'

const electronicInvoicingCountries = ['colombia', 'costaRica', 'peru', 'panama', 'republicaDominicana'];

const ChoosePlan = ({ plans, onSelectPlan }) => {
  const country = useSelector(countrySelector)
  const showHappyWeek40 = !["spain", "other"].includes(country) && dayjs().isAfter('2023-05-17') && dayjs().isBefore('2023-06-01')
  const [period, setPeriod] = useState(showHappyWeek40 ? 'month' : 'year')
  const [showDiscount, setShowDiscount] = useState(false)
  const membership = useSelector(membershipSelector)

  const fecthHappyWeekInfo = useCallback(
    async () => {
      try {
        const response = await membershipsAPI.get(`/coupon/happy-week/status`);

        setShowDiscount(get(response, 'data.results', false) && !["spain", "other"].includes(country))
        setPeriod(get(response, 'data.results', false)
          ? 'month'
          : showHappyWeek40
            ? 'month'
            : 'year')
      } catch {
      }
    },
    [country, showHappyWeek40],
  )

  useEffect(() => {
    fecthHappyWeekInfo()
  }, [fecthHappyWeekInfo])

  if (!plans) return null;

  const primaryPlans = plans.filter(plan => {
    return !!plan.keyword && (
      plan.keyword.includes('pyme')
      || plan.keyword.includes('pro')
      || plan.keyword.includes('plus')
    )
  })

  const secondaryPlans = plans.filter(plan => {
    return !!plan.keyword && !(
      plan.keyword.includes('pyme')
      || plan.keyword.includes('pro')
      || plan.keyword.includes('plus')
    )
  })

  return (
    <div className="choose-plan d-flex flex-column align-items-center">
      <h1 className="choose-plan__title font-weight-light my-4">
        {`${I18n.get('actuallyYouHave', 'Actualmente te encuentras en')} ${get(membership, 'plan.name')}`}
      </h1>

      {(country === 'panama' && get(membership, 'pay')) && (
        <p className="h4 text-capitalize-first font-weight-light mt-2 mb-4">
          {I18n.get('plansUpdatePAN', 'Los planes fueron actualizados el 24/10/22, tenlo en cuenta si necesitas cambiar tu suscripción.')}
        </p>
      )}

      <p className="h4 text-capitalize-first font-weight-light mt-2 mb-4">
        {I18n.get('choosePlanSubtitle', 'Elige tu plan ideal para seguir vendiendo fácil y rápido en tu negocio')}
      </p>

      {/* {showDiscount && (
        <div
          style={{
            minHeight: '200px',
          }}
          className="position-relative w-100"
        >
          <img src={BackgroundSVG} alt="happy week" className="d-none d-sm-block m-auto" />

          <div
            style={{
              top: 0,
              bottom: 0
            }}
            className="position-absolute d-flex justify-content-center align-items-center w-100 flex-column"
          >
            <div
              style={{
                fontSize: '48px',
                fontWeight: 500,
                maxWidth: '300px',
                lineHeight: 'normal',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              <p className="p-0 m-0 pb-2">
                {I18n.get('happyWeekTitle1', '¡Ahorra con la ')}
                {' '}
                <strong className="text-primary">
                  {I18n.get('happyWeekTitle2', 'Happy Week!')}
                </strong>
              </p>
            </div>

            <div
              style={{
                fontSize: '18px',
                maxWidth: '500px',
                lineHeight: 'normal',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              <p className="p-0 m-0">
                {I18n.get('happyWeekSubtitle1', 'Paga')}
                {' '}
                <strong className="text-primary">
                  {I18n.get('happyWeekSubtitle2', '2 messes')}
                </strong>
                {' '}
                {I18n.get('happyWeekSubtitle3', 'de Alegra POS y recibe el ')}
                {' '}
                <strong className="text-primary">
                  {I18n.get('happyWeekSubtitle4', '50% OFF')}
                </strong>
              </p>

              <p className="p-0 m-0">
                {I18n.get('happyWeekSubSubtitle', '* Válido del 9 al 15 de mayo.')}
              </p>
            </div>
          </div>
        </div>
      )} */}

      {(showDiscount || showHappyWeek40) && (
        <div>
          <img src={HappyWeekIMG} alt="happy week img" className="d-none d-sm-block m-auto" />
        </div>
      )}

      <div className={`choose-plan__btn-group my-4 d-flex${(showDiscount || showHappyWeek40) ? ' flex-row-reverse' : ''}`}>
        <button
          type="button"
          onClick={() => setPeriod('year')}
          className={`btn ${period === 'year' ? 'selected' : ''}`}
        >
          {I18n.get('yearlyPlanButton', 'Anual con 10% dcto')}
        </button>
        <button
          type="button"
          onClick={() => setPeriod('month')}
          className={`btn ${period === 'month' ? 'selected' : ''}`}
        >
          {showHappyWeek40
            ? I18n.get('', '2 Meses con 40%')
            : I18n.get('monthlyPlanButton', 'Mensual')}
        </button>
      </div>

      <div className="plan-container d-flex flex-wrap justify-content-center my-4">
        {primaryPlans.map((plan, index) =>
          <PrimaryPlan
            key={index}
            plan={plan}
            period={period}
            country={country}
            showDiscount={(showDiscount || showHappyWeek40) && period === 'month'}
            showHappyWeek={showHappyWeek40}
            discount={
              (showDiscount || showHappyWeek40)
                ? showHappyWeek40
                  ? 40 : 50
                : 0}
            onChoose={() => onSelectPlan({ ...plan, period })}
          />
        )}
      </div>

      {
        !!(secondaryPlans.length !== 0) && (
          <p className="h4 text-capitalize-first font-weight-light mt-2 mb-2 ">
            {I18n.get('choosePlanSecondaries', '¿Aún no encuentras el mejor plan para ti?')}
          </p>

        )
      }

      <div className="plan-container d-flex flex-wrap justify-content-center my-4">
        {
          secondaryPlans.map((plan, index) =>
            <SecondaryPlan
              key={index}
              plan={plan}
              period={period}
              country={country}
              onlyPlan={!!(secondaryPlans.length === 1)}
              onChoose={() => onSelectPlan({ ...plan, period })}
            />
          )
        }
      </div>

      {((showDiscount || showHappyWeek40) && !!secondaryPlans.length) && (<p>{I18n.get('happyWeekTerms', '* El descuento aplica en los planes Pyme, Pro y Plus')}</p>)}

      {(electronicInvoicingCountries.includes(country)) && (
        <p className="h5 text-capitalize-first font-weight-light">
          {I18n.get('choosePlanNote2', 'En todos tendrás disponible Facturación Electrónica.')}
        </p>
      )}
      <div className="d-inline-flex align-items-center h4">
        <p className="h4 text-capitalize-first font-weight-light mr-2">
          {I18n.get('choosePlanContactUs1', '¿Tienes dudas sobre cuál elegir?')}
        </p>
        <p className="h4 text-capitalize-first font-weight-bold mr-2">
          {I18n.get('choosePlanContactUs2', 'Te ayudamos 👉🏻')}
        </p>
        <a className="btn-link my-3" href={contactUsLink(country)} target="_blank" rel="noreferrer">
          {I18n.get('contactUs', 'contáctanos')}
        </a>
      </div>
    </div>
  )
}

export default ChoosePlan;