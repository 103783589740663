import React, { useEffect } from 'react'
import { I18n } from '@aws-amplify/core'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { replace } from 'lodash'

import notificationsAPI from '../../reducers/notificationsAPI'
import { getInvoiceNumerations } from '../../selectors/numerations'
import { userSelector } from '../../selectors/auth'
import { country as countrySelector } from '../../selectors/company'

const ElectronicNumerationsNotification = () => {
  const electronicNumerations = useSelector(getInvoiceNumerations);
  const user = useSelector(userSelector)
  const country = useSelector(countrySelector);

  useEffect(() => {
    const versions = ['colombia', 'republicaDominicana'];
    if (!versions.includes(country)) return;

    electronicNumerations.forEach(num => {
      const readyToShowNotification = (cookie) => cookie === undefined || dayjs(cookie * 1000).isBefore(dayjs())
      const numerationIsAboutToExpire = num.endDate !== null && dayjs(num.endDate).isBefore(dayjs().add(30, 'day')) && dayjs(num.endDate).isAfter(dayjs());
      const numerationExpired = (!!num.endDate || num.endDate !== "") && dayjs(num.endDate).isBefore(dayjs())
      const numerationIsAlmostOver = !!num.nextInvoiceNumber && !!num.maxInvoiceNumber && Number(num.maxInvoiceNumber) - Number(num.nextInvoiceNumber) <= 100
      const numerationIsOver = !!num.maxInvoiceNumber && !!num.nextInvoiceNumber && Number(num.nextInvoiceNumber) >= Number(num.maxInvoiceNumber)

      const numerationIsAboutToExpireCookie = Cookies.get('_numeration-' + num.id + '-about-to-expire');
      const numerationExpiredCookie = Cookies.get('_numeration-' + num.id + '-already-expired');
      const numerationIsAlmostOverCookie = Cookies.get('_numeration-' + num.id + '-almost-over');
      const numerationIsOverCookie = Cookies.get('_numeration-' + num.id + '-already-over');

      if (numerationIsAboutToExpire && readyToShowNotification(numerationIsAboutToExpireCookie)) {
        try {
          Cookies.set('_numeration-' + num.id + '-about-to-expire', dayjs().add(1, 'day').unix());
          notificationsAPI.post('/notifications',
            notificationBody({
              userId: user.idGlobal,
              title: I18n.get('yourNumerationIsAboutToExpire', '¡Tu numeración está por vencer! 📢'),
              text: replace(I18n.get('yourNumerationIsAboutToExpire.description', 'La numeración {} vencerá pronto. Te recomendamos revisarla y solicitar una nueva en la DIAN.'), '{}' ,`<strong>${num.name.trim()}</strong>`),
              actionCall: `${process.env.REACT_APP_ALEGRA}resolution/edit/id/${num.id}`,
              actionLabel: I18n.get('seeMyNumeration', 'Ver mi numeración 🔍'),
              type: "info",
              name: "numeration-about-to-expire:"+country
            }));
        } catch (err) {
          console.log("error while creating notification", err);
        }
      }
      if (numerationIsAlmostOver && readyToShowNotification(numerationIsAlmostOverCookie)) {
        try {
          Cookies.set('_numeration-' + num.id + '-almost-over', dayjs().add(1, 'day').unix());
          notificationsAPI.post('/notifications',
            notificationBody({
              userId: user.idGlobal,
              title: I18n.get('yourNumerationIsAlmostOver', '¡Ya casi se te acaban los consecutivos!⚠️'),
              text: replace(I18n.get('yourNumerationIsAlmostOver.description', 'Dispones de pocos documentos en tu numeración {}. Pide una nueva a la DIAN y configúrala en segundos.'), '{}', `<strong>${num.name.trim()}</strong>`),
              actionCall: `${process.env.REACT_APP_ALEGRA}resolution/edit/id/${num.id}`,
              actionLabel: I18n.get('seeMyNumeration', 'Ver mi numeración 🔍'),
              type: "warning",
              name: "numeration-almost-over:"+country
            }));
        } catch (err) {
          console.log("error while creating notification", err);
        }
        if (numerationExpired && readyToShowNotification(numerationExpiredCookie)) {
          try {
            Cookies.set('_numeration-' + num.id + '-already-expired', dayjs().add(1, 'day').unix())
            notificationsAPI.post('/notifications',
              notificationBody({
                userId: user.idGlobal,
                title: I18n.get('yourNumerationIsExpired', '¡Tu numeración se encuentra vencida! 🚨'),
                text: replace(I18n.get('yourNumerationIsExpired.description', 'La numeración {} está vencida. Solicita una nueva a la DIAN y configúrala para seguir facturando.'), '{}', `<strong>${num.name.trim()}</strong>`),
                actionCall: `https://ayuda.alegra.com/es/solicita-en-l%C3%ADnea-tu-resoluci%C3%B3n-de-facturaci%C3%B3n-a-la-dian`,
                actionLabel: I18n.get('', 'Cómo solicitar otra 👈'),
                type: "danger",
                name: "numeration-expired:"+country
              }))
          } catch (err) {
            console.log("error while creating notification", err);
          }
        }
      }
      if (numerationIsOver && readyToShowNotification(numerationIsOverCookie)) {
        try {
          Cookies.set('_numeration-' + num.id + '-already-over', dayjs().add(1, 'day').unix())
          notificationsAPI.post('/notifications',
            notificationBody({
              userId: user.idGlobal,
              title: I18n.get('yourNumerationIsOver', '¡Se acabaron los consecutivos de tu numeración! 🚨'),
              text: replace(I18n.get('yourNumerationIsOver.description', 'La numeración {} se quedó sin consecutivos. Solicita una nueva a la DIAN para seguir facturando'), '{}', `<strong>${num.name.trim()}</strong>`),
              actionCall: `${process.env.REACT_APP_ALEGRA}resolution/edit/id/${num.id}`,
              actionLabel: I18n.get('seeMyNumeration', 'Ver mi numeración 🔍'),
              type: "warning",
              name: "numeration-over:"+country
            }));
        } catch (err) {
          console.log("error while creating notification", err);
        }
      }
    })
  }, [electronicNumerations, user, country])

  return (
    <></>
  )
}

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type, name }) => ({
  name,
  teamInCharge: "pos",
  receptors: {
    users: [userId]
  },
  availability: {
    from: from ? from : dayjs().toISOString(),
    to: to ? to : dayjs().add(1, 'days').toISOString()
  },
  message: [
    {
      channel: "web",
      type: "localized",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionCall,
        actionLabel
      },
      style: {
        type,
        icon: "notification"
      }
    }
  ]
})

export default ElectronicNumerationsNotification