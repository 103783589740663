import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';

import { hasPermissionTo } from '../../selectors/auth';
import {
  shiftsEnabled,
  isOnlyInvoicingPlan,
  country as countrySelector,
} from '../../selectors/company';
import { isOpen } from '../../selectors/shifts';
import { startDate } from '../../selectors/shifts';
import { closeMenu } from '../../reducers/app';
import { openModal } from '../../reducers/modals';
import { sendGTMEvent } from '../../reducers/company';

import NewMenu from '../svg/new/Menu';
import ArrowBarRight from '../svg/new/ArrowBarRight';
import Typography from '../common/Typography';
import AlegraPosLogo from '../svg/new/logos/pos/AlegraPos';
import AlegraTPVLogo from '../svg/new/logos/pos/AlegraTPV';
import AlegraPointOfSaleLogo from '../svg/new/logos/pos/AlegraPointOfSale';

export const countriesFirstGroupNewDesign = [
  'other',
  'usa',
  'spain',
  'peru',
  'republicaDominicana',
];

const Header = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const startingDate = useSelector(startDate);
  const country = useSelector(countrySelector);
  const isShiftsEnabled = useSelector(shiftsEnabled);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const shiftOpen = useSelector(isOpen);

  const isShiftDisabled = () => {
    if (shiftOpen === true) {
      return !can('view', 'pos-cashier') || !can('close', 'pos-cashier');
    }

    return !can('open', 'pos-cashier');
  };

  const handleCloseShift = () => {
    try {
      dispatch(closeMenu());
      dispatch(
        openModal({
          modal:
            (dayjs(startingDate).isAfter('2023-04-20') &&
              countriesFirstGroupNewDesign.includes(country)) ||
            (dayjs(startingDate).isAfter('2023-05-30') &&
              !countriesFirstGroupNewDesign.includes(country))
              ? 'newCloseShift'
              : 'closeShift',
          params: { attemptOrigin: 'from menu' },
        })
      );
      dispatch(
        sendGTMEvent('shift-closing-attempted', {
          attemptOrigin: 'from menu',
          creationStatus: 'success',
        })
      );
    } catch {
      dispatch(
        sendGTMEvent('shift-closing-attempted', {
          attemptOrigin: 'from menu',
          creationStatus: 'failure',
        })
      );
    }
  };

  const handleOpenShift = () => {
    try {
      dispatch(closeMenu());
      dispatch(
        openModal({
          modal: 'openShift',
          params: { attemptOrigin: 'from menu' },
        })
      );
      dispatch(
        sendGTMEvent('shift-opening-attempted', {
          attemptOrigin: 'from menu',
          creationStatus: 'success',
        })
      );
    } catch {
      dispatch(
        sendGTMEvent('shift-opening-attempted', {
          attemptOrigin: 'from menu',
          creationStatus: 'failure',
        })
      );
    }
  };

  const renderLogo = () => {
    if (country === 'spain') {
      return <AlegraTPVLogo className='custom-sidebar-logo' />;
    }

    if (['peru', 'argentina'].includes(country)) {
      return <AlegraPointOfSaleLogo className='custom-sidebar-logo' />;
    }

    return <AlegraPosLogo className='custom-sidebar-logo' />;
  };

  return (
    <>
      <div className='new-navigation-menu-header'>
        <div onClick={() => dispatch(closeMenu())} className='pointer'>
          <NewMenu />
        </div>
        {renderLogo()}
      </div>
      {!!isShiftsEnabled && !onlyInvoicingPlan && (
        <div
          className={`new-navigation-menu-header new-navigation-menu-action ${
            isShiftDisabled() && 'new-navigation-menu-disabled'
          }`}
          onClick={
            isShiftDisabled()
              ? () => null
              : shiftOpen === true
              ? () => handleCloseShift()
              : () => handleOpenShift()
          }
        >
          <Typography
            type='label-2'
            variant='secondary'
            text={
              shiftOpen === true
                ? I18n.get('closeShift', 'cerrar turno')
                : I18n.get('openShift', 'abrir turno')
            }
          />
          <div className='new-navigation-menu-header-helper' />
          <ArrowBarRight />
        </div>
      )}
    </>
  );
};

export default Header;
