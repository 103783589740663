// eslint-disable-next-line
export default ({ id, className, fill, syncing }) => (
  <svg
    id={id}
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      {syncing && (
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 6 6; -45 6 6; -90 6 6; -135 6 6; -180 6 6; -225 6 6; -270 6 6; -315 6 6; -360 6 6"
          dur="1s"
          repeatCount="indefinite"
        />
      )}
      <path
        d="M6.73966 0.225154C5.45617 0.0595423 4.15383 0.320523 3.03325 0.967894C2.29087 1.39677 1.65555 1.97948 1.16657 2.67131V1.33334C1.16657 1.0572 0.942708 0.833342 0.666565 0.833342C0.390423 0.833342 0.166565 1.0572 0.166565 1.33334V4.00001C0.166565 4.27615 0.390423 4.50001 0.666565 4.50001H0.988317C0.995917 4.50018 1.0035 4.50018 1.01108 4.50001H3.33323C3.60937 4.50001 3.83323 4.27615 3.83323 4.00001C3.83323 3.72387 3.60937 3.50001 3.33323 3.50001H1.81678C2.24045 2.8147 2.82891 2.24082 3.53348 1.83378C4.46413 1.29614 5.54574 1.07939 6.61169 1.21693C7.67765 1.35447 8.66881 1.83868 9.4325 2.59495C10.1962 3.35122 10.69 4.33761 10.838 5.40217C10.876 5.67568 11.1285 5.86659 11.4021 5.82858C11.6756 5.79057 11.8665 5.53803 11.8285 5.26452C11.6503 3.9827 11.0557 2.79501 10.1361 1.8844C9.2166 0.973784 8.02316 0.390767 6.73966 0.225154Z"
        fill={fill ?? "#64748B"}
      />
      <path
        d="M1.16181 6.59788C1.1238 6.32437 0.871255 6.13346 0.597741 6.17147C0.324227 6.20948 0.133314 6.46202 0.171325 6.73553C0.34946 8.01735 0.944102 9.20504 1.86365 10.1157C2.7832 11.0263 3.97664 11.6093 5.26013 11.7749C6.54363 11.9405 7.84597 11.6795 8.96655 11.0322C9.70893 10.6033 10.3442 10.0206 10.8332 9.32874V10.6667C10.8332 10.9429 11.0571 11.1667 11.3332 11.1667C11.6094 11.1667 11.8332 10.9429 11.8332 10.6667V8.00004C11.8332 7.7239 11.6094 7.50004 11.3332 7.50004H11.0115C11.0039 7.49987 10.9963 7.49987 10.9887 7.50004H8.66656C8.39042 7.50004 8.16656 7.7239 8.16656 8.00004C8.16656 8.27618 8.39042 8.50004 8.66656 8.50004H10.183C9.75934 9.18535 9.17089 9.75923 8.46631 10.1663C7.53566 10.7039 6.45406 10.9207 5.3881 10.7831C4.32215 10.6456 3.33099 10.1614 2.5673 9.4051C1.8036 8.64883 1.30975 7.66244 1.16181 6.59788Z"
        fill={fill ?? "#64748B"}
      />
    </g>
  </svg>
)