import React, { useEffect, useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { capitalize, get } from 'lodash';
import { useSelector } from 'react-redux'

import { languageSelector } from '../../../selectors/auth'
import { country as countrySelector, origin as originSelector } from '../../../selectors/company'
import regimes from '../../countriesData/general/regimes'
import regimesLabel from '../../countriesData/general/regimesLabel'
import ivaConditions from '../../countriesData/argentina/companyIvaConditions'
import { renderSelect, renderField, renderPhone } from '../fields/V0/Fields';
import { isClothingStore } from '../../../utils';
import configAPI from '../../../reducers/configAPI';

const OnboardingSettings = ({ form, values }) => {

  const [sectors, setSectors] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [errors, setErrors] = useState(0)
  const country = useSelector(countrySelector)
  const language = useSelector(languageSelector)
  const origin = useSelector(originSelector)

  const change = form.change

  useEffect(() => {
    const loadData = async () => {
      if (!get(sectors, 'length') && errors < 5) {
        try {
          setLoading(true)
          // ex queries.allCompanySectors

          const response = await configAPI.get('/sectors', {
            application_version: country,
            lang: language.slice(0, 2),
          })
          setLoading(false)

          const allSectors = get(response, 'data', [])
          setSectors(allSectors)

          if (!values.sector)
            change('sector', allSectors.find(s => get(s, 'key') === 'RETAIL') || allSectors[0])
        } catch {
          setLoading(false)
          setErrors(errors + 1)
        }
      }

    }

    loadData()
  }, [errors, sectors, country, language, change, values.sector])

  return (
    <div className="form-body__fields form-row justify-content-start">
      <Field
        name="name"
        className="col-12"
        type="text"
        component={renderField}
        label={capitalize(I18n.get('companyName', 'nombre del negocio'))}
        required
      />

      {
        !isClothingStore(country, origin) && (
          <Field
            name="sector"
            className="col-12"
            options={sectors}
            component={renderSelect}
            isLoading={isLoading}
            label={capitalize(I18n.get('whatYouDo', 'A qué te dedicas'))}
            getOptionLabel={option => option.value}
            getOptionValue={option => option.key}
            required={isClothingStore(country, origin) ? false : true}
          />
        )
      }

      <Field
        name="phone"
        className="col-12"
        component={renderPhone}
        label={capitalize(I18n.get('phone', 'teléfono'))}
        country={country}
        required={!['argentina', 'costaRica'].includes(country)}
      />

      {(country === 'republicaDominicana'
        || country === 'peru' || country === 'chile'
        || country === 'costaRica' || country === 'colombia'
      ) && (
          <Field
            name="regime"
            className="col-12"
            options={regimes(country)}
            cacheOptions={regimes(country)}
            component={renderSelect}
            label={regimesLabel(country)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            optionAdditionalInfo
            required
          />
        )}

      {country === 'argentina' && (
        <Field
          name="ivaCondition"
          className="col-12"
          options={ivaConditions}
          component={renderSelect}
          label={I18n.get('ivaCondition', 'condición de IVA')}
          getOptionLabel={option => option.value}
          getOptionValue={option => option.key}
          required
        />
      )}
    </div>
  )
}

export default OnboardingSettings;