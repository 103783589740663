function IncomesDownSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.0581 6.05806C11.3021 5.81398 11.6979 5.81398 11.9419 6.05806C12.186 6.30214 12.186 6.69786 11.9419 6.94194L8.00888 10.875H11.5C11.8452 10.875 12.125 11.1548 12.125 11.5C12.125 11.8452 11.8452 12.125 11.5 12.125H6.5C6.41526 12.125 6.33445 12.1081 6.26076 12.0776C6.18704 12.0471 6.11798 12.0019 6.05806 11.9419C5.99814 11.882 5.95292 11.813 5.92242 11.7392C5.89187 11.6655 5.875 11.5847 5.875 11.5V6.5C5.875 6.15482 6.15482 5.875 6.5 5.875C6.84518 5.875 7.125 6.15482 7.125 6.5V9.99112L11.0581 6.05806Z'
        fill='#94A3B8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.875 9C0.875 4.51269 4.51269 0.875 9 0.875C13.4873 0.875 17.125 4.51269 17.125 9C17.125 13.4873 13.4873 17.125 9 17.125C4.51269 17.125 0.875 13.4873 0.875 9ZM9 2.125C5.20304 2.125 2.125 5.20304 2.125 9C2.125 12.797 5.20304 15.875 9 15.875C12.797 15.875 15.875 12.797 15.875 9C15.875 5.20304 12.797 2.125 9 2.125Z'
        fill='#94A3B8'
      />
    </svg>
  );
}

export default IncomesDownSvg;
