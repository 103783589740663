// eslint-disable-next-line
export default ({ id, className, small }) => (
  <svg 
    id={id}
    className={className}
    width={small ? "20" : "24"} 
    height={small ? "20" : "24"}
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 4 12 a 8 8 0 1 0 16 0 a 8 8 0 0 0 -16 0 z m 15 0 a 7 7 0 1 1 -14 0 a 7 7 0 0 1 14 0 z z z M 12 5.6 z m 0.64 10.88 h -1.28 v -1.28 h 1.28 v 1.28 z m 1.3248 -4.96 l -0.576 0.5888 C 12.928 12.576 12.64 12.96 12.64 13.92 h -1.28 v -0.32 c 0 -0.704 0.288 -1.344 0.7488 -1.8112 l 0.7936 -0.8064 c 0.2368 -0.2304 0.3776 -0.5504 0.3776 -0.9024 c 0 -0.704 -0.576 -1.28 -1.28 -1.28 s -1.28 0.576 -1.28 1.28 H 9.44 c 0 -1.4144 1.1456 -2.56 2.56 -2.56 s 2.56 1.1456 2.56 2.56 c 0 0.5632 -0.2304 1.0752 -0.5952 1.44 z"
    />
    {/* <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0zm15 0a7 7 0 1 1-14 0 7 7 0 0 1 14 0zm-6 4.409c-.016.197-.122.345-.318.443a1.421 1.421 0 0 1-.647.148c-.22 0-.423-.045-.611-.135a.613.613 0 0 1-.353-.417c-.016-.806-.028-1.628-.036-2.467-.008-.84-.02-1.662-.035-2.467a.784.784 0 0 1 .377-.385c.172-.086.368-.129.588-.129.204 0 .396.039.576.116a.73.73 0 0 1 .388.36c.016.804.028 1.627.036 2.466.008.84.02 1.662.035 2.467zm-1.711-9.114A.984.984 0 0 0 11 8.01c0 .266.096.498.289.695a.944.944 0 0 0 .7.295.977.977 0 0 0 .712-.295A.946.946 0 0 0 13 8.01c0-.28-.1-.518-.299-.715A.977.977 0 0 0 11.99 7a.944.944 0 0 0-.701.295z"
    /> */}
  </svg>
)