import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { get } from 'lodash';
import { BigNumber } from "bignumber.js";
import dayjs from 'dayjs';

import { calculateItemsValues, calculateSingleItemValues } from '../../utils';
import { decimalPrecision, multicurrency, dateFormat as dateFormatSelector, country as countrySelector } from '../../selectors/company';
import { getInvoiceNumerations as numerationSelector } from '../../selectors/numerations'
import { companySelector } from '../../selectors/company';
import { getMainCurrency } from '../../selectors/currencies';
import {
  getFmt,
  getItemReference,
  getLegalStatusKey,
  legalStatusToBadgeStatus,
  renderClientName
} from './utils'

const DetailBody = ({ invoice }) => {
  const companyDecimal = useSelector(decimalPrecision);
  const decimal = !!get(invoice, 'decimalPrecision', null) ? Number(invoice.decimalPrecision) : companyDecimal;
  const mainCurrency = useSelector(getMainCurrency);
  const isMulticurrency = useSelector(multicurrency);
  const dateFormat = useSelector(dateFormatSelector);
  const numerations = useSelector(numerationSelector);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);

  if (!invoice) return null;

  const fmt = getFmt(invoice, mainCurrency);
  const itemsValues = calculateItemsValues(invoice.items, decimal);
  const legalStatusKey = getLegalStatusKey(invoice, numerations, country);

  const downloadFile = (source, fileName) => {
    fetch(source, { method: 'GET' })
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(err => {
        console.error('err: ', err);
      })
  }

  const selectStatusColor = (legalStatusKey) => {
    let statusColor = legalStatusToBadgeStatus(legalStatusKey, country);
    switch (country) {
      case 'panama':
          return statusColor + '-' + country;
      default:
        return statusColor;
    }
  }

  return (
    <>
      <div className="p-4 position-relative overflow-hidden">
        <table className="detail-info-table mb-4">
          <tbody>
            <tr>
              <td>{I18n.get('status', 'estado')}</td>
              {!!invoice.offlineStatus ? (
                <td>
                  <div className="badge badge-pill m-0 font-weight-normal p-2 px-3 badge-warning">
                    {I18n.get("offline", "Offline")}
                  </div>
                </td>
              ) : (
                <td>
                  <div className={`badge badge-pill m-0 font-weight-normal p-2 px-3 badge-${invoice.status}`}>
                    {I18n.get(`invoiceStatus${invoice.status}`, `${invoice.status}`)}
                  </div>
                </td>
              )}
            </tr>
            {!!legalStatusKey &&
              <tr>
                <td>{I18n.get('emissionStatus', 'estado de emision')}</td>
                <td>
                  <div className={`badge badge-pill m-0 font-weight-normal text-capitalize-first p-2 px-3 badge-${selectStatusColor(legalStatusKey)}`}>
                    {I18n.get(legalStatusKey, legalStatusKey)}
                  </div>
                </td>
              </tr>
            }
            {(!legalStatusKey && country === 'panama') &&
              <tr>
                <td>{I18n.get('emissionStatus', 'estado de emision')}</td>
                <td>
                  <div className={`badge badge-pill m-0 font-weight-normal text-capitalize-first p-2 px-3 badge-void`}>
                    {I18n.get("No electrónica", "No electrónica")}
                  </div>
                </td>
              </tr>
            }
            {!!get(invoice, 'stamp.cufe') &&
              <tr>
                <td>{I18n.get('cufe', 'CUFE')}</td>
                <td>{get(invoice, 'stamp.cufe')}</td>
              </tr>
            }
            {!!get(invoice, 'stamp.date') &&
              <tr>
                <td>{I18n.get('emissionDate', 'Fecha de emisión')}</td>
                <td>
                  {dayjs(get(invoice, 'stamp.date')).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}
                </td>
              </tr>
            }
            {!!get(invoice, 'stamp.securityCode') &&
              <tr>
                <td>{I18n.get('securityCode', 'Código de seguridad')}</td>
                <td>{get(invoice, 'stamp.securityCode')}</td>
              </tr>
            }

            {!!get(invoice, 'stamp.observations') && (
              <tr>
                <td>{I18n.get('observations', 'Observaciones')}</td>
                <td>
                  {get(invoice, 'stamp.observations').map((warning, i) => {
                    const w = JSON.parse(warning);
                    return (
                      <p className="m-0" key={i}>{w.code} - {w.message}</p>
                    )
                  })}
                </td>
              </tr>
            )}
             {!!get(invoice, 'stampFiles') && !!JSON.parse(get(invoice, 'stampFiles', null)).xml && (
              <tr>
                <td>{I18n.get('xmlDocument', 'Documento XML')}</td>
                <td>
                  <p className="d-inline m-0 text-primary pointer underline-hover"
                    onClick={() => downloadFile(JSON.parse(get(invoice, 'stampFiles')).xml, get(company, 'identification') + get(invoice, 'numberTemplate.fullNumber') + '.xml')}>
                    {get(company, 'identification')}{get(invoice, 'numberTemplate.fullNumber')}.xml
                  </p>
                </td>
              </tr>
            )}
            {!!get(invoice, 'stampFiles') && !!JSON.parse(get(invoice, 'stampFiles', null)).resume && (
              <tr>
                <td>{I18n.get('sumary', 'Resumen')}</td>
                <td>
                  <p className="d-inline m-0 text-primary pointer underline-hover"
                    onClick={() => downloadFile(JSON.parse(get(invoice, 'stampFiles')).resume, get(company, 'identification') + get(invoice, 'numberTemplate.fullNumber') + '_RESUME.xml')}>
                    {get(company, 'identification')}{get(invoice, 'numberTemplate.fullNumber')}_RESUME.xml
                  </p>
                </td>
              </tr>
            )}
            {!!get(invoice, 'stamp.warnings') && (
              <tr>
                <td>{I18n.get('observations', 'Observaciones')}</td>
                <td>
                  {get(invoice, 'stamp.warnings').map((warning, i) => {
                    const w = JSON.parse(warning);
                    return (
                      <p className="m-0" key={i}>{w.code}:{w.value}</p>
                    )
                  })}
                </td>
              </tr>
            )}
            {!!get(invoice, 'stamp.cae') &&
              <tr>
                <td>{I18n.get('emissionType', 'tipo de emisión')}</td>
                <td>{get(invoice, 'stamp.mode') || 'CAE'} - {get(invoice, 'stamp.cae')}</td>
              </tr>
            }
            {!!get(invoice, 'stamp.caea') &&
              <tr>
                <td>{I18n.get('emissionType', 'tipo de emisión')}</td>
                <td>{get(invoice, 'stamp.mode') || 'CAEA'} - {get(invoice, 'stamp.caea')}</td>
              </tr>
            }
            <tr>
              <td>{I18n.get('client', 'cliente')}</td>
              <td>{renderClientName(get(invoice, 'client'))}</td>
            </tr>
            <tr>
              <td>{I18n.get('creation', 'creación')}</td>
              <td>
                {!!invoice.offlineStatus
                  ? dayjs(invoice.timestamp).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))
                  : dayjs(invoice.createdAt).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))
                }
              </td>
            </tr>
            {!!invoice.dueDate && (
              <tr>
                <td>{I18n.get('dueDate', 'fecha de vencimiento')}</td>
                <td>
                  {dayjs(invoice.dueDate).format(!!dateFormat ? dateFormat.toUpperCase() : I18n.get('dateFormat', 'DD/MM/YYYY'))}
                </td>
              </tr>
            )}
            <tr>
              <td>{I18n.get('termsConditions', 'términos y condiciones')}</td>
              <td>{get(invoice, 'termsConditions', '')}</td>
            </tr>
            <tr>
              <td>{I18n.get('anotations', 'observaciones')}</td>
              <td>{get(invoice, 'anotation', '')}</td>
            </tr>
            {!!get(invoice, 'numberTemplate.text', '') && (
              <tr>
                <td>{I18n.get('resolution', 'Resolución')}</td>
                <td>{get(invoice, 'numberTemplate.text', '')}</td>
              </tr>
            )}
            {!!isMulticurrency && (
              <tr>
                <td>{I18n.get('currency', 'moneda')}</td>
                <td>
                  {!!get(invoice, 'currency.code')
                    ? get(invoice, 'currency.code')
                    : get(mainCurrency, 'code')
                  }
                </td>
              </tr>
            )}
            <tr>
              <td>{I18n.get('warehouse', 'bodega')}</td>
              <td>{get(invoice, 'warehouse.name', '')}</td>
            </tr>
            <tr>
              <td>{I18n.get('seller', 'vendedor')}</td>
              <td>{get(invoice, 'seller.name', '')}</td>
            </tr>
            {!!get(invoice, 'idShift', null) && (
              <tr>
                <td>{I18n.get('shiftNumber', 'número de turno')}</td>
                <td>{get(invoice, 'idShift', '')}</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="detail-items-table-wrapper mb-4">
          <table className="detail-items-table rounded-lg">
            <thead>
              <tr>
                <th>{I18n.get('item', 'producto')}</th>
                <th>{I18n.get('reference', 'referencia')}</th>
                <th>{I18n.get('price', 'precio')}</th>
                <th>{I18n.get('discount', 'descuento')}</th>
                <th>{I18n.get('taxes', 'impuestos')}</th>
                <th>{I18n.get('quantity', 'cantidad')}</th>
                <th>{I18n.get('total', 'total')}</th>
              </tr>
            </thead>
            <tbody>
              {invoice.items.map((item, index) => {
                const itemValues = calculateSingleItemValues(item, decimal)
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {getItemReference(item, !!invoice.offlineStatus)}
                    </td>
                    <td>{itemValues.price.toFormat(decimal, fmt)}</td>
                    <td>{itemValues.discount}%</td>
                    <td>{itemValues.taxes}</td>
                    <td>{itemValues.quantity}</td>
                    <td>{itemValues.total.toFormat(decimal, fmt)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-start flex-wrap">
          <table className="detail-payments-table rounded-lg mb-4">
            <thead>
              <tr>
                <th>{I18n.get('paymentMethod', 'método de pago')}</th>
                <th>{I18n.get('value', 'valor')}</th>
              </tr>
            </thead>
            <tbody>
              {!!invoice.payments ? invoice.payments.map((payment, index) => (
                <tr key={index}>
                  <td className="text-capitalize-first">{I18n.get(payment.paymentMethod, payment.paymentMethod)}</td>
                  <td>{new BigNumber(payment.amount).toFormat(decimal, fmt)}</td>
                </tr>
              )) : null}
              {/* <tr>
                <td className="text-capitalize-first">{I18n.get('total', 'total')}</td>
                <td>{new BigNumber(invoice.total).toFormat(decimal, fmt)}</td>
              </tr> */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('totalReceived', 'total recibido')}</td>
                <td>{new BigNumber(invoice.totalReceived).toFormat(decimal, fmt)}</td>
              </tr>
              <tr>
                <td className="text-capitalize-first">{I18n.get('cashReturned', 'cambio')}</td>
                <td>{new BigNumber(invoice.cashReturned).toFormat(decimal, fmt)}</td>
              </tr>
            </tbody>
          </table>

          <table className="detail-summary-table rounded-lg mb-4">
            <tbody>
              {/* {(itemsValues.discount.gt(0) || !!itemsValues.taxes.length) && ( */}
              <tr>
                <td className="text-capitalize-first">{country === 'spain' ? I18n.get('taxableIncome', 'Base imponible') : I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.subtotal.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {/* {itemsValues.discount.gt(0) && ( */}
              <tr>
                <td className="text-capitalize-first">{I18n.get('discount', 'descuento')}</td>
                <td>{itemsValues.discount.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {/* {itemsValues.discount.gt(0) && ( */}
              <tr>
                <td className="text-capitalize-first">{country === 'spain' ? I18n.get('discountedBasis', 'Base con descuento') : I18n.get('subtotal', 'subtotal')}</td>
                <td>{itemsValues.discSubtotal.toFormat(decimal, fmt)}</td>
              </tr>
              {/* )} */}
              {itemsValues.taxes.map((tax, index) => (
                <tr key={index}>
                  <td>{tax.name}</td>
                  <td>{tax.value.toFormat(decimal, fmt)}</td>
                </tr>
              ))}
              <tr>
                <td className="text-capitalize-first font-weight-bold">{I18n.get('total', 'total')}</td>
                <td className="font-weight-bold">{itemsValues.total.toFormat(decimal, fmt)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

DetailBody.propTypes = {
  invoice: PropTypes.object
}

export default DetailBody;