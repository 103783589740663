// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="25" 
    viewBox="0 0 24 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="24" height="24" rx="12" fill="#F8FAFC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 6C10.2511 6 8.83333 7.41777 8.83333 9.16667C8.83333 10.9156 10.2511 12.3333 12 12.3333C13.7489 12.3333 15.1667 10.9156 15.1667 9.16667C15.1667 7.41777 13.7489 6 12 6ZM9.83333 9.16667C9.83333 7.97005 10.8034 7 12 7C13.1966 7 14.1667 7.97005 14.1667 9.16667C14.1667 10.3633 13.1966 11.3333 12 11.3333C10.8034 11.3333 9.83333 10.3633 9.83333 9.16667Z" fill="#94A3B8"/>
    <path d="M9.1346 15.6346C9.54093 15.2283 10.092 15 10.6667 15H13.3333C13.908 15 14.4591 15.2283 14.8654 15.6346C15.2717 16.0409 15.5 16.592 15.5 17.1667V18.5C15.5 18.7761 15.7239 19 16 19C16.2761 19 16.5 18.7761 16.5 18.5V17.1667C16.5 16.3268 16.1664 15.5214 15.5725 14.9275C14.9786 14.3336 14.1732 14 13.3333 14H10.6667C9.82681 14 9.02136 14.3336 8.4275 14.9275C7.83363 15.5214 7.5 16.3268 7.5 17.1667V18.5C7.5 18.7761 7.72386 19 8 19C8.27614 19 8.5 18.7761 8.5 18.5V17.1667C8.5 16.592 8.72827 16.0409 9.1346 15.6346Z" fill="#94A3B8"/>
  </svg>
)
