function BoxSvg({ id, className, size }) {
  return (
    <svg
      id={id}
      className={className}
      width={size ?? '16'}
      height={size ?? '18'}
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.30658 0.955265C8.11632 0.848245 7.88401 0.848245 7.69375 0.955265L1.03725 4.69955C0.952279 4.7452 0.877018 4.81096 0.81936 4.89462C0.791873 4.9344 0.76905 4.97728 0.751401 5.0224C0.7225 5.09609 0.708557 5.17285 0.708496 5.24884V12.75C0.708496 12.9758 0.830285 13.184 1.02708 13.2947L7.68397 17.0392C7.77673 17.0937 7.8848 17.125 8.00016 17.125C8.11553 17.125 8.22359 17.0937 8.31635 17.0392L14.9732 13.2947C15.17 13.184 15.2918 12.9758 15.2918 12.75V5.26597C15.2947 5.15685 15.2691 5.04555 15.2117 4.94349C15.1506 4.83484 15.0618 4.75151 14.9592 4.69735L11.6551 2.83882C11.6455 2.83306 11.6357 2.82756 11.6258 2.82234L8.30658 0.955265ZM10.0586 3.37498L8.00016 2.21709L2.60846 5.24992L4.66694 6.40781L10.0586 3.37498ZM4.35071 7.66412C4.35686 7.66772 4.36306 7.6712 4.36931 7.67458L7.37516 9.36537V15.4313L1.9585 12.3845V6.3185L4.35071 7.66412ZM5.94177 7.12491L11.3335 4.09208L13.392 5.24998L8.00027 8.28281L5.94177 7.12491ZM14.0418 12.3845L8.62516 15.4313V9.3655L14.0418 6.31862V12.3845Z'
        fill='#94A3B8'
      />
    </svg>
  );
}

export default BoxSvg;
