import { get } from 'lodash';

import countries from '../../countriesData/general/countries';
import colombiaAddresses from '../../countriesData/colombia/citiesDepartments.json';
import costaRicaAddresses from '../../countriesData/costaRica/districtsCantonsProvinces.json';
import neighborhoods from '../../countriesData/costaRica/dataNeighborhood';
import argentinaProvinces from '../../countriesData/argentina/provinces';
import argentinaCities from '../../countriesData/argentina/cities.json';
import peruAddresses from '../../countriesData/peru/address.json';
import dominicanaAddresses from '../../countriesData/republicaDominicana/municipalityProvince.json';
import spainAddresses from '../../countriesData/spain/municipalityProvince.json';
import { idTypes as identificationsColombia } from '../../countriesData/colombia/idTypes';
import kindOfPersonOptions from '../../countriesData/colombia/kindOfPerson';
import { regimes } from '../../countriesData/colombia/regimes';
import identificationsCostaRica from '../../countriesData/costaRica/identificationTypes';
import identificationsPeru from '../../countriesData/peru/identificationTypes';
import identificationsArgentina from '../../countriesData/argentina/identificationTypes';
import identificationsDominicana from '../../countriesData/republicaDominicana/identificationTypes';
import identificationsSpain from '../../countriesData/spain/identificationTypes';
import ivaConditions from '../../countriesData/argentina/ivaConditions';
import { fiscalResponsabilities } from '../../countriesData/colombia/fiscalResponsabilities';

const initialValuesPerCountry = props => {

  const getKindOfPersonAndRegimeColombia = (identification) => {
    switch (identification) {
      case 'DIE':
        return {
          kindOfPerson: kindOfPersonOptions.find(option => option.key === 'PERSON_ENTITY'),
          regime: regimes.find(option => option.key === 'COMMON_REGIME'),
        }
      case 'FOREIGN_NIT':
        return {
          kindOfPerson: kindOfPersonOptions.find(option => option.key === 'LEGAL_ENTITY'),
          regime: regimes.find(option => option.key === 'SIMPLIFIED_REGIME'),
        }

      default:
        return {
          kindOfPerson: kindOfPersonOptions.find(option => option.key === 'PERSON_ENTITY'),
          regime: regimes.find(option => option.key === 'SIMPLIFIED_REGIME'),
        }
    }
  }

  switch (props.country) {

    case 'colombia':
      const identification = get(props, 'values.current.identification.type.key', '');
      return {
        identification: {
          type: identificationsColombia(props.isElectronic).find(idTypes => idTypes.key === 'CC'),
        },
        kindOfPerson: getKindOfPersonAndRegimeColombia(identification).kindOfPerson,
        // kindOfPerson: identification === 'FOREIGN_NIT' ? kindOfPersonOptions.find(option => option.key === 'LEGAL_ENTITY') : kindOfPersonOptions.find(option => option.key === 'PERSON_ENTITY'),
        regime: getKindOfPersonAndRegimeColombia(identification).regime,
        // regime: regimes.find(option => option.key === 'SIMPLIFIED_REGIME'),
        // address: {
        //   country: countries.find(option => option.key === 'COL'),
        //   combined: colombiaAddresses.find(option =>
        //     option.value === `${get(props, 'companyAddress.city', null)}, ${get(props, 'companyAddress.department', null)}`),
        // },
        defaultAddress: {
          country: countries.find(option => option.key === 'COL'),
          combined: colombiaAddresses.find(option =>
            option.value === `${get(props, 'companyAddress.city', null)}, ${get(props, 'companyAddress.department', null)}`),
        }
      }
    case 'peru':
      return {
        address: {
          country: countries.find(option => option.key === 'PER'),
          combined: peruAddresses.find(option =>
            option.value === `${get(props, 'companyAddress.district', null)}, ${get(props, 'companyAddress.city', null)}, ${get(props, 'companyAddress.state', null)}, ${get(props, 'companyAddress.ubigeoCode', null)}`),
        }
      }
    case 'argentina':
      return {
        address: {
          country: countries.find(option => option.key === 'ARG'),
          province: argentinaProvinces.find(option =>
            option.value === get(props, 'companyAddress.province', null)),
          city: argentinaCities.find(option =>
            option.value === get(props, 'companyAddress.city', null)),
        }
      }
    case 'costaRica':
      return {
        address: {
          country: countries.find(option => option.key === 'CRI'),
          combined: costaRicaAddresses.find(option =>
            option.value === `${get(props, 'companyAddress.district', null)}, ${get(props, 'companyAddress.city', null)}, ${get(props, 'companyAddress.department', null)}`),
        }
      }
    case 'republicaDominicana':
      return {
        address: {
          country: countries.find(option => option.key === 'DOM'),
          combined: dominicanaAddresses.find(option =>
            option.value === `${get(props, 'companyAddress.municipality', null)}, ${get(props, 'companyAddress.province', null)}`),
        },
        isElectronic: props.isElectronic
      }

    case 'panama':
      return {
        isCorrectRuc: null,
        isElectronic: props.isElectronic
      }
    case 'spain':
      return {
        identification: {
          type: identificationsSpain.find(
            (idTypes) =>
              idTypes.key ===
              get(props, 'contact.identificationObject.type', 'DNI')
          ),
          number: get(props, 'contact.identificationObject.number', ''),
        },
        address: {
          country: countries.find(
            (option) =>
              option.value === get(props, 'contact.address.country', null)
          ),
          combined: spainAddresses.find(
            option => option.value === get(props, 'contact.address.combined', null)
          ),
          postalCode: get(props, 'contact.address.postalCode', ''),
          address: get(props, 'contact.address.address', ''),
          city: get(props, 'contact.address.city', ''),
        },
      };

    default:
      return {};
  }
}

const convertOfflineContact = props => {
  switch (props.country) {
    case 'colombia':
      const kindOfPerson = get(props, 'contact.kindOfPerson', null);
      let fiscalR = new Array(115);
      fiscalResponsabilities.map(item => {
        fiscalR[item.id] = get(props, `contact.fiscalResponsabilities`, []).includes('' + item.id);
        return null;
      })

      return {
        identification: {
          type: identificationsColombia(props.isElectronic).find(option =>
            option.key === get(props, 'contact.identificationObject.type', null)),
          number: get(props, 'contact.identificationObject.number', null)
        },
        firstName: !!get(props, 'contact.name.firstName', null)
          ? get(props, 'contact.name.firstName', null)
          : get(props, 'contact.name', null),
        secondName: get(props, 'contact.name.secondName', null),
        lastName: get(props, 'contact.name.lastName', null),
        kindOfPerson: kindOfPersonOptions.find(option =>
          option.key === kindOfPerson),
        address: {
          country: countries.find(option =>
            option.value === get(props, 'contact.address.country', null)),
          combined: colombiaAddresses.find(option =>
            option.value === `${get(props, 'contact.address.city', null)}, ${get(props, 'contact.address.department', null)}`),
          zipCode: get(props, 'contact.address.zipCode', null),
          address: get(props, 'contact.address.address', null),
        },
        regime: regimes.find(option =>
          option.key === get(props, 'contact.regime', null)),
        fiscalResponsabilities: fiscalR,
      }

    case 'costaRica':
      return {
        identification: {
          type: identificationsCostaRica.find(option =>
            option.key === get(props, 'contact.identificationObject.type', null)),
          number: get(props, 'contact.identificationObject.number', null)
        },
        address: {
          neighborhood: neighborhoods.find(option =>
            option.value === get(props, 'contact.address.neighborhood', null)),
          combined: costaRicaAddresses.find(option =>
            option.value === `${get(props, 'contact.address.district', null)}, ${get(props, 'contact.address.city', null)}, ${get(props, 'contact.address.department', null)}`),
          address: get(props, 'contact.address.address', null),
        },
      }

    case 'argentina':
      return {
        identification: {
          type: identificationsArgentina.find(option =>
            option.key === get(props, 'contact.identificationObject.type', null)),
          number: get(props, 'contact.identificationObject.number', null)
        },
        address: {
          province: argentinaProvinces.find(option =>
            option.value === get(props, 'contact.address.province', null)),
          city: argentinaCities.find(option =>
            option.value === get(props, 'contact.address.city', null)),
          postalCode: get(props, 'contact.address.postalCode', null),
          address: get(props, 'contact.address.address', null),
        },
        ivaCondition: ivaConditions.find(option =>
          option.key === get(props, 'contact.ivaCondition', null)),
      }

    case 'peru':
      return {
        identification: {
          type: identificationsPeru.find(option =>
            option.key === get(props, 'contact.identification.type', null)),
          number: get(props, 'contact.identification.number', null)
        },
        address: {
          country: countries.find(option =>
            option.value === get(props, 'contact.address.country', null)),
          combined: peruAddresses.find(option =>
            option.value === `${get(props, 'contact.address.district', null)}, ${get(props, 'contact.address.city', null)}, ${get(props, 'contact.address.state', null)}, ${get(props, 'contact.address.ubigeoCode', null)}`),
          urbanization: get(props, 'contact.address.urbanization', null),
          address: get(props, 'contact.address.address', null),
        },
      }
    case 'spain':
      return {
        identification: {
          type: identificationsSpain.find(
            (idTypes) =>
              idTypes.key ===
              get(props, 'contact.identificationObject.type', 'DNI')
          ),
          number: get(props, 'contact.identificationObject.number', ''),
        },
        address: {
          country: countries.find(
            (option) =>
              option.value === get(props, 'contact.address.country', null)
          ),
          combined: spainAddresses.find(
            (option) =>
              option.value === get(props, 'contact.address.combined', null)
          ),
          postalCode: get(props, 'contact.address.postalCode', ''),
          address: get(props, 'contact.address.address', ''),
          city: get(props, 'contact.address.city', ''),
        },
      };

    case 'republicaDominicana':
      let offlineDominicanaContact = {};

      offlineDominicanaContact = {
        identification: {
          type: identificationsDominicana.find(option =>
            option.key === get(props, 'contact.identification.type', null)),
          number: get(props, 'contact.identification.number', null)
        },
        address: {
          country: countries.find(option =>
            option.value === get(props, 'contact.address.country', null)),
          combined: {
            value: `${get(props, 'contact.address.municipality', null)}, ${get(props, 'contact.address.province', null)}`,
            label: `${get(props, 'contact.address.municipality', null)}, ${get(props, 'contact.address.province', null)}`,
          },
          address: get(props, 'contact.address.address', null),
        },
      }

      if (!get(props, 'contact.address.municipality',) || !get(props, 'contact.address.province', null))
        delete offlineDominicanaContact?.address?.combined;

      return offlineDominicanaContact;

    default:
      return {};
  }
}

const initialValues = (props) => {
  if (!!get(props, 'contact', null)) {
    return {
      term: props.paymentTerms.find(term => term.id === get(props, 'contact.term', null)) || null,
      priceList: props.priceLists.find(priceList => priceList.id === get(props, 'contact.priceList', null)) || null,
      seller: props.sellers.find(seller => seller.id === get(props, 'contact.seller', null)) || null,
      phonePrimary: get(props, 'contact.phonePrimary', null),
      phoneSecondary: get(props, 'contact.phoneSecondary', null),
      email: get(props, 'contact.email', null),
      firstName: get(props, 'contact.name', null),
      identification: get(props, 'contact.identification', null),
      statementAttached: get(props, 'contact.statementAttached', null) === 'yes',
      accounting: {
        accountReceivable: props.accountReceivableOptions.find(cat => cat?.id === get(props, 'contact.accounting.accountReceivable')) || null,
        debtToPay: props.debtToPayOptions.find(cat => cat?.id === get(props, 'contact.accounting.debtToPay')) || null,
      },
      ...convertOfflineContact(props)
    }
  }

  return {
    statementAttached: true,
    term: props.paymentTerms.find(term => term.id === '1') || null,
    priceList: props.priceLists.find(term => term.id === '1') || null,
    accounting: {
      accountReceivable: get(props, 'defaultAccountReceivableCategory', null),
      debtToPay: get(props, 'defaultDebtToPayCategory', null)
    },
    ...initialValuesPerCountry(props),
  }
}

export default initialValues;