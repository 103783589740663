import React from 'react';
import PropTypes from 'prop-types';

import CloseSVG from '../svg/Close';

function Notification({ onClose, type, body, withIcon }) {
  return (
    <div className='main-notifications'>
      <div
        className={`notification notification-${type} ${
          withIcon && 'with-icon'
        }`}
      >
        <div className='notification-body text-center'>{body}</div>

        {!!onClose && (
          <button
            type='button'
            className='btn button-transparent btn-sm close-button'
            onClick={() => onClose()}
          >
            <CloseSVG className='icon-gray' />
          </button>
        )}
      </div>
    </div>
  );
}

Notification.propTypes = {
  onClose: PropTypes.func,
  type: PropTypes.string,
  body: PropTypes.element,
  withIcon: PropTypes.bool,
};

Notification.defaultProps = {
  withIcon: false,
};

export default Notification;
