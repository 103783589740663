import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import { BigNumber } from "bignumber.js";
import dayjs from 'dayjs';

import { decimalPrecision, dateFormat as dateFormatSelector } from '../../selectors/company';

const Refund = ({ refund, idSelected, onSelect }) => {
  const decimal = useSelector(decimalPrecision);
  const dateFormat = useSelector(dateFormatSelector);

  if (!refund) return null;

  const prefix = !!get(refund, 'currency.symbol')
    ? get(refund, 'currency.symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : null;

  const getNumber = () => {
    const valuePrefix = I18n.get('number', 'número');

    const number = get(refund, 'numberTemplate.number', null);
    const fullNumber = get(refund, 'numberTemplate.fullNumber', null);
    const prefix = !!get(refund, 'numberTemplate.prefix', null)
      ? get(refund, 'numberTemplate.prefix', null): '';

    if (!!fullNumber)
      return `${valuePrefix || ''} ${fullNumber || ''}`

    return `${valuePrefix || ''} ${prefix || ''}${number || ''}`
  }

  return (
    <div 
      className={`refund p-3 d-flex justify-content-between align-items-center ${+idSelected === +refund.id ? 'selected' : ''}`}
      onClick={() => onSelect(refund)}
    >
      <div className="title-container d-flex flex-column w-75">
        <div className="title text-break text-nowrap text-truncate text-capitalize-first">
          {getNumber()}
        </div>
        <div className="text-break text-wrap text-capitalize-first">
          {dayjs(refund.dateTime).format(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))}
        </div>
      </div>
      <div className="">
        <div className="price text-break text-nowrap text-capitalize-first text-right">
          {new BigNumber(refund.total).toFormat(decimal, fmt)}
        </div>
      </div>
    </div>
  )
}

Refund.propTypes = {
  refund: PropTypes.object.isRequired,
  idSelected: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
}

export default Refund;
