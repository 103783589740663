import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash'

import { closeModal } from '../../../reducers/modals';
import Modal from '../../common/Modal';
import { Activate5UVTLoading, Activate5UVTOnboard, StationSettings } from './steps';
import { graphqlOperation } from '@aws-amplify/api';
import * as mutations from '../../../graphql/mutations'
import { setUser } from '../../../reducers/auth'


import image5UVTModal from '../../../assets/images/image_5UVT_activate_modal.png'
import { APIGraphqlSelector, stationFENumberTemplate } from '../../../selectors/app';

const Activate5UVT = () => {
  const dispatch = useDispatch();
  // const isOpen = true;
  const isOpen = useSelector(state => get(state, 'modals.activate5UVT.isOpen', false) || get(state, 'modals.stationSetting5UVT.isOpen', false));
  const isOpenStationSetting5UVT = useSelector(state => get(state, 'modals.stationSetting5UVT.isOpen', false));
  const feNumeration = useSelector(stationFENumberTemplate)
  const [step, setStep] = useState(0)
  const [error, setError] = useState(null)
  const APIGraphql = useSelector(APIGraphqlSelector);

  const activate5uvtInPOS = useCallback(async () => {
    const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
      user: { 
        company: {
          settings: {
            isActiveAutomationElectronicInvoice: true,
            automationElectronicInvoiceIdResolution: get(feNumeration, 'id', null),
          },
          localSettings: {
            automationFEEnabled: true
          }
        }
      }
    }))
    if (!!get(response, 'data.updateUser.id', null))
      dispatch(setUser(get(response, 'data.updateUser')))
    else 
      throw new Error("error");
  }, [dispatch, feNumeration, APIGraphql])

  const close = useCallback(() => {
    if (isOpenStationSetting5UVT)
      activate5uvtInPOS()

    dispatch(closeModal({ modal: 'activate5UVT' }))
    dispatch(closeModal({ modal: 'stationSetting5UVT' }))
  }, [dispatch, isOpenStationSetting5UVT, activate5uvtInPOS])

  const next = useCallback(() => {
    setStep(step => step + 1);
  }, [])

  const back = useCallback(() => {
    setStep(step => step - 1);
  }, [])

  useEffect(() => {
    if (isOpenStationSetting5UVT)
      setStep(2);
  }, [isOpenStationSetting5UVT])

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeAction={close}
        headerImg={image5UVTModal}
        className={"modal__activate-5uvt"}
      >
        {step === 0 && (
          <Activate5UVTOnboard nextStep={next} back={back} close={close} error={error} setError={setError} activate5uvt={activate5uvtInPOS}/>
        )}
        {step === 1 && (
          <Activate5UVTLoading nextStep={next} />
        )}
        {step === 2 && (
          <StationSettings close={close} isOpenStationSetting5UVT={isOpenStationSetting5UVT} activate5uvt={activate5uvtInPOS}/>
        )}
      </Modal>
    </>
  )
}

export default Activate5UVT;