import PropTypes from 'prop-types';

/**
 * Button Component
 * @param {Object} props - Component props.
 * @param {boolean} props.disabled - Specifies whether the button is disabled.
 * @param {'submit' | 'reset' | 'button'} props.type - The type of the button
 * @param {'filled'|'subtle'|'outline'|'ghost'} props.variant - The variant of the button
 * @param {'icon' | 'sm' | 'md' | 'lg'} props.size - The size of the button
 * @param {'default' | 'destructive'} props.buttonType - The size of the button
 * @param {string} props.name - The name attribute for the button
 * @param {string} props.value - The value attribute for the button
 * @param {string} props.className - CSS class to apply to the button.
 * @param {string} props.id - The unique identifier for the button.
 * @param {Object} props.style - Inline styles for the button.
 * @param {function} props.onClick - Function to be executed when the button is clicked.
 * @param {function} props.onFocus - Function to be executed when the button gains focus.
 * @param {function} props.onBlur - Function to be executed when the button loses focus.
 * @returns {JSX.Element} A button element.
 */
function Button({
  children,
  size = 'md',
  variant = 'filled',
  buttonType = 'default',
  ...props
}) {
  return (
    <button
      {...props}
      className={`btn-${size}-${buttonType}-${variant} ${
        props.className || ''
      }`}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  size: PropTypes.oneOf(['icon', 'sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['filled', 'subtle', 'outline', 'ghost']),
  buttonType: PropTypes.oneOf(['default', 'destructive']),
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Button;
