import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { decimalPrecision } from '../../../../selectors/company'
import { getMainCurrency } from '../../../../selectors/currencies'
import { currency } from '../../../../selectors/editInvoice';

const Total = ({ form, values, total }) => {
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-3 position-relative">
      <div className="d-flex align-items-center">
        <p className="h3 text-muted text-uppercase mr-2">
          {I18n.get('total', 'total')}
        </p>
        <h1>
          {total.toFormat(decimal, fmt)}
        </h1>
      </div>

      {!!values.method && (
        <>
          <button
            type="button"
            className="btn button-transparent position-absolute modal__invoice-btn-change d-none d-sm-block"
            onClick={() => {
              form.setConfig('keepDirtyOnReinitialize', false);
              form.reset();
              form.setConfig('keepDirtyOnReinitialize', true);
            }}
          >
            {I18n.get('changeMethod', 'cambiar método')}
          </button>

          <button
            type="button"
            className="btn button-transparent modal__invoice-btn-change d-sm-none"
            onClick={() => {
              form.setConfig('keepDirtyOnReinitialize', false);
              form.reset();
              form.setConfig('keepDirtyOnReinitialize', true);
            }}
          >
            {I18n.get('changeMethod', 'cambiar método')}
          </button>
        </>
      )}
    </div>
  )
}

export default Total;