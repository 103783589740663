import React from 'react'
import { I18n } from '@aws-amplify/core';

const ImageOrQr = ({imageOrQr, isElectronic}) => {
  return (
    <>
      { isElectronic && 
        <p className='text-center mt-4'>{I18n.get('printedRepresentationOfElectronicCreditNote', 'Representación impresa de la nota crédito electrónica')}</p>
      }
      <div className="d-flex justify-content-center align-items-center mr-5 ml-5 mb-2">
        {
          !!imageOrQr && (
            <div style={{width: '120px', maxHeight: '150px'}}>
              <img 
                src={imageOrQr}
                style={{maxWidth: "100%", height: "auto", aspectRatio: "auto"}}
                alt={"Custom icon for company"}
              />
            </div>
          )
        }
      </div>
    </>
  )
};

export default ImageOrQr;