function WarningCircleSvg({ id, className }) {
    return (
      <svg
        id={id}
        className={className}
        width='17'
        height='17'
        viewBox="0 0 20 20" 
        fill='none'
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10V6C9.25 5.58579 9.58579 5.25 10 5.25Z"/>
        <path d="M10 13.25C9.58579 13.25 9.25 13.5858 9.25 14C9.25 14.4142 9.58579 14.75 10 14.75H10.01C10.4242 14.75 10.76 14.4142 10.76 14C10.76 13.5858 10.4242 13.25 10.01 13.25H10Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10ZM10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75Z"/>
      </svg>
    );
  }
  
  export default WarningCircleSvg;
  