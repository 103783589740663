import React from 'react'
import { I18n } from '@aws-amplify/core';
import { get, isString } from 'lodash'

import Bottom from '../../common/Bottom'
import Modal from '../common/Modal'

const getContactIdentification = (contact, country) => {
  if (country === 'colombia' || country === 'costaRica' || country === 'argentina')
    return `${get(contact, 'identificationObject.type', null)} ${get(contact, 'identificationObject.number', null)}`
  if (country === 'peru')
    return `${get(contact, 'identification.type', null) || get(contact, 'identificationObject.type', null)} ${get(contact, 'identification.number', null) || get(contact, 'identificationObject.number', null)}`
  if (country === 'republicaDominicana') {
    const hasIdentificationType = !!(get(contact, 'identification.type', null) || get(contact, 'identificationObject.type', null))
    return hasIdentificationType 
      ? `${get(contact, 'identification.type', null) || get(contact, 'identificationObject.type', null)} ${get(contact, 'identification.number', null) || get(contact, 'identificationObject.number', null)}`
      : `${get(contact, 'identification', null)}`
  }
  return `${get(contact, 'identification', null)}`
}

const getContactName = (contact, country) => {
  const name = get(contact, 'name', null)
  if (!name) return null;

  if (isString(name)) return name

  if (country === 'colombia')
    return `${get(name, 'firstName', null)} ${get(name, 'secondName', null)} ${get(name, 'lastName', null)}`
  return null
}

const ContactCreated = ({ isOpen, onClose, onConfirm, contact, country }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={I18n.get('lookOut', 'Cuidado')}
    >
      <div className="w-100 h-100 bg-white p-5 text-center">
        <p className="text-capitalize-first m-0 h4 mb-2">
          {I18n.get('contactAlreadyExists1', 'El contacto con la identificación')}
          {' '}
          <strong>{getContactIdentification(contact, country)}</strong>
          {' '}
          {I18n.get('contactAlreadyExists2', 'ya existe. Su nombre es')}
          {' '}
          <strong>{getContactName(contact, country)}</strong>
        </p>
        <p className="text-capitalize-first m-0 h4">
          {I18n.get('doYouWantToCreateAnotherContactWithSameId', '¿Estás seguro que deseas crear otro contacto con ese número de identificación?')}
        </p>
      </div>

      <Bottom
        onClose={() => onClose()}
        onConfirm={() => onConfirm()}
        submitText={I18n.get('understood', 'entendido')}
        hideRequired
      />
    </Modal>
  )
}

export default ContactCreated;