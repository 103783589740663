
import { get } from 'lodash';
import { idTypes as identificationsColombia } from '../../countriesData/colombia/idTypes';
import kindOfPersonOptions from '../../countriesData/colombia/kindOfPerson';
import colombiaAddresses from '../../countriesData/colombia/citiesDepartments.json';
import { regimes } from '../../countriesData/colombia/regimes';

export const initialValues = props => {
    const idType = get(props.contact, 'identificationObject.type', null) || 'CC';
    const kindOfPerson = get(props.contact, 'kindOfPerson', null) || 'PERSON_ENTITY';
    return {
        identification: {
          type: identificationsColombia(props.isElectronic).find(idTypes => idTypes.key === idType),
          number: get(props.contact, 'identificationObject.number', null),
          dv: get(props.contact, 'identificationObject.dv', null),
        },
        address: {
            combined: colombiaAddresses.find(option =>
                option.value === `${get(props.contact, 'address.city', null)}, ${get(props.contact, 'address.department', null)}`),
            address: get(props, 'contact.address.address', null),
        },
        firstName: !!get(props, 'contact.name.firstName', null)
          ? get(props, 'contact.name.firstName', null)
          : get(props, 'contact.name', null),
        secondName: get(props, 'contact.name.secondName', null),
        lastName: get(props, 'contact.name.lastName', null),
        kindOfPerson: kindOfPersonOptions.find(option => option.key === kindOfPerson),
        regime: regimes.find(option => option.key === get(props.contact, 'regime', null)),
    }
}