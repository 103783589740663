// eslint-disable-next-line
export default ({ id, className, width = 24, height = 24}) => (
  <svg 
    id={id}
    className={className}
    width={width} 
    height={height} 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.75 12l-7.959 8L7 17.223 12.196 12 7 6.777 9.791 4l7.96 8z" />
  </svg>
)