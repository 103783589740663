import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import BigNumber from 'bignumber.js';
import { get } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import {
  currency as currencySelector,
  itemsCount as itemsCountSelector,
  numeration as numerationSelector
} from '../../../selectors/activeInvoice';
import { currency as companyCurrencySelector } from '../../../selectors/company';
import { sendGTMEvent } from '../../../reducers/company';

import { country as countrySelector, decimalPrecision} from '../../../selectors/company';
import { decimalsVersionsGroup } from '../../../selectors/deployGroups';
import Cash from '../../svg/paymentMethods/Cash'
import Card from '../../svg/paymentMethods/Card'
import Combined from '../../svg/paymentMethods/Combined'

const Methods = ({ form, total }) => {
  const country = useSelector(countrySelector)
  const decimal = useSelector(decimalPrecision)
  const dispatch = useDispatch();
  const numeration = useSelector(numerationSelector);
  const currency = useSelector(currencySelector);
  const companyCurrency = useSelector(companyCurrencySelector);
  const itemsCount = useSelector(itemsCountSelector);

  const sendPaymentMethodSelectedEvent = (paymentMethod) => {
    dispatch(sendGTMEvent("customer-payment-method-selected", {
      isElectronicInvoice: get(numeration, 'isElectronic', false),
      customerPaymentMethod: paymentMethod,
      currency: get(currency, 'code', get(companyCurrency, 'code')),
      invoiceTotal: parseFloat(total),
      totalItems: itemsCount,
    }))
  }


  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row align-items-center justify-content-around">
        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              data-testid="cash-method-btn"
              className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
              onClick={() => {
                sendPaymentMethodSelectedEvent('cash')
                props.input.onChange('cash')
              }}
            >
              <Cash />
              <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('cash', 'efectivo')}</p>
            </button>
          )}
        </Field>

        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              data-testid="card-method-btn"
              className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
              onClick={() => {
                sendPaymentMethodSelectedEvent('debit')
                props.input.onChange('debit')
                form.change('debit', total)
                if (decimalsVersionsGroup.includes(country))
                  form.change('debit', new BigNumber(total).toFixed(decimal).toString())
              }}
            >
              <Card />
              <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('card', 'tarjeta')}</p>
            </button>
          )}
        </Field>

        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              data-testid="combined-method-btn"
              className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
              onClick={() => {
                sendPaymentMethodSelectedEvent('combined')
                props.input.onChange('combined')
              }}
            >
              <Combined />
              <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('combined', 'combinado')}</p>
            </button>
          )}
        </Field>
      </div>
    </div>
  )
}

export default Methods;