function ArrowCross ({id, className}) {
  return (
    <svg 
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none">
      <path d="M12.7085 3.33334C12.7085 2.98817 12.9883 2.70834 13.3335 2.70834H16.6668C16.7516 2.70834 16.8324 2.72521 16.9061 2.75577C16.9798 2.78627 17.0488 2.83148 17.1088 2.8914C17.2308 3.01344 17.2918 3.17339 17.2918 3.33334V6.66668C17.2918 7.01185 17.012 7.29168 16.6668 7.29168C16.3217 7.29168 16.0418 7.01185 16.0418 6.66668V4.84223L12.9421 7.94195C12.698 8.18603 12.3023 8.18603 12.0582 7.94195C11.8141 7.69787 11.8141 7.30215 12.0582 7.05807L15.1579 3.95834H13.3335C12.9883 3.95834 12.7085 3.67852 12.7085 3.33334Z" fill="#334155"/>
      <path d="M7.9421 12.942C8.18618 12.6979 8.18618 12.3021 7.9421 12.0581C7.69803 11.814 7.3023 11.814 7.05822 12.0581L2.89155 16.2247C2.64748 16.4688 2.64748 16.8645 2.89155 17.1086C3.13563 17.3527 3.53136 17.3527 3.77544 17.1086L7.9421 12.942Z" fill="#334155"/>
      <path d="M17.2918 13.3333V16.6667C17.2918 16.7514 17.275 16.8322 17.2444 16.9059C17.2139 16.9796 17.1687 17.0487 17.1088 17.1086C17.0488 17.1685 16.9798 17.2138 16.9061 17.2443C16.8418 17.2709 16.772 17.2872 16.699 17.2909C16.6883 17.2914 16.6776 17.2917 16.6668 17.2917H13.3335C12.9883 17.2917 12.7085 17.0119 12.7085 16.6667C12.7085 16.3215 12.9883 16.0417 13.3335 16.0417H15.1579L2.89155 3.77529C2.64748 3.53121 2.64748 3.13548 2.89155 2.8914C3.13563 2.64732 3.53136 2.64732 3.77544 2.8914L16.0418 15.1578V13.3333C16.0418 12.9882 16.3217 12.7083 16.6668 12.7083C17.012 12.7083 17.2918 12.9882 17.2918 13.3333Z" fill="#334155"/>
    </svg>
  )
}

export default ArrowCross