// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="17"
    height="16"
    viewBox="0 1.372 17.38 14.073"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M 6.234 1.564 C 6.082 1.479 5.896 1.479 5.744 1.564 L 0.422 4.558 C 0.34 4.601 0.269 4.668 0.22 4.755 C 0.174 4.836 0.153 4.925 0.156 5.012 L 0.156 11 C 0.156 11.181 0.253 11.347 0.411 11.436 L 5.736 14.431 C 5.81 14.475 5.897 14.5 5.989 14.5 C 6.081 14.5 6.168 14.475 6.242 14.431 L 7.023 13.992 C 7.052 13.824 7.099 13.658 7.164 13.499 C 7.188 13.439 7.216 13.382 7.248 13.326 L 7.771 12.424 L 6.489 13.145 L 6.489 8.292 L 10.822 5.855 L 10.822 7.159 C 11.026 6.817 11.313 6.532 11.656 6.329 C 11.711 6.297 11.766 6.267 11.822 6.24 L 11.822 5 C 11.822 4.819 11.725 4.653 11.568 4.564 L 6.234 1.564 Z M 5.489 13.145 L 5.489 8.292 L 1.156 5.855 L 1.156 10.708 L 5.489 13.145 Z M 10.302 5 L 5.989 2.574 L 1.676 5 L 5.989 7.426 L 10.302 5 Z" fill="#94A3B8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M 12.38 6.449 C 12.134 6.449 11.893 6.515 11.681 6.639 C 11.471 6.763 11.297 6.941 11.179 7.155 L 7.63 13.277 C 7.622 13.292 7.614 13.307 7.607 13.324 C 7.525 13.527 7.492 13.747 7.512 13.965 C 7.532 14.184 7.603 14.394 7.721 14.579 C 7.839 14.764 7.999 14.918 8.189 15.029 C 8.378 15.139 8.591 15.203 8.81 15.214 C 8.826 15.215 8.842 15.215 8.857 15.214 C 8.866 15.214 8.876 15.215 8.885 15.215 L 15.885 15.215 L 15.887 15.215 C 16.112 15.213 16.332 15.157 16.53 15.05 C 16.727 14.944 16.896 14.791 17.021 14.604 C 17.145 14.418 17.223 14.204 17.246 13.981 C 17.269 13.757 17.237 13.532 17.152 13.324 C 17.146 13.308 17.138 13.292 17.129 13.277 L 13.581 7.155 C 13.462 6.941 13.289 6.763 13.078 6.639 C 12.867 6.515 12.625 6.449 12.38 6.449 Z" fill="#FBBF24" />
    <path d="M 12.76 9.84 C 12.76 9.633 12.592 9.465 12.385 9.465 C 12.178 9.465 12.01 9.633 12.01 9.84 L 12.01 10.84 C 12.01 11.047 12.178 11.215 12.385 11.215 C 12.592 11.215 12.76 11.047 12.76 10.84 L 12.76 9.84 Z" fill="#0F172A" />
    <path d="M 12.76 12.84 C 12.76 12.633 12.592 12.465 12.385 12.465 C 12.178 12.465 12.01 12.633 12.01 12.84 L 12.01 12.845 C 12.01 13.052 12.178 13.22 12.385 13.22 C 12.592 13.22 12.76 13.052 12.76 12.845 L 12.76 12.84 Z" fill="#0F172A" />
  </svg>
)
