import React from 'react'
import { useSelector } from 'react-redux';

import { country as countrySelector } from '../../../selectors/company';
import { getNumber } from '../../../utils/invoices';
import Input from '../../svg/Input';

const Header = ({ invoice }) => {
  const country = useSelector(countrySelector)

  return (
    <div className="detail-header">
      <div className="px-4 pt-4 d-flex justify-content-between align-items-top w-100">
        <div className="d-flex align-items-bottom w-100">
          <div className="pr-1">
            <Input className="icon-grayblue7" width="17.5" height="17.5" />
          </div>
          <h2 className="title text-capitalize-first mr-2">
            {getNumber(invoice, country)}
          </h2>
        </div>
      </div>
    </div>
  )
}

export default Header