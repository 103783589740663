import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { lowerCase, get } from 'lodash'
import { NavLink } from 'react-router-dom'

import * as mutations from '../../../graphql/mutations'
import { formError } from '../../../utils/errors';
import { closeModal, openModal } from '../../../reducers/modals';
import {
  country as countrySelector,
  email as companyEmail,
  identificationSelector,
  currency
} from '../../../selectors/company'
import { validate } from '../../forms/sendInvoiceEmail/utils'
import { replaceAndParse } from '../../../utils';
import { sendGTMEvent } from '../../../reducers/company';

import Body from '../../forms/sendInvoiceEmail/SendInvoiceEmail'
import { APIGraphqlSelector } from '../../../selectors/app';

const BodyInvoiceSaved = () => {
  const dispatch = useDispatch();
  const invoiceCreated = useSelector(state => get(state, 'print.invoice', null));
  const email = useSelector(companyEmail);
  const identification = useSelector(identificationSelector);
  const country = useSelector(countrySelector);
  const companyCurrency = useSelector(currency);
  const APIGraphql = useSelector(APIGraphqlSelector);

  const getDocumentType = () => {
    if(country === 'spain'){
      if (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket') {
        return I18n.get('invoiceModalTitle.saleTicket', 'Ticket');
      }
    }
    return I18n.get(
      get(invoiceCreated, 'numberTemplate.documentType'),
      'invoice'
    );
  }
  const submit = async values => {
    try {
      await APIGraphql(graphqlOperation(mutations.sendInvoice, {
        id: invoiceCreated.id,
        emails: values.email
      }))

      dispatch(sendGTMEvent('invoice-email-sent', {
        isElectronicInvoice: get(invoiceCreated, 'numberTemplate.isElectronic', false),
        customerPaymentMethod: get(invoiceCreated, 'paymentMethod'),
        currency: get(invoiceCreated, 'currency.code', get(companyCurrency, 'code')),
        invoiceTotal: get(invoiceCreated, 'total'),
        totalItems: get(invoiceCreated, 'items').length,
      }))
    } catch (error) {
      if (!email || !identification) {
        dispatch(openModal({ modal: 'companyEmail' }))
        return formError(null, I18n.get('youNeedToConfigYourCompanyEmail', 'Primero necesitas configurar el correo electrónico de tu negocio. Luego reintenta'))
      }
      return formError(error, I18n.get('invoiceSendByEmailError', 'ocurrió un error al intentar enviar la venta por correo'))
    }
  }

  if (!!invoiceCreated && !!invoiceCreated.timestamp)
    return (
      <div className="p-5">
        <p className="text-center h4 text-capitalize-first">
          {replaceAndParse(I18n.get('anyDocumentSavedInOffline', 'Sigue vendiendo mientras tu {} se guarda o consulta el detalle en el'), [lowerCase(getDocumentType())])}
        </p>

        <p className="text-center h4 text-capitalize-first">
          <NavLink to="/invoices" onClick={() => dispatch(closeModal({ modal: 'invoiceSaved' }))}>
            {I18n.get('salesHistoryLink', 'Historial de ventas →')}
          </NavLink>
        </p>

      </div>
    )

  if (!!invoiceCreated && country === 'panama' && !!get(invoiceCreated, 'numberTemplate.isElectronic') && !get(invoiceCreated, 'stamp'))
    return (
      <div className="p-5">
        <p className="text-center h4 text-capitalize-first font-weight-bold">
          {I18n.get('yourInvoiceIsBeingAuthorizedByTheDGI', 'Tu factura está siendo autorizada por la DGI y en un momento podrás imprimirla desde la sección de ventas.')}
        </p>
      </div>
    )


  return (
    <div className="px-sm-5 py-5 d-flex flex-column w-75 m-auto">
      <p className="text-muted text-uppercase h4">
        {I18n.get('sendByMail', 'enviar por correo')}
      </p>

      <Form
        onSubmit={submit}
        validate={values => validate(values)}
        initialValues={{
          email: get(invoiceCreated, 'client.email', null)
        }}
      >
        {({ handleSubmit, submitting, submitError, submitSucceeded }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Body submitting={submitting} />

            {!!submitError && !!email && !!identification && (
              <p className="h4 text-danger text-capitalize-first">
                {submitError}
              </p>
            )}

            {submitSucceeded && (
              <p className="h4 text-primary text-capitalize-first">
                {I18n.get('invoiceSendByEmailSuccessfully', 'la venta ha sido exitosamente enviada por correo')}
              </p>
            )}
          </form>
        )}
      </Form>
    </div>
  )
}

export default BodyInvoiceSaved;