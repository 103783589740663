import React from 'react'
import PropsTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';

const Pagination = ({ 
  previousPage,
  handleChangeIndex,
  nextPage,
  pageIndex,
  pageOptions,
  canPreviousPage,
  canNextPage
  }) => (
  <div className="react-table__pagination">
    <div className="d-flex flex-column flex-sm-row
          justify-content-between align-items-stretch border-top p-1 h-100"
    >
      <button
        className="btn btn-submit"
        type="button"
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
      >
        {I18n.get('previous', 'anterior')}
      </button>

      <div className="react-table__pagination-indicator d-flex align-items-center justify-content-center">
        <div className="pagination-description text-capitalize-first text-break">{I18n.get('showingPage', 'mostrando página')}</div>
        <input
          className="mx-2"
          type="number"
          value={pageIndex + 1}
          onChange={e => handleChangeIndex(e)}
        />
        <div className="text-break">{`${I18n.get('of', 'de')} ${pageOptions.length}`}</div>
      </div>

      <button
        className="btn btn-submit"
        type="button"
        disabled={!canNextPage}
        onClick={() => nextPage()}
      >
        {I18n.get('next', 'siguiente')}
      </button>
    </div>
  </div>
)

Pagination.propTypes = {
  previousPage: PropsTypes.func,
  handleChangeIndex: PropsTypes.func,
  nextPage: PropsTypes.func,
  pageIndex: PropsTypes.number,
  pageOptions: PropsTypes.any,
  canPreviousPage: PropsTypes.bool,
  canNextPage: PropsTypes.bool
}

export default Pagination;