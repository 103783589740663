import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { get } from 'lodash'

import { country as countrySelector, decimalPrecision } from '../../../selectors/company';
import { currency, items as itemsSelector, noITBMS, numeration as numerationSelector } from '../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../selectors/currencies'
import { subtotal, discount, discSubtotal, taxes, itemsCount } from '../../../selectors/activeInvoice';
import { electronicInvoicing } from '../../../selectors/app';

const Summary = () => {
  const invoiceSubtotal = useSelector(subtotal);
  const invoiceDiscount = useSelector(discount);
  const invoiceDiscSubtotal = useSelector(discSubtotal);
  const invoiceTaxes = useSelector(taxes);
  const invoiceItemsCount = useSelector(itemsCount);
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const items = useSelector(itemsSelector)
  const country = useSelector(countrySelector)
  const numeration = useSelector(numerationSelector)
  const isElectronic = useSelector(electronicInvoicing(numeration))
  const showNoITBMSWarning = useSelector(noITBMS) && isElectronic

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol') 
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : '' 
  
  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const domCondition = country === 'republicaDominicana' && isElectronic && items.map(
    item => get(item, 'tax').some(tax => get(tax, 'type') === 'ITBIS' || get(tax, 'type') === 'EXENTO')
  ).some(value => value === false)

  if (invoiceItemsCount === 0 || domCondition || showNoITBMSWarning) return null

  return (
    <div className="invoice-bottom__summary d-flex flex-column mx-4 mt-3">
      {(invoiceDiscount.gt(0) || !!invoiceTaxes.length) && (
        <div className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceDiscount.gt(0) && (
        <div className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('discount', 'descuento')}
          </div>
          <div className="text-capitalize-first text-muted number">
            -{invoiceDiscount.toFormat(decimal, fmt)}
          </div>
        </div>
      )}
      
      {invoiceDiscount.gt(0) && (
        <div className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {I18n.get('subtotal', 'subtotal')}
          </div>
          <div className="text-capitalize-first text-muted number">
            {invoiceDiscSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>
      )}

      {invoiceTaxes.map((tax, index) => (
        <div key={index} className="invoice-bottom__summary-row mx-3 px-0 d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first desc">
            {tax.name}
          </div>
          <div className="text-capitalize-first text-muted number">
            {tax.value.toFormat(decimal, fmt)}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Summary;
