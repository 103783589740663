import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { openModal } from '../../reducers/modals';
import ListSVG from '../svg/List';


const DetailHeader = ({ payment, onListView }) => {
  const dispatch = useDispatch()

  if (!payment) return null;
  
  const getNumber = () => {
    const number = !!get(payment, 'numberTemplate', null) 
      ? get(payment, 'numberTemplate.number', ''): get(payment, 'number', '');
    const prefix = !!get(payment, 'numberTemplate', null) 
      ? get(payment, 'numberTemplate.prefix', ''): get(payment, 'prefix', '');

    return `${prefix || ''}${number || ''}`
  }

  return (
    <div className="px-4 pt-4 d-flex justify-content-between align-items-center">
      <h2 className="title text-capitalize-first">
        {getNumber()}
      </h2>
      <div className="actions d-flex align-items-center">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <button
            type="button"
            className="btn btn-submit d-none d-sm-block"
            onClick={() => dispatch(openModal({ modal: 'payments' }))}
          >
            {I18n.get('inOutCash', 'ingresar / retirar efectivo')}
          </button>

          <button
            type="button" 
            className="btn btn-submit d-block d-sm-none"
            onClick={() => onListView()}
          >
            <ListSVG className="icon-white"/>
          </button>
        </div>
      </div>
    </div>
  )
}

DetailHeader.propTypes = {
  payment: PropTypes.object,
  onListView: PropTypes.func
}

export default DetailHeader;