// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    className={className}
    id={id}
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5 2.25C7.11522 2.25 2.75 6.61522 2.75 12C2.75 17.3848 7.11522 21.75 12.5 21.75C17.8848 21.75 22.25 17.3848 22.25 12C22.25 6.61522 17.8848 2.25 12.5 2.25ZM7.22084 5.65994C8.65144 4.46742 10.4919 3.75 12.5 3.75C17.0563 3.75 20.75 7.44365 20.75 12C20.75 14.0081 20.0326 15.8486 18.8401 17.2792L7.22084 5.65994ZM6.16016 6.72057C4.96751 8.15121 4.25 9.99182 4.25 12C4.25 16.5563 7.94365 20.25 12.5 20.25C14.5082 20.25 16.3488 19.5325 17.7794 18.3398L6.16016 6.72057Z'
      fill='#BE123C'
    />
  </svg>
);
