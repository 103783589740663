import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const userSelector = state => get(state, 'auth', null);

export const idGlobal = createDraftSafeSelector(
  userSelector,
  user => !!user ? user.idGlobal : null
)

export const name = createDraftSafeSelector(
  userSelector,
  user => !!user ? `${!!user.name ? user.name : ''}${!!user.lastName ? ` ${user.lastName}`: ''}` : null
)

export const email = createDraftSafeSelector(
  userSelector,
  user => !!user ? user.email : null
)

export const consommation = createDraftSafeSelector(
  userSelector,
  user => !!user ? user.consommation : {}
)
