import React from 'react'

const Loading = ({ id, className }) => {

  return (
    <svg
      id={id}
      className={className}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" 
    >
      <path fill="#30BBB7" d="M36.6877 8.67464C36.4844 6.75236 37.88 5.01081 39.8129 5.00048C45.5292 4.9699 51.1851 6.3403 56.277 9.0152C62.4053 12.2345 67.4412 17.1987 70.7481 23.2801C74.055 29.3615 75.4843 36.2869 74.8552 43.1806C74.2261 50.0744 71.5669 56.6267 67.2139 62.0091C62.861 67.3915 57.0097 71.3623 50.4 73.4192C43.7903 75.4761 36.7191 75.5268 30.0806 73.5649C23.442 71.6031 17.5344 67.7167 13.1046 62.3973C9.42393 57.9774 6.90095 52.7331 5.73535 47.1369C5.3412 45.2445 6.75236 43.5156 8.67464 43.3123C10.5969 43.1091 12.2982 44.511 12.7396 46.393C13.7293 50.6131 15.6895 54.5624 18.4837 57.9178C22.0275 62.1733 26.7536 65.2824 32.0645 66.8519C37.3753 68.4215 43.0322 68.3809 48.32 66.7353C53.6077 65.0898 58.2888 61.9132 61.7712 57.6073C65.2536 53.3014 67.3809 48.0595 67.8841 42.5445C68.3874 37.0296 67.244 31.4892 64.5985 26.6241C61.953 21.759 57.9242 17.7876 53.0216 15.2122C49.1561 13.1815 44.8841 12.0906 40.5503 12.0054C38.6177 11.9674 36.8909 10.5969 36.6877 8.67464Z">
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 40 40" to="360 40 40" repeatCount="indefinite"></animateTransform>
      </path>
    </svg>
  )
}

export default Loading;