import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { closeMenu } from '../../reducers/app';
import { openModal } from '../../reducers/modals';
import { hasPermissionTo } from '../../selectors/auth';
import {
  shiftsEnabled,
  isOnlyInvoicingPlan,
  membershipSelector,
  country as countrySelector,
} from '../../selectors/company';
import Header from './Header';
import Bottom from './Bottom';
import { sendGTMEvent } from '../../reducers/company';
import HomeSvg from '../svg/new/Home';
import IncomesDownSvg from '../svg/new/IncomesDown';
import ShiftsSvg from '../svg/new/Shifts';
import BoxSvg from '../svg/new/Box';
import MoneySvg from '../svg/new/Money';
import SettingSvg from '../svg/new/Settings';
import TransactionSvg from '../svg/new/Transaction';
import TrailingTextSvg from '../svg/new/TrailingText';
import NavigationMenu from '../common/Navigation';
import useElectronicPayrollStatus from '../../hooks/useElectronicPayrollStatus/hook';
import ElectronicPayrollStatus from '../settings/Invoices/ElectronicPayrollStatus';
import TrailingTextIosSvg from '../svg/new/TrailingTextIos';
import { getSystemVersion } from '../../utils/platform';
import { getPlanKeyword } from '../../selectors/membership';
import { COUNTRIES } from '../../utils/enums/countries';
import RedirectSVG from '../svg/header/Redirect.svg';
import { PRODUCTS } from '../../utils/enums/products';

const Navigation = () => {
  const dispatch = useDispatch();
  const { canElectronicPayroll, isActive } = useElectronicPayrollStatus();
  const can = useSelector(hasPermissionTo);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const menuOpen = useSelector((store) => store.app.menuOpen);
  const isShiftsEnabled = useSelector(shiftsEnabled);
  const membership = useSelector(membershipSelector);
  const country = useSelector(countrySelector);
  const planKeyword = useSelector(getPlanKeyword);

  // incluido para los usuarios antiguos sin plan en POS
  const showPlans = true;

  return (
    <div
      className={`new-navigation ${menuOpen ? 'open' : 'closed'}`}
      onClick={() => dispatch(closeMenu())}
    >
      <div
        className='new-navigation-menu'
        onClick={(event) => event.stopPropagation()}
      >
        <Header />
        <NavigationMenu
          items={[
            {
              to: '/',
              menuName: '/',
              action: () => dispatch(closeMenu()),
              title: I18n.get('checkIn', 'facturar'),
              icon: <HomeSvg />,
            },
            {
              onlyInvoicingPlan,
              title: I18n.get('sales', 'ventas'),
              icon: <IncomesDownSvg />,
              menuName: 'invoices',
              hidden: country !== 'colombia',
              items: [
                {
                  to: '/invoices',
                  menuName: '/invoices',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('salesHistory', 'Historial de ventas'),
                },
                {
                  action: () => {
                    dispatch(closeMenu());
                    dispatch(openModal({ modal: 'dailyReport' }));
                    dispatch(
                      sendGTMEvent('daily-report-voucher-attempted', {
                        creationStatus: 'success',
                      })
                    );
                  },
                  isMenu: false,
                  menuName: '/dailyReport',
                  title: I18n.get(
                    'dailyReport',
                    'Comprobante de informe diario'
                  ),
                },
                ...(!isActive && canElectronicPayroll
                  ? [
                      {
                        isNavAction: true,
                        component: <ElectronicPayrollStatus />,
                      },
                    ]
                  : []),
              ],
            },
            ...([
              'peru',
              'argentina',
              'costaRica',
              'republicaDominicana',
              'panama',
            ].includes(country) &&
            !isActive &&
            !!canElectronicPayroll
              ? [
                  {
                    onlyInvoicingPlan,
                    title: I18n.get('sales', 'ventas'),
                    menuName: 'invoices',
                    icon: <IncomesDownSvg />,
                    items: [
                      {
                        to: '/invoices',
                        menuName: '/invoices',
                        action: () => dispatch(closeMenu()),
                        title: I18n.get(
                          'salesHistory',
                          'Historial de ventas'
                        ),
                      },
                      ...(!isActive && !!canElectronicPayroll
                        ? [
                            {
                              component: <ElectronicPayrollStatus />,
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : [
                  {
                    onlyInvoicingPlan,
                    to: '/invoices',
                    menuName: '/invoices',
                    exact: true,
                    action: (e) =>
                      !onlyInvoicingPlan
                        ? dispatch(closeMenu())
                        : e.preventDefault(),
                    title: I18n.get('sales', 'ventas'),
                    icon: <IncomesDownSvg />,
                    hidden: country === 'colombia',
                  },
                ]),
            {
              title: I18n.get('shifts', 'turnos'),
              icon: <ShiftsSvg />,
              menuName: 'shifts',
              hidden: !(
                !!isShiftsEnabled &&
                !onlyInvoicingPlan &&
                can('index', 'pos-cashier')
              ),
              items: [
                {
                  to: '/shifts',
                  menuName: '/shifts',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('shiftHistory', 'Historial de turnos'),
                },
                {
                  to: '/statistics',
                  menuName: '/statistics',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('shiftStatistics', 'Reportes de turnos'),
                },
              ],
            },
            {
              onlyInvoicingPlan,
              to: '/payments',
              menuName: '/payments',
              action: (e) =>
                !onlyInvoicingPlan
                  ? dispatch(closeMenu())
                  : e.preventDefault(),
              title: I18n.get('cashManagment', 'gestión de efectivo'),
              icon: <MoneySvg />,
            },
            {
              onlyInvoicingPlan,
              to: '/refunds',
              menuName: '/refunds',
              action: (e) =>
                !onlyInvoicingPlan
                  ? dispatch(closeMenu())
                  : e.preventDefault(),
              title: I18n.get('refunds', 'devoluciones'),
              icon: <TransactionSvg />,
            },
            {
              title: I18n.get('inventory', 'Inventario'),
              icon: <BoxSvg />,
              menuName: '/inventory',
              items: [
                {
                  to: '/inventory/items',
                  menuName: '/inventory/items',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get(
                    'itemsAndServices',
                    'Productos y servicios'
                  ),
                },
                {
                  to: '/inventory/inventory-adjustments',
                  action: () => {
                    dispatch(
                      sendGTMEvent('inventory-adjustments-menu-visited')
                    );
                    dispatch(closeMenu());
                  },
                  menuName: '/inventory/inventory-adjustments',
                  title: I18n.get(
                    'inventoryAdjustments',
                    'Ajustes de inventario'
                  ),
                },
                {
                  to: '/inventory/warehouses',
                  menuName: '/inventory/warehouses',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('warehouses', 'Bodegas'),
                },
                {
                  to: '/inventory/price-lists',
                  menuName: '/inventory/price-lists',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('priceLists', 'Lista de precios'),
                },
                {
                  to: '/inventory/categories',
                  menuName: '/inventory/categories',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('categories', 'Categorias'),
                },
                {
                  to: '/inventory/attributes',
                  menuName: '/inventory/attributes',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('attributes', 'Atributos'),
                },
              ],
            },
            {
              to: '/settings',
              menuName: '/settings',
              exact: true,
              action: () => dispatch(closeMenu()),
              title: I18n.get('settings', 'configuraciones'),
              icon: <SettingSvg />,
            },
            {
              to: ['demo', 'readonly'].includes(planKeyword)
                ? `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`
                : `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions`,
              target: '_blank',
              menuName: 'alegra',
              action: () => dispatch(closeMenu()),
              title: I18n.get('mySubscription', 'Mi suscripción'),
              extra: {
                title: get(membership, 'plan.name'),
                variant: 'slate',
              },
              hidden: !showPlans,
              isMenu: false,
            },
            {
              action: () => {
                dispatch(closeMenu());
                dispatch(openModal({ modal: 'shortcuts' }));
              },
              menuName: 'keyboardShortcuts',
              title: I18n.get('keyboardShortcuts', 'atajos del teclado'),
              extraIcon:
                getSystemVersion() === 'mac' ? (
                  <TrailingTextIosSvg />
                ) : (
                  <TrailingTextSvg />
                ),
              isMenu: false,
            },
            {
              to: `${process.env.REACT_APP_ALEGRA_SMILE}potencia-tu-negocio/portal-de-clientes/${PRODUCTS.POS}`,
              target: '_blank',
              menuName: 'alegra',
              action: () => dispatch(closeMenu()),
              title: I18n.get('clientPortal', 'Portal de clientes'),
              hidden: country === COUNTRIES.MEXICO,
              extraIcon: (
                <img
                  src={RedirectSVG}
                  alt=''
                  style={{ width: 11, height: 11, marginRight: 1 }}
                />
              ),
              isMenu: false,
            },
          ]}
        />
        <Bottom />
      </div>
    </div>
  );
};

export default Navigation;
