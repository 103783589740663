import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { electronicInvoicing, stationInvoiceNumeration } from '../../../selectors/app';
import { country as countrySelector } from '../../../selectors/company';
import { settings } from '../../../selectors/activeInvoice';
import { getMainPriceList } from '../../../selectors/priceLists';
import { getMainCurrency } from '../../../selectors/currencies';
import { getPaymentTermById } from '../../../selectors/paymentTerms';
import { setSettings } from '../../../reducers/activeInvoice';
import invoiceTypes from '../../countriesData/colombia/invoiceTypes'
import operationTypes from '../../countriesData/colombia/operationTypes'
import Body from '../../forms/activeInvoiceSettings/InvoiceSettings';
import { transform } from '../../forms/activeInvoiceSettings/utils';
import Close from '../../svg/Close';
import Loading from '../../svg/Loading';

const ActiveInvoiceSettings = ({ onClose }) => {
  const dispatch = useDispatch();
  const componentRef = useRef(null)
  const country = useSelector(countrySelector)
  let initialValues = useSelector(settings);
  const isElectronic = useSelector(electronicInvoicing(initialValues.numeration))
  const mainPriceList = useSelector(getMainPriceList);
  const mainCurrency = useSelector(getMainCurrency)
  const mainPaymentTerm = useSelector(getPaymentTermById(1))
  const stationNumeration = useSelector(stationInvoiceNumeration)

  if (country === 'colombia' && isElectronic) {
    initialValues = {
      ...initialValues,
      type: invoiceTypes.find(el => el.key === 'NATIONAL'),
      operationType: operationTypes.find(el => el.key === 'STANDARD'),
      paymentTerm: mainPaymentTerm,
    }
  }

  const save = values => {
    const modifiedValues = transform(values, { country, isElectronic });
    try {
      dispatch(setSettings(modifiedValues))
      onClose()
    } catch {
      return { [FORM_ERROR]: I18n.get('editInvoiceSettingsError', 'hubo un error a la hora de cambiar las configuraciones') }
    }
  }

  return (
    <div
      ref={componentRef}
      className="invoice-top__header-settings w-100 position-absolute"
    >
      <Form
        onSubmit={values => save(values)}
        initialValues={{
          ...initialValues,
          currency: !!initialValues.currency 
            ? initialValues.currency 
            : { ...mainCurrency, exchangeRate: 1 },
          priceList: !!initialValues.priceList ? initialValues.priceList : mainPriceList,
          numeration: !!initialValues.numeration ? initialValues.numeration: stationNumeration,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting, submitError }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className="w-100 bg-white d-flex flex-column shadow"
          >
            <div className="side-modal__header w-100 d-flex justify-content-between align-items-center p-3">
              <div className="d-flex align-items-center">
                <div className="side-modal__header-title text-capitalize-first">
                  {I18n.get('invoiceSettings', 'configuración de venta')}
                </div>
              </div>

              <button
                className="btn button-transparent"
                type="button"
                onClick={() => onClose()}
              >
                <Close className="icon-gray" />
              </button>
            </div>

            <div className="side-modal__body">
              <Body values={values} form={form} />
            </div>

            <div className={`side-modal__notification side-modal__notification-error ${!!submitError ? 'open' : 'closed'}`}>
              <div className="side-modal__notification-text text-left p-3 text-capitalize-first">
                {submitError}
              </div>
            </div>

            <div className="side-modal__bottom-buttons d-flex">
              <button
                type="button"
                className="btn btn-primary w-100 text-capitalize-first text-center"
                disabled={submitting}
                onClick={() => form.submit()}
              >
                {submitting
                  ? <Loading className="icon-primary" />
                  : I18n.get('update', 'Actualizar')
                }
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

export default ActiveInvoiceSettings;