function CreditCard ({id, className}) {
  return (
    <svg 
      id={id}
      className={className}
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none">
        <path d="M5.20833 12.5C5.20833 12.1548 5.48816 11.875 5.83333 11.875H5.84167C6.18684 11.875 6.46667 12.1548 6.46667 12.5C6.46667 12.8452 6.18684 13.125 5.84167 13.125H5.83333C5.48816 13.125 5.20833 12.8452 5.20833 12.5Z" fill="#334155"/>
        <path d="M9.16667 11.875C8.82149 11.875 8.54167 12.1548 8.54167 12.5C8.54167 12.8452 8.82149 13.125 9.16667 13.125H10.8333C11.1785 13.125 11.4583 12.8452 11.4583 12.5C11.4583 12.1548 11.1785 11.875 10.8333 11.875H9.16667Z" fill="#334155"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.875 6.66666C1.875 4.94077 3.27411 3.54166 5 3.54166H15C16.7259 3.54166 18.125 4.94077 18.125 6.66666V13.3333C18.125 15.0592 16.7259 16.4583 15 16.4583H5C3.27411 16.4583 1.875 15.0592 1.875 13.3333V6.66666ZM3.125 6.66666C3.125 5.63113 3.96447 4.79166 5 4.79166H15C16.0355 4.79166 16.875 5.63113 16.875 6.66666V7.70833H3.125V6.66666ZM16.875 8.95833V13.3333C16.875 14.3689 16.0355 15.2083 15 15.2083H5C3.96447 15.2083 3.125 14.3689 3.125 13.3333V8.95833H16.875Z" fill="#334155"/>
    </svg>
  )
}

export default CreditCard