// eslint-disable-next-line
export default ({ id, className, width, height, color }) => (
  <svg 
    id={id}
    className={className}
    width={width || "24"} 
    height={height || "24"} 
    viewBox="0 0 24 24" 
    // fill="none" 
    fill={color || "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 13 3 L 21 11 L 5 11 l 8 -8 z" />
    <path d="M 13 23 L 21 15 L 5 15 l 8 8 z"/>
  </svg>
)