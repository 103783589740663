export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width='89'
    height='122'
    viewBox='0 0 89 122'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_dd_1276_20704)'>
      <g clip-path='url(#clip0_1276_20704)'>
        <rect
          x='10'
          width='68.5213'
          height='98.8288'
          rx='10.5891'
          fill='white'
        />
        <rect
          x='19.0347'
          y='47.9111'
          width='36.1431'
          height='8.28279'
          rx='4.1414'
          fill='#25BCB3'
        />
        <rect
          x='18.8003'
          y='61.4434'
          width='48.9438'
          height='8.28279'
          rx='4.1414'
          fill='#F1F5F9'
        />
        <rect
          x='19.3496'
          y='76.6348'
          width='18.5384'
          height='8.28279'
          rx='4.1414'
          fill='#F1F5F9'
        />
        <rect
          width='68.5193'
          height='58.6384'
          transform='matrix(1 1.17938e-09 -1.30745e-05 1 9.99756 -17.9746)'
          fill='#F1F5F9'
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_dd_1276_20704'
        x='0.117122'
        y='0'
        width='88.2872'
        height='121.889'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='3.29429'
          operator='erode'
          in='SourceAlpha'
          result='effect1_dropShadow_1276_20704'
        />
        <feOffset dy='6.58859' />
        <feGaussianBlur stdDeviation='3.29429' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1276_20704'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='3.29429'
          operator='erode'
          in='SourceAlpha'
          result='effect2_dropShadow_1276_20704'
        />
        <feOffset dy='13.1772' />
        <feGaussianBlur stdDeviation='6.58859' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow_1276_20704'
          result='effect2_dropShadow_1276_20704'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_1276_20704'
          result='shape'
        />
      </filter>
      <clipPath id='clip0_1276_20704'>
        <rect
          x='10'
          width='68.5213'
          height='98.8288'
          rx='10.5891'
          fill='white'
        />
      </clipPath>
    </defs>
  </svg>
);
