import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'

import { checkFeatureLimit } from '../../../reducers/membership'
import { openModal } from '../../../reducers/modals'
import { hasPermissionTo } from '../../../selectors/auth'
import { country as countrySelector } from '../../../selectors/company'
import { activeSellers } from '../../../selectors/sellers'
import Seller from '../../modals/seller/Seller'
import { renderField, renderSelect } from '../fields/V0/Fields';

const AdvancedData = ({ form, values }) => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const sellers = useSelector(activeSellers)

  const documentType = get(values, 'numeration.documentType', '')
  const anotationMaxLimit = country === 'republicaDominicana' && get(values, 'anotation.length', 0) > 250;

  return (
    <>
      <div className="form-body h-100 p-4 overflow-hidden">
        <div className="form-body__fields form-row justify-content-start">
          {country === 'peru' && documentType === 'invoice' && (
            <Field
              name="paymentMethod"
              className="col-md-12"
              component={renderSelect}
              options={[
                { value: 'CASH', label: capitalize(I18n.get("paymentForm.Cash", "Contado")) }
              ]}
              label={capitalize(I18n.get("paymentForm", "forma de pago"))}
              // helpTooltip={(
              //   <>
              //     <p className="text-capitalize-first">
              //       {I18n.get('paymentMethodHelp', 'En Alegra POS puedes crear facturas de contado, conoce como generar tus facturas a crédito en Alegra Contabilidad')}
              //       {' '}
              //       <a target="_blank" rel="noreferrer" href="https://ayuda.alegra.com/es/facturaci%C3%B3n-electr%C3%B3nica-%C3%A1gil-en-el-punto-de-venta-pos-per%C3%BA?hs_preview=uIRguPyp-30104023725">
              //         {I18n.get('here', 'aquí')}
              //       </a>
              //     </p>
              //   </>
              // )}
              menuPosition="absolute"
            />
          )}

          <Field
            name="seller"
            className="col-md-12"
            component={renderSelect}
            options={sellers}
            label={capitalize(I18n.get('seller', 'vendedor'))}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            addOptionText={can('add', 'sellers')
              ? capitalize(I18n.get('newSeller', 'Nuevo vendedor')) : null}
            addOptionAction={() => dispatch(checkFeatureLimit('sellers', can('add', 'sellers') ? () => dispatch(openModal({ modal: 'seller' })) : null))}
            menuPosition="absolute"
          />

          <div className="anotation_label col-md-12">
            <div className="d-flex justify-content-between">
              <p className="m-0">
                {capitalize(I18n.get('annotations', 'observaciones'))}
              </p>
              {(country === 'republicaDominicana') && (
                <p className={`m-0 ${get(values, 'anotation.length', 0) > 250 ? 'text-danger' : ''}`}>
                  {get(values, 'anotation.length', 0)}/250
                </p>
              )}
            </div>
          </div>
          <Field
            name="anotation"
            className={`col-md-12 mt-5 ${anotationMaxLimit ? 'has-error' : ''}`}
            component={renderField}
            type="textarea"
          />
        </div>
      </div>

      <Seller onCreated={seller => form.change('seller', seller)} />
    </>
  )
}

export default AdvancedData;