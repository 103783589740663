import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'

import { closeModal } from '../../../reducers/modals';
import Modal from '../../common/Modal';
import Bottom from './Bottom';
import { replaceAndParse } from '../../../utils';
import Warning from '../../svg/WarningCircle';
import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';

const Deactivate5UVT = () => {
  const dispatch = useDispatch();
  const colombiaUVT = getColombiaUVT();
  const isOpen = useSelector(state => get(state, 'modals.deactivate5UVT.isOpen', false));
  const isWarningAlert = useSelector(state => get(state, 'modals.deactivate5UVT.params.isWarningAlert', false));
  const onConfirm = useSelector(state => get(state, 'modals.deactivate5UVT.params.onConfirm', () => { }));

  const handleClose = () => {
    dispatch(closeModal({ modal: 'deactivate5UVT' }));
  }

  const handleConfirm = () => {
    onConfirm();
    dispatch(closeModal({ modal: 'deactivate5UVT' }));
  }

  const handleHelp = () => {
    window.open('https://ayuda.alegra.com/es/configura-sistema-pos-para-ventas-superiores-5-uvt-colombia', '_blank');
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="modal__activate-5uvt"
      bordelessHeader={true}
    >
      <div className='d-flex justify-content-center'>
        <div className="d-flex flex-column p-3 justify-content-center align-items-center mb-5 w-70">
          <Warning className="medium-icon" />
          <div className="d-flex align-items-center mt-4 mb-4">
            <p className="h3 font-weight-bold text-center">
              {isWarningAlert
                ? I18n.get('deactivate5UVT.warning.title', '¡Se creará un documento POS!')
                : I18n.get('deactivate5UVT.title', '¿Deseas desactivar el cambio automático de documento al vender?')}
            </p>
          </div>

          <div className="d-flex align-items-center mb-5">
            <p className="h4 text-muted text-center">
              {isWarningAlert
                ? replaceAndParse(I18n.get('deactivate5uvt.warning.description', `La numeración para cambiar documentos POS por <strong>factura electrónica desde $${colombiaUVT?.UVT_FORMATED}</strong> está desactivada.`), [])
                : replaceAndParse(I18n.get('deactivate5uvt.description', `Al hacerlo se dejarán de crear facturas electrónicas de forma automática en las ventas que superen $${colombiaUVT?.UVT_FORMATED} antes de IVA.`), [])}
            </p>
          </div>
        </div>
      </div>
      <Bottom
        isWarningAlert={isWarningAlert}
        onClose={handleClose}
        onConfirm={handleConfirm}
        onOpenHelp={handleHelp}
      />
    </Modal>
  )
}

export default Deactivate5UVT;