import { useSelector } from "react-redux";
import Button from "../../../common/Button";
import { I18n } from '@aws-amplify/core';
import Loading from "../../../svg/Loading";
import { isSelectingItems } from "../../../../selectors/activeRefund";

export const Cash = ({step, changeStep, closeModal, disabled, submitting}) => {
  const isSelectingMode = useSelector(isSelectingItems);
  if (isSelectingMode && step === 3)
    return null;

  return (
    <div className='d-flex flex-end justify-content-end bottom container-buttons-controls'>
      <button type="button" className='refunds-cancel-button' onClick={closeModal}>
        {I18n.get('cancel','Cancelar')}
      </button>
      {
        (step === 1 || step === 2) && (
          <Button className='ml-4' style={{ width: "116px" }} disabled={disabled} onClick={() => changeStep(3)} >
            {I18n.get("continue", "Continuar")}
          </Button>
        )
      }
      {
        (step === 3 && !isSelectingMode) && (
          <Button className='ml-4' style={{ width: "116px" }} onClick={() => changeStep(4)} disabled={disabled}>
            {I18n.get("continue", "Continuar")}
          </Button>
        )
      }
      {
        step === 4 && (
          <Button className='ml-4' style={{ width: "116px" }} disabled={disabled} type="submit" >
            {submitting 
              ? <Loading className="icon-primary" />
              : I18n.get("continue", "Continuar")}
          </Button>
        )
      }
    </div>
  )
}