import React from 'react'
import { I18n } from '@aws-amplify/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BigNumber } from "bignumber.js";
import { get, isNumber } from 'lodash'

import { decimalPrecision } from '../../../../selectors/company';
import { getMainCurrency } from '../../../../selectors/currencies'
import { currency } from '../../../../selectors/activeRefund';

const Summary = ({ values }) => {
  const decimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  if (!values) return null;

  const itemQuantity = new BigNumber(!!+values.quantity ? +values.quantity : 1).decimalPlaces(2);
  const itemPrice = new BigNumber(!!values.price ? values.price : 0).decimalPlaces(decimal);
  const itemDiscountPercent = new BigNumber(!!isNumber(get(values, 'discount.discount', null))
    ? get(values, 'discount.discount')
    : !!get(values, 'discount', null)
      ? get(values, 'discount') : 0
  ).decimalPlaces(2);

  const itemSubtotal = itemPrice.multipliedBy(itemQuantity.isPositive() ? itemQuantity : 1).decimalPlaces(decimal);

  const discountSubtotal = new BigNumber(new BigNumber(1).minus(itemDiscountPercent.dividedBy(100)))
    .multipliedBy(itemPrice).decimalPlaces(decimal)
    .multipliedBy(itemQuantity).decimalPlaces(decimal)
    .abs().decimalPlaces(decimal)

  const itemDiscount = itemSubtotal.minus(discountSubtotal);

  return (
    <div className="d-flex flex-column p-4 form-row justify-content-end m-0 item-summary">
      <div className="col-12">

        <div className="item-summary__row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('subtotal', 'sub total')}
          </div>
          <div className="text-capitalize-first text-muted">
            {itemSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>

        <div className="item-summary__row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('discount', 'descuento')}
          </div>
          <div className="text-capitalize-first text-muted">
            {itemDiscount.toFormat(decimal, fmt)}
          </div>
        </div>

        <div className="item-summary__row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('total', 'total')}
          </div>
          <div className="text-capitalize-first text-muted">
            {discountSubtotal.toFormat(decimal, fmt)}
          </div>
        </div>

      </div>
    </div>
  )
}

Summary.propTypes = {
  values: PropTypes.object.isRequired
}

export default Summary;