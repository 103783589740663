import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useForm } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { isString, debounce, get } from 'lodash'

import { filter } from '../../../../reducers/clients';
import { hasPermissionTo } from '../../../../selectors/auth'
import { activeClients } from '../../../../selectors/clients'
import { Field } from 'react-final-form';
import { renderSelect } from '../../fields/V0/Fields';
import { setClient, setNumeration } from '../../../../reducers/activeRefund';

const renderClientName = client => {
  if (!!get(client, 'name')) {
    if (!!isString(get(client, 'name')))
      return get(client, 'name')
    return `${get(client, 'name.firstName', '')}${!!get(client, 'name.secondName', null)
      ? ' ' + get(client, 'name.secondName') : ''}${!!get(client, 'name.lastName', null)
        ? ' ' + get(client, 'name.lastName') : ''}`;
  }
  return ''
}

const renderClientIdentification = client => {
  if (!client) return ''

  if (!!get(client, 'identification')) {
    if (!!isString(get(client, 'identification')))
      return ` (${get(client, 'identification')})`
    return ` (${get(client, 'identification.number')})`
  }
  return ''
}


export const SelectClient = ({ numerations, changeNumeration = true }) => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const clients = useSelector(activeClients);
  const invoiceSelected = useSelector(state => get(state, 'modals.newRefunds.params.invoice', null));
  const ref = useRef()
  const [loading, setLoading] = useState(false);
  
  const form = useForm();

  useEffect(() => {
    ref.current = true
    search()
    return () => ref.current = false
  }, [])

  const search = useCallback(async (text = '') => {
    if (ref.current)
      setLoading(true)
    
    await dispatch(filter({ text, limit: 35 }))
    
    if (ref.current)
      setLoading(false)
  }, [dispatch])

  return (
    <>
      <Field 
        type="select" 
        name="refund.client"
        render={renderSelect}
        options={can('view', 'contacts') ? clients: []}
        isLoading={loading}
        placeholder={I18n.get('select', 'seleccionar')}
        getOptionValue={option => option.id}
        getOptionLabel={option =>
          `${renderClientName(option)}${renderClientIdentification(option)}`
        }
        onChange={(values) => {
          dispatch(setClient(values))
          if (changeNumeration) {
            const newNumeration = numerations.filter(n => n.isDefault)
            form.change('refund.numeration', newNumeration[0])
            dispatch(setNumeration(newNumeration[0]))
          }
          return values
        }}
        onInputChange={useMemo(() => 
          debounce((value) => { 
            if (can('view', 'contacts'))
              search(value) 
          }, 350), [can, search]
        )}
        cacheOptions={false}
        required
        disabled={!!invoiceSelected}
        label={I18n.get('client', 'Cliente')}
        className="col-12 col-md-6 p-0 pr-md-2 new-refund-input select-refund"
      />
    </>
  
  )
}