import React, { useMemo } from 'react'
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

// import { removeItems, updateItem as updateItemReducer } from '../../../reducers/items';
import { updateItem as updateItemReducer } from '../../../reducers/items';
import alegraAPI from '../../../reducers/alegraAPI';
import { hasPermissionTo } from '../../../selectors/auth';
import { handleError } from '../../../utils/errors'
import { replaceAndParse, toast } from '../../../utils'
import Tooltip from '../../common/Tooltip';
import { Active, ActiveOff } from '../../svg/Legacy'
// import DeleteSolid from "../../svg/DeleteSolid.svg";


const MultipleItemsActions = ({ selectedItems, show, onRefresh, startLoading, stopLoading }) => {
  const can = useSelector(hasPermissionTo);
  const dispatch = useDispatch();

  const [ids, itemsById] = useMemo(() => {
    const ids = [];
    const itemsById = {};
    selectedItems.forEach(item => {
      ids.push(item.original.id);
      itemsById[item.original.id] = item.original;
    })
    return [ids, itemsById]
  }, [selectedItems])

  // const deleteSelectedItems = async () => {
  //   startLoading()
  //   try {
  //     await alegraAPI.delete(`/items?id=${ids.join(',')}`);
  //     stopLoading()
  //     for (let i = 0; i < ids.length; i++) {
  //       toast.success({
  //         title: I18n.get('itemDeleted', 'producto eliminado'),
  //         subtitle: 'El producto ' + itemsById[ids[i]].name + ' fue eliminado con éxito',
  //         params: { autoClose: false }
  //       })
  //     }

  //     dispatch(removeItems(ids));

  //     onRefresh()
  //   } catch (error) {
  //     stopLoading()
  //     if (get(error, 'response.data.error.length', 0) !== 0) {
  //       error.response.data.error.forEach(errorObj => {
  //         toast.error({
  //           title: 'Error eliminando ' + itemsById[errorObj.id].name,
  //           subtitle: errorObj.message,
  //         })
  //       })
  //       error.response.data.success.forEach(successObj => {
  //         toast.success({
  //           title: I18n.get('itemDeleted', 'producto eliminado'),
  //           subtitle: 'El producto ' + itemsById[successObj.id].name + ' fue eliminado con éxito',
  //           params: { autoClose: false }
  //         })
  //       })
  //     } else {
  //       let responseError = get(error, 'response.data.message');
  //       toast.error({
  //         title: I18n.get('itemDeletedError', 'error eliminando producto'),
  //         subtitle: !!responseError ? responseError : handleError(error),
  //       })
  //     }
  //   }
  // }

  const processItemUpdate = (status, newItem) => {
    toast.success({
      title: status === 'active'
        ? replaceAndParse(I18n.get('itemWithNameActivated', 'Item {} activado con éxito'), [itemsById[newItem.data.id].name])
        : replaceAndParse(I18n.get('itemWithNameDeactivated', 'Item {} desactivado con éxito'), [itemsById[newItem.data.id].name]),
    })
    dispatch(updateItemReducer({
      id: newItem.data.id,
      changes: newItem.data,
    }));
  }

  const modifyStatusOnSelectedItems = async (status) => {
    startLoading()
    try {
      const arrayOfPromises = ids.map(async (itemId) => {
        try {
          return await alegraAPI.put(`/items/${itemId}`, { status });
        } catch (error) {
          const code = get(error, 'response.data.code');
          if (code === 1019) {
            toast.success({
              title: replaceAndParse(I18n.get('itemAlreadyInactive', 'El item {} ya se encontraba desactivado'), [itemsById[itemId].name])
            })
          } else {
            const message = get(error, 'response.data.message');
            toast.error({
              title: I18n.get('itemStatusError', 'error al cambiar estado del item'),
              subtitle: !!message ? message : handleError(error)
            })
          }
        }
      })
      for await (const item of arrayOfPromises) {
        processItemUpdate(status, item)
      }
      stopLoading()
      onRefresh()
    } catch (error) {
      stopLoading()
      let responseError = get(error, 'response.data.message');
      toast.error({
        title: I18n.get('itemStatusError', 'error al cambiar estado del item'),
        subtitle: !!responseError ? responseError : handleError(error)
      })
    }
  }

  if (!show) {
    return null
  }

  return (
    <div className="action-buttons mb-4">
      <Tooltip
        tooltipActive={!can('edit', 'items')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.items.edit', 'no tienes permisos para editar productos')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn-action d-flex justify-content-center align-items-center"
          onClick={async () => modifyStatusOnSelectedItems('active')}
        >
          <div className="mr-2">
            <Active />
          </div>
          {capitalize(I18n.get('activate', 'activar'))}
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can('edit', 'items')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.items.edit', 'no tienes permisos para editar productos')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn-action ml-3 d-flex justify-content-center align-items-center"
          onClick={() => modifyStatusOnSelectedItems('inactive')}
        >
          <div className='mr-2'>
            <ActiveOff />
          </div>
          {capitalize(I18n.get('deactivate', 'desactivar'))}
        </button>
      </Tooltip>

      {/* <Tooltip
        tooltipActive={!can('delete', 'items')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.items.delete', 'no tienes permisos para eliminar productos')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn-action ml-3 d-flex justify-content-center align-items-center"
          onClick={deleteSelectedItems}
        >
          <img src={DeleteSolid} alt="eliminar" className='mr-2' />
          {capitalize(I18n.get('delete', 'eliminar'))}
        </button>
      </Tooltip> */}
    </div>
  )
}

export default MultipleItemsActions;