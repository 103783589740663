// eslint-disable-next-line
export default ({ id, className, fill, width, height }) => (
  <svg
    id={id}
    className={className}
    width={width ?? "15"}
    height={height ?? "14"}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.08333 0.09375C3.05152 0.09375 0.59375 2.55152 0.59375 5.58333C0.59375 8.61515 3.05152 11.0729 6.08333 11.0729C7.40792 11.0729 8.62294 10.6038 9.5713 9.8226L13.4993 13.7507C13.7068 13.9581 14.0432 13.9581 14.2507 13.7507C14.4581 13.5432 14.4581 13.2068 14.2507 12.9993L10.3226 9.0713C11.1038 8.12294 11.5729 6.90792 11.5729 5.58333C11.5729 2.55152 9.11515 0.09375 6.08333 0.09375ZM1.65625 5.58333C1.65625 3.13832 3.63832 1.15625 6.08333 1.15625C8.52834 1.15625 10.5104 3.13832 10.5104 5.58333C10.5104 8.02834 8.52834 10.0104 6.08333 10.0104C3.63832 10.0104 1.65625 8.02834 1.65625 5.58333Z"
      fill={fill ?? "#94A3B8"} 
      />
  </svg>
)