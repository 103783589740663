import React from "react";
import AppsV0 from "./V0";
import AppsV1 from "./V1";

const Apps = () => {
  const showNewLauncher = true;
  return <>{showNewLauncher ? <AppsV1 /> : <AppsV0 />}</>;
};

export default Apps;
