import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { Link } from 'react-router-dom';

import { setTypeToPrint } from '../../../reducers/print';
import { country as countrySelector, currency } from '../../../selectors/company'
import { sendGTMEvent } from '../../../reducers/company';

import Print from '../../svg/Print'

const BottomInvoiceSaved = ({ handleClose }) => {
  const dispatch = useDispatch();
  const invoiceCreated = useSelector(state => get(state, 'print.invoice', null));
  const country = useSelector(countrySelector);
  const companyCurrency = useSelector(currency);

  if (!!invoiceCreated && country === 'panama' && !!get(invoiceCreated, 'numberTemplate.isElectronic') && !get(invoiceCreated, 'stamp'))
    return (
      <div className="modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow">
        <Link
          to="/invoices"
          className="btn btn-cancel text-capitalize-first px-4  d-flex align-items-center justify-content-center"
          onClick={handleClose}
        >
          {I18n.get('goToSales', 'Ir a ventas')}
        </Link>

        <button
          type="button"
          data-testid="new-invoice-btn"
          className="btn btn-submit text-capitalize-first"
          onClick={handleClose}
        >
          {I18n.get('newSale', 'nueva venta')}
        </button>
      </div>
    )

  return (
    <div className="modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow">
      <button
        type="button"
        className="btn btn-cancel text-capitalize-first"
        onClick={() => {
          dispatch(setTypeToPrint('invoice'))
          dispatch(sendGTMEvent('invce-printed', {
            isElectronicInvoice: get(invoiceCreated, 'numberTemplate.isElectronic', false),
            customerPaymentMethod: get(invoiceCreated, 'paymentMethod'),
            currency: get(invoiceCreated, 'currency.code', get(companyCurrency, 'code')),
            invoiceTotal: get(invoiceCreated, 'total'),
            totalItems: get(invoiceCreated, 'items').length,
          }))
        }}
      >
        <Print className="icon-secondary" />
        <span className="text-capitalize">{I18n.get('print', 'imprimir')}</span>
      </button>

      <button
        type="button"
        data-testid="new-invoice-btn"
        className="btn btn-submit text-capitalize-first"
        onClick={handleClose}
      >
        {I18n.get('newSale', 'nueva venta')}
      </button>
    </div>
  )
}

export default BottomInvoiceSaved;