import React from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import * as mutations from '../../../graphql/mutations'
import { updatePriceList as updatePriceListReducer, removePriceList } from '../../../reducers/priceLists';
import { hasPermissionTo } from '../../../selectors/auth';
import { priceListTransformer } from '../../../utils/transformers';
import { handleError } from '../../../utils/errors'
import { toast } from '../../../utils'
import Tooltip from '../../common/Tooltip';

import EditSolid from "../../svg/EditSolid.svg";
import DeleteSolid from "../../svg/DeleteSolid.svg";

import Lock from '../../svg/Lock'
import Unlock from '../../svg/Unlock'
import { APIGraphqlSelector } from '../../../selectors/app';

const Actions = ({ priceList, onRefresh, onView, startLoading, stopLoading }) => {
    const dispatch = useDispatch();
    const can = useSelector(hasPermissionTo);
    const APIGraphql = useSelector(APIGraphqlSelector);

    const deletePriceList = async () => {
        startLoading()
        try {
            await APIGraphql(graphqlOperation(mutations.deletePriceList, {
                id: priceList.id,
            }))
            stopLoading();
            toast.success({
                title: I18n.get('priceListDeletedSuccessfully', 'Lista de precio eliminada con éxito'),
            })
            dispatch(removePriceList(priceList.id));
            onRefresh();
        } catch (error) {
            stopLoading();
            toast.error({
                title: I18n.get('priceListDeletedError', 'Error eliminando la lista de precio'),
                subtitle: handleError(error)
            })
        }
    }

    const updatePriceList = async isActive => {
        startLoading()
        try {
            let newPriceList = priceListTransformer({ ...priceList, status: !!isActive ? 'active' : 'inactive' })
            await APIGraphql(graphqlOperation(mutations.updatePriceList, {
                priceList: newPriceList,
            }));
            stopLoading();
            toast.success({
                title: isActive
                    ? I18n.get('priceListActivatedSucessfully', 'Lista de precio activada con éxito')
                    : I18n.get('priceListDeactivatedSuccessfully', 'Lista de precio desactivada con éxito')
            })
            dispatch(updatePriceListReducer({
                id: newPriceList.id,
                changes: newPriceList,
            }));
            onRefresh();
        } catch (error) {
            stopLoading();
            toast.error({
                title: I18n.get('priceListStatusError', 'Error cambiando estado de la lista de precio'),
                subtitle: handleError(error)
            });
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-start table-gray-actions">
            <Tooltip
                tooltipActive={!can('edit', 'price-lists')}
                overlay={(
                    <p className="text-capitalize-first">
                        {I18n.get('userNotAllowed.price-lists.edit', 'no tienes permisos para editar listas de precio')}
                    </p>
                )}
            >
                <button
                    type="button"
                    className="btn button-transparent"
                    title={capitalize(I18n.get('edit', 'editar'))}
                    onClick={() => onView()}
                    disabled={!can('edit', 'price-lists')
                        || !get(priceList, 'editable', true)
                    }
                >
                    <img src={EditSolid} alt="editar" />
                </button>
            </Tooltip>

            <Tooltip
                tooltipActive={!can('edit', 'price-lists')}
                overlay={(
                    <p className="text-capitalize-first">
                        {I18n.get('userNotAllowed.price-lists.edit', 'no tienes permisos para editar listas de precio')}
                    </p>
                )}
            >
                <button
                    type="button"
                    className="btn button-transparent"
                    onClick={() => updatePriceList(!(get(priceList, 'status') === 'active'))}
                    title={get(priceList, 'status') === 'active'
                        ? capitalize(I18n.get('deactivate', 'desactivar'))
                        : capitalize(I18n.get('activate', 'activar'))
                    }
                    disabled={!can('edit', 'price-lists')}
                >
                    {get(priceList, 'status') === 'active'
                        ? <Unlock className="icon-primary" />
                        : <Lock className="icon-primary" />
                    }
                </button>
            </Tooltip>

            <Tooltip
                tooltipActive={!can('delete', 'price-lists')}
                overlay={(
                    <p className="text-capitalize-first">
                        {I18n.get('userNotAllowed.price-lists.edit', 'no tienes permisos para eliminar listas de precio')}
                    </p>
                )}
            >
                <button
                    type="button"
                    className="btn button-transparent button-remove"
                    title={capitalize(I18n.get('delete', 'eliminar'))}
                    onClick={() => deletePriceList()}
                    disabled={!can('delete', 'price-lists')
                        || !get(priceList, "deletable", true)
                    }
                >
                    <img src={DeleteSolid} alt="eliminar" />
                </button>
            </Tooltip>

        </div>
    )
}

export default Actions;