function BarcodeSVG({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width="26"
      height="19"
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1.02148V15.0215" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M1 1.02148V17.0215" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M25 1.02148V17.0215" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M9 5.02148L9 17.0215" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M17 5.02148L17 17.0215" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M5 1.02148L5 13.0215" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M21 1.02148L21 13.0215" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <circle cx="5" cy="17.0215" r="1" fill="white" />
      <circle cx="9" cy="1.02148" r="1" fill="white" />
      <circle cx="17" cy="1.02148" r="1" fill="white" />
      <circle cx="21" cy="17.0215" r="1" fill="white" />
    </svg>
  );
}

export default BarcodeSVG;