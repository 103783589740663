function ShiftsSvg({ id, className }) {
  return (
    <svg
      id={id}
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.4301 3.4301C3.62545 3.23475 3.8904 3.125 4.16667 3.125H9.375V5.83333C9.375 6.22011 9.52865 6.59104 9.80214 6.86453C10.0756 7.13802 10.4466 7.29167 10.8333 7.29167H13.5417V8.33333C13.5417 8.67851 13.8215 8.95833 14.1667 8.95833C14.5118 8.95833 14.7917 8.67851 14.7917 8.33333V6.66667C14.7917 6.50091 14.7258 6.34193 14.6086 6.22472L10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875H4.16667C3.55888 1.875 2.97598 2.11644 2.54621 2.54621C2.11644 2.97598 1.875 3.55888 1.875 4.16667V15.8333C1.875 16.4411 2.11644 17.024 2.54621 17.4538C2.97598 17.8836 3.55888 18.125 4.16667 18.125H9.58333C9.92851 18.125 10.2083 17.8452 10.2083 17.5C10.2083 17.1548 9.92851 16.875 9.58333 16.875H4.16667C3.8904 16.875 3.62545 16.7653 3.4301 16.5699C3.23475 16.3746 3.125 16.1096 3.125 15.8333V4.16667C3.125 3.8904 3.23475 3.62545 3.4301 3.4301ZM12.6578 6.04167L10.625 4.00888V5.83333C10.625 5.88859 10.6469 5.94158 10.686 5.98065C10.7251 6.01972 10.7781 6.04167 10.8333 6.04167H12.6578Z'
        fill='#94A3B8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1667 10.2083C16.3528 10.2083 18.125 11.9805 18.125 14.1667C18.125 16.3528 16.3528 18.125 14.1667 18.125C11.9805 18.125 10.2083 16.3528 10.2083 14.1667C10.2083 11.9805 11.9805 10.2083 14.1667 10.2083ZM13.5417 11.5308C12.3472 11.813 11.4583 12.886 11.4583 14.1667C11.4583 15.6624 12.6709 16.875 14.1667 16.875C15.4473 16.875 16.5204 15.9861 16.8025 14.7917H14.1667C13.8215 14.7917 13.5417 14.5118 13.5417 14.1667V11.5308ZM14.7917 13.5417H16.8025C16.5678 12.5478 15.7855 11.7656 14.7917 11.5308V13.5417Z'
        fill='#94A3B8'
      />
    </svg>
  );
}

export default ShiftsSvg;
