import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import { I18n } from '@aws-amplify/core';

import Modal from '../../common/Modal';
import {
  companySelector,
  country as countrySelector,
  notificationsSelector,
} from '../../../selectors/company';
import { closeModal, openModal } from '../../../reducers/modals';
import Typography from '../../common/Typography';
import Button from '../../common/Button';
import PosDocumentBody from '../../svg/posDucument/PosDocumentBody';
import PosDocumentTime from '../../svg/posDucument/PosDocumentTime';
import { useCallback, useEffect } from 'react';
import { COUNTRIES } from '../../../utils/enums/countries';
import * as mutations from '../../../graphql/mutations';
import { setUser } from '../../../reducers/auth';
import { isAfter } from 'date-fns';
import { APIGraphqlSelector } from '../../../selectors/app';
import { notificationsTransformer } from '../../notifications/utils';

function ElectronicPOSDocument() {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const { registryDate } = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const COOKIE_NAME = `pos-electronic-notification-${country}`;
  const isOpen = useSelector((state) =>
    get(state, 'modals.electronicPosDocument.isOpen', false)
  );
  
  const availableNotification = useCallback(() => {
    if (notifications?.find((notification) => notification[COOKIE_NAME]))
      return false;
    if (country !== COUNTRIES.COLOMBIA) return false;
    if (isAfter(new Date(registryDate), new Date('01/23/2024'))) return false;

    return true;
  }, [registryDate, country, notifications]);

  const closePosElectronicNotification = async () => {
    if (!availableNotification()) return;

    try {
      const response = await APIGraphql(
        graphqlOperation(
          mutations.updateUser,
          notificationsTransformer(COOKIE_NAME)
        )
      );
      if (!!get(response, 'data.updateUser.id', null)) {
        dispatch(setUser(get(response, 'data.updateUser')));
      }
    } catch (err) {
      console.log('error while creating notification', err);
    }
  };

  useEffect(() => {
    if (!availableNotification()) return;
    dispatch(openModal({ modal: 'electronicPosDocument' }));
    closePosElectronicNotification();
  }, []);

  const handleOpenHelp = () => {
    window.open(
      'https://siemprealdia.alegra.com/impuestos/tiquetes-pos-fisicos-y-documento-equivalente-pos-electronico/',
      '_blank',
      'noopener noreferrer'
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() =>
        dispatch(closeModal({ modal: 'electronicPosDocument' }))
      }
      includeHeader={false}
      className='modal__small-size modal__mobile-bottom electronic-pos-modal'
    >
      <div className='p-4 electronic-pos-modal-body'>
        <div className='electronic-pos-modal-body-icon'>
          <PosDocumentBody />
          <PosDocumentTime className='electronic-pos-modal-body-icon-time' />
        </div>
        <div className='electronic-pos-modal-body-info'>
          <Typography
            type='body-2-bold'
            text={I18n.get('', 'Se viene el Documento POS electrónico 📝')}
            variant='primary'
          />
          <Typography
            type='body-3-regular'
            text={I18n.get(
              '',
              'Conoce todo sobre este nuevo documento y qué debes hacer para implementarlo. '
            )}
            variant='tertiary'
          />
        </div>
        <div className='electronic-pos-modal-body-actions'>
          <Button
            variant='ghost'
            onClick={() => [
              dispatch(closeModal({ modal: 'electronicPosDocument' })),
            ]}
          >
            <Typography
              type='label-1'
              variant='secondary'
              text={I18n.get('close', 'Cerrar')}
            />
          </Button>
          <Button
            onClick={() => [
              dispatch(closeModal({ modal: 'electronicPosDocument' })),
              handleOpenHelp(),
            ]}
          >
            <Typography
              type='label-1'
              text={I18n.get('startPreparing', 'Conocer más detalles')}
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ElectronicPOSDocument;
