import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'aws-amplify';
import { Form } from 'react-final-form';
import { get, isEmpty } from 'lodash';
import Notification from '../../common/Notification';
import Loading from '../../../svg/Loading';
import Body from '../../../forms/stationSetting5UVT';
import { transform } from '../../../forms/stationSetting5UVT/utils'
import { stations as stationsSelector } from '../../../../selectors/station';
import { APIGraphqlSelector, station as stationSelector } from '../../../../selectors/app'
import { getElectronicNumerations } from '../../../../selectors/numerations';
import * as mutations from '../../../../graphql/mutations';
import { graphqlOperation } from '@aws-amplify/api';
import { formError } from '../../../../utils/errors';
import { updateStation } from '../../../../reducers/app'
import { stationTransformer } from '../../../../utils/transformers';
import { toast, replaceAndParse } from '../../../../utils';
import Tooltip from '../../../common/Tooltip';
import { getColombiaUVT } from '../../../../utils/enums/colombiaUVT';

const StationSettings = ({ close, isOpenStationSetting5UVT, activate5uvt }) => {
  const stations = useSelector(stationsSelector);
  const numerations = useSelector(getElectronicNumerations)
  const currentStation = useSelector(stationSelector)
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const colombiaUVT = getColombiaUVT();
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    let _initialValues = {};
    if (numerations.length > 0)
      stations.forEach(s => {
        _initialValues['k' + s.id] = { id: numerations[0].id, name: numerations[0].name };
      });
    setInitialValues(_initialValues);
  }, [stations, numerations])

  const submit = async values => {
    try {
      setLoading(true)
      if (isOpenStationSetting5UVT)
        await activate5uvt();

      for (const s of stations) {
        const transformedValues = transform(values, 'k' + s.id)
        const response = await APIGraphql(graphqlOperation(mutations.updateStation, {
          station: stationTransformer({ ...s, ...transformedValues })
        }))

        const station = get(response, 'data.updateStation', null);
        if (currentStation.id === station.id)
          dispatch(updateStation(station))
      }

      setLoading(false)
      close();

      if (isOpenStationSetting5UVT)
        toast.success({
          title: `${I18n.get('5uvtActivatedSuccessfully2', '¡Todo Listo!  ✨')}`,
          subtitle: replaceAndParse(I18n.get('5uvtActivatedSuccessfullyMessage2', `Ya está configurada la numeración de facturas electrónicas para ventas que superen $${colombiaUVT?.UVT_FORMATED} sin IVA.`), [])
        })
      else
        toast.success({
          title: `${I18n.get('5uvtActivatedSuccessfully', '¡Ya está activado! 🎯')}`,
          subtitle: replaceAndParse(I18n.get('5uvtActivatedSuccessfullyMessage', `Se creará una factura electrónica cuando la venta en documento POS <strong>supere los $${colombiaUVT?.UVT_FORMATED}</strong> antes de IVA. `), [])
        })
    } catch (error) {
      setLoading(false)
      return formError(error, I18n.get('updateStationsError', 'hubo un error en la actualización de las terminales'))
    }
    setLoading(false)
  }

  return (
    <>
      <Form
        onSubmit={submit}
        initialValues={initialValues}
      >
        {({ handleSubmit, values, form, error, submitting, submitError, submitFailed, errors }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="d-flex flex-column justify-content-center align-items-center form-body modal__item-body ">
              <div className="mb-5 mt-4 px-5">
                <p className="h3 mb-3 text-capitalize-first font-weight-bold">
                  {!isOpenStationSetting5UVT ? I18n.get('5uvt.stationSettings.title', '¡Solo un paso más! 🤞') : I18n.get('5uvt.stationSettings.title2', '¡Configura tu numeración electrónica! ✍')}
                </p>
                <p className="h4 uvt-description text-capitalize-first text-subtitle">
                  {replaceAndParse(I18n.get('5uvt.stationSettings.description', `Selecciona la numeración que tendrán las facturas electrónicas que se creen en lugar de documentos POS cuando la <strong>venta supere $${colombiaUVT?.UVT_FORMATED}</strong> antes de IVA.`), [])}
                </p>

                <Body type={values.type} values={values} form={form} />
              </div>
            </div>


            {(!!error && !!submitFailed) && (
              <Notification isOpen={true} text={error} type="error" />
            )}

            {(!!submitError && !!submitFailed) && (
              <Notification isOpen={true} text={submitError} type="error" />
            )}

            <div className="modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow">
              <div className="required-message mb-3 mb-sm-0">
                <i className="text-primary mr-2">* </i>
                <span className="text-capitalize-first text-muted">{I18n.get("requiredFields", "Campos obligatorios")}</span>
              </div>
              <div className="modal__bottom-buttons d-flex">
                <Tooltip
                  tooltipActive={isEmpty(values) || !isEmpty(errors)}
                  overlay={replaceAndParse(I18n.get("noElectronicNumeration", "Debes {} para configurarla en tu terminal actual."), [
                    `<a href="https://ayuda.alegra.com/es/pasos-habilitacion-facturador-electronico-dian-colombia#paso-5" target="_blank" rel="noreferrer">
                       ${I18n.get('noElectronicNumeration1', 'crear una numeración electrónica')}
                    </a>`
                  ])}
                  placement="top"
                >
                  <button
                    type="submit"
                    data-testid="modal-submit-btn"
                    disabled={!!submitting || loading || isEmpty(values) || !isEmpty(errors)}
                    className="btn btn-submit text-capitalize-first"
                  >
                    {submitting || loading
                      ? <Loading className="icon-primary" />
                      : I18n.get('finishProcess', 'Terminar proceso')
                    }
                  </button>
                </Tooltip>
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  )
}

export default StationSettings