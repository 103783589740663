import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderCheckbox } from '../../fields/V0/Fields';

const renderStatementAttached = () => {
  return (
    <Field
      name="statementAttached"
      className="col-12 my-3"
      defaultValue={false}
      component={renderCheckbox}
      label={capitalize(I18n.get('includeAccountStatement', 'incluir estado de cuenta'))}
      help={capitalize(I18n.get('includeAccountStatementSubtitle', 'Al enviar una factura al correo, esta irá acompañada del resumen de todas sus facturas'))}
    />
  )
}

export default renderStatementAttached;
