import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get, isArray } from 'lodash'
import dayjs from 'dayjs';

import { idCompanySelector, companySelector, membershipSelector, country as countrySelector } from '../selectors/company'
import { languageSelector } from '../selectors/auth'
import { featureLimitReached as featureLimitReachedSelector, getPlanKeyword, isFeatureLimitReached as isFeatureLimitReachedSelector, membershipACSelector } from '../selectors/membership'
import { handleError } from '../utils/errors'
import membershipsAPI from './membershipsAPI'
import { openModal } from '../reducers/modals';
import { consommation as consommationSelector } from '../selectors/user';

const initialState = {
  'alegra-web': {},
  'alegra-pos': {},
  'alegra-shop': {},
  'alegra-ne': {},
  'happyWeek': false
}

export const fetchMembership = createAsyncThunk(
  'membership/fetch',
  async (_params, { rejectWithValue, getState, dispatch }) => {
    try {
      const companyId = idCompanySelector(getState())

      const response = await membershipsAPI.get(`/subscribers/${companyId}/subscriptions/current`, {
        product: 'alegra-pos'
      });

      const currentPlan = get(response, 'data', null)

      dispatch(setMembership({
        product: 'alegra-pos',
        plan: currentPlan
      }))

    } catch (error) {
      return rejectWithValue(handleError(error))
    }
    finally {
      dispatch(fetchAllMemberships())
    }
  }
)

export const fetchAllMemberships = createAsyncThunk(
  'membership/fetchAll',
  async (_params, { rejectWithValue, getState, dispatch }) => {
    try {
      const companyId = idCompanySelector(getState())

      const products = ['alegra-web', 'alegra-shop', 'alegra-ne']

      await products.map(async product => {
        try {
          const response = await membershipsAPI.get(`/subscribers/${companyId}/subscriptions/current`, {
            product
          });

          const plan = get(response, 'data', null)

          dispatch(setMembership({
            product,
            plan
          }))
        } catch {
          dispatch(setMembership({
            product,
            plan: {}
          }))
        }

        return null;
      })
    } catch (error) {
      rejectWithValue(error)
    }
  }
)

export const getMembershipPlans = () => {
  return async (_dispatch, getState) => {
    try {
      const companyId = idCompanySelector(getState())
      const lang = languageSelector(getState())

      const response = await membershipsAPI.get(`/subscribers/${companyId}/available-plans`, {
        product: 'alegra-pos',
        lang,
      });

      return get(response, 'data.results', null)
    } catch {
      return null
    }
  }
}


export const checkFeatureLimit = (features, callback) => {
  return async (dispatch, getState) => {
    const featureLimitReached = featureLimitReachedSelector(getState());
    const isFeatureLimitReached = isFeatureLimitReachedSelector(getState());
    const planName = getPlanKeyword(getState());
    const country = countrySelector(getState());
    const consommation = consommationSelector(getState());

    if (!isArray(features))
      features = [features]

    let limitReached = false;

    const countries = ['usa', 'other', 'spain', 'republicaDominicana', 'argentina', 'panama', 'costaRica', 'peru', 'colombia'];


    if (countries.includes(country))
      limitReached = features.filter(feature => isFeatureLimitReached(feature));
    else
      limitReached = features.filter(feature => featureLimitReached(feature));

    if (limitReached.length > 0) {
      if (planName === 'readonly' && countries.includes(country)) {
        if (!consommation) return;

        const { sales, clients, items } = consommation;
        if (!!sales || !!clients || !!items)
          dispatch(openModal({ modal: 'readonlyPlanWithData', params: { info: { sales, clients, items } } }));
        else
          dispatch(openModal({ modal: 'readonlyPlan' }));

        return;
      }
      dispatch(openModal({ modal: 'planLimitReached' }));
      return;
    }

    if (callback)
      callback();
  }
}

export const createMembership = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const companyId = idCompanySelector(state)
    const company = companySelector(state)
    const membership = membershipSelector(state)
    const membershipAC = membershipACSelector(state)

    if (get(get(membershipAC, 'features', []).filter(f => get(f, 'keyword') === 'pos'), '0.include', false))
      return;

    if (dayjs(get(company, 'registryDate')).isAfter(dayjs('09/15/2021'))) {
      if (!(get(membership, 'product.keyword', '') === 'alegra-pos')) {
        const response = await membershipsAPI.post(`/subscribers/${companyId}/register`, {
          product: 'alegra-pos'
        });

        const currentPlan = get(response, 'data.results', null)

        dispatch(setMembership({
          product: 'alegra-pos',
          plan: currentPlan
        }))
      }
    }
  }
}

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    setMembership: (state, action) => {
      state[action.payload.product] = action.payload.plan
    },
    setDiscountAvailability: (state, action) => {
      state.happyWeek = action.payload
    }
  },
});

const { reducer, actions } = appSlice;

export const { setMembership, setDiscountAvailability } = actions;

export default reducer;