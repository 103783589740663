// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.83333 3.66673C1.83333 1.91783 3.2511 0.500061 5 0.500061C6.7489 0.500061 8.16667 1.91783 8.16667 3.66673C8.16667 5.41563 6.7489 6.83339 5 6.83339C3.2511 6.83339 1.83333 5.41563 1.83333 3.66673ZM5 1.50006C3.80338 1.50006 2.83333 2.47011 2.83333 3.66673C2.83333 4.86335 3.80338 5.83339 5 5.83339C6.19662 5.83339 7.16667 4.86335 7.16667 3.66673C7.16667 2.47011 6.19662 1.50006 5 1.50006Z" fill="#00B19D" />
    <path d="M3.66667 9.50006C3.09203 9.50006 2.54093 9.72833 2.1346 10.1347C1.72827 10.541 1.5 11.0921 1.5 11.6667V13.0001C1.5 13.2762 1.27614 13.5001 1 13.5001C0.723858 13.5001 0.5 13.2762 0.5 13.0001V11.6667C0.5 10.8269 0.83363 10.0214 1.4275 9.42756C2.02136 8.83369 2.82681 8.50006 3.66667 8.50006H6.33333C7.17319 8.50006 7.97864 8.83369 8.5725 9.42756C9.16637 10.0214 9.5 10.8269 9.5 11.6667V13.0001C9.5 13.2762 9.27614 13.5001 9 13.5001C8.72386 13.5001 8.5 13.2762 8.5 13.0001V11.6667C8.5 11.0921 8.27173 10.541 7.8654 10.1347C7.45907 9.72833 6.90797 9.50006 6.33333 9.50006H3.66667Z" fill="#00B19D" />
    <path d="M12.1667 4.33339C12.1667 4.05725 11.9428 3.83339 11.6667 3.83339C11.3905 3.83339 11.1667 4.05725 11.1667 4.33339V5.83339H9.66667C9.39052 5.83339 9.16667 6.05725 9.16667 6.33339C9.16667 6.60954 9.39052 6.83339 9.66667 6.83339H11.1667V8.33339C11.1667 8.60954 11.3905 8.8334 11.6667 8.8334C11.9428 8.8334 12.1667 8.60954 12.1667 8.33339V6.83339H13.6667C13.9428 6.83339 14.1667 6.60954 14.1667 6.33339C14.1667 6.05725 13.9428 5.83339 13.6667 5.83339H12.1667V4.33339Z" fill="#00B19D" />
  </svg>
)

