import { I18n } from '@aws-amplify/core';
import { BigNumber } from 'bignumber.js';
import { get } from 'lodash';

export const validate = (values, { shift, decimal }) => {
  let errors = {};
  
  if (!!shift) {
    const startingAmount = new BigNumber(get(shift, 'startingAmount', 0))
    const cash = new BigNumber(get(shift, 'cash', 0));
    const refunds = new BigNumber(get(shift, 'refunds', 0));
    const inPayment = new BigNumber(get(shift, 'inPayment', 0));
    const outPayment = new BigNumber(get(shift, 'outPayment', 0));
    const total = startingAmount
      .plus(cash)
      .plus(inPayment)
      .minus(refunds)
      .minus(outPayment)

    const expectedCash = new BigNumber(get(values, 'expectedCash', 0))

    if (expectedCash.decimalPlaces(decimal).lt(total.decimalPlaces(decimal)))
      errors.expectedCash = I18n.get('expectedCashShouldBeEqualToTotal', 'el valor esperado en caja debe coincidir con el total de efectivo en el turno')
    if (expectedCash.decimalPlaces(decimal).gt(total.decimalPlaces(decimal)))
      errors.expectedCash = I18n.get('expectedCashShouldBeEqualToTotal', 'el valor esperado en caja debe coincidir con el total de efectivo en el turno')
  }

  return errors;
}

export const transform = values => {
  return {
    idStation: get(values, 'idStation', null),
    cash: get(values, 'cash', null),
    debit: get(values, 'debit', null),
    credit: get(values, 'credit', null),
    transfer: get(values, 'transfer', null),
    refunds: get(values, 'refunds', null),
    inPayment: get(values, 'inPayment', null),
    outPayment: get(values, 'outPayment', null),
    costCenter: get(values, 'costCenter', null),
    observations: get(values, 'observations', null),
    creditSales: get(values, 'creditSales', null),
  }
}