import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import { BigNumber } from "bignumber.js";
import dayjs from 'dayjs';

import {
  decimalPrecision,
  country as countrySelector,
  dateFormat as dateFormatSelector,
} from '../../selectors/company';
import { getNumber } from '../../utils/invoices';

const Invoice = ({ invoice, idSelected, onSelect }) => {
  const companyDecimal = useSelector(decimalPrecision);
  const decimal = !!get(invoice, 'decimalPrecision', null) ? Number(invoice.decimalPrecision) : companyDecimal;
  const country = useSelector(countrySelector);
  const dateFormat = useSelector(dateFormatSelector);

  if (!invoice) return null;

  const prefix = !!get(invoice, 'currency.symbol')
    ? get(invoice, 'currency.symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  } : null

  return (
    <div
      className={`invoice form-row p-3 d-flex justify-content-between align-items-center ${+idSelected === +invoice.id ? 'selected' : ''}`}
      onClick={() => onSelect(invoice.id)}
    >
      <div className="d-flex flex-column col-8">
        <div className="title text-break text-nowrap text-truncate text-capitalize-first">
          {getNumber(invoice, country)}
        </div>
        <div className="text-break text-wrap text-capitalize-first">
          {!!invoice.offlineStatus
            ? dayjs(invoice.timestamp).format(I18n.get(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')))
            : dayjs(invoice.createdAt).format(I18n.get(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')))
          }
        </div>
        <div className="text-break text-nowrap text-truncate text-capitalize-first">
          {(!!invoice.offlineStatus && invoice.offlineStatus === 'pending') && (
            <span className="badge badge-warning badge-pill m-0">{I18n.get('pending', 'pendiente')}</span>
          )}

          {(!!invoice.offlineStatus && invoice.offlineStatus === 'syncing') && (
            <span className="badge badge-info badge-pill m-0">{I18n.get('syncing', 'sincronizando')}</span>
          )}

          {(!!invoice.offlineStatus && invoice.offlineStatus === 'error') && (
            <span className="badge badge-danger badge-pill m-0">{I18n.get('error', 'error')}</span>
          )}
        </div>
      </div>
      <div className="col-4">
        <div className="price text-break text-nowrap text-capitalize-first text-right"> 
          {new BigNumber(invoice.total).toFormat(decimal, fmt)}
        </div>
      </div>
    </div>
  )
}

Invoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  idSelected: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
}

export default Invoice;
