import React from 'react';
import { useSelector } from 'react-redux'
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { accountReceivableCategories } from '../../../selectors/categories'
import { renderSelect } from '../fields/V0/Fields';

const Accounting = () => {
  const accountReceivableOptions = useSelector(accountReceivableCategories)
  //const debtToPayOptions = useSelector(debtToPayCategories)

  return (
    <div className="form-fields form-row justify-content-start">
      <Field
        name="accounting.accountReceivable"
        component={renderSelect}
        className="col-12"
        label={capitalize(I18n.get('accountReceivable', 'cuenta por cobrar'))}
        options={accountReceivableOptions}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
      />


      {/* <Field
        name="accounting.debtToPay"
        component={renderSelect}
        className="col-12"
        label={capitalize(I18n.get('debtToPay', 'cuenta por pagar'))}
        options={debtToPayOptions}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
      /> */}
    </div>
  )
}

export default Accounting;
