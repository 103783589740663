import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import Cookies from 'js-cookie';
import { replaceAndParse } from '../../../utils';

import { openModal, closeModal } from '../../../reducers/modals';
import { country as countrySelector, notificationsSelector, electronicInvoicing } from '../../../selectors/company';
import Modal from '../../common/Modal';
import imageTermsAndConditions from '../../../assets/images/image_terms_and_conditions.png';
import { APIGraphqlSelector } from '../../../selectors/app';
import { graphqlOperation } from 'aws-amplify';
import { updateUser } from '../../../graphql/mutations';
import { notificationsTransformer } from '../../notifications/utils';

const CompanyDataUpdate = () => {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const notifications = useSelector(notificationsSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const isElectronic = useSelector(electronicInvoicing);
  const notificationName = 'companyDataUpdate';

  const isOpen = useSelector(state => get(state, 'modals.companyDataUpdate.isOpen', false));

  useEffect(() => {
    if (Cookies.get(notificationName)) return;
    if (country !== 'colombia' || !isElectronic) return;
    if (notifications.find(n => n[notificationName])) return;

    // dispatch(openModal({ modal: 'companyDataUpdate' }))

  }, [country, dispatch, notifications])

  const handleClose = async () => {
    dispatch(closeModal({ modal: 'companyDataUpdate' }))
    try {
      if (!notifications.find(n => n[notificationName])) {
        const response = await APIGraphql(graphqlOperation(updateUser, notificationsTransformer(notificationName)));

        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')))
        }
      }

      Cookies.set(notificationName, true, { expires: 1 });

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeAction={handleClose}
        title={I18n.get('smartDataUpdate', 'Actualización inteligente de datos ✨')}
        className={"modal__new-terms-col"}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="my-4 px-5">
            <p className="h4 text-capitalize-first mb-5" style={{ lineHeight: 1.6 }}>
              {replaceAndParse(I18n.get('smartDataUpdate.description', 'A partir de mayo, <b>tu nombre o razón social en Alegra debe coincidir con tu RUT</b> según la DIAN. Lo actualizamos por ti desde ya para que tu negocio esté siempre al día.'))}
            </p>
          </div>
          <img src={imageTermsAndConditions} style={{ width: '100%' }} alt="" />
        </div>

        <div className="modal__bottom w-100 p-3 d-flex justify-content-end align-items-center shadow">
          <div className="modal__bottom-buttons d-flex">
            <button
              type="button"
              className="btn btn-cancel text-capitalize-first"
              onClick={async () => await handleClose()}
            >
              {I18n.get('close', 'Cerrar')}
            </button>
            <button
              type="button"
              data-testid="modal-submit-btn"
              onClick={async () => {
                Cookies.set(notificationName, true, { expires: 1 })
                window.open(`settings/company`, '_blank')
                handleClose();
              }}
              className="btn btn-submit text-capitalize-first"
            >
              {I18n.get('knowUpdatedData', 'Conocer datos actualizados')}
            </button>
          </div>
        </div>
      </Modal >
    </>
  )
}

export default CompanyDataUpdate;