import React from 'react';
import { useDispatch } from 'react-redux';

import { setSettings } from '../../../reducers/activeInvoice';
import CurrencyModal from '../../modals/currency/Currency';
import Header from './Header';
import FastOptions from './FastOptions';
import Client from './Client';
import SearchItem from './SearchItem';
import InvoiceItems from './InvoiceItems';
import Summary from './Summary';
import Actions from './Actions';

const ActiveInvoice = () => {
  const dispatch = useDispatch()

  return (
    <>
      <div className="new-active-invoice w-sm-100 d-flex flex-column position-relative">
        <Header />

        <div className="invoice-top d-flex flex-column">
          <FastOptions />

          <Client />

          <SearchItem />
        </div>

        <div className="invoice-content">
          <InvoiceItems />
        </div>

        <div className="invoice-bottom">
          <Summary />

          <Actions />
        </div>
      </div>

      <CurrencyModal onCreated={(currency) => dispatch(setSettings({ currency }))} />
    </>
  )
}

export default ActiveInvoice;
