import React from 'react'

import Contact from './contact/Contact';
import NewEditItem from './editItem/NewEditItem';
import Item from './item/Item';
import InvoiceSettings from './invoiceSettings/InvoiceSettings';
import Company from './company/Company';

const SideModals = () => (
  <>
    <Contact />
    <Item />
    <InvoiceSettings />
    <Company />
    <NewEditItem />
  </>
)

export default SideModals;