import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import { itemCategoryTransformer } from '../../../utils/transformers';
import { hasPermissionTo } from '../../../selectors/auth';
import Tooltip from '../../common/Tooltip';

import EditSolid from "../../svg/EditSolid.svg";
import DeleteSolid from "../../svg/DeleteSolid.svg";
import Lock from '../../svg/Lock'
import Unlock from '../../svg/Unlock'
import { toast } from '../../../utils';
import { handleError } from '../../../utils/errors';

const Actions = ({ itemCategory, onView, filters, deleteItemCategory, updateItemCategory }) => {
  const can = useSelector(hasPermissionTo);

  return (
    <div className="d-flex align-items-center justify-content-start table-gray-actions">
      <Tooltip
        tooltipActive={!can('edit', 'categories')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.categories.edit', 'no tienes permisos para editar categorías')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn button-transparent"
          title={capitalize(I18n.get('edit', 'editar'))}
          disabled={!can('edit', 'categories') || !get(itemCategory, 'editable', true)}
          onClick={() => onView()}
        >
          <img src={EditSolid} alt="editar" />
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can('edit', 'categories')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.categories.edit', 'no tienes permisos para editar categorías')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn button-transparent"
          onClick={() => updateItemCategory({
            itemCategory: itemCategoryTransformer({
              id: itemCategory?.id,
              name: itemCategory?.name,
              status: !(get(itemCategory, 'status') === 'active')
                ? 'active'
                : 'inactive'
            })
          }, {
            onSuccess: (_, variables) => {
              toast.success({
                title: get(variables, 'status') === 'active'
                  ? I18n.get('itemCategoryActivatedSuccessfully', 'Categoria activada con éxito')
                  : I18n.get('itemCategoryDeactivatedSuccessfully', 'Categoria desactivada con éxito')
              })
            },
            onError: (error) => {
              const responseError = get(error, 'response.data.message', '');

              toast.error({
                title: I18n.get('itemCategoryStatusError', 'Error cambiando estado de la categoria'),
                subtitle: !!responseError ? responseError : handleError(error)
              });
            },
          })}
          title={get(itemCategory, 'status') === 'active'
            ? capitalize(I18n.get('deactivate', 'desactivar'))
            : capitalize(I18n.get('activate', 'activar'))
          }
          disabled={!can('edit', 'categories')}
        >
          {get(itemCategory, 'status') === 'active'
            ? <Unlock className="icon-primary" />
            : <Lock className="icon-primary" />
          }
        </button>
      </Tooltip>

      <Tooltip
        tooltipActive={!can('delete', 'categories')}
        overlay={(
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.categories.delete', 'no tienes permisos para eliminar categorías')}
          </p>
        )}
      >
        <button
          type="button"
          className="btn button-transparent button-remove"
          title={capitalize(I18n.get('delete', 'eliminar'))}
          disabled={!can('edit', 'categories') || !get(itemCategory, 'deletable', true)}
          onClick={() => deleteItemCategory({ id: itemCategory?.id, itemCategory })}
        >
          <img src={DeleteSolid} alt="eliminar" />
        </button>
      </Tooltip>
    </div>
  )
}

export default Actions;