import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'
import { BigNumber } from 'bignumber.js'

import * as mutations from '../../../graphql/mutations'
import { formError } from '../../../utils/errors';
import { closeModal, openModal } from '../../../reducers/modals';
import { decimalPrecision, email as companyEmail, identificationSelector } from '../../../selectors/company'
import Body from '../../forms/sendInvoiceEmail/SendInvoiceEmail'
import { validate } from '../../forms/sendInvoiceEmail/utils'
import Modal from '../../common/Modal';
import { APIGraphqlSelector } from '../../../selectors/app';

const SendEmail = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => get(state, 'modals.sendEmail.isOpen', false));
  const invoice = useSelector(state => get(state, 'modals.sendEmail.params.invoice', null));
  const decimal = useSelector(decimalPrecision)
  const email = useSelector(companyEmail)
  const identification = useSelector(identificationSelector)
  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async values => {
    try {
      await APIGraphql(graphqlOperation(mutations.sendInvoice, {
        id: invoice.id,
        emails: values.email
      }))
    } catch (error) {
      if (!email || !identification) {
        dispatch(openModal({ modal: 'companyEmail' }))
        return formError(null, I18n.get('youNeedToConfigYourCompanyEmail', 'Primero necesitas configurar el correo electrónico de tu negocio. Luego reintenta'))
      }
      return formError(error, I18n.get('invoiceSendByEmailError', 'ocurrió un error al intentar enviar la venta por correo'))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeModal({ modal: 'sendEmail' }))}
      title={I18n.get('sendEmail', 'enviar correo electrónico')}
      className="modal__invoice"
    >
      <div className="d-flex flex-column p-3 justify-content-center align-items-center">
        <div className="p-4 d-flex justify-content-center align-items-center">
        </div>
        {!!invoice && (
          <div className="d-flex align-items-center">
            <p className="h3 text-muted text-uppercase mr-2">
              {I18n.get('total', 'total')}
            </p>
            <h1>
              {new BigNumber(invoice.total).toFormat(decimal)}
            </h1>
          </div>
        )}

        <div className="px-sm-5 py-5 d-flex flex-column w-75 m-auto">
          <p className="text-muted text-uppercase h4">
            {I18n.get('sendByMail', 'enviar por correo')}
          </p>

          <Form
            onSubmit={submit}
            validate={values => validate(values)}
            initialValues={{
              email: get(invoice, 'client.email', null)
            }}
          >
            {({ handleSubmit, submitting, submitError, submitSucceeded }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Body submitting={submitting} />

                {!!submitError && !!email && !!identification && (
                  <p className="h4 text-danger text-capitalize-first">
                    {submitError}
                  </p>
                )}

                {submitSucceeded && (
                  <p className="h4 text-primary text-capitalize-first">
                    {I18n.get('invoiceSendByEmailSuccessfully', 'la venta ha sido exitosamente enviada por correo')}
                  </p>
                )}
              </form>
            )}
          </Form>
        </div>
      </div>

    </Modal>
  )
}

export default SendEmail;