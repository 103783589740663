import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { transformFromAPI, validateOnSubmit, validate, transform } from '../../forms/item/utils';
import { itemTransformer } from '../../../utils/transformers';
import { toast } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import { attachFavoriteImageToItem, checkVariantImages, isItemFromStation, saveVariantsImages } from '../../../utils/items'
import { station as stationSelector } from '../../../selectors/app';
import { customFields as customFieldSelector, requiredFields } from '../../../selectors/customFields'

import alegraAPI from '../../../reducers/alegraAPI';
import Primary from './Primary';
import Secondary from '../NewItem/Secondary';
import Loading from '../../svg/Loading';
import {
  decimalPrecision,
  country as countrySelector,
  electronicInvoicing,
  regime as regimeSelector,
} from '../../../selectors/company';
import { activeWarehouses } from '../../../selectors/warehouses';
import { refresh } from '../../../reducers/items';
import configAPI from '../../../reducers/configAPI';
import * as itemsDB from '../../../database/itemsDB';
import { getCategoryByName } from '../../../selectors/categories';
import { languageSelector } from '../../../selectors/auth';

const EditItemPage = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { id } = useParams();
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const warehouses = useSelector(activeWarehouses);
  const allCustomFields = useSelector(customFieldSelector);
  const mainCategory = useSelector(getCategoryByName("Ventas"));
  const inventoryCategory = useSelector(getCategoryByName("Inventarios"));
  const inventariablePurchaseCategory = useSelector(getCategoryByName("Costos de inventario"));
  const station = useSelector(stationSelector);
  const customFields = useSelector(requiredFields);
  const regime = useSelector(regimeSelector);
  const isElectronic = useSelector(electronicInvoicing);
  const language = useSelector(languageSelector);

  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, [])

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, [])

  const fetchData = useCallback(async () => {
    startLoading()
    try {
      const fields = "ledger,itemVariants,variantAttributes,editable,settingsOnShop,remissionedQuantity,negativeSale";
      const itemResponse = await alegraAPI.get(`/items/${id}?fields=${fields}`);

      if (!get(itemResponse, 'data.editable'))
        throw new Error()

      if (get(itemResponse, 'data.type') === 'variant')
        throw new Error()

      stopLoading()

      const item = get(itemResponse, 'data');

      // ex queries.allUnits
      const optionsResponse = await configAPI.v2.get('/units', {
        application_version: country,
      })
      let options = get(optionsResponse, 'data', []);
      if (country === "republicaDominicana") {
        options = options.map(unit => ({ ...unit, unitType: unit.itemType }))
      }

      const transformedItem = transformFromAPI(item, { country, unitOptions: options, decimal, warehouses, language });

      setItem(transformedItem);
    } catch (err) {
      history.goBack();
    }
  }, [id, startLoading, stopLoading, decimal, country, warehouses, history, language])

  const submit = async values => {
    startLoading()

    if (validateOnSubmit(values))
      return formError(null, I18n.get('updateItemError', 'hubo un error actualizando el producto'))

    const transformedValues = transform(values, {
      country, decimal, allCustomFields,
      categories: {
        mainCategory,
        inventoryCategory,
        inventariablePurchaseCategory
      },
    })

    try {
      const response = await alegraAPI.put(`/items/${item.id}`, itemTransformer({ ...item, ...transformedValues }))
      if (!!values.imageSrc && values.imageSrc.split('http')[0] !== '') {
        await attachFavoriteImageToItem(values.id, values.imageSrc, true);
      }
      const { idWarehouse } = station;
      const newItem = get(response, 'data', null);

      if (get(transformedValues, 'type', '') === 'variantParent') {
        const variantsImages = checkVariantImages(transformedValues)
        if (get(variantsImages, 'length')) {
          try {
            await saveVariantsImages(JSON.parse(get(newItem, 'itemVariants')), variantsImages)
          } catch {
          }
        }
      }

      if (isItemFromStation(newItem, idWarehouse)) {
        await itemsDB.update(newItem.id, newItem)
        dispatch(refresh())
      }

      history.goBack();
      stopLoading();
      toast.success({
        title: `${I18n.get('itemUpdatedSuccessfully', 'producto actualizado con éxito')} 🎯`,
      });

    } catch (error) {
      stopLoading();
      toast.error({
        subtitle: handleError(error, I18n.get('updateItemError', 'hubo un error actualizando el producto'))
      })
      return formError(error, I18n.get('updateItemError', 'hubo un error actualizando el producto'))
    }
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center h-2 mt-5 p-3">
        <Loading className="icon-primary icon x2" />
      </div>
    )
  }
  return (
    <Form
      onSubmit={submit}
      mutators={{
        ...arrayMutators
      }}
      initialValues={item}
      validate={values => validate(values, {
        country,
        customFields,
        regime,
        isElectronic,
      })}
    >
      {({ handleSubmit, values, form, submitting }) => (
        <form
          noValidate
          className="py-5 row new-item"
          onSubmit={handleSubmit}
        >

          <Primary
            form={form}
            values={values}
            submitting={submitting}
            existentCustomFields={get(item, 'customFields', null)}
          />

          <Secondary
            form={form}
            values={values}
            submitting={submitting}
          />

        </form>
      )}
    </Form>
  )
}

export default EditItemPage