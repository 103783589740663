import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import BigNumber from 'bignumber.js';

import { country as countrySelector, decimalPrecision, externalProviderState } from '../../../selectors/company'
import { decimalsVersionsGroup } from '../../../selectors/deployGroups';
import Transfer from '../../svg/paymentMethods/Transfer'
import Nequi from '../../svg/paymentMethods/Nequi'
import Tpaga from '../../svg/paymentMethods/Tpaga'

import ExternalProvidersMethodsModals from './ExternalProvidersMethodsModals';

const OtherMethods = ({ form, total }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [method, setMethod] = useState();
  const country = useSelector(countrySelector);
  const decimal = useSelector(decimalPrecision);
  const nequiState = useSelector(externalProviderState('nequi'));
  const tpagaState = useSelector(externalProviderState('tpaga'));

  return (
    <div className="form-body h-100 p-4 overflow-hidden">
      <div className="form-body__fields form-row align-items-center justify-content-around">
        <Field
          name="method"
          className="col-md-4"
        >
          {props => (
            <button
              type="button"
              data-testid="transfer-method-btn"
              className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
              onClick={() => {
                props.input.onChange('transfer')
                form.change('transfer', total)
                if (decimalsVersionsGroup.includes(country))
                  form.change('transfer', new BigNumber(total).toFixed(decimal).toString())
              }}
            >
              <Transfer />
              <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('transfer', 'transferencia')}</p>
            </button>
          )}
        </Field>

        {country === 'colombia' && (
          <>
            <Field
              name="method"
              className="col-md-4"
            >
              {props => (
                <button
                  type="button"
                  className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
                  onClick={() => {
                    if (nequiState === 'active') {
                      props.input.onChange('nequi')
                    } else {
                      setMethod('nequi')
                      setIsOpen(true)
                    }
                  }}
                >
                  <Nequi />
                  <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('nequi', 'nequi')}</p>
                </button>
              )}
            </Field>

            <Field
              name="method"
              className="col-md-4"
            >
              {props => (
                <button
                  type="button"
                  className="btn btn-invoice-method button-transparent border py-4 px-5 d-flex flex-column align-items-center"
                  onClick={() => {
                    if (tpagaState === 'active') {
                      props.input.onChange('tpaga')
                    } else {
                      setMethod('tpaga')
                      setIsOpen(true)
                    }
                  }}
                >
                  <Tpaga />
                  <p className="text-uppercase h5 text-muted font-weight-bold">{I18n.get('tpaga', 'tpaga')}</p>
                </button>
              )}
            </Field>
          </>
        )}
      </div>

      <ExternalProvidersMethodsModals
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        method={method}
      />
    </div>
  )
}

export default OtherMethods;