// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    // fill="none" 
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.96 4l2.791 2.777L10.554 12l5.197 5.223L12.96 20 5 12l7.96-8z" />
  </svg>
)