import React from 'react'
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux'
import { BigNumber } from 'bignumber.js'
import { get, isNumber } from 'lodash'

import { decimalPrecision } from '../../../selectors/company'
import { currency } from '../../../selectors/activeInvoice'
import { getMainCurrency } from '../../../selectors/currencies'

const Summary = ({ values }) => {
  const decimal = useSelector(decimalPrecision)
  const mainCurrency = useSelector(getMainCurrency)
  const selectedCurrency = useSelector(currency)

  const prefix = !!get(selectedCurrency, 'symbol') ? get(selectedCurrency, 'symbol')
    : !!get(mainCurrency, 'symbol') ? get(mainCurrency, 'symbol') : ''

  const fmt = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  }

  const itemQuantity = new BigNumber(!!+values.quantity ? +values.quantity : 1).decimalPlaces(2);
  const itemPrice = new BigNumber(!!values.price ? values.price : 0);
  const itemDiscountPercent = new BigNumber(!!isNumber(get(values, 'discount.discount', null))
    ? get(values, 'discount.discount')
    : !!get(values, 'discount', null)
      ? get(values, 'discount') : 0
  ).decimalPlaces(2);

  const itemSubtotal = itemPrice.multipliedBy(itemQuantity.isPositive() ? itemQuantity : 1).decimalPlaces(2);

  const itemDiscount = itemPrice.minus(itemPrice * new BigNumber(1).minus(itemDiscountPercent.dividedBy(100)))
    .multipliedBy(itemQuantity);

  const discountSubtotal = new BigNumber(new BigNumber(1).minus(itemDiscountPercent.dividedBy(100)))
    .multipliedBy(itemPrice).multipliedBy(itemQuantity).abs()

  return (
    <div className="d-flex flex-column p-4 form-row justify-content-end m-0 new-item-summary">
      <div className="col-12">
        {(itemDiscount.gt(0) || !!values.tax) && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('subtotal', 'sub total')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {itemSubtotal.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemDiscount.gt(0) && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('discount', 'descuento')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              -{itemDiscount.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {itemDiscount.gt(0) && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center">
            <div className="new-item-summary__row-concept text-capitalize-first">
              {I18n.get('subtotal', 'sub total')}
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {discountSubtotal.toFormat(decimal, fmt)}
            </div>
          </div>
        )}

        {values.tax.map((tax, i) => !!tax && (
          <div className="new-item-summary__row d-flex justify-content-between align-items-center" key={i}>
            <div className="new-item-summary__row-concept text-capitalize-first">
              {tax.name} ({BigNumber(tax.percentage).toFormat(decimal, { ...fmt, prefix: '' })}%)
            </div>
            <div className="new-item-summary__row-value text-capitalize-first">
              {discountSubtotal.multipliedBy(tax.percentage / 100).toFormat(decimal, fmt)}
            </div>
          </div>
        ))}

        <div className="new-item-summary__row d-flex justify-content-between align-items-center">
          <div className="text-capitalize-first">
            {I18n.get('total', 'total')}
          </div>
          <div className="text-capitalize-first">
            {new BigNumber(100).plus(!!values.tax[0] ? values.tax.reduce((prev, tax) => prev + Number(tax.percentage), 0) : 0).dividedBy(100).multipliedBy(discountSubtotal)
              .decimalPlaces(decimal).toFormat(decimal, fmt)}
          </div>
        </div>

      </div>
    </div>
  )
}

Summary.propTypes = {
  values: PropTypes.object.isRequired
}

export default Summary;