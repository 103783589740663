import React from 'react';
import { isString, capitalize } from 'lodash';

import { I18n } from '@aws-amplify/core';
import CloseSVG from '../../../../svg/Close';

const Header = ({ title, icon, description, seeMoreUrl, onClose, hideClose }) => (
  <div className="modal__header w-100 d-flex justify-content-between align-items-center 
    p-3 border-bottom"
  >
    <div className="d-flex w-100">
      {!!icon && <div className="modal__header-icon mr-3">{icon}</div>}
      {!!title && 
        isString(title) 
        ? (
          <div className="modal__header-title text-capitalize-first h2 text-primary text-truncate">
            {title}
            { description && 
            <p className="text-capitalize-first h5 text-muted">
              {description}{' '}
              { seeMoreUrl &&
                <a href={seeMoreUrl} target="_blank" rel="noreferrer">
                  {capitalize(I18n.get('seeMore', 'Ver más'))}
                </a>
              }
            </p>
            }
          </div>
        ): title }
    </div>

    <button
      className={`btn button-transparent btn-sm ${hideClose ? 'invisible': 'visible'}`} 
      type="button"
      onClick={() => onClose()}
    >
      <CloseSVG className="icon-gray"/>
    </button>
  </div>
);

export default Header;
